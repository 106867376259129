import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { Container, Row , Button, Col, Breadcrumb } from "react-bootstrap";
import { useGlobalState, useDispatch } from "../../../store/StoreProvider";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import toursValidate from "../../../validate/toursValidate.js";
import serviceListAction from "../../../actions/serviceListAction";
import DrawerFilter from "../../../components/ui/DrawerFilter";
import serviceType from "../../../constants/serviceTypeConstant";
import CardContainerService from "../../../components/cards/CardContainerService";
import SkeletonCardList from "../../../components/skeleton/SkeletonCardList/SkeletonCardList";
import BoxBooking from "../../../components/bookings/BoxBooking/BoxBooking";
import BoxWhitoutResults from "../../../components/boxes/BoxWhitoutResults/BoxWhitoutResults"
import "./components/tourListPage.scss";

const TourListPage = (props) => {
  const { locale, resourceSite } = props;
  const pathnames = resourceSite?.siteRoutes;
  const searchTourParams = resourceSite?.searchTourParams;
  const intl = useIntl();
  let navigate = useNavigate();
  const location = useLocation();
  const { currency, authSession } = useGlobalState();
  const [searchParams, setSearchParams] = useSearchParams();
  const localizedUrlDetails = `/${locale}${pathnames.tour.pathname}`;
  const [bodyRenderScroll, setBodyRenderScroll] = useState(false);
  const [filtersApply, setFiltersApply] = useState({});
  const [suggestionsDrawerFilter, setSuggestionsDrawerFilter] = useState([]);
  const [toursFiltered, setToursFiltered] = useState([]);
  const [show, setShow] = useState(false);
  const [params, setParams] = useState(null);
  const [availability, setAvailability] = useState(null);
  const [tours, setTours] = useState({
    items: [],
    isFetching: false,
    filters: [],
  });
  
  const [cardsView, setCardsView] = useState({
    total: 0,
    items: [],
    pageSize: 0,
    loading: true,
  });

  const query = {
    sort: searchParams.get("sort"),
    destination: searchParams.get("destination"),
    destinationkeyword: searchParams.get("destinationkeyword"),
    checkin: searchParams.get("checkin"),
    paxes: searchParams.get("paxes"),
  };


  const gridCards = (numberView, availability, newItems, sort = true) => {
    const insideItem = newItems && newItems.isFetching ? newItems : tours;
    let nCards = null;

    if (availability.orderBy === "recommended") {
      nCards = !sort
        ? insideItem.items
        : insideItem.items.sort((a, b) => {
            return a.price === b.price ? 0 : a.price > b.price ? 1 : -1;
          });
      const listGeneral = nCards.filter((meal) => meal.mealPlanCode !== "AI");
      const listAllInclusive = nCards.filter(
        (meal) => meal.mealPlanCode === "AI"
      );
      const all = [...listAllInclusive, ...listGeneral];
      nCards = all;
    } else {
      nCards = !sort
        ? insideItem.items
        : insideItem.items.sort((a, b) => {
            if (availability.orderBy === "desc")
              return a.price === b.price ? 0 : a.price > b.price ? -1 : 1;
            else return a.price === b.price ? 0 : a.price > b.price ? 1 : -1;
          });
    }

    return {
      total: insideItem.items.length,
      items: nCards.length < numberView ? nCards : nCards.slice(0, numberView),
      pageSize: nCards.length < numberView ? nCards.length : numberView,
      loading: insideItem.isFetching ? false : true,
    };
  };

  const getNumbreViewCards = (page) => {
    return 8 * page;
  };

  const applyFilters = (value, type) => {
    const filters = { ...filtersApply };
    switch (type) {
      case "ID":
        filters[type] = value ? value : null;
        break;
      case "NAME":
        filters[type] = value ? value : null;
        break;
      default:
        if (!filters[type]) filters[type] = [];
        var _value = value.value.replace(type + "_", "");
        var currentIndex = filters[type].indexOf(_value);
        value.checked
          ? filters[type].push(_value)
          : filters[type].splice(currentIndex, 1);
        break;
    }

    setFiltersApply(filters);

    var sort = true;
    var items = tours?.items?.filter( (h) =>
      !filters.CATEGORY ||
      !filters.CATEGORY.length ||
        filters.CATEGORY.includes(h.category?.clearValue())
    )
    .filter((h) =>
     !filters.DURATION ||
     !filters.DURATION.length ||
     filters.DURATION.includes(h.duration?.clearValue())
   )
  
    if (filters.ID) {
      sort = false;
      items = items.filter((h) => h.id !== value.id);
      items.unshift(tours.items.find((h) => h.id === value.id));
      filters.ID = null;
      setFiltersApply(filters);
    }

    setToursFiltered(items);

    const nAvailability = { ...availability, page: 1 };
    const nCardsView = { ...cardsView, items: items, isFetching: true };
    setAvailability(nAvailability);
    setCardsView(
      gridCards(
        getNumbreViewCards(nAvailability.page),
        nAvailability,
        nCardsView,
        sort
      )
    );
  };

  const onDrawerInputAutocompleteChange = (value, onSuccess) => {
    if (value != null && value !== "") {
      const toursFiltered = value
        ? tours.items
            .filter(
              (item) =>
                item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
            )
            .map((item) => {
              return {
                id: item.id,
                label: item.name,
              };
            })
        : [];
      setSuggestionsDrawerFilter(toursFiltered);
      const newSuggetionsAutocomplete = {
        items: toursFiltered.map((item) => {
          return {
            name: item.label,
            code: item.id
          }
        })
      }
      onSuccess(newSuggetionsAutocomplete);
    } else {
      applyFilters(null, "ID");
      applyFilters(null, "NAME");
    }
  };
  const getTours = (params, availability) => { 
    const dictionary = {
      discount: intl.formatMessage({id: "general.discount",})
    }
    serviceListAction.tourMock(
      {  locale, sourceMarket: "MX", pathNext: localizedUrlDetails, authSession },
      (response) => {
        setTours(response);
        setCardsView(
          gridCards(
            getNumbreViewCards(availability.page),
            availability,
            response
          )
        );
      },
      (error) => {
        console.error(error);
        setTours({ items: [], filters: [], isFetching: true });
        setCardsView({
          total: 0,
          items: [],
          pageSize: 0,
          loading: false,
        });
      },
      dictionary
    )
  }
  
  const getTagsFiltersApply = () => {
    var tags = [];
    Object.keys(filtersApply).forEach((key) => {
      var values = filtersApply[key];
      if (Array.isArray(values)) {
        values.forEach((v) => {
          tags.push({
            key: v.getId(key),
            label: v.getValue(key),
            type: key,
          });
        });
      }
    });
    return tags;
  };

  const getSelectedsFiltersApply = () => {
    var tags = getTagsFiltersApply();
    return tags.map((tag) => tag.key);
  };

  const onClickOpenFilter = () => setShow((s) => !s);
  const onDrawerHandleToggle = (value) => applyFilters(value, value.code);

  const onDrawerAutocompleteChange = (value) => {
    switch(typeof value) {
      case "object":
        applyFilters({
          id: value.code,
          name: value.name
        }, "ID");
        break;
      case "string":
        applyFilters(value, "NAME");
        break;
      default:
        applyFilters(null, "ID");
        applyFilters(null, "NAME");
    }
  };

  const onClickMoreServices = () => {
    const nAvailability = { ...availability, page: availability.page + 1 };
    setAvailability(nAvailability);
    let nCardsView = { ...cardsView, items: tours.items, isFetching: true };
    if (toursFiltered.length)
      nCardsView = { ...cardsView, items: toursFiltered, isFetching: true };
    setCardsView(
      gridCards(
        getNumbreViewCards(nAvailability.page),
        nAvailability,
        nCardsView
      )
    );
  };

  if (typeof window !== 'undefined') {
    window.onscroll = function () {
        var y = window.scrollY;
        const elem = document.querySelector("#body-render");
        const rect = (elem != null && elem !== undefined && elem && elem.offsetTop) ? elem.offsetTop : 0;

        setBodyRenderScroll(y > (rect + 10))
    };
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    resetInitialRender();
  }, [location, currency]);

  const resetInitialRender = (searchBooking) => {
      const auxParams = {
        ...toursValidate.paramsListFormat(
          query,
          searchTourParams.paramsRoom.rules
        ),
        currency: currency?.code,
        locale,
      };
      setParams(auxParams);
  
      const auxAvailability = toursValidate.paramsListFormatAvailability(query);
      setAvailability(auxAvailability)
  
      setTours({
        items: [],
        isFetching: false,
        filters: [],
      });
      setCardsView({
        total: 0,
        items: [],
        pageSize: 0,
        loading: true,
      });
  
      setFiltersApply({})
      setSuggestionsDrawerFilter([])
      setToursFiltered([])
      getTours(auxParams, auxAvailability);
  }

  const onChangeBooking = (searchBooking) => {
    resetInitialRender(searchBooking);
  }

  return (<div id="body-render">
    <div id="booking-activities" className={`search-box-service-hotel  ${bodyRenderScroll ? 'box-fixed' : ''}`}>
        <div className="pt-3">
          <Container fluid="xl">
          {!bodyRenderScroll && (
            <Breadcrumb className="d-none d-sm-block">
              <Breadcrumb.Item href={`/${locale}/home/Tour`}>{intl.formatMessage({
                id: "general.breadcrumdHome",
              })}</Breadcrumb.Item>
              <Breadcrumb.Item active={false}>
                {intl.formatMessage({ id: "general.breadcrumdTours", })}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{params?.inputAutoValueStart?.label}</Breadcrumb.Item>
            </Breadcrumb>
          )}
          </Container>
        </div>
        {params && (
            <div className="pb-2">
            <Container fluid="xl">
              <BoxBooking
                params={params}
                locale={locale}
                siteRoutes={resourceSite.siteRoutes}
                closeColorButton="white"
                type={serviceType.TOUR}
                searchHotelParams={searchTourParams}
                onChangeBooking={onChangeBooking}
                viewBooking={true}
              />
            </Container>
            </div>
        )}
      </div>
    <Container className=" mt-3 mb-2">
    <Row>
    {cardsView.loading && (
                    <SkeletonCardList number={8} />
          )}
      
      </Row>
      <Row>
        <Col>
        {!cardsView.loading && cardsView.pageSize > 0 && (
            <h3 className="total-hotel">               
                {!cardsView.loading && intl.formatMessage({id: "hotelListPage.hotelResults"}, {count: cardsView.pageSize})}
              </h3>
          )}
          {!cardsView.loading && cardsView.pageSize === 0 && 
          <>
            <br />
            <BoxWhitoutResults
              text = {intl.formatMessage({id: "hotelListPage.hotelWhitoutResults"})}
            />
          </>
          }
        </Col>
      </Row>
    </Container>
    <Container className="mb-5">
    {!cardsView.loading && (
      <DrawerFilter
        filters={tours.filters}
        selecteds={getSelectedsFiltersApply()}
        suggestions={suggestionsDrawerFilter}
        onInputAutocompleteChange={onDrawerInputAutocompleteChange}
        label={ intl.formatMessage({ id: "filtro.nameTour" })}
        onAutocompleteChange={onDrawerAutocompleteChange}
        show={show}
        onClickOpenFilter={onClickOpenFilter}
        handleToggle={onDrawerHandleToggle}
      />
    )}
      <Row>       
        {cardsView &&
          cardsView.items.map((card, index) => <CardContainerService 
          key={index} item={card} currency={currency?.code} 
          txt={ intl.formatMessage({ id: "text.perperson" }) }
          />)}
      </Row>
      {cardsView.pageSize < cardsView.total && (
        <Row>
          <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
            <Button
            variant="primary"
            className="button-more-hotels justify-content-md-center"
            onClick={onClickMoreServices}
            >
            {intl.formatMessage({id: "general.seeMore",})}
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  </div>
  );
};
// eslint-disable-next-line no-extend-native
String.prototype.getId = function (code) {
  return code + "_" + String(this).replace(/\s/g, "_").toUpperCase();
};
// eslint-disable-next-line no-extend-native
String.prototype.clearValue = function () {
  return String(this).replace(/\s/g, "_").toUpperCase();
};
// eslint-disable-next-line no-extend-native
String.prototype.getValue = function (type = null) {
  var _string = String(this);
  if (type && type === "STARS") {
    _string = _string + " " + (_string === "1" ? "Estrella" : "Estrellas");
  }
  return _string.replace(/_/g, " ").toPascalCase();
};
// eslint-disable-next-line no-extend-native
String.prototype.toPascalCase = function () {
  return String(this).replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};
export default TourListPage;
