import iconUtils from "../../../utils/iconUtils";
import "./components/style.scss"

const TimelineFlight = (props) => {
    const {arrival, departure, time, scale, flightSegment } = props;
    return (  
    <div className="box-timeline">
        <div className="timeline-date">
            <p>{arrival.time}</p>
            <span>{arrival.name}</span>
        </div>                                      
            <div className="linepoint">
                <div className="circle start-0"></div>                                               
                <div className="timeline-scale scale-top">{time}</div>
                <div className="line"></div> 
                {scale && (
                    <div className="circle-scale start-50"></div>
                )}
               
                <div className="timeline-scale scale-bottom">{scale ? flightSegment + " " + "Escala" : "Sin escalas"}</div>                                            
                <div className="circle end-0"></div>
            </div>                                        
        <div className="timeline-date text-end ">
            <p>{departure.time}</p>
            <span>{departure.name}</span>
        </div>
    </div>
);
}
 
export default TimelineFlight;