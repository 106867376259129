import React, { useEffect, useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import iconUtils from '../../../utils/iconUtils';
import iconContants from "../../../constants/iconContants";
import businessModel from '../../../constants/businessModel';
import "./components/style.scss";


const ToggleButtonTriple = (props) => {
	const { radios, valueDefaultSelected, type } = props;
	const [radioValue, setRadioValue] = useState(valueDefaultSelected);

	const onChangeToggle = (e) => {
		const { onChange } = props;
	    setRadioValue(e.currentTarget.value)
		onChange && onChange(e.currentTarget.value);
	}

	useEffect(() => {
		setRadioValue(valueDefaultSelected);
	}, [valueDefaultSelected]);

	return (
		<ButtonGroup className={`button-toggle-triple ${process.env.REACT_APP_BUSINESS === businessModel.B2B ? 'b--block' : ''}`}>
			{radios.map((radio, idx) => (
				<ToggleButton
					className='toggle-sx'
					key={`radio-${idx}`}
					id={`radio-${idx}`}
					type={type}
					name="radio"
					value={radio.value}
					checked={radioValue === radio.value}
					onChange={onChangeToggle}
				>
					<div className='container-icons-toggle'>
						<span className='me-1.carousel-item icon-toggle'>{iconUtils.icon(radio.icon)}</span>
						<span className='text-toggle'>{radio.name}</span>
					</div>
				</ToggleButton>
			))}
		</ButtonGroup>
	);

}

ToggleButtonTriple.defaultProps = {
	radios: [
		{ icon: iconContants.BSFILLGRIDFILL, name: 'Bloques', value: 'block' },
		{ icon: iconContants.BILISTUL, name: 'Lista', value: 'large' },
		{ icon: iconContants.LOCATION, name: 'Mapa', value: 'map' }
	],
	valueDefaultSelected: ""

}

export default ToggleButtonTriple;