const hotelDetailToEcommers = (hotel) => {
  let ecommerce = {
    currency: hotel?.bestRate?.currency,
    value: hotel?.bestRate?.total,
    items: [
      {
        item_id: hotel.id,
        item_name: hotel.name,
        discount: hotel?.bestRate?.totalDiscounted,
        item_brand: hotel.name,
        item_category: hotel.type,
        mealPlan: hotel?.rooms[0]?.mealPlan?.name,
        mealCode: hotel?.rooms[0]?.mealPlan?.code,
        roomType: hotel?.rooms[0]?.name,
        refundable: hotel?.rooms[0]?.refundable,
        price: hotel?.rooms[0]?.total,
        pricePerNight: hotel?.rooms[0]?.totalPerNight,
        currency: hotel?.rooms[0]?.currency,
        quantity: 1
      },
    ],
  };

  return ecommerce;
};

const hotelDetailRoomToEcommers = (hotel) => {
  let ecommerce = {
    currency: hotel?.rooms[0]?.currency,
    value: hotel?.rooms[0]?.total,
    items: [
      {
        item_id: hotel.id,
        item_name: hotel.name,
        discount: hotel?.bestRate?.totalDiscounted,
        item_brand: hotel.name,
        item_category: hotel.type,
        mealPlan: hotel?.rooms[0]?.mealPlan?.name,
        mealCode: hotel?.rooms[0]?.mealPlan?.code,
        roomType: hotel?.rooms[0]?.name,
        refundable: hotel?.rooms[0]?.refundable,
        price: hotel?.rooms[0]?.total,
        pricePerNight: hotel?.rooms[0]?.totalPerNight,
        currency: hotel?.rooms[0]?.currency,
        quantity: 1
      },
    ],
  };

  return ecommerce;
};

const hotelRoomItem = (hotel) => {
  const items = {
    item_id: hotel.id,
        item_name: hotel.name,
        discount: hotel?.bestRate?.totalDiscounted,
        item_brand: hotel.name,
        item_category: hotel.type,
        mealPlan: hotel?.rooms[0]?.mealPlan?.name,
        mealCode: hotel?.rooms[0]?.mealPlan?.code,
        roomType: hotel?.rooms[0]?.name,
        refundable: hotel?.rooms[0]?.refundable,
        price: hotel?.rooms[0]?.total,
        pricePerNight: hotel?.rooms[0]?.totalPerNight,
        currency: hotel?.rooms[0]?.currency,
        quantity: 1
  };

  return items;
};

const tourServiceItem = (tour) => {
  const items = {
    quantity: 1,
  };

  return items;
};

const traslateServiceItem = (tour) => {
  const items = {
    quantity: 1,
  };

  return items;
};

const packageServiceItem = (tour) => {
  const items = {
    quantity: 1,
  };

  return items;
};

const hotelListToEcommers = (hotels) => {
  const ecommerce = {
    items: hotels.items.map((hotel, i) => {
      return {
        index: i,
        item_id: hotel.id,
        item_name: hotel.name,
        discount: hotel?.bestRate?.totalDiscounted,
        item_brand: hotel.name,
        item_category: hotel.type,
        mealPlan: hotel?.bestRate?.mealPlan,
        price: hotel?.bestRate?.total,
        pricePerNight: hotel?.bestRate?.totalPerNight,
        currency: hotel?.bestRate?.currency,
        item_list_name: hotel?.destination,
      };
    }),
  };

  return ecommerce;
};

const purchaseEcommers = (services, transaction_id, value, currency,booking) => {
  
  const items = [];

  const hotelItems = services
    .filter(service => service.type === "Hotel")
    .map(hotelRoomItem);

    const tourItems = services
    .filter(service => service.type === "Tour")
    .map(tourServiceItem);

    const transferItems = services
    .filter(service => service.type ===  "Transfer")
    .map(traslateServiceItem);

    const packageItems = services
    .filter(service => service.type ===  "Package")
    .map(packageServiceItem);

  items.push(...hotelItems, ...tourItems,...transferItems, ...packageItems );

let itemNum =0;
  switch(booking.type){
    case "Hotel":
      itemNum= booking.rooms.length
      break
  }


  const ecommerce = {
    transaction_id: transaction_id,
    currency: currency,
    value: value,
    items: items,
    rooms: itemNum == 0 ? hotelItems.length : itemNum,
    tours: tourItems ? tourItems.length : 0,
    shuttleService : transferItems ? transferItems.length : 0
  };

  return ecommerce;
};

const paymentInfoEcommers = (services, payment_type, currency) => {

  const items = [];

  const hotelItems = services
    .filter(service => service.type === "Hotel")
    .map(hotelRoomItem);

    const tourItems = services
    .filter(service => service.type === "Tour")
    .map(tourServiceItem);

    const transferItems = services
    .filter(service => service.type ===  "Transfer")
    .map(traslateServiceItem);

    const packageItems = services
    .filter(service => service.type ===  "Package")
    .map(packageServiceItem);

    const total = hotelItems?.price

  items.push(...hotelItems, ...tourItems,...transferItems, ...packageItems );

  const ecommerce = {
    payment_type: payment_type,
    currency: currency,
    value: total,
    items: items,
  };

  return ecommerce;
};

const beginCheckoutInfoEcommers = (services, currency) => {

  const items = [];

  const hotelItems = services
    .filter(service => service.type === "Hotel")
    .map(hotelRoomItem);

    const tourItems = services
    .filter(service => service.type === "Tour")
    .map(tourServiceItem);

    const transferItems = services
    .filter(service => service.type ===  "Transfer")
    .map(traslateServiceItem);

    const packageItems = services
    .filter(service => service.type ===  "Package")
    .map(packageServiceItem);

  const total = hotelItems?.price

  items.push(...hotelItems, ...tourItems,...transferItems, ...packageItems );

  const ecommerce = {
    currency: currency,
    value: total,
    items: items,
  };

  return ecommerce;
};

const generateLeadInfoEcommers = (services, currency) => {

  const items = [];

  const hotelItems = services
    .filter(service => service.type === "Hotel")
    .map(hotelRoomItem);

    const tourItems = services
    .filter(service => service.type === "Tour")
    .map(tourServiceItem);

    const transferItems = services
    .filter(service => service.type ===  "Transfer")
    .map(traslateServiceItem);

    const packageItems = services
    .filter(service => service.type ===  "Package")
    .map(packageServiceItem);

  const total = hotelItems?.price

  items.push(...hotelItems, ...tourItems,...transferItems, ...packageItems );

  const ecommerce = {
    currency: currency,
    value: total,
    items: items,
  };

  return ecommerce;
};

const serviceReponseToEcommersMapperUtils = {
  hotelDetailToEcommers,
  hotelListToEcommers,
  hotelDetailRoomToEcommers,
  purchaseEcommers,
  paymentInfoEcommers,
  beginCheckoutInfoEcommers,
  generateLeadInfoEcommers

};

export default serviceReponseToEcommersMapperUtils;
