// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "hotelDetailPage.searchTitle" : "The best hotels around the world.",
    "hotelDetailPage.titleAboutHotel" : "About of hotel",
    "hotelDetailPage.titlePolicies" : "About of hotel",
    "hotelDetailPage.titleCancellationPolicies" : "Politicas of cancelation",
    "hotelDetailPage.titlePetFrendly" : "Pets",
    "hotelDetailPage.titleAmenities" : "Childrens and aditional beds",
    "hotelDetailPage.titlehasHotel" : "The accommodation offers",
    "hotelDetailPage.titleAminities":"Room amenities",
    "hotelDetailPage.titleModalRoom":"room information",
    "hotelDetailPage.titleCancelationPolicies":"Cancellation policies",
    "hotelDetailPage.hotelWhitoutResults": "At the moment it is not possible to find what you were looking for, please modify your search to continue planning your trip.",
	"hotelDetailPage.currentRoom": "Your current room"
}