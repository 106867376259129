import cardAmerican from "../../../assets/img/bank/american_express.svg"
import cardMaster from "../../../assets/img/bank/master_card.svg"
import cardPayment from "../../../assets/img/bank/payment_visa.svg"
const pciLogo= "https://cdn.majoristravel.com/banks/PCI_Logo.png";

const CardTypeSection = (props) => {
  return <>
     <div className="box-cards-payments-methods">
      <img loading="lazy" alt="" src={cardAmerican} />
      <img loading="lazy" alt="" src={cardMaster} />
      <img loading="lazy" alt="" src={cardPayment} />
      <img loading="lazy" alt="" src={pciLogo} />

    </div>
  </>;
};


export default CardTypeSection;