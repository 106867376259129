import { useEffect } from 'react';
import IdleJs from 'idle-js';

const useInactivityDetector = (onUserInactive) => {
  useEffect(() => {
    const idle = new IdleJs({
      idle: 1000 * 60 * 60, // 1 hora en milisegundos
      onIdle: () => {
        // Se activa cuando el usuario está inactivo durante 1 hora
        onUserInactive();
        window.location.reload();
      },
    });

    idle.start();

    return () => {
      idle.stop();
    };
  }, [onUserInactive]);
};

export default useInactivityDetector;