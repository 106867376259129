import PropTypes from "prop-types";

const LabelDouble = (props) => {
  const { title, text, validation, show, classTitle, classText  } = props;
  return (
    <>
      {show && (
        <div className={`container-text-duo ${classTitle}`}>
          <span className={`text-left`}>{`${title}: `}</span>
          {validation && <span className={`text-right ${classText}`}>{text}</span>}
        </div>
      )}
    </>
  );
};

LabelDouble.defaultProps = {
  validation: true,
  show: true,
  classText: "",
  classTitle: "",
};

LabelDouble.propTypes = {
  validation: PropTypes.bool,
  show: PropTypes.bool,
  classText: PropTypes.string,
  classTitle: PropTypes.string,
 
};

export default LabelDouble;
