import React, { useEffect, useState } from 'react';
import {Navbar, Container,ToggleButton, ButtonGroup} from 'react-bootstrap';
import ToggleButtonTriple from "../../buttons/ToggleButtonTriple";
import "./styles.scss";

const StickyBar = (props) => {
	const { radios, valueDefaultSelected, type } = props;
	const [radioValue, setRadioValue] = useState(valueDefaultSelected);
  const [radiosOp, setRadiosOp] = useState([]);

	const onChangeToggle = (e) => {
		const { onChange } = props;
		setRadioValue(e)
		onChange && onChange(e);
	}


	useEffect(() => {
		setRadioValue(valueDefaultSelected);
	}, [valueDefaultSelected]);

  return (
    <>
      <Navbar className="nav-bar-container" id="nav-filters">
        <Container>
          <ToggleButtonTriple 
            radios={radios}
            valueDefaultSelected={radioValue}
            onChange={onChangeToggle}
            type={type}
          />
        </Container>
      </Navbar>
    </>
  );
};

export default StickyBar;
