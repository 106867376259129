import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

function InputDropdown(props) {
    const { item, onChange, onChangeLada, onBlur, id, ladaItems, valueSelect,countrySelect, current,qa, ...rest } =
        props;
    const handleChange = (e) => {
        onChange && onChange(e.target);
    };
    const handleBlur = (e) => {
        onBlur && onBlur(e.target);
    };
    const handleChangeLada = (e) => {
        onChangeLada && onChangeLada(e.target.value);
    };

    return (
        <div className="d-block">
            <Form.Label htmlFor={id}>{item.label}</Form.Label>
            <InputGroup>
                <Form.Select qui={qa}
                    value={valueSelect+'-'+countrySelect}
                    className="select-lada"
                    onChange={handleChangeLada}
                    variant="outline-secondary"
                    id="input-group-dropdown-1"
                    name="lada"
                >
                    {ladaItems &&
                        ladaItems.map((lada) => {
                            return (
                                <option key={lada.value} value={lada.value +'-'+ lada.iso}>
                                    {lada.label}
                                </option>
                            );
                        })}
                </Form.Select>
                <Form.Control
                    type={item.type}
                    id={id}
                    {...rest}
                    aria-describedby={item.aria}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <Form.Text id={item.aria} muted>
                    {item.validate}
                </Form.Text>
            </InputGroup>
        </div>
    );
}

export default InputDropdown;
