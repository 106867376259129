const flightDataMock = {
    arrival: {
        time: "4:47 AM",
        codeIATA: "MEX",
        terminal: "T2",
        name:"Cd. México (AICM)"
    },
    departure : {
        time: "4:40 PM",
        codeIATA: "CUN",
        terminal:"",
        name:"Cancún"
    },
    flightDuration:"11h 53min",
    scale:true,
    flightSegment:1
}

export default flightDataMock;