import { useIntl } from "react-intl";
import { useEffect, useState, useRef } from "react";
import { Container, Row, Button, Col } from "react-bootstrap";
import ChipGroup from "@mzp/components/dist/dataDisplay/ChipGroup";
import { useGlobalState } from "../../../store/StoreProvider";
import { useSearchParams, useLocation } from "react-router-dom";
import hotelsValidate from "../../../validate/hotelsValidate";
import serviceListAction from "../../../actions/serviceListAction";
import serviceType from "../../../constants/serviceTypeConstant";
import Modal from "../../../components/modals/Modals";
import DrawerFilter from "../../../components/ui/DrawerFilter";
import CardContainerService from "../../../components/cards/CardContainerService";
import SkeletonCardList from "../../../components/skeleton/SkeletonCardList/SkeletonCardList";
import SkeletonCardLarge from "../../../components/skeleton/SkeletonCardLarge";
import BoxWhitoutResults from "../../../components/boxes/BoxWhitoutResults/BoxWhitoutResults";
import CardHotelLarge from "../../../components/cards/CardHotelLarge";
import CardHotelLargeB2B from "../../../components/cards/CardHotelLargeB2B";
import ToggleButtonTriple from "../../../components/buttons/ToggleButtonTriple";
import { useMediaQuery } from "react-responsive";
import breakpointConstants from "../../../constants/breakpointConstants";
import { Map } from "../../../components/Maps";
import DropdownFilterOrden from "../../../components/DropdownFilterOrden";
import useModal from "../../../hooks/useModal";
import { viewGridListConst } from "../../../constants/common";
import { SearchBoxList } from "../../../components/searchBoxs";
import SlideTopThigsToDoSection from "../../../components/section/SlideTopThigsToDoSection";
import thigsToDoUtils from "../../../utils/thigsToDoUtils";
import businessModel from "../../../constants/businessModel";
import GoogleTagManagerUtils from "../../../utils/GoogleTagManagerUtils";
import serviceReponseToEcommersMapperUtils from "../../../utils/serviceReponseToEcommersMapperUtils"
import StickyBar from "../../../components/ui/StickyBar/StickyBar";
import "./components/hotelListPage.scss";
import "./../../../utils/prototypesUtils";
import moment from "moment/moment";

const HotelListPage = (props) => {
	const { currency, pageOptions, authSession, locale, resourceSite } = useGlobalState();
	const {
		toggleListHotel: radios,
		toggleListHotelMobileList: radiosMobile,
		toggleListHotelMobileMap: radiosMobileMap,
		searchHotelParams,
		siteRoutes: pathnames,
	} = resourceSite.options;
	const intl = useIntl();
	const location = useLocation();
	const sourceMarket = "MX";
	const [searchParams] = useSearchParams();
	const [changeBookingSubmit, setChangeBookingSubmit] = useState(false);
	const [bodyRenderScroll, setBodyRenderScroll] = useState(false);

	const isMobile = useMediaQuery({ maxWidth: breakpointConstants.LG });
	const isTablet = useMediaQuery({ maxWidth: breakpointConstants.MD });
	const [showFilterBarMobile, setShowFilterBarMobile] = useState(false);
	const breakpointViewCardImpar = useMediaQuery({
		query: `(min-width: ${breakpointConstants.LG}px) and (max-width: ${breakpointConstants.XL}px)`,
	});
	const breakpointDesk = useMediaQuery({ minWidth: breakpointConstants.XL });
	const [recommend, setRecommend] = useState({});
	const [activeRecommend, setActiveRecommend] = useState(false);
	//TODO render when change path url
	const [optionsMobile, setOptionsMobile] = useState(radiosMobile);
	const [typeView, setTypeView] = useState(radios[0].value);
	const localizedUrlHotelDetails = `/${locale}${pathnames.hotel.pathname}`;
	const [params, setParams] = useState(null);
	const [show, setShow] = useState(false);
	const [filtersApply, setFiltersApply] = useState({});
	const [suggestionsDrawerFilter, setSuggestionsDrawerFilter] = useState([]);
	const [availability, setAvailability] = useState(null);
	const [hotelsFiltered, setHotelsFiltered] = useState([]);
	const [tags, setTags] = useState([]);
	const [clearInputAutocomplete, setClearInputAutocomplete] = useState(false);
	const isB2B = process.env.REACT_APP_BUSINESS === businessModel.B2B;
	const isB2C = process.env.REACT_APP_BUSINESS === businessModel.B2C;
	const isB2B2C = process.env.REACT_APP_BUSINESS === businessModel.B2B2C;
	const [hotels, sethotels] = useState({
		items: [],
		isFetching: false,
		isError:false,
		isSuccess:false,
		filters: [],
		geoPositionZone: {},
	});

	const [cardsView, setCardsView] = useState({
		total: 0,
		items: [],
		pageSize: 0,
		loading: true,
	});
	const [coordinates, setCoordinates] = useState([]);
	const divRef = useRef(null);
	const [loading, setLoading] = useState(true);
	const [showModalMap, setShowModalMap] = useState(false);
	const prevView = useRef(typeView);
	const pageOptionsTitle = useRef(pageOptions.title);
	//TODO render when change path url
	let query = {
		sort: searchParams.get("sort"),
		paxes: searchParams.get("paxes"),
		checkin: searchParams.get("checkin"),
		checkout: searchParams.get("checkout"),
		s: searchParams.get("s"),
		addDays: searchParams.get("addDays"),
		nights: searchParams.get("nights"),
	};
	if (searchParams.get("destination")) query.destination = searchParams.get("destination");
	if (searchParams.get("destinationkeyword")) query.destinationkeyword = searchParams.get("destinationkeyword");
	if (searchParams.get("hotel")) query.hotel = searchParams.get("hotel");
	if (searchParams.get("hotelkeyword")) query.hotelkeyword = searchParams.get("hotelkeyword");

	function sortByPriorityAscending(data) {
		const priorityOrder = {
			highest: 1,
			high: 2,
			medium: 3,
			low: 4,
			lowest: 5,
		};

		data.items.sort((a, b) => {
			const priorityA = priorityOrder[a.priority];
			const priorityB = priorityOrder[b.priority];
			if (priorityA > priorityB) return 1;
			else if (priorityA < priorityB) return -1;
			return 0;
		});
		return data.items;
	}


	const gridCards = (numberView, availability, newHotels, sort = true) => {
		const insideHotels = newHotels && newHotels.isFetching ? newHotels : hotels;
		let nCards = null;
		//TODO aqui crea solo el ordenamiento, pero desde arriba ya viene filtrado
		if (availability.orderBy === "recommended") {
			nCards = sortByPriorityAscending(insideHotels)

			const listGeneral = nCards.filter(h => h.mealPlan?.code !== "AI");
			const listAllInclusive = nCards.filter(h => h.mealPlan?.code === "AI");
			const listAllInclusiveMayorDiscount = listAllInclusive.sort((a, b) => {
				return a.offerSale === b.offerSale ? 0 : a.offerSale > b.offerSale ? -1 : 1;
			});
			const all = [...listAllInclusiveMayorDiscount, ...listGeneral];
			nCards = all.sort((a, b) => b.isRecommended - a.isRecommended);
		} else {
			nCards = !sort
				? insideHotels.items
				: insideHotels.items.sort((a, b) => {
					if (availability.orderBy === "desc")
						return a.price === b.price ? 0 : a.price > b.price ? -1 : 1;
					return a.price === b.price ? 0 : a.price > b.price ? 1 : -1;
				});
		}

		return {
			total: insideHotels.items.length,
			items: nCards.length < numberView ? nCards : nCards.slice(0, numberView),
			pageSize: nCards.length < numberView ? nCards.length : numberView,
			loading: insideHotels.isFetching ? false : true,
		};
	};

	const getNumbreViewCards = (page) => {
		let numberViewCards = window.innerWidth >= breakpointConstants.LG
			&& window.innerWidth < breakpointConstants.XL ? 21 : 20;
		return numberViewCards * page;
	};

	const onDrawerInputAutocompleteChange = (value, onSuccess) => {
		if (value && value !== null && value !== "") {
			const hotelsFilters = hotels.items
				.filter(
					(hotel) =>
						hotel.name.toLowerCase().includes(value.toLowerCase())
				)
				.map((hotel) => {
					return {
						id: hotel.id,
						label: hotel.name,
					};
				});
			setSuggestionsDrawerFilter(hotelsFilters);
			const newSuggetionsAutocomplete = {
				items: hotelsFilters.map((item) => {
					return {
						name: item.label,
						code: item.id,
					};
				}),
			};
			onSuccess(newSuggetionsAutocomplete);
		} else {
			applyFilters(null, "ID");
			applyFilters(null, "NAME");
		}
	};

	const onDrawerAutocompleteChange = (value) => {
		if (!value) {
			if (clearInputAutocomplete)
				setClearInputAutocomplete(false);
			applyFilters(null, "ID");
			applyFilters(null, "NAME");
			return;
		}
		var type = typeof value;
		if (type === "object") {
			applyFilters(
				{
					id: value.code,
					name: value.name,
				},
				"ID"
			)
		}
		else if (type === "string") {
			applyFilters(value, "NAME");
		}
	};

	const getHotels = async (params, availability) => {
		const query = hotelsValidate.formatQueryDestinationFromParams(params);
		if (query === null) return;
		await serviceListAction.hotels(
			{
				...query,
				locale,
				sourceMarket: sourceMarket,
				pathNext: localizedUrlHotelDetails,
				authSession,
			},
			(response) => {
				setCoordinates([
					response?.geoPositionZone?.latitude,
					response?.geoPositionZone?.longitude,
				]);
				sethotels(response);
				setCardsView(
					gridCards(
						getNumbreViewCards(availability.page),
						availability,
						response
					)
				);
				const paramsRecommend = thigsToDoUtils.prototypeItems(
					"hotel",
					intl.formatMessage({ id: "home.similar" }),
					response.items.slice(0, 10),
					true
				);
				setRecommend(paramsRecommend);
				setLoading(false);
				try {
					const ecommerce = serviceReponseToEcommersMapperUtils.hotelListToEcommers(response)
					ecommerce.item_list_name = "Lista de hoteles";// provicional hasta implementar metas
					GoogleTagManagerUtils.viewItemListEvent(ecommerce)
				} catch (error) {
					console.error('error gta-', error)
				}
			},
			(error) => {
				console.error(error);
				setRecommend({});
				sethotels({
					items: [],
					filters: [],
					isFetching: false,
					isSuccess: false,
					isError: true,
					geoPositionZone: {},
				});
				setActiveRecommend(false);
				setCardsView({
					total: 0,
					items: [],
					pageSize: 0,
					loading: false,
				});
				setLoading(false);
			},
			() => { //abort
				setLoading(true);
			}
	
		);

		console.log(hotels)
	};

	const updateMetaData = () => {
		const isHotel = query.hotel ? true : false;
		let title = document.title;
		if(isHotel){
			const destination = hotels.items.find(h => h.id === parseInt(query.hotelkeyword))?.zone?.name;
			if(destination)
				title = pageOptionsTitle.current.replace("#DESTINATION", destination);
		}
		else title = pageOptionsTitle.current.replace("#DESTINATION", query.destination);
		
		pageOptions.title = title;
		pageOptions.titlePage = title;
		document.title = title;
	}

	useEffect(() => {
		updateMetaData()
	}, [location, pageOptions.title, loading]);

	useEffect(() => {
		if (!changeBookingSubmit) {
			window.scrollTo(0, 0);
			resetInitialRender();
		} else setChangeBookingSubmit(false);
	}, [location, currency]);

	useEffect(() => {
		if (
			isMobile &&
			typeView !== viewGridListConst.MAP &&
			typeView !== viewGridListConst.GRID
		) {
			setTypeView(radios[0].value);
		}
	}, [isMobile]);

	useEffect(() => {
		if (
			(isMobile || breakpointViewCardImpar || breakpointDesk) &&
			availability != null
		) {
			setCardsView(
				gridCards(getNumbreViewCards(availability?.page), availability)
			);
		}
	}, [isMobile, breakpointViewCardImpar, breakpointDesk]);

	useEffect(() => {
		getTagsFiltersApply();
	}, [filtersApply]);

	const onClickMoreServices = () => {
		const nAvailability = { ...availability, page: availability.page + 1 };
		setAvailability(nAvailability);
		let nCardsView = {
			...cardsView,
			items: hotels.items,
			isFetching: true,
		};
		if (hotelsFiltered.length)
			nCardsView = {
				...cardsView,
				items: hotelsFiltered,
				isFetching: true,
			};
		setCardsView(
			gridCards(
				getNumbreViewCards(nAvailability.page),
				nAvailability,
				nCardsView
			)
		);
	};

	if (typeof window !== "undefined") {
		window.onscroll = function () {
			var y = window.scrollY;
			const elem = document.querySelector("#body-render");
			const rect = elem != null && elem !== undefined && elem && elem.offsetTop ? elem.offsetTop : 0;
			setBodyRenderScroll(y > rect + 10);
			const posFotter = document.querySelector(".footer")?.isVisible();
			if (showFilterBarMobile !== posFotter) setShowFilterBarMobile(posFotter);
		}
	}

	const onDrawerHandleToggle = (v) => applyFilters(v, v.code);
	const onClickOpenFilter = () => setShow((s) => !s);
	const onClickClearFilter = () => {
		setShow(false);
		setTags([]);
		setHotelsFiltered([]);
		setSuggestionsDrawerFilter([]);
		setFiltersApply({});
	};

	const getTagsFiltersApply = () => {
		var tags = [];
		Object.keys(filtersApply).forEach((key) => {
			var values = filtersApply[key];
			if (values) {
				if (Array.isArray(values)) {
					values.forEach((v) => {
						tags.push({
							key: v.getId(key),
							label: v.getValue(key).capitalize(),
							type: key,
						});
					});
				}
				else if (typeof values === "string") {
					tags.push({
						key: values.getId(key),
						label: values.getValue(key).capitalize(),
						type: key
					});
				}
			}
		});
		setTags(tags);
	};

	const onDeleteTag = (tag) => {
		tag.checked = false;
		tag.value = tag.key;
		if (tag.type === "IDNAME")
			setClearInputAutocomplete(true);
		applyFilters(tag, tag.type);
	};

	//TODO verifica cuales filtros tiene activado de "applyFilters"
	const validateFiltrerActive = () => {
		let activeFilter =
			filtersApply.NAME?.length > 0 ||
				filtersApply.MOSTUSED?.length > 0 ||
				filtersApply.STARS?.length > 0 ||
				filtersApply.CATEGORIES?.length > 0 ||
				filtersApply.MEALPLANS?.length > 0 ||
				filtersApply.PROPERTY?.length > 0 ||
				filtersApply.SERVICES?.length > 0
				? true
				: false;

		return activeFilter;
	};

	//TODO result por filtro o inicial
	const renderResultHotels = () => {
		const multiTextRender =
			hotelsFiltered.length === 0 ||
				hotelsFiltered.length === hotels.items.length
				? "general.resultsTotal"
				: "general.resultsFilter";
		return intl.formatMessage(
			{ id: multiTextRender },
			{
				count:
					hotelsFiltered.length === 0 ||
						hotelsFiltered.length === hotels.items.length
						? hotels.items.length
						: hotelsFiltered.length,
				total: hotels.items.length,
			}
		);
	};

	//TODO aplica el filtrado
	const applyFilters = (value, type) => {
		const filters = { ...filtersApply };
		switch (type) {
			case "ID":
				filters[type] = value ? value.id : null;
				break;
			case "IDNAME":
			case "NAME":
				filters[type] = value && typeof value === "string" ? value : null;
				break;
			default:
				if (!filters[type]) filters[type] = [];
				var _value = value.value.replace(type + "_", "");
				var currentIndex = filters[type].indexOf(_value);
				value.checked ? filters[type].push(_value) : filters[type].splice(currentIndex, 1);
				break;
		}

		let sort = true;
		let items = hotels.items
			.filter(
				(h) =>
					!filters.NAME ||
					h.name.toLowerCase().includes(filters.NAME.toLowerCase())
			)
			.filter(
				(h) =>
					!filters.MOSTUSED ||
					!filters.MOSTUSED?.length ||
					filters.MOSTUSED.includes(h.type.clearValue()) ||
					filters.MOSTUSED.includes(h.bestRate?.mealPlan.clearValue())
			)
			.filter(
				(h) =>
					!filters.STARS ||
					!filters.STARS?.length ||
					filters.STARS.includes(h.category.toString())
			)
			.filter(
				(h) =>
					!filters.CATEGORIES ||
					!filters.CATEGORIES?.length ||
					filters.CATEGORIES.includes(h.categoryString.clearValue())
			)
			.filter(
				(h) =>
					!filters.MEALPLANS ||
					!filters.MEALPLANS?.length ||
					filters.MEALPLANS.includes(h.bestRate?.mealPlan.clearValue())
			)
			.filter(
				(h) =>
					!filters.PROPERTY ||
					!filters.PROPERTY?.length ||
					filters.PROPERTY.includes(h.type.clearValue())
			)
			.filter(
				(h) =>
					!filters.SERVICES ||
					!filters.SERVICES?.length ||
					filters.SERVICES.some((s) =>
						h.amenities.map((a) => a.clearValue()).includes(s.clearValue())
					)
			);

		if (filters.ID) {
			sort = false;
			items = items.filter((h) => h.id === value.id);
			filters.ID = null;
			filters.NAME = null;
			filters.IDNAME = value.name;
		}
		else filters.IDNAME = null;
		setFiltersApply(filters);
		setHotelsFiltered(items);

		const nAvailability = { ...availability, page: 1 };
		const nCardsView = { ...cardsView, items: items, isFetching: true };
		setAvailability(nAvailability);
		setCardsView(
			gridCards(
				getNumbreViewCards(nAvailability.page),
				nAvailability,
				nCardsView,
				sort
			)
		);
	};

	const resetInitialRender = (auxQuery = null) => {
					// add date range days form url
		query  = hotelsValidate.addRageDateFromURl(query);
		const nAuxQuery = auxQuery == null ? query : auxQuery;
		const auxParams = {
			...hotelsValidate.paramsListFormat(
				nAuxQuery,
				searchHotelParams.paramsRoom.rules
			),
			currency: currency?.code,
			locale,
		};
		setParams(auxParams);
		const auxAvailability = hotelsValidate.paramsListFormatAvailability(nAuxQuery);
		setAvailability(auxAvailability);
		setRecommend({});
		sethotels({
			items: [],
			isFetching: false,
			filters: [],
		});
		setCardsView({
			total: 0,
			items: [],
			pageSize: 0,
			loading: true,
		});

		setFiltersApply({});
		setSuggestionsDrawerFilter([]);
		setHotelsFiltered([]);
		getHotels(auxParams, auxAvailability);
	};

	useEffect(() => {
		if (typeView === viewGridListConst.MAP) setOptionsMobile(radiosMobile)
		else if (typeView === viewGridListConst.GRID) setOptionsMobile(radiosMobileMap)
		else if (typeView === viewGridListConst.ROW) setOptionsMobile(radiosMobileMap)
		else setOptionsMobile(radiosMobile);
	}, [typeView]);

	useEffect(() => {
		isB2B && radios.forEach((item) => {
			if (item.value === viewGridListConst.ROW) {
				radiosMobile[0] = item;
			}
		})
	}, []);

	const onChangeTypeView = (value) => {
		if (value === viewGridListConst.MAP) {
			divRef.current.scrollIntoView({ behavior: 'smooth' });
			setShowModalMap(true);
		}
		setTypeView(prev => {
			prevView.current = prev;
			return value;
		});
	};

	const onChangeTypeViewMobile = (value) => {
		switch (value) {
			case viewGridListConst.FILTER:
				onClickOpenFilter();
				value = isB2B ? viewGridListConst.ROW : viewGridListConst.GRID;
				break;
			case viewGridListConst.MAP:
				divRef.current.scrollIntoView({ behavior: "smooth" });
				setShowModalMap(true);
				break;
		}

		setTypeView(prev => {
			prevView.current = prev;
			return value;
		});
	};

	const onChangeBooking = (response) => {
		setChangeBookingSubmit(true);
		if (typeView === viewGridListConst.MAP) {
			isB2B && setTypeView(viewGridListConst.ROW);
			isB2C && setTypeView(viewGridListConst.GRID);
			isB2B2C && setTypeView(viewGridListConst.GRID);
		}
		const { type, label, keyword } = response.inputAutoValueStart;
		const auxQuery = {
			sort: null,
			paxes: hotelsValidate.paxesQuery(response.rooms),
			checkin: response.startDate,
			checkout: response.endDate,
			inputAutoValueStart: response.inputAutoValueStart
		};
		if (type == "Hotel") {
			auxQuery.hotel = label;
			auxQuery.hotelkeyword = keyword;
		}
		if (type == "Destination") {
			auxQuery.destination = label;
			auxQuery.destinationkeyword = keyword;
		}
		resetInitialRender(auxQuery);
		try {
			GoogleTagManagerUtils.searchEvent(response, serviceType.HOTEL)
		} catch (error) {
			console.error('GTM', error)
		}
	};

	const titleOrderBy = () => {
		let titleOrder = "seleccionar";
		if (availability) {
			resourceSite.options.orderBy.map((item, index) => {
				if (item.value === availability.orderBy) {
					titleOrder = item.name;
				}
			});
		}
		return titleOrder;
	};

	const gridViewChange = (sort) => {
		const nAvailability = { ...availability, orderBy: sort };
		let nCardsView = { ...cardsView, items: hotels.items, isFetching: true };
		if (hotelsFiltered.length)
			nCardsView = { ...cardsView, items: hotelsFiltered, isFetching: true };
		setAvailability(nAvailability);
		setCardsView(
			gridCards(
				getNumbreViewCards(availability.page),
				nAvailability,
				nCardsView
			)
		);
	};

	const textModal = {
		text: [
			{
				title: intl.formatMessage({ id: "general.cotiza.2" }),
				text: intl.formatMessage({ id: "general.cotiza" }),
				redirect: false,
			},
		],
		btn: intl.formatMessage({ id: "general.accept" }),
		timer: process.env.REACT_APP_HOTEL_SESSION_LIST,
	};

	const buttonFilter = (props) => {
		if (cardsView.loading) return;
		const { buttonFloat, buttonShowIcon, buttonShowText = true } = props;
		return <DrawerFilter
			filters={hotels.filters}
			selecteds={tags.map((tag) => tag.key)}
			suggestions={suggestionsDrawerFilter}
			onAutocompleteChange={onDrawerAutocompleteChange}
			onInputAutocompleteChange={onDrawerInputAutocompleteChange}
			show={show}
			label={intl.formatMessage({ id: "filtro.nameHotel" })}
			onClickOpenFilter={onClickOpenFilter}
			onClickClearFilter={onClickClearFilter}
			handleToggle={onDrawerHandleToggle}
			clearInputAutocomplete={clearInputAutocomplete}
			buttonFloat={buttonFloat}
			buttonShowIcon={buttonShowIcon}
			buttonShowText={buttonShowText}
		/>
	}

	const handleCloseModal = () => {
		setShowModalMap(false);
		setTypeView(prevView.current);
	}

	const isFinish = !cardsView.loading && cardsView.pageSize > 0;
	useModal(textModal, isFinish);

	return (
		<div id="body-render">
			<div ref={divRef}></div>
			<SearchBoxList
				bodyRenderScroll={bodyRenderScroll}
				params={params}
				hideBtn={cardsView.loading}
				onChangeBooking={onChangeBooking}
				type={serviceType.HOTEL}
			/>
			<Container className="mt-3 mb-3">
				{cardsView.loading &&
					(typeView === viewGridListConst.GRID ? (
						<Row>
							<SkeletonCardList
								number={
									window.innerWidth >= breakpointConstants.LG &&
										window.innerWidth < breakpointConstants.XL ? 9 : 8
								}
							/></Row>
					) : (
						<Row className="col-hotel">
							<div>
								<SkeletonCardLarge />
							</div>
							<div className="mt-3">
								<SkeletonCardLarge />
							</div>
						</Row>
					))}
				{!loading && cardsView.pageSize > 0 && (
					<Row className="list-filters">
						<Col className="container-count-results">
							<span className="text-nowrap total-hotel">
								{!cardsView.loading && renderResultHotels()}
							</span>
						</Col>
						{!isTablet && <Col className="container-views">
							<ToggleButtonTriple
								radios={radios}
								type="checkbox"
								valueDefaultSelected={typeView}
								onChange={onChangeTypeView}
							/>
						</Col>}
						<Col className="container-filter-orden">
							<DropdownFilterOrden
								items={resourceSite.options.orderBy}
								titleDefault={titleOrderBy()}
								active={availability.orderBy}
								onSelect={gridViewChange}
							/>
						</Col>
					</Row>
				)}
				<ChipGroup
					items={tags}
					sxContent={{ pb: "30px" }}
					onDelete={onDeleteTag}
				/>

				{!loading && (hotels?.isSuccess || hotels?.isError) && hotels?.items?.length == 0 && (
					
					<Row>
						<Col>
							<BoxWhitoutResults
								text={intl.formatMessage({
									id: "hotelListPage.hotelWhitoutResults",
								})}
							/>
						</Col>
					</Row>
				)}
				{typeView !== viewGridListConst.MAP && cardsView && (
					<>
						<Row id="hotel-list-section" qui="con hotel-list">
							{cardsView.items.map((card, index) => {
								if (typeView === viewGridListConst.GRID)
									return (
										<CardContainerService
											key={index}
											item={card}
											currency={currency?.code}
											type={serviceType.HOTEL}
											txt={intl.formatMessage({ id: "text.pernight" })}
											border={card.isRecommended}
										/>
									);
								return (
									<Col xs={12} key={index}>
										{isB2B ? (
											<div key={index} className="col-hotel">
												<CardHotelLargeB2B
													service={card}
													currency={currency?.code}
													type={serviceType.HOTEL}
													locale={locale}
													sourceMarket={sourceMarket}
													authSession={authSession}
													parameters={params}
													pathnames={pathnames}
													border={card.isRecommended}
												/>
											</div>
										) : (
											<CardHotelLarge
												service={card}
												currency={currency?.code}
												type={serviceType.HOTEL}
												border={card.isRecommended}
											/>
										)}
									</Col>
								);
							})}
						</Row>
						{buttonFilter({ buttonFloat: true, buttonShowIcon: false })}
					</>
				)}
				{typeView !== viewGridListConst.MAP &&
					cardsView.pageSize < cardsView.total && (
						<Row>
							<Col
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									margin: "10px",
								}}>
								<Button
									variant="primary"
									className="button-more-hotels justify-content-md-center"
									onClick={onClickMoreServices}>
									{intl.formatMessage({ id: "hotelListPage.buttonTextMore" })}
								</Button>
							</Col>
						</Row>
					)}
				{activeRecommend && <SlideTopThigsToDoSection options={recommend} />}
			</Container>

			{typeView === viewGridListConst.MAP && cardsView.items && (
				<Modal
					className="modal-map"
					title={params?.inputAutoValueStart?.label}
					show={showModalMap}
					fullscreen={true}
					handleCloseModal={handleCloseModal}>
					<Map
						apiKey={process.env.REACT_APP_MAP_TOKEN}
						styleContent={{ height: "100%" }}
						markersData={validateFiltrerActive() ? cardsView.items : hotels.items}
						coordInitial={{ lat: coordinates[0], lng: coordinates[1] }}
						currency={currency.code}
						locale={locale}
						serviceType={serviceType.HOTEL}
						zoomChangeChips={isMobile ? 14 : 12}
						zoomDefault={11}
						limit={true}
						radiusPoint={"5000"}
						pointInterest={[""]}
						txt={intl.formatMessage({ id: "text.pernight" })}
					/>
					{buttonFilter({ buttonFloat: false, buttonShowIcon: true, buttonShowText: !isMobile })}
				</Modal>
			)}

			{!loading && cardsView.pageSize > 0 && !showFilterBarMobile && isTablet &&
				<StickyBar
					radios={optionsMobile}
					valueDefaultSelected={typeView}
					onChange={onChangeTypeViewMobile}
					type="checkbox"
				/>
			}
		</div>
	);
};
export default HotelListPage;
