import initialState from "./initialState";
import types from "../constants/reducers/authSessionConstants";

const authSessionReducer = (state = initialState.authSession, action) => {
    switch(action.type) {
        case types.AUTH_FETCHING:
            return {...initialState.authSession, ...action.authSession}
        case types.AUTH:
            return action.authSession;
        case types.AUTH_ERROR:
            return action.authSession;
        default:
            return state;
    }
}

export default authSessionReducer;