export default {
  "form.name": "Name",
  "form.lastname": "Last name",
  "form.phone": "Phone",
  "form.mail": "Email*",
  "form.airline": "Aeroline",
  "form.numFlight": "Flight number",
  "form.validation.empty": "the field cannot be empty",
  "form.validation.text": "text only",
  "form.validation.numText": "only text and numbers",
  "form.terms":
    "I have read and accept the Privacy Notice, Terms and Conditions.",
  "form.termsMsg": "You must accept terms and conditions.",
  "form.Msg": "Verify that the information entered is valid.",
  "form.validate": "The email has been sent successfully",
  "form.capcha": "Validate captcha",
};
