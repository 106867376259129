import { Col } from "react-bootstrap";
import { BasicForm } from "../form/groupForm/BasicForm/BasicForm";
import BgWrapper from "@mzp/commons/dist/layout/BgWrapper";
import "./components/formAffiliation.scss";
export const FormAffiliationSection = (props) => {
  const { options } = props;
  return (
    <div className="affiliation-container" id="affiliation-container">
      <Col className="col-form  px-3 py-4 col-12 col-md-6 col-lg-6 col-xl-6">
        <BasicForm form={options.form} />
      </Col>
      <Col className="col-form d-none d-sm-block ">
        <BgWrapper {...options} />
      </Col>
    </div>
  );
};
