export default {
	"payment.status.paid": "Paid",
	"payment.status.notpaid": "Not paid",
	"payment.status.error": "Error",
	"payment.status.cancelled": "Cancelled",
	"payment.status.refused": "Refused",
	"payment.status.insuficentfunds": "Insuficent funds",
	"payment.status.returned": "Returned",
	"payment.status.pendingsettlement": "Pending settlement",
	"payment.status.pending": "Pending",
	"payment.status.notfound": "There seems to have been a problem processing your payment, please try again.",
	"payment.status.error.message": "We encountered a problem processing your payment, please try another payment method.",
};
