import { localStorageConst } from "../constants/common";
import businessModel from "../constants/businessModel";

const useGetNameAuthStorage = () => {
    
    const business = process.env.REACT_APP_BUSINESS.toUpperCase();

    switch (business) {
        case businessModel.B2C:
            return localStorageConst.AUTH;
            break;
        case businessModel.B2B:
            return localStorageConst.AUTHB2B;
            break;
        case businessModel.B2B2C:
            return localStorageConst.AUTHB2B2C;
            break;
        default:
            return localStorageConst.AUTH
            break;
    }
}

export default useGetNameAuthStorage;