import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import Modals from "../../modals/Modals";
import { TimelineFlight } from "../../feedback";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import "./components/style.scss";

const BoxDetailBaggageFlight = (props) => {
  const {
    fullscreen,
    show,
    handleCloseModalBaggage,
    scale,
    selectType,
    selectTypeCategory,
  } = props;
  const intl = useIntl();

  const changeTypeModal = (type) => {
    const { changeType } = props;
    changeType && changeType(type);
  };

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Modals
      fullscreen={fullscreen}
      show={show}
      size="lg"
      showClose={false}
      handleCloseModal={handleCloseModalBaggage}
      title="Detalle de equipaje"
    >
      <div className="container-baggage">
        <div className="d-none d-lg-block">
          <Row className="">
            <Col className="">
              <div className="baggage-wrap ">
                <div className="titleBaggage">
                  1 objeto personal y 1 equipaje de mano <strong>light</strong>
                </div>
                <div className="bag-img">
                  <img loading="lazy"
                    src="https://cms.volaris.com/globalassets/1nextgen/images/baggage_10kg_close.png"
                    alt="equipaje"
                  />
                </div>
                <div className="text-info-baggage">
                  <p>
                    {" "}
                    El objeto personal debe caber debajo del asiento frente a ti
                    y el equipaje de mano debe medir máximo{" "}
                    <strong>55 x 40 x 25 cm</strong> (largo x ancho x alto). La
                    suma del peso del objeto personal y el equipaje de mano no
                    debe exceder los{" "}
                  </p>
                  <p>
                    {" "}
                    Si tu equipaje excede los 10 kgs o la dimensión permitida,
                    tendrás que pagar un monto extra por equipaje por
                    documentarlo en el aeropuerto.{" "}
                  </p>
                </div>
              </div>
            </Col>
            <Col className="">
              <div className="baggage-wrap ">
                <div className="titleBaggage">
                  1 objeto personal y 1 equipaje de mano{" "}
                  <strong>estándar</strong>
                </div>
                <div className="bag-img">
                  <img loading="lazy"
                    src="https://cms.volaris.com/globalassets/1nextgen/images/StandardBag_Modal_Details_NoSize.png"
                    alt="equipaje"
                  />
                </div>
                <div className="text-info-baggage">
                  <p>
                    {" "}
                    El objeto personal debe caber debajo del asiento frente a ti
                    y el equipaje de mano debe medir máximo{" "}
                    <strong>55 x 40 x 25 cm</strong> (largo x ancho x alto). La
                    suma del peso del objeto personal y el equipaje de mano no
                    debe exceder los <trong>20 kg</trong>
                  </p>
                  <p>
                    {" "}
                    Tienes la seguridad de que tu equipaje de mano viajará
                    contigo en la cabina{" "}
                  </p>
                </div>
              </div>
            </Col>
            <Col className="">
              <div className="baggage-wrap ">
                <div className="titleBaggage">Equipaje documentado</div>
                <div className="bag-img ">
                  <img loading="lazy"
                    className="complete-docu"
                    src="https://cms.volaris.com/globalassets/1nextgen/images/checked-bag-modal-details.png"
                    alt="equipaje"
                  />
                </div>
                <div className="text-info-baggage">
                  <p>
                    {" "}
                    Hasta <trong>25 kg</trong> y{" "}
                    <strong>158 cm lineales (largo + ancho + alto)</strong>
                  </p>
                  <p>
                    {" "}
                    Las maletas facturadas son aquellas que viajan en la bodega
                    de carga del avión. Debe facturarlos en nuestros mostradores
                    del aeropuerto.{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="d-block d-lg-none">
          <Carousel activeIndex={index} onSelect={handleSelect} controls={false}>
            <Carousel.Item>
            <div className="baggage-wrap ">
                <div className="titleBaggage">
                  1 objeto personal y 1 equipaje de mano <strong>light</strong>
                </div>
                <div className="bag-img">
                  <img loading="lazy"
                    src="https://cms.volaris.com/globalassets/1nextgen/images/baggage_10kg_close.png"
                    alt="equipaje"
                  />
                </div>
                <div className="text-info-baggage">
                  <p>
                    {" "}
                    El objeto personal debe caber debajo del asiento frente a ti
                    y el equipaje de mano debe medir máximo{" "}
                    <strong>55 x 40 x 25 cm</strong> (largo x ancho x alto). La
                    suma del peso del objeto personal y el equipaje de mano no
                    debe exceder los{" "}
                  </p>
                  <p>
                    {" "}
                    Si tu equipaje excede los 10 kgs o la dimensión permitida,
                    tendrás que pagar un monto extra por equipaje por
                    documentarlo en el aeropuerto.{" "}
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
            <div className="baggage-wrap ">
                <div className="titleBaggage">
                  1 objeto personal y 1 equipaje de mano{" "}
                  <strong>estándar</strong>
                </div>
                <div className="bag-img">
                  <img loading="lazy"
                    src="https://cms.volaris.com/globalassets/1nextgen/images/StandardBag_Modal_Details_NoSize.png"
                    alt="equipaje"
                  />
                </div>
                <div className="text-info-baggage">
                  <p>
                    {" "}
                    El objeto personal debe caber debajo del asiento frente a ti
                    y el equipaje de mano debe medir máximo{" "}
                    <strong>55 x 40 x 25 cm</strong> (largo x ancho x alto). La
                    suma del peso del objeto personal y el equipaje de mano no
                    debe exceder los <trong>20 kg</trong>
                  </p>
                  <p>
                    {" "}
                    Tienes la seguridad de que tu equipaje de mano viajará
                    contigo en la cabina{" "}
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
            <div className="baggage-wrap ">
                <div className="titleBaggage">Equipaje documentado</div>
                <div className="bag-img ">
                  <img loading="lazy"
                    className="complete-docu"
                    src="https://cms.volaris.com/globalassets/1nextgen/images/checked-bag-modal-details.png"
                    alt="equipaje"
                  />
                </div>
                <div className="text-info-baggage">
                  <p>
                    {" "}
                    Hasta <trong>25 kg</trong> y{" "}
                    <strong>158 cm lineales (largo + ancho + alto)</strong>
                  </p>
                  <p>
                    {" "}
                    Las maletas facturadas son aquellas que viajan en la bodega
                    de carga del avión. Debe facturarlos en nuestros mostradores
                    del aeropuerto.{" "}
                  </p>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </Modals>
  );
};

export default BoxDetailBaggageFlight;
