import { isAfter, isBefore, isEqual } from "date-fns";
import guestsUtils from "../utils/selectPersonsUtils";
import dateUtils from "../utils/dateUtils";
import orderByFilter from "../mock/filters/orderByFilter";

const convertStringToNumberWithDefault = (value, _valueReturn) => {
	let number = _valueReturn;
	try {
		number = parseInt(value);
	}
	catch (_ex) {
		number = _valueReturn;
	}
	return number;
}
const convertStringToNumber = (value) => {
	let number = 0;
	try {
		number = parseInt(value);
	}
	catch (_ex) {
		number = 0;
	}
	return number;
}
const getRoomChildrenYearsToQuery = (room, rules, _pax) => {
	if (room.child > 0) {
		if (_pax[1]) {
			const _years = _pax[1].split(",")
			let years = [];
			// eslint-disable-next-line eqeqeq
			if (_years.length == _pax[1])
				years = _years.map(year => {
					// eslint-disable-next-line eqeqeq
					const nChild = rules.childYear.find(_child => _child == year);
					return nChild !== undefined && year >= nChild ?
					convertStringToNumber(year) : rules.childYear[0]
				});
			else {
				for (let i = 0; i < room.child; i++) {
					// eslint-disable-next-line eqeqeq
					years[i] = _years[i] && rules.childYear.find(_child => _child == _years[i]) !== undefined ? convertStringToNumber(_years[i]) : rules.childYear[0];
				}
			}
			room.childYears = years;
		}
	}

	return room
}
const formatList = (data, query, dictionary) => {
	const items = data.length ? data.map(d => {
		return {
			...d,
            id: d.id,
            name: d.name,
            keyword: d.id,
			typeCategory: d.categoryType,
            currency: d.rate.currency,
            zone: {
                name: "",
                keyword: "",
            },
			bestRate:{
				totalWithOffersPerNight: d.rate.totalWithOutOffers,
				currency: d.rate.currency,
                totalDiscounted: 0,
                discountRate: 0,
                totalDifferenceOverLowPrice: 0,
                totalPerNight:  d.rate.total
			},
            destination: {
                name: "",
                keyword: "",
            },
			photos:[
				{
				name: d.image.name,
				url: d.image.code,
			  },
			],
			hasOffers: d.rate.hasOffers,
            offerSale: d.rate.totalDiscounted ? `${d.rate.totalDiscounted }% ${dictionary.discount}` : "",
            // pricewithoutOffer: d.rate.hasOffers ? d.bestRate.totalWithOffers : null,
            price: d.rate.totalWithOutOffers,
            taxesIncluded: true,
            gallery:
                d.image && d.image.length
                    ? d.image.map((img) => {
                          return {
                              name: img.title,
                              photoUris: img.code,
                          };
                      })
                    : [],
        };
	}) : []
	return items;
}

const paramsListFormat = (query, rules) => {
	const params = {
        inputAutoValueStart: null,
        inputAutoValueEnd: null,
    };

	const checkinStatic = new Date().toLocaleDateString('sv');
	const checkoutDate = new Date();
	checkoutDate.setDate(checkoutDate.getDate() + 2);
	const checkOutStatic = checkoutDate.toLocaleDateString('sv');
	params.startDate = checkinStatic;
	params.endDate = checkOutStatic;

	if (dateUtils.valiteDateFormat(query.checkin) && dateUtils.valiteDateFormat(query.checkout)) {
		let checkin = dateUtils.getDateforFormat(query.checkin);
		let checkout = dateUtils.getDateforFormat(query.checkout);
		if (isBefore(checkin, checkout)) {
			if ((isEqual(checkin, new Date()) || isAfter(checkin, new Date())) &&
				isAfter(checkout, new Date())) {
				params.startDate = query.checkin;
				params.endDate = query.checkout;
			}
		}
	}

	if (query.paxes) {
		const rooms = [];
		const _rooms = query.paxes.split("!")
		if (_rooms.length > 0) {
			_rooms.forEach(_room => {
				const room = {}
				const _pax = _room.split(":")
				const _guests = _pax[0].split("-")
				const adult = _guests[0] && (convertStringToNumberWithDefault(_guests[0], rules.adultDefaultRoom));
				const child = _guests[1] && (convertStringToNumberWithDefault(_guests[1], rules.childMinforRoom));
				const infant = _guests[2] && (convertStringToNumberWithDefault(_guests[2], rules.infantMinforRoom));
				room.adult = adult >= rules.adultMinforRoom && adult <= rules.adultMaxforRoom ? adult : rules.adultDefaultRoom;
				room.child = child >= rules.childMinforRoom && child <= rules.childMaxforRoom ? child : rules.childMinforRoom;
				room.infant = infant >= rules.infantMinforRoom && infant <= rules.infantMaxforRoom ? infant : rules.childMinforRoom;

				rooms.push(getRoomChildrenYearsToQuery(room, rules, _pax));
			});
			if (guestsUtils.totalPeople(rooms) <= rules.personsTotal && rooms.length <= rules.roomsMax)
				params.rooms = rooms;
		}
	}

	if (query.destination && query.destinationkeyword) {
		params.inputAutoValueStart = {
			label: query.destination,
			keyword: query.destinationkeyword,
			type: "Destination"
		}
	}
	else if (query.airport && query.airportkeyword) {
		params.inputAutoValueStart = {
			label: query.airport,
			keyword: query.airportkeyword,
			type: "Airport"
		}
	}

    if (query.arrival) {
		params.inputAutoValueEnd = {
			label: query.arrival,
			keyword: query.arrival,
			type: "Destination"
		}
	}

	return params
}

const paramsListFormatAvailability = (query) => {
	return {
		orderBy: query.sort && orderByFilter.findIndex(f => f.value.toLocaleLowerCase() === query.sort.toLocaleLowerCase())
			? query.sort.toLocaleLowerCase() : orderByFilter[0].value,
		categories: null,
		page: 1
	}
}

const transfersValidate = {
    paramsListFormatAvailability,
	paramsListFormat,
	formatList,
}

export default transfersValidate;