export default {
  "checkout.titlePresentation": "Almost there! Fill in your personal information and complete your purchase",
  "checkout.form.EditTitle": "Guest Information",
  "checkout.form.RoomText": "Room",
  "checkout.form.step1Button": "Show payment methods",
  "checkout.form.step2Button": "Edit information",
  "checkout.form.title": "Who will be the holder of the reservation?",
  "checkout.form.subtitle": "Will be responsible for booking activities, checking in and out at the hotel.",
  "checkout.form.mail": "Where do we send your tickets?",
  "checkout.form.phone": "Email and telephone are essential to manage your reservation and receive important information about your trip.",
  "checkout.formStripe.title": "Enter your card details",
  "checkout.formStripe.submit": "Pay",
  "checkout.formStripe.loading": "Loading payment methods...",
  "checkout.formRules.errorCheckRate": "The price of the room expired",
  "checkout.formRules.errorCheckRateButton": "Requote",
  "checkout.formRules.errorFraud": "Check your email or phone, they are written correctly",
  "checkout.formRules.important": "Important",
  "checkout.formRules.errorGeneralPhoneEmail": "oops! an error occurred, check that it is a valid email or phone",
  "checkout.formRules.errorGeneralForm": "Check that the information is correctly written",
  "checkout.formRules.paymentMethodDisabled": "Payment method not available, please contact the administrator",
    "checkout.formRules.warningDuplicateHolder":
    "The names of the holders can't be repeated, please enter a different name in each of the rooms holders",
  "checkout.form.special": "Special requests  (Opcional)",
  "checkout.form.special.text": "Special/accessibility requests are not guaranteed. If you don't hear back from the property, you may want to contact them directly to confirm. The property may charge a fee for certain special requests.",
  "checkout.titleImportantInformation": "Important information",
  "checkout.numFlight": "Numero de vuelo",
  "checkout.form.data.transfer": "Transfer data ",
  "checkout.terminal": "Terminal",
  "checkout.pickUp": "Pick up",
  "checkout.roomHolder": "Room holder",
  "checkout.cart.1": "{count , plural, one {Your shopping cart expires in  a # minute } other  {Your shopping cart expires in # minutes }}",
  "checkout.cart.2": "Your session is about to expire",
  "checkout.cart.3": "Your session expired",
  "checkout.cart.4": "Due to lack of activity your session has expired",
  "checkout.confirmButtonText": "Ok",
  "checkout.Continuar": "Continue",
  "checkout.ticket.subtotal": "Subtotal",
  "checkout.cardPaxes.title": "Additional charges",
  "checkout.cardPaxes.description": "The accommodation will charge you when you arrive at your destination.",
  "checkout.cardPaxes.information.title": "Taxes not included",
  "checkout.cardPaxes.information.description": "Total charges",
  "checkout.terms": "I have read and accept the purchase conditions, privacy policy and change and cancellation policy.",
  "checkout.att": " Important! ",
  "checkout.booking": "We received an error while processing your payment.",
  "checkout.try": "Please try again",
  "checkout.confirmed": "Confirmed",
  "checkout.notConfirmed": "Confirmation pending",
  "checkout.reservation.status": "Reservation status",
  "checkout.summary.labelCheckRate": "Public rate payment",
  "checkout.summary.labelCheckRateFalse": "Net fee payment",
  "checkout.deleted": "Canceled!",
  "checkout.file": "Your file has been canceled.",
  "checkout.yes": "Yes!",
  "checkout.cancel": "Cancel",
  "checkout.sure": "Are you sure you want to cancel?",
  "checkout.yes.deleted": "Yes!",
  "checkout.resend": "sent!",
  "checkout.resend.file": "Your file has been sent.",
  "checkout.sureVoucher": "Are you sure you want to resend voucher?",
  "checkout.cancel.reservation": "Cancel reservation",
  "checkout.resend": "Resend voucher",
  "checkout.reservation": "Reservation",
  "checkout.cancelationLimit": "Cancelation limit",
  "checkout.cancelationPayment": "Limit Payment",
  "checkout.airport": "Airport",
  "checkout.commodation": "Commodation",
  "checkout.startingPoint": "Starting point",
  "checkout.arrivalPoint": "Arrival point",
  "checkout.vehicleCapacity": "Vehicle capacity",
  "checkout.transferFor": "Transfer for",
  "checkout.person": "person",
	"checkout.clientReference": "Reservation code",
};
