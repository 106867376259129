import orderByFilter from "../mock/filters/orderByFilter";
import dateUtils from "../utils/dateUtils";
import { isAfter, isBefore, isEqual } from "date-fns";
import guestsUtils from "../utils/selectPersonsUtils";
import roomsDetailsMocks from "../mock/hotels/roomsDetailsMocks";
import hotelDetailsMocks from "../mock/hotels/hotelDetailsMocks";

const convertStringToNumber = (value) => {
	let number = 0;
	try {
		number = parseInt(value);
	}
	catch (_ex) {
		number = 0;
	}
	return number;
}

const convertStringToNumberWithDefault = (value, _valueReturn) => {
	let number = _valueReturn;
	try {
		number = parseInt(value);
	}
	catch (_ex) {
		number = _valueReturn;
	}
	return number;
}


const formatList = (data, query) => {
	const {queryHotel} = query;
	const items = data.length ? data.map(d => {
		return {
            ...d,
            id: d.id,
            name: d.name,
            keyword: d.id,
		
            currency: d.bestRate.currency,
            mealPlan: {
                name: d.bestRate.mealPlan,
                keyword: "",
            },
            zone: {
                name: "",
                keyword: "",
            },
            destination: {
                name: d.address,
                keyword: "",
            },
            offerSale: d.bestRate.hasOffers ? `${d.bestRate.discountRate}% descuento` : "",
            pricewithoutOffer: d.bestRate.hasOffers ? d.bestRate.totalWithOffers : null,
            price: d.bestRate.totalPerNight,
			mealPlanCode:d.bestRate.mealPlanCode,
            taxesIncluded: true,
            description: d.shortDescription,
            gallery:
                d.photos && d.photos.length
                    ? d.photos.map((img) => {
                          return {
                              name: img.name,
                              photoUris: img.url,
                          };
                      })
                    : [],
            url: `${query.pathNext}${d.id}?itinerary=${queryHotel.itinerary}&origin=${queryHotel.origin}&destination=${queryHotel.destination}&checkin=${query.checkIn}&checkout=${query.checkOut}&paxes=${queryHotel?.paxes}`,
        };
	}) : []
	return items;
}

const getMockData = () => {
	var min = 0;
	var max = roomsDetailsMocks.newRooms.length - 1;
	var random = Math.floor(Math.random() * (max - min + 1) + min);
	var bed = roomsDetailsMocks.newRooms[random].amenityBasic.bed;
	return {bed};
};

const formatDetails = (d, query) => {
	let rooms = [];
	const totalPersons = totalOccupancies(query.occupancies);
	d.rooms.forEach((room) => {
		var mockData = getMockData();

		var viewItems = room.amenities && room.amenities.length ? room.amenities.find(a => a.name === "Vista" || a.name === "View") : null;
		var view = viewItems && viewItems.items && viewItems.items.length ? viewItems.items[0] : "Al mar";

		var bedsItems = room.amenities && room.amenities.length ? room.amenities.find(a => a.name === "Cama" || a.name === "Bed") : null;
		var bed = bedsItems && bedsItems.items && bedsItems.items.length ? bedsItems.items[0] : mockData.bed;		
		
		var wifiItems = room.amenities && room.amenities.length ? room.amenities.find(a => a.name === "Internet" || a.name === "Wifi") : null;
		var hasWifi = (wifiItems && wifiItems.items && wifiItems.length > 0) || (Math.random() < 0.5);

		var aaItems = room.amenities && room.amenities.length ? room.amenities.find(a => a.name === "AA" || a.name === "AireAcondicionado") : null;
		var hasAA = (aaItems && aaItems.items && aaItems.length > 0) || (Math.random() < 0.5);

		var phoneItems = room.amenities && room.amenities.length ? room.amenities.find(a => a.name === "Telefono" || a.name === "Phone") : null;
		var hasPhone = (phoneItems && phoneItems.items && phoneItems.length > 0) || (Math.random() < 0.5);

		//Mock for testing
		room.amenities = roomsDetailsMocks.addons;

		room.ratesGrouped.forEach((rate) => {	
			var _room = {
				id: rate.id,
				externalId: room.externalId,
				name: room.name,
				cancellationPolicies: rate.isRefundable ? rate.cancellationPolicies : [rate.refundable],
				isRefundable: rate.isRefundable,
				refundable: rate.refundable,				
				photos: room.photos.map((photo) => {
					return {
						name: photo.name,
						photoUris: photo.url,
					};
				}),
				amenityBasic: {
					bed: bed,
					hasWifiFree: hasWifi,
					hasAA: hasAA,
					hasPhone: hasPhone,
				},				
				amenities: room.amenities,
				mealPlan: {
					code: rate.mealPlan,
					name: rate.mealPlan,
				},
				//TODO Cambiar aqui
				upgradeRate: rate.totalDifferenceOverLowPrice > 0 ? rate.totalDifferenceOverLowPrice / totalPersons: 0,
				rate: rate.totalPerNight,
				currency: rate.currency,
				view: view				
			};
			rooms.push(_room);
		});
	})
	return d ?  {
		...d,
		address: d.addres,
		city: d.city,
		country: d.country,
		aboutOfHotel:d.description,
		id:d.externalId,
		gallery:
			d.gallery && d.gallery.length
			? d.gallery.map((img) => {
				return {
					name: img.name,
					photoUris: img.url,
				};
			})
			: [],
		geoPosition: {
			latitude:d.geoPosition.latitude,
			longitude:d.geoPosition.longitude,
		},
		name: d.name,
		notices: [
			{
			  title: "Impuestos y propinas",
			  description: "Todos los impuestos y propinas están incluidos.",
			},
			{
			  title: "Habitaciones",
			  description: "Incluye las habitaciones de tu elección.",
			},
			{
			  title: "Actividades",
			  description:
				"Actividades diarias conducidas por animadores incluyendo Jazzercise, aerobics y aquaerobics. El programa varía diariamente.",
			},
			{
			  title: "Alimentos y bebidas",
			  description:
				"Incluye desayuno, almuerzo y cena, incluyendo el restaurante de especialidades La Palapa y el snack-bar, así como bebidas nacionales.",
			},
			{
			  title: "Entretenimiento",
			  description:
				"Incluye entretenimiento nocturno y dos veces por semana. El programa varia diariamente.",
			},
			{
			  title: "Deportes acuáticos",
			  description:
				"Incluye actividades acuáticas como snorkel, bote de pedales y kayaks.",
			},
			{
			  title: "Deportes en tierra",
			  description:
				"Los deportes en tierra incluyen voleibol y fútbol de playa, ping pong y juegos de mesa.",
			},
			{
			  title: "Restricciones",
			  description: "Las bebidas importadas están disponibles con cargo extra.",
			},
		  ],
		postalCode: d.postalCode,
		rooms: rooms,	
		roomBestRate: {
			id: rooms[0].id,
			currency: d.bestRate.currency,
			hasPromotions: d.bestRate.hasPromotions,
			rateWithoutPromotional: d.bestRate.totalWithOffers,
			rate: d.bestRate.totalPerNight,
			mealPlan: d.bestRate.mealPlan,
		},
		stars: d.stars,
		zone: d.addres, 
		//services: d.services,
		services: hotelDetailsMocks.services,
		//datos para cargar en las politicas
		checkin: "13:00",
		checkout: "11:00",
		extendedCheckout: "500 USD",
		policies: {
			cancellationPolicies: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et suscipit tortor. Suspendisse at pulvinar nisl. Maecenas molestie feugiat commodo. Nam volutpat nibh a condimentum viverra. Vivamus lobortis erat at tristique viverra. Nulla ultrices pellentesque risus auctor scelerisque. Pellentesque semper, libero sed malesuada vehicula, eros eros ullamcorper nibh, et sollicitudin enim justo tempor nunc.",
			minYears: 25,
			petfriendly: "No se aceptan mascotas",
			additional: [
			"Los niños son bienvenidos.",
			"¡Los niños no pagan! La estancia de hasta 2 niños de 12 años o menos es gratuita siempre y cuando utilicen las camas existentes."
			]
		}
	} : {};
}

const formatQueryDestinationFromParams = (params) => {
	let destination = params.inputAutoValueStart?.keyword;
	let origin = params.inputAutoValueEnd?.keyword;
	return {
		locale: params.locale,
		destination,
		origin,
		culture: params.culture,
		occupancies: params.rooms?.map((room) => {
			return {
				adults: room.adult,
				children: room.child,
				infant: room.infant,
				ages: room.child > 0 ? room.childYears : null,
			};
		}),
		checkIn: params.startDate,
		checkOut: params.endDate,
		currency: params.currency,
		sourceMarket: params.sourceMarket,
	}
};

const formatQueryDetailsFromParams = (params) => {
    return {
		occupancies: params.rooms.map((room) => {
			return {
				adults: room.adult,
				children: room.child,
				infant: room.infant,
				ages: room.child > 0 ? room.childYears : null,
			};
		}),
		checkIn: params.startDate,
		checkOut: params.endDate,
		hotelId: params.inputAutoValueStart.keyword
	};
};

const paxesQuery = (rooms) => {
	let paxes = rooms.map(room => {
		return (`${room.adult}-${room.child}${room.infant > 0 ? `-${room.infant}` : ''}${room.child > 0 ? `:${room.childYears.join()}` : ''}`)
	});
	return paxes.join('!');
}

const totalOccupancies = (rooms) => {
    const totals = rooms.map(room => {
        return (room.adults + (room.children ? room.children : 0) + (room.infant ? room.infant : 0))
    })

    return totals.reduce((accumulator, curr) => accumulator + curr);
}

const paramsListforQuery = (params) => {
	const queries = {}

	queries.itinerary = `${params.inputAutoValueStart.keyword}_${params.inputAutoValueEnd.keyword}.${params.inputAutoValueEnd.keyword}_${params.inputAutoValueStart.keyword}`
	queries.origin = params.inputAutoValueStart.label;
	queries.destination = params.inputAutoValueEnd.label;
	queries.paxes = paxesQuery(params.rooms);
	queries.checkin = params.startDate;
    queries.checkout= params.endDate;

	return queries;
}

const paramsFormat = (query, rules) => {
    //TODO casado con listformat
    const params = {
        checkIn: "2022-08-10",
        checkOut: "2022-08-12",
        rooms: [
            {
                adults: rules.adultDefaultRoom,
                children: rules.childMinforRoom,
                infant: rules.infantMinforRoom,
                //ages: []
            },
        ],
    };
    return params;
};
const getParamsAutoDestinations = (query) => {
	let params = {}

	try {
		const _destinations = query.itinerary.split('.')
		const destinations = _destinations[0].split('_');
		if(destinations.length === 2) {
			//MEX_CUN
			destinations.forEach((des, i) => {
				switch(i){
					case 1:
						params.inputAutoValueEnd = {
							label: query.destination,
							keyword: des,
							//type: "Destination"
						}
						break;
					default:
						params.inputAutoValueStart = {
							label: query.origin,
							keyword: des,
							//type: "Destination"
						}
				}
			})
		}
		else
			params.noSearch = true;

	}
	catch(ex) {
		params = {}
	}

	return params;
}

const getRoomChildrenYearsToQuery = (room, rules, _pax) => {
	if (room.child > 0) {
		if (_pax[1]) {
			const _years = _pax[1].split(",")
			let years = []
			// eslint-disable-next-line eqeqeq
			if (_years.length == _pax[1])
				years = _years.map(year => { 
					// eslint-disable-next-line eqeqeq
					const nChild = rules.childYear.find(_child => _child == year);
					return nChild !== undefined && year >= nChild ?
					convertStringToNumber(year) : rules.childYear[0]
				});
			else {
				for (let i = 0; i < room.child; i++) {
					// eslint-disable-next-line eqeqeq
					years[i] = _years[i] && rules.childYear.find(_child => _child == _years[i]) !== undefined ? convertStringToNumber(_years[i]) : rules.childYear[0];
				}
			}
			room.childYears = years;
		}
	}

	return room
}

const paramsListFormat = (query, rules) => {
	const params = {
        inputAutoValueStart: null,
        inputAutoValueEnd: null,
        rooms: [
            {
                adult: rules.adultDefaultRoom,
                child: rules.childMinforRoom,
                infant: rules.infantMinforRoom,
            },
        ],
    };

	const checkinStatic = new Date().toLocaleDateString('sv');
	const checkoutDate = new Date();
	checkoutDate.setDate(checkoutDate.getDate() + 2);
	const checkOutStatic = checkoutDate.toLocaleDateString('sv');
	params.startDate = checkinStatic;
	params.endDate = checkOutStatic;

	if (dateUtils.valiteDateFormat(query.checkin) && dateUtils.valiteDateFormat(query.checkout)) {
		let checkin = dateUtils.getDateforFormat(query.checkin);
		let checkout = dateUtils.getDateforFormat(query.checkout);
		if (isBefore(checkin, checkout)) {
			if ((isEqual(checkin, new Date()) || isAfter(checkin, new Date())) &&
				isAfter(checkout, new Date())) {
				params.startDate = query.checkin;
				params.endDate = query.checkout;
			}
		}
	}

	if (query.paxes) {
		const rooms = [];
		const _rooms = query.paxes.split("!")
		if (_rooms.length > 0) {
			_rooms.forEach(_room => {
				const room = {}
				const _pax = _room.split(":")
				const _guests = _pax[0].split("-")
				const adult = _guests[0] && (convertStringToNumberWithDefault(_guests[0], rules.adultDefaultRoom));
				const child = _guests[1] && (convertStringToNumberWithDefault(_guests[1], rules.childMinforRoom));
				const infant = _guests[2] && (convertStringToNumberWithDefault(_guests[2], rules.infantMinforRoom));
				room.adult = adult >= rules.adultMinforRoom && adult <= rules.adultMaxforRoom ? adult : rules.adultDefaultRoom;
				room.child = child >= rules.childMinforRoom && child <= rules.childMaxforRoom ? child : rules.childMinforRoom;
				room.infant = infant >= rules.infantMinforRoom && infant <= rules.infantMaxforRoom ? infant : rules.childMinforRoom;

				rooms.push(getRoomChildrenYearsToQuery(room, rules, _pax));
			});
			if (guestsUtils.totalPeople(rooms) <= rules.personsTotal && rooms.length <= rules.roomsMax)
				params.rooms = rooms;
		}
	}


	const _destinations = getParamsAutoDestinations(query);

	return {
		params: {...params, ..._destinations},
		availability: {
			orderBy: query.sort && orderByFilter.findIndex(f => f.value.toLocaleLowerCase() === query.sort.toLocaleLowerCase())
				? query.sort.toLocaleLowerCase() : orderByFilter[0].value,
			mealPlans: null,
			categories: null,
			page: 1
		}
	}
}

const paramsHotelFormat = (query, rules) => {
	const params = {
        inputAutoValueStart: {
			label: "hotel",
			keyword: query.keyword,
			type: "Hotel"
		},
		inputAutoValueEnd: null,
        rooms: [
            {
                adult: rules.adultDefaultRoom,
                child: rules.childMinforRoom,
                infant: rules.infantMinforRoom,
            },
        ],
    };

	const checkinStatic = new Date().toLocaleDateString('sv');
	const checkoutDate = new Date();
	checkoutDate.setDate(checkoutDate.getDate() + 2);
	const checkOutStatic = checkoutDate.toLocaleDateString('sv');
	params.startDate = checkinStatic;
	params.endDate = checkOutStatic;

	if (dateUtils.valiteDateFormat(query.checkin) && dateUtils.valiteDateFormat(query.checkout)) {
		let checkin = dateUtils.getDateforFormat(query.checkin);
		let checkout = dateUtils.getDateforFormat(query.checkout);
		if (isBefore(checkin, checkout)) {
			if ((isEqual(checkin, new Date()) || isAfter(checkin, new Date())) &&
				isAfter(checkout, new Date())) {
				params.startDate = query.checkin;
				params.endDate = query.checkout;
			}
		}
	}

	if (query.paxes) {
		const rooms = [];
		const _rooms = query.paxes.split("!")
		if (_rooms.length > 0) {
			_rooms.forEach(_room => {
				const room = {}
				const _pax = _room.split(":")
				const _guests = _pax[0].split("-")
				const adult = _guests[0] && (convertStringToNumberWithDefault(_guests[0], rules.adultDefaultRoom));
				const child = _guests[1] && (convertStringToNumberWithDefault(_guests[1], rules.childMinforRoom));
				const infant = _guests[2] && (convertStringToNumberWithDefault(_guests[2], rules.infantMinforRoom));
				room.adult = adult >= rules.adultMinforRoom && adult <= rules.adultMaxforRoom ? adult : rules.adultDefaultRoom;
				room.child = child >= rules.childMinforRoom && child <= rules.childMaxforRoom ? child : rules.childMinforRoom;
				room.infant = infant >= rules.infantMinforRoom && infant <= rules.infantMaxforRoom ? infant : rules.childMinforRoom;

				rooms.push(getRoomChildrenYearsToQuery(room, rules, _pax));
			});
			if (guestsUtils.totalPeople(rooms) <= rules.personsTotal && rooms.length <= rules.roomsMax)
				params.rooms = rooms;
		}
	}
	const _destinations = getParamsAutoDestinations(query); //query.itinerary.split('.')
	return {...params, ..._destinations}
}

const getCategoryStartGroup = (stars) => {
	var count = 0;
	switch (stars) {
	  case "Two":
	  case "TwoAndAHalf":
		count = 2;
		break;
	  case "Three":
	  case "ThreeAndAHalf":
		count = 3;
		break;
	  case "Four":
	  case "FourAndAHalf":
		count = 4;
		break;
	  case "Five":
		count = 5;
		break;
		case "One":
	case "OneAndAHalf":
	default:
	  count = 1;
	  break;
	}
	return count;
}


const packagesValidate = {
	paramsFormat,
	paramsListFormat,
	paramsHotelFormat,
	paramsListforQuery,
	formatList,
	formatDetails,
	formatQueryDestinationFromParams,
	formatQueryDetailsFromParams
}

export default packagesValidate;