import { useIntl } from "react-intl";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Gallery from "../../../components/galleries/Collage/Collage";
import { useGlobalState, useDispatch } from "../../../store/StoreProvider";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import rateUtils from "../../../utils/rateUtils";
import hotelsValidate from "../../../validate/hotelsValidate";
import serviceHotelAction from "../../../actions/serviceHotelAction";
import BoxBooking from "../../../components/bookings/BoxBooking/BoxBooking";
import serviceType from "../../../constants/serviceTypeConstant";
import checkoutUtils from "../../../utils/checkoutUtils";
import storage from "../../../localstorage/storage";
import BoxDetailSeviceTitleRate from "../../../components/boxes/BoxDetailSeviceTitleRate";
import iconContants from "../../../constants/iconContants";
import BoxIconText from "../../../components/boxes/BoxIconText";
import SkeletonGallery from "../../../components/skeleton/SkeletonGallery/SkeletonGallery";
import SkeletonDetail from "../../../components/skeleton/SkeletonDetail/SkeletonDetail";
import CardHotelRoomLarge from "../../../components/cards/CardHotelRoomLarge";
import iconUtils from '../../../utils/iconUtils'
import breakpointConstants from "../../../constants/breakpointConstants";
import ReadMore from '../../../components/buttons/ReadMore/ReadMore';
import BoxWhitoutResults from "../../../components/boxes/BoxWhitoutResults/BoxWhitoutResults";
import "./style.scss";
import { useMediaQuery } from 'react-responsive';
import useCharterResponsive from '../../../hooks/useCharterResponsive'

const PackageDetailPage = (props) => {
	const { resourceSite } = props;
	const pathnames = resourceSite.siteRoutes;
	const searchHotelParams = resourceSite.searchHotelParams;
	const intl = useIntl();
	let navigate = useNavigate();
	const location = useLocation();
	const { hotel, currency, locale, authSession } = useGlobalState();
	const dispatch = useDispatch();
	const sourceMarket = "MX";
	const localizedUrlFlight = `/${locale}${pathnames.flight.pathname}`;
	const localizedUrlBooking = `/${locale}${pathnames.reservation.pathname}`;
	let [searchParams] = useSearchParams();
	const [showAboutHotel, setShowAboutHotel] = useState(false);
	const [bodyRenderScroll, setBodyRenderScroll] = useState(false);
	const [expanded, setExpanded] = useState(true);
	const [expandedAmenities, setExpandedAmenities] = useState(true);
	const [isHovering, setIsHovering] = useState(false);
	const [roomIndexSelected, setRoomIndexSelected] = useState(0);

	const selectIndexRoomByRatekey = (rooms, query) => {
		if (rooms.length > 0) {
			rooms.map((room, i) => {
				let ratekey = (room.rateKeyLead).split(`|${sourceMarket}`)[0];
				if (ratekey === query.ratekey) {
					setRoomIndexSelected(i)
					return i;
				}
				return -1;
			})
		}
	}

	const GetIdService = () => {
		const { id } = useParams();
		return id;
	};

	const [query, setQuery] = useState({
		keyword: GetIdService(),
	});
	const [params, setParams] = useState(null);

	if (typeof window !== 'undefined') {
		window.onscroll = function () {
			var y = window.scrollY;
			const elem = document.querySelector("#body-render");
			const rect = (elem != null && elem !== undefined && elem && elem.offsetTop) ? elem.offsetTop : 0;

			setBodyRenderScroll(y > (rect + 10))

			//hover rooms rate hidden or show 
			const elemtRooms = document.querySelector("#box-list-rooms");
			const footer = document.querySelector(".footer");
			const rectRooms = (elemtRooms != null && elemtRooms !== undefined && elemtRooms && elemtRooms.offsetTop) ? elemtRooms.offsetTop : 0;
			const rectRoomsBottom = (elemtRooms != null && elemtRooms !== undefined && elemtRooms && elemtRooms.offsetHeight) ? elemtRooms.offsetTop + elemtRooms.offsetHeight : 0;
			const rectFooter = (footer != null && footer !== undefined && footer && footer.offsetHeight) ? footer.offsetHeight : 0;
			if (y > rectRooms) {
				if ((y + rectFooter) > rectRoomsBottom) {
					setIsHovering(false)
				} else {
					setIsHovering(true)
				}
			} else {
				setIsHovering(false)
			}
		};
	}
	const [searchFixed, setSearchFixe] = useState(false);

	const getHotel = async (parameters, queryUrl) => {
		const query = hotelsValidate.formatQueryDetailsFromParams({
			...parameters,
			currency: currency.code,
		});

		if (query != null) {
			await serviceHotelAction.hotel(
				{ ...query, locale, sourceMarket: sourceMarket, authSession },
				dispatch,
				(response) => {
					let par = { ...parameters };
					par.inputAutoValueStart.label = response.name;
					setParams(par);
					setSearchFixe(false);
					selectIndexRoomByRatekey(response.rooms, queryUrl);
				},
				(error) => {
					console.error(error);
					setSearchFixe(true);
				}
			);
		}
	};
	const handleExpanded = () => setExpanded((prevExpanded) => !prevExpanded)
	const handleExpandedAmenities = () => setExpandedAmenities((prevExpanded) => !prevExpanded)

	useEffect(() => {
		window.scrollTo(0, 0);
		const auxQuery = {
			...query,
			checkin: searchParams.get("checkin"),
			checkout: searchParams.get("checkout"),
			paxes: searchParams.get("paxes"),
			ratekey: searchParams.get("ratekey"),
		};
		setQuery(auxQuery);
		const auxParams = hotelsValidate.paramsHotelFormat(auxQuery, searchHotelParams.paramsRoom.rules)
		setParams(auxParams);

		getHotel(auxParams, auxQuery);
	}, [currency, location]);

	//TODO get select room to go to reservation
	const onClickReservation = (room) => {
		const url = {
			//cart: query.cart,
			checkin: query.checkin,
			checkout: query.checkout,
			paxes: query.paxes,
			leng: "1",
		};

		const newHotel = { ...hotel };
		newHotel.rooms = [];
		newHotel.rooms.push(room);
		localStorage.setItem('room', JSON.stringify(room))
		const request = checkoutUtils.reservationHotel(newHotel, params);
		storage.setLocalStorage(request.uuid, request, 15);
		const location = {
			pathname: localizedUrlFlight + query.keyword,
			search: `?${new URLSearchParams(url).toString()}`,
		};
		navigate(location);
	};
	const getInfoDetailHotel = () => {
		return {
			type: serviceType.HOTEL,
			informationBoxTitle: {
				name: hotel.name,
				stars: hotel.stars,
				zone: {
					name: hotel.zone,
				},
				destination: {
					name: "",
				},
			},
			informationBoxServiceRate: {
				classs: "bg-rate mt-2",
				hasOffers: hotel.bestRate?.hasOffers,
				rateDiscount: hotel.bestRate.rateWithoutPromotional,
				classsThrough: hotel.bestRate?.hasOffers ? 'trought' : '',
				textFrom: intl.formatMessage({ id: "general.from" }),
				rate: hotel.bestRate?.totalPerNight,
				currency: currency.code,
				informations: [
					intl.formatMessage({ id: "general.bestPricePerRoom" }),
					intl.formatMessage({ id: "general.bestPricePerRoomTaxes" }),
				],
				textButton: intl.formatMessage({ id: "general.moreRooms" })
			},
			informationBoxIconText: [{
				icon: iconUtils.getIconMealPlanType(hotel.bestRate?.mealPlanCode),
				classIcon: "",
				text: hotel.bestRate?.mealPlan,
				classTex: "",
			}]
		}
	}
	const onClickToScrollRooms = (id) => {

		document.querySelector(id).scrollIntoView({
			behavior: 'smooth'
		})
	}
	const onClickShowAboutHotel = () => {
		setShowAboutHotel(!showAboutHotel)
	}
	const resetInitialRender = (searchBooking) => {
		if (
			searchBooking.inputAutoValueStart?.type === "Hotel" ||
			searchBooking.inputAutoValueStart?.type === "HotelDetails"
		) {
			const auxQuery = {
				keyword: searchBooking.inputAutoValueStart.keyword,
				checkin: searchBooking.startDate,
				checkout: searchBooking.endDate,
				paxes: hotelsValidate.paxesQuery(searchBooking.rooms)
			};

			setQuery(auxQuery);
			const auxParams = hotelsValidate.paramsHotelFormat(auxQuery, searchHotelParams.paramsRoom.rules);
			setParams(auxParams);
			getHotel(auxParams, {});
		}
	}
	const onChangeBooking = (searchBooking) => {
		resetInitialRender(searchBooking);
	}
	const [isMobile, setIsMobile] = useState(false)

	const handleResize = () => {
		if (window.innerWidth < breakpointConstants.SM) {
			setIsMobile(true)
		} else {
			setIsMobile(false)
		}
	}

	const breakPoint = {
		default: 200,
		sizes: [
			{
				type: useMediaQuery({ minWidth: "992px" }),
				charter: 374
			},
			{
				type: useMediaQuery({ minWidth: "1072px" }),
				charter: 447
			},
			{
				type: useMediaQuery({ minWidth: "1400px" }),
				charter: 517
			}
		]
	}
	let charterResponsive = useCharterResponsive(breakPoint);


	useEffect(() => {
		localStorage.setItem('room', JSON.stringify(null))
		window.addEventListener("resize", handleResize);
	}, [])

	const getGridRow = (items, maxItemsServices = 6) => {
		if (items > maxItemsServices * 2) return 3;
		else if (items > maxItemsServices) return 2;
		return 1;

	};
	return (
		<div id="body-render">
			<div id="booking-hotel" className={`search-box-service-hotel  ${bodyRenderScroll ? 'box-fixed' : ''}`}>
				<Container fluid="xl">
					<Row>
						<Col md={10} className="">
							<div className="pt-3">
								{!bodyRenderScroll && (
									<Breadcrumb className="d-none d-sm-block">
										<Breadcrumb.Item href={`/${locale}`}>{intl.formatMessage({
											id: "general.breadcrumdHome",
										})}</Breadcrumb.Item>
										<Breadcrumb.Item active >{intl.formatMessage({ id: "general.breadcrumdPaquetes", })}</Breadcrumb.Item>
										<Breadcrumb.Item active>{hotel?.name}</Breadcrumb.Item>
									</Breadcrumb>
								)}

							</div>
							{params && (
								<div className="pb-2">
									<BoxBooking
										params={params}
										locale={locale}
										title=""
										siteRoutes={resourceSite.siteRoutes}
										componentTitle="h2"
										closeColorButton="white"
										type={serviceType.PACKAGE}
										searchHotelParams={searchHotelParams}
										onChangeBooking={onChangeBooking}
										viewBooking={searchFixed}
									/>

								</div>
							)}
						</Col>
						<Col md={2} className={isHovering ? "d-none" : "d-block"} >
							{hotel.isSuccess && (
								<div className="d-flex align-items-center h-100 rate-top" >
									<div className="box-rate-top">
										<div className="item-rate-top">
											{intl.formatMessage({ id: "general.from" }) && (<div className="rate-from fs-6 mt-1">{intl.formatMessage({ id: "general.vuelohotel" })}</div>)}
											<span className="">
												{rateUtils.formatAmount(hotel.bestRate?.totalPerNight, currency.code, locale)}
											</span>
										</div>
										<div className="item-rate-top">
											<span className="text-rate-top">{intl.formatMessage({ id: "text.perperson" })}</span>
											<span className="text-rate-top">{intl.formatMessage({ id: "general.bestPricePerRoomTaxes" })}</span>
										</div>
									</div>
									<div className="d-md-none d-lg-none d-xl-none">
										<button onClick={() => onClickToScrollRooms("#title-hotel")} type="button" className="btn-reservation btn btn-primary">{intl.formatMessage({ id: "general.moreRooms" })}</button>
									</div>
								</div>
							)}
						</Col>
					</Row>
				</Container>
			</div>

			{!hotel.isFetching && !hotel.isSuccess && (
				<Container className=" mt-3 mb-2">
					<Row>
						<Col>
							<br />
							<BoxWhitoutResults
								text={intl.formatMessage({ id: "hotelDetailPage.hotelWhitoutResults" })}
							/>
						</Col>
					</Row>
				</Container>)
			}

			{hotel.isFetching && (
				<SkeletonGallery />
			)}
			{hotel.isSuccess && (
				<Gallery locale={locale} gallery={hotel.gallery} />
			)}
			<div>
				{hotel.isFetching && (
					<Container className="pt-4 pb-4">
						<SkeletonDetail />
					</Container>
				)}

				<Container className="pt-4 pb-4">
					{hotel.isSuccess && (
						<BoxDetailSeviceTitleRate
							{...getInfoDetailHotel()}
							service={hotel}
							onClickButton={() => onClickToScrollRooms("#title-hotel")}
						/>
					)}
				</Container>
			</div>
			{hotel.isSuccess && (
				<div className="py-5 pb-1 bg-light">
					<Container fluid="xl">
						<div className="container-information-service-bg p-4 bg-white mb-4 d-none d-sm-block">
							<div className={`${expanded ? 'box-tittle-column-state-about-hotel' : ''}`}>
								<h2 className="details-title-description">{intl.formatMessage({ id: "hotelDetailPage.titleAboutHotel" })}</h2>
								<div className={hotel.aboutOfHotel.length > charterResponsive ? (expanded ? `box-info-hide` : `box-info-show`) : `box-info-show`}>
									{hotel.aboutOfHotel}
								</div>
							</div>
							{(hotel.aboutOfHotel.length >= charterResponsive) && (
								<ReadMore
									handleExpanded={handleExpanded}
									text={expanded ? intl.formatMessage({ id: "general.seeMore", }) : intl.formatMessage({ id: "general.seeLess", })}
									icon={expanded ? iconUtils.icon(iconContants.IOIOSARROWUP) : iconUtils.icon(iconContants.IOIOSARROWDOWN)}
								/>
							)}
						</div>
						{hotel.services && (
							<div className="container-information-service-bg p-4 bg-white mb-4">
								<h2 className="details-title-description">{intl.formatMessage({ id: "hotelDetailPage.titlehasHotel" })}</h2>
								<div className={`box-hashotel-column ${!expandedAmenities && 'expand'} details-container-grid`}>
									{hotel.services.map((item, i) => {
										return (<div className={`box-hashotel-item`} key={i} style={{ gridRow: `span ${getGridRow(item.items.length)}` }}>
											<h5 ><b>{item.name}</b></h5>
											<BoxIconText items={item.items.map((itm) => {
												return {
													icon: iconContants.FICHECK,
													classIcon: "color-primary",
													text: itm,
												}
											})} />
										</div>)
									})}
								</div>
								{(hotel.services.length > 3) && (
									<ReadMore
										handleExpanded={handleExpandedAmenities}
										text={expandedAmenities ? intl.formatMessage({ id: "general.seeMore", }) : intl.formatMessage({ id: "general.seeLess", })}
										icon={expandedAmenities ? iconUtils.icon(iconContants.IOIOSARROWUP) : iconUtils.icon(iconContants.IOIOSARROWDOWN)}
									/>
								)}
							</div>

						)}
						<div className="container-information-service-bg p-4 bg-white">
							<h3 className="details-title-description">{intl.formatMessage({ id: "hotelDetailPage.titleCancellationPolicies" })}</h3>
							<div className="mb-3">
								{hotel.bestRate?.cancellationPolicies.map((cancellation, i) => {
									return <p key={i}>{cancellation}</p>
								})}
							</div>
						</div>
					</Container>
				</div>
			)}
			{hotel.isSuccess && (
				<div className="py-5" id="box-list-rooms">
					<Container fluid="xl">
						<div className="container-information-service-bg p-4 bg-white mb-4"  >
							<h2 id="title-hotel" className="details-title-description">{intl.formatMessage({ id: "general.titleRooms" })}</h2>
							<div>
								{hotel.rooms.map((room, i) => {
									return (<CardHotelRoomLarge key={i}
										currentClass={`mb-3 ${i === roomIndexSelected ? 'selected-active' : ''}`}
										room={room}
										rateDescriptions={[
											intl.formatMessage({ id: "general.bestPricePerRoom" }),
											intl.formatMessage({ id: "general.bestPricePerRoomTaxes" }),
										]}
										textButton={intl.formatMessage({ id: "general.reservation" })}
										onClickReservation={() => onClickReservation(room)}
									/>)
								})}
							</div>
						</div>
					</Container>
				</div>
			)}
		</div>
	);
};

export default PackageDetailPage;
