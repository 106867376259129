import { Container, Button, Image } from "react-bootstrap";
import "./style.scss";
import { useIntl } from "react-intl";
import StartGroup from "../../../components/boxes/BoxIcon/StartGroup"
import { useEffect, useState } from "react";
import serviceVoucherAction from "../../../actions/serviceVoucherAction";
import dateUtils from "../../../utils/dateUtils";
import imageDefault from '../../../assets/img/defaultImage.svg'
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import BoxSelectedFlight from "../../../components/boxes/BoxSelectedFlight/BoxSelectedFlight";
import storage from "../../../localstorage/storage";
import hotelsValidate from "../../../validate/hotelsValidate";
import checkoutUtils from "../../../utils/checkoutUtils"
import rateUtils from "../../../utils/rateUtils";
import iconContants from "../../../constants/iconContants";
import BoxIconText from "../../../components/boxes/BoxIconText";
import iconUtils from '../../../utils/iconUtils'
import ReadMore from '../../../components/buttons/ReadMore/ReadMore'
import { checkoutStep } from "../../../constants/common";
import SkeletonVoucher from "../../../components/skeleton/SkeletonVoucher/SkeletonVoucher";
import useRedirectToHome from "../../../hooks/useRedirectHomeWithTimerAndDeteleLocalStorage";
import { useGlobalState } from "../../../store/StoreProvider";



const PackageVoucherPage = (props) => {
  const { resourceSite } = props;
  const {locale} = useGlobalState();

  let navigate = useNavigate();
  const intl = useIntl();
  const localizedUrlHome = `/${locale}`;
  const { authSession } = useGlobalState();
  let [searchParams, setSearchParams] = useSearchParams();
  const [hotel, setHotel] = useState({ id: null });
  const query = {
    cart: searchParams.get("cart"),
  };
  const [cart, setCart] = useState(null);
  const gotToHome = useRedirectToHome(cart, 14, locale);
  const [expandedAmenities, setExpandedAmenities] = useState(true);
  const [expandedServices, setExpandedServices] = useState(true);
  const title= intl.formatMessage({ id: "voucherPage.title" });
  window.document.title = title
  const handleExpandedAmenities = () => setExpandedAmenities((prevExpanded) => !prevExpanded)
  
  const handleExpandedServices = () => setExpandedServices((prevExpanded) => !prevExpanded)

  const [itinerary, setItinerary] = useState({ isSucces: false })

  const searchHotelParams = resourceSite.searchHotelParams;
  const [params, setParams] = useState(
    hotelsValidate.paramsHotelFormat(query, searchHotelParams.paramsRoom.rules)
  );

  const infoFlightGoing = {
    arrival: {
        time: "2:02 PM",
        codeIATA: "MEX",
        terminal: "T2",
        name:"Cd. México (AICM)"
    },
    departure : {
        time: "3:15 PM",
        codeIATA: "CUN",
        terminal:"",
        name:"Cancún"
    },
    date:"Martes, 27 Enero, 2023",
    flightDuration:"2h 53min",
    scale:true,
    flightSegment:1
  }
  const infoFlightReturn = {
    arrival: {
        time: "3:15 PM",
        codeIATA: "CUN",
        terminal:"",
        name:"Cancún"
       
    },
    departure : {
        time: "2:02 PM",
        codeIATA: "MEX",
        terminal: "T2",
        name:"Cd. México (AICM)"
    },
    date:"Viernes, 28 Enero, 2023",
    flightDuration:"2h 53min",
    scale:true,
    flightSegment:1
  }
  const getItinerary = (query) => {
    serviceVoucherAction.hotelItinerary(
      {...query, authSession},
      (response) => {
        setItinerary(response);

      },
      (error) => {
        console.error(error);
      }
    )
  }

  const onClickRedirect = () => {
    const location = {
      pathname: localizedUrlHome
    };
    window.scrollTo(0, 0);
    navigate(location);
  }

  useEffect(() => {

     const reservation = storage.getLocalStorage(query.cart);
      //const reservation = hotelVoucherMock;
      window.scrollTo(0, 0);
    if (reservation && reservation.type === checkoutStep.VOUCHER) {
      setCart(reservation);
      const nParams = { ...reservation.booking };
      setParams(nParams);
      const newHotel = checkoutUtils.getHotel(reservation.services);
      setHotel(newHotel);

      if (reservation.clientReference) {
        const request = {
          "reference": reservation.clientReference.reference,
          "email": reservation.clientReference.email,
          locale
        };
        getItinerary(request);
      }
      else {
        const location = { pathname: localizedUrlHome };
        navigate(location);
      }

    } else {
      const location = { pathname: localizedUrlHome };
      navigate(location);
    }

  }, []);

  return (
    <>
      <Container className="container-aux-voucher">
        {hotel && (
          <div className="voucher-container">
            <h1 className="title-service-general">
              {hotel.type === "Hotel" ? intl.formatMessage({ id: "voucherPage.titleHotelSucces" })
                : intl.formatMessage({ id: "voucherPage.titleTourSucces" })}
            </h1>
            <br />
            {(!itinerary.isSucces) &&
             <SkeletonVoucher />
            }
            {(itinerary.isSucces) &&(
              <>
                <div className="voucher-card">
                  <div className="voucher-card-header">
                    <div className="container-img-perfil">
                      {hotel.gallery && hotel.gallery.length > 0 && (
                        <Image loading="lazy" alt={hotel.gallery[0].name} src={hotel.gallery[0].photoUris} className='voucher-img-circle'
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = imageDefault
                          }}
                        />
                      )}
                    </div>

                    <div className="d-flex w-100 container-rest-header border-bottom-solid">
                      <div className="container-title-stars-checks">
                        <h2 className="title">{hotel.name}</h2>
                        <div className="container-stars">
                          {hotel.stars && (
                            <StartGroup stars={hotel.stars} />
                          )}

                        </div>
                        <div className="container-checkouts">
                          <div className="container-check-in">
                              <span className="strong">
                                {`${intl.formatMessage({ id: "voucherPage.checkIn" })}:  `}
                              </span>
                              <span>
                                {itinerary.checkInFormat}
                              </span>
                          </div>
                          <div className="container-check-out">
                              <span className="strong">
                                {`${intl.formatMessage({ id: "voucherPage.checkOut" })}:  `}
                              </span>
                              <span>
                                {itinerary.checkOutFormat}
                              </span>
                          </div>
                          <div className="container-check-out">
                              <span className="strong">
                                  {`${intl.formatMessage({ id: "voucherPage.address" })}:  `}
                              </span>
                              <span>
                                  {`${hotel?.city}, ${hotel.addres}`}
                              </span>
                          </div>
                        </div>
                      </div>

                      <div className="container-reference">
                        <div className="container-text-duo refer">
                          <span className="text-left">{`${intl.formatMessage({ id: "voucherPage.reference" })}:  `}</span>
                          {itinerary.isSucces && itinerary.alphanumericReference && (
                            <span className="text-right">{itinerary.alphanumericReference}</span>
                          )}
                        </div>
                        <div className="container-text-duo date">
                          <span className="text-left">{`${intl.formatMessage({ id: "voucherPage.date" })}:  `}</span>
                          {itinerary.isSucces && itinerary.dateCreatedUtc && (
                            <span className="text-right">{dateUtils.dateFormat(itinerary.dateCreatedUtc, "DD-MM-YYYY", locale)}</span>
                          )}
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="voucher-card-body">
                  
                    {(itinerary.isSucces && hotel.rooms && hotel.rooms.length > 0) && (
                        
                      hotel.rooms.map((item, i) => {
                        return (
                          <div className="d-flex w-100 flex-column" key={"service-" + i}>
                            <div className="container-rooms border-bottom-solid">
                              <div className="room">
                                <div className="container-subtitle">
                                  {item.name && (
                                    <h2 className="mb-4 text-subtitle">
                                      {intl.formatMessage({ id: "voucherPage.room" }) + (hotel.rooms.length === 1 ? "" : i + 1) + " - " + (item.name)}
                                    </h2>
                                  )}
                                </div>
                                <div className="container-text-duo name">
                                    <span className="text-left">{intl.formatMessage({ id: "voucherPage.referenceRoom" })}</span>
                                    <span className="text-right">{itinerary.rooms[0]?.providerReference}</span>
                                </div>
                                <div className="container-text-duo name">
                                  <span className="text-left">{intl.formatMessage({ id: "voucherPage.name" })}</span>
                                  <span className="text-right">{[itinerary.holderName, itinerary.holderLastName].join(' ')}</span>
                                </div>
                                <div className="container-text-duo checkin">
                                  <span className="text-left">{intl.formatMessage({ id: "voucherPage.checkIn" })}</span>
                                  <span className="text-right">{itinerary.checkInFormat}</span>
                                </div>
                                <div className="container-text-duo check-out">
                                  <span className="text-left">{intl.formatMessage({ id: "voucherPage.checkOut" })}</span>
                                  <span className="text-right">{itinerary.checkOutFormat}</span>
                                </div>
                                <div className="container-text-duo mealPlan">
                                  <span className="text-left">{intl.formatMessage({ id: "voucherPage.mealPlan" })}</span>
                                  <span className="text-right">{item.mealPlan?.name}</span>
                                </div>
                                <div className="container-text-duo adults">
                                  <span className="text-left">{intl.formatMessage({ id: "voucherPage.adults" })}</span>
                                  {(itinerary.rooms && itinerary.rooms.length > 0 && itinerary.rooms[0].adults) && (
                                    <span className="text-right">{itinerary.rooms[0].adults}</span>
                                  )}
                                </div>
                                <div className="container-text-duo childrens">
                                  <span className="text-left">{intl.formatMessage({ id: "voucherPage.childrens" })}</span>
                                  <span className="text-right">
                                    {(itinerary.rooms && itinerary.rooms.length > 0 && itinerary.rooms[0].children) && (
                                      itinerary.rooms[0].children
                                    )}
                                  </span>
                                </div>
                                <div className="container-text-duo comments">
                                  <span className="text-left">{ intl.formatMessage({ id: "checkout.form.special" }) }</span>
                                  <span className="text-right">{itinerary.comments && itinerary.comments}</span>
                                </div>
                              </div>
                            </div>
                            {hotel.services && hotel.services.length > 0 && (
                              <div className="rounded-5 py-4 bg-white container-incluid ">
                                <div className="border-bottom-solid pb-4">
                                <h2 className="mb-4 text-subtitle">{intl.formatMessage({ id: "voucherPage.titleIncluid" })}</h2>
                                <div className={ `box-hashotel-column ${expandedServices && 'box-tittle-column-state'}` }>
                                  {hotel.services.map((item, i) => {
                                    return (<div className="box-hashotel-item" key={i}>
                                      <h5 className="py-3" ><b>{item.name}</b></h5>
                                      <BoxIconText items={item.items.map((itm) => {
                                        return {
                                          icon: iconContants.FICHECK,
                                          classIcon: "color-primary",
                                          text: itm,
                                        }
                                      })} />
                                    </div>)
                                  })}
                                </div>
                                <ReadMore 
                                  handleExpanded={handleExpandedServices}
                                  text={expandedServices ? intl.formatMessage({id: "general.seeMore",}) : intl.formatMessage({id: "general.seeLess",})}   
                                  icon={expandedServices ?  iconUtils.icon(iconContants.IOIOSARROWUP) : iconUtils.icon(iconContants.IOIOSARROWDOWN)  }
                                  />
                                </div>
                              
                              </div>
                            )}


                             {item.amenities && item.amenities.length > 0 && (
                               
                              <div className="rounded-5 py-4 bg-white container-incluid">
                                <h2 className="mb-4 text-subtitle">{intl.formatMessage({ id: "voucherPage.titleAminities" })}</h2>
                                <h5 className="mt-4">{item.amenities[0].name}</h5>
                                <div className={ `content-list-ameni ${item.amenities[0].amenities.length > 19 && expandedAmenities && 'box-tittle-column-state'}` }>
                                  <BoxIconText items={item.amenities[0].amenities.map((item, i) => {
                                        return {
                                            icon: item.isIncluded ? iconContants.FICHECK : iconContants.AIOUTLINECLOSE, 
                                            classIcon: "color-primary",
                                            text: item.name,
                                        }
                                    })} />
                                </div>
                              
                                {item.amenities[0].amenities.length > 19 && (
                                  <ReadMore 
                                  handleExpanded={handleExpandedAmenities}
                                  text={expandedAmenities ? intl.formatMessage({id: "general.seeMore",}) : intl.formatMessage({id: "general.seeLess",})}   
                                  icon={expandedAmenities ?  iconUtils.icon(iconContants.IOIOSARROWUP) : iconUtils.icon(iconContants.IOIOSARROWDOWN)  }
                                  />
                                )}
                                
          
                              </div>
                            )}                     
                          </div>
                        );
                      })
                    )
                    }
                  </div>
                </div>
                <br />
                {hotel.rooms && hotel.rooms.length > 0 && hotel.rooms[0].cancellationPolicies && hotel.rooms[0].cancellationPolicies.length > 0 && (
                  <div className="voucher-card-extra politicas">
                    <div className="container-subtitle">
                      <h2 className="text-subtitle">{intl.formatMessage({ id: "voucherPage.titlePolitics" })}</h2>
                    </div>
                    <br />
                    {hotel.rooms[0].cancellationPolicies.map((politica, i) => {
                      return (
                        <p key={"politica" + i}>
                          {politica}
                        </p>
                      )
                    })
                    }

                  </div>
                )}
                <br />
                {itinerary.isSucces && itinerary.rooms && itinerary.rooms.length > 0 && itinerary.rooms[0].rateComments && (
                  <>
                    <div className="voucher-card-extra comentarios">
                      <div className="container-subtitle">
                        <h2 className="text-subtitle">{intl.formatMessage({ id: "voucherPage.titleRateComents" })}</h2>
                      </div>
                      <br />
                      <p>{itinerary.rooms[0].rateComments.replace(/_|#|-|@|<>/g, " ")}</p>
                    </div>
                  </>
                )}
                <br />

                <div className="voucher-card-extra mb-4">
                    <BoxSelectedFlight 
                    typePrice={JSON.parse(localStorage.getItem('tPrice'))}
                    showInfo={false}
                    detail={infoFlightGoing}
                    title="Salida" 
                    typeCategory={JSON.parse(localStorage.getItem('tCategory'))}
                    />
                    <BoxSelectedFlight 
                     typePrice={JSON.parse(localStorage.getItem('tPrice'))}
                    showInfo={false}
                    detail={infoFlightReturn}
                    title="Regreso" 
                    typeCategory={JSON.parse(localStorage.getItem('tCategory'))}
                    />
                </div>    

                <div className="voucher-card-extra pago">
                  <div className="container-subtitle">
                    <h2 className="text-subtitle">{intl.formatMessage({ id: "voucherPage.pay" })}</h2>
                  </div>
                  <br />
                  <div className="w-100 border-bottom-solid">
                    <p>{intl.formatMessage({id: "voucherPage.methodPay"})}</p>
										<p>{intl.formatMessage({id: "voucherPage.creditCard"})}</p>
                  </div>
                  <br />
                    <div  className="container-text-duo">
                          <span className=" strong">Tarifa de Uso de Aeropuerto (TUA)</span>                                                                              
                    </div>
                    <div className="rateTua">
                        <div>1 pasajero</div>
                        <div>
                            <span className="">
                              $623.00
                           </span> 
                        </div>
                    </div>
                    <div className="rateTua">
                        <div><strong>Impuestos</strong></div>
                        <div>
                            <span className="">
                              <strong>$345.00</strong>
                           </span> 
                        </div>
                    </div>
                    <br/>
                  {hotel.rooms && hotel.rooms.length > 0 && (
                    hotel.rooms.map((item, i) => {
                      return (
                        <div key={"concepto-pago" + i} className="container-text-duo">
                          <span className="text-total strong">{intl.formatMessage({ id: "voucherPage.publicRate" })}</span>

                          {item.total && item.currency && (
                            <span className="text-total strong">
                              {rateUtils.formatAmount(item.total, item.currency, locale)}
                            </span>
                          )}
                        </div>
                      )
                    })
                  )}
                </div>
                <br /> <br />
                <Button onClick={onClickRedirect} className="text-white d-block m-auto">
                  <span>{intl.formatMessage({ id: "button.backToList" })}</span>
                </Button>
              </>
            )}
          </div>
        )}
      </Container>
      <br />
    </>
  );
};

export default PackageVoucherPage;
