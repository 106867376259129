import { useIntl } from "react-intl";
import { Button } from "react-bootstrap";
import rateUtils from "../../../utils/rateUtils";
import { useGlobalState } from "../../../store/StoreProvider";

const BoxServiceRateTour = (props) => {
    const {classs, textButton, informations, rate, currency, discountRate} = props;
    const intl = useIntl();
    const {locale} = useGlobalState();
    const onClick = () => {
        const {onClick} = props;
        onClick && onClick();
    }

    return ( <div className={`box-service-rate-tour ${classs}`}>
         {!discountRate && (
           <span className="rate-per">
            {intl.formatMessage({ id: "general.for" })} 1 {intl.formatMessage({ id: "general.adult" })} <b>{rateUtils.formatAmount(1029, currency, locale)}</b></span>
        )}        
          
        {discountRate && (
            <div className="d-flex">
                <span className="me-2 rate-per">{intl.formatMessage({ id: "general.for" })} 1 {intl.formatMessage({ id: "general.adult" })} <s>{rateUtils.formatAmount(3000, currency, locale)}</s></span> 
                <span className="rate-per"><b>{rateUtils.formatAmount(1029, currency, locale)}</b></span>  
            </div>
        )}

        <div className="box-boder">
            <div className="rate-tour-card">{rateUtils.formatAmount(rate, currency, locale)}</div>
            <div className="mb-3"> {informations && informations.map((text, i) => {
                return <div key={i} className="informations">{text}</div>
            })}</div>
        </div>
         <Button onClick={onClick}>{textButton}</Button>
       
       
        
    </div> );
}
 
export default BoxServiceRateTour;