import iconUtils from "../../../utils/iconUtils";
import React from "react";

const CategoryTypeGroup = (props) => {
	const { categoryCode, categoryString, num } = props;
	const category = categoryCode ? categoryCode.toLowerCase() : "";
	const icon = category ? iconUtils.getIconHotelCategoryType(category) : null;
	let count = 0;
	switch (num) {
		case "One":
		case "OneAndAHalf":
		case "1":
		case 1:
			count = 1;
			break;
		case "Two":
		case "TwoAndAHalf":
		case "2":
		case 2:
			count = 2;
			break;
		case "Three":
		case "ThreeAndAHalf":
		case "3":
		case 3:
			count = 3;
			break;
		case "Four":
		case "FourAndAHalf":
		case "4":
		case 4:
			count = 4;
			break;
		case "Five":
		case "5":
		case 5:
			count = 5;
			break;
		default:
			count = 0;
			break;
	}

	let component = [];
	if (count > 0) {
		for (let i = 0; i < count; i++) component.push(<></>);
	}

	const iconComponent = component.map((com, index) => {
		return (
			<div key={index} className="box-star">
				{com}
				{icon}
			</div>
		);
	});

	function boxContainer() {
		const classContainer = {
			icons: "container-stars",
			text: "container-categories",
		};
		return (
			<div className={icon ? classContainer.icons : classContainer.text}>
				{icon && iconComponent}
				{!icon && <span>{categoryString}</span>}
			</div>
		);
	}
	const containerIcon = boxContainer();
	return containerIcon;
};

export default CategoryTypeGroup;
