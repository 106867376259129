import BoxImageBookingComponent from "@mzp/components/dist/components/boxes/BoxImageBooking";
import searchHotelAction from "../../actions/searchHotelAction";
import { useDispatch, useGlobalState } from "../../store/StoreProvider";
import iconContants from "@mzp/commons/dist/constants/iconContants";
import iconUtils from "@mzp/commons/dist/utils/iconUtils";
import searchBookingUtils from "../../utils/searchBookingUtils";
import { useNavigate } from "react-router-dom";
import hotelsValidate from "../../validate/hotelsValidate";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMediaQuery } from 'react-responsive';
import breakpointConstants from "../../constants/breakpointConstants";
import GoogleTagManagerUtils from "../../utils/GoogleTagManagerUtils";
import serviceCodeConstants from "../../constants/serviceCodeConstants";
import { useDebounce } from "../../hooks/useDebounce";
import "../../pages/style.scss";

const SearchBookingSection = (props) => {
	const { options } = props;
	const { searchBoxProps, ...rest } = options;
	const {
		paramsFrontProps,
		autocompleteProps,
		autocompleteEndProps,
		calendarProps,
		roomProps,
		...restSearchBoxProps
	} = searchBoxProps;
	const {
		locale,
		authSession,
		resourceSite: resourceSiteProps,
	} = useGlobalState();
	const resourceSite = resourceSiteProps.options;
	const { searchHotelParams } = resourceSite;
	const { minTextAPI, timeDelay } = searchHotelParams.paramsAutocomplete;
	const [interSearchBoxProps, setInterSearchBoxProps] = useState(null);
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const intl = useIntl();
	const nexus = 'nexus';
	const branding = process.env.REACT_APP_BRANDING;
	const isMobile = useMediaQuery({ maxWidth: breakpointConstants.MD });
	const [search, setSearch] = useState({
		value: '',
		onSuccess: null
	});
	const debouncedFromText = useDebounce(search.value, timeDelay);

	useEffect(() => {
		const { onSuccess } = search;
		if (!debouncedFromText || debouncedFromText.length < minTextAPI) {
			if (onSuccess && onSuccess !== null) onSuccess({ items: [] });
			return;
		}

		searchHotelAction.search(
			{ keyword: debouncedFromText, locale, authSession },
			dispatch,
			onSuccess,
			(error) => console.error(error)
		);
	}, [debouncedFromText]);

	const onChangeAutocomplete = (value, onSuccess) => setSearch({ value, onSuccess });

	//TODO Proceso de submit ded servicios
	const onSubmitHotelBooking = (response) => {
		const formatNewQuery = hotelsValidate.paramsListforQuery(response);
		const pathnames = resourceSite.siteRoutes;
		const query = {
			checkin: response.startDate,
			checkout: response.endDate,
			...formatNewQuery,
			s: serviceCodeConstants.HOTEL,
		};

		GoogleTagManagerUtils.searchEvent(response, restSearchBoxProps.type);

		//TODO el autocomplete solo trae hotel o destination por eso siempre entra en el listado, por el default else
		if (response.inputAutoValueStart?.type === "HotelDetails") {
			const keyname = `${hotelsValidate.convertStringToKeyname(response.inputAutoValueStart.label)}/`;
			const location = {
				pathname: `/${locale}${pathnames.hotel.pathname}${keyname}${response.inputAutoValueStart.keyword}`,
				search: `?${new URLSearchParams(query).toString()}`,
			};

			window.location.href = location.pathname + location.search;
		} else {
			let location = {
				pathname: `/${locale}${pathnames.hotels.pathname}`,
				search: `?${new URLSearchParams(query).toString()}`,
			};
			window.location.href = location.pathname + location.search;
		}
	};
	const onSubmitPackageBooking = (response) => {
		const formatNewQuery = hotelsValidate.paramsListforQuery(response);
		const pathnames = resourceSite.siteRoutes;
		const query = {
			checkin: response.startDate,
			checkout: response.endDate,
			...formatNewQuery,
			s: serviceCodeConstants.PACKAGE,
		};
		GoogleTagManagerUtils.searchEvent(response, restSearchBoxProps.type);
		//TODO el autocomplete solo trae hotel o destination por eso siempre entra en el listado, por el default else
		if (
			response.inputAutoValueStart?.type === "Package" ||
			response.inputAutoValueStart?.type === "PackageDetails"
		) {
			let location = {
				pathname: `/${locale}${pathnames.package.pathname}${response.inputAutoValueStart.keyword}`,
				search: `?${new URLSearchParams(query).toString()}`,
			};
			navigate(location);
		} else {
			let location = {
				pathname: `/${locale}${pathnames.packages.pathname}`,
				search: `?${new URLSearchParams(query).toString()}`,
			};
			navigate(location);
		}
	};

	const onSubmitTourBooking = (response) => {
		const pathnames = resourceSite.siteRoutes;
		const localizedUrlTourList = `/${locale}${pathnames.tours.pathname}`;
		const location = {
			pathname: localizedUrlTourList,
			search: `?checkin=2023-04-05&destination=Cancun&destinationkeyword=36a7ba94-d32a-48e6-a139-5bc22ef49df1&paxes=2-0`,
		};
		navigate(location);
		GoogleTagManagerUtils.searchEvent(response, restSearchBoxProps.type);
		window.scrollTo(0, 0);
	};
	const onSubmitBookingShutter = (response) => {
		const pathnames = resourceSite.siteRoutes;
		const localizedUrlShutterList = `/${locale}${pathnames.transfer.pathname}`;
		const location = {
			pathname: localizedUrlShutterList,
			search: `?checkin=2022-12-03&checkout=2022-12-05&destination=Aeropuerto%20internacional%20de%20Cancún&destinationkeyword=36a7ba94-d32a-48e6-a139-5bc22ef49df1&paxes=2-0&arrival=Hotel%20Xcaret%20México`,
		};
		GoogleTagManagerUtils.searchEvent(response, restSearchBoxProps.type);
		navigate(location);
		window.scrollTo(0, 0);
	};
	const onSubmitBooking = (response) => {
		switch (restSearchBoxProps.type) {
			case "Hotel":
				onSubmitHotelBooking(response);
				break;
			case "Package":
				onSubmitPackageBooking(response);
				break;
			case "Tour":
				onSubmitTourBooking(response);
				break;
			case "Transfer":
				onSubmitBookingShutter(response);
				break;
			default:
		}
	};
	/* #endregion */

	const itemTemplate = (item) => {
		return (
			<div className="country-item d-flex">
				{item && (
					<div className="me-2">
						{item.type === "Destination" && iconUtils.icon(iconContants.LOCATION)}
						{item.type === "Hotel" && iconUtils.icon(iconContants.HOTEL)}
						{item.type === "Tour" && iconUtils.icon(iconContants.ACTIVITY)}
					</div>
				)}
				<div>{item.name}</div>
			</div>
		);
	};
	const buttonProp = {
		text: intl.formatMessage({ id: "button.search" }),
		icon: iconContants.SEARCH,
	}

	const buttonPropsDesk = {
		text: iconUtils.icon(iconContants.SEARCH),
	}

	let buttonPropsAux = buttonProp;
	if (!isMobile && nexus == branding) {
		buttonPropsAux = buttonPropsDesk;
	}

	//TODO primer paso agregar los parametros de inicializacion del booking
	useEffect(() => {
		let auxSearchBoxProps = {
			...restSearchBoxProps,
			locale: locale,
			//TODO paramsFront estan con informacion para hacer el submit y pasar al listado
			//TODO solo hotel y tour no
			paramsFrontProps: searchBookingUtils.getParametersService(
				restSearchBoxProps.type
			),
		};

		switch (restSearchBoxProps.type) {
			case "Hotel":
				auxSearchBoxProps = {
					...auxSearchBoxProps,
					autocompleteProps: {
						...autocompleteProps,
						itemTemplate: itemTemplate,
						parameters: searchBookingUtils.getParamsAutocompleteService(
							restSearchBoxProps.type,
							resourceSite
						),
					},
					calendarProps: {
						...calendarProps,
						icon: iconContants.BSFILLCALENDAR2CHECKFILL,
						parameters: searchBookingUtils.getParamsCalendarService(
							restSearchBoxProps.type,
							resourceSite
						),
					},
					roomProps: {
						...roomProps,
						parameters: searchBookingUtils.getParamsRoomsService(
							restSearchBoxProps.type,
							resourceSite
						),
					},
					buttonProps: {
						...buttonPropsAux
					}
				};
				break;
			case "Package":
				//TODO: parametros del sitio CABECERA igual para todos los servicios
				auxSearchBoxProps = {
					...auxSearchBoxProps,
					autocompleteProps: {
						...autocompleteProps,
						itemTemplate: itemTemplate,
						placeholder: intl.formatMessage({ id: "general.origin" }),
						parameters: searchBookingUtils.getParamsAutocompleteService(
							restSearchBoxProps.type,
							resourceSite
						),
					},
					autocompleteEndProps: {
						...autocompleteEndProps,
						itemTemplate: itemTemplate,
						placeholder: intl.formatMessage({ id: "general.destiny" }),
						parameters: searchBookingUtils.getParamsAutocompleteService(
							restSearchBoxProps.type,
							resourceSite
						),
					},
					calendarProps: {
						...calendarProps,
						parameters: searchBookingUtils.getParamsCalendarService(
							restSearchBoxProps.type,
							resourceSite
						),
					},
					roomProps: {
						...roomProps,
						parameters: searchBookingUtils.getParamsRoomsService(
							restSearchBoxProps.type,
							resourceSite
						),
					},
				};
				break;
			case "Tour":
				auxSearchBoxProps = {
					...auxSearchBoxProps,
					autocompleteProps: {
						...autocompleteProps,
						itemTemplate: itemTemplate,
						placeholder: intl.formatMessage({
							id: "general.placeholderAutocomplete",
						}),
						parameters: searchBookingUtils.getParamsAutocompleteService(
							restSearchBoxProps.type,
							resourceSite
						),
					},
					calendarProps: {
						...calendarProps,
						parameters: searchBookingUtils.getParamsCalendarService(
							restSearchBoxProps.type,
							resourceSite
						),
					},
					roomProps: {
						...roomProps,
						parameters: searchBookingUtils.getParamsRoomsService(
							restSearchBoxProps.type,
							resourceSite
						),
					},
				};
				break;
			case "Transfer":
				auxSearchBoxProps = {
					...auxSearchBoxProps, //TODO: parametros del sitio CABECERA igual para todos los servicios
					autocompleteProps: {
						...autocompleteProps,
						itemTemplate: itemTemplate,
						placeholder: intl.formatMessage({ id: "general.origin" }),
						parameters: searchBookingUtils.getParamsAutocompleteService(
							restSearchBoxProps.type,
							resourceSite
						),
					},
					autocompleteEndProps: {
						...autocompleteEndProps,
						itemTemplate: itemTemplate,
						placeholder: intl.formatMessage({ id: "general.destiny" }),
						parameters: searchBookingUtils.getParamsAutocompleteService(
							restSearchBoxProps.type,
							resourceSite
						),
					},
					textRountrip: intl.formatMessage({ id: "general.roundtrip" }),
					calendarProps: {
						...calendarProps,
						parameters: searchBookingUtils.getParamsCalendarService(
							restSearchBoxProps.type,
							resourceSite
						),
					},
					roomProps: {
						...roomProps,
						parameters: searchBookingUtils.getParamsRoomsService(
							restSearchBoxProps.type,
							resourceSite
						),
					},
				};
				break;
			default:
		}

		setInterSearchBoxProps(auxSearchBoxProps);
	}, [searchBoxProps, isMobile]);

	const inputAutocomplete = document.getElementById("input-start");
	if (inputAutocomplete)
		inputAutocomplete?.setAttribute("autocomplete", "off");

	return (
		<section className="search-booking-section">
			{interSearchBoxProps &&
				restSearchBoxProps.type == interSearchBoxProps.type && (
					<div className={`search-${branding}`}>
						<BoxImageBookingComponent
							{...rest}
							searchBoxProps={interSearchBoxProps}
							onChangeAutocomplete={onChangeAutocomplete}
							onSubmitBooking={onSubmitBooking}
						/>
						<div className="div-border-searchbox">
							<div className="border-searchbox"></div>
						</div>
					</div>
				)}
		</section>
	);
};

export default SearchBookingSection;
