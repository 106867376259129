import { images } from "../slideGaleryMockups";
import roomsDetailsMocks from "./roomsDetailsMocks";

const services = [
  {
    name: "Internet",
    icon: "NetworkWifiIcon",
    items: [
      "Disponible en todas las habitaciones: wifi gratis",
      "Disponible en algunas áreas comunes: wifi gratis",
    ],
  },
  {
    name: "Alimentos y bebidas",
    icon: "RestaurantMenuIcon",
    items: [
      "4 restaurantes",
      "4 bares o lounges, 1 bar en la playa, 1 bar en la alberca y 1 bar junto a la alberca.",
      "Servicio a la habitación las 24 horas.",
    ],
  },
  {
    name: "Actividades",
    icon: "PoolIcon",
    items: [
      "2 albercas al aire libre",
      "2 canchas de tenis al aire libre",
      "Clases de aerobics",
      "Yoga en la playa",
      "Kayaks",
      "Área de juegos infantiles",
      "Paseos en velero",
      "Tiendas",
      "Servicios de spa",
      "Clases de yoga",
    ],
  },
  {
    name: "Estacionamiento y transporte",
    icon: "LocalParkingIcon",
    items: [
      "Estacionamiento gratuito en el establecimiento",
      "Disponibilidad de estacionamiento con acceso para sillas de ruedas",
    ],
  },
  {
    name: "Servicios extras",
    icon: "FamilyRestroom",
    items: [
      "2 albercas al aire libre",
      "Servicio de niñera o guardería (con cargo)",
      "Reproductor de DVD",
      "Traslado por la zona gratis",
      "Club infantil gratuito",
      "Cunas gratuitas",
      "Refrigerador con congelador",
      "Supermercado o tienda de conveniencia",
      "Servicio de niñera en la habitación (con cargo)",
      "Cocina",
      "Lavandería",
      "Microondas",
      "Área de juegos infantiles",
      "Área de comedor independiente",
      "Snack bar o deli",
      "Guardería o actividades infantiles supervisadas (con cargo)",
    ],
  },
  {
    name: "Spa",
    icon: "SpaIcon",
    items: [
      "Tratamientos corporales",
      "Tratamientos faciales",
      "Manicures y pedicures",
      "Masajes",
      "Camastros en la alberca",
      "Sombrillas en la alberca",
      "Terraza",
    ],
  },
  {
    name: "Servicios para huéspedes",
    icon: "PersonIcon",
    items: [
      "Recepción abierta las 24 horas",
      "Servicio de limpieza diario",
      "Lavandería",
      "Resguardo de equipaje",
      "Personal multilingüe",
      "Botones",
      "Clases de tenis",
    ],
  },
  {
    name: "Idiomas",
    icon: "LanguageIcon",
    items: ["Inglés", "Español"],
  },
  {
    name: "Exteriores",
    icon: "DeckIcon",
    items: [
      "Camastros en la playa",
      "Sombrillas de playa",
      "Frente a la playa",
      "Jardín",
      "Camastros en la alberca",
      "Sombrillas en la alberca",
      "Terraza",
    ],
  },
  {
    name: "Servicios generales",
    icon: "AddIcon",
    items: [
      "Cajero automático o servicios bancarios",
      "Elevador",
      "Tienda de regalos o puesto de periódicos",
      "Supermercado o tienda de conveniencia",
    ],
  },
  {
    name: "Más",
    icon: "NetworkWifiIcon",
    items: ["Toallas de playa", "No se permite fumar en la propiedad"],
  }
];

const hotelDetailsMocks = {
  gallery: images,
  id: "asd12331132sdqf",
  name: "Live Aqua Beach Resort Cancún 23Car",
  aboutOfHotel:
    "En The Royal Cancun All Villas Resort, es un hotel de lujo todo incluido frente al mar. Las instalaciones incluyen 12 piscinas, de las cuales ocho son climatizadas y una tiene entrada tipo playa, ocho jacuzzis, gimnasio con sala separada para pilates y spinning, y un spa de dos niveles y tres secciones, una para el salón de belleza, otra para las áreas húmedas con hidroterapia y un centro de masajes tratamientos. Además, cuenta con siete restaurantes y 12 bares incluyendo seis con acceso a la piscina, una sala de cata y se ofrece servicio a la habitación en horario limitado para las habitaciones familiares y 24 horas para las habitaciones Elegance Club. Este hotel tiene un teatro para espectáculos en vivo durante el día y la noche, gazebo para bodas en la playa y en el jardín y centro de convenciones para 700 personas. Las habitaciones se dividen en dos secciones, una para familias y otra para adultos. Cada habitación está equipada con balcón o terraza con jacuzzi, puerta independiente para el servicio a la habitación, minibar de reposición diaria, Smart TV y se ofrece una botella",
  geoPosition: { latitude: 21.1492863, longitude: -86.8343926 },
  rooms: roomsDetailsMocks.newRooms,
  roomBestRate: roomsDetailsMocks.roomBest,
  stars: "Five",
  zone: "Zona hotelera",
  city: "Cancún",
  address:
    "Blvd Kukulcan, Km. 13.5, No. 32, Sección A, Segunda Etapa, Zona Hotelera. Cancún. Quintana Roo, 77500",
  notices: [
    {
      title: "Impuestos y propinas",
      description: "Todos los impuestos y propinas están incluidos.",
    },
    {
      title: "Habitaciones",
      description: "Incluye las habitaciones de tu elección.",
    },
    {
      title: "Actividades",
      description:
        "Actividades diarias conducidas por animadores incluyendo Jazzercise, aerobics y aquaerobics. El programa varía diariamente.",
    },
    {
      title: "Alimentos y bebidas",
      description:
        "Incluye desayuno, almuerzo y cena, incluyendo el restaurante de especialidades La Palapa y el snack-bar, así como bebidas nacionales.",
    },
    {
      title: "Entretenimiento",
      description:
        "Incluye entretenimiento nocturno y dos veces por semana. El programa varia diariamente.",
    },
    {
      title: "Deportes acuáticos",
      description:
        "Incluye actividades acuáticas como snorkel, bote de pedales y kayaks.",
    },
    {
      title: "Deportes en tierra",
      description:
        "Los deportes en tierra incluyen voleibol y fútbol de playa, ping pong y juegos de mesa.",
    },
    {
      title: "Restricciones",
      description: "Las bebidas importadas están disponibles con cargo extra.",
    },
  ],
  services: services,
  checkin: "13:00",
  checkout: "11:00",
  extendedCheckout: "500 USD",
  policies: {
    cancellationPolicies: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et suscipit tortor. Suspendisse at pulvinar nisl. Maecenas molestie feugiat commodo. Nam volutpat nibh a condimentum viverra. Vivamus lobortis erat at tristique viverra. Nulla ultrices pellentesque risus auctor scelerisque. Pellentesque semper, libero sed malesuada vehicula, eros eros ullamcorper nibh, et sollicitudin enim justo tempor nunc.",
    minYears: 25,
    petfriendly: "No se aceptan mascotas",
    additional: [
      "Los niños son bienvenidos.",
      "¡Los niños no pagan! La estancia de hasta 2 niños de 12 años o menos es gratuita siempre y cuando utilicen las camas existentes."
    ]
  }

};

export default hotelDetailsMocks;