import { useRef, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useLocalStorage } from "../../hooks/useLocalStorage";
import imageDefault from "../../assets/img/defaultImage.svg";
import ImageHolder from "../image/ImageHolder";
import { useEffect } from 'react';

const CarouselHolder = (props) => {
	const { data, name, className = "", interval = "100000" } = props;
	const [localImagesInvalid] = useLocalStorage('imagesInvalid', []);
	const imagesInvalid = useRef(localImagesInvalid);
	const [images, setImages] = useState(data.filter(img => !imagesInvalid.current.includes(img.url)));

	useEffect(() => {
		if (!data.length) return;
		const newImages = data.filter(img => !imagesInvalid.current.includes(img.url));
		setImages(newImages)
	}, [data]);

	const onError = (src) => {
		if (!imagesInvalid.current.includes(src)) {
			imagesInvalid.current.push(src);
		}
		const newImages = data.filter(img => !imagesInvalid.current.includes(img.url));
		setImages(newImages)
	};

	return (
		<div className={`container-caroulsel ${className}`}>
			{images.length === 0 &&
				<img
					alt={name}
					className="card-img"
					src={imageDefault}
				/>
			}
			{images.length === 1 &&
				<ImageHolder
					alt={images[0].name}
					src={images[0].url}
					onError={onError}
				/>}
			{images.length > 1 &&
				<Carousel>
					{images.map((img, index) => {
						return (
							<Carousel.Item interval={interval} key={`${name} - ${index}`}>
								<ImageHolder
									loading={index === 0 ? "eager" : "lazy"}
									alt={img.name}
									src={img.url}
									onError={index === 0 ? onError : null}
								/>
							</Carousel.Item>
						);
					})}
				</Carousel>
			}
		</div>
	)
}

export default CarouselHolder;