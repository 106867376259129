
const useCharterResponsive = (breakPoint) => {
    let charters = breakPoint.default;
    breakPoint.sizes && breakPoint.sizes.map((size) => {
        if(size.type){
            charters=size.charter;
        }

    })
    return charters
}
export default useCharterResponsive;