import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Offcanvas, Button } from "react-bootstrap";
import InputAutocomplete from "../../form/inputs/InputAutocomplete";
import ListCheck from "../../form/inputs/CheckBox/ListCheck";
import ListCheckBox from "../../form/inputs/CheckBox/ListCheckBox";
import iconContants from "../../../constants/iconContants";
import iconUtils from "../../../utils/iconUtils";
import "./components/DrawerFilter.scss";

const DrawerFilter = (props) => {
	const {
		filters,
		selecteds,
		suggestions,
		show,
		label,
		clearInputAutocomplete,
		buttonFloat,
		buttonShowIcon,
		buttonShowText = true
	} = props;
	const intl = useIntl();
	var _items = [];
	const [valueInput, setValueInput] = useState("");
	const updateValueInput = (response) => {
		switch (typeof response) {
			case "object":
				setValueInput(response.name);
				break;
			default:
				setValueInput(response);
				break;
		}
	};

	//TODO get value to list elements
	const handleToggle = (newValue) => {
		const { handleToggle } = props;
		const item = _items.find((i) => i.value == newValue.value);
		item.checked = newValue.checked;
		handleToggle && handleToggle(item);
	};

	//TODO GET LIST ITEMS
	const onInputChangeItems = (newValue, onSuccess) => {
		const { onInputAutocompleteChange } = props;
		updateValueInput(newValue);
		onInputAutocompleteChange && onInputAutocompleteChange(newValue, onSuccess);
	};

	//TODO ONCLICK SELECT
	const onInputChange = (newValue) => {
		const { onAutocompleteChange } = props;
		updateValueInput(newValue);
		onAutocompleteChange && onAutocompleteChange(newValue);
	};

	const onClickButton = (newValue) => {
		const { onClickButton } = props;
		onClickButton && onClickButton(newValue);
	};

	const handleOpenfilter = () => {
		const { onClickOpenFilter } = props;
		onClickOpenFilter && onClickOpenFilter();
	};

	const onClickButtonFilter = () => {
		const { onClickClearFilter } = props;
		onClickClearFilter && onClickClearFilter();
		updateValueInput("");
	};

	useEffect(() => {
		if (clearInputAutocomplete && valueInput)
			onInputChange("");
	}, [clearInputAutocomplete]);

	const buttonOnlyIcon = buttonShowIcon && !buttonShowText;
	return (
		<>
			<Offcanvas show={show} onHide={handleOpenfilter} className="filter-canvas">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>
						{intl.formatMessage({ id: "text.filter" })}
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<InputAutocomplete
						sxLabel={{ mt: "30px", fontSize: "20px" }}
						sxBox={{ px: "20px" }}
						onChange={onInputChange}
						onInputChange={onInputChangeItems}
						onClick={onClickButton}
						options={suggestions}
						id={"autocomplete-filter"}
						value={valueInput}
						placeholder={label}
					/>
					{
						filters.filter(f => f.code !== "STARS").map((filter, index) => {
							var id = ++index + "";
							var name = filter.code;
							var items = filter.items
								.map((item) => {
									var _item = {
										label: item,
										value: name + "_" + item.replace(/\s/g, "_").toUpperCase(),
										code: filter.code,
									};
									_items.push(_item);
									return _item;
								});

							var categoryItems = filter?.filters?.map(item => {
								var _item = {
									label: item.categoryNumber ?? item.category,
									value: name + "_" + item.category.replace(/\s/g, "_").toUpperCase(),
									code: filter.code,
									type: item.categoryIconType.toUpperCase()
								}
								_items.push(_item);
								return _item;
							});
							return filter.code === "CATEGORIES" ? (
								<ListCheckBox
									key={"stars" + "_" + id}
									title={filter.title}
									items={categoryItems}
									name="filter-hotel-category"
									onChange={handleToggle}
									selects={selecteds}
								/>
							) : (
								<ListCheck
									key={id}
									title={filter.title}
									items={items}
									controlShow={items.length > 5 ? 4 : items.length}
									name={name}
									onChange={handleToggle}
									selects={selecteds}
								/>
							);
						})}
				</Offcanvas.Body>
				<div className="offcanvas-bottom">
					<Button className="clear-filters" variant="link" onClick={onClickButtonFilter}>
						{iconUtils.icon(iconContants.TRASH)} {intl.formatMessage({ id: "text.filterClear" })}
					</Button>
				</div>
			</Offcanvas>
			{filters.length > 1 && <Button
				variant="primary"
				className={`button-filter-hotels ${buttonFloat ? 'button-float-invert' : ''} ${buttonOnlyIcon ? 'only-icon' : ''}`}
				onClick={handleOpenfilter}
			>
				{buttonShowIcon ? iconUtils.icon(iconContants.FILTER) : ''} {buttonShowText ? intl.formatMessage({ id: "text.filter" }) : ''}
			</Button>}
		</>
	);
};

export default DrawerFilter;
