
export default {    
    "packages.tua.title": "Airport Use Fee (TUA) Payment - Volaris gives you transparency in charges",
    "packages.tua.text": "The Airport Use Fee (TUA) is a payment required by airports for the use of their facilities - not for Volaris",
    "packages.tua.text1": "Payment of the Airport Use Fee (TUA) is required to proceed with your purchase",
    "packages.tua.alert": "You need to accept the Airport Use Fee (TUA) charge in order to travel",
    "packages.tua.btn": "Pay Airport Use Fee (TUA) of",
    "packages.text.detail.flight":"Flight detail",
    "packages.text.departure":"Departure flight",
    "packages.text.return":"Departure flight",
    "package.text.total": "Total",
    "package.text.test.date": "Tuesday, 7 february, 2023"
}