import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const TextArea = (props) => {
	const { item, id, onChange, onBlur, rows, maxLength = null, ...rest } = props;
	const [value, setValue] = useState('');

	const handleChange = (e) => {
		onChange && onChange(e.target);
		setValue(e.target.value);
	}
	const handleBlur = (e) => {
		onBlur && onBlur(e.target);
	}

	return (
		<>
			{item.label && <Form.Label htmlFor={id}>{item.label}</Form.Label>}
			{item.subLabel && <Form.Label htmlFor={id}>{item.subLabel}</Form.Label>}
			<Form.Control as="textarea"
				rows={rows}
				type={item.type}
				id={id}
				aria-describedby={item.aria ?? ''}
				onChange={handleChange}
				onBlur={handleBlur}
				maxLength={maxLength}
				{...rest}
			/>
			<Form.Text id={item.aria} muted>
				{item.validate}
			</Form.Text>
			{maxLength && (<div style={{ textAlign: 'right', marginTop: '5px' }}>
				<Form.Label style={{margin:0}}>{`${value.length}/${maxLength}`}</Form.Label>
			</div>)}
		</>
	)
}

export default TextArea;

TextArea.defaultProps = {
	rows: 3
}