import { useIntl } from "react-intl";
import { Card } from "react-bootstrap";
import CardContent from "./components/CardContent";
import CardRate from "./components/CardRate";
import CardTable from "./components/CardTable";
import CardContainerImage from "../../../components/cards/CardContainerImage";
import "./components/style.scss";

const CardHotelLarge = (props) => {
	const {
		service,
		currency,
		locale,
		sourceMarket,
		authSession,
		parameters,
		pathnames,
		type,
		border
	} = props;
	const intl = useIntl();
	const hasRate = service.bestRate ? true : false;
	const hasDiscount = service.hasOffers && service.offerSale ? true : false;

	return (
		<Card className={`card-sv-large-b2b mt-3 b2b-hotel ${border ? 'card-border' : ''}`} qui="card hotel-list-b2b">
			<div className="content-box">
				<CardContainerImage
					name={service.name}
					photos={service.photos}
					showDiscount={hasDiscount}
					discount={service.offerSale} />
				<div className="card-sv-content-info">
					<div className="card-sv-content-info-start">
						<CardContent item={service} type={type} />
					</div>
					<div className="card-sv-content-info-end">
						{hasRate && (
							<CardRate service={service} currency={currency} />
						)}
						{!hasRate && (
							<div className="container-not-avalability">
								<b>{intl.formatMessage({ id: "general.notAvailable" })}</b>
							</div>
						)}
					</div>
				</div>
			</div>
			{hasRate && (<CardTable
				key={service.id}
				hotelId={service.id}
				refundable={service.bestRate?.refundable}
				currency={currency}
				locale={locale}
				sourceMarket={sourceMarket}
				authSession={authSession}
				parameters={parameters}
				pathnames={pathnames}
			/>)}
		</Card>
	);
};

export default CardHotelLarge;
