const SimpleCardDescription = (props) => {
  const { item } = props;

  return (
    <><br />
      <div className="voucher-card-extra pago">
        <div className="w-100 ">
          <p>{item}</p>
        </div>
      </div>
    </>
  );
};

export default SimpleCardDescription;
