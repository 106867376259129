import { Component } from "react";
import './components/style.scss';
import { Form } from 'react-bootstrap';
import iconUtils from "../../../../utils/iconUtils";
import iconContants from "../../../../constants/iconContants";
import serviceTypeConstant from "../../../../constants/serviceTypeConstant";

class AutocompleteList extends Component {
	static defaultProps = {
		noSuggestions: "No suggestions, you're on your own!",
		id: "autocomplete-list",
		destinationIcon: iconContants.LOCATION,
		hotelIcon: iconContants.HOTEL,
		activityIcon: iconContants.ACTIVITY,
		minTextAPI: 1
	};

	constructor(props) {
		super(props);

		const {
			text,
			search,
			placeholder,
			id,
		} = this.props;

		this.state = {
			// The active selection's index
			activeSuggestion: 0,
			// The suggestions that match the user's input
			filteredSuggestions: search ? search : [],
			// Whether or not the suggestion list is shown
			showSuggestions: text ? true : false,
			// What the user has entered
			userInput: text ? text : "",
			placeholder: placeholder,
			id: id,
		};
	}

	onChange = e => {
		const { onChangeAutocomplete, minTextAPI } = this.props;
		const userInput = e.currentTarget.value;

		this.setState({
			...this.state,
			userInput: userInput
		});

		if (userInput.length >= minTextAPI) {
			onChangeAutocomplete && onChangeAutocomplete(userInput,
				(response) => {

					//TODO Filter our suggestions that don't contain the user's input
					const filteredSuggestions = response.items.filter(
						suggestion =>
							suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
					);

					this.setState({
						activeSuggestion: 0,
						filteredSuggestions,
						showSuggestions: true,
					});
				})
		}
	};

	//TODO enter when click to item
	//TODO get item select to list
	//TODO Send Code to item list
	onClick = e => {
		const { onChangeSelect, handleCloseModal } = this.props;

		//TODO if not send item the suggestion in the action
		//e.currentTarget.innerText
		this.setState({
			activeSuggestion: 0,
			filteredSuggestions: [],
			showSuggestions: false,
			userInput: e.name
		});

		const listSearch = this.state.filteredSuggestions;
		const { list } = this.props;
		//TODO return this active list
		list && list(listSearch);

		//TODO close modal
		onChangeSelect && onChangeSelect(e)
		handleCloseModal && handleCloseModal(false);

	};

	render() {
		const { noresultText, destinationIcon, hotelIcon, activityIcon } = this.props;
		const {
			onChange,
			onClick,
			// props: {
			//     //noSuggestions
			// },
			state: {
				activeSuggestion,
				filteredSuggestions,
				showSuggestions,
				userInput,
				placeholder,
				id,
			}
		} = this;


		let suggestionsListComponent;

		if (showSuggestions && userInput) {
			if (filteredSuggestions.length) {
				suggestionsListComponent = (
					<ul className="suggestions">
						{filteredSuggestions.map((suggestion, index) => {
							let className;

							//TODO Flag the active suggestion with a class
							if (index === activeSuggestion)
								className = "suggestion-active";

							return (
								<li className={className} key={suggestion.code} onClick={() => onClick(suggestion)}>
									<div>
										{suggestion.type === serviceTypeConstant.DESTINATION && iconUtils.icon(destinationIcon)}
										{suggestion.type === serviceTypeConstant.HOTEL && iconUtils.icon(hotelIcon)}
										{suggestion.type === serviceTypeConstant.TOUR && iconUtils.icon(activityIcon)}
									</div>
									<span>{suggestion.name}</span>
								</li>
							);
						})}
					</ul>
				);
			} else {
				suggestionsListComponent = (
					<div className="no-suggestions">
						<em>{noresultText}</em>
					</div>
				);
			}
		}

		return (
			<div className="autocomplete-list" id={id}>
				<Form.Control
					autoFocus
					type="text"
					onChange={onChange}
					value={userInput}
					placeholder={placeholder}
				/>
				{suggestionsListComponent}
			</div>
		);
	}
}

export default AutocompleteList;