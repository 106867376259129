const webSitesiteRoutesConstant = {
    HOME: "HOME",
    HOMEPACKAGE: "HOMEPACKAGE",
    HOMETOUR: "HOMETOUR",
    HOMETRANSFER: "HOMETRANSFER",
    HOMEHOTEL: "HOMEHOTEL",
    HOTEL: "HOTEL",
    PACKAGE: "PACKAGE",
    TOUR: "TOUR",
    FLIGHT: "FLIGHT",
    LISTHOTEL: "LISTHOTEL",
    LISTTRANSFER: "LISTTRANSFER",
    LISTTOUR: "LISTTOUR",
    LISTPACKAGE: "LISTPACKAGE",
    VOUCHERSTATIC: "VOUCHERSTATIC",
    VOUCHERHOTEL: "VOUCHERHOTEL",
    VOUCHERTOUR: "VOUCHERTOUR",
    VOUCHERTRANSFER: "VOUCHERTRANSFER",
    VOUCHERPACKAGE: "VOUCHERPACKAGE",
    RESERVATION: "RESERVATION",
    MYRESERVATION: "MYRESERVATION",
    NOTFOUND: "NOTFOUND",
    LANDINGHOME:"LANDINGHOME",
    LOGIN:"LOGIN",
    PRIVACY:"PRIVACY",
    TERMSANDCONDITIONS:"TERMSANDCONDITIONS",
    COOKIES:"COOKIES",
    VOUCHER: "/VOUCHER",
    VOUCHERPENDING: "/VOUCHERPENDING",
}

export default webSitesiteRoutesConstant;