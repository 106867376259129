import React from "react";
import { useEffect } from "react";
import { useGlobalState } from "../../store/StoreProvider";
import { useIntl } from "react-intl";
import apiCall from "../../siteResources/api";
import InformationSection from "../../components/section/InformationSection/InformationSection";

const NotFound = (props) => {
  const { resourceSite, locale } = useGlobalState();
  const intl = useIntl();
  const { options } = props;
  const onClickGoToHome = () => {
    window.location.href = resourceSite.options.home.href;
  };

  useEffect(() => {
    const resourcePage = resourceSite.options.siteRouter?.find(
      (a) => a.basename === "/notfound"
    );
    if (resourcePage) {
      const responsePage = apiCall(locale, resourcePage.typePage);

      document.title =
        responsePage?.title ??
        intl.formatMessage({ id: "general.notFoundTitle" });
    }
  }, []);

  return (
    <>
      <InformationSection
        type="404"
        title={intl.formatMessage({ id: "general.notFoundTitle" })}
        text={intl.formatMessage({ id: "general.notFoundText" })}
        btn={intl.formatMessage({ id: "general.goHome" })}
        onClickGoToHome={onClickGoToHome}
      />
    </>
  );
};

export default NotFound;
