import { useIntl } from "react-intl";
import { Container, Row , Button, Col, Breadcrumb } from "react-bootstrap";
import Gallery from "../../../components/galleries/Collage/Collage";
import { useGlobalState, useDispatch } from "../../../store/StoreProvider";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import rateUtils from "../../../utils/rateUtils";
import hotelsValidate from "../../../validate/hotelsValidate";
import serviceHotelAction from "../../../actions/serviceHotelAction";
import BoxBooking from "../../../components/bookings/BoxBooking/BoxBooking";
import serviceType from "../../../constants/serviceTypeConstant";
import checkoutUtils from "../../../utils/checkoutUtils";
import storage from "../../../localstorage/storage";
import iconUtils from '../../../utils/iconUtils'
import breakpointConstants from "../../../constants/breakpointConstants";
import BoxGrdFlight from "../../../components/boxes/BoxGridFlight/BoxGridFlight";
import flightDataMock from "../../../mock/flight/flightDataMock";
import flighRateFamilyMock from "../../../mock/flight/flightRateFamilyMock";
import dataPackageMock from "../../../mock/checkout/dataPackageMock";
import BoxReservationDetailFlight from "../../../components/boxes/BoxReservationDetailFlight/BoxReservationDetailFlight";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Modals from "../../../components/modals/Modals";
import Form from 'react-bootstrap/Form';
import BoxSelectedFlight from "../../../components/boxes/BoxSelectedFlight/BoxSelectedFlight";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import BoxDetailSeviceTitleRate from "../../../components/boxes/BoxDetailSeviceTitleRate";
import iconContants from "../../../constants/iconContants";
import BoxIconText from "../../../components/boxes/BoxIconText";
import BoxWhitoutResults from "../../../components/boxes/BoxWhitoutResults/BoxWhitoutResults";
import "./style.scss";

const Flight = (props) => {
  const { resourceSite } = props;
  const pathnames = resourceSite.siteRoutes;
  const searchHotelParams = resourceSite.searchHotelParams;
  const intl = useIntl();
  let navigate = useNavigate();
  const location = useLocation();
  const { hotel, currency, locale, authSession } = useGlobalState();
  const dispatch = useDispatch();
  const sourceMarket = "MX";
  const localizedUrlFlight = `/${locale}${pathnames.flight.pathname}`;
  const localizedUrlBooking = `/${locale}${pathnames.reservation.pathname}`;
  let [searchParams, setSearchParams] = useSearchParams();
  const [showAboutHotel, setShowAboutHotel] = useState(false);
  const [bodyRenderScroll, setBodyRenderScroll] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [expandedAmenities, setExpandedAmenities] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [titleFlight, setTitleFlight] = useState(false);
  const [roomIndexSelected, setRoomIndexSelected] = useState(0);
  const [typePrice, setTypePrice] =useLocalStorage('tPrice',''); //regular or club
  const [typeCategory, setTypeCategory] =useLocalStorage('tCategory',''); //basic, classic, plus
  const [idFlight, setIdFlight] = useState("")
  const [activeDrop, setActiveDrop] = useState(null);

  const selectIndexRoomByRatekey = (rooms, query) => {
    if (rooms.length > 0) {
      rooms.map((room, i) => {
        let ratekey = (room.rateKeyLead).split(`|${sourceMarket}`)[0];
        if (ratekey === query.ratekey) {
          setRoomIndexSelected(i)
          return i;
        }
		return -1;
      })
    }
  }

  const GetIdService = () => {
    const { id } = useParams();
    return id;
  };

  const [query, setQuery] = useState({
    keyword: GetIdService(),
  });
 

  const [params, setParams] = useState(null);

  if (typeof window !== 'undefined') {
    window.onscroll = function () {
      var y = window.scrollY;
      const elem = document.querySelector("#body-render");
      const rect = (elem != null && elem !== undefined && elem && elem.offsetTop) ? elem.offsetTop : 0;

        setBodyRenderScroll(y > (rect + 10))

        const elemtFlight = document.querySelector("#box-head-flight");
        const rectFlight = (elemtFlight != null && elemtFlight !== undefined && elemtFlight && elemtFlight.offsetTop) ? elemtFlight.offsetTop : 0;

        setIsHovering(y > (rectFlight - 90))


    };
  }
  const [searchFixed, setSearchFixe] = useState(false);

  const getHotel = async (parameters, queryUrl) => {
    const query = hotelsValidate.formatQueryDetailsFromParams({
      ...parameters,
      currency: currency.code,
    });

    if (query != null) {
      await serviceHotelAction.hotel(
        { ...query, locale, sourceMarket: sourceMarket, authSession },
        dispatch,
        (response) => {
          let par = { ...parameters };
          par.inputAutoValueStart.label = response.name;
          setParams(par);
          setSearchFixe(false);
          selectIndexRoomByRatekey(response.rooms, queryUrl);
        },
        (error) => {
          console.error(error);
          setSearchFixe(true);
        }
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const auxQuery = {
      ...query,
      checkin: searchParams.get("checkin"),
      checkout: searchParams.get("checkout"),
      paxes: searchParams.get("paxes"),
      ratekey: searchParams.get("ratekey"),
      leng: searchParams.get("leng"),
    };
    setQuery(auxQuery);
    const auxParams = hotelsValidate.paramsHotelFormat(auxQuery, searchHotelParams.paramsRoom.rules)
    setParams(auxParams);

    getHotel(auxParams, auxQuery);


    if(searchParams.get("leng") === "2"){ 
      const titletFlight = document.querySelector("#title-flight-page").offsetTop;
      setTimeout(() =>  window.scrollTo({
        top: titletFlight -5, 
        behavior: "smooth"  
      }), 2000)
    }

    if(searchParams.get("leng") === "1"){
      setTypePrice('');
      setTypeCategory('');
      seturlLink("1")
    }
    
  }, [currency, location]);
  
  //TODO get select room to go to reservation
  const onClickReservation = (room) => {
    const newHotel = { ...hotel };
    newHotel.rooms = [];
    newHotel.rooms.push(room);
    const request = checkoutUtils.reservationHotel(newHotel, params);
    storage.setLocalStorage(request.uuid, request, 15);
    const location = {
      pathname: localizedUrlBooking,
      search: `?${new URLSearchParams({ cart: request.uuid }).toString()}`,
    };
    navigate(location);
  };



  const [urlLink, seturlLink] = useState(searchParams.get("leng"));

  const onClickFlightRate = (tPrice, tCategory) => {
   
    if (urlLink === "1") {
      const url = {        
        //cart: query.cart,
        checkin: query.checkin,
        checkout: query.checkout,
        paxes: query.paxes,
        leng: "2",        
      };
      seturlLink(url.leng);
      setSearchParams(url);
      setTypePrice(tPrice);
      setTypeCategory(tCategory);
     
      const location = {
        pathname: localizedUrlFlight+query.keyword,
        search:`?${new URLSearchParams(url).toString()}`,
      };
      navigate(location);
    }
    if (urlLink === "2") {
      const room = JSON.parse(localStorage.getItem('room'))
      const newHotel = { ...hotel };
      newHotel.rooms = [];
      newHotel.rooms.push(room);
      //const request = dataPackageMock;
      const request = checkoutUtils.reservationPackage(newHotel, params);
      storage.setLocalStorage(request.uuid, request, 15);

      const location = {
        pathname: localizedUrlBooking,
        search: `?${new URLSearchParams({ cart: request.uuid }).toString()}`,
      };
      navigate(location);
    }
  };

  const routeChange = () =>{ 
    /* localStorage.setItem('activeDrop', JSON.stringify(null)); */
    setTypePrice('');
    setTypeCategory('');
    const url = {        
      checkin: query.checkin,
      checkout: query.checkout,
      paxes: query.paxes,
      leng: "1",        
    };
    seturlLink(url.leng);
    const location = {
      pathname: localizedUrlFlight+query.keyword,
      search:`?${new URLSearchParams(url).toString()}`,
    };
     
    navigate(location);
  }

  const getInfoDetailHotel = () => {
    return {
      type: serviceType.HOTEL,
      informationBoxTitle: {
        name: hotel.name,
        stars: hotel.stars,
        zone: {
          name: hotel.zone,
        },
        destination: {
          name: "",
        },
      },
      informationBoxServiceRate: {
        classs: "bg-rate mt-2",
        hasOffers: hotel.bestRate?.hasOffers,
        rateDiscount: hotel.bestRate.rateWithoutPromotional,
        classsThrough: hotel.bestRate?.hasOffers ? 'trought' : '',
        textFrom: intl.formatMessage({ id: "general.from" }),
        rate: hotel.bestRate?.totalPerNight,
        currency: currency.code,
        informations: [
          intl.formatMessage({ id: "general.bestPricePerRoom" }),
          intl.formatMessage({ id: "general.bestPricePerRoomTaxes" }),
        ],
        textButton: intl.formatMessage({ id: "general.moreRooms" })
      },
      informationBoxIconText: [{
        icon: iconUtils.getIconMealPlanType(hotel.bestRate?.mealPlanCode),
        classIcon: "",
        text: hotel.bestRate?.mealPlan,
        classTex: "",
      }
      ]
    }
  }

  const onClickShowAboutHotel = () => {
    setShowAboutHotel(!showAboutHotel)
  }
  const resetInitialRender = (searchBooking) => {
    if (
      searchBooking.inputAutoValueStart?.type === "Hotel" ||
      searchBooking.inputAutoValueStart?.type === "HotelDetails"
    ) {
      const auxQuery = {
        keyword: searchBooking.inputAutoValueStart.keyword,
        checkin: searchBooking.startDate,
        checkout: searchBooking.endDate,
        paxes: hotelsValidate.paxesQuery(searchBooking.rooms),
        leng: searchParams.get("leng"),
      };

      setQuery(auxQuery);
      const auxParams = hotelsValidate.paramsHotelFormat(auxQuery, searchHotelParams.paramsRoom.rules);
      setParams(auxParams);
      getHotel(auxParams, {});
    }
  }
  const onChangeBooking = (searchBooking) => {
      resetInitialRender(searchBooking);
  }
  const [isMobile, setIsMobile] = useState(false)

  const handleResize = () => {
  if (window.innerWidth < breakpointConstants.SM) {
      setIsMobile(true)
  } else {
      setIsMobile(false)
  }
  }


  useEffect(() => {
  window.addEventListener("resize", handleResize)

  },[])


  const numbers = [1, 2, 3, 4, 5];

  const renderTooltip = (props) => (
    <Popover id="button-tooltip" {...props}>
        <span className="p-2 d-block toolTip-list">
          <b>v.club</b> es la membresía que siempre te permite viajar al precio más bajo, independientemente de cómo elijas volar.
        </span>
    </Popover>
);

const renderTooltipTua = (props) => (
  <Popover id="button-tooltip" {...props}>
      <span className="p-2 d-block toolTip-list">
       la TUA no es parte del costo de la tarifa, <b>si no que corresponde al aeropuerto</b>.
      </span>
  </Popover>
);


const onClickToScrollFlight = (id) => {
  setIdFlight(id);
  var elementPosition = document.querySelector(id).offsetTop;
  window.scrollTo({
    top: elementPosition - 180, //add your necessary value
    behavior: "smooth"  //Smooth transition to roll
  });
}

const [fullscreen, setFullscreen] = useState(true);
const [showReservation, setShowReservation] = useState(false);

function handleShowReservation(breakpoint) {
    setFullscreen(breakpoint);
    setShowReservation(true);
}
const handleCloseModalReservation = () => setShowReservation(false);

  return (
    <div id="body-render">
        <div id="booking-hotel" className={`search-box-service-hotel  ${bodyRenderScroll ? 'box-fixed' : ''}`}>
            <Container fluid="xl">
            <Row>
                <Col lg={10} className="">
                <div className="pt-3">                
                    {!bodyRenderScroll && (
                    <Breadcrumb className="d-none d-sm-block">
                        <Breadcrumb.Item href={`/${locale}`}>{intl.formatMessage({
                        id: "general.breadcrumdHome",
                        })}</Breadcrumb.Item>
                        <Breadcrumb.Item active >{intl.formatMessage({ id: "general.breadcrumdPaquetes", })}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{hotel?.name}</Breadcrumb.Item>
                    </Breadcrumb>
                    )}
                </div>
                {params && (
                    <div className="pb-4">                    
                        <BoxBooking
                        params={params}
                        locale={locale}
                        title=""
                        siteRoutes={resourceSite.siteRoutes}
                        componentTitle="h2"
                        closeColorButton="white"
                        type={serviceType.PACKAGE}
                        searchHotelParams={searchHotelParams}
                        onChangeBooking={onChangeBooking}
                        noresults={searchFixed}
                        />
                    
                    </div>
                )}
                <div className="d-flex justify-content-between">
                  <div className={`title-flight-info top-info-flight ${isHovering ? "d-block w-75" : "d-none"}`}>
                            {query.leng === "2" ? (
                              <>
                                <h3 className="mb-0">Elige tu vuelo de regreso de</h3>
                                <p>Cd. México (Todos los aeropuertos) a Cancún</p>
                              </>                           
                            ) : (
                              <>
                                <h3 className="mb-0">Elige tu vuelo de salida de</h3>
                                <p>Cd. México (Todos los aeropuertos) a Cancún</p>
                              </>                           
                            ) }
                  </div>
                  <div className={`d-md-none d-lg-none d-xl-none  mb-2 ${isHovering ? "w-25" : "w-100"}`}>                 
                        <div className="d-flex justify-content-end">
                          <button  onClick={() => handleShowReservation(true)} type="button" className="btn-reservation btn btn-primary d-flex align-items-baseline"> {iconUtils.icon(iconContants.BSFILLCARTFILL)} <span className="ms-1">$76 MXN</span></button>
                        </div>                                      
                  </div>
                  <Modals 
                     fullscreen={fullscreen}
                     show={showReservation}
                     showClose={false}
                     handleCloseModal={handleCloseModalReservation}
                     title="Tu reservación"
                  >
                      <BoxReservationDetailFlight position={query.leng} showTitle={false} />
                  </Modals>
                </div>
                
                </Col>
                <Col lg={2}  className={isHovering ? "d-none" : "d-block"} >
                    {hotel.isSuccess && (<>
                    <div className="d-flex align-items-center h-100 rate-top" >
                      <div className="box-rate-top">                   
                          <div className="item-rate-top">
                          {intl.formatMessage({ id: "general.from" }) && (<div className="rate-from fs-6 mt-1">{intl.formatMessage({ id: "general.vuelohotel" })}</div>)}
                          <span>
                            {rateUtils.formatAmount(hotel.bestRate?.totalPerNight, currency.code, locale)}
                          </span> 
                          </div>
                          <div className="item-rate-top">
                          <span className="text-rate-top">{intl.formatMessage({ id: "text.perperson" })}</span>
                          <span className="text-rate-top">{intl.formatMessage({ id: "general.bestPricePerRoomTaxes" })}</span>
                          </div>
                      </div>
                                     
                    </div>                     
                 </> 
                )}
                </Col>
                
            </Row>
            </Container>                
        </div>       
       <div className="py-5">
            <Container fluid="xl">
                <Row>
                    <Col lg={9} className="">
                        <div className="">
                          {query.leng === "2"  && (<>
                            <h3>Vuelos seleccioandos</h3>
                            <BoxSelectedFlight 
                              OnClickRouteChange={routeChange} 
                              activeDrop={activeDrop} 
                              typePrice={typePrice} 
                              typeCategory={typeCategory}/>
                          </>
                           
                          )}                          
                        </div>
                        <div className="tua-flight mb-4">
                            Los precios anunciados son por pasajero, <b>deberás cubrir la Tarifa de Uso de Aeropuerto (TUA) conoce su costo en el siguiente paso </b>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipTua}
                            >
                              <span className="tooltip-club"> {iconUtils.icon(iconContants.BSFILLQUESTIONCIRCLEFILL)}</span>
                            </OverlayTrigger>
                        </div>                       
                        <div className="title-flight-info mb-5" id="title-flight-page">
                          {query.leng === "2" ? (
                            <>
                              <h3 className="mb-0">Elige tu vuelo de regreso de</h3>
                              <p>Cd. México (Todos los aeropuertos) a Cancún</p>
                            </>                           
                          ) : (
                            <>
                              <h3 className="mb-0">Elige tu vuelo de salida de</h3>
                              <p>Cd. México (Todos los aeropuertos) a Cancún</p>
                            </>                           
                          ) }
                        </div>
                        <div className="flightListsHead" id={isHovering ? "fixed-head" : ""}>
                          <Row>
                            <Col md={7}>
                              <div className={isHovering ? "d-none" : "d-block"} >
                                <div className="d-flex align-items-center mb-2">
                                  <div className="me-1">Ordenado por:</div>
                                  <div className="w-50">
                                    <Form.Select aria-label="Default select example w-25">
                                      <option value="1"> Recomendado</option>
                                      <option value="2"> Precio (más barato)</option>
                                      <option value="3"> Precio (más caro)</option>
                                    </Form.Select>
                                  </div>                                   
                                </div>                              
                              </div>
                            </Col>
                            <Col md={5}>
                              <div className="head-tab-flight d-none d-md-flex" >
                                {typePrice != "club" && (
                                  <div className="regular-flight">
                                    <h4 className="label-heading"><span>Precio Regulares</span></h4>
                                  </div>
                                )}
                                
                                {typePrice != "regular" && (
                                  <div className="club-flight">
                                  <h4 className="label-heading">
                                    <span>Precios v.club</span>                                    
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                    >
                                      <span className="tooltip-club"> {iconUtils.icon(iconContants.AIOUTLINEEXCLAMATINCIRCLE)}</span>
                                    </OverlayTrigger>
                                  </h4>
                                </div>
                                )}
                                
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div id="box-head-flight">
                          {numbers.map((number) =>                      
                              <BoxGrdFlight
                              key={number.toString()}
                              flightItem={flightDataMock}
                              family={flighRateFamilyMock.family}
                              id={number}
                              onClickButton={onClickToScrollFlight}
                              position={query.leng}
                              onClickFlightRate={onClickFlightRate}
                              typePrice={typePrice}
                              typeCategory={typeCategory}
                              idFlight={idFlight}
                            
                              />
                          )}
                        </div>
                        
                         
                    </Col>
                    <Col lg={3} className="d-none d-lg-block">
                        <BoxReservationDetailFlight position={query.leng} />
                    </Col>
                </Row>
               {/* <div id="id-1"></div> */}
            </Container>
       </div>
    </div>
  );
};

export default Flight;
