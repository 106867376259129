import types from "../constants/reducers/paymentConstants";

const get = (payment) =>{
    payment.isFetching = false;
    payment.isSuccess= true;
    payment.isError= false;
    return {type: types.PAYMENT, payment};
 };
 const fetching = () =>{
    const payment = {
        isFetching: true,
        isSuccess: false,
        isError: false
    };
    return {type: types.PAYMENT_FETCHING, payment};
 };
 const setItem = () =>{
    const payment = {
        isFetching: false,
        isSuccess: false,
        isError: false
    };
    return {type: types.PAYMENT_FETCHING, payment};
 };
const getError = () =>{
    const payment = {
        isFetching: false,
        isSuccess: false,
        isError: true,
    }
    return {type: types.PAYMENT_ERROR, payment};
};

 // variables to cancel previous api call
 let controller, signal;

const payment = async (request, dispatch, onSuccess, onError) => {
    const {authSession, ...rest} = request;
    // cancel previous api call
    if (controller) {
      controller.abort()
    }
    controller = new AbortController()
    signal = controller.signal;
    dispatch(fetching());
    await fetch(`${process.env.REACT_APP_BFF_API}${process.env.REACT_APP_BFF_PAYMENNTS}`, {
        signal: signal,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authSession.token}`
        },
        body: JSON.stringify(rest)
    })
    .then((response) => {
        if(response.ok)
            return response.json();
        throw new Error('Something went wrong.');
    })
    .then((data) => {
        dispatch(get(data));
        if(onSuccess && typeof onSuccess === 'function') {
            onSuccess(data);
        }
    })
    .catch(ex => {
        dispatch(getError());
        if(onError && typeof onError === 'function'){
           onError(ex);
        }
    });
}

const setPayment = async (dispatch) => {
    dispatch(setItem());
}

const paymentAction = {
    payment,
    setPayment,
}

export default paymentAction;