
import { Col, Card, } from 'react-bootstrap';
import { useIntl } from "react-intl";
import Placeholder from 'react-bootstrap/Placeholder';
import "./components/style.scss";

//TODO card for activities in tour detail
function SkeletonVoucher(props) {
  // const { number,  } = props;
  const intl = useIntl();


  const optionsOverlay = {
    placement: "top",
    delay: { show: 250, hide: 400 }
  }

  return (
    <>
      <Card className="skeleton-voucher-card" >
        <Placeholder className="skeleton-voucher-card-header" animation="glow">
          <Placeholder className="skeleton-voucher-card-img" />
          <div className="skeleton-voucher-header-text border-bottom-solid">
            <div className="skeleton-voucher-header-text-left">
              <Placeholder xs={8} size="lg" />
              <Placeholder xs={4} />
              <Placeholder xs={6} />
              <Placeholder xs={6} />
            </div>
            <div className="skeleton-voucher-header-text-right">
              <Placeholder xs={8} />
              <Placeholder xs={6} />
              <Placeholder xs={8} />
              <Placeholder xs={6} />
            </div>
          </div>
        </Placeholder>
        <Card.Body className='skeleton-voucher-card-body  border-bottom-solid'>
          <Placeholder animation="glow" size="lg">
            <Placeholder xs={6} size="lg"></Placeholder>
          </Placeholder>
          <div className='skeleton-voucher-card-body-container whidth-body-first'>
            <div className="skeleton-voucher-body-text-left">
              <Placeholder animation="glow">
                <Placeholder xs={6} />
                <Placeholder xs={8} />
                <Placeholder xs={6} />
                <Placeholder xs={8} />
                <Placeholder xs={6} />
                <Placeholder xs={8} />
              </Placeholder>
            </div>
            <div className="skeleton-voucher-body-text-right">
              <Placeholder animation="glow">
                <Placeholder xs={8} />
                <Placeholder xs={6} />
                <Placeholder xs={8} />
                <Placeholder xs={6} />
                <Placeholder xs={8} />
                <Placeholder xs={6} />
              </Placeholder>
            </div>
          </div>
        </Card.Body>
        <Card.Body className='skeleton-voucher-card-body body-last'>
          <Placeholder animation="glow" size="lg">
            <Placeholder xs={3} size="lg"></Placeholder>
          </Placeholder>
          <div className='skeleton-voucher-card-body-container'>
            <div className="skeleton-voucher-body-text-left">
              <Placeholder animation="glow">
                <Placeholder xs={8} />
                <Placeholder xs={8} />
                <Placeholder xs={8} />
                <Placeholder xs={8} />
              </Placeholder>
            </div>
            <div className="skeleton-voucher-body-text-right">
              <Placeholder animation="glow">
                <Placeholder xs={8} />
                <Placeholder xs={8} />
                <Placeholder xs={8} />
                <Placeholder xs={8} />
              </Placeholder>
            </div>
            <div className="skeleton-voucher-body-text-right">
              <Placeholder animation="glow">
                <Placeholder xs={8} />
                <Placeholder xs={8} />
                <Placeholder xs={8} />
                <Placeholder xs={8} />
              </Placeholder>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default SkeletonVoucher;