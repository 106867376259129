import { useIntl } from "react-intl";

const SpecialCommentsSection = (props) => {
  const { itinerary } = props;
  const intl = useIntl();

  return (
    <>
      {itinerary.comments && (
        <>
          <br />
          <div className="voucher-card-extra special comments">
            <div className="comentarios">
              <div className="container-subtitle">
                <h2 className="text-subtitle">
                  {intl.formatMessage({ id: "voucherPage.form.special" })}
                </h2>
              </div>
              <br />
              <p className="text-comments">{itinerary.comments}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SpecialCommentsSection;
