export default {
    'stripe.card_declined': 'La tarjeta ha sido rechazada, intente con otra tarjeta',
    'stripe.invalid_cvc': 'El número CVC es incorrecto.',
    'stripe.insufficient_funds': 'La tarjeta no tiene fondos suficientes para completar la compra, intente con otra tarjeta.',
    'stripe.processing_error': 'Se produjo un error al procesar la tarjeta, intente nuevamente.',
    'stripe.incorrect_number':  'El número de la tarjeta es incorrecto, intente con otra tarjeta.',
    'stripe.invalid_expiry_year':  'El año de tarjeta es incorrecto.',
    'stripe.invalid_expiry_month': 'El mes de tarjeta es incorrecto.',  
    'stripe.expired_card':  'La tarjeta ha expirado, intente con otra tarjeta valida.',
    'stripe.incomplete_zip':  'Codigo postal incompleto',
}