import "./components/style.scss";

const BoxTextBanner = (props) => {
  const { options } = props;
  const formbasic = () => document.getElementById("affiliation-container");

  return (
    <div className="box-text-banner-container">
      <span className="title">{options.title}</span>
      <span className="subtitle">{options.subtitle}</span>
      {options.btn && (
        <button
          onClick={() => {
            formbasic().scrollIntoView({ behavior: "smooth" });
          }}
          className="btn"
        >
          {options.btn}
        </button>
      )}
    </div>
  );
};
export default BoxTextBanner;
