import FooterLayout from '../FooterLayout';
import { IntlProvider } from "react-intl";
import { useEffect, useState } from 'react';
import languages from '../../../languages';
import { useDispatch, useGlobalState } from '../../../store/StoreProvider';
import languageConstants from "../../../constants/languageConstants";
import resourceSiteAction from '../../../actions/resourceSiteAction';
import siteResource from "../../../siteResources";
import HeaderLayoutCustom from '../HeaderLayoutCustom';
import storage from '../../../localstorage/storage';
import siteDataAction from '../../../actions/siteInformationAction'; 
import commonUtils from '../../../utils/commonUtils';
import SkeletonPage from "../../../components/skeleton/SkeletonPage";

const LayoutComponent = (props) => {
  const { component: ChildrenPage, positionMenu, routeType,
    positionPage,
    metasPage,
  } = props;
  const { locale, currency, resourceSite, pageOptions,siteInformation } = useGlobalState();
  const dispatch = useDispatch();
  const [messages, setMessages] = useState(null)
  const [currencyCode, setCurrencyCode] = useState(currency.code);

  //TODO retorna el numero por la posicion del idioma
  const getValueIndexLanguage = (locale) => {
    const enumLang = Object.values(languageConstants)
    const auxLocale = enumLang.findIndex(a => a.toLowerCase() === locale.toLowerCase())
    return auxLocale;
  }
  const [interLocaleIndex, setInterLocaleIndex] = useState(getValueIndexLanguage(locale));


  useEffect(() => {
    setMessages(languages[locale]);
    let auxResourceSite = siteResource(locale);
    // Obtiene las llaves del storage
    const environmentKeys = commonUtils.getEnvironmentKeys(locale);
    let siteInformationData = storage.getLocalStorage(
      environmentKeys.siteInfoKey
    );
 if(siteInformationData){
    siteDataAction.siteResourceFormat(
      siteInformationData,
      auxResourceSite,
      dispatch
    );
 }else{
 if(!siteInformation.isFetching){


  const siteRequest = {
    domain: environmentKeys.hostname
  }

  siteDataAction.siteData(siteRequest, auxResourceSite, dispatch,

    (_error) => {
      //TODO si falla la api actualiza el contexto con la informacion de los recursos locales 
      resourceSiteAction.update(auxResourceSite, dispatch);
    })
 }
}

  }, [locale]);

  //TODO controlado el stado por el useChangeLenguage
  const handleChangeLanguage = (value) => {
    setInterLocaleIndex(value);
  }
  //TODO controlado el stado por el useChangeCurrency
  const handleChangeCurrency = (value) => {
    setCurrencyCode(value)
  }
  const restFooter = () => {
    const { footer, ...rest } = resourceSite.options.footerInformation;
    const resto = {
      ...rest
    }

    return (!pageOptions.isFetching && !pageOptions.isSuccess && !pageOptions.isError) ? resto : {}
  }

  return ((messages != null && locale != null && resourceSite.isSuccess) ?
    <IntlProvider locale={locale}  defaultLocale={locale} messages={messages}>
      <div className='container-site'>
        {((pageOptions.isSuccess && pageOptions.hasHeaderLayout)
          || (!pageOptions.isFetching && !pageOptions.isSuccess && !pageOptions.isError)
          || (!pageOptions.isFetching && !pageOptions.isSuccess && pageOptions.isError)
        )
          && (
        
            <HeaderLayoutCustom
              routeType={routeType}
              positionMenu={positionMenu}
              //TODO agregarlo en los llamadoas principales
              {...resourceSite.options.headerInformation}
            />
          )}
        <div className={`item-flex-grow ${(!pageOptions.isFetching && !pageOptions.isSuccess && !pageOptions.isError) ? '' : 'son-layout'}`}>
          <ChildrenPage locale={locale}
            resourceSite={resourceSite.options} metasPage={metasPage}
            positionPage={positionPage} />
        </div>
        {((pageOptions.isSuccess && pageOptions.hasFooterLayout)
          || (!pageOptions.isFetching && !pageOptions.isSuccess && !pageOptions.isError)
          || (!pageOptions.isFetching && !pageOptions.isSuccess && pageOptions.isError)
        )
          && (
            <FooterLayout
              footerInformation={resourceSite.options.footerInformation.footer}
              {...restFooter()}
            />
          )}
      </div>
    </IntlProvider> : <SkeletonPage/>
  );
}
LayoutComponent.defaultProps = {
}
export default LayoutComponent;