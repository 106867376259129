import { Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import CardContent from "./components/CardContent";
import iconUtils from "../../../utils/iconUtils";
import Popover from "react-bootstrap/Popover";
import BoxIconText from "../../../components/boxes/BoxIconText";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import iconContants from "../../../constants/iconContants";
import LabelFloat from "../../label/LabelFloat/LabelFloat";
import CardContainerImage from "../../../components/cards/CardContainerImage";
import "./components/cardService.scss";

function CardContainerService(props) {
	const { item, currency, txt, type, border } = props;
	const exclusiveDeal = item.promotions ?? null;
	const hasDiscount = item.hasOffers && item.offerSale ? true : false;
	const hasRate = item.bestRate ? true : false;
	const renderTooltip = (props) => (
		<Popover id="button-tooltip" {...props}>
			<span className="p-2 d-block toolTip-list">
				<BoxIconText
					items={exclusiveDeal.map((itm) => {
						return {
							icon: iconContants.FICHECK,
							classIcon: "color-primary",
							text: itm,
						};
					})}
				/>
			</span>
		</Popover>
	);

	return (
		<Col className="card-container" qui="card hotel-list" xs={12} sm={6} lg={4} xl={3}>
			<Card className={border ? 'card-border' : ''} style={{ width: "100%", cursor: "pointer", width: "19rem" }}>
				{item.hasPromotions &&
					<OverlayTrigger
						placement="top"
						delay={{ show: 250, hide: 400 }}
						overlay={renderTooltip}>
						<span variant="success"
							className="icono icon-promotion">
							<span className="porcen">%</span>{" "}
							{iconUtils.icon(iconContants.FATAGS)}
						</span>
					</OverlayTrigger>
				}
				<div>
					{hasRate && <Link to={item.url} reloadDocument className="url-card-services"></Link>}
					<CardContainerImage
						name={item.name}
						photos={item.photos}
						showDiscount={hasDiscount}
						discount={item.offerSale}
						position='right' />
					{item.typeCategory && <LabelFloat text={item.typeCategory} />}
					<Card.Body>
						<CardContent
							item={item}
							txt={txt}
							type={type}
							currency={currency}
						/>
					</Card.Body>
				</div>
			</Card>
		</Col>
	);
}

export default CardContainerService;