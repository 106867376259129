import React, { useEffect } from "react";
import Swal from "sweetalert2";
import dateUtils from "../utils/dateUtils";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

const useModal = (item, isFetching = 0, callbackfun) => {
  const intl = useIntl();
  let navigate = useNavigate();
  var textModal = item.text;
  var textBtn = item.btn;
  var time = item.timer ?? 20;
  let colorModal = require(`../theme/site/${process.env.REACT_APP_BRANDING}/vars.scss`).default;
  
  const modalPop = (textModal) => {
    Swal.fire({
      icon: textModal?.icon ?? "info",
      title: textModal?.title,
      text: textModal?.text,
      confirmButtonText:
        textBtn ?? intl.formatMessage({ id: "checkout.confirmButtonText" }),
      confirmButtonColor: colorModal.modalColor,   
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (callbackfun) {
          callbackfun();
        } else {
          navigate(0);
        }
      }
    });
  };

  useEffect(() => {
    if (isFetching) {
      Swal.close();
      const timerModal = setTimeout(() => {
        modalPop(textModal[0]);
      }, dateUtils.minutesToSecondsDate(time));

      return () => {
        clearTimeout(timerModal);
        Swal.close();
      };
    }
  }, [isFetching]);

  useEffect(() => {
    return () => {
      Swal.close();
    };
  }, []);
};

export default useModal;
