
import localeUtils from "../utils/localeUtils";
import toursValidate from "../validate/toursValidate";
import types from "../constants/reducers/hotelConstants";
import tourDetailMock from "../mock/tour/tourDetailMock";
import tourActivitiesMock from "../mock/tour/tourActivitiesMock"
//TODO: get hotel
const get = (hotel) =>{
    hotel.isFetching = false;
    hotel.isSuccess= true;
    hotel.isError= false;
    return {type: types.HOTEL, hotel};
 };
 const fetching = () =>{
    const hotel = {
        isFetching: true,
        isSuccess: false,
        isError: false
    };
    return {type: types.HOTEL_FETCHING, hotel};
 };
const getError = () =>{
    const hotel = {
        isFetching: false,
        isSuccess: false,
        isError: true,
    }
    return {type: types.HOTEL_ERROR, hotel};
};
//TODO crear context tour
//TODO Get tour with context and use Ditpatch
const tour = async (request, dispatch, onSuccess, onError) => {
    const {locale, authSession, ...rest} = request;
    const language = localeUtils.handleLanguage(locale);
    dispatch(fetching());
    await fetch(`${process.env.REACT_APP_BFF_API}tour/detail`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authSession.token}`
        },
        body: JSON.stringify({...rest, culture: language, 
            sourceMarket: request.sourceMarket
        })
    })
    .then((response) => {
        if(response.ok)
            return response.json();
        throw new Error('Something went wrong.');
    })
    .then((data) => {
        const response = toursValidate.formatDetails(data, request);
        dispatch(get(response));
        if(onSuccess && typeof onSuccess === 'function'){
            onSuccess(response);
         }
    })
    .catch(ex => {
        dispatch(getError())
        if(onError && typeof onError === 'function'){
           onError(ex);
        }
    });
}
//TODO get fake hotel
const tourMock = async (request, dispatch, onSuccess, _onError) => {
    const response = toursValidate.formatDetails(tourDetailMock, request);
    dispatch(get(response));
    await onSuccess(response);
}
const ActivitiesMock = async (request, dispatch, onSuccess, _onError) => {
    const response = toursValidate.formatDetails(tourActivitiesMock, request);
    dispatch(get(response));
    await onSuccess(response);
}

const serviceTourAction = {
    tour,
    tourMock,
    ActivitiesMock,
}
export default serviceTourAction;