import iconUtils from "../../../../utils/iconUtils";
import iconContants from "../../../../constants/iconContants";
import languageConstants from "../../../../constants/languageConstants";
import moment from "moment/moment";
import images from "../../../../theme/ImageSite";


const startDate= moment(new Date()).add(7,'days').format("yyyy-MM-DD");
const endDate= moment(new Date()).add(9,'days').format("yyyy-MM-DD");


const footerComponent = {
  type: "footer",
  showSocial: true,
  showText: true,
  secondMenu: false,
  contact: false,
  footer: {
    alt: "Nexus Vacations",
    href: `/${languageConstants.MX}`,
    imgPath: images?.footerLogo ?? "",
    textMini: "Síguenos en redes sociales",
    columnsLinks: [
      {
        textTitle: "Sobre nosotros:",
        links: [
          {
            text: "Quienes somos",
            url: `https://corporate.nexustours.com/es/corporativo/quienes-somos/`,
            hasLink: true,
            applyExternalLink: true,
            target:"_blank"
          },
          {
            text: "Política de privacidad",
            url: `https://www.nexustours.com/privacy-policy`,
            hasLink: true,
            applyExternalLink: true,
            target:"_blank"
          },
          {
            text: "Grupos",
            url: `https://www.nexustours.com/groups-and-weddings`,
            hasLink: true,
            applyExternalLink: true,
            target:"_blank"
          },
          {
            text: "Nuestros Destinos",
            url: `https://corporate.nexustours.com/groups/destinations/`,
            hasLink: true,
            applyExternalLink: true,
            target:"_blank"
          },
          {
            text: "Blog de Viaje",
            url: `https://www.nexustours.com/travel-blog`,
            hasLink: true,
            applyExternalLink: true,
            target:"_blank"
          },
          {
            text: "Estar seguro con Nexus",
            url: `https://www.nexustours.com/besafe`,
            hasLink: true,
            applyExternalLink: true,
            target:"_blank"
          },
          {
            text: "Términos y Condiciones",
            url: `https://www.nexustours.com/terms-and-conditions`,
            hasLink: true,
            applyExternalLink: true,
            target:"_blank"
          },
          {
            text: "Contáctanos",
            url: `https://www.nexustours.com/contact-us`,
            hasLink: true,
            applyExternalLink: true,
            target:"_blank"
          },
        ],
      },
      {
        textTitle: "Usuarios",
        links: [
          {
            text: "Acuerdo del Usuario",
            url: `https://www.nexustours.com/user-agreement`,
            hasLink: true,
            applyExternalLink: true,
            target:"_blank"
          },
          {
            text: "Preguntas Frecuentes",
            url: `https://www.nexustours.com/faq`,
            hasLink: true,
            applyExternalLink: true,
            target:"_blank"
          },
          {
            text: "Acceso Agencia",
            url: `https://tpp.nexustours.com/login/`,
            hasLink: true,
            applyExternalLink: true,
            target:"_blank"
          },
        ],
      },
    ],
    social: [
      {
        icon: iconUtils.icon(iconContants.FAFACEBOOKF),
        url: "https://www.facebook.com/nexus.tours.activities",
        active: true,
      },
      {
        icon: iconUtils.icon(iconContants.FATWITTER),
        url: "https://twitter.com/i/flow/login?redirect_after_login=%2FNexusTours",
        active: true,
      },
      {
        icon: iconUtils.icon(iconContants.FAINSTAGRAM),
        url: "https://www.instagram.com/nexustours/",
        active: true,
      },
      {
        icon: iconUtils.icon(iconContants.FaLINKEDIN),
        url: "https://www.linkedin.com/company/nexustours/",
        active: true,
      },
      {
        icon: iconUtils.icon(iconContants.FATRIPADVISOR),
        url: "https://www.nexustours.com/tripadvisor-reviews",
        active: true,
      },
    ],
  },
};

export default footerComponent;