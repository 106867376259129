import { Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import dateUtils from "../../../../utils/dateUtils";
import serviceTypeConstant from "../../../../constants/serviceTypeConstant";
const CardContent = (props) => {
	const { txt, searchBooking, locale, item } = props;
	const intl = useIntl();
	const styleDates = {
		textTransform: "capitalize"
	}

	return (<>
		<div qui="con box-summary-content" className='box-summary-div'>
			<div className='box-summary-item'>
				{searchBooking.type === serviceTypeConstant.TRANSFER && (
					<p qui="p checkout-airport-transfer" ><b>{intl.formatMessage({ id: "checkout.airport" })}</b></p>
				)}
				{searchBooking.type !== serviceTypeConstant.TOUR && (
					<small qui="small checkin-title"  >{txt?.checkin}</small>
				)}
				<p qui="p checkin-date" style={styleDates} className="mb-1 fw-bold">{dateUtils.dateFormat(searchBooking?.startDate, "DD-MMM-YYYY", locale.split('-')[0])}</p>
				{searchBooking.type === serviceTypeConstant.TRANSFER && (
					<small>01:00</small>
				)}
			</div>
			{searchBooking.type !== serviceTypeConstant.TOUR && (
				<>
					<div className='box-summary-item'>
						{searchBooking.type === serviceTypeConstant.TRANSFER && (
							<p><b>{intl.formatMessage({ id: "checkout.commodation" })}</b></p>
						)}
						<small qui="small checkout-title" >{txt?.checkout}</small>
						<p qui="p checkout-date" style={styleDates} className="mb-1 fw-bold">{dateUtils.dateFormat(searchBooking?.endDate, "DD-MMM-YYYY", locale.split('-')[0])}</p>
						{searchBooking.type === serviceTypeConstant.TRANSFER && (
							<small qui="p checkout-hour">18:00</small>
						)}
					</div>
				</>
			)}
		</div>
		{searchBooking.type === serviceTypeConstant.TRANSFER && (
			<>
				<div className='box-summary-div mt-3'>
					<div className='box-summary-item'>
						<p qui="p checkout-startingPoint" className="mb-1">{intl.formatMessage({ id: "checkout.startingPoint" })}</p>
						<small>Aeropuerto Internacional de Cancún</small>
					</div>
				</div>
				<div className='box-summary-div mt-3'>
					<div className='box-summary-item'>
						<p qui="p checkout-arrivalPoint" className="mb-1">{intl.formatMessage({ id: "checkout.arrivalPoint" })}</p>
						<small>Hotel Xcaret México</small>
					</div>
				</div>
				<div className='box-summary-div mt-3'>
					<div className='box-summary-item'>
						<p qui="p checkout-vehicleCapacity" className="mb-1">{intl.formatMessage({ id: "checkout.vehicleCapacity" })}</p>
						<small>Hasta {item.maxNumberPassengers} pasajeros</small>
					</div>
				</div>
				<div className='box-summary-div mt-3'>
					<div className='box-summary-item'>
						<p qui="p checkout-transferFor" className="mb-1"><b>{intl.formatMessage({ id: "checkout.transferFor" })} 1 {intl.formatMessage({ id: "checkout.person" })}</b></p>
					</div>
				</div>
			</>
		)}
		{searchBooking.type !== serviceTypeConstant.TRANSFER && (
			<>
				<div className='box-summary-div-hotel mt-3'>
					{searchBooking.type !== serviceTypeConstant.TOUR && (
						<div className='box-summary-item'>
							<p  qui="p checkout-summaryDate">{txt.summaryDate}</p>
						</div>
					)}
					<div className='box-summary-item'>
						<p  qui="p checkout-summaryPax-summaryAges">{txt.summaryPax} {txt.summaryAges}</p>
					</div>
				</div>
			</>
		)}

		{searchBooking.type === serviceTypeConstant.PACKAGE && (
			<>
				<div className="container-box-reservation-flight">
					<div className="your-reservation">
						<h3 className="title-vuelo">{intl.formatMessage({ id: "packages.text.detail.flight" })}</h3>
						<>

							<div className="content">
								<div className="flight flightDeparture">
									<div className="d-flex justify-content-between">
										<div className="title">{intl.formatMessage({ id: "packages.text.departure" })}</div>
									</div>
									<Row className="g-0  flightSelected">
										<Col>
											<div className="airport origin">MEX</div>
											<div className="time timeDeparture">02:02 PM</div>
										</Col>
										<Col>
											<div className="separator"></div>
										</Col>
										<Col>
											<div className="airport destination">CUN</div>
											<div className="time timeDeparture">03:15 PM</div>
										</Col>
									</Row>
									<div className="date mb-3">{intl.formatMessage({ id: "package.text.test.date" })}</div>
								</div>

								<div className="flight flightDeparture mt-2 ">
									<div className="d-flex justify-content-between">
										<div className="title">{intl.formatMessage({ id: "packages.text.return" })}</div>
									</div>
									<Row className="g-0  flightSelected">
										<Col>
											<div className="airport origin">CUN</div>
											<div className="time timeDeparture">02:02 PM</div>
										</Col>
										<Col>
											<div className="separator"></div>
										</Col>
										<Col>
											<div className="airport destination">MEX</div>
											<div className="time timeDeparture">03:15 PM</div>
										</Col>
									</Row>
									<div className="date">{intl.formatMessage({ id: "package.text.test.date" })}</div>
								</div>

							</div>
						</>
					</div>
				</div>
			</>
		)}
	</>)

}

export default CardContent;
