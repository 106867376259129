const Title = (props) => {
  const { title, componentTitle } = props;
  return (
    <div className="title">
      {componentTitle === "h1" && <h1>{title}</h1>}
      {componentTitle === "h2" && <h2>{title}</h2>}
      {componentTitle === "h3" && <h3>{title}</h3>}
    </div>
  );
};
export default Title;
