import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import localeAction from "../actions/localeAction";
import webSitesiteRoutesType from '../constants/webSitesiteRoutesConstant';
import languageConstants from "../constants/languageConstants";
import { useDispatch, useGlobalState } from "../store/StoreProvider";
import siteResource from "../siteResources";
import resourceSiteAction from "../actions/resourceSiteAction";
import commonUtils from "../utils/commonUtils";
import siteDataAction from "../actions/siteInformationAction";
import storage from "../localstorage/storage";
const useChangeLenguage = (valueIndexLanguage, routeType, positionPage) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { locale } = useGlobalState();
    const { id: idDetails,keyname: KeyName } = useParams();
    let navigate = useNavigate();

    const getValueIndexLanguage = (locale) => {
        const enumLang = Object.values(languageConstants)
        const auxLocale = enumLang.findIndex(a => a.toLowerCase() === locale.toLowerCase())
        return auxLocale;
    }

    //TODO solo debe activarse cuando cambia el idioma, no debe entrar al cargar el sitio
    useEffect(() => {
        const indexLocaleMain = getValueIndexLanguage(locale);

        if (valueIndexLanguage !== indexLocaleMain) {
            const enumLang = Object.keys(languageConstants)
            const auxLocale = languageConstants[enumLang[valueIndexLanguage]];

            //TODO al cambiar la url cambia el locale, posiblemente ya no hay que actualizarlo aqui
            localeAction.updateLocale(auxLocale, dispatch);

            const auxResourceSite = siteResource(auxLocale)
            const environmentKeys = commonUtils.getEnvironmentKeys(auxLocale);
            let siteInformationData = storage.getLocalStorage(
              environmentKeys.siteInfoKey
            );
            if(siteInformationData){
                siteDataAction.siteResourceFormat(
                    siteInformationData,
                    auxResourceSite,
                    dispatch
                  );
            
            }else{

                const siteRequest = {
                  domain: environmentKeys.hostname
                }
              
                siteDataAction.siteData(siteRequest, auxResourceSite, dispatch,
              
                  (_error) => {
                    //TODO si falla la api actualiza el contexto con la informacion de los recursos locales 
                    resourceSiteAction.update(auxResourceSite, dispatch);
                  })
               }

            const auxLocation = {
                search: location.search,
            };

            if (positionPage >= 0) {
                const siteRouter = auxResourceSite.siteRouter; //TODO NEW LINKS
                auxLocation.pathname = `/${auxLocale}${siteRouter[positionPage].pathname === "/"
                    ? ''
                    : siteRouter[positionPage].pathname}${KeyName ? `/${KeyName}` : ''}${idDetails ? `/${idDetails}` : ''}`;                
            }
            else {
                const auxSiteRoutes = auxResourceSite.siteRoutes;
                switch (routeType) {
                    case webSitesiteRoutesType.HOME:
                        auxLocation.pathname = `/${auxLocale}${auxSiteRoutes.home.pathname}`;
                        break;
                    case webSitesiteRoutesType.HOTEL:
                        //TODO main detail need id
                        auxLocation.pathname = `/${auxLocale}${auxSiteRoutes.hotel.pathname}${idDetails}`;
                        break;
                    case webSitesiteRoutesType.TOUR:
                        //TODO main detail need id
                        auxLocation.pathname = `/${auxLocale}${auxSiteRoutes.tour.pathname}${idDetails}`;
                        break;
                    case webSitesiteRoutesType.LISTHOTEL:
                        auxLocation.pathname = `/${auxLocale}${auxSiteRoutes.hotels.pathname}`;
                        break;
                    case webSitesiteRoutesType.LISTTRANSFER:
                        auxLocation.pathname = `/${auxLocale}${auxSiteRoutes.transfer.pathname}`;
                        break;
                    case webSitesiteRoutesType.LISTTOUR:
                        auxLocation.pathname = `/${auxLocale}${auxSiteRoutes.tours.pathname}`;
                        break;
                    default:
                        auxLocation.pathname = `/${auxLocale}/`;
                        break;
                }
            }

            
            navigate(auxLocation);
        }

    }, [valueIndexLanguage]);

}

export default useChangeLenguage;