
import { Col,Card, } from 'react-bootstrap';
import { useIntl } from "react-intl";
import iconUtils from '../../../utils/iconUtils'
import iconContants from '../../../constants/iconContants';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';
import "./components/style.scss";

//TODO card for activities in tour detail
function SkeletonPayment(props) {
  const { number,  } = props;
  const intl = useIntl();


  return (
            <Col className="mt-4">
                <Card style={{ width: '100%' }} >
                <Card.Body>
                    <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder as={Card.Title} animation="glow" >
                        <Placeholder xs={12} className="height-input-payment"/>
                    </Placeholder>
                
                    <Placeholder.Button variant="primary" xs={2} /> 
                </Card.Body>
                </Card>
            </Col>

  );
}

export default SkeletonPayment;