import { useState } from "react";
import { Button, Container, Col, Row, Form } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useMediaQuery } from 'react-responsive';
import iconUtils from "../../../utils/iconUtils";
import iconContants from "../../../constants/iconContants";
import serviceType from "../../../constants/serviceTypeConstant";
import bookingValidate from "../../../validate/bookingValidate";
import {Autocomplete, Calendar, SelectRooms} from "../../form/inputs";
import SingleDay from "../../form/inputs/Calendar/SingleDay"
import AutocompleteList from "../../form/inputs/AutocompleteList/AutocompleteList";
import { useGlobalState, useDispatch } from "../../../store/StoreProvider";
import searchHotelAction from "../../../actions/searchHotelAction";
import breakpointConstants from "../../../constants/breakpointConstants";
import Title from "../components/Title";
import Modals from '../../modals/Modals';
import "./components/style.scss";
import "./components/transfer.scss";


const BookingEngine = (props) => {
  const { title, locale, params, paramsRooms, type, paramsAutocomplete,
          paramsCalendar, componentTitle, isEditable, isDetailPage, isHome,
        } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery( { maxWidth: breakpointConstants.SM })
  const {searchHotels, authSession} = useGlobalState();
  const [paramsFront, setParams] = useState(
    type === serviceType.TRANSFER
      ? bookingValidate.paramsShutterFormat(params)
      : bookingValidate.paramsHotelFormat(params))
  const [ validate, setValidate ] = useState(false)
  const _onChangeDate = (response ) =>  setParams(Object.assign({}, paramsFront, {startDate: response.startDate, endDate: response.endDate}))
  const _onChangeSingleDate = (response ) =>  setParams(Object.assign({}, paramsFront, {date: response.date}))
  const [ roundtrip , setRoundtrip ] = useState(false)

  const _onClickRooms = () => {
    const {onClickRooms} = props;
    if(onClickRooms)
      onClickRooms();
  }

  const _onCloseRooms = () => {
    const {onCloseRooms} = props;
    if(onCloseRooms)
      onCloseRooms();
  }

  const _onSubmitRooms = (response) => {
    setParams(Object.assign({}, paramsFront, {rooms: response}))
  }

  const onChangeAutocomplete = (value, onSuccess) => {
    if (value.trim().length >= 3){
      searchHotelAction.search(
        { keyword: value, locale, authSession }, dispatch,
        (response) => {  onSuccess(response)  },
        (error) => { console.error(error);  }
      );
    }
  }
  const onChangeDestination = (value, onSuccess) => {
    if (value.trim().length >= 3){
      searchHotelAction.search(
        { keyword: value, locale, authSession }, dispatch,
        (response) => {  onSuccess(response)  },
        (error) => { console.error(error);  }
      );
    }
  }

  const onChangeAutocompleteSelect = (response) => {
    const nAuto = typeof response === 'string' ?
    { label: response} :
    {
      label: response.name,
      keyword: response.code,
      type: response.type,
    };

    setParams(Object.assign({}, paramsFront, {inputAutoValueStart: nAuto}))
    if(validate){
      setValidate(false)
    }
  }

  const onChangeAutocompleteDestination = (response) => {
    const nAuto = typeof response === 'string' ?
    { label: response} :
    {
      label: response.name,
      keyword: response.code,
      type: response.type,
    };

    setParams(Object.assign({}, paramsFront, {inputAutoValueStart: nAuto}))
    if(validate){
      setValidate(false)
    }
  }

  const _onSubmit = () => {
    const {onSubmit } = props;

    if(isDetailPage)
      paramsFront.typeService = 'Detail'

    if(!paramsFront.inputAutoValueStart?.label){
      setValidate(true)
      return false;
    }

    const dateValidate = type === serviceType.HOTEL
    ? bookingValidate.hotelValidate(paramsFront, paramsAutocomplete, paramsCalendar, paramsRooms) :
    { submit: true };

    if(!dateValidate.submit)
      return false;

    if(onSubmit)
      onSubmit(paramsFront);
  }

  const [fullscreen, setFullscreen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [listSearch, setListSearch] = useState(null);

  const handleListSearch = (event) => setListSearch(event);

  const handleShowModal = (breakpoint) => {
    setFullscreen(breakpoint);
    setShowModal(isMobile);
    setParams(Object.assign({}, paramsFront, {search: listSearch}))    
  }

  const handleCloseModal = () => setShowModal(false);

  const onChange = (item, event) => {
    const {onChange} = props;
    onChange && onChange({
        value: item.value,
        checked: event.target.checked,
    })
  }
  return (<Container >
    <div className ="booking-engine booking-engine-transfer">
      <Container>
        { title && ( <Title title={title} componentTitle={componentTitle} /> ) }
        <Row  className="booking-engine-row">
          <Col className="booking-engine-col">
            { isMobile ? ( <>
             <div className="input-group groupinputDesti">
              <Button variant="Light" className="calendar-button-render-input w-100 btn btn-Light"
              onClick={() => handleShowModal(true)}> 
              {iconUtils.icon(iconContants.LOCATION)}
              { paramsFront.inputAutoValueStart?.label ? paramsFront.inputAutoValueStart?.label :
                intl.formatMessage({ id: "general.destiny" }) }
              </Button>
              
              <span className="input-group-text btn-round-mobile">
              {iconUtils.icon(iconContants.BSARROWDOWNUP)}
              </span>

              <Button variant="Light" className="calendar-button-render-input w-100 btn btn-Light"
              onClick={() => handleShowModal(true)}> 
              {iconUtils.icon(iconContants.HOTEL)}
              { paramsFront.inputAutoValueStart?.label ? paramsFront.inputAutoValueStart?.label :
                intl.formatMessage({ id: "general.destiny" }) }
              </Button>
            </div>
            <Modals 
            fullscreen={fullscreen} 
            showClose={false} 
            handleCloseModal={handleCloseModal}> 
                <AutocompleteList  
                suggestions={searchHotels.isFetching ? [] : searchHotels.items}
                onChangeAutocomplete={onChangeAutocomplete}
                handleCloseModal={handleCloseModal}
                onChangeSelect={onChangeAutocompleteSelect}
                text={paramsFront.inputAutoValueStart?.label}
                list={handleListSearch}
                search={searchHotels.items}
                placeholder={intl.formatMessage({ id: "general.placeholderAutocomplete" })}
                noresultText={intl.formatMessage({ id: "general.noresultAutocomplete" })}/>
            </Modals >
            </>)   
            : (
              <div className="input-group groupinputDesti">
                <Autocomplete
                icon={iconContants.LOCATION}
                placeholder={ intl.formatMessage({ id: "general.origin"}) }
                suggestions={searchHotels.isFetching ? [] : searchHotels.items}
                onChangeAutocomplete={onChangeAutocomplete}
                onChangeSelect={onChangeAutocompleteSelect}
                value={paramsFront.inputAutoValueStart?.label}
                />
                <span className="input-group-text btn-round-destopk">
                  {iconUtils.icon(iconContants.BSARROWLEFTRIGHT)}
                </span>
                <Autocomplete
                icon={iconContants.HOTEL}
                placeholder={type === serviceType.TRANSFER ?
                    intl.formatMessage({ id: "general.origin"})
                  : intl.formatMessage({ id: "general.destiny"}) }
                suggestions={searchHotels.isFetching ? [] : searchHotels.items}
                onChangeAutocomplete={onChangeAutocomplete}
                onChangeSelect={onChangeAutocompleteSelect}
                value="Hotel"
                />
              </div>
           )}
            <div className="alert-text">
            { validate &&  intl.formatMessage({ id: "general.selectDestination" }) }
            </div>
          </Col>

          <Col className="booking-engine-col">
            { roundtrip ? 
            ( <SingleDay 
              date={paramsFront.startDate}
              onChage={_onChangeSingleDate}
              type={type}
              locale={locale}
              placeholder={"---"} 
              /> ):
            ( <Calendar
              startDate={paramsFront.startDate}
              endDate={paramsFront.endDate}
              onChage={_onChangeDate}
              type={type}
              locale={locale}
              months={ 2 }
              placeholder={"--- | ---"} /> 
            ) 
            }
          </Col>
          <Col  className="booking-engine-col">
            <SelectRooms
              type={type}
              options={paramsFront.rooms}
              {...paramsRooms}
              onClick={_onClickRooms}
              onClose={_onCloseRooms}
              onSubmit={_onSubmitRooms}/>   
             
          </Col>

          <Col className="booking-engine-col btn-div transfer-btn">
            <Button onClick={_onSubmit} className="text-white pb-4 btn-book-submit">
              { iconUtils.icon(iconContants.SEARCH) }
              <span> 
                { isDetailPage ? intl.formatMessage({ id: "button.availability" }) 
                                : intl.formatMessage({ id: "button.search" }) }
              </span>
            </Button>
          
          </Col>
        </Row>
        <Form.Check
        onChange={(e) => {setRoundtrip(prev => !prev) }}
        defaultChecked={true}
        type="checkbox"
        label={`Book roundtrip`}
        id={`roundtrip`}
        /> 
      </Container>
    </div>
  </Container>
  );
  }
  BookingEngine.defaultProps = {
    isEditable: true,
    isHome: false
  }

export default BookingEngine;
