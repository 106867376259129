import { useRef, useState } from 'react';
import dayjs from "dayjs";
import { useMediaQuery } from 'react-responsive'
import { Calendar } from "react-date-range";
import Form from 'react-bootstrap/Form';
import { addDays, isEqual } from "date-fns";
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';
import dateUtils from '../../../../utils/dateUtils';
import * as locales from "react-date-range/dist/locale";
import serviceType from '../../../../constants/serviceTypeConstant';
import Modals from '../../../modals/Modals';
import breakpointConstants from "../../../../constants/breakpointConstants";
import iconUtils from '../../../../utils/iconUtils';
import iconContants from '../../../../constants/iconContants';
import PropTypes from "prop-types";
import "./components/style.scss";

  const SingleDay = (props) => {
    const {date, endDate, months, locale, type} = props;
    const [maxDate, setMaxDate] = useState(undefined);
    const target = useRef(null);
    const isMobile = useMediaQuery( { maxWidth: breakpointConstants.SM })

    const [state, setState] = useState( dateUtils.valiteDateFormat(date)  ? dateUtils.getDateforFormat(date) : new Date() );
    const [fullscreen, setFullscreen] = useState(true);
    const [showModal, setShowModal] = useState(false);
    
    const changeMaxDate = (date) => {
      const {maxDays} = props;
      setMaxDate(addDays(date, maxDays - 1))
    }

    const changeDate = (newDate) => {
      return dayjs(newDate).locale(locale).format("DD MMM YYYY")
    }

    const chageTextValue = () => {
      const { placeholder } = props;
    return changeDate(state) ? `${changeDate(state)}` : placeholder;
    }    

    const [textValue, setTextValue] = useState(chageTextValue());
    
    const handleShowModal = (breakpoint) => {
      setFullscreen(breakpoint);  
      setShowModal(isMobile);        
    }
    
    const handleCloseModal = () =>   setShowModal(false); 
    const targetHover = () =>  setShowModal(false);

    const closeBtn = () => {
      handleShowModal(true);
      setMaxDate(undefined);
    }  
      
    const popover = (
    <Popover id="popover-calendar" 
             className='popover-calendar-button'>
      <Popover.Body>
        <Calendar
        onChange={(item) => {            
          const {onChage} = props;
          setState(item);
          setTextValue( changeDate(item) );
          if(onChage) { 
            onChage({ date: dateUtils.getFormattedDate(item) })
          }
          setShowModal(false); 
          target.current.click();
          targetHover();
        }}
        retainEndDateOnFirstSelection={false}
        minDate={new Date()}
        maxDate={maxDate}
        months={months}
        locale={locales[locale?.toLowerCase().split('-')[0]]}
        weekStartsOn={1}
        showMonthAndYearPickers={false}
        showMonthArrow={true}
        showPreview={true}
        weekdayDisplayFormat={"EEEEE"}
        monthDisplayFormat={"MMMM yyyy"}
        direction="horizontal"
        />
      </Popover.Body>
    </Popover>
    );

    return ( <>                                              
    <OverlayTrigger 
    trigger={"click"}
    placement="bottom" 
    target={target.current}
    overlay = { !isMobile ?  popover : <></> } 
    rootClose>
        <Button 
        ref={target}
        variant="Light" 
        className='calendar-button-render-input w-100' 
        onClick={ closeBtn } >
            {iconUtils.icon(iconContants.CALENDAR)}
            <span > {`${textValue}`}</span>
        </Button>
    </OverlayTrigger>
    <Modals 
    fullscreen={fullscreen} 
    show={ isMobile && showModal  } 
    showClose={false} 
    handleCloseModal={handleCloseModal}> 
    <div className='calendar-preview'>
        <span>{`${textValue}`}</span>
    </div>
    <div className='calendar-modal'>
        {popover}
    </div>
    </Modals >              
    </> 
    );

  }
  SingleDay.defaultProps = {
    maxDays: 30,
    months: 1,
    locale: "es-mx"
  }

  export default SingleDay;
