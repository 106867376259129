const SideLabel = (props) => {

  const { title, text, validation, show, classTitle, classText  } = props;
  return (
    <>
      {show && (
         <div className="container-check-out">
          <span className="strong">{`${title}: `}</span>
          <span>{text}</span>
        </div>
      )}
    </>
  );
};

SideLabel.defaultProps = {
  validation: true,
  show: true,
  classText: "",
  classTitle: "",
};

export default SideLabel;
