
import {  Card, } from 'react-bootstrap';
import { useIntl } from "react-intl";
import iconUtils from '../../../utils/iconUtils'
import iconContants from '../../../constants/iconContants';
import BoxIconText from '../../boxes/BoxIconText';
import BoxServiceRateTour from '../../boxes/BoxServiceRateTour'
import Tooltip from 'react-bootstrap/Tooltip';
import Accordion from 'react-bootstrap/Accordion';
import "./components/style.scss";

//TODO card for activities in tour detail
function CardTourActivity(props) {
  const { room, from, rateDescriptions, textButton, classs } = props;
  const intl = useIntl();
  const onClickReservation= ()=> {
    const {onClickReservation} = props;
    onClickReservation && onClickReservation();
  }
  const renderTooltip = (props) => {
    const {description, ...rest} = props;
    return <Tooltip id="button-tooltip" {...rest}>
      {description}
    </Tooltip>
  };
  const optionsOverlay = {
    placement: "top",
    delay: { show: 250, hide: 400 }
  }

  return (
    <Card className={`container-card-activity ${classs ? classs : ''}`}>
      <Card.Body>
        <div className="container-left">
          {room && room.name && (
            <div className='container-card-title mb-2'><h2 className="title"> {room.name} </h2></div>
          )}

          <div className='container-info-activity'>
            <div className="d-flex">                                
                <div className="container-icon me-2">{iconUtils.icon(iconContants.BSCLOCK)}</div>
                <span><b>{intl.formatMessage({ id: "general.duration" })} : </b> 7 {intl.formatMessage({ id: "general.hours" })} </span>
            </div>
            <div className="d-flex">                                
                <div className="container-icon me-2">{iconUtils.icon(iconContants.BIRESTAURANT)}</div>
                <span>{room.plan} </span>
            </div> 
            <div className="d-flex">                                
                <div className="container-icon me-2">{iconUtils.icon(iconContants.AIOUTLINEGLOBAL)}</div>
                <span>{intl.formatMessage({ id: "general.language" })}: {intl.formatMessage({ id: "tourProvisional.general.4" })} </span>
            </div>  
            {room.hasOffers && (
                <div className="rate-discount">
                <span className="container-icon me-2">{iconUtils.icon(iconContants.CIDISCOUNT1)}
                </span>
                <span>50% descuento</span> 
                </div>  
            )}  
            <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="0">
                <Accordion.Header><span className='me-2'>{intl.formatMessage({ id: "general.seeMoreDetails" })}</span></Accordion.Header>
                <Accordion.Body>
                  {room.descriptionType }
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>        
          </div>

         
        </div>

        {room && room.rate && (
          <BoxServiceRateTour
            classs={""}
            textFrom={from}
            rate={room.rate ? room.rate : 0}
            discountRate={room.hasOffers}
            currency={room.currency ? room.currency : undefined}
            informations={
              rateDescriptions ? rateDescriptions : undefined}
            textButton={textButton ? textButton : ''}
            onClick={onClickReservation}
          />
        )}
      </Card.Body>
    </Card>

  );
}

export default CardTourActivity;