import dayjs from "dayjs";
import { addDays, differenceInDays } from "date-fns";
import moment from "moment/moment";
import { ca } from "date-fns/locale";

require("dayjs/locale/es");
require("dayjs/locale/en");

const minutesToSeconds = (minutes) => {
  return minutes * 60;
};
const formatDateLocale = (value, locale) => {
  return value.toLocaleDateString(locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
const dateFormat = (date, template = null, locale = null) => {
  if (locale && template) {
    const inLocale = locale.toLowerCase().split("-");
    switch(inLocale[0])
    {
      case "es":
        template = "DD-MMM-YYYY";
        break;
      case "en":
        template= "MMM-DD-YYYY"
        break;
       default:
          template= "DD-MMM-YYYY"
        break;
    }

    return dayjs(date).locale(inLocale[0]).format(template);
  }

  date = typeof date === "string" ? new Date(date) : date;
  //locale = storage.getLocalStorage(localStorageConst.LOCALE);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  return date.toLocaleDateString(locale, options);
};

function getFormattedDate(date) {
  return date.toLocaleDateString("sv");
}

const createDateWithNumber = (days) => {
  return addDays(new Date(), days);
};

const invalidDate = (d) => {
  let validate = false;
  if (Object.prototype.toString.call(d) === "[object Date]") {
    if (!isNaN(d)) validate = true;
  }

  return validate;
};
const getDateforFormat = (date) => {
  const dateArray = date.split("-");
  const dateOne = new Date(
    parseInt(dateArray[0]),
    parseInt(dateArray[1]) - 1,
    parseInt(dateArray[2])
  );

  return dateOne;
};

const getNightsForDates = (start, end) => {
  const result = differenceInDays(end, start);
  return result;
};

const getNights = (start, end) => {
  const startDate = getDateforFormat(start);
  const endDate = getDateforFormat(end);
  const result = differenceInDays(endDate, startDate);
  return result;
};

const valiteDateFormat = (newDay) => {
  let dateTrue = false;
  try {
    let dateOne = new Date();
    if (newDay !== undefined && newDay !== null) {
      const dateArray = newDay.split("-");
      dateOne = new Date(
        parseInt(dateArray[0]),
        parseInt(dateArray[1]) - 1,
        parseInt(dateArray[2])
      );
      dateTrue = invalidDate(dateOne);
    }
  } catch (e) {
    console.error(e);
    dateTrue = false;
  }
  return dateTrue;
};

//TODO Returns the equivalent of seconds for thousand
const minutesToSecondsDate = (minutes) => {
  return minutesToSeconds(minutes) * 1000;
};

// get range of days , one month for default
const getRangeDays = (addStart, addEnd) => {
  const start = addStart ?? 7;
  const end = addEnd ?? 10;
  const dates = {
    startDate: moment(new Date()).add(start, "days").format("yyyy-MM-DD"),
    endDate: moment(new Date()).add(end, "days").format("yyyy-MM-DD"),
  };

  return dates;
};

const utcTodate = (date) => {
  return moment(date).utc().format("YYYY-MM-DD");
};

const dateUtils = {
  minutesToSeconds,
  minutesToSecondsDate,
  getFormattedDate,
  valiteDateFormat,
  invalidDate,
  dateFormat,
  formatDateLocale,
  createDateWithNumber,
  getDateforFormat,
  getNightsForDates,
  getNights,
  getRangeDays,
  utcTodate,
};

export default dateUtils;
