import iconType from "../../constants/iconContants";

const orderByFilter = [
    {
        label: "Recomendado",
        value: "recommended",
        //icon: iconType.CHECK
    },
    {
        label: "Precio:  Menor a mayor",
        value: "asc",
        //icon: iconType.FILTERLIST
    },
    {
        label: "Precio:  Mayor a menor",
        value: "desc",
        //icon: iconType.FILTERLIST,
        //sx:{transform: "rotate(180deg)"}
    }
]

export default orderByFilter;