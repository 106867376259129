import { useIntl } from "react-intl";
import { Card } from "react-bootstrap";
import CardContent from "./components/CardContent";
import CardRate from "./components/CardRate";
import CardContainerImage from "../../../components/cards/CardContainerImage";
import "./components/style.scss";

const CardHotelLarge = (props) => {
	const { service, currency, type, border } = props;
	const hasDiscount = service.hasOffers && service.offerSale ? true : false;
	const hasRate = service.bestRate ? true : false;
	const intl = useIntl();

	return (
		<Card className={`card-sv-large mt-3 ${border ? 'card-border' : ''}`}>
			<div className="content-box">
				<CardContainerImage
					name={service.name}
					photos={service.photos}
					showDiscount={hasDiscount}
					discount={service.offerSale} />

				<div className="card-sv-content-info">
					<div className="card-sv-content-info-start">
						<CardContent item={service} type={type} />
					</div>
					<div className="card-sv-content-info-end">
						{hasRate && (<CardRate service={service} type={type} currency={currency} />)}
						{!hasRate && (<b>{intl.formatMessage({id: "general.notAvailable" })}</b>)}
					</div>
				</div>
			</div>
		</Card>
	);
};

export default CardHotelLarge;
