
const flighRateFamilyMock = {
    family: [
        {   name:"Precios regulares",
            pirce:188,
            currency:"mxn",
            priceDiscounted:218,
            type:"regular",
            rates:[
                {
                    id:1,
                    availability:true,
                    type:"regular",
                    tag:"basic",
                    title:"Básica",
                    favorite:false,
                    textTravel:"Para que armes tu viaje",
                    list:[
                    {
                    
                     text:"1 equipaje de mano",
                     note:"Más 1 artículo personal",
                     include:true,
                     textColor:"10 kg",
                     textDicount:"",
                     info:"1 artículo personal (colocado debajo del asiento) + 1 equipaje de mano (55 x 40 x 25 cm) máx. 10 kg entre los dos."
                    },
                    {
                     
                     text:"No incluye 5 kg extra en equipaje de mano",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Sin descuento al adquirir equipaje documentado",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Equipaje documentado no incluido",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Asiento con costo adicional",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                ],
                rates:{
                    rate:202,
                    currency:"mxn",
                    priceDiscounted :220,
                    },
                },
                {
                    id:2,
                    availability:true,
                    type:"regular",
                    tag:"classic",
                    title:"Clásica",
                    favorite:true,
                    textTravel:"La más comprada",
                    list:[
                    {
                    
                     text:"1 equipaje de mano",
                     note:"Más 1 artículo personal",
                     include:true,
                     textColor:"10 kg",
                     textDicount:"",
                     info:"1 artículo personal (colocado debajo del asiento) + 1 equipaje de mano (55 x 40 x 25 cm) máx. 10 kg entre los dos."
                    },
                    {
                     
                     text:"No incluye 5 kg extra en equipaje de mano",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Sin descuento al adquirir equipaje documentado",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Equipaje documentado no incluido",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Asiento con costo adicional",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                ],
                rates:{
                    rate:730,
                    currency:"mxn",
                    priceDiscounted :748,
                    },
                },
                {
                    id:3,
                    availability:true,
                    type:"regular",
                    tag:"plus",
                    title:"Plus",
                    favorite:false,
                    textTravel:"Con más equipaje",
                    list:[
                    {
                    
                     text:"1 equipaje de mano",
                     note:"Más 1 artículo personal",
                     include:true,
                     textColor:"10 kg",
                     textDicount:"",
                     info:"1 artículo personal (colocado debajo del asiento) + 1 equipaje de mano (55 x 40 x 25 cm) máx. 10 kg entre los dos."
                    },
                    {
                     
                     text:"No incluye 5 kg extra en equipaje de mano",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Sin descuento al adquirir equipaje documentado",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Equipaje documentado no incluido",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Asiento con costo adicional",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                ],
                rates:{
                    rate:1708,
                    currency:"mxn",
                    priceDiscounted :"",
                    },
                }
            ]
        },
        {   
            name:"Precios v.club",
            pirce:87,
            currency:"MXN",
            priceDiscounted:92,
            type:"club",
            rates:[
                {
                    id:1,
                    availability:true,
                    type:"club",
                    tag:"basic",
                    title:"Básica v.club",
                    favorite:false,
                    textTravel:"Para que armes tu viaje",
                    list:[
                    {
                    
                     text:"1 equipaje de mano",
                     note:"Más 1 artículo personal",
                     include:true,
                     textColor:"10 kg",
                     textDicount:"",
                     info:"1 artículo personal (colocado debajo del asiento) + 1 equipaje de mano (55 x 40 x 25 cm) máx. 10 kg entre los dos."
                    },
                    {
                     
                     text:"No incluye 5 kg extra en equipaje de mano",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Sin descuento al adquirir equipaje documentado",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Equipaje documentado no incluido",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Asiento con costo adicional",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                ],
                rates:{
                    rate:87,
                    currency:"mxn",
                    priceDiscounted :92,
                    },
                },
                {
                    id:2,
                    availability:true,
                    type:"club",
                    tag:"classic",
                    title:"Clásica v.club",
                    favorite:true,
                    textTravel:"La más comprada",
                    list:[
                    {
                    
                     text:"1 equipaje de mano",
                     note:"Más 1 artículo personal",
                     include:true,
                     textColor:"10 kg",
                     textDicount:"",
                     info:"1 artículo personal (colocado debajo del asiento) + 1 equipaje de mano (55 x 40 x 25 cm) máx. 10 kg entre los dos."
                    },
                    {
                     
                     text:"No incluye 5 kg extra en equipaje de mano",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Sin descuento al adquirir equipaje documentado",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Equipaje documentado no incluido",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Asiento con costo adicional",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                ],
                rates:{
                    rate:415,
                    currency:"mxn",
                    priceDiscounted :420,
                    },
                },
                {
                    id:3,
                    availability:true,
                    type:"club",
                    tag:"plus",
                    title:"Plus v.club",
                    favorite:false,
                    textTravel:"Con más equipaje",
                    list:[
                    {
                    
                     text:"1 equipaje de mano",
                     note:"Más 1 artículo personal",
                     include:true,
                     textColor:"10 kg",
                     textDicount:"",
                     info:"1 artículo personal (colocado debajo del asiento) + 1 equipaje de mano (55 x 40 x 25 cm) máx. 10 kg entre los dos."
                    },
                    {
                     
                     text:"No incluye 5 kg extra en equipaje de mano",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Sin descuento al adquirir equipaje documentado",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Equipaje documentado no incluido",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                    {
                     
                     text:"Asiento con costo adicional",
                     note:"",
                     include:false,
                     textColor:"",
                     textDicount:"",
                     info:""
                    },
                ],
                rates:{
                    rate:1129,
                    currency:"mxn",
                    priceDiscounted :"",
                    },
                }
            ]
        }
    ],

}

export default flighRateFamilyMock;