import pageLinksConstants from "../../../../src/constants/pageLinksConstants";
import webSitesiteRoutesConstant from "./../../../../src/constants/webSitesiteRoutesConstant"
const brand ="Nexus Vacations"

const response = [
  {
    routeType:webSitesiteRoutesConstant.HOME,
    canonical: pageLinksConstants.HOME,
    title: `${brand} - Reservaciones Online`,
    titleStatic:  `${brand} - Reservaciones Online`,
    description:
      "Motor de Reservas online para ofrecer al usuario todo tipo de productos turísticos, Hoteles, Apartamentos, y otros servicios, con unas búsquedas rápidas basadas en una mínima información, que da como resultado toda la oferta, ideal para todo tipo de turismo, sea empresas o cliente final",
    keywords:
      "hoteles en cancun, hoteles en cancun centro, hoteles en cancun todo incluido, oferta de hoteles en cancun, cancun vacaciones, vacaciones en cancun, viajes a cancun, cancun ofertas, disponibilidad de hospedaje en cancun, disponibilidad de hoteles en cancun, cancun descuentos vacacionales, cancun promociones vacacionales, hoteles en cancun zona hotelera, hoteles cancun economicos, cancun hoteles baratos, cancun hoteles todo incluido, cancun hoteles 5 estrellas, cancun hoteles para adultos, riviera maya, hoteles de cancun 5 estrellas, hoteles de cancun todo incluido, hoteles de cancun zona hotelera, hoteles de cancun economicos, vacaciones en cancun todo incluido, cancun vacaciones paquetes, viajemos todos por Mexico, viajemos juntos, viajemos todos por mexico, viajemos todos x mexico, viajemos todos por Mexico, viajemos por todo mexico, viajemos juntos, viajemos por quintana roo, viajemos todos por quintana roo, viajemos todos por mexico, hotel de Cancun, ofertas de viajes, vacaciones de navidad, vacaciones de navidad con familia, vacaciones de navidad 2018 en mexico, hoteles de navidad, viajes de navidad, viajes de navidad, viajes de navidad para adultos, viajes de navidad en mexico, viajes de navidad, viajes de navidad en familia, ofertas de navidad, ofertas de navidad, paquetes de navidad, paquetes de navidad, ofertas de diciembre viajes, ofertas puente de diciembre, ofertas puente de diciembre viajes",
    img: "../src/theme/site/nexus/logo.png",
    follow: "index,follow", //default inddex, follow, no indexfaz "noindex,nofollow"
  },
  {
    routeType:webSitesiteRoutesConstant.LISTHOTEL,
    canonical: pageLinksConstants.HOTELS,
    title: `Hoteles - #DESTINATION | ${brand}`,
    titleStatic: `Hoteles | ${brand}`,
    description: null,
    keywords: null,
    img: "../src/theme/site/nexus/logo.png",
    follow: "index,follow", //default inddex, follow, no indexfaz "noindex,nofollow"
  },
  {
    routeType:webSitesiteRoutesConstant.HOTEL,
    canonical: pageLinksConstants.HOTEL,
    title: `Hotel - #SERVICENAME | ${brand}`,
    titleStatic: `Hotel  | ${brand}`,
    description: null,
    keywords: null,
    img: "../src/theme/site/nexus/logo.png",
    follow: "index,follow", //default inddex, follow, no indexfaz "noindex,nofollow"
  },
  {
    routeType:webSitesiteRoutesConstant.RESERVATION,
    canonical: pageLinksConstants.CHECKOUT,
    title: `Reserva - #SERVICE | ${brand}`,
    titleStatic: `Reserva | ${brand}`,
    description: null,
    keywords: null,
    img: "../src/theme/site/nexus/logo.png",
    follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
  },
  {
    routeType:webSitesiteRoutesConstant.NOTFOUND,
    canonical: pageLinksConstants.NOTFOUND,
    title: `Pagina no encontrada | ${brand}`,
    titleStatic: `Pagina no encontrada | ${brand}`,
    description: null,
    keywords: null,
    img: "../src/theme/site/nexus/logo.png",
    follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
  },
  {
    routeType:webSitesiteRoutesConstant.LOGIN,
    canonical: pageLinksConstants.LOGIN,
    title: "Login",
    titleStatic: "Login",
    description: null,
    keywords: null,
    img: "../src/theme/site/nexus/logo.png",
    follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
  },
  {
    routeType:webSitesiteRoutesConstant.MYRESERVATION,
    canonical: pageLinksConstants.MY_RESERVATION,
    title: "Mis reservaciones",
    titleStatic: "Mis reservaciones",
    description: "reservaciones",
    keywords: null,
    img: "../src/theme/site/nexus/logo.png",
    follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
  },
  {
  routeType:webSitesiteRoutesConstant.PRIVACY,
  canonical: pageLinksConstants.PRIVACY_ES,
  title: `Privacidad`,
  titleStatic: `Privacidad`,
  description:null,
  keywords: null,
  img: "../src/theme/site/nexus/logo.png",
  follow: "index, follow", //default inddex, follow, no indexfaz "noindex,nofollow"
  },
  {
    routeType:webSitesiteRoutesConstant.TERMSANDCONDITIONS,
    canonical:pageLinksConstants.TERMSANDCONDITIONS_ES,
    title: `Términos y condiciones`,
    titleStatic: `Términos y condiciones`,
    description:  null,
    keywords: null,
    img: "../src/theme/site/nexus/logo.png",
    follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
  },
  {
    routeType:webSitesiteRoutesConstant.COOKIES,
    canonical: pageLinksConstants.COOKIES_POLICY_ES,
    title: `Politica de Cookies`,
    titleStatic: `Politica de Cookies`,
    description: null,
    keywords: null,
    img: "../src/theme/site/nexus/logo.png",
    follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
  },
  {
    routeType: webSitesiteRoutesConstant.VOUCHERPENDING,
    canonical: pageLinksConstants.VOUCHER_STATIC_ES,
    title: "Voucher pendiente",
    titleStatic: "Voucher pendiente",
    description: null,
    keywords: null,
    img: "../src/theme/site/nexus/logo.png",
    follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
  },
 {
    routeType: webSitesiteRoutesConstant.VOUCHER,
    canonical: pageLinksConstants.VOUCHER,
    title: "Voucher",
    titleStatic: "Voucher",
    description: null,
    keywords: null,
    img: "../src/theme/site/nexus/logo.png",
    follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
  },
];

export default response;
