import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import Modals from '../../modals/Modals';
import { TimelineFlight } from "../../feedback";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import serviceType from "../../../constants/serviceTypeConstant";
import BoxIconText from "../BoxIconText/BoxIconText";
import iconUtils from "../../../utils/iconUtils";
import iconContants from "../../../constants/iconContants";
import rateUtils from "../../../utils/rateUtils";
import "./components/style.scss";

const BoxClubFlight = (props) => {
  const {
    fullscreen,
    show,
    handleCloseModal,
    scale,
    selectType,
    selectTypeCategory
  } = props;
  const intl = useIntl();
  const onClick = () => {
    const { onClickButton } = props;
    onClickButton && onClickButton();
  };

  const onClickRate = (tPrice, tCategory) => {
    const {onClickRateClub} = props;
    onClickRateClub && onClickRateClub(tPrice, tCategory);
 }

 const changeTypeModal = (type) => {
    const {changeType} = props;
    changeType && changeType(type);
 }
  
  return (
    <Modals
    fullscreen={fullscreen}
    show={show}
    size="lg"
    head={false}
    showClose={false}
    handleCloseModal={handleCloseModal}
    title="Detalles del vuelo"
    >
      <div className="box-club-flight">
        <div className="closeDialog" onClick={handleCloseModal}>{iconUtils.icon(iconContants.AIOUTLINECLOSE)}</div>
        <Row className="g-0 ">
            <Col className="best-price d-none d-lg-block" >
                <div className="vclub-banner">
                    <div className="vclub-banner-content d-flex flex-column align-items-center">
                        <p>v.club</p>
                        <span>Viajamos al mejor precio.</span>
                    </div>
                </div>
            </Col>
            <Col className="vclub-container">
                <div className="margin-0 padding-0">
                    <span className="d-block d-md-none mb-2"><strong>Viajando al mejor precio</strong></span>
                    <div className="vclub-group-2">
                        <span className="caunt">¿Ya tienes una cuenta v.club? <small>Entra aquí</small> </span>
                        <div className="mat-dialog-title">
                            <h4>Únete a  <span>v.club</span> y disfruta de los beneficios</h4>
                        </div>
                        <ul className="upgrade-container">
                            <li>
                                <span className="material-icons check_circle">{iconUtils.icon(iconContants.CHECKCIRCLE)}</span>
                                <span>v. reservando en yavas.com con beneficios exclusivos para tu viaje.</span>
                            </li>
                            <li>
                                <span className="material-icons check_circle">{iconUtils.icon(iconContants.CHECKCIRCLE)}</span>
                                <span>Ahorra hasta $500 MXN en cada vuelo y tarifa en Volaris.</span>
                            </li>
                            <li>
                                <span className="material-icons check_circle">{iconUtils.icon(iconContants.CHECKCIRCLE)}</span>
                                <span>v. con promociones exclusivas todos los jueves.</span>
                            </li>
                        </ul>
                    </div>
                    <div className="group-member">
                        <h6><span>v.club</span> Membresía Individual</h6>
                        <span className="price">$25 
                            <span className="month">MXN / Mes <sup>*</sup></span>
                        </span>
                        <small className="year">($299 MXN / Año)</small>
                    </div>
                    <div className="btn btn-small choose-vclub mat-flat-button mat-button-base mat-primary" onClick={() => onClickRate(selectType, selectTypeCategory)} >
                        <span className="mat-button-wrapper"  >Quiero viajar con los precios v.club</span>
                        <div className="mat-button-ripple mat-ripple"></div>
                        <div className="mat-button-focus-overlay"></div>
                    </div>
                    <button className="btn btn-small mat-button mat-button-base mat-quarternary"  onClick={() => changeTypeModal("regular")}>
                        <span className="mat-button-wrapper">No gracias, quiero los precios regulares.</span>
                        <div className="mat-button-ripple mat-ripple"></div>
                        <div className="mat-button-focus-overlay"></div>
                    </button>
                </div>
            </Col>
        </Row>              
      </div>
    </Modals>
  );
};

export default BoxClubFlight;
