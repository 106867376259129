import initialState from "./initialState";
import types from "../constants/reducers/packageConstants";
const packageReducer = (state = initialState.packages, action) => {
    switch(action.type) {
        case types.PACKAGE_FETCHING:
            return {...initialState.packages, ...action.packages}
        case types.PACKAGE:
            return action.packages;
        case types.PACKAGE_ERROR:
            return action.packages
        default:
            return state;
    }
}

export default packageReducer;