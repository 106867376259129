import { useState } from "react";
import { useIntl } from "react-intl";
import {Col, Card} from 'react-bootstrap';
import imageDefault from '../../../assets/img/defaultImage.svg'
import CardContent from '../CardContainerService/components/CardContent'
import "../CardContainerService/components/cardService.scss";
import iconUtils from "../../../utils/iconUtils"
import iconContants from '../../../constants/iconContants';
import LabelFloat from '../../label/LabelFloat/LabelFloat';
import OffCanva from "../../ui/OffCanva/OffCanva";
import serviceTypeConstant from "../../../constants/serviceTypeConstant";
//TODO card for list shutters

function CardTransfer (props) {
  const intl = useIntl();
    const { card, currency, txt, type } = props;
  const gotoCheckOut = () => {
    const {onClick} = props;
    onClick && onClick(card);
  }
  const [ details, setDetails] = useState(false)
  const onClickOpenDetails = () => setDetails((prevDetails) => !prevDetails);

  const styleCategory = { 
    borderBottomLeftRadius: 0,
    position: 'absolute',
    bottom: 0,
}
  return ( <Col className='card-container'>
    <Card> <>
    <div style={{ width: '19rem', cursor: 'pointer' }} onClick={gotoCheckOut} >
        <div className="card-div">
        {card.hasOffers && (
            <label className="card-label">
                {iconUtils.icon(iconContants.CIDISCOUNT1)}
                {"  "}
                {card.offerSale}
            </label>
        )}
        </div>
        <img loading="lazy" alt={card.name}  className='card-img'
        src={ card.photos[0]?.url}
        onError={({ currentTarget }) => {
          currentTarget.onerror=null;
          currentTarget.src=imageDefault}}
        />

        { card.typeCategory && (
        <LabelFloat 
        style = { styleCategory }
        text = { card.typeCategory }
        />  )} 
        <Card.Body>
          <CardContent 
          item={card} 
          type={type}
          txt={txt}
          currency={currency}/>
        </Card.Body>
      </div>  </>
    { type === serviceTypeConstant.TRANSFER && (
      <OffCanva 
      key={card.id}
      text={ intl.formatMessage({ id: "general.seeMoreDetails" })  }
      icon= { iconUtils.icon(iconContants.SLARROWRIGHT) }
      onClickOpenDetails={onClickOpenDetails}
      gotoCheckOut={gotoCheckOut}
      item={card} 
      currency={currency}
      show={details}
      />
    )}
    </Card>
    
    
  </Col>
  ); 
}

export default CardTransfer;
