import { useState } from "react";
import { Button, Stack } from "react-bootstrap";

const SelectedCount = (props) => {
    const {value} = props;
    const [count, setCount] = useState(value ?? 0);

    const _mininum = () => {
        const {minValue} = props;
        return (count - 1) >= minValue ? false : true;
    }
    const _maxinum = () => {
        const {maxValue} = props;
        return (count + 1) <= maxValue ? false : true;
    }

    const _onChangeLess = () => {
        const {minValue, onChange} = props;
        const result = (count - 1) >= minValue ? (count - 1) : minValue;
        setCount(result)

        if(onChange)
            onChange(result);
    }

    const _onChangeMore = () => {
        const {maxValue, onChange} = props;
        const result = (count + 1) <= maxValue ? (count + 1) : maxValue;
        setCount(result)

        if(onChange)
            onChange(result);
    }
    return ( <Stack className="form-select-count-pax" direction="horizontal" gap={2} >
    <Button variant="outline-primary"
        onClick={_onChangeLess} disabled={_mininum()}
        className={`${_mininum() ? 'form-select-count-pax-item-disabled': ''}`}>-</Button>
    <span>{count}</span>
    <Button variant="outline-primary"
    onClick={_onChangeMore} disabled={_maxinum()}
    className={`${_maxinum() ? 'form-select-count-pax-item-disabled': ''}`}>+</Button>
    </Stack> );
}
 
export default SelectedCount;