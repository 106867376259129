HTMLElement.prototype.isVisible = function () {
	if (!this) return false;
	const pageTop = window.scrollY;
	const pageBottom = pageTop + window.innerHeight;
	const elementTop = this.offsetTop;
	const elementBottom = elementTop + this.offsetHeight;
	const isVisible = ((elementTop <= pageBottom) && (elementBottom >= pageTop));
	return isVisible;
};

HTMLElement.prototype.scroolled = function (percentVisible = 100) {
	if (!this) return false;
	const rect = this.getBoundingClientRect();
	const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
	return !(Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible);
}