import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import htmlparse from 'html-react-parser'
import { useGlobalState, useDispatch } from "../../../store/StoreProvider";
import {
	Container,
	Row,
	Col,
	Button,
	Alert,
	Spinner,
	Form,
} from "react-bootstrap";
import checkoutUtils from "../../../utils/checkoutUtils";
import storage from "../../../localstorage/storage";
import guestsUtils from "../../../utils/selectPersonsUtils";
import dateUtils from "../../../utils/dateUtils";
import commonUtils from "../../../utils/commonUtils";
import iconContants from "../../../constants/iconContants";
import iconUtils from "../../../utils/iconUtils";
import "./components/style.scss";
import InputLabel from "../../../components/form/inputs/InputLabel/InputLabel";
import TextArea from "../../../components/form/inputs/TextArea/TextArea";
import CardContainerSummary from "../../../components/cards/CardContainerSummary";
import InputDropdown from "../../../components/form/inputs/InputDropdown/InputDropdown";
import SkeletonPayment from "../../../components/skeleton/SkeletonPayment/SkeletonPayment";
import CheckoutForm from "../../../components/checkout/CheckoutForm";
import formValidate from "../../../validate/checkout/formValidate";
import hotelsValidate from "../../../validate/hotelsValidate";
import paymentAction from "../../../actions/paymentAction";
import serviceHotelAction from "../../../actions/serviceHotelAction";
import serviceTypeConstant from "../../../constants/serviceTypeConstant";
import { checkoutStep, paymentType } from "../../../constants/common";
import CardContainerTua from "../../../components/cards/CardContainerTua/CardContainerTua";
import "../../../utils/formUtils";
import useModalVoucher from "../../../hooks/useModalVoucher";
import useModal from "../../../hooks/useModal";
import CardPaxes from "../../../components/cards/CardPaxes";
import AlertErrorWithButton from "./components/AlertErrorWithButton";
import CheckBoxTerms from "./components/CheckBoxTerms";
import GoogleTagManagerUtils from "../../../utils/GoogleTagManagerUtils"
import serviceReponseToEcommersMapperUtils from "../../../utils/serviceReponseToEcommersMapperUtils"
import { useMediaQuery } from 'react-responsive';
import breakpointConstants from '../../../constants/breakpointConstants'
import localeUtils from "../../../utils/localeUtils";
import SkeletonPage from "../../../components/skeleton/SkeletonPage";
import STATUSCODE from "../../../constants/statusCode";
import CardTypeSection from "../../../components/checkout/stripe/CardTypeSection"

const CheckoutPage = (props) => {
	const {
		currency,
		payment,
		authSession,
		locale,
		resourceSite: auxResourceSite,
		pageOptions,
	} = useGlobalState();
	const { options } = props;
	const resourceSite = auxResourceSite.options;
	const searchHotelParams = resourceSite.searchHotelParams
	const intl = useIntl();
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const query = {
		cart: searchParams.get("cart"),
		s: searchParams.get("s"),
		checkin: searchParams.get("checkin"),
		checkout: searchParams.get("checkout"),
		paxes: searchParams.get("paxes"),
		ratekey: searchParams.get("ratekey"),
		sn: searchParams.get("sn"),
		destination: searchParams.get("destination"),
	};
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);
	const [payPublicRate, setPayPublicRate] = useState(true);
	const MINUTES_LOCAL_STORAGE = 10;
	const sourceMarket = "MX";
	const transferType = "single";
	const pathnames = resourceSite.siteRoutes;
	const localizedUrlHome = `/${locale}`;
	const localizedUrlVoucherHotel = pathnames?.voucherHotel?.pathname ? `/${locale}${pathnames.voucherHotel.pathname}` : "";
	const localizedUrlVoucherPackage = pathnames?.voucherPackage?.pathname ? `/${locale}${pathnames.voucherPackage.pathname}` : "";
	const localizedUrlVoucherTour = pathnames?.voucherTour?.pathname ? `/${locale}${pathnames.voucherTour.pathname}` : "";
	const localizedUrlVoucherShutter = pathnames?.voucherShutter?.pathname ? `/${locale}${pathnames.voucherShutter.pathname}` : "";
	const UrlVoucherStatic = pathnames?.voucherStatic?.pathname ? `/${locale}${pathnames.voucherStatic.pathname}` : "";
	const form = checkoutUtils.form;
	const rulesDescription = checkoutUtils.rulesDescription;
	const ladaItems = checkoutUtils.ladaItems;
	const optionsPaxes = checkoutUtils.optionsPaxes;
	const language = localeUtils.handleLanguage(locale);
	const divRef = useRef(null);
	const isMobile = useMediaQuery({ maxWidth: breakpointConstants.SM })
	const [paymentProcessing, setPaymentProcessing] = useState(false);
	const [checkRate, setCheckRate] = useState(false);
	const modalCheckRate = {
		text: [
			{
				title: intl.formatMessage({ id: "checkout.att" }),
				text: intl.formatMessage({ id: "checkout.formRules.errorCheckRate" }),
				redirect: false,
				icon: "warning",
			},
		],
		btn: intl.formatMessage({ id: "checkout.formRules.errorCheckRateButton" }),
		timer: 0,
	};
	const modalBookingError = {
		text: [
			{
				title: intl.formatMessage({ id: "checkout.att" }),
				text: intl.formatMessage({ id: "checkout.booking" }),
				redirect: false,
				icon: "warning",
				location: 0,
			},
		],
		btn: intl.formatMessage({ id: "checkout.try" }),
		timer: 0,
	};

	const [cardSummary, setCardSummary] = useState({
		checkin: intl.formatMessage({ id: "general.checkin" }),
		checkout: intl.formatMessage({ id: "general.checkout" }),
		night: intl.formatMessage({ id: "general.night" }),
		adults: intl.formatMessage({ id: "general.adults" }),
		roomTitle: intl.formatMessage({ id: "general.room" }),
		roomsTitle: intl.formatMessage({ id: "general.rooms" }),
		totalText: intl.formatMessage({ id: "general.total" }),
		taxesText: intl.formatMessage({ id: "general.taxes" }),
		subTotal: intl.formatMessage({ id: "checkout.ticket.subtotal" }),
	});
	const [bodyReservation, setBodyReservation] = useState({
		isSuccess: false,
		isError: false,
	});
	const [cart, setCart] = useState(null);
	const [clientInformation, setClientInformation] = useState([]);
	const [formValidatesData, setFormValidatesData] = useState([]);
	const [isChecked, setIsChecked] = useState(false);
	const [params, setParams] = useState({ type: null });
	const [generalMessage, setGeneralMessage] = useState(null);
	const [errorPayment, setErrorPayment] = useState(false);
	const handleCheckboxChange = (e) => {
		setIsChecked(e.target.checked);
	};
	const [validNames, setValidNames] = useState(true);
	
	const onEnterEvent = (e) => {
		if (e.target.name === "phone") {
			if (
				e.key !== "0" &&
				e.key !== "1" &&
				e.key !== "2" &&
				e.key !== "3" &&
				e.key !== "4" &&
				e.key !== "5" &&
				e.key !== "6" &&
				e.key !== "7" &&
				e.key !== "8" &&
				e.key !== "9" &&
				e.key !== "Backspace" &&
				e.key !== "ArrowLeft" &&
				e.key !== "ArrowRight" &&
				e.key !== "Tab" &&
				e.key !== "Home" &&
				e.key !== "End" &&
				e.key !== "Shift" &&
				e.key !== "Delete"
			) {
				e.preventDefault();
			}
		}
	};

	const onChangeSelect = (target, index) => {
		let newValue = "lada";
		let newIso = "iso";
		let lada = target.split('-')
		if (target != null) {
			const auxClientInformation = [...clientInformation];
			auxClientInformation[index][newValue] = lada[0];
			auxClientInformation[index][newIso] = lada[1];
			setClientInformation(auxClientInformation);
		}
	};

	const onChangeInput = (target, index) => {
		let newValue = null;
		let newValidate = null;
		setGeneralMessage(null);
		switch (target.name) {
			case "name":
				newValue =
					target.value != null || target.value.length > 0
						? commonUtils.compoundNameUpperCase(target.value)
						: target.value;
				newValidate = formValidate.name(newValue, rulesDescription.name);
				break;
			case "lastName":
				newValue =
					target.value != null || target.value.length > 0
						? commonUtils.compoundNameUpperCase(target.value)
						: target.value;
				newValidate = formValidate.lastName(
					newValue,
					rulesDescription.lastName
				);
				break;
			case "phone":
				newValue = commonUtils.removeSpace(target.value);
				newValidate = formValidate.phone(newValue, rulesDescription.phone);
				break;
			case "email":
				newValue = target.value.trim();
				newValidate = formValidate.email(newValue, rulesDescription.email);
				break;
			case "specialRequests":
				newValue = target.value;
				newValidate = formValidate.specialRequest(
					newValue,
					rulesDescription.special
				);
				break;
			case "airline":
				newValue = target.value;
				newValidate = formValidate.specialRequest(
					newValue,
					rulesDescription.special
				);
				break;
			case "numFlight":
				newValue = target.value;
				newValidate = formValidate.specialRequest(
					newValue,
					rulesDescription.special
				);
				break;
			case "terminal":
				newValue = target.value;
				newValidate = formValidate.specialRequest(
					newValue,
					rulesDescription.special
				);
				break;
			case "pickUp":
				newValue = target.value;
				newValidate = formValidate.specialRequest(
					newValue,
					rulesDescription.special
				);
				break;
			default:
		}

		if (newValue != null) {
			const auxClientInformation = [...clientInformation];
			auxClientInformation[index][target.name] = newValue;
			setClientInformation(auxClientInformation);
			const auxformValidateData = [...formValidatesData];
			auxformValidateData[index][target.name] = newValidate;
			setFormValidatesData(auxformValidateData);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		return false;
	};

	const onSubmit = (response) => {
		paymentInfoGoogleTagManagerEvent();

		const payment = {
			id: response.paymentIntent?.id ?? "",
			status: response.paymentIntent?.status ?? "error"
		}
		if (payment.status === "succeeded") {
			if (cart.booking.type === serviceTypeConstant.HOTEL && bodyReservation.isSuccess) {
				const request = checkoutUtils.bodyReservationHotel(
					params,
					clientInformation,
					bodyReservation,
					payPublicRate,
					payment,
					language
				);
				serviceHotelAction.booking(
					{ ...request, authSession },
					(response) => {
						if(response && response.statusCode){
							storage.setLocalStorage(
								`static-voucher-${query.cart}`,
								response,
								MINUTES_LOCAL_STORAGE
							);
							const location = {
								pathname: UrlVoucherStatic,
								search: `?${new URLSearchParams({
									cart: query.cart
								}).toString()}`,
							};
							window.location.href = location.pathname + location.search;		
						}
						else {
							const auxCart = { ...cart };
							auxCart.clientReference = {
								reference: typeof response.clientReference === "string" ? parseInt(response.clientReference) : response.clientReference,
								email: response.holderEmail,
							};
							auxCart.type = checkoutStep.VOUCHER;
							storage.setLocalStorage(
								query.cart,
								auxCart,
								MINUTES_LOCAL_STORAGE
							);

							const location = {
								pathname: localizedUrlVoucherHotel,
								search: `?${new URLSearchParams({
									cart: auxCart.uuid,
								}).toString()}`,
							};
							window.scrollTo(0, 0);							
							window.location.href = location.pathname + location.search;
						} 
					},
					(ex) => {
						console.error(ex);
						storage.setLocalStorage(
							`static-voucher-${query.cart}`,
							{
								statusCode: STATUSCODE.PAIDANDBOOKINGNOTCONFIRMED
							},
							MINUTES_LOCAL_STORAGE
						);
						const location = {
							pathname: UrlVoucherStatic,
							search: `?${new URLSearchParams({
								cart: query.cart
							}).toString()}`,
						};
						window.location.href = location.pathname + location.search;
					}
				);
			} 
			else if (cart.booking.type === serviceTypeConstant.PACKAGE) {
				const request = checkoutUtils.bodyReservationHotel(
					params,
					clientInformation,
					bodyReservation,
					payPublicRate,
					payment,
					language
				);

				serviceHotelAction.booking(
					{ ...request, authSession },
					(response) => {
						const auxCart = { ...cart };
						auxCart.clientReference = {
							reference: typeof response.clientReference === "string" ? parseInt(response.clientReference) : response.clientReference,
							email: response.holderEmail,
						};
						auxCart.type = checkoutStep.VOUCHER;
						storage.setLocalStorage(query.cart, auxCart, MINUTES_LOCAL_STORAGE);
						const location = {
							pathname: localizedUrlVoucherPackage,
							search: `?${new URLSearchParams({
								cart: auxCart.uuid,
							}).toString()}`,
						};

						window.scrollTo(0, 0);
						navigate(location);
					},
					(ex) => {
						console.error(ex);
					}
				);
			} 
			else if (cart.booking.type === serviceTypeConstant.TOUR) {
				const auxCart = { ...cart };
				auxCart.clientReference = {
					reference: 29,
					email: clientInformation[0].email,
				};

				auxCart.type = checkoutStep.VOUCHER;
				storage.setLocalStorage(query.cart, auxCart, MINUTES_LOCAL_STORAGE);
				const location = {
					pathname: localizedUrlVoucherTour,
					search: `?${new URLSearchParams({
						cart: auxCart.uuid,
					}).toString()}`,
				};

				window.scrollTo(0, 0);
				navigate(location);
			} 
			else if (cart.booking.type === serviceTypeConstant.TRANSFER) {
				const auxCart = { ...cart };
				auxCart.clientReference = {
					reference: 29,
					email: clientInformation[0].email,
				};

				auxCart.type = checkoutStep.VOUCHER;
				storage.setLocalStorage(query.cart, auxCart, MINUTES_LOCAL_STORAGE);
				const location = {
					pathname: localizedUrlVoucherShutter,
					search: `?${new URLSearchParams({
						cart: auxCart.uuid,
					}).toString()}`,
				};

				window.scrollTo(0, 0);
				navigate(location);
			}
		}
		else {
			setErrorPayment(true);
		}
	};

	const onChangePaymentService = (response) => {
		//TODO publica es true
		setPayPublicRate(response);
	};

	//Events
	const updateMetaData = () => {
		const reservation = storage.getLocalStorage(query.cart);
		pageOptions.title = pageOptions.title.replace("#SERVICE", reservation?.booking?.type ?? "")
		pageOptions.titlePage = pageOptions?.titlePage ?? pageOptions.title;
	};

	const disableEditInformationButton = (disable) => {
		setPaymentProcessing(disable);
	};

	const getCheckRate = async (hotel, isRateKey = 0) => {
		let shoppingCartData = { items: [], isSuccess: false, isError: false };
		let request = {
			culture: localeUtils.handleLanguage(locale),
			cartId: query.cart,
		};

		if (hotel && isRateKey === 0) {
			let rateKeys = [
				{
					locale: locale,
					rateKey: isRateKey ? hotel : hotel.rooms[0].rateKeyLead,
					service: commonUtils.getServiceConstantByCode(query.s).toUpperCase(),
				},
			];

			request = { rateKeys: rateKeys, ...request };
		}

		await serviceHotelAction.checkRates(
			{ ...request, authSession },
			(response) => {
				if (response && response?.items?.length > 0) {
					setBodyReservation(response);
					shoppingCartData = response;
					setCheckRate(true)

				} else {
					shoppingCartData.isError = true;
					setBodyReservation(shoppingCartData);

				}
			},
			() => {
				shoppingCartData.isError = true;
				setBodyReservation(shoppingCartData);
			}
		);

		return shoppingCartData;
	};

	const checkRatePrePayment = async () => {
		const reservation = storage.getLocalStorage(query.cart);
		const hotel = checkoutUtils.getHotel(reservation.services);
		let checkRate = false;

		let request = {
			culture: localeUtils.handleLanguage(locale),
			cartId: query.cart,
		};

		if (hotel.rooms[0]?.rateKeyLead) {
			let rateKeys = [
				{
					locale: locale,
					rateKey: hotel.rooms[0].rateKeyLead,
					service: commonUtils.getServiceConstantByCode(query.s).toUpperCase(),
				},
			];
			request = { rateKeys: rateKeys, ...request };
		}

		await serviceHotelAction.checkRates(
			{ ...request, authSession },
			(response) => {
				setBodyReservation(response);
				checkRate = true;
			},
			() => {
				setBodyReservation({ isSuccess: false, isError: true });
				checkRate = false
			}
		);
		return checkRate;
	}

	const goBackPage = () => {
		if (cart.booking.type === serviceTypeConstant.HOTEL) {
			const newHotel = checkoutUtils.getHotel(cart.services);
			let ratekey = "";
			if (newHotel?.rooms[0]?.rateKeyLead) {
				ratekey = newHotel.rooms[0].rateKeyLead.split(
					`|${sourceMarket}`
				)[0];

			} else {
				ratekey = newHotel.rooms[0].rates[0].rateKey.split(
					`|${sourceMarket}`
				)[0];
			}

			let backQuery = {}
			if (cart.booking?.paxes) {
				backQuery = hotelsValidate.paramsListforQuery(cart.booking);
			}


			let keyname = "";

			if (!cart.booking?.paxes) {
				backQuery.paxes = searchParams.get("paxes");
			}

			if (!cart.booking?.checkin) {
				backQuery.checkin = searchParams.get("checkin");
			}

			if (!cart.booking?.checkout) {
				backQuery.checkout = searchParams.get("checkout");
			}

			if (searchParams.get("sn")) {
				backQuery.sn = searchParams.get("sn");
				keyname = `${hotelsValidate.convertStringToKeyname(searchParams.get("sn"))}/`
			}

			if (searchParams.get("destinationkeyword")) {
				backQuery.destinationkeyword = searchParams.get("destinationkeyword");
			}

			if (searchParams.get("destination")) {
				backQuery.destination = searchParams.get("destination");
			}

			const locationPage = {
				pathname: `/${locale}${pathnames.hotel.pathname}${keyname}${newHotel.externalId}`,
				search: `?${new URLSearchParams(
					backQuery
				).toString()}&ratekey=${ratekey}`,
			};
			window.scrollTo(0, 0);
			navigate(locationPage);
		}
		window.removeEventListener("popstate", goBackPage);
	};

	const generetePayment = (fraud) => {
		const auxService = params.type === serviceTypeConstant.HOTEL
			? checkoutUtils.getHotel(cart.services)
			: params.type === serviceTypeConstant.TOUR
				? checkoutUtils.getTour(cart.services)
				: params.type === serviceTypeConstant.TRANSFER
					? checkoutUtils.getShutter(cart.services)
					: params.type === serviceTypeConstant.PACKAGE
						? checkoutUtils.getHotel(cart.services)
						: {};

		var amount = params.type === serviceTypeConstant.HOTEL
			? (auxService.rooms[0]?.total ?? auxService.rooms[0].rates[0].total)
			: params.type === serviceTypeConstant.PACKAGE
				? (auxService.rooms[0]?.total ?? auxService.rooms[0].rates[0].total)
				: params.type === serviceTypeConstant.TRANSFER
					? auxService.rate.total
					: 1000;
		const request = {
			cartReference: cart.uuid,
			name: fraud.name,
			surname: fraud.lastName,
			countryOfResidence: fraud.country ?? "MEX",
			email: fraud.email,
			phoneNumber: fraud.phone,
			amount: amount,
			currency: currency.code,
			description: `${params.type} reservation - ${auxService.name}`,
			phoneCountryCode: fraud.lada,
		};

		if (
			(params.type !== serviceTypeConstant.TRANSFER &&
				(auxService.rooms[0]?.total ?? auxService.rooms[0].rates[0].total) > 0) ||
			(params.type === serviceTypeConstant.TRANSFER &&
				auxService.rate.total > 0)
		) {
			paymentAction.payment(
				{ ...request, authSession },
				dispatch,
				(response) => {
					if (!response.fraudResult.approved)
						setGeneralMessage(
							intl.formatMessage({ id: "checkout.formRules.errorFraud" })
						);


				},
				() => {
					setGeneralMessage(
						intl.formatMessage({
							id: "checkout.formRules.errorGeneralPhoneEmail",
						})
					);
				}
			);
		}
	};

	const handleStep2 = () => {

		window.scrollTo(0, 0);
		paymentAction.setPayment(dispatch);
	};

	const handleStep1 = (e) => {

		let okSubmit = e.target.closest("form").IsValid();
		setValidNames(true)
		let validNames = validDuplicateName(clientInformation)

		if(!validNames){
			setValidNames(false)

			return false;
		}

		if (okSubmit) {
			try {
				generateLeadGoogleTagManagerEvent(clientInformation[0]);
				beginCheckoutGoogleTagManagerEvent();
			} catch (error) {
				console.log(error)
			}

			setGeneralMessage(null);
			generetePayment(clientInformation[0]);

		} else {
			setGeneralMessage(
				intl.formatMessage({ id: "checkout.formRules.errorGeneralForm" })
			);
		}
	};

	const validDuplicateName = (data) => {

		const names = new Set(); 
		for (const item of data) {

			let name = item.name.replace(/\s/g, "")
			.replace(/[^a-zA-Z0-9]/g, "")

			if (names.has(name)) {

				return false;
			} else {
			
				names.add(name);
			}
		}
		return true;
	}

	const contentDetailsRatesComments = () => {
		if (
			bodyReservation.items[0]?.comments &&
			bodyReservation.items[0]?.comments.length > 0
		) {

			const comments = bodyReservation.items[0]?.comments;
			const containHtml = hotelsValidate.contieneEntidadesHTML(comments)


			try {

				return (
					<div qui="con rate-comments" className="mt-4 mb-4 d-block">
						<div className="box-form">
							<h2>
								{intl.formatMessage({ id: "checkout.titleImportantInformation" })}
							</h2>
							<p className="checkout-rate-details-comments">
								{containHtml.containHtmlTag &&
									htmlparse(comments)}

								{containHtml.containHtmlCode &&
									htmlparse(htmlparse(comments))}

								{!containHtml.containHtmlCode &&
									!containHtml.containHtmlTag &&
									htmlparse(comments)}
							</p>
						</div>
					</div>
				);

			} catch
			{
				return ("");
			}

		}
	};

	const renderCardPax = () => {
		let component = null;
		//TODO tipo hotel solo trae un servicio hotel
		//TODO tra una habitacion aun que tenga cargado varias habitaciones
		if (
			cart != null &&
			params.type === serviceTypeConstant.HOTEL &&
			cart.services[0].rooms[0].totalTaxesNotIncluded &&
			cart.services[0].rooms[0].totalTaxesNotIncluded > 0 &&
			cart.services[0].rooms[0].taxes &&
			cart.services[0].rooms[0]?.taxesOptions?.some((t) => !t.included)
		)
			component = (
				<div className="mt-4">
					<CardPaxes options={optionsPaxes} service={cart.services[0]} />
				</div>
			);

		return component;
	};

	// Google TagManager Events
	const paymentInfoGoogleTagManagerEvent = () => {
		try {
			const reservation = storage.getLocalStorage(query.cart);
			const ecommercePaymentInfo =
				serviceReponseToEcommersMapperUtils.paymentInfoEcommers(
					reservation.services,
					paymentType.CREDITCARD,
					currency.code
				);

			GoogleTagManagerUtils.addPaymentInfoEvent(ecommercePaymentInfo);
		} catch (error) {
			console.log(error);
		}
	};

	const generateLeadGoogleTagManagerEvent = (customer) => {
		try {
			const reservation = storage.getLocalStorage(query.cart);
			const ecommerce =
				serviceReponseToEcommersMapperUtils.generateLeadInfoEcommers(
					reservation.services,
					currency.code
				);

			GoogleTagManagerUtils.generateLeadEvent(ecommerce, customer);
		} catch (error) {
			console.log(error);
		}
	};

	const beginCheckoutGoogleTagManagerEvent = () => {
		try {

			const reservation = storage.getLocalStorage(query.cart);
			const ecommerce =
				serviceReponseToEcommersMapperUtils.beginCheckoutInfoEcommers(
					reservation.services,
					currency.code
				);

			GoogleTagManagerUtils.beginCheckoutEvent(ecommerce);
		} catch (error) {
			console.log(error);
		}
	};
	// Google TagManager Events

	//Shopping cart


	const serviceShoppingCartSetUp = () => {
		paymentAction.setPayment(dispatch);
		const reservation = storage.getLocalStorage(query.cart);

		if (
			reservation &&
			(reservation.type === undefined ||
				reservation.type === null ||
				reservation.type !== checkoutStep.VOUCHER)
		) {
			setCart(reservation);
			let nParams = { ...reservation.booking };
			setParams(nParams);

			if (nParams.type === serviceTypeConstant.HOTEL) {

				const auxParams = hotelsValidate.paramsHotelFormat(
					query,
					searchHotelParams.paramsRoom.rules
				);
				let isRateKey = 0;
				if (!nParams?.startDate && !nParams?.endDate) {
					nParams = { ...nParams, ...auxParams }
					isRateKey = 1;
				}

				setParams(nParams);
				const newHotel = checkoutUtils.getHotel(reservation.services);
				getCheckRate(newHotel, isRateKey);
				setCardSummary({
					...cardSummary,
					summaryPax: `${intl.formatMessage(
						{ id: "website.adultTotal" },
						{ count: guestsUtils.totalAdults(nParams.rooms) }
					)},
				   ${intl.formatMessage(
						{ id: "website.childrenTotal" },
						{ count: guestsUtils.totalChildren(nParams.rooms) }
					)}				
				  `,
					summaryDate: intl.formatMessage(
						{ id: "website.nightsTotal" },
						{ count: dateUtils.getNights(nParams.startDate, nParams.endDate) }
					),
					summaryAges: guestsUtils.totalChildYears(nParams.rooms),
				});
			} else if (nParams.type === serviceTypeConstant.PACKAGE) {
				const newHotel = checkoutUtils.getHotel(reservation.services);
				getCheckRate(newHotel);
				setCardSummary({
					...cardSummary,
					summaryPax: `${intl.formatMessage(
						{ id: "website.adultTotal" },
						{ count: guestsUtils.totalAdults(nParams.rooms) }
					)}, ${intl.formatMessage(
						{ id: "website.childrenTotal" },
						{ count: guestsUtils.totalChildren(nParams.rooms) }
					)}`,
					summaryDate: intl.formatMessage(
						{ id: "website.nightsTotal" },
						{ count: dateUtils.getNights(nParams.startDate, nParams.endDate) }
					),
				});
			} else if (nParams.type === serviceTypeConstant.TOUR) {
				setCardSummary({
					...cardSummary,
					summaryPax: `${intl.formatMessage(
						{ id: "website.adultTotal" },
						{ count: guestsUtils.totalAdults(nParams.rooms) }
					)}, ${intl.formatMessage(
						{ id: "website.childrenTotal" },
						{ count: guestsUtils.totalChildren(nParams.rooms) }
					)}`,
					summaryDate: intl.formatMessage(
						{ id: "website.nightsTotal" },
						{ count: 1 }
					),
				});
			} else if (nParams.type === serviceTypeConstant.TRANSFER) {
				setCardSummary({
					...cardSummary,
					summaryPax: `${intl.formatMessage(
						{ id: "website.adultTotal" },
						{ count: guestsUtils.totalAdults(nParams.rooms) }
					)}, ${intl.formatMessage(
						{ id: "website.childrenTotal" },
						{ count: guestsUtils.totalChildren(nParams.rooms) }
					)}`,
					summaryDate: intl.formatMessage(
						{ id: "website.nightsTotal" },
						{ count: 1 }
					),
					checkin: intl.formatMessage({ id: "general.departure" }),
					checkout: intl.formatMessage({ id: "general.arrival" }),
				});
			}

			const selectLada = ladaItems.filter((lada) => lada.code === locale);
			setClientInformation(
				nParams.rooms.map((room, i) => {
					return {
						name: "",
						lastName: "",
						email: "",
						phone: "",
						lada: selectLada.length > 0 ? selectLada[0].value : "52",
						iso:selectLada.length > 0 ? selectLada[0].iso : "MX",
						specialRequests: "",
					};
				})
			);
			setFormValidatesData(
				nParams.rooms.map((room, i) => {
					return {};
				})
			);
		} else {
			const location = { pathname: localizedUrlHome };
			navigate(location);
		}
	};

	const createShoppingCartData = (cartId, cartServices) => {


		const request = checkoutUtils.reservationHotel(
			cartServices[0].hotel.details,//newHotel,
			[],//params,
			cartServices[0].hotel.details?.gallery[0],
			cartId
		);

		storage.setLocalStorage(
			request.uuid,
			request,
			process.env.REACT_APP_SHOPPING_SESSION_CART
		);

	}


	//** Component functions section

	//** useEffect

	// Initial useEffect
	useEffect(() => {
		window.scrollTo(0, 0);
		const reservation = storage.getLocalStorage(query.cart);
		const fetchShoppingCart = async () => {
			if (!reservation) {
				const shopping = await getCheckRate(null, 1);
				if (shopping?.isSuccess) {
					createShoppingCartData(query.cart, shopping.items);
					serviceShoppingCartSetUp();
				} else {
					const location = { pathname: localizedUrlHome };
					navigate(location);
				}
			} else {
				serviceShoppingCartSetUp();
			}
		};

		fetchShoppingCart();
	}, []);

	useEffect(() => {
		updateMetaData();
	}, [locale, pageOptions.title]);

	useEffect(() => {
		if (cart !== null) {
			window.addEventListener("popstate", goBackPage);
		}
	}, [cart]);

	// goes to the payment form if the user data is correct
	useEffect(() => {
		if (payment.isSuccess && payment?.fraudResult?.approved) {
			divRef.current.scrollIntoView({ behavior: 'smooth' });
		}

	}, [payment.isSuccess]);

	
	//** useEffect section

	//** Custom hook section

	//Modal validations for session modals
	useModalVoucher(locale);

	// modal para cuando no pasan los pagos
	useModal(modalBookingError, errorPayment);

	//Modal check rate
	useModal(modalCheckRate, bodyReservation.isError, goBackPage);
	
	//** Custom hook section

	const goHome = () => {	
		let reservationStorage = storage.getLocalStorage(query.cart);
		if (reservationStorage?.type === "VOUCHER") {
		 let location = { pathname: localizedUrlHome };
		 window.removeEventListener('pageshow', goHome);
		 navigate(location);
		}
    };

    window.addEventListener("pageshow", goHome);
	
	return (
		cart != null && checkRate ?
			<Container qui="container checkout" className="mb-5 container-aux-checkout">
				{!isMobile && (
					<Row>
						<Col className="mt-4">
							<h1 className="title-service-general">
								{intl.formatMessage({ id: "checkout.titlePresentation" })}
							</h1>
						</Col>
					</Row>
				)}
				<Row>
					<Col className="col-12 col-md-12 col-lg-8 mt-4 checkout-summary-form">
						{cart?.booking?.type === serviceTypeConstant.PACKAGE && (
							<div className="box-form mb-4 box-tua-form">
								<CardContainerTua />
							</div>
						)}
						<Form qui="frm checkout-lead" onSubmit={handleSubmit}>
							{params.type !== null &&
								(!payment.isSuccess || !payment.fraudResult?.approved) &&
								clientInformation.map((client, index) => {
									return (
										<div
											style={{ display: "block" }}
											className="mb-4"
											key={index}
											data-index={index}
										>
											<div className="box-form mb-4 box-holder">
												{index === 0 && (
													<>
														<h2>
															{intl.formatMessage({
																id: "checkout.form.title",
															})}
														</h2>
														<p>
															{intl.formatMessage({
																id: "checkout.form.subtitle",
															})}
														</p>
													</>
												)}
												{index > 0 && (
													<p>
														{intl.formatMessage({
															id: "checkout.roomHolder",
														})}
														{" " + (index + 1)}
													</p>
												)}
												<div className="box-form-container">
													<div className="box-form-item">
														<InputLabel
															qa="ipt firstName"
															item={form.name}
															value={client.name}
															id={`${form.name.id}-${index}`}
															onChange={(e) => onChangeInput(e, index)}
															name="name"
															required
														/>
													</div>
													<div className="box-form-item">
														<InputLabel
															qa="ipt lastName"
															item={form.lastName}
															value={client.lastName}
															id={`${form.lastName.id}-${index}`}
															onChange={(e) => onChangeInput(e, index)}
															name="lastName"
															required
														/>
													</div>
												</div>
											</div>
											{index === 0 && (
												<div className="box-form mb-4 box-notifications">
													<h2>
														{intl.formatMessage({
															id: "checkout.form.mail",
														})}{" "}
													</h2>
													<p>
														{intl.formatMessage({
															id: "checkout.form.phone",
														})}{" "}
													</p>
													<div className="box-form-container box-contact">
														<div className="box-form-item">
															<InputDropdown
																qa="select phone"
																item={form.phone}
																value={client.phone}
																valueSelect={client.lada}
																countrySelect={client.iso}
																id={`${form.phone.id}-${index}`}
																onChange={(e) => onChangeInput(e, index)}
																name="phone"
																autoComplete="off"
																onKeyDown={onEnterEvent}
																ladaItems={ladaItems}
																current={locale}
																onChangeLada={(event) =>
																	onChangeSelect(event, index)
																}
																required
															/>
														</div>
														<div className="box-form-item">
															<InputLabel
																qa="ipt email"
																item={form.email}
																value={client.email}
																id={`${form.email.id}-${index}`}
																onChange={(event) =>
																	onChangeInput(event, index)
																}
																name="email"
																required
															/>
														</div>
													</div>
												</div>
											)}
											{index === clientInformation.length - 1 && (
												<div style={{display:"none"}} className="box-form box-special-requests">
													{cart?.booking?.type ===
														serviceTypeConstant.TRANSFER && (
															<>
																<h2>
																	{intl.formatMessage({
																		id: "checkout.form.data.transfer",
																	})}
																</h2>
																<p className="title-transfer-icon">
																	{iconUtils.icon(iconContants.LOCATION)}
																	{intl.formatMessage({
																		id: "general.origin",
																	})}{" "}
																	: Aeropuerto
																</p>
																<div className="box-form-container">
																	<div className="box-form-item">
																		<InputLabel
																			qa={"ipt airline-1"}
																			item={form.airline}
																			value={client.airline}
																			id={`${form.airline.id}-${index}`}
																			onChange={(e) => onChangeInput(e, index)}
																			name="airline"
																		/>
																	</div>
																	<div className="box-form-item">
																		<InputLabel
																			qa={"ipt numFlight-1"}
																			item={form.numFlight}
																			value={client.numFlight}
																			id={`${form.numFlight.id}-${index}`}
																			onChange={(e) => onChangeInput(e, index)}
																			name="numFlight"
																		/>
																	</div>
																</div>
																<div className="box-form-container">
																	<div className="box-form-item">
																		<InputLabel
																			qa={"ipt terminal-1"}
																			item={form.terminal}
																			value={client.terminal}
																			id={`${form.terminal.id}-${index}`}
																			onChange={(e) => onChangeInput(e, index)}
																			name="terminal"
																		/>
																	</div>
																	<div className="box-form-item">
																		<InputLabel
																			qa={"ipt pickUp"}
																			item={form.pickUp}
																			value={client.pickUp}
																			id={`${form.pickUp.id}-${index}`}
																			onChange={(e) => onChangeInput(e, index)}
																			name="pickUp"
																		/>
																	</div>
																</div>
																{cart?.booking?.type === transferType && (
																	<>
																		<p className="title-transfer-icon mt-3">
																			{iconUtils.icon(iconContants.LOCATION)}
																			{intl.formatMessage({
																				id: "general.destiny",
																			})}{" "}
																			: Alojamiento
																		</p>
																		<div className="box-form-container mt-2">
																			<div className="box-form-item">
																				<InputLabel
																					qa={"ipt airline-2"}
																					item={form.airline}
																					value={client.airline}
																					id={`${form.airline.id}-${index}`}
																					onChange={(e) =>
																						onChangeInput(e, index)
																					}
																					name="airline"
																				/>
																			</div>
																			<div className="box-form-item">
																				<InputLabel
																					qa={"ipt numFlight-2"}
																					item={form.numFlight}
																					value={client.numFlight}
																					id={`${form.numFlight.id}-${index}`}
																					onChange={(e) =>
																						onChangeInput(e, index)
																					}
																					name="numFlight"
																				/>
																			</div>
																		</div>
																		<div className="box-form-container mt-2">
																			<div className="box-form-item">
																				<InputLabel
																					qa={"ipt terminal-2"}
																					item={form.terminal}
																					value={client.terminal}
																					id={`${form.terminal.id}-${index}`}
																					onChange={(e) =>
																						onChangeInput(e, index)
																					}
																					name="terminal"
																				/>
																			</div>
																			<div className="box-form-item">
																				<InputLabel
																					qa={"ipt pickUp-2"}
																					item={form.pickUp}
																					value={client.pickUp}
																					id={`${form.pickUp.id}-${index}`}
																					onChange={(e) =>
																						onChangeInput(e, index)
																					}
																					name="pickUp"
																				/>
																			</div>
																		</div>
																	</>
																)}
															</>
														)}

													<div className="box-form-container">
														<div className="box-form-item-full">
															<TextArea
																qa={"textarea specialRequests"}
																item={form.specialRequests}
																value={clientInformation[0].specialRequests}
																id={`${form.specialRequests.id}`}
																onChange={(e) => onChangeInput(e, 0)}
																name="specialRequests"
																rows="3"
																maxLength="255"
															/>
														</div>
													</div>
												</div>
											)}
										</div>
									);
								})}
							{!validNames && (
								<>
									<AlertErrorWithButton qui={" warningDuplicateHolder"}
										hasButton={false}
										variant={"warning"}
									>
								 <p>
								 {iconUtils.icon(iconContants.TBALERTCIRCLE)} {intl.formatMessage({ id: "checkout.formRules.warningDuplicateHolder" })}
							    </p>	
									</AlertErrorWithButton>
								</>
							)}

							{payment.isSuccess &&
								payment.fraudResult.approved &&
								clientInformation && (
									<div qa={"container clientInformation"} className="box-form mb-4">
										<h2 className="mb-4">
											{intl.formatMessage({ id: "checkout.form.EditTitle" })}
										</h2>
										{clientInformation.map((client, index) => {
											return (
												<div key={index}>
													{clientInformation.length > 1 && (
														<h2 className="mb-4">
															{`${intl.formatMessage({
																id: "checkout.form.RoomText",
															})} ${index + 1}`}
														</h2>
													)}
													<p>
														<strong>{form.name.label}:</strong>
														{` ${client.name}`}
													</p>
													<p>
														<strong>{form.lastName.label}:</strong>
														{` ${client.lastName}`}
													</p>
													{client.phone && (
														<p>
															<strong>{form.phone.label}:</strong>
															{`+${client.lada} ${client.phone}`}
														</p>
													)}
													{client.email && (
														<p>
															<strong>{form.email.label}:</strong>
															{` ${client.email}`}
														</p>
													)}
												</div>
											);
										})}
										{clientInformation.length &&
											clientInformation[0].specialRequests && (
												<p>
													<strong>{form.specialRequests.label}:</strong>
													<p className="textSR">  {` ${clientInformation[0].specialRequests}`}  </p>
												</p>
											)}
									</div>
								)}
							<div className="col-12 mt-4">
								<CheckBoxTerms
									checkBoxTermsData={options.components.CheckBoxTerms}
									isChecked={isChecked}
									handleCheckboxChange={handleCheckboxChange}
								/>
							</div>
							<div className="col-12 mt-4">
								{(!payment.isSuccess || !payment.fraudResult?.approved) && (
									<>
									<Button id="generate_lead"
										qui={"button showPaymentMethods"}
										className="px-4 checkout-spt-btn"
										disabled={payment.isFetching || !isChecked}
										onClick={handleStep1}
										type="submit"
									>
										{intl.formatMessage({ id: "checkout.form.step1Button" })}
									</Button>
									<CardTypeSection/>
									</>	
								)}
								{payment.isSuccess && payment.fraudResult.approved && (
									<Button qui={"button editClientInformation"}
										className="px-4 checkout-spt-btn"
										disabled={paymentProcessing}
										onClick={handleStep2}
									>
										{intl.formatMessage({ id: "checkout.form.step2Button" })}
									</Button>
								)}					
							</div>
						</Form>
						
						<div>
							{payment.isSuccess &&
								payment?.fraudResult?.approved && !payment.isFetching &&
								!bodyReservation.isError && (
									<Elements stripe={stripePromise} options={{locale}}>
										<CheckoutForm
											canBook={authSession.canBook}
											paymentId={payment.paymentId}
											clientSecret={payment.clientSecret}
											key={payment.paymentId}
											clienteInformation={clientInformation}
											onSubmit={onSubmit}
											divRef={divRef}
											disableEditInformationButton={disableEditInformationButton}
											checkRatePrePayment={checkRatePrePayment}
											bodyReservation={bodyReservation}
											intl={intl}
										/>
									</Elements>

								)}
							{payment.isFetching && (
								<>
									<div
										className="mt-3 mb-3 d-flex"
										style={{ alignItems: "center" }}
									>
										<Spinner animation="border" role="status">
											<span className="visually-hidden">
												{intl.formatMessage({
													id: "checkout.formStripe.loading",
												})}
											</span>
										</Spinner>
										<span className="ms-2">
											{intl.formatMessage({
												id: "checkout.formStripe.loading",
											})}
										</span>
									</div>
									<SkeletonPayment />
								</>
							)}
							{generalMessage && (
								<Alert variant="danger" qui={"generalMessage alertGeneralMessage"} className="mt-3">
									{generalMessage}
								</Alert>
							)}
				
						</div>
						{bodyReservation.isSuccess && contentDetailsRatesComments()}
					</Col>
					<Col className="col-12 col-md-12 col-lg-4 mt-4 checkout-summary-ticket">
						<div className="card-content">
							{!bodyReservation.isSuccess ? <div></div> : <CardContainerSummary
								service={cart.services[0]}
								services={cart.services}
								searchBooking={params}
								txt={cardSummary}
								locale={locale}
								currency={currency.code}
								checkRates={bodyReservation}
								payPublicRate={payPublicRate}
								onChangePaymentService={onChangePaymentService}
							/>}
						</div>
						{renderCardPax()}
					</Col>
				</Row>
			</Container> : <SkeletonPage />
	);
};
export default CheckoutPage;
