import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import formValidate from "../../../../validate/checkout/formValidate";
import commonUtils from "../../../../utils/commonUtils";
import checkoutUtils from "../../../../utils/checkoutUtils";
import { useGlobalState } from "../../../../store/StoreProvider";
import homeServiceAction from "../../../../actions/homeServiceActions";
import ReCAPTCHA from "react-google-recaptcha";
import "./components/style.scss";

export const BasicForm = (props) => {
  const { form } = props;
  const { authSession, locale, siteInformation } = useGlobalState();
  const captchaRef = useRef(null);
  const intl = useIntl();
  const siteID = siteInformation?.site?.id ?? process.env.REACT_APP_AUTH_SITEID;
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({
    error: false,
    tandC: false,
    capcha: false,
  });
  const [terms, setTerms] = useState(false);
  const [data, setData] = useState({
    siteId: siteID,
  });
  const [isSent, setIsSent] = useState(false);
  const token = captchaRef?.current?.getValue();

  const handdleForm = (value, propety) => {
    let newValue = null;
    let newValidate = null;
    setShowAlert({
      error: false,
      tandC: false,
      capcha: false,
    });
    setIsSent(false);
    switch (propety) {
      case "name":
      case "agency":
        newValue =
          value != null || value.length > 0
            ? commonUtils.compoundNameUpperCase(value)
            : value;
        newValidate = formValidate.name(
          newValue,
          checkoutUtils.rulesDescription.name
        );
        break;
      case "phone":
        newValue = commonUtils.removeSpace(value);
        newValidate = formValidate.phone(
          newValue,
          checkoutUtils.rulesDescription.phone
        );
        break;
      case "email":
        newValue = value.trim();
        newValidate = formValidate.email(
          newValue,
          checkoutUtils.rulesDescription.email
        );
        break;
    }
    setData((prev) => ({
      ...prev,
      [propety]: value,
    }));
  };

  const affiliateFormSubmission = (params) => {
    setLoading(true);
    const lang = locale?.slice(0, -3) + locale?.slice(-2).toUpperCase();
    homeServiceAction.affiliationForm(
      { ...params, authSession, culture: lang },
      (response) => {
        setIsSent(response.sent);
        setData({
          siteId: siteID,
        });
        setTerms(false);
        setLoading(false);
        captchaRef.current.reset();
      },
      (error) => {
        console.error(error);
        setShowAlert((prev) => ({
          ...prev,
          error: true,
        }));
        setData({
          siteId: siteID,
        });
        setTerms(false);
        setIsSent(false);
        setLoading(false);
        captchaRef.current.reset();
      }
    );
  };

  const onsubmit = (e) => {
    setIsSent(false);
    let okSubmit = e.target.closest("form").IsValid();
    e.preventDefault();
    setShowAlert({
      error: !okSubmit,
      tandC: !terms,
      capcha: !captchaRef.current.getValue(),
    });

    if (terms && okSubmit && captchaRef.current.getValue()) {
      affiliateFormSubmission(data);
    }
  };

  return (
    <div className="basic-form" id="basic-form">
      <Form>
        {form.items.map((item, i) => {
          return (
            <InputGroup key={i}>
              <Form.Control
                autocomplete="off"
                name={item.name}
                type={item.type}
                disabled={loading}
                onChange={(e) => handdleForm(e.target.value, item.name)}
                value={data[item.name] || ""}
                placeholder={item.placeholder}
                id={`${item?.id}`}
                required
              />
            </InputGroup>
          );
        })}
        <InputGroup>
          <Form.Check
            autocomplete="off"
            id={`form-check`}
            checked={terms}
            onChange={(e) => setTerms((prev) => !prev)}
            className="form-check"
            type="checkbox"
            disabled={loading}
            label={intl.formatMessage({ id: "form.terms" })}
          />
        </InputGroup>
        {process.env.REACT_APP_SITE_KEY && (
          <InputGroup className="input-captcha">
            <div className="input-center captcha">
              <ReCAPTCHA
                style={{ display: "inline-block" }}
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
              />
            </div>
          </InputGroup>
        )}

        <InputGroup>
          <button className="btn" disabled={loading} onClick={onsubmit}>
            {loading ? (
              <div>
                {intl.formatMessage({ id: "voucherPage.titleError" })}{" "}
                <Spinner as="span" animation="border" size="sm" />
              </div>
            ) : (
              form.btn
            )}
          </button>
        </InputGroup>
        <div className="form-alert">
          {showAlert.tandC && (
            <li> {intl.formatMessage({ id: "form.termsMsg" })}</li>
          )}

          {showAlert.error && (
            <li> {intl.formatMessage({ id: "form.Msg" })}</li>
          )}

          {showAlert.capcha && (
            <li> {intl.formatMessage({ id: "form.capcha" })}</li>
          )}
        </div>
        {isSent && (
          <div className="form-message-success">
            {intl.formatMessage({ id: "form.validate" })}
          </div>
        )}
      </Form>
    </div>
  );
};
