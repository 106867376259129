import languageConstants from "../../../constants/languageConstants";
import pageLinksConstants from "../../../constants/pageLinksConstants";
import footerComponent from "./components/footerComponent";
import headerComponent from "./components/headerComponent";
import commonUtils from "../../../utils/commonUtils";
import meta from "../../../theme/metaSiteData"
import webSiteConstant from "../../../constants/webSitesiteRoutesConstant"
const metaData = commonUtils.filterMetaByRouteType(meta.metaData_es_mx,webSiteConstant.LISTHOTEL);




let response = {
    routeType: webSiteConstant.LISTHOTEL,
    siteLocale: languageConstants.MX,
    pathname: pageLinksConstants.HOTELS,
    hasHeaderLayout: false,
    hasFooterLayout: false,
    hasDisabled: false,
    hideMenuMobile: true,
    itemsComponent: [
        {
            order:1,
            type: "header",
            showComponent:true,
            ...headerComponent
        },
        {
            order:2,
            type: "hotels-list-component",
            showComponent:true,
        },
        {
            order:3,
            type: "footer",
            showComponent:true,
            ...footerComponent
        },
    ]
}

response = {...response,...metaData}

export default response;