import { Container, Button, Image } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import serviceVoucherAction from "../../../actions/serviceVoucherAction";
import dateUtils from "../../../utils/dateUtils";
import imageDefault from '../../../assets/img/defaultImage.svg'
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import hotelsValidate from "../../../validate/hotelsValidate";
import checkoutUtils from "../../../utils/checkoutUtils"
import rateUtils from "../../../utils/rateUtils";
import tourVoucherMock from "../../../mock/voucher/tourVoucherMock";
import { checkoutStep } from "../../../constants/common";
import SkeletonVoucher from "../../../components/skeleton/SkeletonVoucher/SkeletonVoucher";
import useRedirectToHome from "../../../hooks/useRedirectHomeWithTimerAndDeteleLocalStorage";
import { useGlobalState } from "../../../store/StoreProvider";

const TourVoucherPage = (props) => {
  const { resourceSite } = props;
  const {locale} = useGlobalState();
  let navigate = useNavigate();
  const intl = useIntl();
  const localizedUrlHome = `/${locale}`;
  const { authSession } = useGlobalState();
  let [searchParams, setSearchParams] = useSearchParams();
  const [hotel, setHotel] = useState({ id: null });
  const [cart, setCart] = useState(null);
  const gotToHome = useRedirectToHome(cart, 14, locale);
  const query = {
    cart: searchParams.get("cart"),
  };

  const [itinerary, setItinerary] = useState({ isSucces: false })
  const title= intl.formatMessage({ id: "voucherPage.title" });
  window.document.title = title
  const searchHotelParams = resourceSite.searchHotelParams;
  const [params, setParams] = useState(
    hotelsValidate.paramsHotelFormat(query, searchHotelParams.paramsRoom.rules)
  );

  const getItinerary = (query) => {
    serviceVoucherAction.hotelItinerary(
      {...query, authSession},
      (response) => {
        setItinerary(response);

      },
      (error) => {
        console.error(error);
      }
    )
  }

  const onClickRedirect = () => {
    const location = {
      pathname: localizedUrlHome
    };
    window.scrollTo(0, 0);
    navigate(location);
  }

  useEffect(() => {


    //const reservation = storage.getLocalStorage(query.cart);
    const reservation = tourVoucherMock;
    window.scrollTo(0, 0);
    if (reservation && reservation.type === checkoutStep.VOUCHER) {
      setCart(reservation);
      const nParams = { ...reservation.booking };
      setParams(nParams);
      const newHotel = checkoutUtils.getHotel(reservation.services);
      setHotel(newHotel);

      if (reservation.clientReference) {
        const request = {
          "reference": reservation.clientReference.reference,
          "email": reservation.clientReference.email,
          locale
        };
        //getItinerary(request);
      }
      else {
        const location = { pathname: localizedUrlHome };
        navigate(location);
      }

    } else {
      const location = { pathname: localizedUrlHome };
      navigate(location);
    }
  }, []);

  return (
    <>
      <Container className="container-aux-voucher">
        {hotel && (
          <div className="voucher-container">
            <h1 className="title-service-general">
              {hotel.type === "Hotel" ? intl.formatMessage({ id: "voucherPage.titleHotelSucces" })
                : intl.formatMessage({ id: "voucherPage.titleTourSucces" })}
            </h1>
            <br />
            {(cart === null) &&
                <SkeletonVoucher />
            }
            {(cart !== null) && (
              <>
                <div className="voucher-card">
                  <div className="voucher-card-header">
                    <div className="container-img-perfil">
                      {hotel.gallery && hotel.gallery.length > 0 && (
                        <Image loading="lazy" alt={hotel.gallery[0].name} src={hotel.gallery[0].photoUris} className='voucher-img-circle'
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = imageDefault
                          }}
                        />
                      )}
                    </div>

                    <div className="d-flex w-100 container-rest-header ">

                      <div className="container-title-stars-checks">
                        <h2 className="title">{hotel.name}</h2>
                        <div className="container-checkouts">
                          <div className="container-check-out">
                            <span className="strong" style={{ marginRight: '5px' }}>
                              {`${intl.formatMessage({ id: "general.reservationName" })}:  `}
                            </span>
                            <span>
                              Lilian Perez Aceves
                            </span>
                          </div>
                          <div className="container-check-out">
                            <span className="strong" style={{ marginRight: '5px' }}>
                              {`${intl.formatMessage({ id: "general.passanger" })}:  `}
                            </span>
                            <span>
                              2 Adultos
                            </span>
                          </div>
                          <div className="container-check-out">
                            <span className="strong" style={{ marginRight: '5px' }}>
                              {`${intl.formatMessage({ id: "general.date" })}:  `}
                            </span>
                            <span>
                              Mié., 02/may./2023
                            </span>
                          </div>
                          <div className="container-check-out">
                            <span className="strong" style={{ marginRight: '5px' }}>
                              {`${intl.formatMessage({ id: "general.startDate" })}:  `}
                            </span>
                            <span>
                              8:00 a.m.
                            </span>
                          </div>
                          <div className="container-check-out">
                            <span className="strong" style={{ marginRight: '5px' }}>
                              {`${intl.formatMessage({ id: "general.endDate" })}:  `}
                            </span>
                            <span>
                              3:00 p.m.
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="container-reference">
                        <div className="container-text-duo refer">
                          <span className="text-left">{`${intl.formatMessage({ id: "voucherPage.reference" })}:  `}</span>
                          <span className="text-right">efda9444</span>
                          {/* {itinerary.isSucces && itinerary.alphanumericReference && (
                            <span className="text-right">{itinerary.alphanumericReference}</span>
                          )} */}
                        </div>
                        <div className="container-text-duo date">
                          <span className="text-left">{`${intl.formatMessage({ id: "voucherPage.date" })}:  `}</span>
                          <span className="text-right">{dateUtils.dateFormat(new Date(), "DD-MM-YYYY", locale)}</span>
                          {/* {itinerary.isSucces && itinerary.dateCreatedUtc && (
                            <span className="text-right">{dateUtils.dateFormat(itinerary.dateCreatedUtc, "DD-MM-YYYY", locale)}</span>
                          )} */}
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="container-checkouts border-top-solid" style={{ paddingTop: '20px' }}>
                    <div className="container-subtitle">
                      <h2 className="text-subtitle">
                        {`${intl.formatMessage({ id: "tourVoucher.info" })}:  `}
                      </h2>
                    </div>
                    <br />
                    <p>Salidas todos los Lunes, Miércoles, Jueves, Viernes, Sábados y Domingos.</p>
                    <p>La hora exacta de tu Pick-up varía de acuerdo a la localización de tu hotel.</p>
                    <p>Este Tour tiene una duración de 8 horas aproximadamente.</p>
                  </div>
                </div>
                <br />
                <br />
                <div className="voucher-card-extra politicas">
                  <div className="container-subtitle">
                    <h2 className="text-subtitle">
                      {`${intl.formatMessage({ id: "tourVoucher.cancelation" })}:  `} </h2>
                  </div>
                  <br />
                  <p>Las reservaciones canceladas hasta 2 días antes de la fecha de llegada están sujetas a un cargo del 10% del total de la cantidad de su reservación.</p>
                  <p>Las reservaciones canceladas hasta dentro de las 48 horas antes de la fecha de llegada, o en caso de NO llegar a tomar la actividad, NO serán reembolsadas.</p>
                </div>
                <br />
                <br />
                <div className="voucher-card-extra pago">
                  <div className="container-subtitle">
                    <h2 className="text-subtitle">{intl.formatMessage({ id: "voucherPage.pay" })}</h2>
                  </div>
                  <br />
                  <div className="w-100 border-bottom-solid">
                    <p>{intl.formatMessage({id: "voucherPage.methodPay"})}</p>
										<p>{intl.formatMessage({id: "voucherPage.creditCard"})}</p>
                  </div>
                  <br />
                  {hotel.rooms && hotel.rooms.length > 0 && (
                    hotel.rooms.map((item, i) => {
                      return (
                        <div key={"concepto-pago" + i} className="container-text-duo">
                          <span className="text-total strong">{intl.formatMessage({ id: "voucherPage.publicRate" })}</span>

                          {item.total && item.currency && (
                            <span className="text-total strong">
                              {rateUtils.formatAmount(item.total, item.currency, locale)}
                            </span>
                          )}
                        </div>
                      )
                    })
                  )}
                </div>
                <br /> <br />
                <Button onClick={onClickRedirect} className="text-white d-block m-auto">
                  <span>{intl.formatMessage({ id: "button.backToList" })}</span>
                </Button>
              </>
            )}
          </div>
        )}

      </Container>
      <br />
    </>
  );
};

export default TourVoucherPage;
