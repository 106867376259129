/* eslint-disable import/no-anonymous-default-export */
export default {
    "reservationList.table.title": "Search from...",
    "reservationList.table.name": "Name",
    "reservationList.table.reservationId": "Reference Reservation",
    "reservationList.table.nameService": "Hotel name",
    "reservationList.table.nameSeller": "Seller name",
    "reservationList.table.netRate": "Net agency rate",
    "reservationList.table.publicRate": "Public Rate",
    "reservationList.table.nameServiceRoom": "Room name",
    "reservationList.table.roomCount": "Rooms",
    "reservationList.table.adultsTotal": "Adults",
    "reservationList.table.childrenTotal": "Children",
    "reservationList.table.dateReservation": "Reservation date",
    "reservationList.table.dateLimitCancellation": "Cancellation limit",
    "reservationList.table.dateLimitPayment": "Payment limit",
    "reservationList.table.statusReservation": "Status Reservation",
    "reservationList.table.statusPayment": "Status Payment",
    "reservationList.table.isRefundable": "Refundable",
    "reservationList.table.isPublicRate": "Type Rate",
    "reservationList.table.checkIn": "Check In",
    "reservationList.table.checkOut": "Check Out",
    "reservationList.table.td.isPublicRate": "Public Rate",
    "reservationList.table.td.nonPublicRate": "Net Rate",

    "reservationList.table.placeholderName": "Search by name",
    "reservationList.table.placeholder.reservationId": "Search by reservation",
    "reservationList.table.placeholder.nameService": "Search by hotel",
    "reservationList.table.placeholder.nameSeller": "Search by seller",
    "reservationList.table.placeholder.netRate": "Search by net rate",
    "reservationList.table.placeholder.publicRate": "Search by public rate",
    "reservationList.table.placeholder.nameServiceRoom": "Search by room",
    "reservationList.table.placeholder.roomCount": "Search by number rooms",
    "reservationList.table.placeholder.adultsTotal": "Search by number adults",
    "reservationList.table.placeholder.childrenTotal": "Search by number children",
    "reservationList.table.placeholder.isRefundable": "Select by",
    "reservationList.table.placeholder.isPublicRate": "Select by",
    "reservationList.table.placeholder.select": "Select",
    "reservationList.table.placeholder.any": "Any",

    "reservationList.table.emptyMessage": "No customers found.",
    "reservationList.table.currentPageReport": "Showing {first} to {last} of {totalRecords} entries",
    "reservationList.table.filter.roomCount": "{count, plural, =0 {without results} one {# Room} other {# Rooms}}",
    "reservationList.table.filter.adultCount": "{count, plural, =0 {without results} one {# Adult} other {# Adults}}",
    "reservationList.table.filter.childrenCount": "{count, plural, =0 {without results} one {# Child} other {# Children}}",
}