import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BannerHomeSection from "../components/section/BannerHomeSection";
import SlideTopDestinationSection from "../components/section/SlideTopDestinationSection";
import SlideVacationLifeSection from "../components/section/SlideVacationLifeSection";
import SlideTopThigsToDoSection from "../components/section/SlideTopThigsToDoSection";
import RightVideoSection from "../components/section/RightVideoSection";
import GridGallerySection from "../components/section/GridGallerySection";
import { BackgroundVideo } from "../components/section/BackgroundVideo";
import NewslatterSection from "../components/section/NewslatterSection";
import BannerFlatSection from "../components/section/BannerFlatSection";
import CardsIconBasic from "../components/section/CardsIconBasic";
import LoginPage from "./Login/LoginPage";
import { useDispatch, useGlobalState } from "../store/StoreProvider";
import FooterLayout from "./shared/FooterLayout";
import SearchBookingSection from "../components/section/SearchBookingSection";
import HeaderLayoutCustom from "./shared/HeaderLayoutCustom";
import pageOptionsAction from "../actions/pageOptionsAction";
import { useEffect } from "react";
import apiCall from "../siteResources/api";
import MyReservations from "./MyReservations/MyReservations";
import HotelListPage from "./hotel/HotelListPage/HotelListPage";
import HotelDetailPage from "./hotel/HotelDetailPage/HotelDetailPage";
import siteUtils from "../utils/siteUtils";
import CheckoutPage from "./checkout/CheckoutPage/CheckoutPage";
import VoucherPage from "./voucher/VoucherPage/VoucherPage";
import VoucherStatic from "./voucher/VoucherStatic/VoucherStatic";
import SkeletonPage from "../components/skeleton/SkeletonPage";
import Privacy from "../pages/general/Privacy/Privacy";
import TermsAndConditions from "../pages/general/TermsAndConditions/TermsAndConditions";
import CookiesPolicy from "../pages/general/CookiesPolicy/CookiesPolicy";
import { FormAffiliationSection } from "../components/section/FormAffiliationSection";
import usePageMeta from "../hooks/usePageMeta";
import NotFound from "./notFound/NotFound";
import storage from "../localstorage/storage";
import { localStorageConst } from "../constants/common";
import commonUtils from "../utils/commonUtils";
import ErrorPage from "../pages/errorPage/ErrorPage";

//dominio.com/:slug
const SlugPage = (props) => {
  //TODO resourceSite: informacion del sitio, resourceSite.api trae el objeto que hace funcion como la respuesta de una api de una cierta pagina
  const { positionPage } = props;
  const { locale, pageOptions, resourceSite, authSession, cmsComponents } =
    useGlobalState();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id: IDPage, keyname: KeyName } = useParams();
  const [searchParams] = useSearchParams();
  const query = { cart: searchParams.get("cart") };

  usePageMeta(pageOptions);
  useEffect(() => {
    if (resourceSite.isSuccess) {
      // //TODO fake llamado api por idioma
      const pathLocale = window.location?.pathname?.toLowerCase();
      //TODO obtener el slug
      const slug = siteUtils.getSlug(pathLocale, locale, IDPage, KeyName);

      const resourcePage =
        resourceSite.options.siteRouter?.find((a) => a.basename === slug) ??
        resourceSite.options.siteRouter?.find(
          (a) => a.basename === "/notfound"
        );
      if (resourcePage) {
        let responsePage = apiCall(locale, resourcePage.typePage);
        responsePage = slugComponentOptions(responsePage);

        if (responsePage) {
          pageOptionsAction.setFetching(dispatch);
          pageOptionsAction.updatePageOptions(responsePage, dispatch);
        } else {
          pageOptionsAction.setError(dispatch);
        }
      }
    }
    return () => {
      pageOptionsAction.setInitial(dispatch);
    };
  }, [locale, resourceSite, navigate]);

  useEffect(() => {
    return () => {
      pageOptionsAction.setInitial(dispatch);
    };
  }, []);

  const slugComponentOptions = (responsePage) => {
    const hostname = commonUtils.getEnvironmentHostname();

    let cmsKey = hostname + localStorageConst.COMPONENTSOPTIONS;
    let componentsOptions = storage.getLocalStorage(cmsKey);

    componentsOptions = componentsOptions?.pageOptions
      ? componentsOptions
      : cmsComponents;

    let componentsPage = componentsOptions?.pageOptions
      ?.filter(
        (x) =>
          x.routeType === responsePage.routeType &&
          x.siteLocale === responsePage.siteLocale
      )
      .flatMap((x) => x.itemsComponent);

    if (componentsPage?.length > 0) {
      pageOptionsAction.setFetching(dispatch);
      let filteredItems = [];

      componentsPage.forEach((itemComponent) => {
        filteredItems = insertOrUpdateItems(
          responsePage.itemsComponent,
          itemComponent
        );
      });
    }

    return responsePage;
  };

  function insertOrUpdateItems(itemsList, componentToInsert) {
    const existingIndex = itemsList.findIndex(
      (item) => item.type === componentToInsert.type
    );

    if (existingIndex !== -1) {
      // If an object with the same type exists, update it
      //itemsList[existingIndex] = componentToInsert;
      itemsList.splice(existingIndex, 1, componentToInsert);
    } else {
      // If not, add the object to the list
      itemsList.push(componentToInsert);
    }

    return itemsList;
  }

  let selectComponentPage = (item, position) => {
    //TODO identificamos a X pagina y decimos como pintarla
    //TODO dinamic-page ejemplo de header y footer desde el componentePage y no desde el layout
    const { type, ...restItemsOptions } = item;

    switch (item.type) {
      case "banner-title":
        return (
          <BannerHomeSection key={position} options={{ ...restItemsOptions }} />
        );
      case "vacation-life":
        return (
          <SlideVacationLifeSection
            key={position}
            options={{ ...restItemsOptions }}
          />
        );
      case "banner-video":
        return (
          <RightVideoSection key={position} options={{ ...restItemsOptions }} />
        );
      case "background-image":
        return (
          <BackgroundVideo key={position} options={{ ...restItemsOptions }} />
        );
      case "grid-gallery-section":
        return (
          <GridGallerySection
            key={position}
            options={{ ...restItemsOptions }}
          />
        );
      case "banner-flat-text":
        return (
          <BannerFlatSection key={position} options={{ ...restItemsOptions }} />
        );
      case "slide-top-destination":
        return (
          <SlideTopDestinationSection
            key={position}
            options={{ ...restItemsOptions }}
          />
        );
      case "form-affiliation-section":
        return (
          <FormAffiliationSection
            key={position}
            options={{ ...restItemsOptions }}
          />
        );
      case "slide-top-things-todo":
        return (
          <SlideTopThigsToDoSection
            key={position}
            options={{ ...restItemsOptions }}
          />
        );
      case "banner-newsletter":
        return (
          <NewslatterSection key={position} options={{ ...restItemsOptions }} />
        );
      case "my-reservations-component":
        return (
          <MyReservations key={position} options={{ ...restItemsOptions }} />
        );
      case "hotels-list-component":
        return (
          <HotelListPage key={position} options={{ ...restItemsOptions }} />
        );
      case "hotel-detail-component":
        return (
          <HotelDetailPage key={position} options={{ ...restItemsOptions }} />
        );
      case "checkout-section":
        return (
          <CheckoutPage key={position} options={{ ...restItemsOptions }} />
        );
      case "privacy-component":
        return <Privacy key={position} options={{ ...restItemsOptions }} />;
      case "terms-and-conditions-component":
        return (
          <TermsAndConditions
            key={position}
            options={{ ...restItemsOptions }}
          />
        );
      case "cookies-policy-component":
        return (
          <CookiesPolicy key={position} options={{ ...restItemsOptions }} />
        );

      case "search-booking":
        return (
          <SearchBookingSection
            key={position}
            options={{ ...restItemsOptions }}
          />
        );
      case "cards-iconBasic-section":
        return (
          <CardsIconBasic key={position} options={{ ...restItemsOptions }} />
        );
      case "login-component":
        return <LoginPage key={position} />;
      case "header":
        return (
          <HeaderLayoutCustom
            key={position}
            positionPage={positionPage}
            {...restItemsOptions}
          />
        );
      case "footer":
        const { footer, ...rest } = restItemsOptions;
        return (
          <FooterLayout
            key={position}
            secondMenu={restItemsOptions.secondMenu}
            showSocial={restItemsOptions.showSocial}
            showText={restItemsOptions.showText}
            contact={restItemsOptions.contact}
            footerInformation={footer}
          />
        );
      case "notFound":
        return <NotFound key={position} />;

      case "voucher":
        return <VoucherPage key={position} />;

      case "voucher-pending":
        return <VoucherStatic key={position} />;

      default:
        return <div key={position}></div>;
    }
  };

  const errorPageProps = resourceSite.options.errorType400;

  return (
    <>
      {pageOptions.isSuccess === false && <SkeletonPage />}
      {pageOptions.isFetching && <SkeletonPage />}
      {pageOptions.isError && (
        <ErrorPage
          type={errorPageProps.type}
          title={errorPageProps.title}
          text={errorPageProps.text}
          btn={errorPageProps.btn}
        />
      )}
      {pageOptions.isSuccess &&
        pageOptions.itemsComponent &&
        pageOptions.itemsComponent
          .sort((a, b) => a.order - b.order)
          .filter((c) => c.showComponent === true)
          .map((item, i) => selectComponentPage(item, i))}
    </>
  );
};

export default SlugPage;
