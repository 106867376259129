import { useEffect, useState } from 'react'

//  Debounce function for making query is a way to slow down the process of
//  querying and will make the request only after a certain amount of time
//  when the user stops typing.
export function useDebounce (value, delay = 1000) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay)
    return () => { clearTimeout(timer); }
  }, [value, delay]);
  return debouncedValue;
}