import { useIntl } from "react-intl";
import ReadMore from "../buttons/ReadMore/ReadMore";
import iconContants from "../../constants/iconContants";
import iconUtils from "../../utils/iconUtils";
import BoxIconText from "../boxes/BoxIconText";
import "./components/serviceSection.scss"

const ServiceSection = (props) => {
	const { hotel, handleExpandedServices, expandedServices } = props;
	const intl = useIntl();

	const sortedServices = hotel.services.slice().sort((a, b) => {
		return a.amenities.length - b.amenities.length;
	});

	const amenities = hotel.services.map((itm => itm.amenities.map((s => s))));

	return <>
		{hotel.services && hotel.services.length && (
			<div className="rounded-5 bg-white  w-100">
				<div className="border-bottom-solid pb-4">
					<h2 className="mb-4 text-subtitle">{intl.formatMessage({ id: "voucherPage.titleIncluid" })}</h2>
					<div className={`box-hashotel-column ${expandedServices && "box-tittle-column-state"}`}>
						{sortedServices.map((item, i) => {
							return (
								<div className="box-hashotel-item" key={i}>
									{item?.name && (
										<h5 className="py-3">
											<b>{item.name}</b>
										</h5>
									)}

									<BoxIconText
										items={item.amenities.map((itm) => {
											return {
												icon:
													itm.included === true
														? iconContants.FICHECK
														: iconContants.DOLLARSIGN,
												classIcon: "color-primary",
												text: itm.name,
											};
										})}
									/>
								</div>
							);
						})}
					</div>
					{amenities && amenities[0].length > 12 && (<ReadMore
						handleExpanded={handleExpandedServices}
						text={
							expandedServices
								? intl.formatMessage({
									id: "general.seeMore"
								})
								: intl.formatMessage({
									id: "general.seeLess"
								})
						}
						icon={expandedServices ? iconUtils.icon(iconContants.IOIOSARROWUP) : iconUtils.icon(iconContants.IOIOSARROWDOWN)}
					/>)}
				</div>
			</div>
		)}
	</>
}

export default ServiceSection;