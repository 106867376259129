HTMLFormElement.prototype.IsValid = function () {
    if (this) {
        for (var element of this.elements) {
            var isValid = inputIsValid(element);
            if (!isValid) element.addEventListener("blur", (e) => inputIsValid(e.target));
        }
    }
    return this.checkValidity();
};

const inputIsValid = (input) => {
    var isValid = input.checkValidity();
    isValid ? input.classList.remove("is-invalid") : input.classList.add("is-invalid");
    
    var describedby = input.getAttribute("aria-describedby");
    var describedbyElement = describedby ? document.getElementById(describedby) : null;
    if (describedbyElement) describedbyElement.innerHTML = !isValid ? "" : "";
    return isValid;
};
