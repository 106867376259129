import types from "../constants/reducers/pageOptionsConstants";

const get = (pageOptions) => {
    pageOptions.isFetching = false;
    pageOptions.isSuccess = true;
    pageOptions.isError = false;
    return { type: types.PAGE_OPTIONS, pageOptions };
};

const fetching = () => {
    const pageOptions = {
        isFetching: true,
        isSuccess: false,
        isError: false,
    };
    return { type: types.PAGE_OPTIONS_FETCHING, pageOptions };
};
const initial = () => {
    const pageOptions = {
        isFetching: false,
        isSuccess: false,
        isError: false,
    };
    return { type: types.PAGE_OPTIONS_FETCHING, pageOptions };
};
const error = () => {
    const pageOptions = {
        isFetching: false,
        isSuccess: false,
        isError: true,
    };
    return { type: types.PAGE_OPTIONS_ERROR, pageOptions };
};

const updatePageOptions = async (pageOptions, dispatch) => {
    dispatch(get(pageOptions));
}
const setFetching = async (dispatch) => {
    dispatch(fetching());
}

const setInitial = async (dispatch) => {
    dispatch(initial());
}
const setError = async (dispatch) => {
    dispatch(error());
}
const pageOptionsAction = {
    updatePageOptions,
    setFetching,
    setInitial,
    setError
}

export default pageOptionsAction;