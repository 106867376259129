import React from "react";
import { useIntl } from "react-intl";
import htmlparse from 'html-react-parser'

const CheckBoxTerms = (props) => {
  const { handleCheckboxChange, isChecked, text,checkBoxTermsData } = props;
  const intl = useIntl();

  return (
    <>
      <div className="form-check">
        <input  qa={"ch CheckBoxTerms"}
          className="form-check-input"
          id="terms-checkbox"
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
        {htmlparse(checkBoxTermsData.link)}
     
        </label>
      </div>
    </>
  );
};

export default CheckBoxTerms;
