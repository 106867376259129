import iconType from "../../constants/iconContants";
import {images} from "../slideGaleryMockups";

const addons= [
    {
        name: "Baño",
        icon: iconType.SHOWER,
        items:["Artículos de tocador ecológicos","Amenidades de baño gratuitas","Secadora de cabello","Baño privado", "Tina y regadera combinadas"]
    },
    {
        name: "Habitación",
        icon: iconType.AIRLINESEATINDIVIDUALSUITE,
        items:["Ropa de Cama","Cortinas blackout","Ventilador de techo","Aire acondicionado con control", "Cunas gratis", "Colchón con pillow-top", "Ropa de cama premium"]
    },
    {
        name: "Entretenimiento",
        icon: iconType.CHECK,
        items:["Reproductor de DVD","Televisión pantalla plana","Canales vía satélite"]
    },
    {
        name: "Alimentos y bebidas",
        icon: iconType.RESTAURANT,
        items:["Servicio a la habitación las 24 horas","Cafetera y tetera","Agua empotellada gratis","Microoondas"]
    },
    {
        name: "Internet",
        icon: iconType.NETWORKWIFI,
        items:["Wifi gratuito"]
    },
    {
        name: "Más servicios",
        icon: iconType.ADD,
        items:["Servicio a la habitación las 24 horas","Cafetera y tetera","Agua empotellada gratis","Microoondas"]
    },
    {
        name: "Exterior",
        icon: iconType.CHECK,
        items:["Balcón"]
    }
]
const roomBest = {
    id: "QWE123",
    currency: "mxn",
    hasPromotions: false,
    rateWithoutPromotional: 50000,
    rate: 25000,
    mealPlan: "Europeo",
}
const newRooms = [
    {
        id: "QWE123",
        name: "Habitación Deluxe, 2 camas matrimoniales, balcón, vista al jardín asd asdas",
        currency: "mxn",
        hasPromotions: false,
        view: "Vista al mar",
        // rateWithoutPromotional: 6778.28,
        // rate: 3778.28,
        upgradeRate: 0,
        mealPlan: {
            code: "europeo",
            name: "Europeo"
        },
        amenityBasic: {
            bed: "2 camas Queen",
            hasWifiFree: true,
            hasAA: true,
            hasPhone: true,
        },
        amenities: addons,
        photos: images,
        cancellationPolicies:"No puedes cancelar ni realizar cambios  ó puedes cancelar hasta el 00/00/000 a las 00:00 hrs."
    },
    {
        id: "QWE124",
        name: "Habitación Deluxe, 2 camas matrimoniales, balcón, vista al jardín asd asdas",
        currency: "mxn",
        hasPromotions: false,
        view: "Balcón",
        upgradeRate: 1200,
        mealPlan: {
            code: "allinclusive",
            name: "todo incluido"
        },
        amenityBasic: {
            bed: "2 camas individuales",
            hasWifiFree: true,
            hasAA: true,
            hasPhone: false,
        },
        amenities:addons,
        photos: images,
        cancellationPolicies:"No puedes cancelar ni realizar cambios  ó puedes cancelar hasta el 00/00/000 a las 00:00 hrs."
    },
    {
        id: "QWE125",
        name: "Habitación Deluxe, 2 camas matrimoniales, balcón, vista al jardín asd asdas",
        currency: "mxn",
        hasPromotions: false,
        view: "Vista al mar",
        upgradeRate: 1300,
        mealPlan: {
            code: "europeo",
            name: "Europeo"
        },
        amenityBasic: {
            bed: "2 camas Queen",
            hasWifiFree: false,
            hasAA: true,
            hasPhone: true,
        },
        amenities: addons,
        photos: images,
        cancellationPolicies:"No puedes cancelar ni realizar cambios  ó puedes cancelar hasta el 00/00/000 a las 00:00 hrs."
    },
]
const rooms = [
    {
        id: 1,
        active:true,
        title: "Habitación Deluxe, 2 camas matrimoniales, balcón, vista al jardín asd asdas",
        category: 5,
        destination: "Cancún",
        location: "Zona hotelera",
        currency: "mxn",
        price: 25000,
        mealPlan: "Todo incluido",
        vista: "Al Mar",
        gallery: images,
        amenities:[
            {
            label: "Baño",
            icon: iconType.SHOWER,
            list:["Artículos de tocador ecológicos","Amenidades de baño gratuitas","Secadora de cabello","Baño privado", "Tina y regadera combinadas"]
        },
        {
            label: "Habitación",
            icon: iconType.AIRLINESEATINDIVIDUALSUITE,
            list:["Ropa de Cama","Cortinas blackout","Ventilador de techo","Aire acondicionado con control", "Cunas gratis", "Colchón con pillow-top", "Ropa de cama premium"]
        },
        {
            label: "Entretenimiento",
            icon: iconType.CHECK,
            list:["Reproductor de DVD","Televisión pantalla plana","Canales vía satélite"]
        },
        {
            label: "Alimentos y bebidas",
            icon: iconType.RESTAURANT,
            list:["Servicio a la habitación las 24 horas","Cafetera y tetera","Agua empotellada gratis","Microoondas"]
        },
        {
            label: "Internet",
            icon: iconType.NETWORKWIFI,
            list:["Wifi gratuito"]
        },
        {
            label: "Más servicios",
            icon: iconType.ADD,
            list:["Servicio a la habitación las 24 horas","Cafetera y tetera","Agua empotellada gratis","Microoondas"]
        },
        {
            label: "Exterior",
            icon: iconType.CHECK,
            list:["Balcón"]
        }],
        list: [ {
            href: "#",
            icon: iconType.PEOPLEALTICON,
            label: "2 personas"
        },
        {
            href: "#",
            icon: iconType.KINGBED,
            label: "1 cama matrimonial"
        },
        {
            href: "#",
            icon: iconType.NETWORKWIFI,
            label: "Wifi gratuito"
        },
        {
            href: "#",
            icon: iconType.ACUNIT,
            label: "Aire Acondicionado"
        },
        {
            href: "#",
            icon: iconType.LOCALPHONE,
            label: "Teléfono"
        }]
    },
    {
        id: 2,
        active:false,
        title: "Habitación Deluxe, 2 camas matrimoniales, balcón, vista al jardín asd asdas",
        category: 5,
        destination: "Cancún",
        location: "Zona hotelera",
        currency: "mxn",
        price: 25000,
        mealPlan: "Todo incluido",
        vista: "Al Mar",
        gallery: images,
        amenities:[{
            label: "Baño",
            icon: iconType.PEOPLEALTICON,
            list:["Artículos de tocador ecológicos","Amenidades de baño gratuitas","Secadora de cabello","Baño privado", "Tina y regadera combinadas"]
        }],
        list: [ {
            href: "#",
            icon: iconType.PEOPLEALTICON,
            label: "2 personas"
        },
        {
            href: "#",
            icon: iconType.KINGBED,
            label: "1 cama matrimonial"
        },
        {
            href: "#",
            icon: iconType.NETWORKWIFI,
            label: "Wifi gratuito"
        },
        {
            href: "#",
            icon: iconType.ACUNIT,
            label: "Aire Acondicionado"
        },
        {
            href: "#",
            icon: iconType.LOCALPHONE,
            label: "Teléfono"
        }]
    },
    {
        id: 3,
        active:false,
        title: "Habitación Deluxe, 2 camas matrimoniales, balcón, vista al jardín asd asdas",
        category: 5,
        destination: "Cancún",
        location: "Zona hotelera",
        currency: "mxn",
        price: 25000,
        mealPlan: "Todo incluido",
        vista: "Al Mar",
        gallery: images,
        amenities:[{
            label: "Baño",
            icon: iconType.PEOPLEALTICON,
            list:["Artículos de tocador ecológicos","Amenidades de baño gratuitas","Secadora de cabello","Baño privado", "Tina y regadera combinadas"]
        }],
        list: [ {
            href: "#",
            icon: iconType.PEOPLEALTICON,
            label: "2 personas"
        },
        {
            href: "#",
            icon: iconType.KINGBED,
            label: "1 cama matrimonial"
        },
        {
            href: "#",
            icon: iconType.NETWORKWIFI,
            label: "Wifi gratuito"
        },
        {
            href: "#",
            icon: iconType.ACUNIT,
            label: "Aire Acondicionado"
        },
        {
            href: "#",
            icon: iconType.LOCALPHONE,
            label: "Teléfono"
        }]
    },
    {
        id: 4,
        active:false,
        title: "Habitación Deluxe, 2 camas matrimoniales, balcón, vista al jardín asd asdas",
        category: 5,
        destination: "Cancún",
        location: "Zona hotelera",
        currency: "mxn",
        price: 25000,
        mealPlan: "Todo incluido",
        vista: "Al Mar",
        gallery: images,
        amenities:[{
            label: "Baño",
            icon: iconType.PEOPLEALTICON,
            list:["Artículos de tocador ecológicos","Amenidades de baño gratuitas","Secadora de cabello","Baño privado", "Tina y regadera combinadas"]
        }],
        list: [ {
            href: "#",
            icon: iconType.PEOPLEALTICON,
            label: "2 personas"
        },
        {
            href: "#",
            icon: iconType.KINGBED,
            label: "1 cama matrimonial"
        },
        {
            href: "#",
            icon: iconType.NETWORKWIFI,
            label: "Wifi gratuito"
        },
        {
            href: "#",
            icon: iconType.ACUNIT,
            label: "Aire Acondicionado"
        },
        {
            href: "#",
            icon: iconType.LOCALPHONE,
            label: "Teléfono"
        }]
    },
    {
        id: 5,
        active:false,
        title: "Habitación Deluxe, 2 camas matrimoniales, balcón, vista al jardín asd asdas",
        category: 5,
        destination: "Cancún",
        location: "Zona hotelera",
        currency: "mxn",
        price: 25000,
        mealPlan: "Todo incluido",
        vista: "Al Mar",
        gallery: images,
        amenities:[{
            label: "Baño",
            icon: iconType.PEOPLEALTICON,
            list:["Artículos de tocador ecológicos","Amenidades de baño gratuitas","Secadora de cabello","Baño privado", "Tina y regadera combinadas"]
        }],
        list: [ {
            href: "#",
            icon: iconType.PEOPLEALTICON,
            label: "2 personas"
        },
        {
            href: "#",
            icon: iconType.KINGBED,
            label: "1 cama matrimonial"
        },
        {
            href: "#",
            icon: iconType.NETWORKWIFI,
            label: "Wifi gratuito"
        },
        {
            href: "#",
            icon: iconType.ACUNIT,
            label: "Aire Acondicionado"
        },
        {
            href: "#",
            icon: iconType.LOCALPHONE,
            label: "Teléfono"
        }]
    },
    {
        id: 6,
        active:false,
        title: "Habitación Deluxe, 2 camas matrimoniales, balcón, vista al jardín asd asdas",
        category: 5,
        destination: "Cancún",
        location: "Zona hotelera",
        currency: "mxn",
        price: 25000,
        mealPlan: "Todo incluido",
        vista: "Al Mar",
        gallery: images,
        amenities:[{
            label: "Baño",
            icon: iconType.PEOPLEALTICON,
            list:["Artículos de tocador ecológicos","Amenidades de baño gratuitas","Secadora de cabello","Baño privado", "Tina y regadera combinadas"]
        }],
        list: [ {
            href: "#",
            icon: iconType.PEOPLEALTICON,
            label: "2 personas"
        },
        {
            href: "#",
            icon: iconType.KINGBED,
            label: "1 cama matrimonial"
        },
        {
            href: "#",
            icon: iconType.NETWORKWIFI,
            label: "Wifi gratuito"
        },
        {
            href: "#",
            icon: iconType.ACUNIT,
            label: "Aire Acondicionado"
        },
        {
            href: "#",
            icon: iconType.LOCALPHONE,
            label: "Teléfono"
        }]
    }
  ]
  
  const list = [
    {
        icon: iconType.PEOPLEALTICON,
        label: "2 personas"
    },
    {
        icon: iconType.KINGBED,
        label: "1 cama matrimonial"
    },
    {
        icon: iconType.NETWORKWIFI,
        label: "Wifi gratuito"
    },
    {
        icon: iconType.ACUNIT,
        label: "Aire Acondicionado"
    },
    {
        icon: iconType.LOCALPHONE,
        label: "Teléfono"
    }
  ];
  
  const listTitles = [
    {
        label: "Baño",
        icon: iconType.PEOPLEALTICON,
        list:["Artículos de tocador ecológicos","Amenidades de baño gratuitas","Secadora de cabello","Baño privado", "Tina y regadera combinadas"]
    },
    {
        label: "Habitación",
        icon: iconType.PEOPLEALTICON,
        list:["Artículos de tocador ecológicos","Amenidades de baño gratuitas","Secadora de cabello","Baño privado", "Tina y regadera combinadas"]
    }
  ];

const roomsDetailsMocks = {
    rooms,
    newRooms,
    roomBest,
    list,
    listTitles,
    addons
}

export default roomsDetailsMocks;