import { useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import srcImageDefault from "../../assets/img/defaultImage.svg";

const ImageHolder = (props) => {
	const { src, holder = srcImageDefault, width, height } = props;	
	const [imagesInvalid, setImagesInvalid] = useLocalStorage('imagesInvalid', []);
	const [isLoading, setLoading] = useState(true);
	const styleLoading = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0
	};

	const onImageInvalid = (src) => {
		const { onError } = props;
		if (!imagesInvalid.includes(src)) {
			const newImages = [...imagesInvalid, src];
			setImagesInvalid(newImages);
		}
		onError && onError(src);
	};

	return (
		<div className="container-img" style={{position:"relative"}}>
			{isLoading && (
				<div
					width={width}
					height={height}
					className="card-img loading"
					style={styleLoading}>
					<div className="spinner-border loadings" role="status">
						<span className="visually-hidden"></span>
					</div>
				</div>
			)}
			<img
				{...props}
				width={width}
				height={height}
				className="card-img"
				style={{ opacity: isLoading ? 0 : 1 }}
				src={src}
				onLoad={() => setLoading(false)}
				onError={({ currentTarget }) => {
					currentTarget.src = holder;
					currentTarget.onerror = null;
					setLoading(true);
					onImageInvalid(src);
				}}
			/>
		</div>
	);
}

ImageHolder.defaultProps = {
	width: "100%",
	height: "100%"
}

export default ImageHolder;
