// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "general.bestPricePerRoom": "Price per night",
  "general.vuelohotel": "FLIGHT+HOTEL",
  "general.bestPricePerRoomTaxes": "Taxes Incluided",
  "general.breadcrumdHome": "Home",
  "general.breadcrumdHotels": "Hotels",
  "general.breadcrumdTours": "Tours",
  "general.breadcrumdTransfer": "Transfers",
  "general.breadcrumdPaquetes": "Packages",
  "text.price": "Price",
  "text.tax": "Taxes included",
  "general.taxes": "Taxes",
  "text.filter": "FILTERS",
  "text.filterClear": "Clear Filters",
  "text.pernight": "per night",
  "general.from": "since",
  "general.utility": "Utility",
  "general.reservation": "Reserve",
  "general.seeMore": "See more",
  "general.seeLess": "See less",
  "general.moreRooms": "See Rooms",
  "general.moreInformation": "See Information",
  "general.titleRooms": "Rooms",
  "general.refundable": "Refundable",
  "general.noRefundable": "Not refundable",
  "general.checkin": "CheckIn",
  "general.checkout": "CheckOut",
  "general.departure": "departure",
  "general.arrival": "arrival",
  "general.night": "Night",
  "general.adults": "Adults",
  "general.adult": "adult",
  "general.for": "for",
  "general.allinclusive": "All inclusive",
  "general.discount": "Discount",
  "general.discountText": "{count, plural, =0 { Special rate} other {#% Discount}}",
  "general.titleActivity": "Select your activity",
  "general.features": "Features",
  "general.duration": "Duration",
  "general.startTime": "Start time",
  "general.endTime": "End time",
  "general.child": "child",
  "general.language": "Language",
  "general.seeMoreDetails": "More details",
  "general.room": "Room",
  "general.rooms": "Rooms",
  "general.total": "Total",
  "general.reservationName": "Reservation name",
  "general.passanger": "Passenger",
  "general.date": "Date",
  "general.startDate": "Start of activity",
  "general.endDate": "End of activity",
  "text.pertransfer": "per transfer",
  "text.perperson": "per person",
  "filtro.nameTour": "Tour name",
  "filtro.nameTransfer": "Transfer name",
  "filtro.nameHotel": "Hotel name",
  "general.done": "Done",
  "general.placeholderAutocomplete": "Where are you going?",
  "general.noresultAutocomplete": "Search by destination, accommodations, or landmark",
  "general.selectDestination": "Please select a destination",
  "general.result": "{count} result",
  "general.results": "{count} results",
  "general.resultsTotal": "{count, plural, one {# result} other {# results}}",
  "general.resultsFilter": "{count} of {total} results",
  "general.origin": "Origin",
  "general.destiny": "Going to",
  "general.persons": "Travelers",
  "general.orderBy": "Order by",
  "general.details": "Details",
  "general.measures": "Cleaning measures",
  "general.accept": "Accept",
  "general.cotiza": "Do you want to continue with your quote? ",
  "general.cotiza.2": "You still there!",
  "general.roundtrip": "Book roundtrip",
  "general.hours": "hours",
  "general.table.room": "Room",
  "general.table.mealplan": "Meal Plan",
  "general.table.publicRate": "Public rate",
  "general.table.netRate": "Net rate",
  "general.table.paymentLimit": "Payment deadline",
  "general.table.cancelationLimit": "Cancelation deadline",
  "general.table.refundable": "Refundable",
  "general.table.nonRefundable": "Non Reembolsable",
  "general.statusConfirmed": "Confirmed",
  "general.statusCancelled": "Cancelled",
  "general.statusHold": "Hold",
  "general.moreAvailableRooms": "See more available rooms",
  "general.lessAvailableRooms": "See less available rooms",
  "general.noAvailable": "Sorry, no rooms available",
  "general.error": "oops! an error occurred",
  "general.typeDate": "Date type",
  "general.totalPrice": "Total",
  "general.table.pricePerNight": "Per night",
  "general.table.totalRate": "Total rate",
  "general.updated": "Successful",
  "general.infoSaved": "The action has been carried out successfully.",
  "general.changes": "oops! an error occurred, changes are not saved",
  "general.gallery": "Gallery",
  "general.grid": "Grid",
  "general.all": "ALL",
  "general.notFoundText": "The page you are looking for does not exist",
  "general.notFoundTitle": "Page Not Found",
  "general.goHome": "Go home",
  "general.loading": "Loading... ",
  "general.autoI": "Where to?",
  "general.roomI": "When",
  "general.paxI": "Room",
  "genera.autoP": "Search for a place or hotel",
  "general.action": "Action",
	"general.notAvailable": "Not available"
};
