
export default {
    "packages.tua.title": "Pago de la Tarifa de Uso de Aeropuerto (TUA) - Volaris te da transparencia en los cargos",
    "packages.tua.text": "La Tarifa de Uso de Aeropuerto (TUA) es un pago que exigen los aeropuertos para el uso de sus instalaciones - no es para Volaris",
    "packages.tua.text1": "El pago de la Tarifa de Uso de Aeropuerto (TUA) es requerido para continuar con tu compra",
    "packages.tua.alert": "Necesitas aceptar el cargo de la Tarifa de Uso de Aeropuerto (TUA) para poder viajar",
    "packages.tua.btn": "Pagar Tarifa de Uso de Aeropuerto (TUA) de",
    "packages.text.detail.flight":"Detalle del vuelo",
    "packages.text.departure":"Vuelo de salida",
    "packages.text.return":"Vuelo de salida",
    "package.text.total": "Total",
    "package.text.test.date": "Martes, 7 febrero, 2023"
}