const serviceTypeConstant = {
    HOTEL: "Hotel",
    HOTELLEAD: "HotelLead",
    PACKAGE: "Package",
    TOUR: "Tour",
    TOURB2CSLIM: "TourB2cslim",
    TOURDETAIL: "TourDetail",
    TRANSFER: "Transfer",
    DESTINATION: "Destination",
    HOMEHOTEL: "HOMEHOTEL",
}

export default serviceTypeConstant;