import { FormattedMessage } from "react-intl";

const optionsPaxes = {
  title: <FormattedMessage id="checkout.cardPaxes.title" />,
  description: <FormattedMessage id="checkout.cardPaxes.description" />,
  informationTitle: (
    <FormattedMessage id="checkout.cardPaxes.information.title" />
  ),
  informationDescription: (
    <FormattedMessage id="checkout.cardPaxes.information.description" />
  ),
};

const voucherStaticInformation = {
  SupplierNote: <FormattedMessage id="voucherPage.SupplierNote" />,
};

const title = <FormattedMessage id="voucherPage.titleHotelSucces" />

const titleVoucher = <FormattedMessage id="voucherPage.title" />


const voucher = {
  optionsPaxes,
  voucherStaticInformation,
  titleVoucher,
  title
};
export default voucher;
