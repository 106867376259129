import { Routes, Route, Navigate } from "react-router-dom";
import LayoutComponent from "./pages/shared/LayoutComponent/LayoutComponent";
import { useDispatch, useGlobalState } from "./store/StoreProvider";
import { useLocation } from "react-router-dom";
import "./theme/main-boostrap.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./theme/main.scss";
import "./theme/site/main.scss";
import PackageDetailPage from "./pages/package/PackageDetailPage/PackageDetailPage";
import Flight from "./pages/checkout/Flight";
import TourDetailPage from "./pages/tour/TourDetailPage/TourDetailPage.js";
import PackageVoucherPage from "./pages/voucher/PackageVoucherPage/PackageVoucherPage";
import TourVoucherPage from "./pages/voucher/TourVoucherPage/TourVoucherPage";
import TransferVoucherPage from "./pages/voucher/TransferVoucherPage/TransferVoucherPage";
import PackageListPage from "./pages/package/PackageListPage/PackageListPage";
import SlugPage from "./pages/SlugPage";
import LoginPage from "./pages/Login/LoginPage";
import storage from "./localstorage/storage";
import { localStorageConst, currencyConst } from "./constants/common";
import TourListPage from "./pages/tour/TourListPage/TourListPage";
import TransferListPage from "./pages/transfer/TransferListPage/TransferListPage";
import webSitesiteRoutesConstant from "./constants/webSitesiteRoutesConstant";
import AuthSession from "./components/AuthSession/AuthSession";
import { useEffect, useState } from "react";
import currencyAction from "./actions/currencyAction";
import localeAction from "./actions/localeAction";
import languageUtils from "./utils/languageUtils";
import resourceSiteAction from "./actions/resourceSiteAction";
import siteResource from "./siteResources";
import siteDataAction from "./actions/siteInformationAction";
import cmsAction from "./actions/cmsActions";
import commonUtils from "./utils/commonUtils";
import googleTagManagerUtils from "./utils/GoogleTagManagerUtils";
import TagManager from "react-gtm-module";
import SkeletonPage from "./components/skeleton/SkeletonPage";
import NotFound from "./pages/notFound";
import useInactivityDetector from "./hooks/useInactivityDetector";
import pageOptionsAction from "./actions/pageOptionsAction";

function App() {
  const dispatch = useDispatch();
  const { locale, resourceSite, siteInformation, pageOptions,cmsComponents } =
    useGlobalState();
  const location = useLocation();
  const [tagManagerKey, setTagManagerKey] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const defaultCurrency =   process.env.REACT_APP_CURRENCY;
  //const Navigate = useNavigate();
  const updateContextCurrency = (value) => {
    const currency = {
      name: "",
      code: value?.toUpperCase(),
    };
    currencyAction.currency(currency, dispatch);
  };

  const handleUserInactive = () => {
    // El usuario está inactivo, actualiza la página
    setRefreshCount((prevCount) => prevCount + 1);
  };

  useInactivityDetector(handleUserInactive);


  useEffect(() => {
    pageOptionsAction.setFetching(dispatch);
  }, [locale]);

  
  useEffect(() => {
    const locale = languageUtils.getLocaleFromPath(
      window.location?.pathname?.toLowerCase()
    );
    const hostname = commonUtils.getEnvironmentHostname();
    const environmentKeys = commonUtils.getEnvironmentKeys(locale);

    let resourceSitedata = storage.getLocalStorage(
      environmentKeys.siteResourceKey
    );
    let siteInformationData = storage.getLocalStorage(
      environmentKeys.siteInfoKey
    );

    const siteRequest = {
      domain: hostname,
    };

    localeAction.updateLocale(locale, dispatch);
    let currency = storage.getLocalStorage(localStorageConst.CURRENCY);
    if (currency === null) {
      currency = defaultCurrency;
      storage.setLocalStorage(localStorageConst.CURRENCY, currency, 30);
    }
    updateContextCurrency(currency);

    const auxResourceSite = siteResource(locale);

    resourceSiteAction.update(auxResourceSite, dispatch);

    if (!siteInformationData) {
      siteDataAction.siteData(
        siteRequest,
        auxResourceSite,
        onSuccessSiteInformation,
        dispatch,

        (_error) => {
          //TODO si falla la api actualiza el contexto con la informacion de los recursos locales
          resourceSiteAction.update(auxResourceSite, dispatch);
        }
      );
    } else {
      if (
        !storage.getLocalStorage(localStorageConst.GTM) &&
        siteInformationData?.siteGoogleTagManager.length > 0
      ) {
        storage.setLocalStorage(
          localStorageConst.GTM,
          siteInformationData.siteGoogleTagManager,
          process.env.REACT_APP_SITERESOURCE_SESSION
        );
      }

      if (!resourceSitedata) {
        siteDataAction.siteResourceFormat(
          siteInformationData,
          auxResourceSite,
          dispatch
        );
      } else {
        storage.setCookie(
          localStorageConst.SITE,
          siteInformationData.site.id,
          process.env.REACT_APP_SITE_COOKIE
        );
        siteDataAction.setSiteData(siteInformationData, dispatch);
        resourceSiteAction.update(resourceSitedata, dispatch);
      }
    }
  }, []);

  useEffect(() => {
    const locale = languageUtils.getLocaleFromPath(
      window.location?.pathname?.toLowerCase()
    );
    const environmentKeys = commonUtils.getEnvironmentKeys(locale);
    let siteInformationData = storage.getLocalStorage(
      environmentKeys.siteInfoKey
    );

    if (!tagManagerKey) {
      if (siteInformationData?.siteGoogleTagManager.length > 0) {
        const googleTags = siteInformationData?.siteGoogleTagManager;

        googleTags.forEach((gtm) => {
          TagManager.initialize({ gtmId: gtm.tag });
        });
        setTagManagerKey(true);
      }
    }
  }, [siteInformation?.siteGoogleTagManager]);

  useEffect(() => {
    try {
      if (pageOptions.isSuccess) {
        googleTagManagerUtils.pageViewEvent(location, pageOptions?.title);
      }
    } catch (error) {
      console.log(error);
    }

  }, [location, pageOptions]);

  useEffect(() => {
    if (
      siteInformation.isSuccess &&
      cmsComponents?.isSuccess === false &&
      cmsComponents?.isFetching === false
    ) {
      getCmsInit();
    }
  }, [siteInformation]);

  const onSuccessSiteInformation = () => {
    getCmsInit();
  };

  const getCmsInit = () => {
    const hostname = commonUtils.getEnvironmentHostname();
    let cmsKey = hostname + localStorageConst.COMPONENTSOPTIONS;
      
    let componentsOptions = storage.getLocalStorage(
      cmsKey
    );

    if (!componentsOptions) {


      let siteRequest = {
        domain: hostname,
      };

      cmsAction.getResourceSiteComponents(
        siteRequest,
        (response) => {
          const expirationMinutes = process.env.REACT_APP_SITE_CMS_COMPONENTS_DATA ?? 360;
          storage.setLocalStorage(
            cmsKey,
            response,
            expirationMinutes 
          );
        },
        dispatch,
        (error) => {
          const expirationMinutes = 10;
          storage.setLocalStorage(
            cmsKey,
            {},
            expirationMinutes 
          );
        }
      );
    }
  };


  const selectPage = (item, position) => {
    //TODO identificamos a X pagina y decimos como pintarla
    //TODO dinamic-page ejemplo de header y footer desde el componentePage y no desde el layout

    switch (item.keypage) {
      case "slug-page":
        return item.hasAuth ? (
          <AuthSession
            propsPage={{
              positionPage: position, //cambio de idioma por position del array
              metasPage: item, //TODO paso a la api y no a los links
              component: SlugPage,
            }}
            component={LayoutComponent}
          />
        ) : (
          <LayoutComponent
            positionPage={position}
            metasPage={item} //TODO paso a la api y no a los links
            component={SlugPage} //todo DEBE MOSTRAR SOLO LENGUAJE Y LOGO
          />
        );
      case "login-page":
        return (
          <LayoutComponent
            positionPage={position}
            metasPage={item} //TODO paso a la api y no a los links
            component={LoginPage} //todo DEBE MOSTRAR SOLO LENGUAJE Y LOGO
          />
        );
      default:
        return <SkeletonPage />;
    }
  };

  const generedRouteHome = () => {
    const response = resourceSite.options.siteRouter.find(
      (item, i) => item.homePage
    );
    const responseIndex = resourceSite.options.siteRouter.findIndex(
      (item) => item.homePage
    );
    return (
      <Route
        path={response.basename}
        element={selectPage(response, responseIndex)}
      />
    );
  };

  return (
    <>
      {resourceSite && resourceSite.isSuccess && (
        <Routes>
          {generedRouteHome()}
          {resourceSite.options.siteRouter.map((item, i) => {
            return (
              <Route
                key={i}
                path={`/${locale}${item.basename === "/" ? "" : item.basename}`}
                element={
                  item.isDisabled ? (
                    <Navigate
                      to={`/${locale}${resourceSite.options.redirectPageDefault}`}
                    />
                  ) : (
                    selectPage(item, i)
                  )
                }
              />
            );
          })}
          <Route
            path={`/es-mx/vuelo/:id`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  positionMenu: 1,
                  routeType: webSitesiteRoutesConstant.FLIGHT,
                  component: Flight,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            path="*"
            element={
              <LayoutComponent
                positionPage={1}
                metasPage={[]} //TODO paso a la api y no a los links
                component={NotFound} //todo DEBE MOSTRAR SOLO LENGUAJE Y LOGO
              />
            }
          />
          <Route
            exact
            path={`/en-us/flight/:id`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  positionMenu: 1,
                  routeType: webSitesiteRoutesConstant.FLIGHT,
                  component: Flight,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            path={`/es-mx/paquete/:keyname/:id`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  positionMenu: 1,
                  routeType: webSitesiteRoutesConstant.PACKAGE,
                  component: PackageDetailPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            exact
            path={`/en-us/package/:keyname/:id`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  positionMenu: 1,
                  routeType: webSitesiteRoutesConstant.PACKAGE,
                  component: PackageDetailPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            exact
            path={`/es-mx/paquetes`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  positionMenu: 1,
                  routeType: webSitesiteRoutesConstant.LISTPACKAGE,
                  component: PackageListPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            exact
            path={`/en-us/packages`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  positionMenu: 1,
                  routeType: webSitesiteRoutesConstant.LISTPACKAGE,
                  component: PackageListPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            path={`/es-mx/tour/:id`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  positionMenu: 2,
                  routeType: webSitesiteRoutesConstant.TOUR,
                  component: TourDetailPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            exact
            path={`/en-us/activity/:id`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  positionMenu: 2,
                  routeType: webSitesiteRoutesConstant.TOUR,
                  component: TourDetailPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            path={`/es-mx/voucher/paquete`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  routeType: webSitesiteRoutesConstant.VOUCHERPACKAGE,
                  component: PackageVoucherPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            path={`/en-us/voucher/package`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  routeType: webSitesiteRoutesConstant.VOUCHERPACKAGE,
                  component: PackageVoucherPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            path={`/es-mx/voucher/tour`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  routeType: webSitesiteRoutesConstant.VOUCHERTOUR,
                  component: TourVoucherPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            path={`/en-us/voucher/activity`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  routeType: webSitesiteRoutesConstant.VOUCHERTOUR,
                  component: TourVoucherPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            path={`/es-mx/voucher/traslado`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  routeType: webSitesiteRoutesConstant.VOUCHERTRANSFER,
                  component: TransferVoucherPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            path={`/en-us/voucher/shutter`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  routeType: webSitesiteRoutesConstant.VOUCHERTRANSFER,
                  component: TransferVoucherPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          
          <Route
            exact
            path={`/en-us/activities`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  positionMenu: 2,
                  routeType: webSitesiteRoutesConstant.LISTTOUR,
                  component: TourListPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            exact
            path={`/es-mx/tours`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  positionMenu: 2,
                  routeType: webSitesiteRoutesConstant.LISTTOUR,
                  component: TourListPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            exact
            path={`/en-us/shutters`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  positionMenu: 3,
                  routeType: webSitesiteRoutesConstant.LISTTRANSFER,
                  component: TransferListPage,
                }}
                component={LayoutComponent}
              />
            }
          />
          <Route
            exact
            path={`/es-mx/traslados`}
            element={
              <AuthSession
                propsPage={{
                  locale: locale,
                  positionMenu: 3,
                  routeType: webSitesiteRoutesConstant.LISTTRANSFER,
                  component: TransferListPage,
                }}
                component={LayoutComponent}
              />
            }
          />
        </Routes>
      )}
    </>
  );
}

export default App;
