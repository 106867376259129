export default {
  "form.name": "Nombre",
  "form.lastname": "Apellidos",
  "form.phone": "Teléfono",
  "form.mail": "Correo electrónico",
  "form.airline": "Aerolinea",
  "form.numFlight": "Numero de vuelo",
  "form.validation.empty": "el campo no puede estar vacio",
  "form.validation.text": "solo texto",
  "form.validation.numText": "solo texto y números",
  "form.terms":
    "He leído y acepto el Aviso de Privacidad, Términos y Condiciones.",
  "form.termsMsg": "Debes aceptar los terminos y condiciones.",
  "form.Msg": "Verifica que la información ingresada sea valida.",
  "form.validate": "El correo ha sido enviado con éxito.",
  "form.capcha": "Validar captcha",
};
