const listTransfersMock = {
    result: [
        {
            id: "gre-a89e-453a-a460-0d0cdcf6fff5eae",
            name: "Traslado Vip 0",
            type: "Traslado",
            description: [
                {"text": "No se permite la cancelación parcial de los traslados de ida y vuelta."},
                {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                {"text": "Este es un servicio de traslado compartido y tiene un máximo de 3 paradas  "},
                {"text": "Los asientos de seguridad para bebés son responsabilidad del viajero; los niños deben estar sentados en un sistema de seguridad de tamaño apropiado, de acuerdo con la ley local."},
            ],
            measures:[
            { 
                title: "Medidas de limpieza reforzadas" ,
                items: [
                    {"text": "Limpieza de vehículos y espacios con desinfectante"},
                    {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                    {"text": "Limpieza y desinfección de superficies de contacto frecuente" },
                ]
            },
            {
                title: "Medidas de seguridad" ,
                items: [
                    {"text": "Gel antibacterial"},
                    {"text": "Cubrebocas obligatorio"},
                    {"text": "Toma de temperatura para el personal  "},
                ]
            }
            ],
            maxNumberPassengers:8,
            maxNumberBags:6,
            shuttleType:"Viaje sencillo",
            categoryType:"Compartido",
            address: "Cancun - Aeropuerto",
            cancelationfree: false,
            image: {      
                code: "https://www.blogicars.com/public_html/blogicars/wp-content/uploads/2010/03/camionetas-van-e1663366340962.jpg",          
                title: "Traslado privado"
              },
            rate: {               
                currency: "MXN",               
                hasPromotions: true,
                hasCancellationPolicies: true,
                hasOffers: true,
                total: 2600,
                promotions: [
                    "Cancelación Gratuita"
                ],
                cancellationPolicies: [
                    "Puedes cancelar hasta el 10/12/2022"
                ],
                taxes: [
                    {
                        included: false,
                        total: 90.0022,
                        currency: "MXN"
                    }
                ],
                offers: [
                    {
                        name: "Descuento exclusivo",
                        total: 9
                    }
                ],
                totalDiscounted: 400,
                totalWithOutOffers: 3000           
            }
    
        },
      
        {
            id: "434ce-a89e-453a-a460-0d0dfdcdaacfcf65ea3ase",
            name: "Minivan Estándar",
            type: "Traslado",
            description: [
                {"text": "No se permite la cancelación parcial de los traslados de ida y vuelta."},
                {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                {"text": "Este es un servicio de traslado compartido y tiene un máximo de 3 paradas  "},
                {"text": "Los asientos de seguridad para bebés son responsabilidad del viajero; los niños deben estar sentados en un sistema de seguridad de tamaño apropiado, de acuerdo con la ley local."},
            ],
            measures:[
            { 
                title: "Medidas de limpieza reforzadas" ,
                items: [
                    {"text": "Limpieza de vehículos y espacios con desinfectante"},
                    {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                    {"text": "Limpieza y desinfección de superficies de contacto frecuente" },
                ]
            },
            {
                title: "Medidas de seguridad" ,
                items: [
                    {"text": "Gel antibacterial"},
                    {"text": "Cubrebocas obligatorio"},
                    {"text": "Toma de temperatura para el personal  "},
                ]
            }
            ],
            maxNumberPassengers:8,
            cancelationfree: false,
            maxNumberBags:6,
            shuttleType:"Viaje sencillo",
            categoryType:"Compartido",
            image: {
                
                code: "https://media.staticontent.com/media/pictures/abeba89d-efa7-46b8-bbaf-71ab282c4d4b/300x200",          
                title: "Traslado privado"
              },
            rate: {               
                currency: "MXN",               
                hasPromotions: true,
                hasCancellationPolicies: true,
                hasOffers: true,
                total: 1000,
                promotions: [
                    "Cancelación Gratuita"
                ],
                cancellationPolicies: [
                    "Puedes cancelar hasta el 10/12/2022"
                ],
                taxes: [
                    {
                        included: false,
                        total: 90.0022,
                        currency: "MXN"
                    }
                ],
                offers: [
                    {
                        name: "Descuento exclusivo",
                        total: 11
                    }
                ],
                totalDiscounted: 12,
                totalWithOutOffers: 1500           
            }
    
        },
        {
            id: "226fa434ce-a89e-453a-a460-0d0cdcffffsvse65aaa",
            name: "Minibus Premium",
            type: "Traslado",
                        description: [
                {"text": "No se permite la cancelación parcial de los traslados de ida y vuelta."},
                {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                {"text": "Este es un servicio de traslado compartido y tiene un máximo de 3 paradas  "},
                {"text": "Los asientos de seguridad para bebés son responsabilidad del viajero; los niños deben estar sentados en un sistema de seguridad de tamaño apropiado, de acuerdo con la ley local."},
            ],
            measures:[
            { 
                title: "Medidas de limpieza reforzadas" ,
                items: [
                    {"text": "Limpieza de vehículos y espacios con desinfectante"},
                    {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                    {"text": "Limpieza y desinfección de superficies de contacto frecuente" },
                ]
            },
            {
                title: "Medidas de seguridad" ,
                items: [
                    {"text": "Gel antibacterial"},
                    {"text": "Cubrebocas obligatorio"},
                    {"text": "Toma de temperatura para el personal  "},
                ]
            }
            ],xNumberPassengers:8,
             cancelationfree: true,
            maxNumberBags:6,
            shuttleType:"Viaje sencillo",
            categoryType:"Privado",
            image: {
                code: "https://media.staticontent.com/media/pictures/0df605db-367e-45c6-ba62-842f987c342e/300x200",          
                title: "Traslado privado"
              },
            rate: {               
                currency: "MXN",               
                hasPromotions: true,
                hasCancellationPolicies: true,
                hasOffers: true,
                total: 1200,
                promotions: [
                    "Cancelación Gratuita"
                ],
                cancellationPolicies: [
                    "Puedes cancelar hasta el 10/12/2022"
                ],
                taxes: [
                    {
                        included: false,
                        total: 90.0022,
                        currency: "MXN"
                    }
                ],
                offers: [
                    {
                        name: "Descuento exclusivo",
                        total: 5
                    }
                ],
                totalDiscounted: 12,
                totalWithOutOffers: 1500           
            }
    
        },
        {
            id: "136fa434ce-a89e-453a-a460-",
            name: "Minivan privado",
            type: "Traslado",
                        description: [
                {"text": "No se permite la cancelación parcial de los traslados de ida y vuelta."},
                {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                {"text": "Este es un servicio de traslado compartido y tiene un máximo de 3 paradas  "},
                {"text": "Los asientos de seguridad para bebés son responsabilidad del viajero; los niños deben estar sentados en un sistema de seguridad de tamaño apropiado, de acuerdo con la ley local."},
            ],
            measures:[
            { 
                title: "Medidas de limpieza reforzadas" ,
                items: [
                    {"text": "Limpieza de vehículos y espacios con desinfectante"},
                    {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                    {"text": "Limpieza y desinfección de superficies de contacto frecuente" },
                ]
            },
            {
                title: "Medidas de seguridad" ,
                items: [
                    {"text": "Gel antibacterial"},
                    {"text": "Cubrebocas obligatorio"},
                    {"text": "Toma de temperatura para el personal  "},
                ]
            }
            ],xNumberPassengers:4,
             cancelationfree: false,
            maxNumberBags:4,
            shuttleType:"Traslado redondo",
            categoryType:"Privado",
            image: {
                
                code: "https://mediaim.expedia.com/localexpert/780674/e88e74c6-5b15-4da1-b73f-08212da0ab7d.jpg?impolicy=resizecrop&rw=350&rh=197",          
                title: "Traslado privado"
              },
            rate: {               
                currency: "MXN",               
                hasPromotions: true,
                hasCancellationPolicies: true,
                hasOffers: true,
                total: 1030,
                promotions: [
                    "Cancelación Gratuita"
                ],
                cancellationPolicies: [
                    "Puedes cancelar hasta el 10/12/2022"
                ],
                taxes: [
                    {
                        included: false,
                        total: 90.0022,
                        currency: "MXN"
                    }
                ],
                offers: [
                    {
                        name: "Descuento exclusivo",
                        total: 3
                    }
                ],
                totalDiscounted: 12,
                totalWithOutOffers: 1500           
            }
    
        },
        {
            id: "51fa434ce-a89e-453a-a460-0d0cdcf65eae",
            name: "Traslado Vip",
            type: "Traslado",
                        description: [
                {"text": "No se permite la cancelación parcial de los traslados de ida y vuelta."},
                {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                {"text": "Este es un servicio de traslado compartido y tiene un máximo de 3 paradas  "},
                {"text": "Los asientos de seguridad para bebés son responsabilidad del viajero; los niños deben estar sentados en un sistema de seguridad de tamaño apropiado, de acuerdo con la ley local."},
            ],
            measures:[
            { 
                title: "Medidas de limpieza reforzadas" ,
                items: [
                    {"text": "Limpieza de vehículos y espacios con desinfectante"},
                    {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                    {"text": "Limpieza y desinfección de superficies de contacto frecuente" },
                ]
            },
            {
                title: "Medidas de seguridad" ,
                items: [
                    {"text": "Gel antibacterial"},
                    {"text": "Cubrebocas obligatorio"},
                    {"text": "Toma de temperatura para el personal  "},
                ]
            }
            ],xNumberPassengers:8,
             cancelationfree: true,
            maxNumberBags:6,
            shuttleType:"Viaje sencillo",
            categoryType:"Privado",
            image: {
                code: "https://rentacamionetasdf.com/wp-content/uploads/2019/06/Sprinter_transparente-1-e1468386277126.png",          
                title: "Traslado privado"
              },
            rate: {               
                currency: "MXN",               
                hasPromotions: true,
                hasCancellationPolicies: true,
                hasOffers: true,
                total: 700,
                promotions: [
                    "Cancelación Gratuita"
                ],
                cancellationPolicies: [
                    "Puedes cancelar hasta el 10/12/2022"
                ],
                taxes: [
                    {
                        included: false,
                        total: 90.0022,
                        currency: "MXN"
                    }
                ],
                offers: [
                    {
                        name: "Descuento exclusivo",
                        total: 20
                    }
                ],
                totalDiscounted: 12,
                totalWithOutOffers: 1500           
            }
    
        },
        {
            id: "3331aa434ce-a89e-453a-a460-0d0cdcf65eae",
            name: "Traslado Privado",
            type: "Traslado",
                        description: [
                {"text": "No se permite la cancelación parcial de los traslados de ida y vuelta."},
                {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                {"text": "Este es un servicio de traslado compartido y tiene un máximo de 3 paradas  "},
                {"text": "Los asientos de seguridad para bebés son responsabilidad del viajero; los niños deben estar sentados en un sistema de seguridad de tamaño apropiado, de acuerdo con la ley local."},
            ],
            measures:[
            { 
                title: "Medidas de limpieza reforzadas" ,
                items: [
                    {"text": "Limpieza de vehículos y espacios con desinfectante"},
                    {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                    {"text": "Limpieza y desinfección de superficies de contacto frecuente" },
                ]
            },
            {
                title: "Medidas de seguridad" ,
                items: [
                    {"text": "Gel antibacterial"},
                    {"text": "Cubrebocas obligatorio"},
                    {"text": "Toma de temperatura para el personal  "},
                ]
            }
            ],xNumberPassengers:5,
             cancelationfree: false,
            maxNumberBags:5,
            shuttleType:"Viaje redondo",
            categoryType:"Privado",
            image: {
                
                code: "https://mediaim.expedia.com/localexpert/780674/e88e74c6-5b15-4da1-b73f-08212da0ab7d.jpg?impolicy=resizecrop&rw=350&rh=197",          
                title: "Traslado privado"
              },
            rate: {               
                currency: "MXN",               
                hasPromotions: true,
                hasCancellationPolicies: true,
                hasOffers: true,
                total: 1400,
                promotions: [
                    "Cancelación Gratuita"
                ],
                cancellationPolicies: [
                    "Puedes cancelar hasta el 10/12/2022"
                ],
                taxes: [
                    {
                        included: false,
                        total: 90.0022,
                        currency: "MXN"
                    }
                ],
                offers: [
                    {
                        name: "Descuento exclusivo",
                        total: 14
                    }
                ],
                totalDiscounted: 12,
                totalWithOutOffers: 1500           
            }
    
        },
        {
            id: "9999sa434ce-a89e-453a-a460-0d0cdcf65eae",
            name: "Traslado Premium",
            type: "Traslado",
                        description: [
                {"text": "No se permite la cancelación parcial de los traslados de ida y vuelta."},
                {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                {"text": "Este es un servicio de traslado compartido y tiene un máximo de 3 paradas  "},
                {"text": "Los asientos de seguridad para bebés son responsabilidad del viajero; los niños deben estar sentados en un sistema de seguridad de tamaño apropiado, de acuerdo con la ley local."},
            ],
            measures:[
            { 
                title: "Medidas de limpieza reforzadas" ,
                items: [
                    {"text": "Limpieza de vehículos y espacios con desinfectante"},
                    {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                    {"text": "Limpieza y desinfección de superficies de contacto frecuente" },
                ]
            },
            {
                title: "Medidas de seguridad" ,
                items: [
                    {"text": "Gel antibacterial"},
                    {"text": "Cubrebocas obligatorio"},
                    {"text": "Toma de temperatura para el personal  "},
                ]
            }
            ],xNumberPassengers:8,
             cancelationfree: true,
            maxNumberBags:6,
            shuttleType:"Traslado redondo",
            categoryType:"Compartido",
            image: {
                code: "https://rentacamionetasdf.com/wp-content/uploads/2019/06/Sprinter_transparente-1-e1468386277126.png",          
                title: "Traslado privado"
              },
            rate: {               
                currency: "MXN",               
                hasPromotions: true,
                hasCancellationPolicies: true,
                hasOffers: true,
                total: 1000,
                promotions: [
                    "Cancelación Gratuita"
                ],
                cancellationPolicies: [
                    "Puedes cancelar hasta el 10/12/2022"
                ],
                taxes: [
                    {
                        included: false,
                        total: 90.0022,
                        currency: "MXN"
                    }
                ],
                offers: [
                    {
                        name: "Descuento exclusivo",
                        total: 3
                    }
                ],
                totalDiscounted: 12,
                totalWithOutOffers: 1500           
            }
    
        },
        {
            id: "10k434ce-a89e-453a-a460-0d0cdcf65eae",
            name: "Traslado Vip 2",
            type: "Traslado",
                        description: [
                {"text": "No se permite la cancelación parcial de los traslados de ida y vuelta."},
                {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                {"text": "Este es un servicio de traslado compartido y tiene un máximo de 3 paradas  "},
                {"text": "Los asientos de seguridad para bebés son responsabilidad del viajero; los niños deben estar sentados en un sistema de seguridad de tamaño apropiado, de acuerdo con la ley local."},
            ],
            measures:[
            { 
                title: "Medidas de limpieza reforzadas" ,
                items: [
                    {"text": "Limpieza de vehículos y espacios con desinfectante"},
                    {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                    {"text": "Limpieza y desinfección de superficies de contacto frecuente" },
                ]
            },
            {
                title: "Medidas de seguridad" ,
                items: [
                    {"text": "Gel antibacterial"},
                    {"text": "Cubrebocas obligatorio"},
                    {"text": "Toma de temperatura para el personal  "},
                ]
            }
            ],xNumberPassengers:8,
             cancelationfree: false,
            maxNumberBags:6,
            shuttleType:"Viaje sencillo",
            categoryType:"Compartido",
            image: {
                
                code: "https://mediaim.expedia.com/localexpert/780674/e88e74c6-5b15-4da1-b73f-08212da0ab7d.jpg?impolicy=resizecrop&rw=350&rh=197",          
                title: "Traslado privado"
              },
            rate: {               
                currency: "MXN",               
                hasPromotions: true,
                hasCancellationPolicies: true,
                hasOffers: true,
                total: 1000,
                promotions: [
                    "Cancelación Gratuita"
                ],
                cancellationPolicies: [
                    "Puedes cancelar hasta el 10/12/2022"
                ],
                taxes: [
                    {
                        included: false,
                        total: 90.0022,
                        currency: "MXN"
                    }
                ],
                offers: [
                    {
                        name: "Descuento exclusivo",
                        total: 4
                    }
                ],
                totalDiscounted: 12,
                totalWithOutOffers: 1500           
            }
    
        },
        {
            id: "2ss434ce-a89e-453a-a460-0d0cdcf65eae",
            name: "Traslado Vip 3",
            type: "Traslado",
                        description: [
                {"text": "No se permite la cancelación parcial de los traslados de ida y vuelta."},
                {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                {"text": "Este es un servicio de traslado compartido y tiene un máximo de 3 paradas  "},
                {"text": "Los asientos de seguridad para bebés son responsabilidad del viajero; los niños deben estar sentados en un sistema de seguridad de tamaño apropiado, de acuerdo con la ley local."},
            ],
            measures:[
            { 
                title: "Medidas de limpieza reforzadas" ,
                items: [
                    {"text": "Limpieza de vehículos y espacios con desinfectante"},
                    {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                    {"text": "Limpieza y desinfección de superficies de contacto frecuente" },
                ]
            },
            {
                title: "Medidas de seguridad" ,
                items: [
                    {"text": "Gel antibacterial"},
                    {"text": "Cubrebocas obligatorio"},
                    {"text": "Toma de temperatura para el personal  "},
                ]
            }
            ],xNumberPassengers:8,
             cancelationfree: true,
            maxNumberBags:6,
            shuttleType:"Viaje sencillo",
            categoryType:"Compartido",
            image: {
                code: "https://rentacamionetasdf.com/wp-content/uploads/2019/06/Sprinter_transparente-1-e1468386277126.png",          
                title: "Traslado privado"
              },
            rate: {               
                currency: "MXN",               
                hasPromotions: true,
                hasCancellationPolicies: true,
                hasOffers: true,
                total: 1000,
                promotions: [
                    "Cancelación Gratuita"
                ],
                cancellationPolicies: [
                    "Puedes cancelar hasta el 10/12/2022"
                ],
                taxes: [
                    {
                        included: false,
                        total: 90.0022,
                        currency: "MXN"
                    }
                ],
                offers: [
                    {
                        name: "Descuento exclusivo",
                        total: 500
                    }
                ],
                totalDiscounted: 12,
                totalWithOutOffers: 1500           
            }
    
        },
        {
            id: "6aa234ce-a89e-TYTa460-0d0cdcf65eae",
            name: "Traslado Vip 4",
            type: "Traslado",
                        description: [
                {"text": "No se permite la cancelación parcial de los traslados de ida y vuelta."},
                {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                {"text": "Este es un servicio de traslado compartido y tiene un máximo de 3 paradas  "},
                {"text": "Los asientos de seguridad para bebés son responsabilidad del viajero; los niños deben estar sentados en un sistema de seguridad de tamaño apropiado, de acuerdo con la ley local."},
            ],
            measures:[
            { 
                title: "Medidas de limpieza reforzadas" ,
                items: [
                    {"text": "Limpieza de vehículos y espacios con desinfectante"},
                    {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                    {"text": "Limpieza y desinfección de superficies de contacto frecuente" },
                ]
            },
            {
                title: "Medidas de seguridad" ,
                items: [
                    {"text": "Gel antibacterial"},
                    {"text": "Cubrebocas obligatorio"},
                    {"text": "Toma de temperatura para el personal  "},
                ]
            }
            ],xNumberPassengers:8,
             cancelationfree: true,
            maxNumberBags:6,
            shuttleType:"Viaje sencillo",
            categoryType:"Compartido",
            image: {
                
                code: "https://mediaim.expedia.com/localexpert/780674/e88e74c6-5b15-4da1-b73f-08212da0ab7d.jpg?impolicy=resizecrop&rw=350&rh=197",          
                title: "Traslado privado"
              },
            rate: {               
                currency: "MXN",               
                hasPromotions: true,
                hasCancellationPolicies: true,
                hasOffers: true,
                total: 1700,
                promotions: [
                    "Cancelación Gratuita"
                ],
                cancellationPolicies: [
                    "Puedes cancelar hasta el 10/12/2022"
                ],
                taxes: [
                    {
                        included: false,
                        total: 90.0022,
                        currency: "MXN"
                    }
                ],
                offers: [
                    {
                        name: "Descuento exclusivo",
                        total: 5
                    }
                ],
                totalDiscounted: 12,
                totalWithOutOffers: 1500           
            }
    
        },
        {
            id: "6fa434ls-a89e-AAA-a460-0d0cdcf65eae",
            name: "Traslado Vip 5",
            type: "Traslado",
                        description: [
                {"text": "No se permite la cancelación parcial de los traslados de ida y vuelta."},
                {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                {"text": "Este es un servicio de traslado compartido y tiene un máximo de 3 paradas  "},
                {"text": "Los asientos de seguridad para bebés son responsabilidad del viajero; los niños deben estar sentados en un sistema de seguridad de tamaño apropiado, de acuerdo con la ley local."},
            ],
            measures:[
            { 
                title: "Medidas de limpieza reforzadas" ,
                items: [
                    {"text": "Limpieza de vehículos y espacios con desinfectante"},
                    {"text": "Las imágenes solo son ilustrativas. Tu vehículo puede ser diferente al de la fotografía."},
                    {"text": "Limpieza y desinfección de superficies de contacto frecuente" },
                ]
            },
            {
                title: "Medidas de seguridad" ,
                items: [
                    {"text": "Gel antibacterial"},
                    {"text": "Cubrebocas obligatorio"},
                    {"text": "Toma de temperatura para el personal  "},
                ]
            }
            ],xNumberPassengers:8,
             cancelationfree: false,
            maxNumberBags:6,
            shuttleType:"Viaje sencillo",
            categoryType:"Compartido",
            image: {
                code: "https://rentacamionetasdf.com/wp-content/uploads/2019/06/Sprinter_transparente-1-e1468386277126.png",          
                title: "Traslado privado"
              },
            rate: {               
                currency: "MXN",               
                hasPromotions: true,
                hasCancellationPolicies: true,
                hasOffers: true,
                total: 2400,
                promotions: [
                    "Cancelación Gratuita"
                ],
                cancellationPolicies: [
                    "Puedes cancelar hasta el 10/12/2022"
                ],
                taxes: [
                    {
                        included: false,
                        total: 90.0022,
                        currency: "MXN"
                    }
                ],
                offers: [
                    {
                        name: "Descuento exclusivo",
                        total: 12
                    }
                ],
                totalDiscounted: 12,
                totalWithOutOffers: 1500           
            }
    
        }


    ],
    filters: [
        {
            code: "TYPE",
            title: "Filtros más usados",
            items: [
                "Privado",
                "Compartido"
            ]
        },
        {
            code: "CANCELATION",
            title: "Recomendaciones",
            items: [
                "Cancelación Gratuita"
               
            ]
        }
    ]
    }

    export default listTransfersMock;