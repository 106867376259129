import { useIntl } from "react-intl";
import { Form } from 'react-bootstrap';
import Autocomplete from "../Autocomplete";

const InputAutocomplete = (props) => {
	const {
		id,
		label,
		value,
		placeholder,
	} = props;
	const intl = useIntl();

	//TODO GET LIST ITEMS
	const onChangeAutocomplete = (value, onSuccess) => {
		const { onInputChange } = props;
		onInputChange && onInputChange(value, onSuccess);
	}

	//TODO ONCLICK SELECT
	const onChangeAutocompleteSelect = (response) => {
		const { onChange } = props;
		onChange && onChange(response);
	}

	return (<div className="input">
		{label && <p>{label}</p>}
		<Form id={id}>
			<Form.Group style={{ marginBottom: "1.25rem" }}>
				<p>{intl.formatMessage({ id: "website.searchFilter.labelAutocomplete" })}</p>
				<Autocomplete
					onChangeAutocomplete={onChangeAutocomplete}
					onChangeSelect={onChangeAutocompleteSelect}
					placeholder={placeholder}
					value={value}
				/>
			</Form.Group>
		</Form>
	</div>)
}

export default InputAutocomplete;