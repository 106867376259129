const totalPeople = (rooms) => {
    const totals = rooms.map(room => {
        return (room.adult + (room.child ? room.child : 0) + (room.infant ? room.infant : 0))
    })

    return totals.reduce((accumulator, curr) => accumulator + curr);
}

const totalPeopleForRoom = (room) => {
    const total = (room.adult + (room.child ? room.child : 0) + (room.infant ? room.infant : 0))

    return total;
}

const totalChildren = (rooms) => {
    const totals = rooms.map(room => {
        return ((room.child ? room.child : 0) + (room.infant ? room.infant : 0))
    })

    return totals.reduce((accumulator, curr) => accumulator + curr);
}

const totalChildYears = (rooms) => {

    let ages = [];
    for (let i = 0; i < rooms.length; i++) {
        if(rooms[i].childYears)
            ages = ages.concat(rooms[i].childYears);
    }
 
    if (ages.length > 0) {

        return '(' + ages.join(',') + ')';
    }
    else {
        return '';
    }
}

const totalAdults = (rooms) => {
  
    const totals = rooms.map(room => {
        return room.adult
    })

    return totals.reduce((accumulator, curr) => accumulator + curr);
}

const totalPeopleDefault = (rooms, rules) => {
    const total = totalPeople(rooms);
    let groupPerson = rules.adultDefaultRoom + rules.childMinforRoom + rules.infantMinforRoom;

    const result = {
        createRoom: false,
    }

    if(total + groupPerson <= rules.personsTotal)
    {
        result.createRoom = true;
        result.adult = rules.adultDefaultRoom;
        result.child = rules.childMinforRoom;
        result.infant = rules.infantMinforRoom;
    }
    else {
        groupPerson = rules.adultMinforRoom + rules.childMinforRoom + rules.infantMinforRoom;

        if(total + groupPerson <= rules.personsTotal)
        {
            result.createRoom = true;
            result.adult = rules.adultMinforRoom;
            result.child = rules.childMinforRoom;
            result.infant = rules.infantMinforRoom;
        }
    }

    return result;
}

const formatYears = (children, years) => {
    if(children > 0 && (years == null || years == undefined)) {
        let _years = []
        for(let i = 0; i < children; i++)
            _years[i] = null;
        return _years;
    }
    else if (children > 0 && years.length != children)
    {
        let _years = [];
        for(let i = 0; i < children; i++)
            _years[i] = years[i] != null && years[i] != undefined ? years[i] : null;
        return _years;
    }

    return years ?? [];
}

const formatTextSelectionRooms = (rooms, rulesDescriptions, option, rules) => {
    let totalAdult = 0;
    let totalChild = 0;
    let totalInfant = 0;

    rooms.map(room => {
        totalAdult += room.adult;
        totalChild += room.child ? room.child : 0;
        totalInfant += room.infant ? room.infant : 0;
    })

    if(option === "A")
    {
        let response = `${totalAdult} ${totalAdult == 1 ? rulesDescriptions.adult : rulesDescriptions.adults}`;

        if(totalChild > 0)
            response += `, ${totalChild} ${totalChild == 1 ? rulesDescriptions.child : rulesDescriptions.children}`;
        if(totalInfant > 0)
            response += `, ${totalInfant} ${totalInfant == 1 ? rulesDescriptions.infant : rulesDescriptions.infants}`;

        return response;
    }

    let total = totalPeople(rooms)
    let response = `${rules.roomsMax <= 1 ? "" : rooms.length + '  hab,'} ${total} ${total == 1 ? rulesDescriptions.person : rulesDescriptions.people}`;

    return response;
}

const totalReservationAdults = (rooms, id) => {
    const totalAdult = rooms.reduce((p, a) => {
        const sum = a.adults + p
        return sum; 
    }, 0);
    
    return totalAdult;
}



const totalReservationChildren = (rooms) => {
      const totalChildren = rooms.reduce((p, a) => {
        const sum = a.children + p
        return sum; 
    }, 0);
    
    return totalChildren;
}

const selectPersonsUtils = {
    totalChildren,
    totalChildYears,
    totalAdults,
    totalPeople,
    totalPeopleDefault,
    formatYears,
    formatTextSelectionRooms,
    totalPeopleForRoom,
    totalReservationAdults,
    totalReservationChildren,
}

export default selectPersonsUtils