import { useIntl } from "react-intl";
import BoxTitleCard from "../../../boxes/BoxTitleCard";
import rateUtils from "../../../../utils/rateUtils";
import iconContants from "../../../../constants/iconContants";
import iconUtils from "../../../../utils/iconUtils";
import serviceTypeConstant from "../../../../constants/serviceTypeConstant";
import BoxIconText from "../../../boxes/BoxIconText";
import { useGlobalState } from "../../../../store/StoreProvider";
import { Link } from "react-router-dom";

const CardContent = (props) => {
	const { item, txt, type, exclusiveDeal } = props;
	const intl = useIntl();
	const { locale, currency } = useGlobalState();
	const hasRate = item.bestRate ? true : false;
	const onClickClose = () => {
		const { onClickClose } = props;
		if (onClickClose)
			onClickClose();
	}

	return (
		<div className="card-content">
			<div className="card-div-close">
				<button className="close-button" onClick={onClickClose}> {iconUtils.icon(iconContants.AIOUTLINECLOSE)}  </button>
			</div>
			<Link style={{ pointerEvents: hasRate ? '' : 'none' }} to={item.url} reloadDocument>
				<BoxTitleCard item={item} type={type} hasLink={false} />
				<b className="mb-2">
					{type === serviceTypeConstant.HOTEL && (
						<i className="me-1">{iconUtils.icon(iconUtils.getIconMealPlanType(item.mealPlan?.code))}</i>
					)}
					{type === serviceTypeConstant.HOTEL ? item.mealPlan?.name : item?.category}
				</b>
				<div className={`container-flex ${hasRate ? '' : 'container-not-avalability'}`}>
					{hasRate && (<>
						<div className="container-mealplan">
							<div style={{ display: "grid" }}>
								<span
									className="container-duration"
									style={{
										display: "flex",
										verticalAlign: "middle",
									}}>
									{item?.maxNumberPassengers && (
										<>
											Max :
											<span className="icono">
												{" "}
												{iconUtils.icon(
													iconContants.BSFILLPERSONFILL
												)}{" "}
											</span>
											<span style={{ marginRight: "5px" }}>
												{item.maxNumberPassengers}{" "}
											</span>
										</>
									)}
									{item?.maxNumberBags && (
										<>
											<span className="icono">
												{" "}
												{iconUtils.icon(
													iconContants.BRIEFCASE
												)}{" "}
											</span>
											<span style={{ marginRight: "5px" }}>
												{item.maxNumberBags}{" "}
											</span>
										</>
									)}
									{item.duration && (
										<>
											{intl.formatMessage({
												id: "general.duration",
											})}
											{item.duration}
										</>
									)}
								</span>
								<p
									className="box-item-text bold"
									style={{ marginTop: "5px" }}>
									{txt && txt}
								</p>
							</div>
							{exclusiveDeal.length > 0 && (
								<div className="d-flex justify-content-start mt-2 box-ofer">
									<BoxIconText items={exclusiveDeal.map((itm) => {
										return {
											icon: iconContants.FICHECK,
											classIcon: "color-primary",
											text: itm,
										};
									})}
									/>
								</div>
							)}
						</div>
						<div className="box-price">
							{item.hasOffers && (
								<div
									className="rate-without-discount"
									style={{
										textDecoration: "line-through",
										height: "20px",
									}}>
									{rateUtils.formatAmount(item.bestRate?.totalWithOffersPerNight, currency.code, locale)}

								</div>
							)}
							<div className="box-item-price">
								{rateUtils.formatAmount(item.bestRate?.totalPerNight, currency.code, locale)}
							</div>
							<p className="box-item-text">
								{intl.formatMessage({ id: "general.totalPrice" })} {rateUtils.formatAmount(item.bestRate?.total, currency.code, locale)}
							</p>
							<p className="box-item-text">
								{intl.formatMessage({ id: "text.tax" })}
							</p>
						</div>
					</>)}
					{!hasRate && (<b>{intl.formatMessage({ id: "general.notAvailable" })}</b>)}
				</div>
			</Link>
		</div>
	);
};

export default CardContent;
