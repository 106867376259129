import { FormattedMessage  } from "react-intl";

const tourDetailMock = {
    externalId: "",
    name:  "Parque Xcaret",
    description:
      "En The Royal Cancun All Villas Resort, es un hotel de lujo todo incluido frente al mar. Las instalaciones incluyen 12 piscinas, de las cuales ocho son climatizadas y una tiene entrada tipo playa, ocho jacuzzis, gimnasio con sala separada para pilates y spinning, y un spa de dos niveles y tres secciones, una para el salón de belleza, otra para las áreas húmedas con hidroterapia y un centro de masajes tratamientos. Además, cuenta con siete restaurantes y 12 bares incluyendo seis con acceso a la piscina, una sala de cata y se ofrece servicio a la habitación en horario limitado para las habitaciones familiares y 24 horas para las habitaciones Elegance Club. Este hotel tiene un teatro para espectáculos en vivo durante el día y la noche, gazebo para bodas en la playa y en el jardín y centro de convenciones para 700 personas. Las habitaciones se dividen en dos secciones, una para familias y otra para adultos. Cada habitación está equipada con balcón o terraza con jacuzzi, puerta independiente para el servicio a la habitación, minibar de reposición diaria, Smart TV y se ofrece una botella",
    type: "Hostal",
    country: "MX",
    city: "CANCUN",
    addres: "Xcaret - México, Carretera Chetumal - Puerto Juárez km 282 Int. B Colonia Rancho Xcaret,Playa",
    postalCode: "77524",
    gallery: [
      {
        name: "Entrada Xcaret Parque",
        url: "https://www.xcaret.com/assets/xcaret/sliders/es/ficha/xcaret/sliders-ficha-xcaret03.webp",
      },
      {
        name: "Entrada Xcaret Parque",
        url: "https://www.xcaret.com/assets/xcaret/sliders/es/ficha/xcaret/sliders-ficha-xcaret01.webp",
      },
      {
        name: "Entrada Xcaret Parque",
        url: "https://www.xcaret.com/assets/xcaret/sliders/es/ficha/xcaret/sliders-ficha-xcaret02.webp",
      },
      {
        name: "Entrada Xcaret Parque",
        url: "https://www.xcaret.com/assets/xcaret/sliders/es/ficha/xcaret/sliders-ficha-xcaret04.webp",
      },
      {
        name: "Entrada Xcaret Parque",
        url: "https://www.xcaret.com/assets/xcaret/sliders/es/ficha/xplor/sliders-ficha-xplor02.webp",
      },
    ],
    services: [
      {
        name:  <FormattedMessage id="tourProvisional.title.1" /> ,
        items: [
          <FormattedMessage id="tourProvisional.experience.1" /> ,
          <FormattedMessage id="tourProvisional.experience.2" /> ,
          <FormattedMessage id="tourProvisional.experience.3" /> ,
          <FormattedMessage id="tourProvisional.experience.4" /> ,
          <FormattedMessage id="tourProvisional.experience.5" /> ,
        ],
      },
      {
        name:  <FormattedMessage id="tourProvisional.title.2" /> ,
        items: [
          <FormattedMessage id="tourProvisional.detail.1" /> ,
        ],
      },
      {
        name:  <FormattedMessage id="tourProvisional.title.3" /> ,
        items: [
          <FormattedMessage id="tourProvisional.detail.2" /> ,
          <FormattedMessage id="tourProvisional.detail.3" /> ,
          <FormattedMessage id="tourProvisional.detail.4" /> ,
          <FormattedMessage id="tourProvisional.detail.5" /> ,
          <FormattedMessage id="tourProvisional.detail.6" /> ,
          <FormattedMessage id="tourProvisional.detail.7" /> ,
          <FormattedMessage id="tourProvisional.detail.8" /> ,
          <FormattedMessage id="tourProvisional.detail.9" /> ,
          <FormattedMessage id="tourProvisional.detail.10" /> ,
          <FormattedMessage id="tourProvisional.detail.11" /> ,
          <FormattedMessage id="tourProvisional.detail.12" /> ,
          <FormattedMessage id="tourProvisional.detail.13" /> ,
          <FormattedMessage id="tourProvisional.detail.14" /> ,
          <FormattedMessage id="tourProvisional.detail.15" /> ,
          <FormattedMessage id="tourProvisional.detail.16" /> ,
          <FormattedMessage id="tourProvisional.detail.17" /> ,
          <FormattedMessage id="tourProvisional.detail.18" /> ,
        ],
      },
      {
        name:  <FormattedMessage id="tourProvisional.title.4" /> ,
        items: [
          <FormattedMessage id="tourProvisional.notfor.1" /> ,
          <FormattedMessage id="tourProvisional.notfor.2" /> ,
          <FormattedMessage id="tourProvisional.notfor.3" /> ,
          <FormattedMessage id="tourProvisional.notfor.4" /> ,
        ],
      },
      {
        name:  <FormattedMessage id="tourProvisional.title.5" /> ,
        items: [
          <FormattedMessage id="tourProvisional.important.1" /> ,
          <FormattedMessage id="tourProvisional.important.2" /> ,
          <FormattedMessage id="tourProvisional.important.3" /> ,
          <FormattedMessage id="tourProvisional.important.4" /> ,
        ],
      },
    ],
    geoPosition: {
      latitude: 21.174293,
      longitude: -86.822199,
    },
    rooms: [
      {
        id: "d03b5cd6-d1b6-4e03-89fb-7eb2cd790752",
        externalId: "DBL.ST",
        name:  "Parque Xcaret: solo entrada" ,
        descriptionType: <FormattedMessage id="tourProvisional.txt2" /> ,
        plan: <FormattedMessage id="tourProvisional.txt1" /> ,
        rates: [
          {
            id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-RO-NRF-TODOS",
            rateKey:
              "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|DBL.ST|BOOKABLE|NRF|RO|MXN|NRF-TODOS|1476.39|MX|638010340917159839;7CADB2BABF6CB95AB0B4F01F972FB64D",
            isRefundable: false,
            refundable: "No puedes cancelar ni realizar cambios",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 935,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: ["Non-refundable rate. No amendments permitted"],
            cancellationPolicies: [],
            taxes: [
              {
                included: false,
                total: 2,
                currency: "MXN",
              },
            ],
            nights: 3,
            pricePerNight: 2058,
          }
        ],
        ratesGrouped: [
          {
            grouped: [
              {
                id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-RO-NRF-TODOS",
                rateKey:
                  "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|DBL.ST|BOOKABLE|NRF|RO|MXN|NRF-TODOS|1476.39|MX|638010340917159839;7CADB2BABF6CB95AB0B4F01F972FB64D",
                isRefundable: false,
                refundable: "No puedes cancelar ni realizar cambios",
                mealPlan: "Room Only",
                mealPlanCode: "RO",
                total: 935,
                differenceOverLowPrice: 0,
                currency: "MXN",
                promotions: ["Non-refundable rate. No amendments permitted"],
                cancellationPolicies: [],
                taxes: [
                  {
                    included: false,
                    total: 300,
                    currency: "MXN",
                  },
                ],
                nights: 3,
                pricePerNight:2058,
              },
            ],
            id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-RO-NRF-TODOS",
            currency: "MXN",
            isRefundable: false,
            hasPromotions: true,
            hasOffers: false,
            hasCancellationPolicies: false,
            refundable: "No puedes cancelar ni realizar cambios",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2458,
            promotions: ["Non-refundable rate. No amendments permitted"],
            cancellationPolicies: [],
            offers: [],
            totalDiscounted: 0,
            discountRate: 0,
            totalWithOffers: 2058,
            totalDifferenceOverLowPrice: 0,
            totalPerNight: 2058,
          },
        ],
        minRate: 1560.390305775,
        maxRate: 2735.03660745,
      },
      {
        id: "4117d559-fb11-4117-bd89-cac192cca854",
        externalId: "TPL.ST",
        name: "Parque Xcaret: nocturno",
        descriptionType: <FormattedMessage id="tourProvisional.txt2" /> ,
        plan: <FormattedMessage id="tourProvisional.txt1" /> ,
        photos: [
          {
            name: "Triple Standard - 1",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_025.JPG",
          },
          {
            name: "Triple Standard - 2",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_026.JPG",
          },
          {
            name: "Triple Standard - 3",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_023.jpg",
          },
          {
            name: "Triple Standard - 4",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_028.JPG",
          },
          {
            name: "Triple Standard - 5",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_021.jpg",
          },
          {
            name: "Triple Standard - 6",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_022.jpg",
          },
          {
            name: "Triple Standard - 7",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_024.jpg",
          },
          {
            name: "Triple Standard - 8",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_027.jpg",
          },
        ],
        rates: [
          {
            id: "TPL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-TODOS",
            rateKey:
              "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|TPL.ST|BOOKABLE|NOR|RO|MXN|CGW-TODOS|2136.84|MX|638010340917160607;67E7B9AA2E6C88900B4E48087250AD5A",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2220.8401923,
            differenceOverLowPrice: 660,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 24/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 84,
                currency: "MXN",
              },
            ],
            nights: 3,
            pricePerNight: 1500,
          },
        ],
        ratesGrouped: [
          {
            grouped: [
              {
                id: "TPL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-TODOS",
                rateKey:
                  "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|TPL.ST|BOOKABLE|NOR|RO|MXN|CGW-TODOS|2136.84|MX|638010340917160607;67E7B9AA2E6C88900B4E48087250AD5A",
                isRefundable: true,
                refundable: "Tarifa reembolsable",
                mealPlan: "Room Only",
                mealPlanCode: "RO",
                total: 2220.8401923,
                differenceOverLowPrice: 660,
                currency: "MXN",
                promotions: [],
                cancellationPolicies: [
                  "Puedes cancelar hasta el 18/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 21/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 21/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 24/11/2022 a las 4:59",
                ],
                taxes: [
                  {
                    included: false,
                    total: 84,
                    currency: "MXN",
                  },
                ],
                nights: 3,
                pricePerNight: 2058,
              },
            ],
            id: "TPL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-TODOS",
            currency: "MXN",
            isRefundable: true,
            hasPromotions: false,
            hasOffers: false,
            hasCancellationPolicies: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2221,
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 24/11/2022 a las 4:59",
            ],
            offers: [],
            totalDiscounted: 0,
            discountRate: 0,
            totalWithOffers: 2221,
            totalDifferenceOverLowPrice: 660,
            totalPerNight: 2058,
          },
        ],
        minRate: 2220.8401923,
        maxRate: 3075.268367175,
      },
    ],
    bestRate: {
      grouped: [
        {
          id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-RO-NRF-TODOS",
          rateKey:
            "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|DBL.ST|BOOKABLE|NRF|RO|MXN|NRF-TODOS|1476.39|MX|638010340917159839;7CADB2BABF6CB95AB0B4F01F972FB64D",
          isRefundable: false,
          refundable: "No puedes cancelar ni realizar cambios",
          mealPlan: "Room Only",
          mealPlanCode: "RO",
          total: 935,
          differenceOverLowPrice: 0,
          currency: "MXN",
          promotions: ["Non-refundable rate. No amendments permitted"],
          cancellationPolicies: [],
          taxes: [
            {
              included: false,
              total: 84,
              currency: "MXN",
            },
          ],
          nights: 3,
          pricePerNight: 935,
        },
      ],
      id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-RO-NRF-TODOS",
      currency: "MXN",
      isRefundable: false,
      hasPromotions: true,
      hasOffers: false,
      hasCancellationPolicies: false,
      refundable: "No puedes cancelar ni realizar cambios",
      mealPlan: "Room Only",
      mealPlanCode: "RO",
      total: 935,
      promotions: ["Non-refundable rate. No amendments permitted"],
      cancellationPolicies: [],
      offers: [],
      totalDiscounted: 0,
      discountRate: 0,
      totalWithOffers: 935,
      totalDifferenceOverLowPrice: 0,
      totalPerNight: 935,
    },
    notices: [],
    zones: ["Cancun"],
};

export default tourDetailMock;