import { FormattedMessage } from "react-intl";
import CardTourComponent from "@mzp/components/dist/components/surfaces/Cards/CardTour";
import CardTransferComponent from "@mzp/components/dist/components/surfaces/Cards/CardTransfer";
import CardPackageComponent from "@mzp/components/dist/components/surfaces/Cards/CardPackage";
import CardContainerService from "../components/cards/CardContainerService";

const settings = (toShow) => {
	return {
		dots: false,
		infinite: true,
		autoplay: true,
		speed: 1000,
		arrows: true,
		variableWidth: false,
		slidesToShow: toShow ?? 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					centerMode: false,
					variableWidth: true,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: false,
					variableWidth: true,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: false,
					variableWidth: true,
				},
			},
		],
	};

}

const txt = {
	left: <FormattedMessage id="text.pernight" />,
	price: <FormattedMessage id="text.tax" />,
};

const txtTour = {
	duration: <FormattedMessage id="general.duration" />,
	left: <FormattedMessage id="text.perperson" />,
	price: <FormattedMessage id="text.tax" />,
};

// Create object for recommed hotels at HotelListPage
const prototypeItems = (type, title, items, itemsFlag) => {
	return {
		showComponent: true,
		typeCard: type,
		title: title ?? <FormattedMessage id="top.destination" />,
		items: items,
		itemsFlag: itemsFlag ?? false,
	};
};

// return a card based on type of service
const handleCard = (typeCard, currency) => {
	const type = 'HOMEHOTEL';
	switch (typeCard) {
		case "hotel":
			return (
				<CardContainerService
					txt={txt.left}
					currency={currency?.code}
					validateItems={false}
					type={type}
				/>
			);
		case "transfer":
			return <CardTransferComponent txt={txt} activeButton={false} />;
		case "package":
			return <CardPackageComponent txt={txt} />;
		case "tour":
			return (
				<CardTourComponent txt={txtTour} classNameCard="card-content-width" />
			);
		default:
			return (
				<CardContainerService
					txt={txt.left}
					currency={currency?.code}
					validateItems={false}
					type={type}
				/>
			);
	}
};

const getRecommended = (items) => {
	let nCards = null;
	nCards = items.sort((a, b) => {
		return a.price === b.price ? 0 : a.price > b.price ? 1 : -1;
	});
	const listGeneral = nCards.filter((hotel) => hotel.mealPlan.code !== "AI");
	const listAllInclusive = nCards.filter(
		(hotel) => hotel.mealPlan.code === "AI"
	);
	const listAllInclusiveMayorDiscount = listAllInclusive.sort((a, b) => {
		return a.offerSale === b.offerSale ? 0 : a.offerSale > b.offerSale ? -1 : 1;
	});
	const all = [...listAllInclusiveMayorDiscount, ...listGeneral];
	nCards = all;
	return nCards.slice(0, 10);
};

const thigsToDoUtils = {
	settings,
	txt,
	txtTour,
	prototypeItems,
	handleCard,
	getRecommended,
};

export default thigsToDoUtils;
