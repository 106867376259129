const pageLinksConstants = {
  HOME: "/",
  HOME_EN: "/",
  MY_RESERVATION: "/mis-reservaciones",
  MY_RESERVATION_EN: "/my-reservations",
  HOTELS: "/hoteles",
  HOTELS_EN: "/hotels",
  CHECKOUT: "/reserva",
  CHECKOUT_EN: "/checkout",
  HOTEL: "/hotel",
  HOTEL_EN: "/hotel",
  LOGIN: "/login",
  LOGIN_EN: "/login",
  LANDING_ACTIVITY: "/tour",
  LANDING_ACTIVITY_EN: "/activity",
  LANDING_HOTEL: "/hotel",
  LANDING_HOTEL_EN: "/hotel",
  LANDING_PACKAGE: "/paquete",
  LANDING_PACKAGE_EN: "/package",
  LANDING_SHUTTER: "/traslado",
  LANDING_SHUTTER_EN: "/transfer",
  PRIVACY_ES: "/privacidad",
  PRIVACY_EN: "/privacy",
  TERMSANDCONDITIONS_ES: "/teminos-y-condiciones",
  TERMSANDCONDITIONS_EN: "/terms-and-conditions",
  COOKIES_POLICY_ES: "/politicas-cookies",
  COOKIES_POLICY_EN: "/cookies-policy",
  NOTFOUND: "/notfound",
  VOUCHER: "/voucher",
  VOUCHER_STATIC_ES: "/voucher-pendiente",
  VOUCHER_STATIC_EN: "/voucher-pending",
};

export default pageLinksConstants;
