const constants = {
    iconType: {
        EMPTY: "",
        DESCRIPTION: "Description",
        FACEBOOK: "Facebook",
        FAMILYRESTROOM: "FamilyRestroom",
        MAIL: "Mail",
        PHONE: "Phone",
        INSTAGRAM: "Instagram",
        WORK: "Work",
        STARRATE: "StarRate",
        STAR: "Star",
        LOCALOFFER: "LocalOffer",
        ARROWFORWARDIOS:"ArrowForwardIosIcon",
        TUNE: "Tune",
        LOCATION: "LocationOn",
        LOCATIONON: "LocationOn",
        VIEWLIST: "ViewList",
        GRIDVIEW: "GridView",
        FILTERLIST: "FilterList",
        PEOPLEALTICON: "PeopleAltIcon",
        KINGBED: "KingBedIcon",
        NETWORKWIFI: "NetworkWifiIcon",
        ACUNIT: "AcUnitIcon",
        LOCALPHONE: "LocalPhoneIcon",
        SHOWER: "ShowerIcon",
        AIRLINESEATINDIVIDUALSUITE: "AirlineSeatIndividualSuiteIcon",
        CHECK: "CheckIcon",
        RESTAURANT: "RestaurantIcon",
        ADD:"AddIcon",
        NIGHTLIGHT:"NightLight",
		LIGHTMODE: "LightMode",
		BRIGHTNESS: "Brightness",
        DOORROOM:"MeetingRoom",
        CHECKROOM:"CheckinRoom",
        RESTAURANTMENU: "RestaurantMenuIcon",
        POOL:"PoolIcon",
        LOCALPARKING:"LocalParkingIcon",
        SPA:"SpaIcon",
        PERSON:"PersonIcon",
        LANGUAGE:"LanguageIcon",
        DECK:"DeckIcon",
        SCHEDULE:"Schedule",
        WATCHLATER:"WatchLater",
        CLOSE:"Close",
		HELP: "Help",
		WARNING: "Warning",
		DANGEROUS: "Dangerous",
		INFO: "Info",
		SELL: "Sell",
        ARROWFORWARDOUTLINED: "ArrowForwardOutlined",
        ARROWBACKOUTLINED: "ArrowBackOutlined",
        HORIZONTALRULE: "HorizontalRule",
        UPDATE: "Update",
        KEYBOARDARROWDOWN: "KeyboardArrowDown",
        MAP:"Map",
        ATM:"Atm",
        TIMERZ:"Snooze",
        CALENDARMONTH:"CalendarMonth",
        HOURGLASS:"Hourglass"
    },
    fontFamilyType: {
        font: "Neue,Roboto,Helvetica,Arial,sans-serif",
        fontMedium: "Neue Medium,Roboto,Helvetica,Arial,sans-serif",
        fontLight: "Neue Light,Roboto,Helvetica,Arial,sans-serif"
    },
    breakpoints: {
        xs: 0,
        sm: 768,
        md: 992,
        lg: 1024,
        xl: 1280
    },
    language: {
        MX: "es-mx",
        EN: "en-us",
    },
};

export default constants;