import environment from "../constants/environment";
import serviceTypeConstant from "../constants/serviceTypeConstant"
const removeAccents = (str) => {
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

const firstUpperCase = (newValue, isUpper) => {
	if (newValue && (newValue.length > 3 || isUpper)) {
		const textstar = newValue.charAt(0).toUpperCase();
		const textEnd = newValue.slice(1);
		return (textstar + textEnd)
	}
	return newValue;
}

const isNumeric = (num) => {
	var esempty = false;

	if (!isNaN(num)) {
		var expreg = /^[0-9]*$/;

		if (expreg.test(num))
			esempty = true;
		else
			esempty = false;
	}

	return esempty;
}

const isNumberDecimals = (num) => {
	var esempty = false;

	if (!isNaN(num)) {
		var expreg = /^[0-9.0-9]*$/;

		if (expreg.test(num))
			esempty = true;
		else
			esempty = false;
	}

	return esempty;
}
const isNumber = (num) => {
	return isNaN(num) ? false : true;
}

const isEmpty = (valor) => {
	let isempty = false;
	if (valor === undefined || valor == null)
		isempty = true;
	else if (valor.toString().replace(/\s/g, '') === "")
		isempty = true;
	return isempty;
}

const isText = (value) => {
	let isempty = true;
	const pattern = new RegExp('^[A-ZñÑáéíóúÁÉÍÓÚü ]+$', 'i');
	if (!pattern.test(value))
		isempty = false;
	return isempty;
}

const isEmail = (email) => {
	var expr = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	if (isEmpty(email))
		return false;
	return expr.test(email);
}

const compoundNameUpperCase = (value) => {
	const dNames = value?.toLowerCase().split(' ') ?? [];
	const names = [];
	dNames.forEach((element, index) => {
		names[index] = firstUpperCase(element, index == 0 ? true : false);
	});
	return names.join(' ');
}

const removeSpace = (value) => {
	const dNames = value?.split(' ') ?? [];
	const names = [];
	dNames.forEach((element, index) => {
		names[index] = element;
	});
	return names.join('');
}

// validates number of images to show in Detail Page
const validateItemsGallery = (isMobile, numberImages) => {
	const maxNumGallery = isMobile ? 1 : 5;
	return numberImages >= maxNumGallery;
}

const getEnvironmentHostname = () => {
	const hostname = process.env.NODE_ENV === environment.PRODUCTION
		? window.location.hostname : process.env.REACT_APP_AUTH_DOMAIN;

	return hostname
}

const getEnvironmentKeys = (locale) => {
	const hostname = getEnvironmentHostname();
	const data = {
		hostname: hostname,
		siteInfoKey: `${process.env.REACT_APP_BUSINESS_SITE_FILE}-${hostname}`,
		siteResourceKey: `${process.env.REACT_APP_BUSINESS_SITE_FILE}-${hostname}-${locale}`
	}
	return data
}

const filterMetaByRouteType = (meta, routeType) => {
	const filteredItems = meta.filter(item => item.routeType === routeType);
	return filteredItems.length > 0 ? filteredItems[0] : null;
}

const getServiceConstantByCode = (service) => {
	service = service.toLowerCase()
	switch (service) {
		case "h":
			return serviceTypeConstant.HOTEL;
			break;
		case "A":
			return serviceTypeConstant.TOUR;
			break;
		case "p":
			return serviceTypeConstant.PACKAGE;
			break;
		case "t":
			return serviceTypeConstant.TRANSFER;
			break;
	}
}

const commonUtils = {
	removeAccents,
	firstUpperCase,
	compoundNameUpperCase,
	removeSpace,
	isEmpty,
	isText,
	isEmail,
	isNumeric,
	isNumber,
	isNumberDecimals,
	validateItemsGallery,
	getEnvironmentHostname,
	getEnvironmentKeys,
	filterMetaByRouteType,
	getServiceConstantByCode
}

export default commonUtils;