import {Accordion, Button} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useState } from "react";
import { useIntl } from "react-intl";
import './components/listCheck.scss'
function ListCheck(props) {
    const { title, items, selects, controlShow } = props;
    const [showControl, setShowControl] = useState(false);
    const intl = useIntl();
    const onChange = (item, event) => {
      const {onChange} = props;
      onChange && onChange({
          value: item.value,
          checked: event.target.checked,
      })
  }
  const openControl = () => {
    setShowControl(!showControl);
}

    return ( <Accordion defaultActiveKey="0" className="acordion" >
    <Accordion.Item eventKey="0" className="border-0">
        <Accordion.Header> {title} </Accordion.Header>
        <Form.Group>
         <Accordion.Body>
         { items.map((item, index) => {
         return (
            <Form.Check
            key={index}
            style={{  display :   index >= controlShow ? (showControl ? "block" : "none") : "block" }}
            type="checkbox"
            id={item.value}
            label={item.label}
            checked={ (selects && selects.length > 0) ? selects.indexOf(item.value) !== -1 : false}
            onChange={(e) => {onChange(item, e)}}
            />
         ) }) }
          {items.length > controlShow && (
            <Button variant="text" 
            className='btn-filter'
            onClick={openControl}
            >
            { showControl ? intl.formatMessage({ id: "general.seeLess" }) : intl.formatMessage({ id: "general.seeMore" }) }
            </Button>
          )}
         </Accordion.Body>
         </Form.Group>
      </Accordion.Item>
    </Accordion>
   );

}

export default ListCheck;