import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import { useGlobalState } from "../store/StoreProvider";


const useModalTable = (type, request, isFetching = 0, resetModals, ...rest) => {
  const intl = useIntl();
  const { authSession } = useGlobalState();
  const [_resul, setResult] = useState(null);
  const { url, method } = request;
  let colorModal = require(`../theme/site/${process.env.REACT_APP_BRANDING}/vars.scss`).default;
  var item = "";

  const requestBody = request.body;
  const headers = {
    method: method ?? "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authSession.token}`,
    },
    body: JSON.stringify(requestBody),
  };

  const textModalVoucher = {
    text: [
      {
        title: intl.formatMessage({ id: "checkout.att" }),
        text: intl.formatMessage({ id: "checkout.sureVoucher" }),
        deletedBtn: intl.formatMessage({ id: "checkout.sureVoucher" }),
        deleted: intl.formatMessage({ id: "checkout.resend" }),
        body: intl.formatMessage({ id: "checkout.resend.file" }),
        redirect: false,
      },
    ],
    btn: intl.formatMessage({ id: "checkout.confirmButtonText" }),
  };

  const textModalCancel = {
    text: [
      {
        title: intl.formatMessage({ id: "checkout.att" }),
        text: intl.formatMessage({ id: "checkout.sure" }),
        deletedBtn: intl.formatMessage({ id: "checkout.sure" }),
        deleted: intl.formatMessage({ id: "checkout.deleted" }),
        body: intl.formatMessage({ id: "checkout.file" }),
        redirect: false,
      },
    ],
    btn: intl.formatMessage({ id: "checkout.confirmButtonText" }),
  };

  switch (type) {
    case "voucher":
      item = textModalVoucher;
      break;
    case "cancel":
      item = textModalCancel;
      break;
  }

  var textModal = item.text;
  var textBtn = item.btn;

  const modalError = (textModal) => {
    Swal.fire({
      title: intl.formatMessage({ id: "checkout.att" }),
      text: intl.formatMessage({ id: "general.changes" }),
      icon: textModal?.icon ?? "info",
      showCloseButton: true,
      showConfirmButton: false,
      confirmButtonColor: colorModal.modalSucess,     
      didDestroy: () => {
        if (resetModals && typeof resetModals === "function") {
          resetModals();
        }
      },
    });
  };

  const modalUpdate = () => {
    Swal.fire({
      title: intl.formatMessage({ id: "general.updated" }),
      text: intl.formatMessage({ id: "general.infoSaved" }),
      icon: textModal?.icon ?? "info",
      showCloseButton: true,
      showConfirmButton: false,
      confirmButtonColor: colorModal.modalSucess,
      didDestroy: () => {
        if (resetModals && typeof resetModals === "function") {
          resetModals(type === "cancel" ? true : false);
        }
      },
    });
  };

  const modalPop = (textModal) => {
    Swal.fire({
      icon: textModal?.icon ?? "info",
      title: textModal?.title,
      text: textModal?.text,
      showCancelButton: true,
      confirmButtonText: textBtn,
      confirmButtonColor: colorModal.modalSucess,
      cancelButtonText: intl.formatMessage({ id: "button.close" }),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(`${process.env.REACT_APP_BFF_API}` + url, { ...headers })
          .then((response) => {
            if (!response.ok) throw Error(response.status);
            return response.json();
          })
          .then((data) => {
            if (data?.status || data?.sent ) {
              modalUpdate();
            } else {
              modalError();
            }
          })
          .catch((ex) => {
            modalError();
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isDismissed) {
        if (resetModals && typeof resetModals === "function") {
          resetModals();
        }
      }
    });
  };

  useEffect(() => {
    if (isFetching) {
      modalPop(textModal[0]);
      return () => {
        Swal.close();
      };
    }
  }, [isFetching]);
};

export default useModalTable;
