import iconUtils from "@mzp/commons/dist/utils/iconUtils";
import iconContants from "@mzp/commons/dist/constants/iconContants";
import footerComponent from "./components/footerComponentEN";
import languageConstants from "../../../constants/languageConstants";
import pageLinksConstants from "../../../constants/pageLinksConstants";
import dateUtils from "../../../utils/dateUtils";
import headerComponentEN from "./components/headerComponentEN";
import images from "../../../theme/ImageSite";
import commonUtils from "../../../utils/commonUtils";
import meta from "../../../theme/metaSiteData";
import webSiteConstant from "../../../constants/webSitesiteRoutesConstant";
const metaData = commonUtils.filterMetaByRouteType(
  meta.metaData_en_us,
  webSiteConstant.HOME
);

const imagenback = images?.imagenback;


const { startDate, endDate } = dateUtils.getRangeDays(15, 17);

let response = {
  routeType: webSiteConstant.HOME,
  siteLocale: languageConstants.EN,
  pathname: pageLinksConstants.HOME_EN,
  hasHeaderLayout: false,
  hasFooterLayout: false,
  dinamicPage: true,
  keypage: "slug-page",
  itemsComponent: [
    {
      order:1,
      type: "header",
      showComponent: true,
      ...headerComponentEN,
    },
    {
      order:2,
      type: "search-booking",
      showComponent: true,
      searchBoxProps: {
        background: false,
        type: "Hotel",
        autocompleteProps: {
          active: true,
          label: "Where to?",
          placeholder: "Search for a place or hotel",
          single: true,
          icon: iconContants.LOCATION,
        },
        calendarProps: {
          active: true,
          label: "When",
          placeholder: "-- - --",
          rangeDate: true,
          icon: iconContants.BSFILLCALENDAR2CHECKFILL,
        },
        roomProps: {
          active: true,
          label: "Room",
          icon: iconContants.BSFILLPERSONFILL,
        },
        buttonProps: {
          text: iconUtils.icon(iconContants.SEARCH),
        },
      },
      dataButton: {
        text: "Search Hotels in...",
        icon: iconUtils.icon(iconContants.SEARCH),
      },
      component: {
        title: "Your next journey begins here",
        image: {
          url: images.imagenback,
        },
      },
      minTextAPI: 3,
    },
    {
      order:3,
      type: "grid-gallery-section",
      showComponent: true,
      title: "Get ready for your next <span>adventure</span>",
      items: [
        {
          title: "Cancun",
          // text: "10+ Hotels",
          isBig: true,
          img: images.cancun,
          url: `/hotels?checkin=${startDate}&checkout=${endDate}&destination=Cancun&destinationkeyword=1536&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
        {
          title: "Punta Cana",
          // text: "10+ Hotels",
          img: images.PuntaCanaB,
          url: `/hotels?checkin=${startDate}&checkout=${endDate}&destination=Punta+Cana&destinationkeyword=1708&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
        {
          title: "Dominican Republic",
          // text: "10+ Hotels",
          isBig: true,
          img: images.DominicanB,
          url: `/hotels?checkin=${startDate}&checkout=${endDate}&destination=República+Dominicana&destinationkeyword=1709&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
        {
          title: "Isla Mujeres",
          // text: "10+ Hotels",
          img: images.IslaMujeres,
          url: `/hotels?checkin=${startDate}&checkout=${endDate}&destination=Isla+Mujeres&destinationkeyword=1546&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
        {      
          title: "Riviera Maya",
          // text: "20+ Hotels",
          img: images.RivieraMayaB,
          url: `/hotels?checkin=${startDate}&checkout=${endDate}&destination=Riviera+Maya&destinationkeyword=1561&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
        {
          title: "Jamaica",
          // text: "25+ Hotels",
          img: images.Jamaica,
          isBig: true,
          bottom: true,
          url: `/hotels?checkin=${startDate}&checkout=${endDate}&destination=Jamaica&destinationkeyword=1711&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
        {
          title: "Los Cabos",
          // text: "25+ Hotels",
          img: images.LosCabos,
          url: `/hotels?checkin=${startDate}&checkout=${endDate}&destination=Los+Cabos&destinationkeyword=1452&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
        {
          title: "Santa Lucia",
          // text: "25+ Hotels",
          img: images.SantaLuciaB,
          isBig: true,
          bottom: true,
          url: `/hotels?checkin=${startDate}&checkout=${endDate}&destination=Santa+Lucía&destinationkeyword=1710&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
      ],
    },
    {
      order:4,
      type: "slide-top-things-todo",
      showComponent: true,
      typeCard: "hotel",
      title: "Hotels <span>recommended</span> for you",
      items: [],
    },
    {
      order:5,
      type: "footer",
      showComponent: true,
      ...footerComponent,
    },
  ],
};
response = { ...response, ...metaData };

export default response;
