import hotelDetailQuery from '../../mock/hotel/hotelDetailQuery';
import hotelsValidate from "../../validate/hotelsValidate";

const hotelDetailMock = {
    externalId: "",
    name: "Hacienda de Castilla",
    description:
      "Situado entre las avenidas López Portillo y Chichen Itza en un famoso complejo turístico y urbano de Cancún, este espléndido hotel es el lugar perfecto para unas vacaciones en familia, una escapada de fin de semana o un viaje de negocios. El Aeropuerto Internacional de Cancún está a 20 km y los visitantes se encontrarán muy cerca de muchas empresas locales, oficinas corporativas, lugares de ocio y monumentos locales. Este precioso hotel cuenta con espaciosas habitaciones elegantemente decoradas en las que descansar por completo durante la noche. Los huéspedes despertarán con un delicioso desayuno americano o continental y podrán cenar delicias culinarias mexicanas en el tradicional restaurante del hotel. Durante el día, los clientes podrán jugar al billar, hacer uso de la piscina al aire libre, tomar el sol en las tumbonas o disfrutar de un exótico cóctel en el bar del hotel. Hay aparcamiento disponible para aquellos que lleguen en coche.",
    stars: "TwoAndAHalf",
    type: "Hostal",
    country: "MX",
    city: "CANCUN",
    addres: "Calle 17 Norte, Mz 23, Lote 9, SM 64",
    postalCode: "77524",
    gallery: [
      {
        name: "Hacienda de Castilla - 1",
        url: "https://photos.hotelbeds.com/giata/bigger/13/135692/135692a_hb_w_016.JPG",
      },
      {
        name: "Hacienda de Castilla - 2",
        url: "https://photos.hotelbeds.com/giata/bigger/13/135692/135692a_hb_p_013.jpg",
      },
      {
        name: "Hacienda de Castilla - 3",
        url: "https://photos.hotelbeds.com/giata/bigger/13/135692/135692a_hb_a_017.JPG",
      },
      {
        name: "Hacienda de Castilla - 4",
        url: "https://photos.hotelbeds.com/giata/bigger/13/135692/135692a_hb_ro_004.JPG",
      },
      {
        name: "Hacienda de Castilla - 5",
        url: "https://photos.hotelbeds.com/giata/bigger/13/135692/135692a_hb_a_014.jpg",
      },
    ],
    services: [
      {
        name: "Restaurant",
        items: ["Atencio - Rivera", "Mena, Quintana and Griego"],
      },
      {
        name: "Bar",
        items: ["Carbajal, Delafuente and Cuellar"],
      },
      {
        name: "Service",
        items: ["Rentería, León and Prado"],
      },
    ],
    geoPosition: {
      latitude: 21.174293,
      longitude: -86.822199,
    },
    rooms: [
      {
        id: "d03b5cd6-d1b6-4e03-89fb-7eb2cd790752",
        externalId: "DBL.ST",
        name: "Double Standard",
        photos: [
          {
            name: "Double Standard - 1",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_007.jpg",
          },
          {
            name: "Double Standard - 2",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_012.jpg",
          },
          {
            name: "Double Standard - 3",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_011.jpg",
          },
          {
            name: "Double Standard - 4",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_013.jpg",
          },
          {
            name: "Double Standard - 5",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_004.JPG",
          },
          {
            name: "Double Standard - 6",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_010.jpg",
          },
          {
            name: "Double Standard - 7",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_009.JPG",
          },
          {
            name: "Double Standard - 8",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_008.jpg",
          },
          {
            name: "Double Standard - 9",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_005.JPG",
          },
        ],
        amenities: [
          {
            name: "Amenidad",
            items: ["Regadera"],
          },
          {
            name: "Vista",
            items: ["Calle", "Jardín", "Mar"],
          },
        ],
        rates: [
          {
            id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-RO-NRF-TODOS",
            rateKey:
              "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|DBL.ST|BOOKABLE|NRF|RO|MXN|NRF-TODOS|1476.39|MX|638010340917159839;7CADB2BABF6CB95AB0B4F01F972FB64D",
            isRefundable: false,
            refundable: "No puedes cancelar ni realizar cambios",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 1560.390305775,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: ["Non-refundable rate. No amendments permitted"],
            cancellationPolicies: [],
            taxes: [
              {
                included: false,
                total: 84,
                currency: "MXN",
              },
            ],
            nights: 3,
            pricePerNight: 520,
          },
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-TODOS",
            rateKey:
              "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|CGW-TODOS|1554.14|MX|638010340917160195;3F3783A8FFA4A92673F556976F83CAEB",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 1638.1355721375,
            differenceOverLowPrice: 78,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 24/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 84,
                currency: "MXN",
              },
            ],
            nights: 3,
            pricePerNight: 546,
          },
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-PVP-TODOS",
            rateKey:
              "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|PVP-TODOS|1779.67|MX|638010340917160325;A7379E50DD18EB153DEBFC4E2788CFF9",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 1863.6738201,
            differenceOverLowPrice: 303,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 24/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 84,
                currency: "MXN",
              },
            ],
            nights: 3,
            pricePerNight: 621,
          },
          {
            id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-AB-NRF-TODOS",
            rateKey:
              "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|DBL.ST|BOOKABLE|NRF|AB|MXN|NRF-TODOS|2330.82|MX|638010340917160402;2DF819FDF7CD1BAA7DE7BFFB83F20333",
            isRefundable: false,
            refundable: "No puedes cancelar ni realizar cambios",
            mealPlan: "American Breakfast",
            mealPlanCode: "AB",
            total: 2414.81848065,
            differenceOverLowPrice: 854,
            currency: "MXN",
            promotions: ["Non-refundable rate. No amendments permitted"],
            cancellationPolicies: [],
            taxes: [
              {
                included: false,
                total: 84,
                currency: "MXN",
              },
            ],
            nights: 3,
            pricePerNight: 805,
          },
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-AB-CGW-TODOS",
            rateKey:
              "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|DBL.ST|BOOKABLE|NOR|AB|MXN|CGW-TODOS|2408.56|MX|638010340917160453;6EF0D6A6E5E8732AEC38F84A3E050185",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "American Breakfast",
            mealPlanCode: "AB",
            total: 2492.5637470125,
            differenceOverLowPrice: 932,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 24/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 84,
                currency: "MXN",
              },
            ],
            nights: 3,
            pricePerNight: 831,
          },
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-AB-PVP-TODOS",
            rateKey:
              "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|DBL.ST|BOOKABLE|NOR|AB|MXN|PVP-TODOS|2651.04|MX|638010340917160526;AF611CB4F291E8771CDC6722D341D0C0",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "American Breakfast",
            mealPlanCode: "AB",
            total: 2735.03660745,
            differenceOverLowPrice: 1175,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 24/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 84,
                currency: "MXN",
              },
            ],
            nights: 3,
            pricePerNight: 912,
          },
        ],
        ratesGrouped: [
          {
            grouped: [
              {
                id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-RO-NRF-TODOS",
                rateKey:
                  "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|DBL.ST|BOOKABLE|NRF|RO|MXN|NRF-TODOS|1476.39|MX|638010340917159839;7CADB2BABF6CB95AB0B4F01F972FB64D",
                isRefundable: false,
                refundable: "No puedes cancelar ni realizar cambios",
                mealPlan: "Room Only",
                mealPlanCode: "RO",
                total: 1560.390305775,
                differenceOverLowPrice: 0,
                currency: "MXN",
                promotions: ["Non-refundable rate. No amendments permitted"],
                cancellationPolicies: [],
                taxes: [
                  {
                    included: false,
                    total: 84,
                    currency: "MXN",
                  },
                ],
                nights: 3,
                pricePerNight: 520,
              },
            ],
            id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-RO-NRF-TODOS",
            currency: "MXN",
            isRefundable: false,
            hasPromotions: true,
            hasOffers: false,
            hasCancellationPolicies: false,
            refundable: "No puedes cancelar ni realizar cambios",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 1560,
            promotions: ["Non-refundable rate. No amendments permitted"],
            cancellationPolicies: [],
            offers: [],
            totalDiscounted: 0,
            discountRate: 0,
            totalWithOffers: 1560,
            totalDifferenceOverLowPrice: 0,
            totalPerNight: 520,
          },
          {
            grouped: [
              {
                id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-AB-NRF-TODOS",
                rateKey:
                  "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|DBL.ST|BOOKABLE|NRF|AB|MXN|NRF-TODOS|2330.82|MX|638010340917160402;2DF819FDF7CD1BAA7DE7BFFB83F20333",
                isRefundable: false,
                refundable: "No puedes cancelar ni realizar cambios",
                mealPlan: "American Breakfast",
                mealPlanCode: "AB",
                total: 2414.81848065,
                differenceOverLowPrice: 854,
                currency: "MXN",
                promotions: ["Non-refundable rate. No amendments permitted"],
                cancellationPolicies: [],
                taxes: [
                  {
                    included: false,
                    total: 84,
                    currency: "MXN",
                  },
                ],
                nights: 3,
                pricePerNight: 805,
              },
            ],
            id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-AB-NRF-TODOS",
            currency: "MXN",
            isRefundable: false,
            hasPromotions: true,
            hasOffers: false,
            hasCancellationPolicies: false,
            refundable: "No puedes cancelar ni realizar cambios",
            mealPlan: "American Breakfast",
            mealPlanCode: "AB",
            total: 2415,
            promotions: ["Non-refundable rate. No amendments permitted"],
            cancellationPolicies: [],
            offers: [],
            totalDiscounted: 0,
            discountRate: 0,
            totalWithOffers: 2415,
            totalDifferenceOverLowPrice: 854,
            totalPerNight: 805,
          },
        ],
        minRate: 1560.390305775,
        maxRate: 2735.03660745,
      },
      {
        id: "4117d559-fb11-4117-bd89-cac192cca854",
        externalId: "TPL.ST",
        name: "Triple Standard",
        photos: [
          {
            name: "Triple Standard - 1",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_025.JPG",
          },
          {
            name: "Triple Standard - 2",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_026.JPG",
          },
          {
            name: "Triple Standard - 3",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_023.jpg",
          },
          {
            name: "Triple Standard - 4",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_028.JPG",
          },
          {
            name: "Triple Standard - 5",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_021.jpg",
          },
          {
            name: "Triple Standard - 6",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_022.jpg",
          },
          {
            name: "Triple Standard - 7",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_024.jpg",
          },
          {
            name: "Triple Standard - 8",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_027.jpg",
          },
        ],
        rates: [
          {
            id: "TPL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-TODOS",
            rateKey:
              "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|TPL.ST|BOOKABLE|NOR|RO|MXN|CGW-TODOS|2136.84|MX|638010340917160607;67E7B9AA2E6C88900B4E48087250AD5A",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2220.8401923,
            differenceOverLowPrice: 660,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 24/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 84,
                currency: "MXN",
              },
            ],
            nights: 3,
            pricePerNight: 740,
          },
          {
            id: "TPL.ST-BOOKABLE-NOR-False-AT_WEB-AB-CGW-TODOS",
            rateKey:
              "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|TPL.ST|BOOKABLE|NOR|AB|MXN|CGW-TODOS|2991.27|MX|638010340917160705;F7416DF3884B9BFE18F2C174192F9987",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "American Breakfast",
            mealPlanCode: "AB",
            total: 3075.268367175,
            differenceOverLowPrice: 1515,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 24/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 84,
                currency: "MXN",
              },
            ],
            nights: 3,
            pricePerNight: 1025,
          },
        ],
        ratesGrouped: [
          {
            grouped: [
              {
                id: "TPL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-TODOS",
                rateKey:
                  "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|TPL.ST|BOOKABLE|NOR|RO|MXN|CGW-TODOS|2136.84|MX|638010340917160607;67E7B9AA2E6C88900B4E48087250AD5A",
                isRefundable: true,
                refundable: "Tarifa reembolsable",
                mealPlan: "Room Only",
                mealPlanCode: "RO",
                total: 2220.8401923,
                differenceOverLowPrice: 660,
                currency: "MXN",
                promotions: [],
                cancellationPolicies: [
                  "Puedes cancelar hasta el 18/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 21/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 21/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 24/11/2022 a las 4:59",
                ],
                taxes: [
                  {
                    included: false,
                    total: 84,
                    currency: "MXN",
                  },
                ],
                nights: 3,
                pricePerNight: 740,
              },
            ],
            id: "TPL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-TODOS",
            currency: "MXN",
            isRefundable: true,
            hasPromotions: false,
            hasOffers: false,
            hasCancellationPolicies: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2221,
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 24/11/2022 a las 4:59",
            ],
            offers: [],
            totalDiscounted: 0,
            discountRate: 0,
            totalWithOffers: 2221,
            totalDifferenceOverLowPrice: 660,
            totalPerNight: 740,
          },
          {
            grouped: [
              {
                id: "TPL.ST-BOOKABLE-NOR-False-AT_WEB-AB-CGW-TODOS",
                rateKey:
                  "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|TPL.ST|BOOKABLE|NOR|AB|MXN|CGW-TODOS|2991.27|MX|638010340917160705;F7416DF3884B9BFE18F2C174192F9987",
                isRefundable: true,
                refundable: "Tarifa reembolsable",
                mealPlan: "American Breakfast",
                mealPlanCode: "AB",
                total: 3075.268367175,
                differenceOverLowPrice: 1515,
                currency: "MXN",
                promotions: [],
                cancellationPolicies: [
                  "Puedes cancelar hasta el 18/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 21/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 21/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 24/11/2022 a las 4:59",
                ],
                taxes: [
                  {
                    included: false,
                    total: 84,
                    currency: "MXN",
                  },
                ],
                nights: 3,
                pricePerNight: 1025,
              },
            ],
            id: "TPL.ST-BOOKABLE-NOR-False-AT_WEB-AB-CGW-TODOS",
            currency: "MXN",
            isRefundable: true,
            hasPromotions: false,
            hasOffers: false,
            hasCancellationPolicies: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "American Breakfast",
            mealPlanCode: "AB",
            total: 3075,
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 24/11/2022 a las 4:59",
            ],
            offers: [],
            totalDiscounted: 0,
            discountRate: 0,
            totalWithOffers: 3075,
            totalDifferenceOverLowPrice: 1515,
            totalPerNight: 1025,
          },
        ],
        minRate: 2220.8401923,
        maxRate: 3075.268367175,
      },
      {
        id: "ff451599-ff8e-4410-b380-3ec9f110eae4",
        externalId: "QUA.ST",
        name: "Quadruple Standard",
        photos: [
          {
            name: "Quadruple Standard - 1",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_018.JPG",
          },
          {
            name: "Quadruple Standard - 2",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_020.JPG",
          },
          {
            name: "Quadruple Standard - 3",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_016.jpg",
          },
          {
            name: "Quadruple Standard - 4",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_014.jpg",
          },
          {
            name: "Quadruple Standard - 5",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_017.JPG",
          },
          {
            name: "Quadruple Standard - 6",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_019.jpg",
          },
          {
            name: "Quadruple Standard - 7",
            url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_015.jpg",
          },
        ],
        amenities: [
          {
            name: "Vista",
            items: ["Interior"],
          },
          {
            name: "Amenidad",
            items: ["Tina"],
          },
        ],
        rates: [
          {
            id: "QUA.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-TODOS",
            rateKey:
              "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|QUA.ST|BOOKABLE|NOR|RO|MXN|CGW-TODOS|2719.54|MX|638010340917160757;BF4B76DB4372E65C1BCDCC3BB7656560",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2803.5448124625,
            differenceOverLowPrice: 1243,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 24/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 84,
                currency: "MXN",
              },
            ],
            nights: 3,
            pricePerNight: 935,
          },
          {
            id: "QUA.ST-BOOKABLE-NOR-False-AT_WEB-AB-CGW-TODOS",
            rateKey:
              "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|QUA.ST|BOOKABLE|NOR|AB|MXN|CGW-TODOS|3573.97|MX|638010340917160832;C03A29931E60570BC86663F61A2703CC",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "American Breakfast",
            mealPlanCode: "AB",
            total: 3657.9729873375,
            differenceOverLowPrice: 2098,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 24/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 84,
                currency: "MXN",
              },
            ],
            nights: 3,
            pricePerNight: 1219,
          },
        ],
        ratesGrouped: [
          {
            grouped: [
              {
                id: "QUA.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-TODOS",
                rateKey:
                  "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|QUA.ST|BOOKABLE|NOR|RO|MXN|CGW-TODOS|2719.54|MX|638010340917160757;BF4B76DB4372E65C1BCDCC3BB7656560",
                isRefundable: true,
                refundable: "Tarifa reembolsable",
                mealPlan: "Room Only",
                mealPlanCode: "RO",
                total: 2803.5448124625,
                differenceOverLowPrice: 1243,
                currency: "MXN",
                promotions: [],
                cancellationPolicies: [
                  "Puedes cancelar hasta el 18/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 21/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 21/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 24/11/2022 a las 4:59",
                ],
                taxes: [
                  {
                    included: false,
                    total: 84,
                    currency: "MXN",
                  },
                ],
                nights: 3,
                pricePerNight: 935,
              },
            ],
            id: "QUA.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-TODOS",
            currency: "MXN",
            isRefundable: true,
            hasPromotions: false,
            hasOffers: false,
            hasCancellationPolicies: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2804,
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 24/11/2022 a las 4:59",
            ],
            offers: [],
            totalDiscounted: 0,
            discountRate: 0,
            totalWithOffers: 2804,
            totalDifferenceOverLowPrice: 1243,
            totalPerNight: 935,
          },
          {
            grouped: [
              {
                id: "QUA.ST-BOOKABLE-NOR-False-AT_WEB-AB-CGW-TODOS",
                rateKey:
                  "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|QUA.ST|BOOKABLE|NOR|AB|MXN|CGW-TODOS|3573.97|MX|638010340917160832;C03A29931E60570BC86663F61A2703CC",
                isRefundable: true,
                refundable: "Tarifa reembolsable",
                mealPlan: "American Breakfast",
                mealPlanCode: "AB",
                total: 3657.9729873375,
                differenceOverLowPrice: 2098,
                currency: "MXN",
                promotions: [],
                cancellationPolicies: [
                  "Puedes cancelar hasta el 18/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 21/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 21/11/2022 a las 4:59",
                  "Puedes cancelar hasta el 24/11/2022 a las 4:59",
                ],
                taxes: [
                  {
                    included: false,
                    total: 84,
                    currency: "MXN",
                  },
                ],
                nights: 3,
                pricePerNight: 1219,
              },
            ],
            id: "QUA.ST-BOOKABLE-NOR-False-AT_WEB-AB-CGW-TODOS",
            currency: "MXN",
            isRefundable: true,
            hasPromotions: false,
            hasOffers: false,
            hasCancellationPolicies: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "American Breakfast",
            mealPlanCode: "AB",
            total: 3658,
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              "Puedes cancelar hasta el 21/11/2022 a las 4:59",
              "Puedes cancelar hasta el 24/11/2022 a las 4:59",
            ],
            offers: [],
            totalDiscounted: 0,
            discountRate: 0,
            totalWithOffers: 3658,
            totalDifferenceOverLowPrice: 2098,
            totalPerNight: 1219,
          },
        ],
        minRate: 2803.5448124625,
        maxRate: 3657.9729873375,
      },
    ],
    bestRate: {
      grouped: [
        {
          id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-RO-NRF-TODOS",
          rateKey:
            "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|DBL.ST|BOOKABLE|NRF|RO|MXN|NRF-TODOS|1476.39|MX|638010340917159839;7CADB2BABF6CB95AB0B4F01F972FB64D",
          isRefundable: false,
          refundable: "No puedes cancelar ni realizar cambios",
          mealPlan: "Room Only",
          mealPlanCode: "RO",
          total: 1560.390305775,
          differenceOverLowPrice: 0,
          currency: "MXN",
          promotions: ["Non-refundable rate. No amendments permitted"],
          cancellationPolicies: [],
          taxes: [
            {
              included: false,
              total: 84,
              currency: "MXN",
            },
          ],
          nights: 3,
          pricePerNight: 520,
        },
      ],
      id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-RO-NRF-TODOS",
      currency: "MXN",
      isRefundable: false,
      hasPromotions: true,
      hasOffers: false,
      hasCancellationPolicies: false,
      refundable: "No puedes cancelar ni realizar cambios",
      mealPlan: "Room Only",
      mealPlanCode: "RO",
      total: 1560,
      promotions: ["Non-refundable rate. No amendments permitted"],
      cancellationPolicies: [],
      offers: [],
      totalDiscounted: 0,
      discountRate: 0,
      totalWithOffers: 1560,
      totalDifferenceOverLowPrice: 0,
      totalPerNight: 520,
    },
    notices: [],
    zones: ["Cancun"],
};

const hotelDetailMockMapper = hotelsValidate.formatDetails(hotelDetailMock, hotelDetailQuery)

export {
	hotelDetailQuery,
	hotelDetailMockMapper,
	hotelDetailMock
};
