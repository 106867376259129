import { useState, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { useDispatch, useGlobalState } from "../../store/StoreProvider";
import { useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import formValidate from "../../validate/formValidate";
import authAction from "../../actions/authAction";
import storage from "../../localstorage/storage";
import "./login-style.scss";
import useGetNameAuthStorage from "../../hooks/useGetNameAuthStorage";
import useCallApi from "../../hooks/useCallApi";
import Spinner from 'react-bootstrap/Spinner';

const LoginPage = (props) => {
  const intl = useIntl();
  const { locale, authSession, pageOptions, resourceSite,siteInformation } = useGlobalState();
  const dispatch = useDispatch();

  let navigate = useNavigate();
  useCallApi();
  const authConst = useGetNameAuthStorage();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const butonValidateObject = {
    ok: true,
    description: ""
  }
  const [userValidate, setUSerValidate] = useState({
    email: {
      ok: true,
      description: ""
    },
    password: {
      ok: true,
      description: ""
    },
    button: butonValidateObject
  });

  const rulesDescriptions = {
    email: {
      empty: intl.formatMessage({ id: "loginPage.form.validate.mail" }),
      text: intl.formatMessage({ id: "loginPage.form.validate.mail" }),
    },
    password: {
      empty: intl.formatMessage({ id: "loginPage.form.validate.password" }),
    },
  }
  const handleInputChange = (event) => {
    let newParam = null;
    let newValidate = null;

    switch (event.target.name) {
      case 'email':
        newParam = event.target.value.trim();
        newValidate = formValidate.email(event.target.value, rulesDescriptions.email)
        break;
      case 'password':
        newParam = event.target.value.trim();
        newValidate = formValidate.password(event.target.value, rulesDescriptions.password)
        break;
      default:
        break;
    }

    if (newParam != null) {
      setUser({ ...user, [event.target.name]: newParam });
      setUSerValidate({ ...userValidate, [event.target.name]: newValidate, button: butonValidateObject });
    }
  }

  const handleInputBlur = (event) => {
    handleInputChange(event)
  }
  useEffect(() => {
    if (pageOptions.isSuccess && resourceSite.isSuccess) {
      if(pageOptions.hasDisabled) {
        //TODO por default el login no es la pagina principal
        //TODO si no estas activado el login entonces esta libre la pagina raiz
        const localizedUrlLandingHotel = `/${locale}${resourceSite.options.redirectPageDefault}`;
        const location = {
          pathname: localizedUrlLandingHotel,
        };
        navigate(location);
      }
      else {
        const auxSession = storage.getLocalStorage(authConst);

        if (auxSession !== null) {
          //TODO mandar a llamar al redirect pero de la pagina
          const localizedUrlLandingHotel = pageOptions.isSuccess
            ? `/${locale}${pageOptions.pageRedirect}`
            : `/${locale}/error-500`;
          const location = {
            pathname: localizedUrlLandingHotel,
          };
          navigate(location);
        }
      }
    }
  }, [locale, pageOptions, resourceSite]);

  const onSubmit = (e) => {
    const SiteidGeneric = 0;
    e.preventDefault();
    e.stopPropagation();

    if (formValidate.validateFormLogin(user)) {
      const request = {
        emailAddress: user.email,
        password: user.password,
        siteId: siteInformation.site.id ?? SiteidGeneric
      }
      authAction.auth(request, dispatch,
        (response) => {
          
          storage.setLocalStorage(authConst, response, response.expires - 1);
          const localizedUrlLandingHotel = `/${locale}${pageOptions.pageRedirect}`;
          const location = {
            pathname: localizedUrlLandingHotel,
          };
          navigate(location);
        },
        (error) => {
          if(error == '500'){
            setUSerValidate({
            ...userValidate,
            button: {
              ok: false,
              description: intl.formatMessage({ id: "loginPage.form.validate.types" }),
            },
            });
          }else{
            setUSerValidate({
              ...userValidate,
              button: {
                ok: false,
                description: intl.formatMessage({ id: "loginPage.form.validate.useropass" }),
              },
            });
          }
        })
    }
  }

  return ((pageOptions.isSuccess && !pageOptions.hasDisabled) &&
    <div className="login-bg">
      <Container fluid="xl" className="login-container">
        <Card className="login-card">
          <Card.Body>
            <Form onSubmit={onSubmit} autoComplete="off">
              <div className="login-box">
                <h3 className="text-center mb-3">
                  {intl.formatMessage({ id: "loginPage.title" })}
                </h3>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{intl.formatMessage({ id: "loginPage.form.mail" })}</Form.Label>
                  <Form.Control type="email" name="email"
                    placeholder={intl.formatMessage({ id: "loginPage.form.mail" })}
                    onChange={handleInputChange}
                  />
                  {userValidate.email.ok === false && (
                    <Form.Text className="text-warning">
                      {userValidate.email.description}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>{intl.formatMessage({ id: "loginPage.form.password" })}</Form.Label>
                  <Form.Control type="password" name="password"
                    placeholder={intl.formatMessage({ id: "loginPage.form.password" })}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                  {userValidate.password.ok === false && (
                    <Form.Text className="text-warning">
                      {userValidate.password.description}
                    </Form.Text>
                  )}
                </Form.Group>
                <Button variant="Light" id="btn-login-submit" 
                  className="w-100 btn btn-Light"
                  disabled={authSession.isFetching}
                  type="submit">
                  {intl.formatMessage({ id: "loginPage.title" })}
                  {authSession.isFetching && <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />}
                </Button>
                {!userValidate.button.ok && (
                  <Form.Group className="mb-3">
                    <Form.Text className="text-warning pt-3 text-center">
                      {userValidate.button.description}
                    </Form.Text>
                  </Form.Group>
                )}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default LoginPage;