import {combineReducers} from "redux";
import currency from "./CurrencyReducer";
import searchHotels from "./searchHotelsReducer";
import hotel from "./hotelReducer";
import packages from "./packageReducer";
import flight from "./flightReducer";
import tour from "./tourReducer";
import payment from "./paymentReducer";
import authSession from "./authSessionReducer";
import locale from "./localeReducer";
import pageOptions from "./pageOptionsReducer";
import resourceSite from "./resourceSiteReducer"
import siteInformation  from "./siteInformationReducer";
import cmsComponents  from "./cmsComponentsReducers";
const rootReducer = combineReducers({
    pageOptions,
    currency,
    searchHotels,
    hotel,
    packages,
    flight,
    tour,
    payment,
    authSession,
    locale,
    resourceSite,
    siteInformation,
    cmsComponents
})

export default rootReducer;
