import { useGlobalState } from "../../store/StoreProvider";
import InformationSection from "../../components/section/InformationSection/InformationSection";

const ErrorPage = (props) => {
  const { resourceSite } = useGlobalState();
  const { type, text, btn, title } = props;

  const onClickGoToHome = () => {
    window.location.href = resourceSite?.options?.home?.href ?? "/";
  };

  return (
    <>
      <InformationSection
        type={type}
        title={title}
        text={text}
        btn={btn}
        onClickGoToHome={onClickGoToHome}
      />
    </>
  );
};

export default ErrorPage;
