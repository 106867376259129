const hotelDetailQuery = {
  "occupancies": [
    {
      "adults": 2,
      "children": 0,
      "infant": 0,
      "ages": null
    }
  ],
  "checkIn": "2023-09-06",
  "checkOut": "2023-09-08",
  "currency": "MXN",
  "hotelId": "10147",
  "sourceMarket": "MX",
  "culture": "Spanish"
};

export default hotelDetailQuery;