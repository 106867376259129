import footerLogo from "../../../assets/img/branding/nexus/logo-footer.svg";
import IslaMujeres from "../../../assets/img/collage/grid/IslaMujeres.jpg";
import PVallarta from "../../../assets/img/collage/grid/PVallarta.jpg";


import DominicanB from "../../../assets/img/collage/grid/DominicanB.jpg";
import PuntaCanaB from "../../../assets/img/collage/grid/PuntaCanaB.jpg";
import RivieraMayaB from "../../../assets/img/collage/grid/RivieraMayaB.jpg";
import SantaLuciaB from "../../../assets/img/collage/grid/SantaLuciaB.jpg";

const Jamaica = "https://majorisclub.blob.core.windows.net/$web/Jamaica-edd9a252dbfb8788b50a.jpg.jpg";
const cancun = "https://cdn.majoristravel.com/cancun-a5186ebe-770d-446a-8bce-0ec43e9ee1c11.jpg";
const LosCabos="https://majorisclub.blob.core.windows.net/$web/los_cabos-4f163c19239fb491d68f.jpg";
const headerLogo = "https://cdn.majoristravel.com/branding/nexus/logo-desktop.png";
const imagenback = "https://cdn.majoristravel.com/branding/nexus/banner-home-desktop.png";
const imgFlip1 =
  "https://i.pinimg.com/originals/d6/55/a7/d655a757a5fa3b71c35be4380c4b36d7.jpg";
const imgFlip2 =
  "https://www.yavas.com/images/upload//assets-yavas/Home/paquetes-cancun.jpg";
const imgFlip3 =
  "https://s3.amazonaws.com/crowdriff-media/full/e9df3341c44588f049ef1433bdd5fc2bbb385ee4dffda77f1caff014e9489e74.jpg";
const imgFlip4 =
  "https://www.yavas.com/images/upload//assets-yavas/Home/paquetes-tulum.jpg";
const imgBannerBackgroundVideo =
  "https://www.nexustours.com/images/upload/Landing%20Pages/febrero2023/9P.jpg";
const imageNewsletter = "https://cdn.majoristravel.com/beach-3369140_1920.jpg";
const imgBannerVideo = "https://cdn.majoristravel.com/blue-4145659_1920.jpg";
const bannerImgTitle =
  "https://www.nexustours.com/handlers/ImageRequest.ashx?path=https%3a%2f%2fwww.nexustours.com%2fimages%2fupload%2fLanding%2520Pages%2ffebrero2023%2fBannerweb7.jpg&mediaSize=lg&imgSize=bigger";
  
 const homeVacationLife = {
  img1: "https://cdn.majoristravel.com/acapulco-797eb183-50c9-43dd-9311-d9ef11ec0ae7.jpg",
  img2: "https://cdn.majoristravel.com/cancun-a5186ebe-770d-446a-8bce-0ec43e9ee1c0.jpg",
  img3: "https://cdn.majoristravel.com/los_cabos-3d49cdf8-5bde-464d-9d77-1356c7621215.jpg",
  img4: "https://cdn.majoristravel.com/mazatlan-d39df221-1ff8-4df7-8b42-3cdeb4ef913f.jpg",
  img5: "https://cdn.majoristravel.com/aerial-view-of-puerto-vallarta-at-sunset-puerto.jpg",
  img6: "https://cdn.majoristravel.com/puerto-escondido-oaxaca-137809.jpg",
};

const images = {
  bannerImgTitle: bannerImgTitle,
  imgFlip1: imgFlip1,
  imgFlip2: imgFlip2,
  imgFlip3: imgFlip3,
  imgFlip4: imgFlip4,
  imgBannerBackgroundVideo: imgBannerBackgroundVideo,
  imgBannerVideo: imgBannerVideo,
  imageNewsletter: imageNewsletter,
  imagenback: imagenback,
  homeVacationLife: homeVacationLife,
  footerLogo:footerLogo,
  headerLogo:headerLogo,
  cancun: cancun,
  IslaMujeres: IslaMujeres,
  Jamaica: Jamaica,
  PVallarta: PVallarta,
  DominicanB: DominicanB,
  PuntaCanaB: PuntaCanaB,
  RivieraMayaB: RivieraMayaB,
  SantaLuciaB: SantaLuciaB,
  LosCabos:LosCabos
};

export default images;
