import localeUtils from "../utils/localeUtils";
import hotelsValidate from "../validate/hotelsValidate";
import types from "../constants/reducers/hotelConstants";
import {hotelDetailMock} from "../mock/hotel/hotelDetailMock";

//TODO: get hotel
const get = (hotel) => {
	hotel.isFetching = false;
	hotel.isSuccess = true;
	hotel.isError = false;
	return { type: types.HOTEL, hotel };
};

const fetching = () => {
	const hotel = {
		isFetching: true,
		isSuccess: false,
		isError: false,
	};
	return { type: types.HOTEL_FETCHING, hotel };
};

const getError = () => {
	const hotel = {
		isFetching: false,
		isSuccess: false,
		isError: true,
	};
	return { type: types.HOTEL_ERROR, hotel };
};

//TODO Get hotel without context
const hotelSlow = async (request, onSuccess, onError) => {
	const { locale, authSession, ...rest } = request;
	const language = localeUtils.handleLanguage(locale);
	await fetch(`${process.env.REACT_APP_BFF_API}${process.env.REACT_APP_BFF_HOTEL_DETAIL}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${authSession.token}`
		},
		body: JSON.stringify({ ...rest, culture: language, sourceMarket: request.sourceMarket })
	})
		.then((response) => {
			if (response.ok) return response.json();
			throw new Error("Something went wrong.");
		})
		.then((data) => {
			const response = hotelsValidate.formatDetails(data, request);
			if (onSuccess && typeof onSuccess === "function")
				onSuccess(response);
		})
		.catch((ex) => {
			if (onError && typeof onError === "function")
				onError(ex);
		});
};

//TODO Get hotel with context and use Ditpatch
const hotel = async (request, dispatch, onSuccess, onError) => {
	const { locale, authSession, ...rest } = request;
	const language = localeUtils.handleLanguage(locale);
	dispatch(fetching());
	await fetch(`${process.env.REACT_APP_BFF_API}${process.env.REACT_APP_BFF_HOTEL_DETAIL}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${authSession.token}`,
		},
		body: JSON.stringify({ ...rest, culture: language, sourceMarket: request.sourceMarket }),
	})
		.then((response) => {
			if (response.ok) return response.json();
			throw new Error("Something went wrong.");
		})
		.then((data) => {
			const response = hotelsValidate.formatDetails(data, request);
			dispatch(get(response));
			if (onSuccess && typeof onSuccess === "function") {
				onSuccess(response);
			}
		})
		.catch((ex) => {
			dispatch(getError());
			if (onError && typeof onError === "function") {
				onError(ex);
			}
		});
};

//TODO get fake hotel
const hotelMock = async (request, dispatch, onSuccess, _onError) => {
	const response = hotelsValidate.formatDetails(hotelDetailMock, request);
	dispatch(get(response));
	await onSuccess(response);
};

const checkRates = async (request, onSuccess, onError) => {
	const { locale, authSession, ...rest } = request;
	await fetch(`${process.env.REACT_APP_BFF_API}${process.env.REACT_APP_BFF_CHECKRATE}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${authSession.token}`,
		},
		body: JSON.stringify({ ...rest }),
	})
		.then((response) => {
			if (response.ok) return response.json();
			throw new Error("Something went wrong.");
		})
		.then((data) => {
			if (onSuccess && typeof onSuccess === "function") {
				onSuccess({ items: data, isSuccess: true, isError: false });
			}
		})
		.catch((ex) => {
			if (onError && typeof onError === "function") {
				onError(ex);
			}
		});
};

const booking = async (request, onSuccess, onError) => {
	const { authSession, ...rest } = request;
	await fetch(`${process.env.REACT_APP_BFF_API}${process.env.REACT_APP_BFF_BOOK}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${authSession.token}`,
		},
		body: JSON.stringify(rest),
	})
		.then((response) => {
			if (response.ok) return response.json();
			throw new Error("Something went wrong.");
		})
		.then((json) => {
			if (onSuccess && typeof onSuccess === "function") {
				onSuccess(json);
			}
		})
		.catch((ex) => {
			if (onError && typeof onError === "function") {
				onError(ex);
			}
		});
};

const serviceHotelAction = {
	hotel,
	hotelSlow,
	hotelMock,
	checkRates,
	booking,
};
export default serviceHotelAction;
