import { useState } from "react";
//import SelectedCount from "../../form/SelectedCount/SelectedCount";
import {SelectedCount} from "../../form/selects";
import Form from 'react-bootstrap/Form';
import selectPersonsUtils from "../../../utils/selectPersonsUtils";

const BoxSelectPeopleSearch = (props) => {
    const {label, subLabel,value, sx, hasDroplist,itemsSelect,itemsValue,classs, ...rest} = props;
    const [count, setCount] = useState(value ?? 0)
    const [drops, setDrops] = useState(selectPersonsUtils.formatYears(value,itemsValue))

    /* event to count the number of values it receives */
    const _onChange = (_value) => {
        const { onChange, onChangeSelect } = props;
        setCount(_value);

        if(onChange)
            onChange(_value);

        if(hasDroplist && onChangeSelect) {
            let next = 0;
            let items = [];
            while(next < _value)
            {
                if(drops != null && drops[next] !== undefined && drops[next] != null)
                    items[next] = drops[next];
                else
                    items.push(itemsSelect[0]?.toString());//TODO change value null to default //itemsSelect[0]?.toString()
                next++;
            }
            setDrops(items)
            onChangeSelect(items)
        }
    }

    const _onChangeSelect = (e, index) => {
        const {onChangeSelect} = props;
        let items = [...drops];
        items[index] = e.target.value;
        setDrops(items);

        if(onChangeSelect)
            onChangeSelect(items);
    }
/* we pass the amount and return the list */
    const selectedGroup = () => {
        const { id } = props;

        let components = [];
        for(let i = 0; i < count; i++) {
            const nValue = drops && drops.length > 0 ? drops[i] : undefined;
            components.push(<Form.Select id={`${id}-${i}`}
            value={nValue}
            onChange={(response) => { _onChangeSelect(response, i)}}>
            {itemsSelect.map((item, index) => {
                    return (<option key={"itms" + index} value={item}>{item}</option>)
            })}
            </Form.Select>)
        }

        return components.map((item, index) => {
            return(<div key={index} className="box-years">
                {item}
            </div>)
        })
    }

    return ( <div className={`box-select-people-search ${classs}`}>
        <div className="box-select-people-search-container">
        {label && <span className="fs-6">{label}</span>}
        <SelectedCount {...rest} value={value} onChange={_onChange} />
        </div>
        <div>
        {subLabel && <span className="text-muted subtext"
            >{subLabel}</span>}
        </div>
        {hasDroplist && (<div className="container-select-years">
                {selectedGroup()}
        </div>)}
    </div> );
}
 
export default BoxSelectPeopleSearch;