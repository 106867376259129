import { Button } from "react-bootstrap";
import { useGlobalState } from "../../../store/StoreProvider";
import rateUtils from "../../../utils/rateUtils";
import { useIntl } from "react-intl";
import Overlay from "../../Overlays/Overlay/Overlay";
import businessModel from "../../../constants/businessModel"
import "./components/style.scss";

const BoxServiceRate = (props) => {
	const {
		classs,
		textButton,
		informations,
		textFrom,
		rate,
		currency,
		hasOffers,
		rateDiscount,
		classsThrough,
		offerSale,
		rateWithoutTax,
		totalNetPerNight,
		total,
	} = props;
	const { locale } = useGlobalState();
	const intl = useIntl();
	const onClick = () => {
		const { onClick } = props;
		onClick && onClick();
	};

	let offerSaleAmount = offerSale ?? 0;
	let rateWithoutTaxeAmout = rateWithoutTax ?? 0;
	let totalAmountNetPerNight = totalNetPerNight ?? 0;

	return (
		<div className={`box-service-rate ${classs}`}>
			{hasOffers && offerSaleAmount > 0 && (
				<div className={`rate-offe ${classsThrough}`}>
					<span>{rateUtils.formatAmount(rateDiscount, currency, locale)}</span>
				</div>
			)}

			<div className="informations">
				<b>
					{intl.formatMessage({ id: "text.pernight" })}
				</b>
			</div>
			{/* B2C Rate */}
			{process.env.REACT_APP_BUSINESS.toUpperCase() === businessModel.B2C && (
				<div className="rate">
					{rateUtils.formatAmount(rate, currency, locale)}
				</div>
			)}
			{/* B2B rate with net rate room card*/}
			{process.env.REACT_APP_BUSINESS.toUpperCase() === businessModel.B2B && totalAmountNetPerNight > 0 && (
				<div className="overlay-container">
					<Overlay
						classNameOverlay={"tooltip-rates"}
						description={`${intl.formatMessage({
							id: "general.table.netRate",
						})}: ${rateUtils.formatAmount(
							totalAmountNetPerNight,
							currency,
							locale
						)}`}
					>
						{rateUtils.formatAmount(rate, currency, locale)}
					</Overlay>
				</div>
			)}
			{/* B2B rate without net rate on hotel detaild */}
			{process.env.REACT_APP_BUSINESS.toUpperCase() === businessModel.B2B && !totalAmountNetPerNight && (
				<div className="rate">
					{rateUtils.formatAmount(rate, currency, locale)}
				</div>
			)}
			<div className="informations">
				{intl.formatMessage({ id: "general.totalPrice" })}{" "}
				{rateUtils.formatAmount(total, currency, locale)}
			</div>
			<div className="informations-tax">
				{" "}
				{intl.formatMessage({ id: "general.bestPricePerRoomTaxes" })}
			</div>
			<Button className="btn-reservar" onClick={onClick}>{textButton}</Button>
		</div>
	);
};

export default BoxServiceRate;
