import { useState } from "react";
import { useIntl } from "react-intl";
import { Card, Carousel } from "react-bootstrap";
import imageDefault from "../../../assets/img/defaultImage.svg";
import iconUtils from "../../../utils/iconUtils";
import iconContants from "../../../constants/iconContants";
import BoxIconText from "../../boxes/BoxIconText";
import BoxServiceRate from "../../boxes/BoxServiceRate";
import Modals from "../../modals/Modals";
import BoxInfoRoom from "../../boxes/BoxInfoRoom/BoxInfoRoom";
import DataNumberImage from "../../ui/NumberImages";
import "./components/style.scss";


//TODO card for rooms in hotel detail
function CardHotelRoomLarge(props) {
	const { room, from, rateDescriptions, textButton, currentClass, itemCardRoomClass,hiddenImage } = props;
	const intl = useIntl();
	const onClickReservation = () => {
		const { onClickReservation } = props;
		onClickReservation && onClickReservation();
	};

	const [fullscreen, setFullscreen] = useState(true);
	const [show, setShow] = useState(false);

	function handleShow(breakpoint) {
		setFullscreen(breakpoint);
		setShow(true);
	}
	const handleCloseModal = () => setShow(false);

	const dataNumeberImag = (room) => {
		let infoIconText = [{
			icon: iconContants.IMGS,
			classIcon: "",
			text: room.photos.length,
			classTex: "",
		}];
		return infoIconText;
	}

	return (
		<Card className={`container-card-room ${itemCardRoomClass} ${currentClass ? currentClass : ""}`}>
			<div style={{display: hiddenImage && "none"}} className="container-card-img">
				{(room.photos && room.photos.length === 0) && (
					<Card.Img loading="lazy"
						className="card-img"
						src={imageDefault}
					/>
				)}
				{(room.photos && room.photos.length === 1) && (
					<Card.Img loading="lazy"
						className="card-img"
						src={room.photos[0]?.photoUris || imageDefault}
					/>
				)}
				{(room.photos && room.photos.length > 1) && (
					<Carousel>
						{room.photos.map((img, index) => {
							return (
								<Carousel.Item interval={100000} key={index}>
									<Card.Img loading="lazy"
										className="card-img"
										src={room.photos[index]?.photoUris || imageDefault}
									/>
								</Carousel.Item>
							);
						})}
					</Carousel>
				)
				}
				<div className="container-tag-selected-room">
					<span>{intl.formatMessage({ id: "hotelDetailPage.currentRoom" })}</span>
				</div>
				{room.hasOffers && (
					<div className="container-tag">
						<span className="container-icon">
							{iconUtils.icon(iconContants.CIDISCOUNT1)}
						</span>
						<span>{`${intl.formatMessage(
							{ id: "general.discountText" },
							{ count: room.offerSale }
						)}`}</span>
					</div>
				)}
				<DataNumberImage numberImages={room?.photos?.length} />
			</div>
			<Card.Body>
				<div className="container-aux">
					<div className="container-left">
						{room && room.name && (
							<div className="container-card-body-header">
								<h2 className="title">
									{" "}
									{`${room.name} ${room.view ? `- ${room.view}` : ""}`}
								</h2>
							</div>
						)}
						{room && room.mealPlan && (
							<BoxIconText
								items={[
									{
										icon: iconUtils.getIconMealPlanType(room.mealPlan.code),
										text: room.mealPlan.name,
									},
								]}
							/>
						)}
						<div className="container-icon-amenities">
							{room.amenityBasic.hasAA && (
								<div className="container-icon">
									{iconUtils.icon(iconContants.AA)}
								</div>
							)}
							{room.amenityBasic.hasPhone && (
								<div className="container-icon">
									{iconUtils.icon(iconContants.ROOMPHONE)}
								</div>
							)}
							{room.amenityBasic.hasWifiFree && (
								<div className="container-icon">
									{iconUtils.icon(iconContants.WIFI)}
								</div>
							)}
						</div>

						{room && room.cancellationPolicies && (
							<div className="container-policies">
								<span>{room.cancellationPolicies[0]}</span>
							</div>
						)}
						{room.hasOffers && room.offerSale > 0 && (
							<div className="rate-discount">
								<span className="container-icon me-2">
									{iconUtils.icon(iconContants.CIDISCOUNT1)}
								</span>
								<span>
									{`${intl.formatMessage(
										{ id: "general.discountText" },
										{ count: room.offerSale }
									)}`}
								</span>
							</div>
						)}
						{room.amenities && (
							<div
								className="btn-showmore"
								onClick={() => handleShow(true)}>
								<span>
									{intl.formatMessage({
										id: "general.moreInformation",
									})}
								</span>
								<span className="container-icon">
									{iconUtils.icon(
										iconContants.AIOUTLINERIGHT
									)}
								</span>
							</div>
						)}
						<Modals
							fullscreen={fullscreen}
							show={show}
							showClose={false}
							handleCloseModal={handleCloseModal}
							title={intl.formatMessage({
								id: "hotelDetailPage.titleModalRoom",
							})}
						>
							<BoxInfoRoom
								item={room}
								textButton={textButton ? textButton : ""}
								onClick={onClickReservation}
								classStrikethrough={room.hasOffers ? "tachado" : ""}
								textFrom={from}
								informations={rateDescriptions ?? undefined}
							/>
						</Modals>
					</div>

					{room && room.rate && (
						<div className="container-right">
							<BoxServiceRate
								classsThrough={room.hasOffers ? "trought" : ""}
								textFrom={from}
								hasOffers={room.hasOffers}
								offerSale={room.offerSale}
								rateDiscount={room.pricewithoutOffer}
								rate={room.rate ?? 0}
								rateWithoutTax={room.totalPerNightWithoutTaxe ? room.totalPerNightWithoutTaxe : 0}
								totalNetPerNight={room.totalNetAgencyPerNight ? room.totalNetAgencyPerNight : 0}
								currency={room.currency ?? undefined}
								total={room.total}
								informations={rateDescriptions ?? undefined}
								textButton={textButton ?? ""}
								onClick={onClickReservation}
							/>
						</div>
					)}
				</div>
			</Card.Body>
		</Card>
	);
}

export default CardHotelRoomLarge;
