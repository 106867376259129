import { useEffect } from "react"
import { useGlobalState } from "../store/StoreProvider";
const usePageMeta = (parameters) => {
    const { locale } = useGlobalState();

    useEffect(() => {

        if(parameters.isSuccess){
            document.title = parameters.title ?? document.title;
           (locale && document.querySelector("html")) &&  document.querySelector("html").setAttribute("lang", locale);    
           (parameters.follow &&  document.querySelector("meta[name='robots']")) &&  document.querySelector("meta[name='robots']").setAttribute("content", parameters.follow);
           (parameters.description && document.querySelector("meta[name='description']")) && document.querySelector("meta[name='description']").setAttribute("content", parameters.description);
           (parameters.keywords && document.querySelector("meta[name='keywords']")) && document.querySelector("meta[name='keywords']").setAttribute("content", parameters.keywords);
           (parameters.canonical && document.querySelector("link[rel='canonical']")) && document.querySelector("link[rel='canonical']").setAttribute("href", `${window.location.hostname}/${locale}${parameters.canonical === "/" ? '': parameters.canonical}`);
         
        }
 
    },[parameters, locale])
}

export default usePageMeta;