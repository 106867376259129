export default {
  "checkout.titlePresentation": "¡Falta poco! Completa tus datos y finaliza tu compra",
  "checkout.form.title": "¿Quién es el titular de la reserva?",
  "checkout.form.EditTitle": "Informacion del cliente",
  "checkout.form.RoomText": "Habitación",
  "checkout.form.step1Button": "Ver formas de pago",
  "checkout.form.step2Button": "Editar información",
  "checkout.form.subtitle": "Será responsable de la reserva de actividades, hacer el registro de llegada y salida en el hotel.",
  "checkout.form.mail": "¿A dónde enviamos la confirmación y notificaciones de tu reserva?",
  "checkout.form.phone": "El correo electrónico y teléfono son fundamentales para que gestiones tu reserva y recibas información importante sobre tu viaje.",
  "checkout.formStripe.title": "Ingresa los datos de tu tarjeta",
  "checkout.formStripe.submit": "Comprar",
  "checkout.formStripe.loading": "Cargando métodos de pago...",
  "checkout.formRules.errorCheckRate": "Caduco el precio de la habitación",
  "checkout.formRules.errorCheckRateButton": "Recotizar",
  "checkout.formRules.errorFraud": "Revisa tu correo o teléfono, que están escritos correctamente",
  "checkout.formRules.errorGeneralPhoneEmail": "Ups! ocurrio un error, revisa que sea un correo o teléfono válido",
  "checkout.formRules.errorGeneralForm": "Revisa que la información esté correctamente escrita",
   "checkout.formRules.paymentMethodDisabled": "Método de pago no disponible, por favor contacte al administrador",
    "checkout.formRules.warningDuplicateHolder":
    "Los nombres de los titulares no se pueden repetir, por favor introduce un nombre diferente en cada uno de los titulares de las habitaciones",
  "checkout.formRules.important": "Importante",
  "checkout.form.special": "Solicitudes especiales (Opcional)",
  "checkout.form.special.text": "Las solicitudes especiales o de accesibilidad no están garantizadas. Si no recibe noticias de la propiedad, es posible que desee comunicarse con ellos directamente para confirmar. La propiedad puede cobrar una tarifa por ciertas solicitudes especiales.",
  "checkout.titleImportantInformation": "Información importante",
  "checkout.numFlight": "Numero de vuelo",
  "checkout.form.data.transfer": "Datos del traslado",
  "checkout.terminal": "Terminal",
  "checkout.pickUp": "Pick up",
  "checkout.roomHolder": "Titular habitación",
  "checkout.cart.1": "{count , plural, one {Tu carrito  de compra expira en un # minuto } other  {Tu carrito  de compra expira en # minutos }}",
  "checkout.cart.2": "Tú sesión está por expirar",
  "checkout.cart.3": "Tú sesión expiro",
  "checkout.cart.4": "Debido a falta de actividad tú sesión ha expirado",
  "checkout.confirmButtonText": "Aceptar",
  "checkout.Continuar": "Continuar",
  "checkout.ticket.subtotal": "Subtotal",
  "checkout.cardPaxes.title": "Cargos adicionales",
  "checkout.cardPaxes.description": "Te los cobrará el alojamiento cuando llegues a destino.",
  "checkout.cardPaxes.information.title": "Impuestos no incluidos",
  "checkout.cardPaxes.information.description": "Total de cargos",
  "checkout.terms": "He leído y acepto las condiciones de compra, política de privacidad y política de cambios y cancelaciones.",
  "checkout.att": "¡Atención!",
  "checkout.booking": "Se produjo un error al procesar tu pago.  ",
  "checkout.try": "Intentar de nuevo",
  "checkout.confirmed": "Confirmado",
  "checkout.notConfirmed": "Pendiente por confirmar",
  "checkout.reservation.status": "Estado de la reserva",
  "checkout.summary.labelCheckRate": "Pago con tarifa pública",
  "checkout.summary.labelCheckRateFalse": "Pago con tarifa neta",
  "checkout.deleted": "Cancelado!",
  "checkout.file": "Tu archivo ha sido Cancelado.",
  "checkout.yes": "¡Sí!",
  "checkout.cancel": "Cancelar",
  "checkout.sure": "¿Seguro de que quieres cancelar?",
  "checkout.resend": "Enviado!",
  "checkout.resend.file": "Tu archivo ha sido Enviado.",
  "checkout.sureVoucher": "¿Estás seguro de que quieres volver a enviar el voucher?",
  "checkout.cancel.reservation": "Cancelar reserva",
  "checkout.resend": "Renviar voucher",
  "checkout.reservation": "Reservación",
  "checkout.cancelationLimit": "Limite de cancelación",
  "checkout.cancelationPayment": "Limite de pago",
  "checkout.airport": "Aeropuerto",
  "checkout.commodation": "Alojamiento",
  "checkout.startingPoint": "Punto de partida",
  "checkout.arrivalPoint": "Punto de llegada",
  "checkout.vehicleCapacity": "Capacidad del vehículo",
  "checkout.transferFor": "Traslado para",
  "checkout.person": "Persona",
	"checkout.clientReference": "Clave de reservación",
};
