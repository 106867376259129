import { Card } from "react-bootstrap";
import CardContent from "./components/CardContent";
import CardContainerImage from "../../../components/cards/CardContainerImage";
import "./components/cardService.scss";

function CardContainerServiceMap(props) {
	const { card, currency, txt, type, onClickClose } = props;
	const exclusiveDeal = card.promotions && card.promotions;
	const hasDiscount = card.hasOffers && card.offerSale > 0;
	return (
		<div className="card-container-map" qui="card hotel-list-map">
			<Card className="container-card-general">
				<CardContainerImage
					name={card.name}
					photos={card.photos}
					showDiscount={hasDiscount}
					discount={card.offerSale} />

				<Card.Body>
					<CardContent
						item={card}
						txt={txt}
						type={type}
						currency={currency}
						exclusiveDeal={card.hasPromotions ? exclusiveDeal.slice(0, 2) : []}
						onClickClose={() => onClickClose()}
					/>
				</Card.Body>
			</Card>
		</div >
	);
}
export default CardContainerServiceMap;