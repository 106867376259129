

const tourVoucherMock =
{
    "uuid": "fc4358d8-2763-489e-b303-35a66855d474",
    "hotel": {
        "id": "160960e6-a17c-4f91-961b-b0c081dedff8",
        "name": "Parque Xcaret",
        "checkin": "13:00",
        "checkout": "11:00",
        "city": "CANCUN",
        "country": "MX",
        "externalId": "160960e6-a17c-4f91-961b-b0c081dedff8",
        "gallery": [
            {
                "name": "Parque Xcaret",
                "photoUris": "https://www.xcaret.com/assets/xcaret/og/og.jpg",
            }
        ],
        "rooms": [
            {
                "id": "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-4B2B-RO",
                "externalId": "DBL.ST",
                "name": "Double Standard",
                "hasCancellationPolicies": true,
                "cancellationPolicies": [
                    "Puedes cancelar hasta el 20/12/2023 a las 01:00 p. m."
                ],
                "isRefundable": false,
                "refundable": "Tarifa reembolsable",
                "photos": [
                    {
                        "name": "Parque Xcaret",
                        "photoUris": "/static/media/defaultImage.7bac87981efbee468be1d8813a09948c.svg"
                    }
                ],
                "amenityBasic": {
                    "hasWifiFree": true,
                    "hasAA": false,
                    "hasPhone": false
                },
                "amenities": [
                    {
                        "name": "Baño",
                        "items": [
                            "Artículos de tocador ecológicos",
                            "Amenidades de baño gratuitas",
                            "Secadora de cabello",
                            "Baño privado",
                            "Tina y regadera combinadas"
                        ]
                    },
                    {
                        "name": "Habitación",
                        "items": [
                            "Ropa de Cama",
                            "Cortinas blackout",
                            "Ventilador de techo",
                            "Aire acondicionado con control",
                            "Cunas gratis",
                            "Colchón con pillow-top",
                            "Ropa de cama premium"
                        ]
                    },
                    {
                        "name": "Entretenimiento",
                        "items": [
                            "Reproductor de DVD",
                            "Televisión pantalla plana",
                            "Canales vía satélite"
                        ]
                    },
                    {
                        "name": "Alimentos y bebidas",
                        "items": [
                            "Servicio a la habitación las 24 horas",
                            "Cafetera y tetera",
                            "Agua empotellada gratis",
                            "Microoondas"
                        ]
                    },
                    {
                        "name": "Internet",
                        "items": [
                            "Wifi gratuito"
                        ]
                    },
                    {
                        "name": "Más servicios",
                        "items": [
                            "Servicio a la habitación las 24 horas",
                            "Cafetera y tetera",
                            "Agua empotellada gratis",
                            "Microoondas"
                        ]
                    },
                    {
                        "name": "Exterior",
                        "items": [
                            "Balcón"
                        ]
                    }
                ],
                "mealPlan": {
                    "code": "Room Only",
                    "name": "Room Only"
                },
                "upgradeRate": 0,
                "rate": 1249,
                "total": 3746,
                "taxes": 90.00220546323497,
                "rateKeyLead": "2023-02-14|2023-02-17|2|160960e6-a17c-4f91-961b-b0c081dedff8|HotelBeds|DBL.ST|BOOKABLE|RoomOnly|NOR|RO|MXN|CGW-4B2B-RO|3655.54|MX|638055992178434299;173A4C50C71725A4A7B95F9600968C9E",
                "currency": "MXN",
                "view": "",
                "hasOffers": false,
                "offerSale": "",
                "pricewithoutOffer": null
            }
        ],
        "stars": "Three"
    },
    "services": [
        {
            "type": "Hotel",
            "id": "160960e6-a17c-4f91-961b-b0c081dedff8",
            "name": "Parque Xcaret",
            "checkin": "13:00",
            "city": "CANCUN",
            "country": "MX",
            "externalId": "160960e6-a17c-4f91-961b-b0c081dedff8",
            "gallery": [
                {
                    "name": "Parque Xcaret",
                    "photoUris": "https://www.xcaret.com/assets/xcaret/og/og.jpg",
                }
            ],
            "rooms": [
                {
                    "id": "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-4B2B-RO",
                    "externalId": "DBL.ST",
                    "name": "Double Standard",
                    "hasCancellationPolicies": true,
                    "cancellationPolicies": [
                        "Puedes cancelar hasta el 10/02/2023 a las 04:59 a. m."
                    ],
                    "isRefundable": false,
                    "refundable": "Tarifa reembolsable",
                    "photos": [
                        {
                            "name": "Parque Xcaret",
                            "photoUris": "/static/media/defaultImage.7bac87981efbee468be1d8813a09948c.svg"
                        }
                    ],
                    "amenityBasic": {
                        "hasWifiFree": true,
                        "hasAA": false,
                        "hasPhone": false
                    },
                    "amenities": [
                        {
                            "name": "Baño",
                            "items": [
                                "Artículos de tocador ecológicos",
                                "Amenidades de baño gratuitas",
                                "Secadora de cabello",
                                "Baño privado",
                                "Tina y regadera combinadas"
                            ]
                        },
                        {
                            "name": "Habitación",
                            "items": [
                                "Ropa de Cama",
                                "Cortinas blackout",
                                "Ventilador de techo",
                                "Aire acondicionado con control",
                                "Cunas gratis",
                                "Colchón con pillow-top",
                                "Ropa de cama premium"
                            ]
                        },
                        {
                            "name": "Entretenimiento",
                            "items": [
                                "Reproductor de DVD",
                                "Televisión pantalla plana",
                                "Canales vía satélite"
                            ]
                        },
                        {
                            "name": "Alimentos y bebidas",
                            "items": [
                                "Servicio a la habitación las 24 horas",
                                "Cafetera y tetera",
                                "Agua empotellada gratis",
                                "Microoondas"
                            ]
                        },
                        {
                            "name": "Internet",
                            "items": [
                                "Wifi gratuito"
                            ]
                        },
                        {
                            "name": "Más servicios",
                            "items": [
                                "Servicio a la habitación las 24 horas",
                                "Cafetera y tetera",
                                "Agua empotellada gratis",
                                "Microoondas"
                            ]
                        },
                        {
                            "name": "Exterior",
                            "items": [
                                "Balcón"
                            ]
                        }
                    ],
                    "mealPlan": {
                        "code": "Room Only",
                        "name": "Room Only"
                    },
                    "upgradeRate": 0,
                    "rate": 1249,
                    "total": 2458,
                    "taxes": 90.00220546323497,
                    "rateKeyLead": "2023-02-14|2023-02-17|2|160960e6-a17c-4f91-961b-b0c081dedff8|HotelBeds|DBL.ST|BOOKABLE|RoomOnly|NOR|RO|MXN|CGW-4B2B-RO|3655.54|MX|638055992178434299;173A4C50C71725A4A7B95F9600968C9E",
                    "currency": "MXN",
                    "view": "",
                    "hasOffers": false,
                    "offerSale": "",
                    "pricewithoutOffer": null
                }
            ],
            "stars": "Three"
        }
    ],
    "booking": {
        "inputAutoValueStart": {
            "label": "Parque Xcaret",
            "keyword": "160960e6-a17c-4f91-961b-b0c081dedff8",
            "type": "Hotel"
        },
        "rooms": [
            {
                "adult": 2,
                "child": 0,
                "infant": 0
            }
        ],
        "startDate": "2023-02-14",
        "endDate": "2023-02-17",
        "type": "Hotel"
    },
    "clientReference": {
        "reference": 24,
        "email": "wendy.saules@mzpsolutions.com"
    },
    "type": "VOUCHER"
}

export default tourVoucherMock;