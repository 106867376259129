import BookingInformation from "../BookingInformation/BookingInformation";
import { CloseButton, Container } from "react-bootstrap";
import BookingEnginePackage from "../BookingEngine/BookingEnginePackage";
import BookingEngineTour from "../BookingEngine/BookingEngineTour";
import BookingEngineTransfer from "../BookingEngine/BookingEngineTransfer";
import SearchBox from "@mzp/components/dist/components/surfaces/SearchBox";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import hotelsValidate from "../../../validate/hotelsValidate";
import { useNavigate, useSearchParams } from "react-router-dom";
import serviceType from "../../../constants/serviceTypeConstant";
import iconUtils from "@mzp/commons/dist/utils/iconUtils";
import iconContants from "@mzp/commons/dist/constants/iconContants";
import { useIntl } from "react-intl";
import searchBookingUtils from "../../../utils/searchBookingUtils";
import { useDispatch, useGlobalState } from "../../../store/StoreProvider";
import searchHotelAction from "../../../actions/searchHotelAction";
import { useDebounce } from "../../../hooks/useDebounce";

const BoxBooking = (props) => {
	const {
		params,
		type,
		title,
		searchHotelParams,
		componentTitle,
		closeColorButton,
		viewBooking,
		isEditable,
		isDetailPage,
		getCardsDetail,
		hideBtn,
		bodyRenderScroll
	} = props;
	const {
		locale,
		authSession,
		resourceSite: resourceSiteProps,
	} = useGlobalState();
	const resourceSite = resourceSiteProps.options;
	const dispatch = useDispatch();
	const intl = useIntl();
	let navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [view, setView] = useState(viewBooking);
	const { minTextAPI, timeDelay } = searchHotelParams.paramsAutocomplete;
	const [search, setSearch] = useState({
		value: '',
		onSuccess: null
	});
	const debouncedFromText = useDebounce(search.value, timeDelay);

	useEffect(() => {
		const { onSuccess } = search;
		if (!debouncedFromText || debouncedFromText.length < minTextAPI) {
			if (onSuccess && onSuccess !== null) onSuccess({ items: [] });
			return;
		}

		searchHotelAction.search(
			{ keyword: debouncedFromText, locale, authSession },
			dispatch,
			onSuccess,
			(error) => console.error(error)
		);
	}, [debouncedFromText]);

	const onSubmit = (response) => {
		const { siteRoutes, onChangeBooking } = props;
		if (type === serviceType.HOTEL) {
			const localizedUrlHotelDetails = `/${locale}${siteRoutes.hotel.pathname}`;
			const localizedUrlHotelList = `/${locale}${siteRoutes.hotels.pathname}`;
			if (!response?.destination) response.destination = searchParams.get("destination");
			if (!response?.destinationkeyword) response.destinationkeyword = searchParams.get("destinationkeyword");
			//TODO type hotel default
			const query = hotelsValidate.paramsListforQuery(response);
			if (response.inputAutoValueStart?.type === "Hotel" && params.inputAutoValueStart?.type === "HotelDetails") {
				const keyname = `${hotelsValidate.convertStringToKeyname(response.inputAutoValueStart.label)}/`
				const location = {
					pathname: `${localizedUrlHotelDetails}${keyname}${response.inputAutoValueStart.keyword}`,
					search: `?${new URLSearchParams(query).toString()}`,
				};
				navigate(location);
				window.scrollTo(0, 0);
				onChangeBooking && onChangeBooking(response);
			} else {
				const location = {
					pathname: localizedUrlHotelList,
					search: `?${new URLSearchParams(query).toString()}`,
				};
				navigate(location);
				window.scrollTo(0, 0);
				onChangeBooking && onChangeBooking(response);
			}
		} else if (type === serviceType.TOUR) {
			if (isDetailPage) {
				getCardsDetail && getCardsDetail(response);
			} else {
				const localizedUrlTourList = `/${locale}${siteRoutes.tours.pathname}`;
				const location = {
					pathname: localizedUrlTourList,
					search: `?checkin=2023-04-05&destination=Cancun&destinationkeyword=36a7ba94-d32a-48e6-a139-5bc22ef49df1&paxes=2-0`,
				};
				navigate(location);
				window.scrollTo(0, 0);
				onChangeBooking && onChangeBooking(response);
			}
		} else if (type === serviceType.TRANSFER) {
			const localizedUrlShutterList = `/${locale}${siteRoutes.transfer.pathname}`;
			const location = {
				pathname: localizedUrlShutterList,
				search: `?checkin=2022-12-03&checkout=2022-12-05&destination=Aeropuerto%20internacional%20de%20Cancún&destinationkeyword=36a7ba94-d32a-48e6-a139-5bc22ef49df1&paxes=2-0&arrival=Hotel%20Xcaret%20México`,
			};
			navigate(location);
			window.scrollTo(0, 0);
			onChangeBooking && onChangeBooking(response);
		}
		setView(viewBooking);
	};

	const onClickEdit = () => setView(!view);
	const onChangeAutocomplete = (value, onSuccess) => setSearch({ value, onSuccess });

	useEffect(() => {
		setView(viewBooking);
	}, [viewBooking]);

	const itemTemplate = (item) => {
		return (
			<div className="country-item d-flex">
				{item && (
					<div className="me-2">
						{item.type === "Destination" &&
							iconUtils.icon(iconContants.LOCATION)}
						{item.type === "Hotel" && iconUtils.icon(iconContants.HOTEL)}
						{item.type === "Tour" && iconUtils.icon(iconContants.ACTIVITY)}
					</div>
				)}
				<div>{item.name}</div>
			</div>
		);
	};
	return (
		<>
			{view ? (
				<>
					{isEditable && (
						<Container fluid>
							<div className={`text-end ${bodyRenderScroll ? "text-end-fixed" : ""}`} >
								<CloseButton
									aria-label="Hide"
									variant={closeColorButton}
									onClick={onClickEdit}
								/>
							</div>
						</Container>
					)}

					{type === serviceType.HOTEL ? (
						<SearchBox
							background={false}
							paramsFrontProps={params}
							type={type}
							locale={locale}
							autocompleteProps={{
								active: true,
								single: true,
								icon: iconContants.LOCATION,
								itemTemplate: itemTemplate,
								placeholder: intl.formatMessage({id: "general.placeholderAutocomplete"}),
								parameters: searchBookingUtils.getParamsAutocompleteService(
									type,
									resourceSite
								),
							}}
							calendarProps={{
								active: true,
								placeholder: "-- - --",
								rangeDate: true,
								parameters: searchBookingUtils.getParamsCalendarService(
									type,
									resourceSite
								),
							}}
							roomProps={{
								active: true,
								icon: iconContants.BSFILLPERSONFILL,
								parameters: searchBookingUtils.getParamsRoomsService(
									type,
									resourceSite
								),
							}}
							buttonProps={{
								text: intl.formatMessage({ id: "button.search" }),
								icon: iconContants.SEARCH,
							}}
							onSubmit={onSubmit}
							onChangeAutocomplete={onChangeAutocomplete}
						/>
					) : type === serviceType.PACKAGE || type === serviceType.FLIGHT ? (
						<BookingEnginePackage
							onlyBooking={true}
							title={title}
							params={params}
							locale={locale}
							type={type}
							componentTitle={componentTitle}
							paramsRooms={searchHotelParams.paramsRoom}
							paramsAutocomplete={searchHotelParams.paramsAutocomplete}
							paramsCalendar={searchHotelParams.paramsCalendar}
							onSubmit={onSubmit}
							isEditable={isEditable}
							isDetailPage={isDetailPage}
						/>
					) : type === serviceType.TOUR ? (
						<BookingEngineTour
							onlyBooking={true}
							title={title}
							params={params}
							locale={locale}
							type={type}
							componentTitle={componentTitle}
							paramsRooms={searchHotelParams.paramsRoom}
							paramsAutocomplete={searchHotelParams.paramsAutocomplete}
							paramsCalendar={searchHotelParams.paramsCalendar}
							onSubmit={onSubmit}
							isEditable={isEditable}
							isDetailPage={isDetailPage}
						/>
					) : (
						<BookingEngineTransfer
							onlyBooking={true}
							title={title}
							params={params}
							locale={locale}
							type={type}
							componentTitle={componentTitle}
							paramsRooms={searchHotelParams.paramsRoom}
							paramsAutocomplete={searchHotelParams.paramsAutocomplete}
							paramsCalendar={searchHotelParams.paramsCalendar}
							onSubmit={onSubmit}
							isEditable={isEditable}
							isDetailPage={isDetailPage}
						/>
					)}
				</>
			) : (
				<BookingInformation
					bodyRenderScroll={bodyRenderScroll}
					hideBtn={hideBtn}
					type={type}
					locale={locale}
					params={params}
					onClick={onClickEdit}
				/>
			)}{" "}
		</>
	);
};

BoxBooking.propTypes = {
	type: PropTypes.any.isRequired,
	params: PropTypes.object.isRequired,
	viewBooking: PropTypes.bool,
	searchHotelParams: PropTypes.object.isRequired,
};

BoxBooking.defaultProps = {
	closeColorButton: "dark",
	viewBooking: false,
	isEditable: true,
	isDetailPage: false,
};

export default BoxBooking;
