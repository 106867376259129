//import Footer from "../../components/footers/Footer/Footer";
import Footer from "@mzp/commons/dist/footers/Footer";
import { useGlobalState } from "../../store/StoreProvider";

const FooterLayout = (props) => {
    const { footerInformation, ...rest } = props;
    const {locale} = useGlobalState();

    return <Footer locale={locale}
        footer={footerInformation}
        {...rest}
    />;
}

export default FooterLayout;