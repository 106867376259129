export default {
    "homePage.title" : "Los mejores hoteles alrededor del mundo.",
    "homePage.titleDestinations" : "Top Destinos",
    "homePage.attentionATitle" : "Atención personalizada",
    "homePage.attentionADescription" : "Servicio personal antes y durante tu viaje.",
    "homePage.attentionBTitle" : "Productos",
    "homePage.attentionBDescription" : "Elige entre un amplio abaníco de opciones.",
    "homePage.attentionCTitle" : "Seguridad",
    "homePage.attentionCDescription" : "Realiza tu reserva con garantía de precio y servicio.",
    "homePage.tours.title" : "Actividades que te van a encantar",
    "homePage.tours.transfer" : "Traslados que te van a encantar.",
    "homePage.packages.title" : "Los mejores paquetes alrededor del mundo.",
}