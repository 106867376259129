import { useIntl } from "react-intl";
import dateUtils from "../../../../utils/dateUtils";
const PoliticsSection = (props) => {
  const { itinerary, locale } = props;
  const hotelBooking = itinerary.hotelBookings[0];
  const intl = useIntl();

  const ValidCancellationDeadline = () => {

    if (hotelBooking?.isRefundable) {

      const cancellationDeadline = dateUtils.dateFormat(
        hotelBooking.cancellationDeadline,
        "DD/MMM/YYYY",
        locale.split("-")[0]
      );
  
      const message =
        intl.formatMessage({
          id: "voucherPage.cancellationDeadline",
        }) +
        " " +
        cancellationDeadline;
      return message;
    } else {
      return intl.formatMessage({
        id: "voucherPage.cancellationDeadlineMessage",
      });
    }
  };

  return (
    <>
      {hotelBooking && hotelBooking.cancellationDeadline && (
        <>
          {" "}
          <br></br>
          <div className="politicas">
            <div className="container-subtitle">
              <h2 className="text-subtitle">
                {intl.formatMessage({
                  id: "voucherPage.titlePolitics",
                })}
              </h2>
            </div>
            <p>
            {ValidCancellationDeadline()}
            </p>
          </div>
        </>
      )}
    </>
  );
};
export default PoliticsSection;
