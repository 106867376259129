import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useGlobalState } from "../../../store/StoreProvider";
import { keyByValue } from "../../../utils/constUtils";
import dateUtils from "../../../utils/dateUtils";
import STATUSCODE from "../../../constants/statusCode";
import BOOKSTATUS from "../../../constants/bookStatus";
import storage from "../../../localstorage/storage";
import LabelDouble from "../components/LabelDouble";
import "./style.scss";

const VoucherStatic = (props) => {
	const intl = useIntl();
	const { locale,resourceSite } = useGlobalState();
	const MINUTES_REDIRECT_HOME = 2;
	const localizedUrlHome =  `${resourceSite.options.home.href}` ?? `/${locale}`;
	
	const [searchParams] = useSearchParams();
	const query = { cart: searchParams.get("cart") };
	const bookResult = storage.getLocalStorage(`static-voucher-${query.cart}`);
	let { statusCode, clientReference, bookStatus, redirect = true } = props;

	if (bookResult) {
		statusCode = bookResult.statusCode;
		clientReference = bookResult.clientReference;
		bookStatus = bookResult.status;
	}
	const onClickRedirect = () => {
		const location = { pathname: localizedUrlHome };
		window.scrollTo(0, 0);
		window.location.href = location.pathname;
	};

	useEffect(() => {
		if (redirect) {
			const timerModal = setTimeout(() => {
				onClickRedirect();
			}, dateUtils.minutesToSecondsDate(MINUTES_REDIRECT_HOME));
			return () => clearTimeout(timerModal);
		}
	}, []);

	const idBookStatus = bookStatus ? (keyByValue(BOOKSTATUS, bookStatus) ?? null) : null;
	const bookStatusText = (idBookStatus ? intl.formatMessage({ id: `book.status.${idBookStatus.toLowerCase()}` }) : null);

	let title = "", subtitle = "", text = "";
	const code = Number.isInteger(statusCode) ? statusCode : STATUSCODE[statusCode?.toUpperCase()];
	switch (code) {
		case STATUSCODE.PAYMENTNOTFOUND:
			text = intl.formatMessage({ id: "voucherStatic.PaymentNotFound" });
			break;
		case STATUSCODE.PAYMENTNOTVALID:
			text = intl.formatMessage({ id: "voucherStatic.PaymentNotValid" });
			break;
		case STATUSCODE.BOOKINGERROR:
			text = intl.formatMessage({ id: "voucherStatic.BookingError" });
			break;
		case STATUSCODE.PAIDANDBOOKINGNOTCONFIRMED:
			text = intl.formatMessage({ id: "voucherStatic.PaidAndNotConfirmed" });
			break;
		case STATUSCODE.BOOKINGNOFOUND:
			text = intl.formatMessage({ id: "voucherStatic.BookNotFound" });
			break;
		default:
			text = intl.formatMessage({ id: "voucherStatic.InternalServerError" });
			break;
	}

	let classBookStatus = "text-warning";
	if (idBookStatus) {
		switch (BOOKSTATUS[idBookStatus]) {
			case BOOKSTATUS.CONFIRMED:
				classBookStatus = "text-success";
				break;
			case BOOKSTATUS.CANCELLED:
				classBookStatus = "text-error";
				break;
			case BOOKSTATUS.HOLD:
				classBookStatus = "text-info";
				break;
		}
	}

	const showClientReference = (clientReference !== null && clientReference > 0);
	return (
		<Container className="con-Vstatic" qui="con voucher-static">
			<div className="div-Vstatic">
				<div className="content">
					{title && (<h1 className="title">{title}</h1>)}
					{(bookStatus || showClientReference) && (<div className="container-reference d-flex flex-row">
						{bookStatus && <LabelDouble
							title={intl.formatMessage({ id: "checkout.reservation.status" })}
							classText={classBookStatus}
							text={bookStatusText} />}
						{showClientReference && <LabelDouble
							title={intl.formatMessage({ id: "checkout.clientReference" })}
							text={clientReference}
							classTitle="align-items-end" />}
					</div>)}
					{subtitle && (<p className="subtitle">{subtitle}</p>)}
					{text && (<div className="text" dangerouslySetInnerHTML={{ __html: text }} />)}
				</div>
				<Button
					onClick={onClickRedirect}
					className="text-white d-block m-auto btn-voucher">
					<span>{intl.formatMessage({ id: "button.home" })} </span>
				</Button>
			</div>
		</Container>
	);
};

export default VoucherStatic;
