
import SlidersComponent from "@mzp/commons/dist/layout/Sliders";
import breakpointConstants from "@mzp/commons/dist/constants/statusConstant";
import { useGlobalState } from "../../store/StoreProvider";
import CardTopDestinationsComponent from "@mzp/commons/dist/surfaces/CardTopDestinations";

const SlideTopDestinationSection = (props) => {
    const { options } = props;
    const {locale} = useGlobalState();
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 1000,
        arrows: false,
        variableWidth: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive:[
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                variableWidth: true,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                variableWidth: true,
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                variableWidth: true
              }
            },
          ]
    }
    return (<section className="top-destination-section">
        <SlidersComponent settings={settings}{...options}>
            <CardTopDestinationsComponent
                classTopDestination='top-destination-align-center card-con-img'
                settings={settings}
                locale={locale}
            />
        </SlidersComponent>
    </section>);
}

export default SlideTopDestinationSection;