export default {
	"voucherPage.titleHotelSucces": "Reservation summary",
	"voucherPage.titleTourSucces": "Tour",
	"voucherPage.titleError": "Loading...",
	"voucherPage.room": "Room",
	"voucherPage.rooms": "Rooms",
	"voucherPage.referenceRoom": "Reference",
	"voucherPage.address": "Address",
	"voucherPage.reference": "Reservation",
	"voucherPage.date": "Reservation date",
	"voucherPage.mealPlan": "Meal Plan",
	"voucherPage.checkIn": "CheckIn",
	"voucherPage.checkOut": "CheckOut",
	"voucherPage.name": "Name",
	"voucherPage.adults": "Adults",
	"voucherPage.childrens": "Childrens",
	"voucherPage.titlePolitics": "Cancellation policies",
	"voucherPage.amountPenalty": "Penalty price",
	"voucherPage.datePenalty": "Cancellation valid until",
	"voucherPage.titleIncluid": "That contains?",
	"voucherPage.pay": "Pay",
	"voucherPage.publicRate": "Total amount",
	"voucherPage.netRate": "Total net amount",
	"voucherPage.titleRateComents": "Rate Comments",
	"voucherPage.titleAminities": "Room amenities",
	"voucherPage.year": "Year",
	"voucherPage.years": "Years",
	"voucherPage.methodPay": "Payment method",
	"voucherPage.creditCard": "Credit card",
	"voucherPage.policyNotes": "Note: Policies provided for at the time and date of the destination.",
	"voucherPage.form.special": "Special requests",
	"voucherPage.detaild": "Room detail",
	"voucherPage.reservationHolder": "Reservation Holder",
	"voucherPage.SupplierNote": "Payable through #SupplierName #VatNumber, acting as agent with respect to the company operating the Service. Details of the above can be provided upon request.",
	"voucherPage.HotelPhoneNumber": "Hotel phone",
	"voucherPage.title": "Voucher",
	"voucherPage.cancellationDeadline": "You can cancel before",
	"voucherPage.cancellationDeadlineMessage": "You can´t cancel or make changes",
	"voucherStatic.title": "Your reservation has been delayed",
	"voucherStatic.subTitle": "We apologize for the delay and thank you for your patience.",
	"voucherStatic.text": "Reservations are taking longer than usual because we are receiving a large number of new reservations. It should not take more than 24 hrs to process your reservation, we will contact you as soon as there is an update. ",
	"voucherStatic.PaymentNotFound": `<p>Dear Customer,</p>
		<p>We inform you that we are experiencing difficulties in retrieving the payment information. You don't need to make the reservation again.</p>
		<p>We apologize for any inconvenience. We are working on the immediate solution, we will send you your confirmation shortly.</p>
		<p>We appreciate your understanding and patience in this matter.</p>`,
	"voucherStatic.PaymentNotValid": `<p>Dear Customer,</p>
		<p>We want to inform you that we have received your reservation successfully. However, we want to inform you that after a verification process by our payment service provider, we have received notification that the payment corresponding to your reservation has been rejected.</p>
		<p>If you want to continue with your purchase, we recommend trying to pay with another payment method.</p>
		<p>We appreciate your understanding and patience in this matter.</p>`,
	"voucherStatic.BookingError": `<p>Dear Customer,</p>
		<p>We inform you that your payment was processed successfully, but we are having difficulties generating your confirmation. No need to rebook.</p>
		<p>We apologize for any inconvenience. We are working on the immediate solution, we will send you your confirmation shortly.</p>
		<p>We appreciate your understanding and patience in this matter.</p>`,
	"voucherStatic.PaidAndNotConfirmed": `<p>Dear client,</p>
		<p>We inform you that your reservation has been successfully processed and the payment has been completed. We are having difficulty showing you the confirmation.</p>
		<p>We apologize for any inconvenience. We are working on the immediate solution, we will send you your confirmation shortly.</p>
		<p>We appreciate your understanding and patience in this matter.</p>`,
	"voucherStatic.BookNotFound": `<p>Dear client,</p>
		<p>We inform you that your reservation has been successfully processed and the payment has been completed. We are having difficulty showing you the confirmation.</p>
		<p>We apologize for any inconvenience. We are working on the immediate solution, we will send you your confirmation shortly.</p>
		<p>We appreciate your understanding and patience in this matter.</p>`,
	"voucherStatic.InternalServerError": `<p>Dear Customer,</p>
		<p>We want to thank you for choosing our services for your purchase. However, we want to inform you that we experienced an unexpected problem during the processing of your reservation.</p>
		<p>We apologize for any inconvenience. We are working on an immediate solution.</p>
		<p>We appreciate your understanding and patience in this matter.</p>`
};