import { useGlobalState } from "../../store/StoreProvider";
import useAuthSession from "../../hooks/useAuthSession";
import SkeletonPage from "../skeleton/SkeletonPage";
import ErrorPage from "../../pages/errorPage/ErrorPage";

const AuthSession = (props) => {
  const { component: Component, propsPage } = props;
  const { component: ChildrenPage, ...restLayout } = propsPage;
  const { authSession, resourceSite } = useGlobalState();

  useAuthSession();

 const errorType = resourceSite.options.errorType500;

  return (
    <>
      {authSession.isFetching && <SkeletonPage />}
      {authSession.isError && (
        <ErrorPage
          type={errorType.type}
          title={errorType.title}
          text={errorType.text}
          btn={errorType.btn}
        />
      )}
      {authSession.isSuccess && (
        <Component component={ChildrenPage} {...restLayout} />
      )}
    </>
  );
};

export default AuthSession;
