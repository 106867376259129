import { useRef, useState } from 'react';
import dayjs from "dayjs";
import { useMediaQuery } from 'react-responsive'
import { DateRange } from "react-date-range";
import { addDays, isEqual } from "date-fns";
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';
import dateUtils from '../../../../utils/dateUtils';
import * as locales from "react-date-range/dist/locale";
import serviceType from '../../../../constants/serviceTypeConstant';
import Modals from '../../../modals/Modals';
import breakpointConstants from "../../../../constants/breakpointConstants";
import iconUtils from '../../../../utils/iconUtils';
import iconContants from '../../../../constants/iconContants';
import PropTypes from "prop-types";

  const Calendar = (props) => {
    const {startDate, endDate, months, locale, type} = props;
    const [maxDate, setMaxDate] = useState(undefined);
    const target = useRef(null);
    const [state, setState] = useState([
        {
          startDate: dateUtils.valiteDateFormat(startDate) && dateUtils.valiteDateFormat(endDate) ? dateUtils.getDateforFormat(startDate) : new Date(),
          endDate: dateUtils.valiteDateFormat(endDate) && dateUtils.valiteDateFormat(endDate) ? dateUtils.getDateforFormat(endDate) : new Date(),
          key: "selection",
          color: "purple",
          showDateDisplay: false,
        }
    ]);
    const isMobile = useMediaQuery( { maxWidth: breakpointConstants.SM })
    const [fullscreen, setFullscreen] = useState(true);
    const [showModal, setShowModal] = useState(false);
    
    const changeMaxDate = (startDate) => {
      const {maxDays} = props;
      setMaxDate(addDays(startDate, maxDays - 1))
    }

    const changeDate = (newDate) => {
      return dayjs(newDate).locale(locale).format("DD MMM YYYY")
    }

    const chageTextValue = () => {
      const {placeholder} = props;
        return !isEqual(state[0].startDate, state[0].endDate)
          ? type === serviceType.TOUR
            ? `${changeDate(state[0].startDate)}`
            : `${changeDate(state[0].startDate)} - ${changeDate(state[0].endDate)}`
            : placeholder;
    }    

    const [textValue, setTextValue] = useState(chageTextValue());
    
    const handleShowModal = (breakpoint) => {
      setFullscreen(breakpoint);  
      setShowModal(isMobile);        
    }
    
    const handleCloseModal = () =>   setShowModal(false); 
    const targetHover = () =>  setShowModal(false);

    const closeBtn = () => {
      handleShowModal(true);
      setMaxDate(undefined);
    }  
      
    const popover = (
    <Popover id="popover-calendar" 
    className='popover-calendar-button'>
      <Popover.Body>
        <DateRange
        onChange={(item) => {
          const {onChage} = props;
          setState([item.selection]);
            if(!isEqual(item.selection.startDate, item.selection.endDate)) {
              setTextValue( type === serviceType.TOUR
              ? `${changeDate(item.selection.startDate)}`
              : `${changeDate(item.selection.startDate)} - ${changeDate(item.selection.endDate)}`);
            if(onChage) { 
              onChage({ startDate: dateUtils.getFormattedDate(item.selection.startDate),
                        endDate: dateUtils.getFormattedDate(item.selection.endDate)
                     })
            }
            setShowModal(false); 
            target.current.click();
            targetHover();
          }
          else{
            changeMaxDate(item.selection.startDate)}
          }}
          retainEndDateOnFirstSelection={false}
          ranges={state}
          maxDate={maxDate}
          months={months}
          locale={locales[locale?.toLowerCase().split('-')[0]]}
          weekStartsOn={1}
          showMonthAndYearPickers={false}
          showMonthArrow={true}
          showPreview={true}
          weekdayDisplayFormat={"EEEEE"}
          monthDisplayFormat={"MMMM yyyy"}
          direction="horizontal"
          />
      </Popover.Body>
    </Popover>
    );

    return ( <>                                              
    <OverlayTrigger 
    trigger={"click"}
    placement="bottom" 
    target={target.current}
    overlay = { !isMobile ?  popover : <></> } 
    rootClose>
        <Button 
        ref={target}
        variant="Light" 
        className='calendar-button-render-input w-100' 
        onClick={ closeBtn } >
            {iconUtils.icon(iconContants.CALENDAR)}
            <span > {`${textValue}`}</span>
        </Button>
    </OverlayTrigger>
    <Modals 
    fullscreen={fullscreen} 
    show={ isMobile && showModal  } 
    showClose={false} 
    handleCloseModal={handleCloseModal}> 
    <div className='calendar-preview'>
        <span>{`${textValue}`}</span>
    </div>
    <div className='calendar-modal'>
        {popover}
    </div>
    </Modals >              
    </> 
    );

  }
  Calendar.defaultProps = {
    maxDays: 30,
    months: 2,
    locale: "es-mx"
  }
  export default Calendar;
