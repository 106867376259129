import iconUtils from "@mzp/commons/dist/utils/iconUtils";
import iconContants from "@mzp/commons/dist/constants/iconContants";

const headerComponentEN = {
    showLanguage: true,
    showCurrency: true,
    showMenu: true,
    showMenuMobile: true,
    orderLogo: "0",
    orderMenu: "1",
    orderSettings: "2",
    centerLogo: false,
    menuSlide: true,
    // cart: true, //TODO habilitar u ocultar carrito
    // user: true, //TODO habilitar u ocultar sesion usuario
    iconcart: iconUtils.icon(iconContants.BICART),
    iconUser: iconUtils.icon(iconContants.HIUSERCIRCLE),
    //textCart: "Cart",
    urlUser: "/user",
    urlCart: "/cart",
    iconMenu: iconUtils.icon(iconContants.CGMENUGRIDR),
    topinfo: false, //oculta el texto de call 
}

export default headerComponentEN;