// const RADIUS = 85445659.4471; //radio merge
// const OFFSET = 268435456; //compensar
const lonToX = (lon, OFFSET = 268435456, RADIUS = 85445659.4471) => {
	return Math.round(OFFSET + RADIUS * lon * Math.PI / 180);
}
const latToY = (lat, OFFSET = 268435456, RADIUS = 85445659.4471) => {
	return Math.round(OFFSET
		- RADIUS
		* Math.log((1 + Math.sin(lat * Math.PI / 180))
			/ (1 - Math.sin(lat * Math.PI / 180))) / 2);
}

const pixelDistance = (lat1, lon1, lat2, lon2, zoom, OFFSET = 268435456, RADIUS = 85445659.4471) => {
	const x1 = lonToX(lon1, OFFSET, RADIUS);
	const y1 = latToY(lat1, OFFSET, RADIUS);

	const x2 = lonToX(lon2, OFFSET, RADIUS);
	const y2 = latToY(lat2, OFFSET, RADIUS);

	return (Math.sqrt(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2))) >> (21 - zoom);
}

const getGluster = (markers, zoom, OFFSET = 268435456, RADIUS = 85445659.4471) => {
	let clusterList = [];
	let newMarkers = [...markers];
	let originalListCopy = [...markers];

	for (let i = 0; i < originalListCopy.length;) {
		const markerList = [];
		for (let j = i + 1; j < newMarkers.length;) {
			const pixelDistanceParam = pixelDistance(newMarkers[i].geoPosition.latitude,
				newMarkers[i].geoPosition.longitude, newMarkers[j].geoPosition.latitude, newMarkers[j].geoPosition.longitude,
				zoom, OFFSET, RADIUS);

			if (pixelDistanceParam < 40) {
				markerList.push(newMarkers[j]);
				newMarkers.splice(j, 1);
				originalListCopy.splice(j, 1);
				j = i + 1;
			}
			else { j++; }
		}

		if (markerList.length > 0) {
			markerList.push(newMarkers[i]);

			const cluster = {
				clusterList: clusterList.length,
				markerList,
				size: markerList.length,
				lat: originalListCopy[i].geoPosition.latitude,
				lng: originalListCopy[i].geoPosition.longitude,
			};
			clusterList.push(cluster);
			originalListCopy.splice(i, 1);
			newMarkers.splice(i, 1);
			i = 0;
		}
		else { i++; }
	}

	if (originalListCopy.length > 0) {
		const inCount = clusterList.length;
		clusterList = [...clusterList, ...originalListCopy.map((item, index) => {
			return {
				clusterList: inCount + index,
				markerList: [item],
				size: 1,
				lat: item.geoPosition.latitude,
				lng: item.geoPosition.longitude,
			}
		})];
	}

	return clusterList;
}

const getPointPosition = (map, latLng) => {
	const projection = map.getProjection();
	const bounds = map.getBounds();
	const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
	const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
	const scale = Math.pow(2, map.getZoom());
	const worldPoint = projection.fromLatLngToPoint(latLng);
	return {
		x: Math.floor((worldPoint.x - bottomLeft.x) * scale),
		y: Math.floor((worldPoint.y - topRight.y) * scale)
	}
}

const mapUtils = {
	getGluster,
	getPointPosition
}

export default mapUtils;