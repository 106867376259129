import { Container, Card, Row, Col } from "react-bootstrap";
import BoxTextBanner from "../boxes/BoxTextBanner/BoxTextBanner";
import "./components/cardsIconBasic.scss";
const CardsIconBasic = (props) => {
  const { options } = props;

  return (
    <section className="cards-iconBasic-section">
      <div className="py-5 bg-white">
        <Container fluid="xl">
          <BoxTextBanner options={options} />
          <Row>
            {options.items &&
              options.items.map((item, index) => {
                return (
                  <Col
                    key={index}
                    className="px-3 py-4 col-6 col-sm-6 col-md-3 col-xl-3 pb-0 mb-0"
                  >
                    <Card className="card-iconBasic">
                      <Card.Img loading="lazy"
                        className="image-iconBasic"
                        variant="top"
                        src={item.img}
                      />

                      <Card.Body>
                        <Card.Title className="title">
                          {item.text && item.text}{" "}
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default CardsIconBasic;
