import React from "react";
import iconUtils from "../../../utils/iconUtils";

const BoxIconText = (props) => {
  const { items, alignItems, ...rest } = props;

  return (
    items && items.length > 0 && (
      items.map((item, index) => {
        return (
          <div key={index}style={{justifyContent: alignItems}} className={`container-text-icon ${item.classContainer ? item.classContainer : ''}`} {...rest}>
            <div className="d-flex" style={{alignItems: "center"}}>
              {item.icon && (
                <div className={`container-icon ${item.classIcon ? item.classIcon : ''}`}>{iconUtils.icon(item.icon)}</div>
              )}
              <div className={`container-text ${item.classText ? item.classText : ''}`}>{item.text}</div>
            </div>            
          </div>
        );
      })
    )
  )
};

BoxIconText.defaultProps = {

}
export default BoxIconText;
