import types from "../constants/reducers/resourceSiteConstants";

// const get = (resourceSite) =>{
//     return {type: types.RESOURCE_SITE, resourceSite};
//  };
const get = (resourceSite) => {
    resourceSite.isFetching = false;
    resourceSite.isSuccess = true;
    resourceSite.isError = false;
    return { type: types.RESOURCE_SITE, resourceSite };
};

const update = async (resourceSite, dispatch) => {
    dispatch(get({
        options: resourceSite
    }));
}

const resourceSiteAction = {
    update
}

export default resourceSiteAction;