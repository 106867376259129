import { Image } from "react-bootstrap";
import { useIntl } from "react-intl";
import imageDefault from "../../../assets/img/defaultImage.svg";
import LabelDouble from "./LabelDouble";
import SideLabel from "./SideLabel";
import CategoryTypeGroup from "../../../components/boxes/BoxIcon/CategoryTypeGroup";
import dateUtils from "../../../utils/dateUtils";
import { useGlobalState, useDispatch } from "../../../store/StoreProvider";
// import VoucherRoomsSection from ""

const renderImage = (hotel, itinerary) => {
	return (
		<div className="container-img-perfil">
			{itinerary?.hotelPhoto  && (
				<img
					loading="lazy"
					alt={itinerary?.hotelName}
					src={itinerary?.hotelPhoto}
					className="voucher-img-circle"
					onError={({ currentTarget }) => {
						currentTarget.onerror = null;
						currentTarget.src = imageDefault;
					}}
				/>
			)}
		</div>
	);
};

const VoucherHeader = (props) => {
	const { hotel, itinerary } = props;
	const intl = useIntl();
	const { locale } = useGlobalState();
	return (
		<div className="voucher-card-header border-bottom-solid">
			{renderImage(hotel, itinerary)}
			<div className="d-flex w-100 container-rest-header">
				<div className="container-title-stars-checks">
					<div className="container-checkouts">
						<h2 className="title">{itinerary.hotelName}</h2>
						<CategoryTypeGroup
							num={itinerary.hotelStars}
							categoryCode={itinerary.hotelCategoryType}
							categoryString={itinerary.hotelCategory}
						/>
					</div>
					<div className="container-checkouts">
						<SideLabel
							title={intl.formatMessage({ id: "voucherPage.checkIn" })}

							text={dateUtils.dateFormat(itinerary.checkIn, null, locale)}
						/>
						<SideLabel
							title={intl.formatMessage({ id: "voucherPage.checkOut" })}
							text={dateUtils.dateFormat(itinerary.checkOut, null, locale)}
						/>
						<SideLabel
							title={intl.formatMessage({ id: "voucherPage.address" })}
							text={itinerary.hotelAddress}
						/>
						<SideLabel
							title={intl.formatMessage({
								id: "voucherPage.reservationHolder",
							})}
							text={`${itinerary.holderName + " " + itinerary.holderLastName}`}
							show={itinerary.isSucces && itinerary.holderName}
						/>
					</div>
				</div>
				<div className="container-reference">
					{itinerary.isSucces && (
						<>
							<LabelDouble
								title={intl.formatMessage({
									id: "checkout.reservation.status",
								})}
								show={itinerary.status == "confirmed"}
								classText="text-success"
								text={intl.formatMessage({ id: "checkout.confirmed" })}
							/>
							<LabelDouble
								title={intl.formatMessage({
									id: "checkout.reservation.status",
								})}
								show={itinerary.status == "pending"}
								classText="text-success"
								text={intl.formatMessage({
									id: "checkout.notConfirmed",
								})}
							/>
						</>
					)}

					<LabelDouble
						title={intl.formatMessage({ id: "voucherPage.reference" })}
						validation={itinerary.isSucces && itinerary.referenceBooking}
						text={itinerary.referenceBooking}
					/>
					{/* <LabelDouble
            title={intl.formatMessage({ id: "voucherPage.date" })}
            validation={itinerary.isSucces && itinerary.dateCreatedUtc}
            text={dateUtils.dateFormat(itinerary.dateCreatedUtc, null, locale)}
          /> */}
					<LabelDouble
						title={intl.formatMessage({
							id: "voucherPage.HotelPhoneNumber",
						})}
						show={itinerary.isSucces && itinerary.hotelPhoneNumber}
						text={itinerary.hotelPhoneNumber}
					/>
				</div>
			</div>
		</div>
	);
};

export default VoucherHeader;
