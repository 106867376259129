import iconContants from "../constants/iconContants";
import {
	FaHotel,
	FaTicketAlt,
	FaBusAlt,
	FaCalendarAlt,
	FaBed,
	FaConciergeBell,
	FaBriefcase,
	FaClock,
	FaGlassMartini,
	FaBlenderPhone,
	FaSnowflake,
	FaTags,
	FaLongArrowAltRight,
	FaListUl,
	FaDollarSign,
	FaFacebookF,
	FaTwitter,
	FaInstagram,
	FaYoutube,
	FaPinterest,
	FaLinkedin,
	FaTripadvisor,
	FaFilter
} from "react-icons/fa";
import {
	AiOutlineFire,
	AiOutlineMenu,
	AiOutlineWifi,
	AiFillTag,
	AiOutlineArrowRight,
	AiOutlineGlobal,
	AiOutlineCheck,
	AiOutlineEdit,
	AiOutlineRight,
	AiOutlineClose,
	AiOutlineExclamationCircle,
	AiFillCheckCircle,
	AiOutlineAppstore,
} from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import { RiStarSFill, RiFlightTakeoffLine, RiFlightLandLine, RiKeyFill } from "react-icons/ri";
import {
	BsFillPersonFill,
	BsFillBriefcaseFill,
	BsFillCheckCircleFill,
	BsTrash,
	BsClock,
	BsFillHandbagFill,
	BsFillBagFill,
	BsBagDashFill,
	BsFillQuestionCircleFill,
	BsExclamationTriangleFill,
	BsFillExclamationCircleFill,
	BsFillCartFill,
	BsImages,
	BsArrowLeftRight,
	BsArrowDownUp,
	BsGridFill,
	BsFillCalendar2CheckFill,
	BsFilter,
} from "react-icons/bs";
import {
	FiCheck, FiDollarSign,
} from "react-icons/fi";
import {
	GrAddCircle,
	GrSubtractCircle,
	GrAdd,
} from "react-icons/gr";
import {
	BiSearch,
	BiRestaurant,
	BiListUl
} from "react-icons/bi";
import {
	GoArrowRight,
	GoArrowLeft,
	GoPerson,
	GoAlert,
} from "react-icons/go";
import {
	IoIosArrowDown,
	IoIosArrowUp,
} from "react-icons/io";
import {
	ImPencil,
} from "react-icons/im";

import {
	IoGridSharp,
	IoListSharp,
	IoLocationSharp
} from "react-icons/io5";
import { SlArrowRight } from "react-icons/sl"
//TODO /ci generan error al compilarlos
import { CiDiscount1, CiDollar } from "react-icons/ci";
import { TbDiscount2, TbBuildingCommunity, TbArrowsDownUp,TbAlertCircle } from "react-icons/tb";
import { VscKey } from "react-icons/vsc";
import { HiKey, HiHome, HiBuildingOffice2 } from "react-icons/hi2";


const icon = (typeIcon) => {
	let icon;

	switch (typeIcon) {
		case iconContants.HOTEL:
			icon = <FaHotel />;
			break;
		case iconContants.ACTIVITY:
			icon = <FaTicketAlt />;
			break;
		case iconContants.SHUTTER:
			icon = <FaBusAlt />;
			break;
		case iconContants.BED:
			icon = <FaBed />;
			break;
		case iconContants.CONCIERGE:
			icon = <FaConciergeBell />;
			break;
		case iconContants.BRIEFCASE:
			icon = <FaBriefcase />;
			break;
		case iconContants.CLOCK:
			icon = <FaClock />;
			break;
		case iconContants.CALENDAR:
			icon = <FaCalendarAlt />;
			break;
		case iconContants.OFFSALE:
			icon = <AiOutlineFire />;
			break;
		case iconContants.OUTLINEMENU:
			icon = <AiOutlineMenu />;
			break;
		case iconContants.LOCATION:
			icon = <ImLocation2 />;
			break;
		case iconContants.STAR:
			icon = <RiStarSFill />;
			break;
		case iconContants.KEY:
			icon = <RiKeyFill />
			break;
		case iconContants.SEARCH:
			icon = <BiSearch />;
			break;
		case iconContants.PERSON:
			icon = <GoPerson />;
			break;
		case iconContants.BSFILLPERSONFILL:
			icon = <BsFillPersonFill />;
			break;
		case iconContants.BSFILLBRIEFCASEFILL:
			icon = <BsFillBriefcaseFill />;
			break;
		case iconContants.MARTINI:
			icon = <FaGlassMartini />;
			break;
		case iconContants.ROOMPHONE:
			icon = <FaBlenderPhone />;
			break;
		case iconContants.WIFI:
			icon = <AiOutlineWifi />;
			break;
		case iconContants.OUTLINEEDIT:
			icon = <AiOutlineEdit />;
			break;
		case iconContants.TRASH:
			icon = <BsTrash />;
			break;
		case iconContants.ADD:
			icon = <GrAdd />;
			break;
		case iconContants.AA:
			icon = <FaSnowflake />;
			break;
		case iconContants.FICHECK:
			icon = <FiCheck />;
			break;
		case iconContants.DISCOUNT:
			icon = <TbDiscount2 />;
			break;
		case iconContants.TBARROWDOWNUP:
			icon = <TbArrowsDownUp />;
			break;
		case iconContants.TBALERTCIRCLE:
				icon = <TbAlertCircle />;
				break;
		case iconContants.CIDISCOUNT1:
			icon = <AiFillTag />;
			break;
		case iconContants.AIOUTLINEARROWRIGHT:
			icon = <AiOutlineArrowRight />;
			break;
		case iconContants.CHECKCIRCLE:
			icon = <BsFillCheckCircleFill />;
			break;
		case iconContants.ADDCIRCLE:
			icon = <GrAddCircle />;
			break;
		case iconContants.MINUSCIRCLE:
			icon = <GrSubtractCircle />;
			break;
		case iconContants.BIRESTAURANT:
			icon = <BiRestaurant />;
			break;
		case iconContants.GOARROWRIGHT:
			icon = <GoArrowRight />;
			break;
		case iconContants.GOARROWLEFT:
			icon = <GoArrowLeft />;
			break;
		case iconContants.BSCLOCK:
			icon = <BsClock />;
			break;
		case iconContants.AIOUTLINEGLOBAL:
			icon = <AiOutlineGlobal />;
			break;
		case iconContants.AIOUTLINECHECK:
			icon = <AiOutlineCheck />;
			break;
		case iconContants.IOIOSARROWUP:
			icon = <IoIosArrowDown />;
			break;
		case iconContants.IOIOSARROWDOWN:
			icon = <IoIosArrowUp />;
			break;
		case iconContants.AIOUTLINERIGHT:
			icon = <AiOutlineRight />;
			break;
		case iconContants.FATAGS:
			icon = <FaTags />;
			break;
		case iconContants.AIOUTLINECLOSE:
			icon = <AiOutlineClose />;
			break;
		case iconContants.AIOUTLINEEXCLAMATINCIRCLE:
			icon = <AiOutlineExclamationCircle />;
			break;
		case iconContants.RIFLIGHTOFF:
			icon = <RiFlightTakeoffLine />;
			break;
		case iconContants.RIFLIGHTLAND:
			icon = <RiFlightLandLine />;
			break;
		case iconContants.BSFILLHANDBAGFILL:
			icon = <BsFillHandbagFill />;
			break;
		case iconContants.BSFILLBAGFILL:
			icon = <BsFillBagFill />;
			break;
		case iconContants.BSBAGDASFILL:
			icon = <BsBagDashFill />;
			break;
		case iconContants.BSFILLQUESTIONCIRCLEFILL:
			icon = <BsFillQuestionCircleFill />;
			break;
		case iconContants.AIFILLCHECKCIRCLE:
			icon = <AiFillCheckCircle />;
			break;
		case iconContants.BSEXCLAMATIONTRIANGLEFILL:
			icon = <BsExclamationTriangleFill />;
			break;
		case iconContants.BSEXCLAMATIONCIRCLEFILL:
			icon = <BsFillExclamationCircleFill />;
			break;
		case iconContants.IMGS:
			icon = <BsImages />;
			break;
		case iconContants.AIOUTLINEAPPSTORE:
			icon = <AiOutlineAppstore />;
			break;
		case iconContants.SLARROWRIGHT:
			icon = <SlArrowRight />;
			break;
		case iconContants.BILISTUL:
			icon = <BiListUl />;
			break;
		case iconContants.FALONGARROWALTRIGHT:
			icon = <FaLongArrowAltRight />;
			break;
		case iconContants.GOALERT:
			icon = <GoAlert />;
			break;
		case iconContants.BSFILLCARTFILL:
			icon = <BsFillCartFill />;
			break;
		case iconContants.BSARROWLEFTRIGHT:
			icon = <BsArrowLeftRight />
			break;
		case iconContants.BSARROWDOWNUP:
			icon = <BsArrowDownUp />;
			break;
		case iconContants.BSGRIDFILL:
			icon = <BsGridFill />;
			break;
		case iconContants.IOGRIDSHARP:
			icon = <IoGridSharp />
			break;
		case iconContants.IOLISTSHARP:
			icon = <IoListSharp />
			break;
		case iconContants.IOLOCATIONSHARP:
			icon = <IoLocationSharp />
			break;
		case iconContants.FALISTUL:
			icon = <FaListUl />
			break;
		case iconContants.DOLLARSIGN:
			icon = <FiDollarSign />
			break;
		case iconContants.IMPENCIL:
			icon = <ImPencil />
			break;
		case iconContants.FAFACEBOOKF:
			icon = <FaFacebookF />;
			break;
		case iconContants.FATWITTER:
			icon = <FaTwitter />;
			break;
		case iconContants.FAINSTAGRAM:
			icon = <FaInstagram />;
			break;
		case iconContants.FaLINKEDIN:
			icon = <FaLinkedin />;
			break;
		case iconContants.FATRIPADVISOR:
			icon = <FaTripadvisor />;
			break;
		case iconContants.BSFILLCALENDAR2CHECKFILL:
			icon = <BsFillCalendar2CheckFill />;
			break;
		case iconContants.BSFILTER:
			icon = <BsFilter />;
			break;
			case iconContants.FILTER:
			icon = <FaFilter />;
			break; 
	}
	return icon;
};

const getIconMealPlan = (mealPlanCode) => {
	let icon;

	//TODO is add, remove or mod any case, change getIconMealPlanType
	switch (mealPlanCode.toLowerCase()) {
		case "ro":
			icon = <FaBed />;
			break;
		case "ai":
		case "i":
			icon = <FaGlassMartini />;
			break;
		case "bb":
		case "ab":
		case "db":
		case "l":
			icon = <BiRestaurant />;
			break;
		default:
			icon = <FaBed />;
			break;
	}

	return icon;
}

const getIconMealPlanType = (mealPlanCode) => {
	let icon;
	//TODO is add, remove or mod any case, change getIconMealPlan
	if(!mealPlanCode) return "";
	switch (mealPlanCode.toLowerCase()) {
		case "ro":
			icon = iconContants.BED;
			break;
		case "ai":
		case "i":
			icon = iconContants.MARTINI;
			break;
		case "bb":
		case "ab":
		case "db":
		case "l":
			icon = iconContants.BIRESTAURANT;
			break;
		default:
			icon = iconContants.BED;
			break;
	}
	return icon;
}


const getIconHotelCategoryType = (category) => {
	let icon;
	//TODO is add, remove or mod any case, change getIconMealPlan
	switch (category) {
		case "1est":
		case "2est":
		case "3est":
		case "4est":
		case "5est":
		case "h1_5":
		case "h2_5":
		case "h3_5":
		case "h4_5":
		case "stars":
		case "star":
			icon = <RiStarSFill />;
			break;
		case "1ll":
		case "2ll":
		case "3ll":
		case "4ll":
		case "5ll":
		case "resid":
		case "alber":
		case "keys":
		case "key":
			icon = <HiKey />;
			break;
		case "1apth":
		case "2apth":
		case "3apth":
		case "4apth":
		case "5apth":
		case "apth1":
		case "apth2":
		case "apth3":
		case "apth4":
		case "apth5":
		case "keys":
			icon = <HiKey />;
			break;
		case "hs":
		case "hs2":
		case "hs3":
		case "hs4":
		case "hs5":
		case "keys":
			icon = <HiKey />;
			break;
		case "at1":
		case "at2":
		case "at3":
		case "at4":
		case "at5":
		case "keys":
			icon = <HiKey />;
			break;
		case "tpc":
		case "spc":
		case "std":
		case "sup":
		case "villa":
		case "vtv":
		case "bou":
			icon = <RiStarSFill />;
			break;
		default:
			icon = null;
			break;
	}

	return icon;
}

const iconUtils = {
	icon,
	getIconMealPlan,
	getIconMealPlanType,
	getIconHotelCategoryType
};

export default iconUtils;
