import React, { useEffect, useState, useRef } from "react";
import { locale as localePrime, addLocale } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { useIntl } from "react-intl";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import selectPersonsUtils from "../../../utils/selectPersonsUtils";
import Badge from "react-bootstrap/Badge";
import optionsTable from "./components/optionsTable";
import funcsTable from "./components/funcsTable";
import dataTableUtils from "../../../utils/dataTableUtils";
import "./components/style.scss";
import { useGlobalState } from "../../../store/StoreProvider";
import useModalTable from "../../../hooks/useModalTable";

const style = {
  maxWidth: "14rem",
  justifyContent: "center",
};
const DataReservationTable = (props) => {
  const { dataTable, loading } = props;
  const { locale, currency: currencySite } = useGlobalState();
  const currency = currencySite.code;
  const intl = useIntl();
  const toast = useRef(null);
  const [customers, setCustomer] = useState(null);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(optionsTable.filterOptions);
  const [roomsCountRange, setRoomsCountRange] = useState([0]);
  const [adultsCountRange, setAdultsCountRange] = useState([0]);
  const [childrenCountRange, setChildrenCountRange] = useState([0]);
  const tableNamesColums = optionsTable.tableNamesColums(intl);
  const dateFormat = "dd/mm/yy"; //mm/dd/yy EN
  const placeHolderDateFormat = "dd/mm/yyyy"; //mm/dd/yyyy EN
  const [modal, setModal] = useState(false);
  const [modalVoucher, setModalVoucher] = useState(false);
  const [showExportBtn, setShowExportBtn] = useState(false);
  const [request, setRequest] = useState({});

  const resetModals = (update = false) => {
    setModal(false);
    setModalVoucher(false);
    const { updateTable } = props;
    updateTable && update && updateTable();
  };

  useEffect(() => {
    if (!!dataTable) {
      const auxElementRoomsCountP = dataTable.map((d) => d.rooms.length);
      setRoomsCountRange([...new Set(auxElementRoomsCountP)].sort());

      const auxElementAdults = dataTable.map((d) =>
        selectPersonsUtils.totalReservationAdults(d.rooms, d.id)
      );
      setAdultsCountRange(
        [...new Set(auxElementAdults)].sort((a, b) => {
          return a === b ? 0 : a > b ? 1 : -1;
        })
      );
      const auxElementChildren = dataTable.map((d) =>
        selectPersonsUtils.totalReservationChildren(d.rooms)
      );
      setChildrenCountRange(
        [...new Set(auxElementChildren)].sort((a, b) => {
          return a === b ? 0 : a > b ? 1 : -1;
        })
      );

      setCustomer(dataTableUtils.getCustomers(dataTable));

      const localmin = locale.split("-")[0].toLowerCase();
      addLocale(localmin, dataTableUtils.optionsLocale(localmin));
      localePrime(localmin);
      
  
    }
  }, [dataTable, locale]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let auxfilters = { ...filters };
    auxfilters["global"].value = value;

    setFilters(auxfilters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="header-table">
        <div className="search">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder={intl.formatMessage({
                id: "reservationList.table.title",
              })}
            />
          </span>
        </div>
        <div></div>
        {showExportBtn && (
          <Button
            type="button"
            icon="pi pi-file-excel"
            onClick={exportExcel}
            className="p-button-success mx-3"
            data-pr-tooltip="XLS"
          />
        )}
      </div>
    );
  };

  /* #region  //TODO options filter date */

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        //onChange={(e) => options.filterApplyCallback(e.value, options.index)}
        dateFormat={dateFormat}
        placeholder={placeHolderDateFormat}
        mask="31/31/9999"
      />
    );
  };

  const dateLimitCancellarionFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        //onChange={(e) => options.filterApplyCallback(e.value, options.index)}
        dateFormat={dateFormat}
        placeholder={placeHolderDateFormat}
        mask="31/31/9999"
      />
    );
  };

  const dateLimitPaymentFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        //onChange={(e) => options.filterApplyCallback(e.value, options.index)}
        dateFormat={dateFormat}
        placeholder={placeHolderDateFormat}
        mask="31/31/9999"
      />
    );
  };

  const dateCheckInFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        //onChange={(e) => options.filterApplyCallback(e.value, options.index)}
        dateFormat={dateFormat}
        placeholder={placeHolderDateFormat}
        mask="31/31/9999"
      />
    );
  };

  const dateCheckOutFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        //onChange={(e) => options.filterApplyCallback(e.value, options.index)}
        dateFormat={dateFormat}
        placeholder={placeHolderDateFormat}
        mask="31/31/9999"
      />
    );
  };
  /* #endregion */
  /* #region //TODO  options filter rooms count */
  const roomCountFilterTemplate = (options) => {
    return (
      <React.Fragment>
        <div className="mb-3 font-bold">
          {intl.formatMessage({
            id: "reservationList.table.placeholder.roomCount",
          })}
        </div>
        <MultiSelect
          value={options.value}
          options={roomsCountRange}
          itemTemplate={roomCountItemTemplate}
          onChange={(e) => {
            //options.filterCallback(e.value)
            options.filterApplyCallback(e.value);
          }}
          placeholder={intl.formatMessage({
            id: "reservationList.table.placeholder.any",
          })}
          className="p-column-filter"
        />
      </React.Fragment>
    );
  };

  const roomCountItemTemplate = (option) => {
    return (
      <div className="p-multiselect-representative-option">
        <span className="image-text">
          {intl.formatMessage(
            { id: "reservationList.table.filter.roomCount" },
            { count: option }
          )}
        </span>
      </div>
    );
  };

  /* #endregion */
  /* #region //TODO options filter total adults */
  const adultCountFilterTemplate = (options) => {
    return (
      <React.Fragment>
        <div className="mb-3 font-bold">
          {intl.formatMessage({
            id: "reservationList.table.placeholder.adultsTotal",
          })}
        </div>
        <MultiSelect
          value={options.value}
          options={adultsCountRange}
          itemTemplate={adultCountItemTemplate}
          onChange={(e) => {
            options.filterApplyCallback(e.value);
          }}
          placeholder={intl.formatMessage({
            id: "reservationList.table.placeholder.any",
          })}
          className="p-column-filter"
        />
      </React.Fragment>
    );
  };

  const adultCountItemTemplate = (option) => {
    return (
      <div className="p-multiselect-representative-option">
        <span className="image-text">
          {intl.formatMessage(
            { id: "reservationList.table.filter.adultCount" },
            { count: option }
          )}
        </span>
      </div>
    );
  };
  // /* #endregion */
  // /* #region //TODO options filter total children */
  const childrenCountFilterTemplate = (options) => {
    return (
      <React.Fragment>
        <div className="mb-3 font-bold">
          {intl.formatMessage({
            id: "reservationList.table.placeholder.childrenTotal",
          })}
        </div>
        <MultiSelect
          value={options.value}
          options={childrenCountRange}
          itemTemplate={childrenCountItemTemplate}
          onChange={(e) => {
            //options.filterCallback(e.value)
            options.filterApplyCallback(e.value);
          }}
          placeholder={intl.formatMessage({
            id: "reservationList.table.placeholder.any",
          })}
          className="p-column-filter"
        />
      </React.Fragment>
    );
  };
  const childrenCountItemTemplate = (option) => {
    return (
      <div className="p-multiselect-representative-option">
        <span className="image-text">
          {intl.formatMessage(
            { id: "reservationList.table.filter.childrenCount" },
            { count: option }
          )}
        </span>
      </div>
    );
  };

  const statusReservationBodyTemplate = (rowData) => {
    const info = dataTableUtils.objectStatusReservation(
      rowData.statusReservation
    );
    return <Badge bg={info.bgClass}>{info.text}</Badge>;
  };

  const statusReservationFilterTemplate = (options) => {
    const statusReservation = optionsTable.statusReservation(intl);
    return (
      <Dropdown
        value={options.value}
        options={statusReservation}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        //onChange={(e) => options.filterApplyCallback(e.value, options.index)}
        itemTemplate={dataTableUtils.statusReservationItemTemplate}
        placeholder={intl.formatMessage({
          id: "reservationList.table.placeholder.select",
        })}
        className="p-column-filter"
        showClear
      />
    );
  };

  /* #endregion */
  /* #region //TODO validate statusPayment */
  const statusPaymentBodyTemplate = (rowData) => {
    const info = dataTableUtils.objectStatusReservation(rowData.statusPayment);
    return <Badge bg={info.bgClass}>{info.text}</Badge>;
  };

  const statusPaymentFilterTemplate = (options) => {
    const statusPayment = optionsTable.statusReservation(intl);
    return (
      <Dropdown
        value={options.value}
        options={statusPayment}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        //onChange={(e) => options.filterApplyCallback(e.value, options.index)}
        itemTemplate={statusPaymentItemTemplate}
        placeholder={intl.formatMessage({
          id: "reservationList.table.placeholder.select",
        })}
        className="p-column-filter"
        showClear
      />
    );
  };
  const statusPaymentItemTemplate = (option) => {
    const info = dataTableUtils.objectStatusReservation("error");
    return <Badge bg={info.bgClass}>{option.label}</Badge>;
  };
  /* #endregion */
  /* #region //TODO validate Refundable */
  const isRefundableBodyTemplate = (rowData) => {
    return (
      <Badge bg={rowData.isRefundable ? "info" : "warning"}>
        {rowData.isRefundable
          ? intl.formatMessage({ id: "general.table.refundable" })
          : intl.formatMessage({ id: "general.table.nonRefundable" })}
      </Badge>
    );
  };
  const refundableFilterTemplate = (options) => {
    const statusRefundable = optionsTable.statusRefundable(intl);
    return (
      <Dropdown
        value={options.value}
        options={statusRefundable}
        onChange={(e) =>
          options.filterCallback(
            e.value === "refundable" ? true : false,
            options.index
          )
        }
        //onChange={(e) => options.filterApplyCallback(e.value === "refundable" ? true: false, options.index)}
        itemTemplate={statusRefundableItemTemplate}
        placeholder={intl.formatMessage({
          id: "reservationList.table.placeholder.isRefundable",
        })}
        className="p-column-filter"
        showClear
      />
    );
  };
  const statusRefundableItemTemplate = (option) => {
    return (
      <Badge bg={option.value === "refundable" ? "success" : "warning"}>
        {option.label}
      </Badge>
    );
  };
  /* #endregion */
  /* #region //TODO validate isPublicRate */
  const isPublicRateBodyTemplate = (rowData) => {
    return rowData.isPublicRate
      ? intl.formatMessage({ id: "reservationList.table.td.isPublicRate" })
      : intl.formatMessage({
          id: "reservationList.table.td.nonPublicRate",
        });
  };

  const publicRateFilterTemplate = (options) => {
    const statusPublicRate = optionsTable.statusPublicRate(intl);

    return (
      <Dropdown
        value={options.value}
        options={statusPublicRate}
        onChange={(e) =>
          options.filterCallback(
            e.value === "publicRate" ? true : false,
            options.index
          )
        }
        itemTemplate={statuspublicRateItemTemplate}
        placeholder={intl.formatMessage({
          id: "reservationList.table.placeholder.isPublicRate",
        })}
        className="p-column-filter"
        showClear
      />
    );
  };

  const statuspublicRateItemTemplate = (option) => {
    return (
      <Badge bg={option.value === "publicRate" ? "success" : "warning"}>
        {option.label}
      </Badge>
    );
  };
  /* #endregion */
  /* #region //TODO rates */
  const netRateFilterTemplate = (options) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        mode="currency"
        currency={currency.toUpperCase()}
        placeholder={intl.formatMessage({
          id: "reservationList.table.placeholder.netRate",
        })}
        locale={locale}
      />
    );
  };

  const publicRateRageFilterTemplate = (options) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        mode="currency"
        currency={currency.toUpperCase()}
        placeholder={intl.formatMessage({
          id: "reservationList.table.placeholder.publicRate",
        })}
        locale={locale}
      />
    );
  };

  /* #endregion */
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(customers);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "customers");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const items = (options) => [
    {
      label: intl.formatMessage({ id: "checkout.cancel.reservation" }),
      icon: "pi pi-times",
      command: (e) => {
      const body =  [{
        supplierReference: encodeURIComponent(options.reference),
        service: "hotel"
      }]

        const culture = locale?.slice(0, -3) + locale?.slice(-2).toUpperCase();
        setRequest({
          url: `cancel?culture=${culture}`,
          method: "DELETE",  body: body 
        });
        setModal(true);
      },
    },
    {
      label: intl.formatMessage({ id: "checkout.resend" }),
      icon: "pi pi-send",
      command: (e) => {
        const culture = locale?.slice(0, -3) + locale?.slice(-2).toUpperCase();
        const body = {
          bookingId: encodeURIComponent(options.reservationId),
          email: options.holder.email,
          culture: culture,
        }
        setRequest({ url: `itinerary/send`, body: body });
        setModalVoucher(true);
      },
    },
  ];

  const actionBodyTemplate = (options) => {
    return (
      <>
        <Toast ref={toast}></Toast>
        <SplitButton
          className="OrangeSplitButton"
          label={intl.formatMessage({ id: "button.update" })}
          icon="pi pi-update"
          model={items(options)}
          size="small"
        />
      </>
    );
  };

  useModalTable("voucher", request, modalVoucher, resetModals);
  useModalTable("cancel", request, modal, resetModals);

  return (
    <div className="reservation-list-datatable">
      <div className="card">
        <DataTable
          value={customers}
          paginator
          className="p-datatable-customers"
          header={renderHeader}
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          dataKey="id"
          rowHover
          selection={selectedCustomers}
          onSelectionChange={(e) => setSelectedCustomers(e.value)}
          filters={filters}
          filterDisplay="menu"
          loading={loading}
          responsiveLayout="scroll"
          scrollable
          stripedRows
          globalFilterFields={[
            "id",
            "name",
            "customer.fullName",
            "seller.fullName",
            "name",
            "firstRoom.name",
            "roomsCount",
          ]}
          emptyMessage={intl.formatMessage({
            id: "reservationList.table.emptyMessage",
          })}
          currentPageReportTemplate={intl.formatMessage({
            id: "reservationList.table.currentPageReport",
          })}
        >
          <Column {...tableNamesColums.id} style={{ minWidth: "10rem" }} />
          <Column {...tableNamesColums.name} style={{ minWidth: "14rem" }} />
          <Column
            {...tableNamesColums.nameService}
            style={{ minWidth: "14rem" }}
          />
          <Column
            {...tableNamesColums.nameSeller}
            style={{ minWidth: "14rem" }}
          />
          <Column
            style={{ minWidth: "14rem" }}
            {...tableNamesColums.netRate}
            filterElement={netRateFilterTemplate}
            body={(rowData) =>
              dataTableUtils.rateBodyTemplate(
                rowData?.net,
                 rowData?.currency?.toUpperCase() ,
                locale
              )
            }
          />
          <Column
            style={{ minWidth: "14rem" }}
            {...tableNamesColums.publicRate}
            body={(rowData) =>
              dataTableUtils.rateBodyTemplate(
                rowData?.selling,
                rowData?.currency === 0 ? 'MXN' : rowData?.currency?.toUpperCase() ,
                locale
              )
            }
            filterElement={publicRateRageFilterTemplate}
          />
          <Column
            {...tableNamesColums.nameServiceRoom}
            style={{ minWidth: "14rem" }}
          />
          <Column
            style={style}
            {...tableNamesColums.roomCount}
            filterElement={roomCountFilterTemplate}
          />
          <Column
            {...tableNamesColums.adultsTotal}
            style={style}
            filterElement={adultCountFilterTemplate}
          />
          <Column
            {...tableNamesColums.childrenTotal}
            style={style}
            filterElement={childrenCountFilterTemplate}
          />
          <Column
            style={style}
            {...tableNamesColums.dateReservation}
            body={(rowData) =>
              dataTableUtils.dateBodyTemplate(rowData.dateReservation, locale)
            }
            filterElement={dateFilterTemplate}
          />
          <Column
            style={style}
            {...tableNamesColums.dateLimitCancellation}
            body={(rowData) =>
              funcsTable.dateLimitCancellationBodyTemplate(rowData, locale)
            }
            filterElement={dateLimitCancellarionFilterTemplate}
          />
          <Column
            style={style}
            {...tableNamesColums.dateLimitPayment}
            body={(rowData) =>
              dataTableUtils.dateBodyTemplate(rowData.dateLimitPayment, locale)
            }
            filterElement={dateLimitPaymentFilterTemplate}
          />
          <Column
            style={style}
            {...tableNamesColums.checkIn}
            body={(rowData) =>
              dataTableUtils.dateBodyTemplate(rowData.checkIn, locale)
            }
            filterElement={dateCheckInFilterTemplate}
          />
          <Column
            style={style}
            {...tableNamesColums.checkOut}
            body={(rowData) =>
              dataTableUtils.dateBodyTemplate(rowData.checkOut, locale)
            }
            filterElement={dateCheckOutFilterTemplate}
          />
          <Column
            style={style}
            {...tableNamesColums.isPublicRate}
            body={isPublicRateBodyTemplate}
            filterElement={publicRateFilterTemplate}
          />
          <Column
            style={style}
            {...tableNamesColums.statusReservation}
            body={statusReservationBodyTemplate}
            filterElement={statusReservationFilterTemplate}
          />
          <Column
            style={style}
            filterMenuStyle={{ width: "14rem" }}
            {...tableNamesColums.statusPayment}
            body={statusPaymentBodyTemplate}
            filterElement={statusPaymentFilterTemplate}
          />
          <Column
            style={style}
            filterMenuStyle={{ width: "14rem" }}
            {...tableNamesColums.isRefundable}
            body={isRefundableBodyTemplate}
            filterElement={refundableFilterTemplate}
          />

          <Column
            field="Action"
            filterMenuStyle={{ width: "14rem" }}
            {...tableNamesColums.actions}
            alignFrozen="right"
            frozen={true}
            body={actionBodyTemplate}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default DataReservationTable;
