
import BannerHomeComponent from "@mzp/commons/dist/layout/BannerHome";

const BannerHomeSection = (props) => {
    const { options } = props;

    return (<section className="banner-home-section">
        <BannerHomeComponent {...options} />
    </section>);
}

export default BannerHomeSection;