import constants from "../constants";

const defaultLocale = process.env.REACT_APP_LOCALE;
const getLanguagueToPath = (path) => {
    //TODO formato del path de la url con o sin / despues del domain
    //['','']
    //['','es-mx']
    //['','es-mx', '']
    let locale = defaultLocale; //default

    const pathSplit = path.split('/');
    const language = constants.language;
    if(pathSplit.length > 0) {
        switch(pathSplit[1]) {
            case language.MX:
                locale = language.MX;
            break;
            case language.EN:
                locale = language.EN;
            break;
            default:
                locale = defaultLocale;
        }
    }

    return locale;
}
const getLocaleFromPath = (pathLocale) => {
    pathLocale = pathLocale.toLowerCase();

    const auxLocale = getLanguagueToPath(pathLocale);

    return auxLocale;
}

const languageUtils = {
    getLanguagueToPath,
    getLocaleFromPath,
    defaultLocale
}

export default languageUtils;