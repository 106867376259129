
import BannerSimpleFlatComponent from "@mzp/commons/dist/layout/BannerSimpleFlat";

const BannerFlatSection = (props) => {
    const { options } = props;

    return (<section className="banner-flat-section">
        <BannerSimpleFlatComponent {...options} />
    </section>);
}

export default BannerFlatSection;