import { useIntl } from "react-intl";
import {  Button } from "react-bootstrap";

const ReadMore = (props) => {
    const intl = useIntl();
    const { handleExpanded, text, icon } = props;
    return ( <div className="box-more-details"> 
        <Button  variant="link" 
        className='btn-more-details' 
        onClick={handleExpanded}  
        >   
        {text} {icon}
        </Button>
    </div>) }

export default ReadMore;