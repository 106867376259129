import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import reservationUtils from "../../../utils/reservationUtils";
import dateUtils from "../../../utils/dateUtils";
import Calendar from "../../form/inputs/Calendar/Calendar";
import "./components/style.scss";

const FiltersTable = (props) => {
  const intl = useIntl();
  const { loading, _params, locale } = props;
  const optionDates = reservationUtils.optionDates;
  const [typeDate, setTypeDate] = useState(reservationUtils.optionDates[0]);

  const [params, setParams] = useState(_params);

  const getItem = () => {
    const { onSubmit } = props;
    onSubmit && onSubmit(params);
  };

  const _onChangeDate = (response) => {
    params.start = response?.startDate;
    params.end = response?.endDate;
  };

  const _onChangeType = (response) => {
    setTypeDate(response.value);
    setParams(Object.assign({}, params, { typeDate: response.value.key }));
  };

  useEffect(() => {
    setParams(_params);
  }, [params]);

  return (
    <div className="div-filters">
      <div className="col-filters">
        <p className="label-table">
          {intl.formatMessage({ id: "general.typeDate" })}:
        </p>
        <Dropdown
          className="dropdown-filter"
          value={typeDate}
          onChange={_onChangeType}
          options={optionDates}
          optionLabel="label"
          placeholder="Select"
        />
      </div>
      <div className="col-filters">
        <Calendar
          startDate={params?.start}
          endDate={params?.end}
          onChage={_onChangeDate}
          locale={locale}
        />
      </div>
      <div className="col-filters">
        <Button
          onClick={getItem}
          className="btn-table"
          icon="pi pi-search"
          size="small"
          disabled={loading}
          label={intl.formatMessage({ id: "button.search" })}
        />
      </div>
    </div>
  );
};

export default FiltersTable;
