import dateUtils from "../utils/dateUtils"

const setCookie = (name, value, expirationDays) => {
	const date = new Date();
	date.setTime(date.getTime() + ((expirationDays ?? 30) * 24 * 60 * 60 * 1000));
	const expires = "expires=" + date.toUTCString();
	document.cookie = name + "=" + value + ";" + expires + ";path=/";

}

const deleteCookie = (name) => {
	document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

const modifyCookie = (name, newValue, expirationDays) => {
	const currentValue = getCookie(name); // Obtén el valor actual de la cookie
	if (currentValue) {
		setCookie(name, newValue, expirationDays); // Establece una nueva cookie con el valor modificado
	}
}

const getCookie = (name) => {
	const cookieString = document.cookie;
	const cookies = cookieString.split(";");
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i].trim();
		// Verificar si la cookie comienza con el nombre buscado
		if (cookie.startsWith(name + "=")) {
			// Extraer el valor de la cookie
			return cookie.substring(name.length + 1);
		}
	}
	// Si no se encuentra la cookie
	return null;
}

const getWithExpiry = (key) => {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.value
}

const getWithExpiryObject = (key) => {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item
}

const deleteObjectWithExpiry = (key) => {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}

	try {
		const item = JSON.parse(itemStr);
		if (item.expiry !== undefined && item.expiry !== null && item.expiry) {
			const now = new Date()
			// compare the expiry time of the item with the current time
			if (now.getTime() > item.expiry) {
				// If the item is expired, delete the item from storage
				// and return null
				localStorage.removeItem(key)
				return null
			}
		}
	}
	catch (_) {
		return null;
	}
}

const getTimerExpiry = (key) => {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.expiry;
}

const removeObjectsWithExpiry = () => {
	const localStorageLength = localStorage.length;
	for (let i = 0; i < localStorageLength; i++) {
		let key = localStorage.key(i);
		deleteObjectWithExpiry(key)
	}
}

const getSession = () => {
	return getWithExpiry("session")
}
const getLocalStorage = (name) => {
	return getWithExpiry(name)
}

const setSession = (value) => {
	setLocalStorage("session", value, 120)
}

const deleteLocalStorage = (name) => {
	const itemStr = localStorage.getItem(name)
	if (!itemStr) return null;
	localStorage.removeItem(name)
}

const setLocalStorage = (name, value, minutes = 15) => {
	const now = new Date()
	const item = {
		value: value,
		expiry: now.getTime() + dateUtils.minutesToSecondsDate(minutes),
	}
	localStorage.setItem(name, JSON.stringify(item))
}

const updateLocalStorage = (name, value) => {
	const old = getWithExpiryObject(name);
	if (old == null)
		return false;
	const item = {
		value: value,
		expiry: old.expiry
	}
	localStorage.setItem(name, JSON.stringify(item))
	return true;
}

const cleanSession = (name) => {
	localStorage.removeItem(name)
}

const storage = {
	getWithExpiry,
	setSession,
	getSession,
	cleanSession,
	getTimerExpiry,
	setLocalStorage,
	getLocalStorage,
	updateLocalStorage,
	deleteLocalStorage,
	deleteObjectWithExpiry,
	removeObjectsWithExpiry,
	setCookie,
	getCookie,
	deleteCookie,
	modifyCookie
}

export default storage;