import { useGlobalState } from "../../store/StoreProvider";
import CardOverlay from "@mzp/commons/dist/surfaces/CardOverlay";
import { Container, Row, Col } from "react-bootstrap";
import "./components/gridGallerySection.scss";
import { useMediaQuery } from "react-responsive";
import breakpointConstants from "../../constants/breakpointConstants";

const GridGallerySection = (props) => {
  const { options } = props;
  const { locale } = useGlobalState();
  const items = options.items;
  const isXXL = useMediaQuery({ minWidth: breakpointConstants.XXL });
  return (
    <section className="grid-gallery-section">
      {options.title && (
        <div className="container-title">
          <h1
            className="title"
            dangerouslySetInnerHTML={{ __html: options.title }}
          />
        </div>
      )}
      {isXXL ? (
        <Container qui="con - galleryGrid">
          <Row>
            {items.map((d, index) => {
              return (
                <Col className={d.bottom ? "bottomImg " : ""}>
                  <div className={d.isBig ? "biggerImage" : ""}>
                    <CardOverlay item={d} locale={locale} />
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      ) : (
        <Container qui="con - galleryGrid">
          <Row className="rowContaint">
            {items.map((d, index) => {
              return <CardOverlay item={d} key={`item-${index}`} locale={locale} />;
            })}
          </Row>
        </Container>
      )}
    </section>
  );
};

export default GridGallerySection;
