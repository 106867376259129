export default {
  "button.search": "Buscar",
  "button.modify": "Modificar",
  "button.backToList": "Seguir Comprando",
  "button.availability": "Disponibilidad",
  "button.viewDetails": "Ver detalles",
  "button.update": "Actualizar",
  "button.close": "Cerrar",
	"button.home": "Ir al inicio",
};
