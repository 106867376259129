
import iconContants from "../../../constants/iconContants";
import BoxIconText from "../../boxes/BoxIconText";
import "./styles/dataNumberImage.scss";

const  DataNumberImage = (props) => {
  const { numberImages, ...rest } = props;
  const dataNumberImage = (numberImages) => {
    let infoIconText = [{
        icon: iconContants.IMGS,
        classIcon: "",
        text: numberImages,
        classTex: "",
    }];
    return infoIconText;
}
  return (
    <>
      {(!!numberImages && numberImages > 1) && (
        <div className="container-number-img">
          <BoxIconText
            items={dataNumberImage(numberImages)}
            {...rest}
          />
        </div>
      )}
    </>
  )
}

export default DataNumberImage;