import { Container, Button, Image } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import serviceVoucherAction from "../../../actions/serviceVoucherAction";
import dateUtils from "../../../utils/dateUtils";
import imageDefault from '../../../assets/img/defaultImage.svg'
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import hotelsValidate from "../../../validate/hotelsValidate";
import rateUtils from "../../../utils/rateUtils";
import hotelVoucherMock from "../../../mock/voucher/hotelVoucherMock";
import transferVoucherMock from "../../../mock/voucher/transferVoucherMock";
import selectPersonsUtils from "../../../utils/selectPersonsUtils";
import { checkoutStep } from "../../../constants/common";
import SkeletonVoucher from "../../../components/skeleton/SkeletonVoucher/SkeletonVoucher";
import useRedirectToHome from "../../../hooks/useRedirectHomeWithTimerAndDeteleLocalStorage";
import { useGlobalState } from "../../../store/StoreProvider";

const TransferVoucherPage = (props) => {
  const { resourceSite } = props;
  const {locale} = useGlobalState();
  let navigate = useNavigate();
  const intl = useIntl();
  const localizedUrlHome = `/${locale}`;
  const { authSession } = useGlobalState();
  let [searchParams, setSearchParams] = useSearchParams();
  const [transfer, setTransfer] = useState({ id: null });
  const query = {
    cart: searchParams.get("cart"),
  };
  const [cart, setCart] = useState(null);
  const gotToHome = useRedirectToHome(cart, 14, locale);
  const [itinerary, setItinerary] = useState({ isSucces: false })
  const searchHotelParams = resourceSite.searchHotelParams;
  const [params, setParams] = useState(
    hotelsValidate.paramsHotelFormat(query, searchHotelParams.paramsRoom.rules)
  );
  const getItinerary = (query) => {
    serviceVoucherAction.hotelItinerary(
      {...query, authSession},
      (response) => {
        setItinerary(response);
      },
      (error) => {
        console.error(error);
      }
    )
  }
  const title= intl.formatMessage({ id: "voucherPage.title" });
  window.document.title = title
  const onClickRedirect = () => {
    const location = {
      pathname: localizedUrlHome
    };
    window.scrollTo(0, 0);
    navigate(location);
  }

  useEffect(() => {
    //const reservation = storage.getLocalStorage(query.cart);
    const reservation = hotelVoucherMock;
    const transfer = transferVoucherMock;
    window.scrollTo(0, 0);
    if (reservation && reservation.type === checkoutStep.VOUCHER) {
      setCart(reservation);
      const nParams = { ...reservation.booking };
      setParams(nParams);

      if (reservation.clientReference) {
        const request = {
          "reference": reservation.clientReference.reference,
          "email": reservation.clientReference.email,
          locale
        };
        //getItinerary(request);
      }
      else {
        const location = { pathname: localizedUrlHome };
        navigate(location);
      }

    } else {
      const location = { pathname: localizedUrlHome };
      navigate(location);
    }

    if (transfer) {
      setTransfer(transfer);

    } else {
      const location = { pathname: localizedUrlHome };
      navigate(location);
    }
  }, []);

  return (
    <>
      <Container className="container-aux-voucher">
        {transfer && (
          <div className="voucher-container">
            <h1 className="title-service-general">
              {intl.formatMessage({ id: "transferVoucherPage.titleHotelSucces" })}
            </h1>
            <br />
            {(cart === null) &&
              <SkeletonVoucher />
            }
            {(cart !== null) &&(
              <>
                <div className="voucher-card">
                  <div className="voucher-card-header">
                    <div className="container-img-perfil">
                      {transfer.image && (
                        <Image loading="lazy" alt={transfer.image.title} src={transfer.image.code} className='voucher-img-circle'
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = imageDefault
                          }}
                        />
                      )}
                    </div>

                    <div className="d-flex w-100 container-rest-header border-bottom-solid">
                      <div className="container-title-stars-checks">
                        <h2 className="title">{transfer.shuttleName}</h2>
                        <div className="container-checkouts">
                          {transfer.holderName && (
                            <div className="container-titular mb-1">
                              <span className="strong">
                                {`${intl.formatMessage({ id: "transferVoucherPage.name" })}:  `}
                              </span>
                              <span>
                                {[transfer.holderName, transfer.holderLastName].join(' ')}
                              </span>
                            </div>
                          )}
                          <div className="container-pasajeros mb-1">
                            <span className="strong">
                              {`${intl.formatMessage({ id: "transferVoucherPage.adults" })}:  `}
                            </span>
                            <span>{selectPersonsUtils.totalAdults(params.rooms)}</span>
                          </div>
                          <div className="container-pasajeros mb-1">
                            <span className="strong">
                              {`${intl.formatMessage({ id: "transferVoucherPage.childrens" })}:  `}
                            </span>
                            <span>{selectPersonsUtils.totalChildren(params.rooms)}</span>
                          </div>

                        </div>
                      </div>

                      <div className="container-reference">
                        <div className="container-text-duo refer">
                          <span className="text-left">{`${intl.formatMessage({ id: "transferVoucherPage.reference" })}:  `}</span>
                          <span className="text-right">efda9444</span>
                          {/* {transfer.alphanumericReference && (
                            <span className="text-right">{transfer.alphanumericReference}</span>
                          )} */}
                        </div>
                        <div className="container-text-duo date">
                          <span className="text-left">{`${intl.formatMessage({ id: "transferVoucherPage.date" })}:  `}</span>
                          <span className="text-right">{dateUtils.dateFormat(new Date(), "DD-MM-YYYY", locale)}</span>
                          {/* {itinerary.isSucces && itinerary.dateCreatedUtc && (
                            <span className="text-right">{dateUtils.dateFormat(itinerary.dateCreatedUtc, "DD-MM-YYYY", locale)}</span>
                          )} */}
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="voucher-card-body">

                    {transfer && (
                      <div className="d-flex w-100 flex-column">
                        <div className="container-rooms border-bottom-solid">
                          <div className="room room-60 room-xs-100">
                            <div className="container-subtitle">
                              <h2 className="mb-4 text-subtitle">
                                {intl.formatMessage({ id: "transferVoucherPage.arrive" })}
                              </h2>
                            </div>
                            <div className="container-text-duo vuelo">
                              <span className="text-left">{intl.formatMessage({ id: "transferVoucherPage.itinerayFly" })}</span>
                              {transfer.arrivalDate && (
                                <span className="text-right">
                                  {[dateUtils.dateFormat(transfer.arrivalDate, "ddd.. DD/MMM./YYYY", locale), transfer.arrivalHour, "Hrs."].join(' ')}
                                </span>
                              )}
                            </div>
                            <div className="container-text-duo aereolinea">
                              <span className="text-left">{intl.formatMessage({ id: "transferVoucherPage.aereolineFly" })}</span>
                              {transfer.airline && (
                                <span className="text-right">
                                  {[transfer.airline, transfer.flightNumber].join(' / ')}
                                </span>
                              )}
                            </div>
                            <div className="container-text-duo desde">
                              <span className="text-left">{intl.formatMessage({ id: "transferVoucherPage.fromTo" })}</span>
                              {(transfer.from && transfer.to) && (
                                <span className="text-right">
                                  {[transfer.from, transfer.to,].join(' - ')}
                                </span>
                              )}
                            </div>
                            <div className="container-text-duo horario-traslado">
                              <span className="text-left">{intl.formatMessage({ id: "transferVoucherPage.transferItinerary" })}</span>
                              <span className="text-right">
                                Contacte al provedor
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="container-rooms border-bottom-solid">
                          <div className="room room-60 room-xs-100">
                            <div className="container-subtitle">
                              <h2 className="mb-4 text-subtitle">
                                {intl.formatMessage({ id: "transferVoucherPage.leave" })}
                              </h2>
                            </div>
                            <div className="container-text-duo vuelo">
                              <span className="text-left">{intl.formatMessage({ id: "transferVoucherPage.itinerayFly" })}</span>

                              <span className="text-right">
                                Mie... 28/dic./2022 20:00 Hrs.
                              </span>

                            </div>
                            <div className="container-text-duo aereolinea">
                              <span className="text-left">{intl.formatMessage({ id: "transferVoucherPage.aereolineFly" })}</span>
                              {transfer.airline && (
                                <span className="text-right">
                                  {[transfer.airline, "587"].join(' / ')}
                                </span>
                              )}
                            </div>
                            <div className="container-text-duo desde">
                              <span className="text-left">{intl.formatMessage({ id: "transferVoucherPage.fromTo" })}</span>
                              {(transfer.from && transfer.to) && (
                                <span className="text-right">
                                  {[transfer.to, transfer.from].join(' - ')}
                                </span>
                              )}
                            </div>
                            <div className="container-text-duo horario-traslado">
                              <span className="text-left">{intl.formatMessage({ id: "transferVoucherPage.transferItinerary" })}</span>
                              <span className="text-right">
                                Contacte al provedor
                              </span>
                            </div>
                          </div>
                        </div>
                        {transfer.services && transfer.services.length > 0 && (
                          <div className="voucher-card-extra backcolor services mt-5 mb-5">
                            <div className="container-subtitle">
                              <h2 className="text-subtitle">{intl.formatMessage({ id: "transferVoucherPage.titleServices" })}</h2>
                            </div>
                            <div className="d-flex flex-row w-100 justify-content-end">
                              <i className="text-right-color">{intl.formatMessage({ id: "transferVoucherPage.read" })}</i>
                            </div>
                            {transfer.services.map((service, i) => {
                              return (
                                <p key={"politica" + i}>
                                  {service}
                                </p>
                              )
                            })
                            }
                          </div>
                        )}
                        <div className="container-rooms border-top-solid">
                          <div className="room">
                            <div className="container-subtitle">
                              <h2 className="mb-4 text-subtitle">
                                {intl.formatMessage({ id: "transferVoucherPage.provider" })}
                              </h2>
                            </div>
                            <div className="container-text-duo name-provider">
                              <span className="text-left">{intl.formatMessage({ id: "transferVoucherPage.nameProvider" })}</span>
                              <span className="text-right">
                                Integradora Turistica del Sureste Winique
                              </span>

                            </div>
                            <div className="container-text-duo phone-provider">
                              <span className="text-left">{intl.formatMessage({ id: "transferVoucherPage.phoneProvider" })}</span>
                              {transfer.airline && (
                                <span className="text-right">
                                  7830-936-000
                                </span>
                              )}
                            </div>

                          </div>
                        </div>

                      </div>
                    )
                    }
                  </div>
                </div>
                <br />
                {transfer.cancellationPolicies && transfer.cancellationPolicies.length > 0 && (
                  <div className="voucher-card-extra back-white politicas">
                    <div className="container-subtitle">
                      <h2 className="text-subtitle">{intl.formatMessage({ id: "transferVoucherPage.titlePolitics" })}</h2>
                    </div>
                    <br />
                    {transfer.cancellationPolicies.map((politica, i) => {
                      return (
                        <p key={"politica" + i}>
                          {politica}
                        </p>
                      )
                    })
                    }
                  </div>
                )}
                <br />
                <div className="voucher-card-extra back-white pago">
                  <div className="container-subtitle">
                    <h2 className="text-subtitle">{intl.formatMessage({ id: "transferVoucherPage.pay" })}</h2>
                  </div>
                  <br />
                  <div className="w-100 border-bottom-solid">
                    <p>{intl.formatMessage({id: "voucherPage.methodPay"})}</p>
										<p>{intl.formatMessage({id: "voucherPage.creditCard"})}</p>
                  </div>
                  <br />

                  {transfer.total && (
                    <div className="container-text-duo">
                      <span className="text-total strong">{intl.formatMessage({ id: "transferVoucherPage.publicRate" })}</span>

                      {transfer.total && transfer.currency && (
                        <span className="text-total strong">
                          {rateUtils.formatAmount(transfer.total, transfer.currency, locale)}
                        </span>
                      )}
                    </div>
                  )}

                </div>
                <br /> <br />
                <Button onClick={onClickRedirect} className="text-white d-block m-auto">
                  <span>{intl.formatMessage({ id: "button.backToList" })}</span>
                </Button>
              </>
            )}
          </div>
        )}
      </Container>
      <br />
    </>
  );
};

export default TransferVoucherPage;
