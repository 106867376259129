import voucherValidate from "../validate/voucherValidate";

// variables to cancel previous api call
let controller, signal;
//TODO: get hotel Itinerary
const hotelItinerary = async (request, onSuccess, onError) => {
	// cancel previous api call
	if (controller) {
		controller.abort()
	}
	controller = new AbortController()
	signal = controller.signal;
	const { locale, authSession, ...rest } = request;
	const language = locale?.slice(0, -3) + locale?.slice(-2).toUpperCase();
	await fetch(`${process.env.REACT_APP_BFF_API}${process.env.REACT_APP_BFF_ITINERARY}`, {
		signal: signal,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${authSession.token}`
		},
		body: JSON.stringify({ ...rest, culture: language })
	})
		.then((response) => {
			if (response.ok)
				return response.json();
			throw new Error('Something went wrong.');
		})
		.then((data) => {
			const responseFormat = voucherValidate(data);
			if (onSuccess && typeof onSuccess === 'function') {
				onSuccess({ ...responseFormat, isSucces: true });
			}
		})
		.catch(ex => {
			if (onError && typeof onError === 'function') {
				onError(ex);
			}
		});
}

const serviceVoucherAction = {
	hotelItinerary,
}

export default serviceVoucherAction;