
const getSlug = (pathLocale, locale, IDPage,KeyName) => {
    let slug = "/";

    const pathSplit = pathLocale.replace("//", "/").split('/');
    if (pathSplit.length >= 3) {
        //TODO tercera posicion es path sin locale
        slug = pathLocale.replace("//", "/"); //TODO mejorar remplace todos
        slug = slug.replace(`/${locale}`, '');
    }

    if (IDPage && slug.indexOf(IDPage) >= 0) {
        //slug = slug.split(`/${IDPage}`)[0];
        slug = slug.replace(`/${IDPage}`, '/:id');
    }
    if (KeyName && slug.indexOf(KeyName) >= 0) {
        //slug = slug.split(`/${IDPage}`)[0];
        slug = slug.replace(`/${KeyName}`, '/:keyname');
    }

    return slug;
}

const siteUtils = {
    getSlug
}

export default siteUtils;