import {Language} from "../constants/common";
import LOCALE from "../constants/languageConstants";
const handleLanguage = (locale) => {
    switch(locale) {
        case LOCALE.EN:
        case LOCALE.ENU:
            return Language.EN;
        default:
            return Language.MX;
    }
}

const getLOCALE = (locale) => {
    switch(locale) {
        case LOCALE.EN:
            return LOCALE.EN;
        default:
            return LOCALE.MX;
    }
}

const unicode = [
    {
        uni : /\\u00c1/ig,
        ansi: "Á"
    },
    {
        uni : /\\u00e1/ig,
        ansi: "á"
    },
    {
        uni : /\\u00c9/ig,
        ansi: "É"
    },
    {
        uni : /\\u00e9/ig,
        ansi: "é"
    },
    {
        uni : /\\u00cd/ig,
        ansi: "Í"
    },
    {
        uni : /\\u00ed/ig,
        ansi: "í"
    },
    {
        uni : /\\u00d3/ig,
        ansi: "Ó"
    },
    {
        uni : /\\u00f3/ig,
        ansi: "ó"
    },
    {
        uni : /\\u00da/ig,
        ansi: "Ú"
    },
    {
        uni : /\\u00fa/ig,
        ansi: "ú"
    },
    {
        uni : /\\u00d1/ig,
        ansi: "Ñ"
    },
    {
        uni : /\\u00f1/ig,
        ansi: "ñ"
    },
]
const decodeURIComponent =(value) => {
    let result = value;
    for(let i=0; i <unicode.length; i++)
        result = result.replaceAll(unicode[i].uni, unicode[i].ansi)

    return result;
}

const localeUtils = {
    handleLanguage,
    getLOCALE,
    decodeURIComponent
}

export default localeUtils;