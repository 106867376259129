import dateUtils from "../utils/dateUtils";
import objectUtils from "../utils/objectUtils";

const paramsHotelFormat = (params) => {
  return {
    inputAutoValueStart: params.inputAutoValueStart
      ? params.inputAutoValueStart
      : null,
    startDate: params.startDate,
    endDate: params.endDate,
    rooms: params.rooms ?? [],
    search: params.search
      ? params.search
        : null,
  };
};

const paramsTourFormat = (params) => {
  return {
    inputAutoValueStart: params.inputAutoValueStart
      ? params.inputAutoValueStart
      : null,
    date: params.date,
    rooms: params.rooms ?? [],
    search: params.search
      ? params.search
        : null,
  };
};

const paramsShutterFormat = (params) => {
  return {
    inputAutoValueStart: params.inputAutoValueStart
      ? params.inputAutoValueStart
      : null,
    inputAutoValueEnd: params.inputAutoValueEnd
      ? params.inputAutoValueEnd
      : null,
    startDate: params.startDate,
    endDate: params.endDate,
    rooms: params.rooms ?? [],
  };
};

const paramsPackageFormat = (params) => {
  return {
    inputAutoValueStart: params.inputAutoValueStart
      ? params.inputAutoValueStart
      : null,
      inputAutoValueEnd: params.inputAutoValueEnd
      ? params.inputAutoValueEnd
      : null,
    startDate: params.startDate,
    endDate: params.endDate,
    rooms: params.rooms ?? [],
  };
};

const hotelValidate = (paramsFront, paramsAutocomplete, paramsCalendar, paramsRooms) => {
  const result = {
    alert: "",
    submit: true,
  };

  if (
    paramsFront.inputAutoValueStart === undefined ||
    paramsFront.inputAutoValueStart === null
  ) {
    result.alert = paramsAutocomplete.rulesDescriptions.empty;
    result.submit = false;
    return result;
  } else if (
    paramsFront.inputAutoValueStart.keyword === undefined ||
    paramsFront.inputAutoValueStart.keyword === null
  ) {
    result.alert = paramsAutocomplete.rulesDescriptions.selectList;
    result.submit = false;
    return result;
  }

  if (
    paramsFront.startDate === undefined ||
    paramsFront.startDate === null ||
    paramsFront.endDate === undefined ||
    paramsFront.endDate === null
  ) {
    result.alert = paramsCalendar.rulesDescriptions.empty;
    result.submit = false;
    return result;
  } else if (
    !dateUtils.valiteDateFormat(paramsFront.startDate) ||
    !dateUtils.valiteDateFormat(paramsFront.endDate)
  ) {
    result.alert = paramsCalendar.rulesDescriptions.empty;
    result.submit = false;
    return result;
  }

  if (objectUtils.isObjEmpty(paramsFront.rooms)) {
    result.alert = paramsRooms.rulesDescriptions.emptyPersons;
    result.submit = false;
    return result;
  }

  return result;
};

const packageValidate = (paramsFront, paramsAutocomplete, paramsCalendar, paramsRooms) => {
  const result = {
    alert: "",
    submit: true,
  };

  if (
    paramsFront.inputAutoValueStart === undefined ||
    paramsFront.inputAutoValueStart === null
  ) {
    result.alert = paramsAutocomplete.rulesDescriptions.empty;
    result.submit = false;
    return result;
  } else if (
    paramsFront.inputAutoValueStart.keyword === undefined ||
    paramsFront.inputAutoValueStart.keyword === null
  ) {
    result.alert = paramsAutocomplete.rulesDescriptions.selectList;
    result.submit = false;
    return result;
  }

  if (
    paramsFront.inputAutoValueEnd === undefined ||
    paramsFront.inputAutoValueEnd === null
  ) {
    result.alert = paramsAutocomplete.rulesDescriptions.empty;
    result.submit = false;
    return result;
  } else if (
    paramsFront.inputAutoValueEnd.keyword === undefined ||
    paramsFront.inputAutoValueEnd.keyword === null
  ) {
    result.alert = paramsAutocomplete.rulesDescriptions.selectList;
    result.submit = false;
    return result;
  }

  if (
    paramsFront.startDate === undefined ||
    paramsFront.startDate === null ||
    paramsFront.endDate === undefined ||
    paramsFront.endDate === null
  ) {
    result.alert = paramsCalendar.rulesDescriptions.empty;
    result.submit = false;
    return result;
  } else if (
    !dateUtils.valiteDateFormat(paramsFront.startDate) ||
    !dateUtils.valiteDateFormat(paramsFront.endDate)
  ) {
    result.alert = paramsCalendar.rulesDescriptions.empty;
    result.submit = false;
    return result;
  }

  if (objectUtils.isObjEmpty(paramsFront.rooms)) {
    result.alert = paramsRooms.rulesDescriptions.emptyPersons;
    result.submit = false;
    return result;
  }

  return result;
};

const tourWithoutAutocompleteValidate = (paramsFront, paramsCalendar, paramsRooms) => {
  const result = {
    alert: "",
    submit: true,
  };

  if (
    paramsFront.startDate === undefined ||
    paramsFront.startDate === null
  ) {
    result.alert = paramsCalendar.rulesDescriptions.empty;
    result.submit = false;
    return result;
  } else if (
    !dateUtils.valiteDateFormat(paramsFront.startDate)
  ) {
    result.alert = paramsCalendar.rulesDescriptions.empty;
    result.submit = false;
    return result;
  }

  if (objectUtils.isObjEmpty(paramsFront.rooms)) {
    result.alert = paramsRooms.rulesDescriptions.emptyPersons;
    result.submit = false;
    return result;
  }

  return result;
};

const bookingValidate = {
  paramsHotelFormat,
  paramsShutterFormat,
  paramsTourFormat,
  hotelValidate,
  tourWithoutAutocompleteValidate,
  paramsPackageFormat,
  packageValidate
};

export default bookingValidate;
