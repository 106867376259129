import Form from 'react-bootstrap/Form';
import iconContants from '../../../../../constants/iconContants'
import CategoryTypeGroup from '../../../../boxes/BoxIcon/CategoryTypeGroup';
import Accordion from 'react-bootstrap/Accordion';
import '../../../../../utils/prototypesUtils';

const ListCheckBox = (props) => {
	const { title,
		items,
		selects
	} = props;

	const onChange = (item, event) => {
		const { onChange } = props;
		onChange && onChange({
			value: item.value,
			checked: event.target.checked,
		})
	}

	items.sort((a, b) => {
		if (a.type == iconContants.STAR)
			return -2;
		if (a.type == iconContants.KEY)
			return -1;
		return 0;
	}).sort((a, b) => b.label - a.label);
	return (
		<Accordion defaultActiveKey="0">
			<Accordion.Item eventKey="0">
				{title && <Accordion.Header> {title} </Accordion.Header>}
				<Form.Group>
					<Accordion.Body>
						<div className='card-content'>
							<div className="container-flex">
								{items.map((item, index) => {
									return (<div className='container-drawer' key={index}>
										<Form.Check
											key={index}
											type="checkbox"
											id={item.value}
											checked={(selects && selects.length > 0) ? selects.indexOf(item.value) !== -1 : false}
											onChange={(e) => { onChange(item, e) }}
											label={
												<CategoryTypeGroup
													categoryCode={item.type}
													categoryString={item.label.toString().capitalize()}
													num={item.label} />
											}
										/>
									</div>
									)
								})
								}
							</div>
						</div>
					</Accordion.Body>
				</Form.Group>
			</Accordion.Item>
		</Accordion>
	);
}

ListCheckBox.propTypes = {


}

ListCheckBox.defaultProps = {

}
export default ListCheckBox;