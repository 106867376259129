import { useIntl } from "react-intl";
import dateUtils from "../../../../utils/dateUtils";
import PoliticsSection from "./PoliticsSection";
import CommentSection from "./CommentSection";

const VoucherRoomsSection = (props) => {
	const { itinerary, locale } = props;
	const intl = useIntl();
	const hotelBooking = itinerary.hotelBookings[0];
	if (!hotelBooking || !hotelBooking.rooms.length)
		return "";

	let countGuestsByRoom = (holders) => {
		// Agrupar titulares por índice de habitación
		const rooms = holders.reduce((acc, holder) => {
			const roomIndex = holder.index ?? holder.roomIndex;
			const room = acc[roomIndex] || { adults: 0, children: 0, ages: [] };
			if (holder.paxType === "adult" || holder.type === "adult") {
				room.adults++;
			} else if (holder.paxType === "children" || holder.type === "children") {
				room.children++;
				room.ages.push(holder.age);
			}
			acc[roomIndex] = room;
			return acc;
		}, {});
		return rooms;
	}

	let roomSeccion = [];
	let item = hotelBooking.rooms[0];
	for (let i = 1; i <= item.rooms; i++) {
		var roomGrouped = item.holders.filter(r => r.index === i || r.roomIndex === i);
		let roomHolder = roomGrouped.map(h => {
			var fistName = h.fistName ?? h.name;
			if (!fistName || fistName.includes("adult_name"))
				return null;

			return {
				name: fistName,
				surname: h.lastName ?? h.surname,
				roomIndex: h.index ?? h.roomIndex
			}
		})[0];
		let roomPaxDistribution = countGuestsByRoom(roomGrouped);
		//Add rooms sections
		roomSeccion.push(
			<div
				className="d-flex w-100 flex-column border-bottom-solid"
				key={"service-" + i}>
				<div className="container-rooms ">
					<div className="container-subtitle">
						{item.name && (
							<h2 className="mb-4 text-subtitle">
								{intl.formatMessage({ id: "voucherPage.room" }) + " " + (item.rooms === 1 ? "" : i) + " - " + item.name}
							</h2>
						)}
					</div>
					<div className="room">
						<div className="container-rooms-paxes">
							<div
								className="container-text-duo"
								key={"holder-" + roomHolder.roomIndex}>
								<span className="text-left">
									{intl.formatMessage({ id: "checkout.roomHolder" })}
									{"" + item.rooms == 1 ? ": " : " " + roomHolder.roomIndex + ": "}
								</span>
								<span className="text-right">
									{roomHolder.name + " " + roomHolder.surname}
								</span>
							</div>
						</div>
						<div className="container-text-duo name">
							<span className="text-left">
								{intl.formatMessage({ id: "voucherPage.referenceRoom" })}:
							</span>
							<span className="text-right">{hotelBooking.reference} </span>
						</div>
						<div className="container-text-duo checkin">
							<span className="text-left">
								{intl.formatMessage({ id: "voucherPage.checkIn" })}:
							</span>
							<span className="text-right">
								{dateUtils.dateFormat(hotelBooking.checkIn, null, locale)}
							</span>
						</div>
						<div className="container-text-duo check-out">
							<span className="text-left">
								{intl.formatMessage({ id: "voucherPage.checkOut" })}:
							</span>
							<span className="text-right">
								{dateUtils.dateFormat(hotelBooking.checkOut, null, locale)}
							</span>
						</div>
					</div>
					<div className="room">
						<div className="container-text-duo mealPlan">
							<span className="text-left">
								{intl.formatMessage({ id: "voucherPage.mealPlan" })}:
							</span>
							<span className="text-right">{item.mealplan.name}</span>
						</div>
						<div className="container-text-duo adults">
							<span className="text-left">
								{intl.formatMessage({ id: "voucherPage.adults" })}:
							</span>
							{roomPaxDistribution[roomHolder.roomIndex].adults && (
								<span className="text-right">
									{roomPaxDistribution[roomHolder.roomIndex].adults}
								</span>
							)}
						</div>
						<div className="container-text-duo childrens">
							<span className="text-left">
								{intl.formatMessage({ id: "voucherPage.childrens" })}:
							</span>
							<span className="text-right">
								{roomPaxDistribution[roomHolder.roomIndex].children &&
									`${roomPaxDistribution[roomHolder.roomIndex].children} 
										(${roomPaxDistribution[roomHolder.roomIndex].ages
										.map((a) => `${a} ${intl.formatMessage({ id: a === 1 ? "voucherPage.year" : "voucherPage.years" })}`)
										.join(", ")})`}
							</span>
						</div>
					</div>
				</div>
				<PoliticsSection  itinerary={itinerary} locale={locale}/>
				<CommentSection itinerary={itinerary} />
			</div>
		);
	}
	return roomSeccion;
};

export default VoucherRoomsSection;