
import { Col,Card, } from 'react-bootstrap';
import { useIntl } from "react-intl";
import iconUtils from '../../../utils/iconUtils'
import iconContants from '../../../constants/iconContants';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';
import "./components/style.scss";

//TODO card for activities in tour detail
function SkeletonCardList(props) {
  const { number,  } = props;
  const intl = useIntl();


  const optionsOverlay = {
    placement: "top",
    delay: { show: 250, hide: 400 }
  }

  return (
    <>
        {Array.from(Array(number)).map((_, index) =>
        (
            <Col className='card-container' xs={12} sm={6} lg={4} xl={3} key={index}>
                <Card className="skeleton-card" >
                <Placeholder as={Card.Title}  animation="glow">
                    <Placeholder className="skeleton-card-img" />
                 </Placeholder>
                <Card.Body>
                    <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={6} /> <Placeholder xs={5} /> <Placeholder xs={6} />{' '}
                    <Placeholder xs={5} /> {' '} <Placeholder xs={5} />  {' '}
                    <Placeholder xs={4} /> {' '} <Placeholder xs={6} />
                    
                    </Placeholder>
                </Card.Body>
                </Card>
            </Col>
          
        )
    )}
    </>
  

  );
}

export default SkeletonCardList;