import { useIntl } from "react-intl";
import htmlparse from 'html-react-parser'

const CommentSection = (props) => {
	const { itinerary } = props;
	const intl = useIntl();
	const hotelBooking = itinerary.hotelBookings[0];
	return <>
		{itinerary.isSucces && hotelBooking.rooms && hotelBooking.rooms.length > 0 && hotelBooking.rooms[0].rateComments && (
			<>
				<div className="comentarios">
					<div className="container-subtitle">
						<h2 className="text-subtitle">
							{intl.formatMessage({
								id: "voucherPage.titleRateComents"
							})}
						</h2>
					</div>
					<p className="container-p"> {htmlparse(hotelBooking.rooms[0].rateComments.replace(/_|#|-|@|<>/g, " "))}</p>
				</div>
			</>
		)}
	</>;

}

export default CommentSection;