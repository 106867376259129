export default {
  "button.search": "Search",
  "button.modify": "Modify",
  "button.backToList": "Keep buying",
  "button.availability": "check availability",
  "button.viewDetails": "More",
  "button.update": "Update",
  "button.close": "Close",
	"button.home": "Go to home",
};
