import iconContants from "../../constants/iconContants";
//import iconContants from "@mzp/commons/dist/constants/iconContants"; //TODO descuadra al gris de listado icons
import languageConstants from "../../constants/languageConstants";
import pageLinksConstants from "../../constants/pageLinksConstants";
import footerComponent from "./api/components/footerComponent";
import { viewGridListConst } from "../../constants/common";
import iconUtils from "@mzp/commons/dist/utils/iconUtils";
import iconMZPContants from "@mzp/commons/dist/constants/iconContants";
import images from "../../theme/ImageSite";


const resource = {
	home: {
		alt: "",
		href: "/es-mx",
		hrefWithSession: "/es-mx",
		hrefWithOutSession: "/es-mx",
		imgPath: images?.headerLogo ?? "",
	},
	menu: [
		{
			name: "actividades",
			href: "https://www.nexustours.com/services/?utm_source=nexusvacations&utm_medium=header",
			icon: iconContants.ACTIVITY,
			external:true,
			target:"_blank"
			//url: "Tranfer"
		},
		{
			name: "traslados",
			href: "https://www.nexustours.com/transfers/?utm_source=nexusvacations&utm_medium=header",
			icon: iconContants.SHUTTER,
			external:true,
			target:"_blank"
			//url: "Hotel"
		},
	],
	languagesItems: [
		{
			name: "Español",
			value: 1,
			locale: "es-mx",
		},
		{
			name: "Inglés",
			value: 2,
			locale: "en-us",
		},
	],
	currenciesItems: [
		{
			name: "MXN",
			value: "MXN",
		},
		{
			name: "USD",
			value: "USD",
		},
	],
	siteLocale: languageConstants.MX,
	siteRoutes: {
		home: {
			pathname: "/",
			basename: "/",
		},
		notFound: {
			pathname: "/notfound",
			basename: "/notfound",
		},
		hotels: {
			pathname: "/hoteles",
			basename: "/hoteles",
		},
		hotel: {
			pathname: "/hotel/",
			basename: "/hotel/:keyname/:id",
		},
		bookingHotel: {
			pathname: "/reserva/hotel",
			basename: "/reserva/hotel",
		},
		reservation: {
			pathname: "/reserva",
			basename: "/reserva",
		},
		voucherHotel: {
			pathname: "/voucher",
			basename: "/voucher",
		},
		voucherStatic: {
			pathname: "/voucher-pendiente",
			basename: "/voucher-pendiente",
		},
	},
	redirectPageDefault: "/",
	siteRouter: [
		{
			pathname: pageLinksConstants.HOME,
			basename: pageLinksConstants.HOME,
			homePage: true, //TODO es unico por array para identificar al que ba en el route sin el mapeado
			keypage: "slug-page",
			typePage: "home", //TODO unico por array identificador
			hasAuth: true,
		},
		{
			pathname: pageLinksConstants.HOTELS,
			basename: pageLinksConstants.HOTELS,
			keypage: "slug-page",
			typePage: "hotels", //TODO unico por array identificador,
			hasAuth: true, //TODO valor en true necesita login o redirecciona a la pagina de default o login
		},
		{
			pathname: pageLinksConstants.HOTEL,
			basename: `${pageLinksConstants.HOTEL}/:keyname/:id`,
			keypage: "slug-page",
			typePage: "hotel-detail", //TODO unico por array identificador,
			hasAuth: true, //TODO valor en true necesita login o redirecciona a la pagina de default o login
		},
		{
			pathname: pageLinksConstants.CHECKOUT,
			basename: pageLinksConstants.CHECKOUT,
			keypage: "slug-page",
			typePage: "checkout", //TODO unico por array identificador,
			hasAuth: true, //TODO valor en true necesita login o redirecciona a la pagina de default o login
		},
		{
			pathname: pageLinksConstants.NOTFOUND,
			basename: pageLinksConstants.NOTFOUND,
			homePage: true, //TODO es unico por array para identificar al que ba en el route sin el mapeado
			keypage: "slug-page",
			typePage: "notFound", //TODO unico por array identificador
			hasAuth: false, //TODO valor en true necesita login o redirecciona a la pagina de default o login
		},
		{
			pathname: pageLinksConstants.VOUCHER,
			basename: pageLinksConstants.VOUCHER,
			homePage: true, //TODO es unico por array para identificar al que ba en el route sin el mapeado
			keypage: "slug-page",
			typePage: "voucher", //TODO unico por array identificador
			hasAuth: true, //TODO valor en true necesita login o redirecciona a la pagina de default o login
		},
		{
			pathname: pageLinksConstants.VOUCHER_STATIC_ES,
			basename: pageLinksConstants.VOUCHER_STATIC_ES,
			homePage: true, //TODO es unico por array para identificar al que ba en el route sin el mapeado
			keypage: "slug-page",
			typePage: "voucher-pending", //TODO unico por array identificador
			hasAuth: true, //TODO valor en true necesita login o redirecciona a la pagina de default o login
		},
	],
	headerInformation: {
		showLanguage: true,
		showCurrency: true,
		showMenu: true,
		showMenuMobile: true,
		orderLogo: "0",
		orderMenu: "1",
		orderSettings: "2",
		centerLogo: false,
		menuSlide: true,
		// cart: true, //TODO habilitar u ocultar carrito
		// user: true, //TODO habilitar u ocultar sesion usuario
		iconcart: iconUtils.icon(iconMZPContants.BICART),
		iconUser: iconUtils.icon(iconMZPContants.HIUSERCIRCLE),
		//textCart: "Cart",
		urlUser: "",
		urlCart: "",
		iconMenu: iconUtils.icon(iconMZPContants.CGMENUGRIDR),
		topinfo: false, //oculta el texto de call
	},
	footerInformation: {
		...footerComponent,
	},
	orderBy: [
		{
			name: "Recomendado",
			value: "recommended",
		},
		{
			name: "Precio:  Menor a mayor",
			value: "asc",
		},
		{
			name: "Precio:  Mayor a menor",
			value: "desc",
		},
	],
	toggleListHotel: [
		{
			icon: iconContants.IOGRIDSHARP,
			name: "Bloques",
			value: viewGridListConst.GRID,
		},
		{
			icon: iconContants.FALISTUL,
			name: "Lista",
			value: viewGridListConst.ROW,
		},
		{ icon: iconContants.LOCATION, name: "Mapa", value: viewGridListConst.MAP },
	],
	toggleListHotelMobileMap: [
		{ icon: iconContants.LOCATION, 
			name: "Mapa", 
			value: viewGridListConst.MAP 
		},
		{
			icon: iconContants.BSFILTER,
			name: "Filtros",
			value: viewGridListConst.FILTER,
		},
	],
	toggleListHotelMobileList: [
		{ 	
			icon: iconContants.FALISTUL, 
			name: "Lista", 
			value: viewGridListConst.ROW 
		},
		{
			icon: iconContants.BSFILTER,
			name: "Filtros",
			value: viewGridListConst.FILTER,
		},
	],
	searchHotelParams: {
		//TODO al clonar solo paquete tiene el mismo campo de room: "hab", en paramsroom rulesDescriptions
		paramsRoom: {
			// label: "Personas",
			id: "demo-simple-select-label",
			rules: {
				roomsMax: 8,
				personsTotal: 14,
				adultMinforRoom: 1,
				adultDefaultRoom: 2,
				adultMaxforRoom: 14,
				childMinforRoom: 0,
				childMaxforRoom: 6,
				infantMinforRoom: 0,
				infantMaxforRoom: 6,
				childYear: [
					0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
				],
			},
			paramsRoom: {
				adultParams: {
					ariaLabelLess: "Quitar Adultos",
					ariaLabelMore: "Agregar Adultos",
					label: "Adultos",
					//subLabel: "> 12 años",
				},
				childParams: {
					ariaLabelLess: "Quitar niños",
					ariaLabelMore: "Agregar niños",
					label: "Niños",
					subLabel: "0 - 17",
				},
				infantParams: {
					ariaLabelLess: "Quitar Infantes",
					ariaLabelMore: "Agregar Infantes",
					label: "Infantes",
					subLabel: "Menores de 2 años",
				},
			},
			rulesDescriptions: {
				personsTotal: "Sobre pasa el numero de personas a reservar",
				yearChildren: "Selecciona la edad de los menores",
				emptyPersons: "Selecciona las personas de tu viaje",
				emptyChildren: "Falta agregar la edad de los menores",
				room: "hab",
				rooms: "habs",
				adult: "adulto",
				adults: "adultos",
				child: "niño",
				children: "niños",
				infant: "infante",
				infants: "infantes",
				person: "persona",
				people: "personas",
				guest: "Huésped",
				guests: "Huéspedes",
				age: "Edad",
				ages: "Edades",
			},
			text: {
				remove: "Eliminar",
				addRoom: "Agregar habitación",
				done: "Listo",
			},
		},
		paramsCalendar: {
			placeholder: "Seleccionar fechas",
			label: "Fechas",
			rulesDescriptions: {
				empty: "Selecciona las fechas de tu viaje",
			},
		},
		paramsAutocomplete: {
			id: "combo-demo",
			limitOptions: 10,
			minTextAPI: 3,
			timeDelay: 200,
			labelStart: "Destino",
			rulesDescriptions: {
				empty: "Por favor ingresa un hotel o destino.",
				selectList: "Selecciona un hotel o destino del listado de búsqueda",
			},
		},
	},
	searchPackageParams: {
		//TODO al clonar solo paquete tiene el mismo campo de room: "hab", en paramsroom rulesDescriptions
		paramsRoom: {
			//label: "Personas",
			//id: "demo-simple-select-label",
			rules: {
				roomsMax: 8,
				personsTotal: 14,
				adultMinforRoom: 1,
				adultDefaultRoom: 2,
				adultMaxforRoom: 14,
				childMinforRoom: 0,
				childMaxforRoom: 6,
				infantMinforRoom: 0,
				infantMaxforRoom: 6,
				childYear: [
					0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
				],
			},
			paramsRoom: {
				adultParams: {
					ariaLabelLess: "Quitar Adultos",
					ariaLabelMore: "Agregar Adultos",
					label: "Adultos",
					//subLabel: "> 12 años",
				},
				childParams: {
					ariaLabelLess: "Quitar niños",
					ariaLabelMore: "Agregar niños",
					label: "Niños",
					subLabel: "0 - 17",
				},
				infantParams: {
					ariaLabelLess: "Quitar Infantes",
					ariaLabelMore: "Agregar Infantes",
					label: "Infantes",
					subLabel: "Menores de 2 años",
				},
			},
			rulesDescriptions: {
				personsTotal: "Sobre pasa el numero de personas a reservar",
				yearChildren: "Selecciona la edad de los menores",
				emptyPersons: "Selecciona las personas de tu viaje",
				emptyChildren: "Falta agregar la edad de los menores",
				room: "hab",
				rooms: "habs",
				adult: "adulto",
				adults: "adultos",
				child: "niño",
				children: "niños",
				infant: "infante",
				infants: "infantes",
				person: "persona",
				people: "personas",
				guest: "Huésped",
				guests: "Huéspedes",
				age: "Edad",
				ages: "Edades",
			},
			text: {
				remove: "Eliminar",
				addRoom: "Agregar habitación",
				done: "Listo",
			},
		},
		paramsCalendar: {
			// placeholder: "Seleccionar fechas",
			// label: "Fechas",
			rulesDescriptions: {
				empty: "Selecciona las fechas de tu viaje",
			},
		},
		paramsAutocomplete: {
			// id: "combo-demo",
			// limitOptions: 10,
			// labelStart: "Destino",
			rulesDescriptions: {
				empty: "Selecciona un origen o destino.",
				selectList: "Selecciona un hotel o destino del listado de búsqueda",
			},
		},
	},
	searchTourParams: {
		paramsRoom: {
			label: "Personas",
			id: "demo-simple-select-label",
			rules: {
				roomsMax: 1,
				personsTotal: 14,
				adultMinforRoom: 1,
				adultDefaultRoom: 2,
				adultMaxforRoom: 14,
				childMinforRoom: 0,
				childMaxforRoom: 6,
				infantMinforRoom: 0,
				infantMaxforRoom: 6,
				childYear: [
					0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
				],
			},
			paramsRoom: {
				adultParams: {
					ariaLabelLess: "Quitar Adultos",
					ariaLabelMore: "Agregar Adultos",
					label: "Adultos",
					//subLabel: "> 12 años",
				},
				childParams: {
					ariaLabelLess: "Quitar niños",
					ariaLabelMore: "Agregar niños",
					label: "Niños",
					subLabel: "0 - 17",
				},
				infantParams: {
					ariaLabelLess: "Quitar Infantes",
					ariaLabelMore: "Agregar Infantes",
					label: "Infantes",
					subLabel: "Menores de 2 años",
				},
			},
			rulesDescriptions: {
				personsTotal: "Sobre pasa el numero de personas a reservar",
				yearChildren: "Selecciona la edad de los menores",
				emptyPersons: "Selecciona las personas de tu viaje",
				emptyChildren: "Falta agregar la edad de los menores",
				adult: "adulto",
				adults: "adultos",
				child: "niño",
				children: "niños",
				infant: "infante",
				infants: "infantes",
				person: "persona",
				people: "personas",
				guest: "Huésped",
				guests: "Huéspedes",
				age: "Edad",
				ages: "Edades",
			},
		},
		paramsCalendar: {
			placeholder: "Selecciona la fecha",
			label: "Fecha",
			rulesDescriptions: {
				empty: "Selecciona la fecha de tu viaje",
			},
		},
		paramsAutocomplete: {
			id: "combo-demo",
			limitOptions: 10,
			minTextAPI: 3,
			timeDelay: 200,
			labelStart: "Destino",
			rulesDescriptions: {
				empty: "Por favor ingresa un tour o destino.",
				selectList: "Selecciona un hotel o destino del listado de búsqueda",
			},
		},
	},
	searchShutterParams: {
		paramsRoom: {
			label: "Personas",
			id: "demo-simple-select-label",
			rules: {
				roomsMax: 1,
				personsTotal: 14,
				adultMinforRoom: 1,
				adultDefaultRoom: 2,
				adultMaxforRoom: 14,
				childMinforRoom: 0,
				childMaxforRoom: 6,
				infantMinforRoom: 0,
				infantMaxforRoom: 6,
				childYear: [
					0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
				],
			},
			paramsRoom: {
				adultParams: {
					ariaLabelLess: "Quitar Adultos",
					ariaLabelMore: "Agregar Adultos",
					label: "Adultos",
					//subLabel: "> 12 años",
				},
				childParams: {
					ariaLabelLess: "Quitar niños",
					ariaLabelMore: "Agregar niños",
					label: "Niños",
					subLabel: "0 - 17",
				},
				infantParams: {
					ariaLabelLess: "Quitar Infantes",
					ariaLabelMore: "Agregar Infantes",
					label: "Infantes",
					subLabel: "Menores de 2 años",
				},
			},
			rulesDescriptions: {
				personsTotal: "Sobre pasa el numero de personas a reservar",
				yearChildren: "Selecciona la edad de los menores",
				emptyPersons: "Selecciona las personas de tu viaje",
				emptyChildren: "Falta agregar la edad de los menores",
				adult: "adulto",
				adults: "adultos",
				child: "niño",
				children: "niños",
				infant: "infante",
				infants: "infantes",
				person: "persona",
				people: "personas",
				guest: "Huésped",
				guests: "Huéspedes",
				age: "Edad",
				ages: "Edades",
			},
			text: {
				remove: "Eliminar",
				addRoom: "Agregar habitación",
				done: "Listo",
			},
		},
		paramsCalendar: {
			placeholder: "Selecciona la fecha",
			label: "Fecha",
			rulesDescriptions: {
				empty: "Selecciona la fecha de tu viaje",
			},
		},
		paramsAutocomplete: {
			id: "combo-demo",
			limitOptions: 10,
			minTextAPI: 3,
			timeDelay: 200,
			labelStart: "Desde",
			labelEnd: "Hasta",
			rulesDescriptions: {
				empty: "Selecciona un origen o destino.",
				selectList: "Selecciona un origen o destino del listado de búsqueda",
			},
		},
	},
	errorType500: {
		type: "500",
		title: "Página no encontrada",
		text: "La página que estás buscando no existe",
		btn: "Ir a la Home",
	},
	errorType400: {
		type: "400",
		title: "Página no encontrada",
		text: "La página que estás buscando no existe",
		btn: "Ir a la Home",
	},
};

export default resource;