import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useGlobalState } from "../store/StoreProvider";
import authAction from "../actions/authAction";
import storage from "../localstorage/storage";
import useGetNameAuthStorage from "./useGetNameAuthStorage";
import businessModel from "../constants/businessModel";
import { localStorageConst } from "../constants/common";

const useAuthSession = () => {
    const business = process.env.REACT_APP_BUSINESS.toUpperCase();
    const { locale, resourceSite, siteInformation,authSession } = useGlobalState()
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const authConst = useGetNameAuthStorage();
 
      const  requestB2C = {
            emailAddress: process.env.REACT_APP_AUTH_EMAIL,
            password: process.env.REACT_APP_AUTH_PASSWORD,
            siteId: storage.getCookie(localStorageConst.SITE)

        };

    const onSuccessB2CLogin = (response) => {
        //TODO si no existe la sesion de b2c la crea y despues la agrega al localstorage
        storage.setLocalStorage(authConst, response, response.expires) //120min
    }

    //TODO setSession: solo para b2c para poder relogearse
    const refreshSession = (onSecondFucn = [true, true]) => {
        if (onSecondFucn[0]) {
            //TODO diferencia con .auth que no muestra el loading y carga la info directo
            authAction.authWithOutLoading(authConst,
                requestB2C,
                dispatch,
                onSuccessB2CLogin,
                (_error) => {
                    //TODO false mandar mensaje de error
                    onSecondFucn[1] && refreshSession([true, false]);
                }
            );
        }
    };
    const loginB2C = (auxSession) => {
        if (auxSession === null) {
            authAction.auth(requestB2C, dispatch,
                onSuccessB2CLogin,
                (_error) => {
                    //TODO cargar pagina error, llamar nuevamente, etc,
                })
        }
        else
        {
            authAction.setAuth(auxSession, dispatch); //TODO existe la sesion y solo lo manda al contexto
        }
           
    }

    const loginOther = (auxSession) => {
        if (auxSession === null) {
            //TODO limpio del contexto y lo mando a la home
            authAction.cleanAuth(dispatch);
            const location = {
                pathname: `/${locale}${resourceSite.options.redirectPageDefault}`,
            };
            navigate(location);
        }
        else {
            authAction.setAuth(auxSession, dispatch); 
        }

    }

    const validateLogin = () => {
        const auxSession = storage.getLocalStorage(authConst);
        const auxSessionTimer = storage.getTimerExpiry(authConst);

        //TODO Si existe sesion y tiene poco tiempo, lo vuelve a actualizar para b2c
        if (auxSession && auxSessionTimer) {
            const now = new Date();
            const loginDate = new Date(auxSessionTimer);
            const dif = (loginDate.getTime() - now.getTime()) / 1000 / 60;
            //Todo diff in minutes
            const x = Math.abs(Math.round(dif));

            if (x <= 5 || x - 5 <= 5) {
                //TODO 5minues to refresh b2c
                business === businessModel.B2C && refreshSession();
                //TODO si no es b2c lo manda a la home de default
                business !== businessModel.B2C && loginOther(null);
            }
        }
        else {
            //TODO si no existe lo relogea para b2c
            business === businessModel.B2C && refreshSession();
            //TODO si no es b2c lo manda a la home de default
            business !== businessModel.B2C && loginOther(null);
            //TODO elimina localstorage al finalizar sesion
            //storage.deleteLocalStorage(localStorageConst.AUTH);
        }
    };

    useEffect(() => {
        const auxSession = storage.getLocalStorage(authConst);
        if (siteInformation.isSuccess && !authSession?.isFetching ) {

            switch (business) {
                case businessModel.B2C:
                    loginB2C(auxSession)
                    break;
                default:
                    loginOther(auxSession);
            }
        }
        //TODO timer
        setInterval(validateLogin, 1000 * 60 * 5); //5min 1 cambiar por 5

        return () => {
            clearInterval(validateLogin);
        }
    }, [siteInformation.isSuccess]);
}

export default useAuthSession;