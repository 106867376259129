import CategoryTypeGroup from "../BoxIcon/CategoryTypeGroup";
import "./components/boxTitleCard.scss";
import { Link } from "react-router-dom";

const BoxTitleCard = (props) => {
	const { item, sxBoxTitle, hasLink } = props;

	return (
		<div className={`box-title`} style={sxBoxTitle}>
			<div className="box-title-content">
				<h5 qui="tit service-name" className="box-title-content-title">
					{hasLink && (
						<Link to={item.url}>{item?.name}</Link>
					)}
					{!hasLink && (item?.name)}
				</h5>
			</div>
			<div
				className="container-flex align-items-baseline box-service-descriptions"
				style={{ flexDirection: "initial" }}
			>
				<CategoryTypeGroup qui="con	category-type-group" num={item.categoryNumber} categoryCode={item.categoryCode} categoryString={item.categoryString} />
				<div className="container-flex container-flex-address ms-1">
					<p  qui="p destination" className="container-flex-text container-address">
						{(item.destination && typeof item.destination === "string") && (
							item.destination
						)}
						{(item.destination && typeof item.destination === "object") && (
							item.destination.name
						)}
					</p>
				</div>
			</div>
		</div>
	);
};

export default BoxTitleCard;
