import initialState from "./initialState";
import types from "../constants/reducers/searchHotelsConstants";
const searchHotelsReducer = (state = initialState.searchHotels, action) => {
    switch(action.type) {
        case types.SEARCH_HOTEL_FETCHING:
            return {...initialState.searchHotels, ...action.searchHotels}
        case types.SEARCH_HOTEL:
            return action.searchHotels;
        case types.SEARCH_HOTEL_ERROR:
            return initialState.searchHotels
        default:
            return state;
    }
}

export default searchHotelsReducer;