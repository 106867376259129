import BgWrapper from "@mzp/commons/dist/layout/BgWrapper";
import BoxTextBanner from "../boxes/BoxTextBanner/BoxTextBanner";

export const BackgroundVideo = (props) => {
  const { options } = props;

  return (
    <section className="background-wrapper">
      <BgWrapper {...options}>
        <BoxTextBanner options={options} />
      </BgWrapper>
    </section>
  );
};
