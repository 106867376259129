import NewsletterComponent from "@mzp/commons/dist/layout/Newsletter";
const NewslatterSection = (props) => {
    const {options} = props;

    const onSubmit = (response) => {
        console.log(response, "newsletter");
    }

    return ( <section>
        <NewsletterComponent {...options} onSubmit={onSubmit} />
    </section> );
}

export default NewslatterSection;