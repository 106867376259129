import initialState from "./initialState";
import types from "../constants/reducers/currencyConstants";

const CurrencyReducer = (state  = initialState.currency, action) => {
    switch (action.type) {
        case types.SET_CURRENCY:
            return action.currency
        default:
            return state
    }
}

export default CurrencyReducer;
