import initialState from "./initialState";
import types from "../constants/reducers/cmsComponentsConstants";

const cmsComponentsReducer = (state = initialState.cmsComponents, action) => {
    switch(action.type) {
        case types.CMS_COMPONENT_FETCHING:
            return {...initialState.cmsComponents, ...action.data}
        case types.CMS_COMPONENT:
            return action.data;
        case types.CMS_COMPONENT_ERROR:
            return action.data;
        default:
            return state;
    }
}

export default cmsComponentsReducer;