export default {
  "destino.cun.des": "Considered as the gateway to the Mayan World.",

  "destino.cabos.des":
    "Discover the beauty of the southern tip of the Baja California peninsula.",

  "destino.aca.des": "Recognized worldwide as a synonym of relaxation and fun",

  "destino.mz.des":
    "The Pearl of the Pacific, with unbeatable sunsets and generous gastronomy",
  "destino.tr.cun": "Transfers in Cancun",
  "destino.tr.cabos": "Transfers in Los Cabos",
  "destino.tr.aca": "Transfers in Acapulco",
  "destino.tr.mz": "Transfers in Mazatlán",
  "banner.tr.title": "Special Sale",
  "banner.tr.sub1": "Dec 01st 2022 - Jan 08th 2023",
  "banner.tr.sub2": "Discounts Up to",
  "banner.tr.sub3": "52% In transfers",
  "banner.tr.sub4": "40% In excursions",
  "banner.tr.sub5": "Traveler's Choice Tripadvisor",
  "banner.h.title": "Special Sale",
  "banner.h.sub1": "Dec 01st 2022 - Jan 08th 2023",
  "banner.h.sub2": "Discounts Up to",
  "banner.h.sub3": "52% In hansfers",
  "banner.h.sub4": "40% In excursions",
  "banner.h.sub5": "Traveler's Choice Tripadvisor",
  "banner.tour.title": "Special Tours",
  "banner.tour.sub1": "Discounts Up to",
  "banner.tour.sub2": "From January to May",
  "banner.tour.sub3": "52% in Cancun tours",
  "home.destination": "Top 10 Thing to do in Caribe",
  "home.similar": "Hoteles similars",
  "destino.cun": "Cancun",
  "destino.cabos": "Los Cabos",
  "destino.aca": "Acapulco",
  "destino.mz": "Mazatlán",
  "destino.rm": "Riviera Maya",
  "destino.rm": "Isla Mujeres",
  "destino.rm": "Santa Lucia",
  "destino.rm": "Punta Cana",
  "destino.rm": "Isla Mujeres",
  "destino.rm": "Santa Lucia",
  "destino.rm": "Punta Cana",
};
