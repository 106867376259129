import { useIntl } from "react-intl";
import rateUtils from "../../../../utils/rateUtils";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import iconUtils from "../../../../utils/iconUtils";
import iconContants from "../../../../constants/iconContants";
import { useGlobalState } from "../../../../store/StoreProvider";

const CardRate = (props) => {
	const { service, showDiscount = false } = props;
	const intl = useIntl();
	const { locale, currency } = useGlobalState();
	const hasOffers = service.hasOffers && service.offerSale > 0 ? true : false;
	return (<>
		{showDiscount && hasOffers && (
			<div style={{ height: '10px' }} className="rate-discount">
				<span className="container-icon me-2">
					{iconUtils.icon(iconContants.CIDISCOUNT1)}
				</span>
				<span>
					{`${intl.formatMessage(
						{ id: "general.discountText" },
						{ count: service.offerSale }
					)}`}
				</span>
			</div>
		)}
		<small className="text-muted"><b>{intl.formatMessage({ id: "text.pernight" })}</b></small>
		{hasOffers && (
			<span className="old-fee text-color">
				{rateUtils.formatAmount(service.bestRate?.totalWithOffersPerNight, currency.code, locale)}
			</span>
		)}
		<div className="rate">
			{rateUtils.formatAmount(service.bestRate?.totalPerNight, currency.code, locale)}
		</div>
		<small className="text-muted">{intl.formatMessage({ id: "general.totalPrice" })} {rateUtils.formatAmount(service.bestRate?.total, currency.code, locale)}  </small>
		<small className="text-muted"> {intl.formatMessage({ id: "text.tax" })}</small>
		<Link to={service.url} className="text-decoration-none">
			<Button variant="outline-primary" className="card-btn-reservation">
				{intl.formatMessage({ id: "general.moreRooms" })}
			</Button>
		</Link>
	</>);
}

export default CardRate;