import { useIntl } from "react-intl";
import { Container, Row, Col } from "react-bootstrap";
import WrapperCollage from "../../../components/galleries/WrapperColage";
import Collage from "../../../components/galleries/Collage/Collage";
import { useGlobalState, useDispatch } from "../../../store/StoreProvider";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import hotelsValidate from "../../../validate/hotelsValidate";
import serviceHotelAction from "../../../actions/serviceHotelAction";
import serviceType from "../../../constants/serviceTypeConstant";
import checkoutUtils from "../../../utils/checkoutUtils";
import storage from "../../../localstorage/storage";
import BoxDetailSeviceTitleRate from "../../../components/boxes/BoxDetailSeviceTitleRate";
import iconContants from "../../../constants/iconContants";
import SkeletonGallery from "../../../components/skeleton/SkeletonGallery/SkeletonGallery";
import SkeletonDetail from "../../../components/skeleton/SkeletonDetail/SkeletonDetail";
import CardHotelRoomLarge from "../../../components/cards/CardHotelRoomLarge";
import iconUtils from "../../../utils/iconUtils";
import breakpointConstants from "../../../constants/breakpointConstants";
import ReadMore from "../../../components/buttons/ReadMore/ReadMore";
import BoxWhitoutResults from "../../../components/boxes/BoxWhitoutResults/BoxWhitoutResults";
import { useMediaQuery } from "react-responsive";
import useCharterResponsive from "../../../hooks/useCharterResponsive";
import useModal from "../../../hooks/useModal";
import commonUtils from "../../../utils/commonUtils";
import { SearchBoxDetail } from "../../../components/searchBoxs";
import ServiceSection from "../../../components/section/ServiceSection";
import serviceReponseToEcommersMapperUtils from "../../../utils/serviceReponseToEcommersMapperUtils"
import GoogleTagManagerUtils from "../../../utils/GoogleTagManagerUtils"
import { UseFilterImages } from "../../../hooks/useFilterImages";
import htmlparse from 'html-react-parser'
import "../../../utils/htmlUtils";
import "./style.scss";
import _ from "lodash";
import moment from "moment/moment";

const HotelDetailPage = () => {
	const {
		hotel,
		currency,
		locale,
		authSession,
		resourceSite: auxResourceSite,
		pageOptions
	} = useGlobalState();
	const resourceSite = auxResourceSite.options;
	const pathnames = resourceSite.siteRoutes;
	const searchHotelParams = resourceSite.searchHotelParams;
	const intl = useIntl();
	const dispatch = useDispatch();
	const sourceMarket = "MX";
	const localizedUrlBooking = `/${locale}${pathnames.reservation.pathname}`;
	let [searchParams] = useSearchParams();
	const [expanded, setExpanded] = useState(true);
	const [expandedAmenities, setExpandedAmenities] = useState(true);
	const [roomIndexSelected, setRoomIndexSelected] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const isMobile = useMediaQuery({ maxWidth: breakpointConstants.SM });
	const isFinish = !hotel.isFetching && hotel.isSuccess;
	const [isLoading, setIsLoading] = useState(true);
	const [showNoResult, setShowNoResult] = useState(false);
	const { images: gallery, loading: galleryLoading } = UseFilterImages({ data: hotel.gallery });

	const hiddenRoomCardImage = process.env?.REACT_APP_SITE_HIDDEN_ROOM_CARD_IMAGE === 'true';
	const hiddenCardContainerRoom =   process.env?.REACT_APP_SITE_HIDDEN_CARD_CONTAINER_ROOM === 'true';

	const styleClassRoomsSection = {

		blockWithOutImage:{
			contaner:"row row-content",
			item_card_room:"col-lg-6  col-xl-6 col-sm-12",
			hiddenImage:hiddenRoomCardImage,

		},
		listCard:{
			contaner:"container-rooms-cards",
		}

	}

	useEffect(() => {
		if (!isLoading) {
			const hasResults = (hotel.isSuccess && hotel.rooms && hotel.rooms.length > 0);
			setShowNoResult(!hasResults);
		}
	}, [isLoading])


	const selectIndexRoomByRatekey = (rooms, query) => {
		if (rooms.length > 0) {
			rooms.map((room, i) => {
				let ratekey = room.rateKeyLead.split(`|${sourceMarket}`)[0];
				if (ratekey === query.ratekey) {
					setRoomIndexSelected(i);
					return i;
				}
				return -1;
			});
		}
	};

	const GetIdService = () => {
		const { id } = useParams();
		return id;
	};

	const [query, setQuery] = useState({
		keyword: GetIdService(),
	});

	const [params, setParams] = useState(null);
	const [searchFixed, setSearchFixe] = useState(false);
	const updateMetaData = () => {
		pageOptions.title = pageOptions.title.replace(
			"#SERVICENAME",
			searchParams.get("sn")
		);
		pageOptions.titlePage = pageOptions?.titlePage ?? pageOptions.title;
		document.title = pageOptions.title;
	};

	useEffect(() => {
		updateMetaData();
	}, [locale, pageOptions.title]);

	const getHotel = async (parameters, queryUrl) => {
		let par = { 
			...parameters,
			sn: parameters.inputAutoValueStart.label
		};
		let destinationId, destination;
		const query = hotelsValidate.formatQueryDetailsFromParams({
			...parameters,
			currency: currency.code,
		});
		setShowNoResult(false);
		if (query != null) {
			setIsLoading(true);
			await serviceHotelAction.hotel(
				{ ...query, locale, sourceMarket: sourceMarket, authSession },
				dispatch,
				(response) => {
					destinationId = response.zoneId;
					destination = response.zone;
					par.inputAutoValueStart.label = response.name;

					setSearchFixe(false);
					selectIndexRoomByRatekey(response.rooms, queryUrl);
					let ecommerce = serviceReponseToEcommersMapperUtils.hotelDetailToEcommers(response);
					GoogleTagManagerUtils.viewItemEvent(ecommerce)
				},
				(error) => {
					console.error(error);
					setSearchFixe(true);
				}
			).finally(() => {
				destinationId = destinationId ?? searchParams.get("destinationkeyword");
				destination = destination ?? searchParams.get("destination");
				par.inputAutoValueStart.type = "HotelDetails";
				if(destinationId) {
					par.destinationkeyword = destinationId;
					searchParams.set("destinationkeyword", destinationId);
				}
				if(destination) {
					par.destination = destination;
					searchParams.set("destination", destination);
				}
				setParams(par);
				setIsLoading(false);
			});
		}
	};
	const handleExpanded = () => setExpanded((prev) => !prev);
	const handleExpandedAmenities = () => setExpandedAmenities((prev) => !prev);
	const handleNumberImagesClick = () => setShowModal(prev => !prev);

	useEffect(() => {
		window.scrollTo(0, 0);
		let auxQuery = {
			...query,
			checkin: searchParams.get("checkin"),
			checkout: searchParams.get("checkout"),
			paxes: searchParams.get("paxes"),
			ratekey: searchParams.get("ratekey"),
			sn: searchParams.get("sn"),
			destination: searchParams.get("destination"),
			addDays: searchParams.get("addDays"),
			nights: searchParams.get("nights"),
		};

		auxQuery  = hotelsValidate.addRageDateFromURl(auxQuery);
		updateMetaData()
		setQuery(auxQuery);
		const auxParams = hotelsValidate.paramsHotelFormat(
			auxQuery,
			searchHotelParams.paramsRoom.rules
		);
		setParams(auxParams);
		getHotel(auxParams, auxQuery);
	}, [currency, locale]);

	//TODO get select room to go to reservation
	const onClickReservation = (room) => {
		const newHotel = { ...hotel };
		newHotel.rooms = [];
		newHotel.rooms.push(room);
		try {
			const ecommerce = serviceReponseToEcommersMapperUtils.hotelDetailRoomToEcommers(newHotel);
			GoogleTagManagerUtils.addToCartEvent(ecommerce);
		} catch (error) {
			console.error(error);
		}
		// create shooping cart
		const request = checkoutUtils.reservationHotel(
			newHotel,
			params,
			gallery[0]
		);

		storage.setLocalStorage(
			request.uuid,
			request,
			process.env.REACT_APP_SHOPPING_SESSION_CART
		);
		const location = {
			pathname: localizedUrlBooking,
			search: `?${new URLSearchParams(
				{
					cart: request.uuid,
					sn: searchParams.get("sn"),
					checkin: query.checkin,
					checkout: query.checkout,
					paxes: query.paxes,
					destination: searchParams.get("destination"),
					destinationkeyword: searchParams.get("destinationkeyword"),
					s: searchParams.get("s"),
				}).toString()}`,
		};
		window.location.href = location.pathname + location.search;
	};

	const getInfoDetailHotel = () => {
		return {
			type: serviceType.HOTEL,
			informationBoxServiceRate: {
				classs: "bg-rate",
				hasOffers: hotel.bestRate?.hasOffers,
				rateDiscount: hotel.bestRate.rateWithoutPromotional,
				classsThrough: hotel.bestRate?.hasOffers ? "trought" : "",
				textFrom: intl.formatMessage({ id: "general.from" }),
				rate: hotel.bestRate?.totalPerNight,
				currency: currency.code,
				rateWithoutTax: hotel.bestRate?.totalPerNightWithoutTaxe,
				total: hotel.bestRate?.total,
				informations: [
					intl.formatMessage({ id: "general.bestPricePerRoom" }),
					intl.formatMessage({ id: "general.bestPricePerRoomTaxes" }),
				],
				textButton: intl.formatMessage({ id: "general.moreRooms" }),
			},
			informationBoxIconText: [
				{
					icon: iconUtils.getIconMealPlanType(hotel.bestRate?.mealPlanCode),
					classIcon: "",
					text: hotel.bestRate?.mealPlan,
					classTex: "",
				},
			],
		};
	};

	const onClickToScrollRooms = (id) => {
		document.querySelector(id).scrollIntoView({
			behavior: "smooth",
		});
	};

	const resetInitialRender = (searchBooking) => {
		const { startDate, endDate, inputAutoValueStart } = searchBooking;
		const { type, keyword, label } = inputAutoValueStart;
		if (type === "Hotel" || type === "HotelDetails") {
			const auxQuery = {
				keyword: keyword,
				checkin: startDate,
				checkout: endDate,
				paxes: hotelsValidate.paxesQuery(searchBooking.rooms),
				destination: searchParams.get("destination"),
				destinationkeyword: searchParams.get("destinationkeyword"),
				sn: label //service name
			};
			setQuery(auxQuery);
			const auxParams = hotelsValidate.paramsHotelFormat(
				auxQuery,
				searchHotelParams.paramsRoom.rules
			);
			setParams(auxParams);
			getHotel(auxParams, {});
		}
	};

	const onChangeBooking = (searchBooking) => {
		resetInitialRender(searchBooking);
		try {
			GoogleTagManagerUtils.searchEvent(searchBooking, serviceType.HOTEL)
		} catch (error) {
			console.error(error)
		}
	};

	const breakPoint = {
		default: 200,
		sizes: [
			{
				type: useMediaQuery({ minWidth: "992px" }),
				charter: 374,
			},
			{
				type: useMediaQuery({ minWidth: "1072px" }),
				charter: 447,
			},
			{
				type: useMediaQuery({ minWidth: "1400px" }),
				charter: 517,
			},
		],
	};
	let charterResponsive = useCharterResponsive(breakPoint);

	const textModal = {
		text: [
			{
				title: intl.formatMessage({ id: "general.cotiza.2" }),
				text: intl.formatMessage({ id: "general.cotiza" }),
				redirect: false,
			},
		],
		btn: intl.formatMessage({ id: "general.accept" }),
		timer: process.env.REACT_APP_HOTEL_SESSION_DETAIL,
	};

	useModal(textModal, isFinish);
	const NoResult = () => {
		return <Container className="mt-2 mb-2">
			<Row>
				<Col>
					<br />
					<BoxWhitoutResults
						text={intl.formatMessage({
							id: "hotelDetailPage.hotelWhitoutResults",
						})}
					/>
				</Col>
			</Row>
		</Container>;
	};

	const GridGallery = () => {
		if (isLoading || galleryLoading) return <div className="container-xl"><SkeletonGallery /></div>
		if (!gallery || !commonUtils.validateItemsGallery(isMobile, gallery.length)) return;
		return <div className="gallery-container">
			<Collage isMobile={isMobile} locale={locale} gallery={gallery} onClick={handleNumberImagesClick} />
			<WrapperCollage
				show={showModal}
				handleCloseModal={handleNumberImagesClick}
				gallery={hotel.gallery}
				title={hotel.name}
			/>
		</div>
	};

	const AboutOfHotel = () => {
		try {
			return (
				<>
					{hotel.aboutOfHotelHtml.containHtmlTag &&
						htmlparse(hotel.aboutOfHotel)}
					{hotel.aboutOfHotelHtml.containHtmlCode &&
						htmlparse(htmlparse(hotel.aboutOfHotel))}
					{!hotel.aboutOfHotelHtml.containHtmlCode &&
						!hotel.aboutOfHotelHtml.containHtmlTag &&
						htmlparse(hotel.aboutOfHotel)}
				</>
			);
		} catch {
			hotel.aboutOfHotel = "";
			return <></>;
		}
	};

	return (
		<div id="body-render" className="hotel-detail">
			<SearchBoxDetail
				searchParams={searchParams}
				params={params}
				hideBtn={hotel.isFetching}
				onChangeBooking={onChangeBooking}
				type={serviceType.HOTEL}
				service={hotel}
				searchFixed={searchFixed}
				onClickToScrollRooms={() => onClickToScrollRooms("#box-list-rooms")}
			/>
			{!showNoResult && <GridGallery />}
			{showNoResult && <NoResult />}
			{hotel.isFetching && (
				<Container className="pt-4 pb-4">
					<SkeletonDetail />
				</Container>
			)}
			<Container fluid="xl" className="pt-3 pb-3">
				{hotel.isSuccess && (
					<BoxDetailSeviceTitleRate
						{...getInfoDetailHotel()}
						service={hotel}
						onClickButton={() => onClickToScrollRooms("#box-list-rooms")}
					/>
				)}
			</Container>
			{hotel.isSuccess && hotel?.aboutOfHotel?.length && (
				<Container fluid="xl" className="py-1 pb-1 container-page">
					<div className="container-information-service-bg  bg-white p-4 mb-4">
						<div className={`${expanded ? "box-tittle-column-state-about-hotel" : ""}`}>
							<h2 className="details-title-description">
								{intl.formatMessage({
									id: "hotelDetailPage.titleAboutHotel",
								})}
							</h2>
							<div
								className={
									hotel?.aboutOfHotel?.length > charterResponsive
										? expanded
											? `box-info-hide`
											: `box-info-show`
										: `box-info-show`
								}
							>
								{AboutOfHotel()}
							</div>
						</div>
						{hotel.aboutOfHotel.length >= charterResponsive && (
							<ReadMore
								handleExpanded={handleExpanded}
								text={intl.formatMessage({ id: expanded ? "general.seeMore" : "general.seeLess" })}
								icon={iconUtils.icon(expanded ? iconContants.IOIOSARROWUP : iconContants.IOIOSARROWDOWN)}
							/>
						)}
					</div>
					{hotel.services && (
						<div className="container-information-service-bg p-4 bg-white mb-4">
							<ServiceSection
								hotel={hotel}
								handleExpandedServices={handleExpandedAmenities}
								expandedServices={expandedAmenities}
							/>
						</div>
					)}
				</Container>
			)}
			{hotel.isSuccess && hotel.rooms.length && (
				<Container fluid="xl" id="box-list-rooms" className="mb-5">
					<div className= {`${!hiddenCardContainerRoom ? "container-information-service-bg  bg-white p-4 mb-4" : "p-1 mb-4"}  hotelroom`}>
						<h2 id="title-rooms" className="details-title-description">
							{intl.formatMessage({ id: "general.titleRooms" })}
						</h2>
						<div className={` ${hiddenRoomCardImage ?  styleClassRoomsSection.blockWithOutImage.contaner: styleClassRoomsSection.listCard.contaner }`}>
							{hotel.rooms.map((room, i) => {
								return (
									<CardHotelRoomLarge
										key={i}
										currentClass={`mb-3 ${i === roomIndexSelected && hotel.rooms.length > 1 ? "selected-active" : ""}`}
										room={room}
										itemCardRoomClass={hiddenRoomCardImage ?  styleClassRoomsSection.blockWithOutImage.item_card_room:""  }
										hiddenImage={hiddenRoomCardImage && styleClassRoomsSection.blockWithOutImage.hiddenImage}
										rateDescriptions={[
											intl.formatMessage({ id: "general.bestPricePerRoom" }),
											intl.formatMessage({ id: "general.bestPricePerRoomTaxes" }),
										]}
										textButton={intl.formatMessage({ id: "general.reservation" })}
										onClickReservation={() => onClickReservation(room)}
									/>
								);
							})}
						</div>
					</div>
				</Container>
			)}
		</div>
	);
};

export default HotelDetailPage;