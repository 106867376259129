import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useGlobalState } from "../../store/StoreProvider";
import thigsToDoUtils from "../../utils/thigsToDoUtils";
import hotelsValidate from "../../validate/hotelsValidate";
import serviceListAction from "../../actions/serviceListAction";
import SlidersComponent from "@mzp/commons/dist/layout/Sliders";
import { SkeletonThingsToDoHome } from "../skeleton/SkeletonThingsToDoHome/SkeletonThingsToDoHome";
import localeUtils from "../../utils/localeUtils";

const SlideTopThigsToDoSection = (props) => {
	const { options } = props;
	const { authSession, locale, currency, cmsComponents, pageOptions } = useGlobalState();
	const [items, setItems] = useState({});
	const [hide, setHide] = useState(true);
	const [settingsAux, setTingsAux] = useState(thigsToDoUtils.settings(4));
	const favoriteHotels = (options, authSession) => {
		if (options.itemsFlag) {
			setItems(options);
		} else {

			if (cmsComponents.isSuccess && options?.items.length === 0) {
				options = slugComponentOptions(options)
			}

			const sourceMarket = "MX";
			const hotelIds = options?.items ?? [];
			const addStart = options?.checkinDateNumber ?? 15;
			const addEnd = options?.checkoutDateNumber ?? 18;
			const language = localeUtils.handleLanguage(locale);

			if (hotelIds.length > 0) {
				const query = hotelsValidate.paramsTopHotels(currency.code, hotelIds, sourceMarket, language, addStart, addEnd);
				serviceListAction.hotelsById(
					{
						...query,
						locale,
						sourceMarket,
						authSession,
						pathNext: `/${locale}/hotel/`,
					},
					(response) => {
						if (response.items.length > 0) {
							setHide(true);
							if (response.items.length < 4) {
								setHide(false);
								setTingsAux(thigsToDoUtils.settings(response.items.length));
							}

							setItems({
								showComponent: true,
								typeCard: "hotel",
								title: options.title,
								items: thigsToDoUtils.getRecommended(response.items),
							});
						}
					},
					(error) => {
						console.error(error);
						setHide(false);
						setItems({});
					}
				);
			} else {
				setHide(false);
			}
		}
	};

	const slugComponentOptions = (options) => {
		let components = cmsComponents?.pageOptions
			?.filter(
				(x) =>
					x.routeType === pageOptions.routeType &&
					x.siteLocale === pageOptions.siteLocale
			)
			.flatMap((x) => x.itemsComponent);
		return components[0];

	}

	const hotels = (options) => {
		setHide(options?.items?.length > 0 ? true : false);
		setItems(options);
	};

	const getItems = async (options, authSession) => {
		switch (options.typeCard) {
			case "hotel":
				return favoriteHotels(options, authSession);
			default:
				return hotels(options);
		}
	};

	const handleRequest = async () => await getItems(options, authSession);

	useEffect(() => {
		setHide(true);
		handleRequest();
		return () => {
			setHide(false);
			setItems({});
		};
	}, [options?.typeCard, locale, currency, cmsComponents?.pageOptions]);

	const stylesRow = {
		justifyContent: "space-between",
		display: "flex",
		gap: "1rem",
		height: "330px",
		overflow: "hidden",
	};

	const styles = {
		display: "flex",
		justifyContent: "center",
		paddingTop: "2rem",
		paddingBottom: "2rem",
	};

	
	return (
		<>
		  {hide && (
			<>
			  {items.showComponent && items ? (
				<section
				  className={`top-things-to-do-section ${options?.typeCard}`}
				>
				  <SlidersComponent settings={settingsAux} {...items}>
					{thigsToDoUtils.handleCard(options.typeCard, currency)}
				  </SlidersComponent>
				</section>
			  ) : (
				<Container style={styles}>
				  <Row style={stylesRow}>
					<SkeletonThingsToDoHome />
				  </Row>
				</Container>
			  )}
			</>
		  )}
		</>
	  );
};

export default SlideTopThigsToDoSection;