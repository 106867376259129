import languageUtils from "../utils/languageUtils";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    //languageSite : "es-mx",
    //currency: "MXN"
    locale: languageUtils.defaultLocale,
    currency: {
        name: "Pesos mexicanos",
        code: "MXN"
    },
    resourceSite: {
        isFetching: false,
        isSuccess: false,
        isError: false,
        options: {}
    },
    searchHotels: {
        items: [],
        isFetching: false,
    },
    pageOptions: {
        type: null,
        isFetching: false,
        isSuccess: false,
        isError: false
    },
    hotel : {
        id : 0,
        isFetching: false,
        isSuccess: false,
        isError: false
    },
    packages : {
        id : 0,
        isFetching: false,
        isSuccess: false,
        isError: false
    },
    flight : {
        id : 0,
        isFetching: false,
        isSuccess: false,
        isError: false
    },
    tour : {
        id : 0,
        isFetching: false,
        isSuccess: false,
        isError: false
    },
    payment: {
        isFetching: false,
        isSuccess: false,
        isError: false
    },
    authSession: {
        isFetching: false,
        isSuccess: false,
        isError: false
    },
    siteInformation: {
        isFetching: false,
        isSuccess: false,
        isError: false
    },
    cmsComponents: {
        isFetching: false,
        isSuccess: false,
        isError: false
    }
}