import types from "../constants/reducers/siteInformationConstants";
import resourceSiteAction from "../actions/resourceSiteAction";
import storage from "../localstorage/storage";
import commonUtils from "../utils/commonUtils";
import authAction from "../actions/authAction";
 import useGetNameAuthStorage from "../hooks/useGetNameAuthStorage";
import businessModel from "../constants/businessModel";
import { localStorageConst } from "../constants/common";
//TODO: get authSession
const get = (siteData) => {
  siteData.isFetching = false;
  siteData.isSuccess = true;
  siteData.isError = false;
  return { type: types.SITE, siteData };
};
const fetching = () => {
  const siteData = {
    isFetching: true,
    isSuccess: false,
    isError: false,
  };
  return { type: types.SITE_FETCHING, siteData };
};
const initial = () => {
  const siteData = {
    isFetching: false,
    isSuccess: false,
    isError: false,
  };
  return { type: types.SITE_FETCHING, siteData };
};
const getError = () => {
  const siteData = {
    isFetching: false,
    isSuccess: false,
    isError: true,
  };
  return { type: types.SITE_ERROR, siteData };
};


//TODO Get Site data with context and use Ditpatch
const siteData = async (request, resourceSite,onSuccessSiteInformation, dispatch, onError) => {
  dispatch(fetching());
  await fetch(`${process.env.REACT_APP_BFF_AUTH_API}api/Site/SiteInformation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  })
    .then((response) => {
      if (!response.ok) throw Error(response.status);
      return response.json();
    })
    .then((data) => {

      if(data?.siteGoogleTagManager.length>0){
        storage.setLocalStorage(localStorageConst.GTM,data.siteGoogleTagManager,process.env.REACT_APP_SITERESOURCE_SESSION)
      }
 
      storage.setCookie(localStorageConst.SITE,data.site.id,process.env.REACT_APP_SITE_COOKIE)
      const environmentKeys = commonUtils.getEnvironmentKeys(
        resourceSite.siteLocale
      );

if( data.site.businessModelId === businessModel.B2C){
  
      const authConst = useGetNameAuthStorage();
      const auxSession = storage.getLocalStorage(authConst);

        const  request = {
              emailAddress: process.env.REACT_APP_AUTH_EMAIL,
              password: process.env.REACT_APP_AUTH_PASSWORD,
              siteId: data.site.id
          };

     
    if(!auxSession){
      authAction.auth(request, dispatch,
        (response) => {
          storage.setLocalStorage(authConst, response, response.expires - 1);
          
          //navigate(location);
        },
        (error) => {
          //TODO cargar pagina error, llamar nuevamente, etc,
      
        })   
    }
}
            
      dispatch(get(data));
      storage.setLocalStorage(
        environmentKeys.siteInfoKey,
        data,
        process.env.REACT_APP_SITE_SESSION
      );
      siteResourceFormat(data, resourceSite, dispatch);
      onSuccessSiteInformation()
    })
    .catch((ex) => {
      dispatch(getError());
      if (onError && typeof onError === "function") {
        onError(ex);
      }
    });
};

const setSiteData = async (request, dispatch) => {
  dispatch(get(request));
};
const cleanSiteData = async (dispatch) => {
  dispatch(initial());
};

const siteResourceFormat = (siteInformation, resourceSite, dispatch) => {

  setSiteData(siteInformation,dispatch)
  const environmentKeys = commonUtils.getEnvironmentKeys(
    resourceSite.siteLocale
  );
   // currencies
  const currenciesItems =
    siteInformation.currencies.length > 0
      ? siteInformation.currencies.map((item) => {
          return {
            name: item,
            value: item,
          };
        })
      : [];

  if (currenciesItems.length > 0){
      resourceSite.currenciesItems = currenciesItems;
  }
   
  // cultures
  if (siteInformation.cultures.length > 0) {
    // filtrar los idiomas/culturas permitidas
    siteInformation.cultures = siteInformation.cultures.map((c) =>
      c.toLowerCase()
    );
    resourceSite.languagesItems = resourceSite.languagesItems.filter(
      (language) => siteInformation.cultures.includes(language.locale)
    );
  }
   
//header
  resourceSite.home.imgPath = siteInformation.site.urlLogo ?? resourceSite.home.imgPath
  resourceSite.home.alt = siteInformation.site.name ?? resourceSite.home.alt
// footers
  resourceSite.footerInformation.footer.alt = siteInformation.site.name ?? resourceSite.footerInformation.footer.alt;
  resourceSite.footerInformation.footer.phoneAsistencia = siteInformation.site.phone ?? resourceSite.footerInformation.footer.phoneAsistencia; 
  resourceSite.footerInformation.footer.emailAsistencia = siteInformation.site.email ?? resourceSite.footerInformation.footer.emailAsistencia;

  resourceSiteAction.update(resourceSite, dispatch);

  storage.setLocalStorage(
    environmentKeys.siteResourceKey,
    resourceSite,
    process.env.REACT_APP_SITERESOURCE_SESSION
  ); 
};

const siteDataAction = {
  siteData,
  setSiteData,
  cleanSiteData,
  siteResourceFormat
};
export default siteDataAction;
