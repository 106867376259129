
import { Col,Card, Row } from 'react-bootstrap';
import { useIntl } from "react-intl";
import iconUtils from '../../../utils/iconUtils'
import iconContants from '../../../constants/iconContants';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';
import "./components/style.scss";
import rateUtils from "../../../utils/rateUtils";

//TODO card for activities in tour detail
function SkeletonDetail(props) {
  const { number,  } = props;
  const intl = useIntl();

  return (  
    
    <>
        <div className="d-flex flex-column w-100">
            <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
            </Placeholder>
            <hr className={'divider-horizontal'} />
        </div>
        <Row className="pt-4">
            <Col className="" md={8}>
            <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
            </Placeholder>
            <Row>
                <Col md={6}>
                    <div className="d-flex">                                
                        <Placeholder className="w-100" animation="glow">
                            <Placeholder xs={6} /> <Placeholder xs={5} />                                                     
                        </Placeholder>
                    </div>
                    <div className="d-flex">                                
                        <Placeholder className="w-100" animation="glow">
                            <Placeholder xs={6} /> <Placeholder xs={5} />                                                     
                        </Placeholder>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="d-flex">                                
                        <Placeholder className="w-100" animation="glow">
                            <Placeholder xs={6} /> <Placeholder xs={5} />                                                     
                        </Placeholder>
                    </div>
                    <div className="d-flex">                                
                        <Placeholder className="w-100" animation="glow">
                            <Placeholder xs={6} /> <Placeholder xs={5} />                                                     
                        </Placeholder>
                    </div>
                </Col>
            </Row>
            </Col>
            <Col className="text-start text-md-end " md={4}>
                <div className="box-rate-price">
                    <div className="box-service-rate-tour bg-rate">   
                        <Placeholder className="w-100" animation="glow">
                            <Placeholder xs={6} /> <Placeholder xs={5} /> 
                            <Placeholder xs={6} /> <Placeholder xs={5} />
                            <Placeholder xs={6} /> <Placeholder xs={5} />
                            <Placeholder.Button variant="primary" xs={6} />                                                     
                        </Placeholder>                        
                    </div>
                </div>
                
            </Col>
        </Row>

  </>
           
  );
}

export default SkeletonDetail;