import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { TimelineFlight } from "../../feedback";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import BoxDetailFlight from "../BoxDetailFlight/BoxDetailFlight";
import BoxFamilyRateFlight from "../BoxFamilyRateFlight/BoxFamilyRateFlight";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import serviceType from "../../../constants/serviceTypeConstant";
import BoxIconText from "../BoxIconText/BoxIconText";
import iconUtils from "../../../utils/iconUtils";
import iconContants from "../../../constants/iconContants";
import rateUtils from "../../../utils/rateUtils";
import "./components/style.scss";

const BoxGrdFlight = (props) => {
  const {
    flightItem,
    id,
    position,
    family,
    typePrice,
    typeCategory,
    idFlight,
  } = props;
  const intl = useIntl();



  const idItem = "#item-flight-"+id;
  const onClick = (item) => {
    
    const { onClickButton } = props;
    onClickButton && onClickButton("#"+item);
  };

/*   const toggleActive = (value) => {
     const {handleActiveDrop} = props;
     handleActiveDrop && handleActiveDrop(value);
   } */
  const [activeDrop, setActiveDrop] = useLocalStorage('activeDrop', '');

  function toggleActive(id) {
    if (activeDrop === id) {
      setActiveDrop(null);
    } else {
      setActiveDrop(id);
    }
  }

  const changeType = (value) => {
   if(value == "regular"){
    setActiveDrop("0")
   }
 }

 useEffect(() => {
  setActiveDrop(null)
  },[])

  function CustomToggle({ children, eventKey, clase }) {
    return (
      <button
        type="button"
        className={`btn-open-panel ${clase} ${eventKey == 0 ? "priceRegular" : eventKey == 1 ? "priceVclup" : ""} `} 
        /* style={{ backgroundColor: "pink" }} */
        onClick={() => toggleActive(eventKey)}
      >
        {children}
      </button>
    );
  }

  const renderTooltip = (props) => (
    <Popover id="button-tooltip" {...props}>
        <span className="p-2 d-block toolTip-list">
          <b>v.club</b> es la membresía que siempre te permite viajar al precio más bajo, independientemente de cómo elijas volar.
        </span>
    </Popover>
);

const [openModalDetail, setOpenModalDetail] = useState(false);
const [fullscreen, setFullscreen] = useState(true);

function handleOpenDetail(breakpoint) {
  setFullscreen(breakpoint);
  setOpenModalDetail(!openModalDetail);
}

const handleCloseModal = () => setOpenModalDetail(false);

const onClickRate = (value, newValue) => {
  setActiveDrop(null);
  const {onClickFlightRate} = props;
  onClickFlightRate && onClickFlightRate(value, newValue);
};


useEffect(() => {
 
},[])

  return (
    <>
      <div className="box-list-flight mb-4" id={"item-flight-"+id}>
        <div className="card-ribbon">
            <span><small>Toluca</small></span>
        </div>
        <Row>
          <Col md={7}>
            <div className="p-4 pb-2 pb-lg-4">
              <TimelineFlight
                arrival={flightItem.arrival}
                departure={flightItem.departure}
                time={flightItem.flightDuration}
                scale={flightItem.scale}
                flightSegment={flightItem.flightSegment}
              />
              <div className="info-flight">
                <div>Y4 102 Operado por Volaris México</div>
                <div className="flight-btn-detail" onClick={() => handleOpenDetail('md-down')}>Detalles</div>
              </div>
            </div>            
          </Col>
          <Col md={5}>
            <Row className="g-0 text-center h-100">
            {typePrice != "club"  &&(
              <Col className="type-regular" onClick={() => onClick("item-flight-"+id)}>
                <CustomToggle eventKey="0" clase={ idFlight == idItem &&  activeDrop == 0 ? "panel-close" :  idFlight == idItem && activeDrop == 1 ? "borderRegular" : "panel-open"}>
                    <div className="headerTab d-md-none">
                      <h6><span>Precios Regulares</span></h6>
                    </div>
                    { idFlight == idItem && activeDrop == 0 ? 
                    (<div className="price-tab">
                      <span>{iconUtils.icon(iconContants.IOIOSARROWDOWN)}</span>
                      <span>Cerrar</span>
                    </div>) 
                    : (<div className="price-tab">
                      <span className="seat-left d-none">1 asientos disponibles</span>
                      <span className="priceDiscounted">$218 MXN</span>
                      <span className="price">$188 <small>MXN</small></span>
                      <span>{iconUtils.icon(iconContants.IOIOSARROWUP)}</span>
                    </div>) }
                    
                </CustomToggle>
              </Col>
              )}
              {typePrice != "regular"  &&(
                <Col className="type-vclub" onClick={() => onClick("item-flight-"+id)}>
                <CustomToggle eventKey="1" clase={ idFlight == idItem && activeDrop == 1 ? "panel-close" :  idFlight == idItem && activeDrop == 0 ? "borderClub" : "panel-open"}>
                  <div className="headerTab d-md-none">
                      <h6><span>Precios v.club</span>
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                        >
                          <span className="tooltip-club"> {iconUtils.icon(iconContants.AIOUTLINEEXCLAMATINCIRCLE)}</span>
                        </OverlayTrigger>
                      </h6>
                    </div>
                {idFlight == idItem && activeDrop == 1 ? 
                    (<div className="price-tab">
                      <span>{iconUtils.icon(iconContants.IOIOSARROWDOWN)}</span>
                      <span>Cerrar</span>
                    </div>) 
                    : (<div className="price-tab">
                      <span className="seat-left">1 asientos disponibles</span>
                      <span className="priceDiscounted">$92 MXN</span>
                      <span className="price">$82 <small>MXN</small></span>
                      <span>{iconUtils.icon(iconContants.IOIOSARROWUP)}</span>
                    </div>) }
                </CustomToggle>
                </Col>
               )}                                         
            </Row>                        
          </Col>
          <Col>
            <Accordion activeKey={idFlight == idItem ? activeDrop : null}>
            {family.map((item, i) => {          
              return (<Accordion.Item eventKey={i.toString()} key={i}>
                {/*  <Accordion.Header>Accordion Item #1</Accordion.Header> */}
                <Accordion.Body>                
                    <BoxFamilyRateFlight 
                      typeFamily={item.type} 
                      family={item} 
                      onClickRate={onClickRate} 
                      position={position} 
                      typePrice={typePrice} 
                      typeCategory={typeCategory}
                      changeType={changeType}
                      />               
                </Accordion.Body>
              </Accordion.Item>)
              })}
            </Accordion>
          </Col>
        </Row>
        <BoxDetailFlight handleCloseModal={handleCloseModal} show={openModalDetail} fullscreen={fullscreen} scale={true}/>
      </div>
    </>
  );
};

export default BoxGrdFlight;
