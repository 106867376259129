export default {
	"voucherPage.titleHotelSucces": "Resumen de reservación",
	"voucherPage.titleTourSucces": "Tour",
	"voucherPage.titleError": "Cargando...",
	"voucherPage.room": "Habitación",
	"voucherPage.rooms": "Habitaciones",
	"voucherPage.reference": "Clave de reservación",
	"voucherPage.address": "Dirección",
	"voucherPage.referenceRoom": "Referencia",
	"voucherPage.date": "Fecha de reserva",
	"voucherPage.mealPlan": "Plan",
	"voucherPage.checkIn": "CheckIn",
	"voucherPage.checkOut": "CheckOut",
	"voucherPage.name": "Nombre",
	"voucherPage.adults": "Adultos",
	"voucherPage.childrens": "Niños",
	"voucherPage.titlePolitics": "Póliticas de cancelación",
	"voucherPage.amountPenalty": "Precio de penalización",
	"voucherPage.datePenalty": "Cancelación válida hasta",
	"voucherPage.titleIncluid": "¿Qué contiene?",
	"voucherPage.pay": "Pago",
	"voucherPage.publicRate": "Monto total",
	"voucherPage.netRate": "Monto total neto",
	"voucherPage.titleRateComents": "Comentarios acerca de la tárifa",
	"voucherPage.titleAminities": "Amenidades en la habitación",
	"voucherPage.year": "Año",
	"voucherPage.years": "Años",
	"voucherPage.methodPay": "Método de Pago",
	"voucherPage.creditCard": "Tarjeta de crédito",
	"voucherPage.policyNotes": "Nota: Políticas previstas en la hora y fecha del destino.",
	"voucherPage.form.special": "Solicitudes especiales",
	"voucherPage.detaild": "Detalle de la habitación",
	"voucherPage.reservationHolder": "Titular de la reserva",
	"voucherPage.SupplierNote": "Pagadero a través #SupplierName #VatNumber, actuando como agente respecto a la sociedad explotadora del Servicio.Los detalles de lo anterior, pueden ser provistos previa solicitud.",
	"voucherPage.HotelPhoneNumber": "Teléfono del hotel",
	"voucherPage.title": "Voucher",
	"voucherPage.cancellationDeadline": "Puedes cancelar hasta el ",
	"voucherPage.cancellationDeadlineMessage": "No puedes cancelar ni realizar cambios",
	"voucherStatic.title": "Se ha producido una demora en tu reserva",
	"voucherStatic.subTitle": "Te pedimos disculpas por la demora y agradecemos tu paciencia",
	"voucherStatic.text": "Las reservas están tardando más de lo habitual porque estamos recibiendo un gran número de reservas nuevas. No deberíamos tardar más de 24 hrs en procesar tu reserva, nos pondremos en contacto contigo en cuanto haya alguna novedad. ",
	"voucherStatic.PaymentNotFound": `<p>Estimado cliente,</p>
		<p>Te informamos que estamos experimentando dificultades para recuperar la informaci&oacute;n del pago. No es necesario que hagas la reserva nuevamente.</p>
		<p>Te pedimos disculpas por cualquier inconveniente. Estamos trabajando en la soluci&oacute;n inmediata, en breve te haremos llegar tu confirmaci&oacute;n.</p>
		<p>Apreciamos tu comprensi&oacute;n y paciencia en este asunto.</p>`,
	"voucherStatic.PaymentNotValid": `<p>Estimado cliente,</p>
		<p>Queremos informarte que hemos recibido tu reservaci&oacute;n con &eacute;xito. Sin embargo, queremos comunicarte que despu&eacute;s de un proceso de verificaci&oacute;n por parte de nuestro proveedor de servicios de pago, hemos recibido la notificaci&oacute;n de que el pago correspondiente a tu reservaci&oacute;n ha sido declinado.</p>
		<p>Si deseas continuar con tu compra, te recomendamos intentar pagar con otra forma de pago.</p>
		<p>Apreciamos tu comprensi&oacute;n y paciencia en este asunto.</p>`,
	"voucherStatic.BookingError": `<p>Estimado cliente,</p>
		<p>Te informamos que tu pago se proces&oacute; exitosamente, pero estamos experimentando dificultades para generar tu confirmaci&oacute;n. No es necesario que hagas la reserva nuevamente.</p>
		<p>Te pedimos disculpas por cualquier inconveniente. Estamos trabajando en la soluci&oacute;n inmediata, en breve te haremos llegar tu confirmaci&oacute;n.</p>
		<p>Apreciamos tu comprensi&oacute;n y paciencia en este asunto.</p>`,
	"voucherStatic.PaidAndNotConfirmed": `<p>Estimado cliente,</p>
		<p>Te informamos que tu reserva ha sido procesada exitosamente y el pago se ha completado. Estamos experimentando dificultades para mostrarte la confirmaci&oacute;n.</p>
		<p>Te pedimos disculpas por cualquier inconveniente. Estamos trabajando en la soluci&oacute;n inmediata, en breve te haremos llegar tu confirmaci&oacute;n.</p>
		<p>Apreciamos tu comprensi&oacute;n y paciencia en este asunto.</p>`,
	"voucherStatic.BookNotFound": `<p>Estimado cliente,</p>
		<p>Te informamos que tu reserva ha sido procesada exitosamente y el pago se ha completado. Estamos experimentando dificultades para mostrarte la confirmaci&oacute;n.</p>
		<p>Te pedimos disculpas por cualquier inconveniente. Estamos trabajando en la soluci&oacute;n inmediata, en breve te haremos llegar tu confirmaci&oacute;n.</p>
		<p>Apreciamos tu comprensi&oacute;n y paciencia en este asunto.</p>`,
	"voucherStatic.InternalServerError": `<p>Estimado cliente,</p>
		<p>Queremos agradecerte por elegir nuestros servicios para tu compra. Sin embargo queremos informarte que experimentamos un problema inesperado durante el procesamiento de tu reserva.</p>
		<p>Te pedimos disculpas por cualquier inconveniente. Estamos trabajando en una soluci&oacute;n inmediata.</p>
		<p>Apreciamos tu comprensi&oacute;n y paciencia en este asunto.</p>`
};