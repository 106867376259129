import hotelUtils from "../utils/hotelUtils";

const formatReservation = (d, query) => {
  let rooms = null;
  let orderItems = [];
  var reference = "";
  var provider = "";
  var nameService = "";
  var checkIn = "";
  var checkOut = "";
  const items = [];
  const auxItems = d.length
    ? d.map((d) => {
        d.hotelBookings.map((item) => {
          provider = item.supplier;
          nameService = item.name;
          checkIn = item.checkIn;
          checkOut = item.checkOut;
          reference = item.reference;
          rooms = item.rooms;
        });

        if (d.hotelBookings[0]?.rooms[0]) {
          var auxRooms = hotelUtils.countGuestsByRoom(
            d.hotelBookings[0].rooms[0], d.id
          );
          const auxMapper = {
            reservationId: d.id,
            reference: reference,
            customer: {
              id: d.id,
              name: d.holder.name,
              lastName: d.holder.surname,
              years: d.holder.age,
              email: d.holder.email,
            },
            status: d.bookingStatus,
            provider: provider,
            seller: {
              id: 565,
              name: d.user.name,
              lastName: " ",
            },
            rooms: auxRooms ?? [],
            netRate: d.netRate ?? "",
            netRateCurrency: d.netRateCurrency ?? "",
            publicRate: d.publicRate ?? "",
            publicRateCurrency: d.publicRateCurrency ?? "",
            dateReservation: d.bookingDate ?? "",
            cancellationDate: d.cancellationDate ?? "",
            checkIn: checkIn ?? "",
            checkOut: checkOut ?? "",
            service: {
              name: nameService,
              checkIn: checkIn,
              checkOut: checkOut,
            },
            statusReservation: d.bookingStatus,
            statusPayment: d.paymentStatus,
            isPublicRate: true,
            hasCancellationPolicies: true,
            isRefundable: true,
            dateLimitCancellation: d.cancellationDate,
            dateLimitPayment: d.payLimitDate,
            ...d,
          };
          items.push(auxMapper);
        } else {
          return {};
        }
      })
    : [];
  items.sort((a, b) => {
    return a.dateReservation < b.dateReservation ? 1 : -1;
  });

  return items;
};

const reservationValidate = {
  formatReservation,
};

export default reservationValidate;
