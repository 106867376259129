// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "tourDetailPage.searchTitle" : "Los mejores tours alrededor del mundo.",
    "tourDetailPage.titleInfoGeneral" : "Información general",
    "tourDetailPage.titleExperience" : "Tu experiencia",
    "tourDetailPage.infoCancelation" : "Cancelación gratuita",
    "tourDetailPage.infoCovid" : "Precauciones contra la Covid-19",
    "tourDetailPage.infoValidateDay" : "Validez 1 día",
    "tourDetailPage.infoLanguaje" : "Idioma",
    "tourVoucher.info" : " Información / Itinerario del Tour",
    "tourVoucher.cancelation" : " Políticas de cancelación del Tour",    
}