import { useEffect } from "react";
import Swal from "sweetalert2";
import dateUtils from "../utils/dateUtils";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

// validations for session modals

const useModalVoucher = (locale) => {
  const intl = useIntl();
  let navigate = useNavigate();
  var firstModal = process.env.REACT_APP_CHECKOUT_SESSION_FIRST_ALERT ?? 12;
  var secondModal = process.env.REACT_APP_CHECKOUT_SESSION_SECOND_ALERT ?? 15;
  let colorModal = require(`../theme/site/${process.env.REACT_APP_BRANDING}/vars.scss`).default;

  if (firstModal >= secondModal) {
    firstModal = 12;
    secondModal = 15;
  }

  var timeValidation = secondModal - firstModal;

  const textModal = [
    {
      title: intl.formatMessage({ id: "checkout.cart.2" }),

      text: `${intl.formatMessage(
        { id: "checkout.cart.1" },
        { count: timeValidation }
      )}`,

      redirect: false,
    },
    {
      title: intl.formatMessage({ id: "checkout.cart.3" }),
      text: intl.formatMessage({ id: "checkout.cart.4" }),
      redirect: true,
    },
  ];

  const modalCheckout = (item) => {
    Swal.fire({
      icon: item?.icon ?? "info",
      title: item?.title,
      text: item?.text,
       confirmButtonColor: colorModal.modalColor,   
      confirmButtonText: intl.formatMessage({
        id: "checkout.confirmButtonText",
      }),
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (item.redirect) {
        if (result.isConfirmed) {
          navigate(`/${locale}`);
        }
      }
    });
  };

  // RULES: the second modal closes the first one and redirects home in the current language
  useEffect(() => {
    const timerFirst = setTimeout(() => {
      modalCheckout(textModal[0]);
    }, dateUtils.minutesToSecondsDate(firstModal));

    const timerSecond = setTimeout(() => {
      clearTimeout(timerFirst);
      modalCheckout(textModal[1]);
    }, dateUtils.minutesToSecondsDate(secondModal));

    return () => {
      Swal.close();
      clearTimeout(timerFirst);
      clearTimeout(timerSecond);
    };
  }, []);
};

export default useModalVoucher;
