import serviceType from "../constants/serviceTypeConstant";
import localeUtils from "../utils/localeUtils";
const formatType = (dataType) => {
    switch(dataType) {
        case "Zone":
            return serviceType.DESTINATION;
        case "Point":
        default:
            return serviceType.HOTEL;
    }
}

const formatList = (data) => {

    const items = data.length > 0 ? data.map(d => {
        return {
            name: localeUtils.decodeURIComponent( d.name ?? d.match),
            code: d.id,
            type: formatType(d.type),
            keyword : d.keyword
        }
    }) : []

    return items;
}

const autocompleteValidate = {
    formatList,
    formatType
}

export default autocompleteValidate;