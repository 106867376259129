import iconContants from "../../constants/iconContants";
import languageConstants from "../../constants/languageConstants";
import pageLinksConstants from "../../constants/pageLinksConstants";
import footerComponent from "./api/components/footerComponentEN";
import { viewGridListConst } from "../../constants/common";
import iconUtils from "@mzp/commons/dist/utils/iconUtils";
import iconMZPContants from "@mzp/commons/dist/constants/iconContants";
import images from "../../theme/ImageSite";


const resource = {
	home: {
		alt: "",
		href: "/en-us",
		hrefWithSession: "/en-us",
		hrefWithOutSession: "/en-us",
		imgPath: images?.headerLogo ?? '',
	},
	menu: [
		{
			name: "activities",
			href: "https://www.nexustours.com/services/?utm_source=nexusvacations&utm_medium=header",
			icon: iconContants.ACTIVITY,
			external:true,
			target:"_blank"
		},
		{
			name: "transfers",
			href: "https://www.nexustours.com/transfers/?utm_source=nexusvacations&utm_medium=header",
			icon: iconContants.SHUTTER,
			external:true,
			target:"_blank"
		},
	],
	languagesItems: [
		{
			name: "Spanish",
			value: 1,
			locale: "es-mx"
		},
		{
			name: "English",
			value: 2,
			locale: "en-us"
		}
	],
	currenciesItems: [
		{
			name: "MXN",
			value: "MXN"
		},
		{
			name: "USD",
			value: "USD"
		}
	],
	siteLocale: languageConstants.EN,
	siteRoutes: {
		home: {
			pathname: "/",
			basename: "/"
		},
		notFound: {
			pathname: "/notfound",
			basename: "/notfound",
		},
		hotels: {
			pathname: "/hotels",
			basename: "/hotels"
		},
		hotel: {
			pathname: "/hotel/",
			basename: "/hotel/:keyname/:id"
		},
		bookingHotel: {
			pathname: "/reservation/hotel",
			basename: "/reservation/hotel"
		},
		reservation: {
			pathname: "/checkout",
			basename: "/checkout"
		},
		voucherHotel: {
			pathname: "/voucher",
			basename: "/voucher"
		},
		voucherStatic: {
			pathname: "/voucher-pending",
			basename: "/voucher-pending",
		},
	},
	redirectPageDefault: "/",
	siteRouter: [
		{
			pathname: pageLinksConstants.HOME_EN,
			basename: pageLinksConstants.HOME_EN,
			homePage: true, //TODO es unico por array para identificar al que ba en el route sin el mapeado
			keypage: "slug-page",
			typePage: "home", //TODO unico por array identificador
			hasAuth: true,
		},
		{
			pathname: pageLinksConstants.HOTELS_EN,
			basename: pageLinksConstants.HOTELS_EN,
			keypage: "slug-page",
			typePage: "hotels", //TODO unico por array identificador,
			hasAuth: true, //TODO valor en true necesita login o redirecciona a la pagina de default o login
		},
		{
			pathname: pageLinksConstants.HOTEL_EN,
			basename: `${pageLinksConstants.HOTEL_EN}/:keyname/:id`,
			keypage: "slug-page",
			typePage: "hotel-detail", //TODO unico por array identificador,
			hasAuth: true, //TODO valor en true necesita login o redirecciona a la pagina de default o login
		},
		{
			pathname: pageLinksConstants.CHECKOUT_EN,
			basename: pageLinksConstants.CHECKOUT_EN,
			keypage: "slug-page",
			typePage: "checkout", //TODO unico por array identificador,
			hasAuth: true, //TODO valor en true necesita login o redirecciona a la pagina de default o login
		},
		{
			pathname: pageLinksConstants.NOTFOUND,
			basename: pageLinksConstants.NOTFOUND,
			homePage: true, //TODO es unico por array para identificar al que ba en el route sin el mapeado
			keypage: "slug-page",
			typePage: "notFound", //TODO unico por array identificador
			hasAuth: false, //TODO valor en true necesita login o redirecciona a la pagina de default o login
		},
		{
			pathname: pageLinksConstants.VOUCHER,
			basename: pageLinksConstants.VOUCHER,
			homePage: true, //TODO es unico por array para identificar al que ba en el route sin el mapeado
			keypage: "slug-page",
			typePage: "voucher", //TODO unico por array identificador
			hasAuth: true, //TODO valor en true necesita login o redirecciona a la pagina de default o login
		},
		{
			pathname: pageLinksConstants.VOUCHER_STATIC_EN,
			basename: pageLinksConstants.VOUCHER_STATIC_EN,
			homePage: true, //TODO es unico por array para identificar al que ba en el route sin el mapeado
			keypage: "slug-page",
			typePage: "voucher-pending", //TODO unico por array identificador
			hasAuth: true, //TODO valor en true necesita login o redirecciona a la pagina de default o login
		},
	],
	headerInformation: {
		showLanguage: true,
		showCurrency: true,
		showMenu: true,
		showMenuMobile: true,
		orderLogo: "0",
		orderMenu: "1",
		orderSettings: "2",
		centerLogo: false,
		menuSlide: true,
		// cart: true, //TODO habilitar u ocultar carrito
		// user: true, //TODO habilitar u ocultar sesion usuario
		iconcart: iconUtils.icon(iconMZPContants.BICART),
		iconUser: iconUtils.icon(iconMZPContants.HIUSERCIRCLE),
		//textCart: "Cart",
		urlUser: "",
		urlCart: "",
		iconMenu: iconUtils.icon(iconMZPContants.CGMENUGRIDR),
		topinfo: false, //oculta el texto de call 
	},
	footerInformation: {
		...footerComponent
	},
	orderBy: [
		{
			name: "Recommended",
			value: "recommended",
		},
		{
			name: "Price: Low to High",
			value: "asc",
		},
		{
			name: "Price: High to Low",
			value: "desc",
		}
	],
	toggleListHotel: [
		{ icon: iconContants.IOGRIDSHARP, name: 'Grid', value: viewGridListConst.GRID },
		{ icon: iconContants.FALISTUL, name: 'List', value: viewGridListConst.ROW },
		{ icon: iconContants.IOLOCATIONSHARP, name: 'Map', value: viewGridListConst.MAP }
	],
	toggleListHotelMobileMap: [
		{ icon: iconContants.LOCATION, 
			name: "Map", 
			value: viewGridListConst.MAP 
		},
		{
			icon: iconContants.BSFILTER,
			name: "Filters",
			value: viewGridListConst.FILTER,
		},
	],
	toggleListHotelMobileList: [
		{ 	
			icon: iconContants.FALISTUL, 
			name: "List", 
			value: viewGridListConst.ROW 
		},
		{
			icon: iconContants.BSFILTER,
			name: "Filters",
			value: viewGridListConst.FILTER,
		},
	],

	searchHotelParams: {
		paramsRoom: {
			// label: "People",
			id: "demo-simple-select-label",
			rules: {
				roomsMax: 8,
				personsTotal: 14,
				adultMinforRoom: 1,
				adultDefaultRoom: 2,
				adultMaxforRoom: 14,
				childMinforRoom: 0,
				childMaxforRoom: 6,
				infantMinforRoom: 0,
				infantMaxforRoom: 6,
				childYear: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
			},
			paramsRoom: {
				adultParams: {
					ariaLabelLess: "Remove Adults",
					ariaLabelMore: "Add Adults",
					label: "Adults",
					//subLabel: "> 12 años",
				},
				childParams: {
					ariaLabelLess: "Remove children",
					ariaLabelMore: "Add children",
					label: "Children",
					subLabel: "0 - 17",
				},
				infantParams: {
					ariaLabelLess: "Remove Infants",
					ariaLabelMore: "Add Infants",
					label: "Infants",
					subLabel: "Under 2 years",
				},

			},
			rulesDescriptions: {
				personsTotal: "Exceeds the number of people to book",
				yearChildren: "Select the age of the minors",
				emptyPersons: "Select the people on your trip",
				emptyChildren: "It is necessary to add the age of the minors",
				room: "room",
				rooms: "rooms",
				adult: "adult",
				adults: "adults",
				child: "child",
				children: "children",
				infant: "infant",
				infants: "infants",
				person: "guest",
				people: "guests",
				guest: "guest",
				guests: "guests",
				age: "Age",
				ages: "Ages",
			},
			text: {
				"remove": "Remove",
				"addRoom": "Add room",
				"done": "Send"
			},
		},
		paramsCalendar: {
			placeholder: "select dates",
			label: "Dates",
			rulesDescriptions: {
				empty: "Select the dates of your trip",
			}
		},
		paramsAutocomplete: {
			id: "combo-demo",
			limitOptions: 10,
			minTextAPI: 3,
			timeDelay: 200,
			labelStart: "Destination",
			rulesDescriptions: {
				empty: "Please enter a hotel or destination.",
				selectList: "Select a hotel or destination from the search list"
			}
		},
	},
	searchPackageParams: { //TODO al clonar solo paquete tiene el mismo campo de room: "hab", en paramsroom rulesDescriptions
		paramsRoom: {
			rules: {
				roomsMax: 8,
				personsTotal: 14,
				adultMinforRoom: 1,
				adultDefaultRoom: 2,
				adultMaxforRoom: 14,
				childMinforRoom: 0,
				childMaxforRoom: 6,
				infantMinforRoom: 0,
				infantMaxforRoom: 6,
				childYear: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
			},
			paramsRoom: {
				adultParams: {
					ariaLabelLess: "Remove Adults",
					ariaLabelMore: "Add Adults",
					label: "Adults",
					//subLabel: "> 12 años",
				},
				childParams: {
					ariaLabelLess: "Remove children",
					ariaLabelMore: "Add children",
					label: "Children",
					subLabel: "0 - 17",
				},
				infantParams: {
					ariaLabelLess: "Remove Infants",
					ariaLabelMore: "Add Infants",
					label: "Infants",
					subLabel: "Under 2 years",
				},
			},
			rulesDescriptions: {
				personsTotal: "Exceeds the number of people to book",
				yearChildren: "Select the age of the minors",
				emptyPersons: "Select the people on your trip",
				emptyChildren: "It is necessary to add the age of the minors",
				room: "room",
				rooms: "rooms",
				adult: "adult",
				adults: "adults",
				child: "child",
				children: "children",
				infant: "infant",
				infants: "infants",
				person: "guest",
				people: "guests",
				guest: "guest",
				guests: "guests",
				age: "Age",
				ages: "Ages",
			},
		},
		paramsCalendar: {
			rulesDescriptions: {
				empty: "Select the dates of your trip",
			}
		},
		paramsAutocomplete: {
			rulesDescriptions: {
				empty: "Select a origin or destination.",
				selectList: "Select a hotel or destination from the search list"
			}
		},
	},
	searchTourParams: {
		paramsRoom: {
			id: "demo-simple-select-label",
			rules: {
				roomsMax: 1,
				personsTotal: 14,
				adultMinforRoom: 1,
				adultDefaultRoom: 2,
				adultMaxforRoom: 14,
				childMinforRoom: 0,
				childMaxforRoom: 6,
				infantMinforRoom: 0,
				infantMaxforRoom: 6,
				childYear: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
			},
			paramsRoom: {
				adultParams: {
					ariaLabelLess: "Remove Adults",
					ariaLabelMore: "Add Adults",
					label: "Adults",
					//subLabel: "> 12 años",
				},
				childParams: {
					ariaLabelLess: "Remove children",
					ariaLabelMore: "Add children",
					label: "Children",
					subLabel: "0 - 17",
				},
				infantParams: {
					ariaLabelLess: "Remove Infants",
					ariaLabelMore: "Add Infants",
					label: "Infants",
					subLabel: "Under 2 years",
				},
			},
			rulesDescriptions: {
				personsTotal: "Exceeds the number of people to book",
				yearChildren: "Select the age of the minors",
				emptyPersons: "Select the people on your trip",
				emptyChildren: "It is necessary to add the age of the minors",
				adult: "adult",
				adults: "adults",
				child: "child",
				children: "children",
				infant: "infant",
				infants: "infants",
				person: "guest",
				people: "guests",
				guest: "guest",
				guests: "guests",
				age: "Age",
				ages: "Ages",
			},
		},
		paramsCalendar: {
			placeholder: "select date",
			label: "Date",
			rulesDescriptions: {
				empty: "Select the date of your trip",
			}
		},
		paramsAutocomplete: {
			id: "combo-demo",
			limitOptions: 10,
			minTextAPI: 3,
			timeDelay: 200,
			labelStart: "Destination",
			rulesDescriptions: {
				empty: "Please enter a activity or destination.",
				selectList: "Select a hotel or destination from the search list"
			}
		},
	},
	searchShutterParams: {
		paramsRoom: {
			id: "demo-simple-select-label",
			rules: {
				roomsMax: 1,
				personsTotal: 14,
				adultMinforRoom: 1,
				adultDefaultRoom: 2,
				adultMaxforRoom: 14,
				childMinforRoom: 0,
				childMaxforRoom: 6,
				infantMinforRoom: 0,
				infantMaxforRoom: 6,
				childYear: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
			},
			paramsRoom: {
				adultParams: {
					ariaLabelLess: "Remove Adults",
					ariaLabelMore: "Add Adults",
					label: "Adults",
					//subLabel: "> 12 años",
				},
				childParams: {
					ariaLabelLess: "Remove children",
					ariaLabelMore: "Add children",
					label: "Children",
					subLabel: "0 - 17",
				},
				infantParams: {
					ariaLabelLess: "Remove Infants",
					ariaLabelMore: "Add Infants",
					label: "Infants",
					subLabel: "Under 2 years",
				},

			},
			rulesDescriptions: {
				personsTotal: "Exceeds the number of people to book",
				yearChildren: "Select the age of the minors",
				emptyPersons: "Select the people on your trip",
				emptyChildren: "It is necessary to add the age of the minors",
				room: "room",
				rooms: "rooms",
				adult: "adult",
				adults: "adults",
				child: "child",
				children: "children",
				infant: "infant",
				infants: "infants",
				person: "guest",
				people: "guests",
				guest: "guest",
				guests: "guests",
				age: "Age",
				ages: "Ages",
			},
			text: {
				"remove": "Remove",
				"addRoom": "Add room",
				"done": "Send"
			},
		},
		paramsCalendar: {
			placeholder: "select dates",
			label: "Dates",
			rulesDescriptions: {
				empty: "Select the dates of your trip",
			}
		},
		paramsAutocomplete: {
			id: "combo-demo",
			limitOptions: 10,
			minTextAPI: 3,
			timeDelay: 200,
			labelStart: "Destination",
			rulesDescriptions: {
				empty: "Select a origin or destination.",
				selectList: "Select a origin or destination from the search list"
			}
		},
	},
	errorType500: {
		type: "500",
		title: "Page Not Found",
		text: "The page you are looking for does not exist",
		btn: "Go home",
	},
	errorType400: {
		type: "400",
		title: "Page Not Found",
		text: "The page you are looking for does not exist",
		btn: "Go home",
	},
}

export default resource;