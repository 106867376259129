export default {
    "tourProvisional.name": "Xcaret Park: night",
    "tourProvisional.txt1": "Food and drinks",
    "tourProvisional.txt2": "Follow the scent of copal to the Pre-Hispanic Town, where Pre-Hispanic Dances celebrate the arrival of night",
    "tourProvisional.experience.1": "Enjoy more than 40 attractions and activities at Xcaret Park",
    "tourProvisional.experience.2": "Swim in the caves and natural pools of a lush tropical landscape",
    "tourProvisional.experience.3": "Explore the coral reef aquarium of the Caribbean Sea",
    "tourProvisional.experience.4": "Let yourself be dazzled by the butterflies from the hatchery of the Butterfly Pavilion",
    "tourProvisional.experience.5": "Watch Mayan and Mexican cultural performances.",
    "tourProvisional.detail.1": "Enjoy full-day admission to Xcaret Park, a paradisiacal theme park in the Riviera Maya, where you can enjoy more than 40 activities and attractions.",
    "tourProvisional.detail.2": "Locker and use of snorkel gear ($20 refundable deposit required)",
    "tourProvisional.detail.3": "Exhibitions and cultural tours",
    "tourProvisional.detail.4": "Xcaret México Espectacular Show (a musical journey through the history of Mexico)",
    "tourProvisional.detail.5": "Life jackets and inner tubes",
    "tourProvisional.detail.6": "Changing rooms and showers",
    "tourProvisional.detail.7": "Access to the beach, cove and natural pools",
    "tourProvisional.detail.8": "Rest areas, armchairs and hammocks",
    "tourProvisional.detail.9": "coral reef aquariuml",
    "tourProvisional.detail.10": "House of Whispers",
    "tourProvisional.detail.11": "farm wildlife breeding",
    "tourProvisional.detail.12": "Capilla de San Francisco de Asís",
    "tourProvisional.detail.13": "Parking",
    "tourProvisional.detail.14": "Marinas",
    "tourProvisional.detail.15": "Transportation",
    "tourProvisional.detail.16": "Swim with dolphins",
    "tourProvisional.detail.17": "Interactive Shark Adventure",
    "tourProvisional.detail.18": "Photos",
    "tourProvisional.notfor.1": "People with back problems",
    "tourProvisional.notfor.2": "People with mobility problems",
    "tourProvisional.notfor.3": "People with heart problems",
    "tourProvisional.notfor.4": "People in wheelchairs",
    "tourProvisional.important.1": "Passport or Official Identification",
    "tourProvisional.important.2": "Comfortable shoes",
    "tourProvisional.important.3": "Deposit",
    "tourProvisional.important.4": "People in wheelchairs",
    "tourProvisional.general.1": "Cancel up to 24 hours in advance and receive a full refund",
    "tourProvisional.general.2": "Special health and safety measures apply. Check all the details in your activity voucher once you have made the reservation.",
    "tourProvisional.general.3": "Check availability to see the start times.",
    "tourProvisional.general.4": "Spanish, English",
    "tourProvisional.title.1": "What will you do?",
    "tourProvisional.title.2": "Tour details",
    "tourProvisional.title.3": "Whats included",
    "tourProvisional.title.4": "Not suitable  for",
    "tourProvisional.title.5": "Important Information",
}
