import { useEffect } from "react";
import currencyAction from "../actions/currencyAction";
import { localStorageConst } from "../constants/common";
import storage from "../localstorage/storage";
import { useDispatch, useGlobalState } from "../store/StoreProvider";

const useChangeCurrency = (currencyCode) => {
    const dispatch = useDispatch();
    const {currency} = useGlobalState();

    const updateContextCurrency = (value) => {
        const currency = {
            name: "",
            code: value?.toUpperCase()
        }
        currencyAction.currency(currency, dispatch);
    }

    useEffect(() => {
        if(currencyCode !== currency.code) {
            storage.updateLocalStorage(localStorageConst.CURRENCY, currencyCode);
            updateContextCurrency(currencyCode)
        }
    }, [currencyCode]);

}

export default useChangeCurrency;