import { useState } from "react";
import {  Col, Row , Form, Alert } from "react-bootstrap";
import iconContants from "../../../constants/iconContants";
import iconUtils from "../../../utils/iconUtils";

// import {  } from "react-bootstrap";
import { useIntl } from "react-intl";
import "./components/style.scss";

const CardContainerTua = () => {
    const intl = useIntl();
    return ( <Row className="box-tua">
        <Col xs="12" sm="12" md="2" lg="2" xl="2"  className="box-tua-img"> 
            <img loading="lazy" src="https://www.volaris.com/assets/images/icon_tua.svg" alt="TUA"/>
        </Col>
        <Col xs="12" sm="12" md="10" lg="10" xl="10"  className="box-tua-content">
            <span className="box-title"> { intl.formatMessage({ id: "packages.tua.title" }) } </span> 
            <ul>
              <li className="box-li"> { intl.formatMessage({ id: "packages.tua.text" }) } </li>
              <li className="box-li"> { intl.formatMessage({ id: "packages.tua.text1" }) } </li>
            </ul>
           <div className="tua-footer">
                <div >
                  <Alert className="alert-tua" variant="warning">
                    <span className="ml-4"> { iconUtils.icon(iconContants.GOALERT) }  </span>
                   
                    {intl.formatMessage({ id: "packages.tua.alert" })} $1,200 MXN
                  </Alert>
                
                </div>
                <div className="mb-3">
                    <Form.Check 
                    className="btn-tua"
                    type="checkbox"
                    id={`tua-check`}
                    label=  {intl.formatMessage({ id: "packages.tua.btn" })}
                    />
                </div>
           </div>
        </Col>
        
    </Row>)
}
export default CardContainerTua;
