
import { Card, } from 'react-bootstrap';
import { useIntl } from "react-intl";

import Placeholder from 'react-bootstrap/Placeholder';
import "./components/style.scss";

//TODO card for activities in tour detail
const SkeletonCardListHome = (props) => {
  const { number,  } = props;
  return (
    <>
        {Array.from(Array(number)).map((_, index) =>
        (
            <div className='card-container-home'  key={index}>
                <Card className="skeleton-card" >
                <Placeholder as={Card.Title}  animation="glow">
                    <Placeholder className="skeleton-card-img" />
                 </Placeholder>
                <Card.Body>
                    <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={6} /> <Placeholder xs={5} /> <Placeholder xs={6} />{' '}
                    <Placeholder xs={5} /> {' '} <Placeholder xs={5} />  {' '}
                    <Placeholder xs={4} /> {' '} <Placeholder xs={6} />
                    
                    </Placeholder>
                </Card.Body>
                </Card>
            </div>
          
        )
    )}
    </>
  

  );
}

export default SkeletonCardListHome;