const countGuestsByRoom = (item, id) => {
  const holders = item.holders;
  const nameRoom = item.name;
  var rooms = [];

  const auxRooms = holders.reduce((acc, holder) => {
    const roomIndex = holder.roomIndex;
    const room = acc[roomIndex] || { adults: 0, children: 0, ages: [] };

    if (holder.paxType === "adult" || holder.type === "adult") {
      room.adults++;
    } else if (holder.paxType === "children" || holder.type === "children") {
      room.children++;
      room.ages.push(holder.age);
    }

    room.name = nameRoom ?? "";
    acc[roomIndex] = room;
    rooms.push(room);

    return acc;
  }, []);

  auxRooms.shift();

  return auxRooms;
};

const hotelUtils = {
  countGuestsByRoom,
};

export default hotelUtils;
