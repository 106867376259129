import pageLinksConstants from "../../../../src/constants/pageLinksConstants";
import webSitesiteRoutesConstant from "./../../../../src/constants/webSitesiteRoutesConstant"
const brand ="Nexus Vacations"

const response = [
    {
      routeType:webSitesiteRoutesConstant.HOME,
      canonical: pageLinksConstants.HOME_EN,
      title: `${brand} - Online reservations`,
      titleStatic: `${brand} - Online reservations`,
      description:
        "Nexus vacations - Online reservations offers the hotels within the main destinations of the Mexico, Dominican Republic, Jamaica and more.",
      keywords:
        "hotels in cancun, hotels in downtown cancun, hotels in cancun all inclusive, hotel deals in cancun, cancun vacations, vacations in cancun, trips to cancun, cancun offers, availability of accommodation in cancun, availability of hotels in cancun, cancun vacation discounts , cancun vacation promotions, hotels in cancun hotel zone, cheap cancun hotels, cancun cheap hotels, cancun all inclusive hotels, cancun 5 star hotels, cancun adult hotels, cancun tours, cancun and riviera maya tours, 5 star cancun hotels , all inclusive cancun hotels, cancun hotels hotel zone, cheap cancun hotels, all inclusive cancun vacations, cancun vacation packages, we all travel through mexico, we travel together, we all travel through mexico, we all travel through mexico, we all travel through mexico We traveled all over Mexico, we traveled together, we traveled through Quintana Roo, we all traveled through Quintana Roo, we all traveled through Mexico, Cancun hotel, travel deals, Christmas holidays, Christmas holidays with children, Christmas holidays in Mexico, Christmas hotels, Christmas trips, Christmas trips, Christmas trips with children, Christmas trips for children, Christmas trips for adults, christmas tours in mexico, christmas and new year trips, family christmas trips, christmas deals, christmas deals, christmas packages, December travel deals, December long weekend deals",
      img: null,
      follow: "index,follow", //default inddex, follow, no indexfaz "noindex,nofollow"
    },
    {

      routeType:webSitesiteRoutesConstant.LISTHOTEL,
      canonical: pageLinksConstants.HOTELS_EN,
      title: `Hotels - #DESTINATION | ${brand}`,
      titleStatic:`Hotels | ${brand}`,
      description: null,
      keywords: null,
      img: "../src/theme/site/nexus/logo.png",
      follow: "index,follow",//default inddex, follow, no indexfaz "noindex,nofollow"
    },
    {
      routeType:webSitesiteRoutesConstant.HOTEL,
      canonical: pageLinksConstants.HOTEL_EN,
      title: `Hotel - #SERVICENAME | ${brand}`,
      titleStatic: `Hotel | ${brand}`,
      description: null,
      keywords: null,
      img: "../src/theme/site/nexus/logo.png",
      hideMenuMobile: true,
      follow: "index,follow", //default inddex, follow, no indexfaz "noindex,nofollow"
    },
    {
      routeType:webSitesiteRoutesConstant.RESERVATION,
      canonical: pageLinksConstants.CHECKOUT_EN,
      title: `Booking - #SERVICE | ${brand}`,
      titleStatic: `Booking | ${brand}`,
      description: null,
      keywords: null,
      img: "../src/theme/site/nexus/logo.png",
      follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
    },
    {
      routeType:webSitesiteRoutesConstant.NOTFOUND,
      canonical: pageLinksConstants.NOTFOUND,
      title: `Page Not Found | ${brand}`,
      titleStatic: `Page Not Found | ${brand}`,
      description: null,
      keywords: null,
      img: "../src/theme/site/nexus/logo.png",
      follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
    },
    {
      routeType:webSitesiteRoutesConstant.LOGIN,
      canonical: pageLinksConstants.LOGIN,
      title: "Login",
      titleStatic:"Login",
      description: null,
      keywords: null,
      img: "../src/theme/site/nexus/logo.png",
      follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
    },
    {
      routeType:webSitesiteRoutesConstant.MYRESERVATION,
      canonical: pageLinksConstants.MY_RESERVATION_EN,
      title: "My reservations",
      titleStatic:null,
      description: null,
      keywords: null,
      img: "../src/theme/site/nexus/logo.png",
      follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
    },
    {
      routeType:webSitesiteRoutesConstant.PRIVACY,
      canonical: pageLinksConstants.PRIVACY_EN,
      title: "Privacy",
      titleStatic:"Privacy",
      description: null,
      keywords: null,
      img: "../src/theme/site/nexus/logo.png",
      follow: "index, follow", //default inddex, follow, no indexfaz "noindex,nofollow"
      },
      ,
    {
      routeType:webSitesiteRoutesConstant.TERMSANDCONDITIONS,
      canonical: pageLinksConstants.TERMSANDCONDITIONS_EN,
      title: "Terminos and condiciones",
      titleStatic:"Terminos and condiciones",
      description: null,
      keywords: null,
      img: "../src/theme/site/nexus/logo.png",
      follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
    },
    {
      routeType:webSitesiteRoutesConstant.COOKIES,
      canonical: pageLinksConstants.COOKIES_POLICY_EN,
      title: "Cookies policy",
      titleStatic: "Cookies policy",
      description: null,
      keywords: null,
      img: "../src/theme/site/nexus/logo.png",
      follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
    },
    {
    routeType: webSitesiteRoutesConstant.VOUCHERPENDING,
    canonical: pageLinksConstants.VOUCHER_STATIC_EN,
    title: "Voucher pending",
    titleStatic: "Voucher pending",
    description: null,
    keywords: null,
    img: "../src/theme/site/nexus/logo.png",
    follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
  },
  {
      routeType: webSitesiteRoutesConstant.VOUCHER,
      canonical: pageLinksConstants.VOUCHER,
      title: "Voucher",
      titleStatic: "Voucher",
      description: null,
      keywords: null,
      img: "../src/theme/site/nexus/logo.png",
      follow: "noindex, nofollow", //default inddex, follow, no indexfaz "noindex,nofollow"
    },
  ];

  export default response;
