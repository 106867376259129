export default {
	"payment.status.paid": "Pagado",
	"payment.status.notpaid": "No pagado",
	"payment.status.error": "Error",
	"payment.status.cancelled": "Cancelado",
	"payment.status.refused": "Rechazado",
	"payment.status.insuficentfunds": "Fondos insuficientes",
	"payment.status.returned": "Devuelto",
	"payment.status.pendingsettlement": "Liquidación pendiente",
	"payment.status.pending": "Pendiente",
	"payment.status.notfound": "Parece que ha habido un problema al procesar tu  pago, por favor intente de nuevo",
	"payment.status.error.message": "¡Hemos encontrado un problema al procesar tu pago!, por favor intente con otra forma de pago.",
};
