import { useEffect } from "react";
import { useDispatch, useGlobalState } from "../store/StoreProvider";
import apiCall from "../siteResources/api";
import pageOptionsAction from "../actions/pageOptionsAction";

const useCallApi = () => {
    const {resourceSite, locale} = useGlobalState();
    const dispatch = useDispatch();

    useEffect(() => {
        if(resourceSite.isSuccess) {
            // //TODO fake llamado api por idioma
            let pathLocale = window.location?.pathname?.toLowerCase();
            const pathSplit = pathLocale.replace("//", "/").split('/');

            //TODO obtener el slug
            let slug = "/";
            if (pathSplit.length >= 3) {
                //TODO tercera posicion es path sin locale
                slug = pathLocale.replace("//", "/"); //TODO mejorar remplace todos
                slug = slug.replace(`/${locale}`, '');
            }

            const resourcePage = resourceSite.options.siteRouter?.find((a) => a.pathname === slug);
            if(resourcePage) {
                const responsePage = apiCall(locale, resourcePage.typePage);

                //const responsePage = resourceSite.api?.find((a) => a.pathname === slug);
                if(responsePage) {
                    pageOptionsAction.setFetching(dispatch);
                    pageOptionsAction.updatePageOptions(responsePage, dispatch);
                }
                else
                    pageOptionsAction.setError(dispatch);
            }
        }
    }, [locale, resourceSite])
}

export default useCallApi;