import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { useIntl } from "react-intl";
import CardTypeSection from "./CardTypeSection"

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#303238",
      fontSize: "16px",
      fontFamily: "sans-serif",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF"
      }
    },
    invalid: {
      color: "#e5424d",
      iconColor: "#e5424d",
      ":focus": {
        color: "#303238"
      }
    }
  }
};

function CardSection(props) {
  const intl = useIntl();
  const onChange = (event) => {
    const {onChange} = props;
    onChange && onChange(event);
  }

  return (
    <>
    <h4>{intl.formatMessage({ id: "checkout.formStripe.title" })}</h4>
    <CardTypeSection/>
    <div className="bg-white">
      <CardElement id="card-element-paymentmethod" options={CARD_ELEMENT_OPTIONS} onChange={onChange} />
    </div>
    </>
  );
}

export default CardSection;