export default {
    'stripe.card_declined': 'The card was declined, try another card.',
    'stripe.invalid_cvc': 'The CVC number is incorrect.',
    'stripe.insufficient_funds': 'The card has insufficient funds to complete the purchase, try another card.',
    'stripe.processing_error': 'Ocurrió un error al procesar la tarjeta.',
    'stripe.incorrect_number': 'The card number is incorrect.',
    'stripe.invalid_expiry_year':  'The card’s expiration year is incorrect. ',
    'stripe.invalid_expiry_month': 'The card’s expiration month is incorrect.',  
    'stripe.expired_card': 'The card has expired, try another valid card.',
    'stripe.incomplete_zip':  'Incomplete zip code',
}
 