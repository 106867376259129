import iconContants from "../../../constants/iconContants";
import iconUtils from "../../../utils/iconUtils";
import "./components/style.scss";

const BoxWhitoutResults = (props) => {
	const { text, classs } = props;
	return (
		<div className={`box-whitout-result container-flex flex-column ${classs}`} >
			<div className="container-flex container-icon-whitout-reesults">
				{iconUtils.icon(iconContants.SEARCH)}
			</div>
			<div className="container-flex">
				{text && (
					<p className="">
						{text}
					</p>
				)}
			</div>
		</div>
	);
}

export default BoxWhitoutResults;