// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "transferVoucherPage.titleHotelSucces": "Resumen de traslado",
    "transferVoucherPage.reference": "Clave de reservación",
    "transferVoucherPage.date": "Fecha de reserva",
    "transferVoucherPage.name": "Nombre",
    "transferVoucherPage.adults": "Adultos",
    "transferVoucherPage.childrens": "Niños",
    "transferVoucherPage.arrive": "Llega",
    "transferVoucherPage.leave": "Sale",
    "transferVoucherPage.titleError": "Cargando...",
    "transferVoucherPage.itinerayFly": "Horario de Vuelo",
    "transferVoucherPage.aereolineFly": "Aereolinea / Vuelo",
    "transferVoucherPage.fromTo": "Desde - Hacia",
    "transferVoucherPage.transferItinerary": "Horario de traslado",
    "transferVoucherPage.titleServices": "Servicios",
    "transferVoucherPage.read": "*Leer con atención",
    "transferVoucherPage.provider": "Información del proveedor",
    "transferVoucherPage.nameProvider": "Nombre del provedor",
    "transferVoucherPage.phoneProvider": "Teléfono del provedor",
    "transferVoucherPage.titlePolitics": "Politicas de cancelación",
    "transferVoucherPage.pay": "Pago",
    "transferVoucherPage.publicRate": "Monto total",
}