
import { Card, } from 'react-bootstrap';
import Placeholder from 'react-bootstrap/Placeholder';
import "./components/style.scss";

//TODO card for activities in tour detail
function SkeletonGallery(props) {
	return (
		<div className='gallery-container collage' >
			<div className='container-first'>
				<Placeholder as={Card.Title} animation="glow" className="w-100">
					<Placeholder className="w-100 gallery-box-img" />
				</Placeholder>
			</div>
			<div className="container-duo">
				<div className='container-top gallery-box w-100' >
					<Placeholder as={Card.Title} animation="glow" className="w-100">
						<Placeholder className="w-100 gallery-box-img" />
					</Placeholder>
				</div>
				<div className='container-botom gallery-box w-100' >
					<Placeholder as={Card.Title} animation="glow" className="w-100">
						<Placeholder className="w-100 gallery-box-img" />
					</Placeholder>
				</div>
			</div>
			<div className="container-duo">
				<div className='container-top gallery-box w-100' >
					<Placeholder as={Card.Title} animation="glow" className="w-100">
						<Placeholder className="w-100 gallery-box-img" />
					</Placeholder>
				</div>
				<div className='container-botom gallery-box w-100'>
					<Placeholder as={Card.Title} animation="glow" className="w-100">
						<Placeholder className="w-100 gallery-box-img" />
					</Placeholder>
				</div>
			</div>
		</div>
	);
}

export default SkeletonGallery;