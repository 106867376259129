import initialState from "./initialState";
import types from "../constants/reducers/pageOptionsConstants";
const pageOptionsReducer = (state = initialState.pageOptions, action) => {
    switch(action?.type) {
        case types.PAGE_OPTIONS_FETCHING:
            return {...initialState.pageOptions, ...action.pageOptions}
        case types.PAGE_OPTIONS:
            return action.pageOptions;
        case types.PAGE_OPTIONS_ERROR:
            return action.pageOptions;
        default:
            return state;
    }
}

export default pageOptionsReducer;