import { useState } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useMediaQuery } from "react-responsive";
import { useGlobalState } from "../../../store/StoreProvider";
import BoxBooking from "../../bookings/BoxBooking/BoxBooking";
import breakpointConstants from "../../../constants/breakpointConstants";
import serviceTypeConstant from "../../../constants/serviceTypeConstant";
import rateUtils from "../../../utils/rateUtils";
import "../../../utils/htmlUtils";

const SearchBoxDetail = (props) => {
	const {
		params,
		type,
		hideBtn,
		service,
		searchFixed,
		searchParams,
	} = props;
	const { locale, resourceSite, currency } = useGlobalState();
	const isMobile = useMediaQuery({ maxWidth: breakpointConstants.SM });
	const [bodyRenderScroll, setBodyRenderScroll] = useState(false);
	const [isHovering, setIsHovering] = useState(false);
	const intl = useIntl();
	const {
		searchHotelParams,
		searchPackageParams,
		searchTourParams,
		searchShutterParams,
	} = resourceSite.options;
	const pathnames = resourceSite.options?.siteRoutes;
	const homePath = `${resourceSite.options.home.href}` ?? `${locale}`;
	const localizedUrlHotelList = pathnames?.hotels?.pathname ? `/${locale}${pathnames.hotels.pathname}` : "";
	const destination = params?.destination ?? searchParams.get("destination");
	const destinationkeyword = params?.destinationkeyword ?? searchParams.get("destinationkeyword");
	const hotel = params?.hotel ?? searchParams.get("hotel");
	const hotelkeyword = params?.hotelkeyword ?? searchParams.get("hotelkeyword");
	let query = {
		paxes: searchParams.get("paxes"),
		checkin: params?.startDate ?? searchParams.get("checkin"),
		checkout: params?.endDate ?? searchParams.get("checkout"),
		sn: params?.sn ?? searchParams.get("sn"),
	};
	if(destination) query.destination = destination;
	if(destinationkeyword) query.destinationkeyword = destinationkeyword;
	if(hotel) query.hotel = hotel;
	if(hotelkeyword) query.hotelkeyword = hotelkeyword;
	const urlLinkBack = query.hotelkeyword || query.destinationkeyword ? `?${new URLSearchParams(query).toString()}` : null;
	const onChangeBooking = (response) => {
		const { onChangeBooking } = props;
		onChangeBooking && onChangeBooking(response);
	};
	const onClickToScrollRooms = () => {
		const { onClickToScrollRooms } = props;
		onClickToScrollRooms && onClickToScrollRooms();
	};

	if (typeof window !== "undefined") {
		const elementHeader = document.querySelector("#header");
		const elementRooms = document.querySelector("#box-list-rooms .container-rooms-cards .card-body")?.firstChild ?? null;
		window.onscroll = function () {
			const isBodyScroll = !elementHeader.isVisible();
			if (bodyRenderScroll !== isBodyScroll)
				setBodyRenderScroll(isBodyScroll);
			if (isMobile && elementRooms) {
				const elementsIsScrolled = elementRooms.scroolled();
				if (isHovering !== elementsIsScrolled)
					setIsHovering(elementsIsScrolled);
			}
		};
	}
	return (
		<div id="booking-hotel"
			className={`search-box-service-hotel ${bodyRenderScroll ? "box-fixed" : ""}`}>
			<div className="container-xl">
				<div className="pt-2">
					<Container fluid>
						{!bodyRenderScroll && (
							<Breadcrumb className="d-none d-sm-block">
								<Breadcrumb.Item href={homePath}>
									{intl.formatMessage({
										id: "general.breadcrumdHome",
									})}
								</Breadcrumb.Item>
								{urlLinkBack && (
									<Breadcrumb.Item href={`${localizedUrlHotelList}${urlLinkBack}`}>
										{intl.formatMessage({ id: "general.breadcrumdHotels" })}
									</Breadcrumb.Item>
								)}
								{!urlLinkBack && (
									<Breadcrumb.Item>
										{intl.formatMessage({ id: "general.breadcrumdHotels" })}
									</Breadcrumb.Item>
								)}
								<Breadcrumb.Item active>{query.sn}</Breadcrumb.Item>
							</Breadcrumb>
						)}
					</Container>
				</div>
				{params && (
					<>
						<div className="pb-2 hoteList">
							<BoxBooking
								bodyRenderScroll={bodyRenderScroll}
								params={params}
								locale={locale}
								title=""
								siteRoutes={resourceSite.options?.siteRoutes}
								componentTitle="h2"
								closeColorButton="white"
								type={type}
								searchHotelParams={
									type === serviceTypeConstant.HOTEL
										? searchHotelParams
										: searchHotelParams
								}
								onChangeBooking={onChangeBooking}
								viewBooking={searchFixed}
								hideBtn={hideBtn}
							/>
						</div>
						<div className={`priceview-mobile ${isHovering ? "d-none" : "d-block"}`}>
							{service.isSuccess && (
								<div className="d-flex align-items-center h-100 rate-top">
									<div className="box-rate-top">
										<div className="item-rate-top">
											<span className="text-rate-top">
												{intl.formatMessage({ id: "general.bestPricePerRoom" })}
											</span>
											<span className="">
												{rateUtils.formatAmount(
													service.bestRate?.totalPerNight,
													currency.code,
													locale
												)}
											</span>
										</div>
										<div className="item-rate-top">
											<span className="text-rate-top">
												{intl.formatMessage({ id: "general.totalPrice" })}{" "}
												{rateUtils.formatAmount(
													service.bestRate?.total,
													currency.code,
													locale
												)}
											</span>
											<span className="text-rate-top">
												{intl.formatMessage({
													id: "general.bestPricePerRoomTaxes",
												})}
											</span>
										</div>
									</div>
									<div className="d-md-none d-lg-none d-xl-none">
										<button
											onClick={onClickToScrollRooms}
											type="button"
											className="btn-reservation btn btn-primary">
											{intl.formatMessage({ id: "general.moreRooms" })}
										</button>
									</div>
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default SearchBoxDetail;
