import { Button, Stack, Container } from "react-bootstrap";
import { useIntl } from "react-intl";
import iconUtils from "../../../utils/iconUtils";
import iconContants from "../../../constants/iconContants";
import serviceType from "../../../constants/serviceTypeConstant";
import dateUtils from "../../../utils/dateUtils";
import guestsUtils from "../../../utils/selectPersonsUtils";
import breakpointConstants from "../../../constants/breakpointConstants";
import MediaQuery from "react-responsive";

const BookingInformation = (props) => {
  const { params, type, locale, hideBtn,bodyRenderScroll } = props;
  const intl = useIntl();
  const startD = dateUtils.valiteDateFormat(params.startDate)
    ? dateUtils.dateFormat(
        dateUtils.getDateforFormat(params.startDate),
        "D MMM",
        locale
      )
    : "--";
  const endD = dateUtils.valiteDateFormat(params.endDate)
    ? dateUtils.dateFormat(
        dateUtils.getDateforFormat(params.endDate),
        "D MMM",
        locale
      )
    : "--";
  const onClick = () => {
    const { onClick } = props;
    onClick && onClick();
  };

  const dates =
    type === serviceType.TOUR ? `${startD} ` : `${startD} - ${endD}`;

  const label =
    type === serviceType.TRANSFER
      ? `${params.inputAutoValueStart?.label} - ${params.inputAutoValueEnd?.label}`
      : params.inputAutoValueStart?.label;
  let total = "";
  switch (type) {
    case serviceType.TOUR:
      total = intl.formatMessage(
        { id: "website.peopleTotal" },
        { count: guestsUtils.totalPeople(params.rooms) }
      );
      break;
    case serviceType.TRANSFER:
      total = intl.formatMessage(
        { id: "website.passengerTotal" },
        { count: guestsUtils.totalPeople(params.rooms) }
      );
      break;

    default:
      total = guestsUtils.totalPeople(params.rooms);
      break;
  }

  return (
<Container fluid>
 <Stack className="booking-search-information" gap={2}>
      <MediaQuery maxWidth={breakpointConstants.MD}>
        {(matches) =>
          matches ? (
            <div
              onClick={onClick}
              className="w-100"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <div className="booking-service-view">
                  <b>{label}</b>
                </div>
                <div className="bs-texts">
                  <span> {dates} </span>
                  <span className="divider">  | </span>
                 <span>{total} {iconUtils.icon(iconContants.BSFILLPERSONFILL)} </span>
                </div>
              </div>
              <div className="btn-mobile">
                <Button className="bookig-edit-btn btn-sm">
                  <span> {iconUtils.icon(iconContants.IMPENCIL)} </span>
                  <span className="visually-hidden text-button">
                    {intl.formatMessage({ id: "button.modify" })}
                  </span>
                </Button>
              </div>
            </div>
          ) : (
            <>
              {(type === serviceType.PACKAGE ||
                type === serviceType.FLIGHT) && (
                <div>
                  {" "}
                  MEX{" "}
                  {iconUtils.icon(iconContants.FALONGARROWALTRIGHT)} CUN
                </div>
              )}

              <span>{label}</span>

              {iconUtils.icon(iconContants.CALENDAR)}
              <span> {dates} </span>
              {type === serviceType.HOTEL && iconUtils.icon(iconContants.BED)}
              {(type === serviceType.TOUR || type === serviceType.TRANSFER) &&
                iconUtils.icon(iconContants.PERSON)}
              <span>
                {`${intl.formatMessage(
                  { id: "website.adultTotal" },
                  { count: guestsUtils.totalAdults(params.rooms) }
                )}${
                  guestsUtils.totalChildren(params.rooms) > 0
                    ? `, ${intl.formatMessage(
                        { id: "website.childrenTotal" },
                        { count: guestsUtils.totalChildren(params.rooms) }
                      )}`
                    : ""
                }`}
              </span>

              {!hideBtn && (
                <Button onClick={onClick} className="bookig-edit-btn btn-sm">
                  <span> {iconUtils.icon(iconContants.IMPENCIL)} </span>
                  <span className="text-button">
                    {" "}
                    {intl.formatMessage({ id: "button.modify" })}
                  </span>
                </Button>
              )}
            </>
          )
        }
      </MediaQuery>
    </Stack>
</Container>
   
  );
};

export default BookingInformation;
