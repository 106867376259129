import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Accordion from 'react-bootstrap/Accordion';
import BoxDetailFlight from "../BoxDetailFlight/BoxDetailFlight";
import BoxIconText from "../../../components/boxes/BoxIconText";
import iconContants from "../../../constants/iconContants";
import { Container, Row , Button, Col } from "react-bootstrap";
import rateUtils from "../../../utils/rateUtils";
import "./components/style.scss";

const BoxReservationDetailFlight = (props) => {
  const {  position, showTitle } = props;

    const [openModalDetail, setOpenModalDetail] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);

    function handleOpenDetail(breakpoint) {
    setFullscreen(breakpoint);
    setOpenModalDetail(!openModalDetail);
    }
    const handleCloseModal = () => setOpenModalDetail(false);

  return (
    <div className="container-box-reservation-flight">
        <div className={`your-reservation ${position === "1" ? " noflight" : ""}`}>
            {showTitle && (
                <h3>Tu reservación</h3>
            )}
            
            {position === "2" && (
                <>
                <div className="selectedFareType">
                    <div className="regularBasic ">Básica</div>
                </div>
                <div className="content">
                        <div className="flight flightDeparture">
                            <div className="d-flex justify-content-between">
                                <div className="title">Vuelo de salida</div>
                                <div className="details" onClick={() => handleOpenDetail('md-down')}>Detalles</div>
                            </div>
                            <Row className="g-0  flightSelected">
                                <Col>
                                    <div className="airport origin">MEX</div>
                                    <div className="time timeDeparture">02:02 PM</div>
                                </Col>
                                <Col>
                                    <div className="separator"></div>
                                </Col>
                                <Col>
                                    <div className="airport destination">CUN</div>
                                    <div className="time timeDeparture">03:15 PM</div>
                                </Col>
                            </Row>
                            <div className="date">Martes, 7 febrero, 2023</div>
                        </div>
                    </div>
                    <BoxDetailFlight handleCloseModal={handleCloseModal} show={openModalDetail} fullscreen={fullscreen} scale={false}/>
                </>
            )}
            
        </div>
        <div className="item Passengers">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Pasajeros</Accordion.Header>
                    <Accordion.Body>
                        <div>1 Adulto</div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>

         {position === "2" && (
            <div className="d-block d-lg-none">
                <div className="priceR">
                    <div>Total</div>
                    <div>$76 MXN</div>
                </div>                
            </div>
         )}      
    </div>
  )
};

BoxReservationDetailFlight.defaultProps = {
    showTitle: true
}
export default BoxReservationDetailFlight;
