// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "transferVoucherPage.titleHotelSucces": "Transfer summary",
    "transferVoucherPage.reference": "Reservation",
    "transferVoucherPage.date": "Reservation date",
    "transferVoucherPage.name": "Name",
    "transferVoucherPage.adults": "Adults",
    "transferVoucherPage.childrens": "Childrens",
    "transferVoucherPage.arrive": "arrives",
    "transferVoucherPage.leave": "leaves at",
    "transferVoucherPage.titleError": "Loading...",
    "transferVoucherPage.itinerayFly": "Flight schedule",
    "transferVoucherPage.aereolineFly": "Airline / Flight",
    "transferVoucherPage.fromTo": "From - To",
    "transferVoucherPage.transferItinerary": "Shuttle schedule",
    "transferVoucherPage.titleServices": "Services",
    "transferVoucherPage.read": "*Read carefully",
    "transferVoucherPage.provider": "Provider information",
    "transferVoucherPage.nameProvider": "Name Provider",
    "transferVoucherPage.phoneProvider": "Phone provider",
    "transferVoucherPage.titlePolitics": "Cancellation policies",
    "transferVoucherPage.pay": "Pay",
    "transferVoucherPage.publicRate": "Total amount",
}