import rateUtils from "./rateUtils";
import dateUtils from "./dateUtils";
import { FormattedMessage } from "react-intl";
import Badge from "react-bootstrap/Badge";
import selectPersonsUtils from "./selectPersonsUtils";

//TODO locale es/en/etc
const optionsLocale = (locale) => {
  let optionsLocale = null;

  switch (locale.toLowerCase()) {
    case "es":
      optionsLocale = {
        firstDayOfWeek: 1,
        dayNames: [
          "Domingo",
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sábado",
        ],
        dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
        dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        monthNamesShort: [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ],
        today: "Hoy",
        clear: "Limpiar",
        addRule: "Agregar regla",
        any: "Cualquiera",
        apply: "Aceptar",
        matchAll: "Combinar todos",
        matchAny: "Igualar cualquiera",
        startsWith: "	Comienza con",
        contains: "Contiene",
        notContains: "No contiene",
        endsWith: "Termina con",
        equals: "Igual",
        notEquals: "No es igual",
        noFilter: "Sin filtro",
        dateIs: "Es fecha",
        dateIsNot: "No es fecha",
        dateBefore: "La fecha es anterior",
        dateAfter: "La fecha es posterior",
        lt: "Menos que",
        lte: "Menor o igual que",
        gt: "Mayor que",
        gte: "Mayor o igual que",
        removeRule: "Quitar Regla"
      };
      break;
    default:
       optionsLocale = {
        firstDayOfWeek: 1,
        dayNames: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
        monthNames: [
         "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        monthNamesShort: [
          "Jan",
          "Feb",
          "Sea",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        today: "Today",
        clear: "Clear",
        addRule: "Add Rule",
        any: "Any",
        apply: "Accept",
        matchAll: "Match all",
        matchAny: "Match Any",
        startsWith: "Starts with",
        contains: "Contains",
        notContains: "Does not contain",
        endsWith: "Ends With",
        equals: "Equals",
        notEquals: "Not equal",
        noFilter: "No filter",
        dateIs: "It is date",
        dateIsNot: "Not date",
        dateBefore: "The date is before",
        dateAfter: "The date is after",
        lt: "Less than",
        lte: "Less than or equal to",
        gt: "Greater than",
        gte: "Greater than",
        removeRule: "Remove Rule",
      };
  }

  return optionsLocale;
};

const rateBodyTemplate = (rate, currency, locale) => {
  if(rate ===0 ) return ""
  return rateUtils.formatAmount(rate, currency, locale);
};
const dateBodyTemplate = (date, locale) => {
  return dateUtils.formatDateLocale(date, locale);
};
const objectStatusReservation = (status) => {
  let bgClass = "";
  let text = "";
  switch (status) {
    case "CONFIRMED":
    case "confirmed":
      bgClass = "success";
      text = <FormattedMessage id="general.statusConfirmed" />;
      break;
    case "paid":
      bgClass = "success";
      text = <FormattedMessage id="payment.status.paid" />;
      break;
    case "notpaid":
      bgClass = "danger";
      text = <FormattedMessage id="payment.status.notpaid" />;
      break;
    case "error":
      bgClass = "danger";
      text = <FormattedMessage id="payment.status.error" />;
      break;
    case "refused":
      bgClass = "danger";
      text = <FormattedMessage id="payment.status.refused" />;
      break;
    case "insuficentfunds":
      bgClass = "warning";
      text = <FormattedMessage id="payment.status.insuficentfunds" />;
      break;
    case "pendingsettlement":
      bgClass = "warning";
      text = <FormattedMessage id="payment.status.pendingsettlement" />;
      break;
    case "CANCELLED":
    case "cancelled":
    case "HOLD":
      bgClass = "danger";
      text = <FormattedMessage id="general.statusCancelled" />;
      break;
    case "returned":
      bgClass = "warning";
      text = <FormattedMessage id="payment.status.returned" />;
      break;
    case "pending":
      bgClass = "info";
      text = <FormattedMessage id="payment.status.pending" />;
      break;
    default:
      bgClass = "info";
      text = status;
  }
  return {
    bgClass,
    text,
  };
};

const statusReservationItemTemplate = (option) => {
  const info = objectStatusReservation(option.value);
  return <Badge bg={info.bgClass}>{option.label}</Badge>;
};

const getCustomers = (data) => {

  return [...(data || [])].map((d) => {
    d.dateReservation = new Date(d.dateReservation);
    d.dateLimitPayment = new Date(d.dateLimitPayment);
    d.checkIn = new Date(d.checkIn);
    d.checkOut = new Date(d.checkOut);
    d.customer.fullName = `${d.customer?.lastName} ${d.customer?.name}`;
    d.seller.fullName = `${d.seller?.lastName} ${d.seller?.name}`;
    d.firstRoom = d.rooms[0];
    d.roomsCount = d.rooms.length;
    d.adultsTotal = selectPersonsUtils.totalReservationAdults(d.rooms, d.id);
    d.childrenTotal = selectPersonsUtils.totalReservationChildren(d.rooms);

    if (d.hasCancellationPolicies)
      d.dateLimitCancellation = new Date(d.dateLimitCancellation);

    return d;
  });
};

const dataTableUtils = {
  optionsLocale,
  rateBodyTemplate,
  dateBodyTemplate,
  objectStatusReservation,
  // exportExcel,
  // saveAsExcelFile,
  getCustomers,
};

export default dataTableUtils;
