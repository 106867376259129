import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { useGlobalState } from "../../../store/StoreProvider";
import { useIntl } from "react-intl";
import BoxBooking from "../../bookings/BoxBooking/BoxBooking";
import serviceTypeConstant from "../../../constants/serviceTypeConstant";

const SearchBoxList = (props) => {
	const { params, bodyRenderScroll, type, hideBtn } = props;
	const { locale, resourceSite } = useGlobalState();
	const intl = useIntl();
	const homePath = `${resourceSite.options.home.href}` ?? `${locale}`;
	const {
		searchHotelParams,
		searchPackageParams,
		searchTourParams,
		searchShutterParams,
	} = resourceSite.options;

	const onChangeBooking = (response) => {
		const { onChangeBooking } = props;
		onChangeBooking && onChangeBooking(response);
	};

	return (
		<div id="booking-hotel" className={`search-box-service-hotel  ${bodyRenderScroll ? "box-fixed" : ""}`}>
			<Container>
				<Row>
					<Col className="">
						<div className="pt-2">
							<Container fluid>
								{!bodyRenderScroll && (
									<Breadcrumb className="d-none d-sm-block">
										<Breadcrumb.Item href={`${homePath}`}>
											{intl.formatMessage({
												id: "general.breadcrumdHome",
											})}
										</Breadcrumb.Item>
										<Breadcrumb.Item active>
											{intl.formatMessage({
												id: "general.breadcrumdHotels",
											})}
										</Breadcrumb.Item>
										<Breadcrumb.Item active>
											{params?.inputAutoValueStart?.label}
										</Breadcrumb.Item>
									</Breadcrumb>
								)}
							</Container>
						</div>
						{params && (
							<div className="pb-2 hoteList">
								<Container fluid>
									<BoxBooking
										bodyRenderScroll={bodyRenderScroll}
										params={params}
										locale={locale}
										title=""
										siteRoutes={resourceSite.options?.siteRoutes}
										componentTitle="h2"
										closeColorButton="white"
										type={type}
										searchHotelParams={
											type === serviceTypeConstant.HOTEL
												? searchHotelParams
												: searchHotelParams
										}
										onChangeBooking={onChangeBooking}
										hideBtn={hideBtn}
									/>
								</Container>
							</div>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default SearchBoxList;
