import TagManager from "react-gtm-module";

const pageViewEvent = (location, pageTitle) => {

  TagManager.dataLayer({
    dataLayer: {
      event: "pageview",
      pagePath: window.location.hostname + location.pathname + location.search,
      pageTitle: pageTitle,
    },
  });
};

const searchEvent = (searchData, type) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "search",
      search_type: type,
      search_term: searchData,
    },
  });
};

const purchaseEvent = (ecommerce) => {

  window.dataLayer.push({
    event: "purchase",
    ecommerce: ecommerce,
  });
};
const addPaymentInfoEvent = (ecommerce) => {

  window.dataLayer.push({
    event: "add_payment_info",
    ecommerce: ecommerce,
  });
};

const viewItemListEvent = (ecommerce) => {
  window.dataLayer.push({
    event:"view_item_list",
     ecommerce: ecommerce
     }); 

};

const beginCheckoutEvent = (ecommerce) => {

  window.dataLayer.push({
    event: "begin_checkout",
    ecommerce: ecommerce,
  });
};

const viewItemEvent = (ecommerce) => {

  window.dataLayer.push({
    event:"view_item",
     ecommerce: ecommerce
     }); 
};

const addToCartEvent = (ecommerce) => {
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: ecommerce,
  });
};

const generateLeadEvent = (ecommerce,customer) => {
  window.dataLayer.push({
    event: "generate_lead",
    ecommerce: ecommerce,
    customer: customer
  });
};

const GoogleTagManagerUtils = {
  pageViewEvent,
  searchEvent,
  viewItemListEvent,
  viewItemEvent,
  addToCartEvent,
  addPaymentInfoEvent,
  purchaseEvent,
  beginCheckoutEvent,
  generateLeadEvent
};

export default GoogleTagManagerUtils;
