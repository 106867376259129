const listTourMock = {
    result: [
      {
        id: "5ea6223c-052c-4f0b-b6a0-32d8d4ae75ae",
        externalId: "5ea6223c-052c-4f0b-b6a0-32d8d4ae75ae",
        name: "Parque Xcaret",
        description: "Partial cancellation is not available for roundtrip transfers. Additional or oversized baggage is subject to space availability and may incur an extra surcharge, which is payable to your driver at the time of service. Photographs are meant to illustrate vehicle type only; your vehicle may differ from what is pictured. This is a shared shuttle and a maximum of 3 stops is guaranteed Infant car seats are the traveller’s responsibility; children must be seated in a size appropriate safety restraint, in accordance with local law.",
        address: "Cancun",
        duration: "10 hrs",
        category: "Acuatico",
        photos: [
          {
            name: "Parque Xcaret - 1",
            url: "https://www.xcaret.com/assets/xcaret/og/og.jpg",
          }
        ],
        geoPosition: {
          latitude: 21.056093,
          longitude: -86.781217,
        },
        bestRate: {
          grouped: [
            {
              id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-RO-NRF-TODOS",
              rateKey:
                "2022-11-23|2022-11-26|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|DBL.ST|BOOKABLE|NRF|RO|MXN|NRF-TODOS|1476.39|MX|638010340917159839;7CADB2BABF6CB95AB0B4F01F972FB64D",
              isRefundable: false,
              refundable: "No puedes cancelar ni realizar cambios",
              mealPlan: "Room Only",
              mealPlanCode: "RO",
              total: 1560.390305775,
              differenceOverLowPrice: 0,
              currency: "MXN",
              promotions: ["Non-refundable rate. No amendments permitted"],
              cancellationPolicies: [],
              taxes: [
                {
                  included: false,
                  total: 84,
                  currency: "MXN",
                },
              ],
              nights: 3,
              pricePerNight: 1200,
            },
          ],
          id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-RO-NRF-TODOS",
          currency: "MXN",
          isRefundable: false,
          hasPromotions: true,
          hasOffers: false,
          hasCancellationPolicies: false,
          refundable: "No puedes cancelar ni realizar cambios",
          mealPlan: "Room Only",
          mealPlanCode: "RO",
          total: 1560,
          promotions: ["Non-refundable rate. No amendments permitted"],
          cancellationPolicies: [],
          offers: [],
          totalDiscounted: 0,
          discountRate: 0,
          totalWithOffers: 1200,
          totalDifferenceOverLowPrice: 0,
          totalPerNight: 1029,
        },
        amenities: ["Vigil - Gaona", "Alarcón Hermanos", "Razo - Arce"],
      },
      {
        id: "23591bc9-4ce6-4196-8df0-65bb5fd97557",
        externalId: "23591bc9-4ce6-4196-8df0-65bb5fd97557",
        name: "Grand Cañon Borde Sur en autobus",
        duration: "8 hrs",
        category: "Acuatico",
        description: "Partial cancellation is not available for roundtrip transfers. Additional or oversized baggage is subject to space availability and may incur an extra surcharge, which is payable to your driver at the time of service. Photographs are meant to illustrate vehicle type only; your vehicle may differ from what is pictured. This is a shared shuttle and a maximum of 3 stops is guaranteed Infant car seats are the traveller’s responsibility; children must be seated in a size appropriate safety restraint, in accordance with local law.",
        address: "Cancun",
        offerSale: "50% de descuento",
        hasOffers: false,
        photos: [
          {
            name: "Hotel Dos Playas Faranda Cancun - 1",
            url: "https://media.tacdn.com/media/attractions-splice-spp-674x446/07/3b/52/b6.jpg",
          }
        ],
        geoPosition: {
          latitude: 21.140095,
          longitude: -86.770597,
        },
        bestRate: {
          grouped: [
            {
              id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-GARRO",
              rateKey:
                "2022-11-17|2022-11-19|2|23591bc9-4ce6-4196-8df0-65bb5fd97557|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|GARRO|3953.46|MX|638005925048456335;E772417F13860F98096A19C4799F44AC",
              isRefundable: true,
              refundable: "Tarifa reembolsable",
              mealPlan: "Room Only",
              mealPlanCode: "RO",
              total: 4009.4622578,
              differenceOverLowPrice: 0,
              currency: "MXN",
              promotions: [],
              cancellationPolicies: [
                "Puedes cancelar hasta el 08/11/2022 a las 4:59",
                "Puedes cancelar hasta el 15/11/2022 a las 4:59",
                "Puedes cancelar hasta el 11/11/2022 a las 4:59",
                "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              ],
              taxes: [
                {
                  included: false,
                  total: 56,
                  currency: "MXN",
                },
              ],
              nights: 2,
              pricePerNight: 1405,
            },
          ],
          id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-GARRO",
          currency: "MXN",
          isRefundable: true,
          hasPromotions: false,
          hasCancellationPolicies: true,
          refundable: "Tarifa reembolsable",
          mealPlan: "Room Only",
          mealPlanCode: "RO",
          total: 4009,
          promotions: [],
          cancellationPolicies: [
            "Puedes cancelar hasta el 08/11/2022 a las 4:59",
            "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            "Puedes cancelar hasta el 11/11/2022 a las 4:59",
            "Puedes cancelar hasta el 18/11/2022 a las 4:59",
          ],
          offers: [],
          totalDiscounted: 0,
          discountRate: 0,
          totalWithOffers: 4009,
          totalDifferenceOverLowPrice: 0,
          totalPerNight: 2005,
        }
      },
      {
        id: "93f9750c-4df2-4bee-92f0-012abb7aaf35",
        externalId: "93f9750c-4df2-4bee-92f0-012abb7aaf35",
        name: "dolphin discovery",
        duration: "2 hrs",
        category: "Acuatico",
        description: "Partial cancellation is not available for roundtrip transfers. Additional or oversized baggage is subject to space availability and may incur an extra surcharge, which is payable to your driver at the time of service. Photographs are meant to illustrate vehicle type only; your vehicle may differ from what is pictured. This is a shared shuttle and a maximum of 3 stops is guaranteed Infant car seats are the traveller’s responsibility; children must be seated in a size appropriate safety restraint, in accordance with local law.",        
        address: "Cancun",
        photos: [
          {
            name: "Entrada Xcaret - 1",
            url: "https://www.dolphindiscovery.com.mx/src/newimage/programs/royal-swim.jpg",
          }
        ],
        geoPosition: {
          latitude: 21.139753,
          longitude: -86.771329,
        },
        bestRate: {
          grouped: [
            {
              id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-GARRO",
              rateKey:
                "2022-11-17|2022-11-19|2|93f9750c-4df2-4bee-92f0-012abb7aaf35|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|GARRO|3953.46|MX|638005925048456966;9FD81F25F1FE04C6B44FF19EE6841BCF",
              isRefundable: true,
              refundable: "Tarifa reembolsable",
              mealPlan: "Room Only",
              mealPlanCode: "RO",
              total: 4009.4622578,
              differenceOverLowPrice: 0,
              currency: "MXN",
              promotions: [],
              cancellationPolicies: [
                "Puedes cancelar hasta el 08/11/2022 a las 4:59",
                "Puedes cancelar hasta el 15/11/2022 a las 4:59",
                "Puedes cancelar hasta el 11/11/2022 a las 4:59",
                "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              ],
              taxes: [
                {
                  included: false,
                  total: 56,
                  currency: "MXN",
                },
              ],
              nights: 2,
              pricePerNight: 2005,
            },
          ],
          id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-GARRO",
          currency: "MXN",
          isRefundable: true,
          hasPromotions: false,
          hasOffers: false,
          hasCancellationPolicies: true,
          refundable: "Tarifa reembolsable",
          mealPlan: "Room Only",
          mealPlanCode: "RO",
          total: 4009,
          promotions: [],
          cancellationPolicies: [
            "Puedes cancelar hasta el 08/11/2022 a las 4:59",
            "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            "Puedes cancelar hasta el 11/11/2022 a las 4:59",
            "Puedes cancelar hasta el 18/11/2022 a las 4:59",
          ],
          offers: [],
          totalDiscounted: 0,
          discountRate: 0,
          totalWithOffers: 4009,
          totalDifferenceOverLowPrice: 0,
          totalPerNight: 2005,
        },
        amenities: ["Delapaz - Tirado", "Macías Hermanos"],
      },
      {
        id: "e22f5831-db93-4bcc-9928-9f812c940bf5",
        externalId: "e22f5831-db93-4bcc-9928-9f812c940bf5",
        name: "Vuelo en Globo",
        duration: "3 hrs",
        category: "Aventura",
        description: "Partial cancellation is not available for roundtrip transfers. Additional or oversized baggage is subject to space availability and may incur an extra surcharge, which is payable to your driver at the time of service. Photographs are meant to illustrate vehicle type only; your vehicle may differ from what is pictured. This is a shared shuttle and a maximum of 3 stops is guaranteed Infant car seats are the traveller’s responsibility; children must be seated in a size appropriate safety restraint, in accordance with local law.",
        address: "Cancun",
        photos: [
          {
            name: "Golden Parnassus - 1",
            url: "https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/24/57/8e.jpg",
          }
        ],
        geoPosition: {
          latitude: 21.091989,
          longitude: -86.768727,
        },
        bestRate: {
          grouped: [
            {
              id: "DBL.LX-OV-BOOKABLE-NOR-False-AT_WEB-AI-CGW-MEXB2B",
              rateKey:
                "2022-11-17|2022-11-19|2|e22f5831-db93-4bcc-9928-9f812c940bf5|HotelBeds|DBL.LX-OV|BOOKABLE|NOR|AI|MXN|CGW-MEXB2B|17272.28|MX|638005925048460609;8016419072D3D0D36A1366D1073A90B3",
              isRefundable: true,
              refundable: "Tarifa reembolsable",
              mealPlan: "All Inclusive",
              mealPlanCode: "AI",
              total: 17328.27838435,
              differenceOverLowPrice: 0,
              currency: "MXN",
              promotions: [],
              cancellationPolicies: [
                "Puedes cancelar hasta el 13/11/2022 a las 4:59",
                "Puedes cancelar hasta el 16/11/2022 a las 4:59",
              ],
              taxes: [
                {
                  included: false,
                  total: 56,
                  currency: "MXN",
                },
              ],
              offers: [
                {
                  name: "Special discount",
                  total: 727.46989832,
                },
              ],
              nights: 2,
              pricePerNight: 1664,
            },
          ],
          id: "DBL.LX-OV-BOOKABLE-NOR-False-AT_WEB-AI-CGW-MEXB2B",
          currency: "MXN",
          isRefundable: true,
          hasPromotions: false,
          hasOffers: true,
          hasCancellationPolicies: true,
          refundable: "Tarifa reembolsable",
          mealPlan: "All Inclusive",
          mealPlanCode: "AI",
          total: 17328,
          promotions: [],
          cancellationPolicies: [
            "Puedes cancelar hasta el 13/11/2022 a las 4:59",
            "Puedes cancelar hasta el 16/11/2022 a las 4:59",
          ],
          offers: [
            {
              name: "Special discount",
              total: 727.46989832,
            },
          ],
          totalDiscounted: 727,
          discountRate: 4,
          totalWithOffers: 18055,
          totalDifferenceOverLowPrice: 0,
          totalPerNight: 8664,
        },
        amenities: [
          "Vigil - Carbajal",
          "Escobedo, Bueno and Reynoso",
          "Luevano S.A.",
          "Zayas S.L.",
        ],
      },
      {
        id: "910a04dc-38c2-4b96-aa36-d1c2ac95a1df",
        externalId: "910a04dc-38c2-4b96-aa36-d1c2ac95a1df",
        name: "Tour Riviera:Tulum",
        duration: "2 hrs",
        category: "Aventura",
        description: "Partial cancellation is not available for roundtrip transfers. Additional or oversized baggage is subject to space availability and may incur an extra surcharge, which is payable to your driver at the time of service. Photographs are meant to illustrate vehicle type only; your vehicle may differ from what is pictured. This is a shared shuttle and a maximum of 3 stops is guaranteed Infant car seats are the traveller’s responsibility; children must be seated in a size appropriate safety restraint, in accordance with local law.",
        address: "Cancun",
        photos: [
          {
            name: "Hotel Imperial Laguna Faranda cancun - 1",
            url: "https://d1el5jddkxvjyd.cloudfront.net/viceroyhotelsandresorts.com-2109743334/cms/cache/v2/6130189f3eb1f.jpg/1920x1080/fit/80/a4ea12931c990e242201612729ffac49.jpg",
          }
        ],
        geoPosition: {
          latitude: 21.133097,
          longitude: -86.757455,
        },
        bestRate: {
          grouped: [
            {
              id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-GARRO",
              rateKey:
                "2022-11-17|2022-11-19|2|910a04dc-38c2-4b96-aa36-d1c2ac95a1df|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|GARRO|2063.46|MX|638005925048466932;86508B6E075172A26F302AB20EB18432",
              isRefundable: true,
              refundable: "Tarifa reembolsable",
              mealPlan: "Room Only",
              mealPlanCode: "RO",
              total: 2119.456871575,
              differenceOverLowPrice: 0,
              currency: "MXN",
              promotions: [],
              cancellationPolicies: [
                "Puedes cancelar hasta el 08/11/2022 a las 4:59",
                "Puedes cancelar hasta el 15/11/2022 a las 4:59",
                "Puedes cancelar hasta el 11/11/2022 a las 4:59",
                "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              ],
              taxes: [
                {
                  included: false,
                  total: 56,
                  currency: "MXN",
                },
              ],
              nights: 2,
              pricePerNight: 1260,
            },
          ],
          id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-GARRO",
          currency: "MXN",
          isRefundable: true,
          hasPromotions: false,
          hasOffers: false,
          hasCancellationPolicies: true,
          refundable: "Tarifa reembolsable",
          mealPlan: "Room Only",
          mealPlanCode: "RO",
          total: 2119,
          promotions: [],
          cancellationPolicies: [
            "Puedes cancelar hasta el 08/11/2022 a las 4:59",
            "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            "Puedes cancelar hasta el 11/11/2022 a las 4:59",
            "Puedes cancelar hasta el 18/11/2022 a las 4:59",
          ],
          offers: [],
          totalDiscounted: 0,
          discountRate: 0,
          totalWithOffers: 2119,
          totalDifferenceOverLowPrice: 0,
          totalPerNight: 1060,
        },
        amenities: [
          "Guardado - Ponce",
          "Rojas S.A.",
          "Aguayo, Valenzuela and Alcaraz",
          "Naranjo, Garrido and Tirado",
        ],
      },
      {
        id: "a56b6235-5963-4999-ac28-c63b0a0fd82b",
        externalId: "a56b6235-5963-4999-ac28-c63b0a0fd82b",
        name: "Navegación a Isla Mujeres con barra libre y snorkel",
        duration: "7 hrs",        
        description: "Partial cancellation is not available for roundtrip transfers. Additional or oversized baggage is subject to space availability and may incur an extra surcharge, which is payable to your driver at the time of service. Photographs are meant to illustrate vehicle type only; your vehicle may differ from what is pictured. This is a shared shuttle and a maximum of 3 stops is guaranteed Infant car seats are the traveller’s responsibility; children must be seated in a size appropriate safety restraint, in accordance with local law.",
        address: "Cancun",
        category: "Acuatico",
        photos: [
          {
            name: "Beachscape Kin Ha Villas & Suites - 1",
            url: "https://www.yalkutours.com.mx/up/media/isla-mujeres-new-ok-2.jpg",
          }
        ],
        geoPosition: {
          latitude: 21.135977,
          longitude: -86.753946,
        },
        bestRate: {
          grouped: [
            {
              id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-PPAMEX",
              rateKey:
                "2022-11-17|2022-11-19|2|a56b6235-5963-4999-ac28-c63b0a0fd82b|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|PPAMEX|5525.30|MX|638005925048467024;7FCA1E93529115B214F50FC0B4C49B46",
              isRefundable: true,
              refundable: "Tarifa reembolsable",
              mealPlan: "Room Only",
              mealPlanCode: "RO",
              total: 5581.302197525,
              differenceOverLowPrice: 0,
              currency: "MXN",
              promotions: [],
              cancellationPolicies: [
                "Puedes cancelar hasta el 14/11/2022 a las 4:59",
                "Puedes cancelar hasta el 17/11/2022 a las 4:59",
              ],
              taxes: [
                {
                  included: false,
                  total: 56,
                  currency: "MXN",
                },
              ],
              nights: 2,
              pricePerNight: 2791,
            },
          ],
          id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-PPAMEX",
          currency: "MXN",
          isRefundable: true,
          hasPromotions: false,
          hasOffers: false,
          hasCancellationPolicies: true,
          refundable: "Tarifa reembolsable",
          mealPlan: "Room Only",
          mealPlanCode: "RO",
          total: 5581,
          promotions: [],
          cancellationPolicies: [
            "Puedes cancelar hasta el 14/11/2022 a las 4:59",
            "Puedes cancelar hasta el 17/11/2022 a las 4:59",
          ],
          offers: [],
          totalDiscounted: 0,
          discountRate: 0,
          totalWithOffers: 5581,
          totalDifferenceOverLowPrice: 0,
          totalPerNight: 2791,
        },
        amenities: ["Acosta S.L.", "Miranda Hermanos"],
      },
      {
        id: "fa547dbd-a22d-425f-8447-1dc24553febd",
        externalId: "fa547dbd-a22d-425f-8447-1dc24553febd",
        name: "Chichén Itzá y Valladolid: nado en cenote y almuerzo",
       duration: "10 hrs",
        category: "Aventura",  
           description: "Partial cancellation is not available for roundtrip transfers. Additional or oversized baggage is subject to space availability and may incur an extra surcharge, which is payable to your driver at the time of service. Photographs are meant to illustrate vehicle type only; your vehicle may differ from what is pictured. This is a shared shuttle and a maximum of 3 stops is guaranteed Infant car seats are the traveller’s responsibility; children must be seated in a size appropriate safety restraint, in accordance with local law.",
        address: "Cancun",
        photos: [
          {
            name: "Selina  Cancun Laguna Zona Hotelera - 1",
            url: "https://www.mexicodesconocido.com.mx/wp-content/uploads/2010/05/chichen-itza-arquitectura-que-visitar.png",
          }
        ],
        geoPosition: {
          latitude: 21.169379,
          longitude: -86.844882,
        },
        bestRate: {
          grouped: [
            {
              id: "DBL.FH-BOOKABLE-NOR-True-AT_WEB-RO-GARRONRF",
              rateKey:
                "2022-11-17|2022-11-19|2|fa547dbd-a22d-425f-8447-1dc24553febd|HotelBeds|DBL.FH|BOOKABLE|NOR|RO|MXN|GARRONRF|3213.21|MX|638005925048471393;B0803E66BA72ACEA4EEA813BC9DAD119",
              isRefundable: true,
              refundable: "Tarifa reembolsable",
              mealPlan: "Room Only",
              mealPlanCode: "RO",
              total: 3269.2144246125,
              differenceOverLowPrice: 0,
              currency: "MXN",
              promotions: [],
              cancellationPolicies: [
                "Puedes cancelar hasta el 10/11/2022 a las 4:59",
                "Puedes cancelar hasta el 13/11/2022 a las 4:59",
              ],
              taxes: [
                {
                  included: false,
                  total: 56,
                  currency: "MXN",
                },
              ],
              offers: [
                {
                  name: "Exclusive discount",
                  total: 285.73309776,
                },
              ],
              nights: 2,
              pricePerNight: 1635,
            },
          ],
          id: "DBL.FH-BOOKABLE-NOR-True-AT_WEB-RO-GARRONRF",
          currency: "MXN",
          isRefundable: true,
          hasPromotions: false,
          hasOffers: true,
          hasCancellationPolicies: true,
          refundable: "Tarifa reembolsable",
          mealPlan: "Room Only",
          mealPlanCode: "RO",
          total: 3269,
          promotions: [],
          cancellationPolicies: [
            "Puedes cancelar hasta el 10/11/2022 a las 4:59",
            "Puedes cancelar hasta el 13/11/2022 a las 4:59",
          ],
          offers: [
            {
              name: "Exclusive discount",
              total: 285.73309776,
            },
          ],
          totalDiscounted: 286,
          discountRate: 8,
          totalWithOffers: 3555,
          totalDifferenceOverLowPrice: 0,
          totalPerNight: 1535,
        },
        amenities: ["Velásquez e Hijos", "Alva, Palomino and Prado"],
      },
      {
        id: "ba61127e-a448-485f-904a-4cad211f17d2",
        externalId: "ba61127e-a448-485f-904a-4cad211f17d2",
        name: "Xplor Cancún",
        duration: "10 hrs",
        category: "Aventura",
           description: "Partial cancellation is not available for roundtrip transfers. Additional or oversized baggage is subject to space availability and may incur an extra surcharge, which is payable to your driver at the time of service. Photographs are meant to illustrate vehicle type only; your vehicle may differ from what is pictured. This is a shared shuttle and a maximum of 3 stops is guaranteed Infant car seats are the traveller’s responsibility; children must be seated in a size appropriate safety restraint, in accordance with local law.",
        address: "Cancun",
        photos: [
          {
            name: "Grand Park Royal Cancun - 1",
            url: "https://www.xplor.travel/img/actividades/entrada/xplor_0017_003_balsas.jpg",
          }
        ],
        geoPosition: {
          latitude: 21.123445,
          longitude: -86.754114,
        },
        bestRate: {
          grouped: [
            {
              id: "DBL.LX-BOOKABLE-NOR-False-AT_WEB-AI-SPA-0",
              rateKey:
                "2022-11-17|2022-11-19|2|ba61127e-a448-485f-904a-4cad211f17d2|HotelBeds|DBL.LX|BOOKABLE|NOR|AI|MXN|SPA-0|15377.65|MX|638005925048492341;26278D7B4C9621427444561BD45BB658",
              isRefundable: true,
              refundable: "Tarifa reembolsable",
              mealPlan: "All Inclusive",
              mealPlanCode: "AI",
              total: 15433.65446745,
              differenceOverLowPrice: 0,
              currency: "MXN",
              promotions: [],
              cancellationPolicies: [
                "Puedes cancelar hasta el 12/11/2022 a las 4:59",
                "Puedes cancelar hasta el 15/11/2022 a las 4:59",
              ],
              taxes: [
                {
                  included: false,
                  total: 56,
                  currency: "MXN",
                },
              ],
              nights: 2,
              pricePerNight: 7717,
            },
          ],
          id: "DBL.LX-BOOKABLE-NOR-False-AT_WEB-AI-SPA-0",
          currency: "MXN",
          isRefundable: true,
          hasPromotions: false,
          hasOffers: false,
          hasCancellationPolicies: true,
          refundable: "Tarifa reembolsable",
          mealPlan: "All Inclusive",
          mealPlanCode: "AI",
          total: 15434,
          promotions: [],
          cancellationPolicies: [
            "Puedes cancelar hasta el 12/11/2022 a las 4:59",
            "Puedes cancelar hasta el 15/11/2022 a las 4:59",
          ],
          offers: [],
          totalDiscounted: 0,
          discountRate: 0,
          totalWithOffers: 15434,
          totalDifferenceOverLowPrice: 0,
          totalPerNight: 7717,
        },
        amenities: [
          "Gallardo S.L.",
          "Orellana S.L.",
          "Báez, Ortiz and Montañez",
          "Tamez e Hijos",
        ],
      },
      {
        id: "5698e78b-3304-44fa-a573-44efc0565a6f",
        externalId: "5698e78b-3304-44fa-a573-44efc0565a6f",
        name: "Xel Ha",
        duration: "10 hrs",
        category: "Aventura",        
           description: "Partial cancellation is not available for roundtrip transfers. Additional or oversized baggage is subject to space availability and may incur an extra surcharge, which is payable to your driver at the time of service. Photographs are meant to illustrate vehicle type only; your vehicle may differ from what is pictured. This is a shared shuttle and a maximum of 3 stops is guaranteed Infant car seats are the traveller’s responsibility; children must be seated in a size appropriate safety restraint, in accordance with local law.",
        address: "Cancun",
        photos: [
          {
            name: "Temptation Cancun Resort - Adults Only - 1",
            url: "https://www.turismomexico.es/wp-content/uploads/2021/03/parque_xel_ha_mexico.jpg",
          }
        ],
        geoPosition: {
          latitude: 21.148108,
          longitude: -86.792109,
        },
        bestRate: {
          grouped: [
            {
              id: "DBL.GV-1-BOOKABLE-NOR-False-AT_WEB-AI-CG-MEXB2B",
              rateKey:
                "2022-11-17|2022-11-19|2|5698e78b-3304-44fa-a573-44efc0565a6f|HotelBeds|DBL.GV-1|BOOKABLE|NOR|AI|MXN|CG-MEXB2B|19153.05|MX|638005925048494441;6DD72D94AA2D45F28F2FE442812CF0C2",
              isRefundable: true,
              refundable: "Tarifa reembolsable",
              mealPlan: "All Inclusive",
              mealPlanCode: "AI",
              total: 19209.046709225,
              differenceOverLowPrice: 0,
              currency: "MXN",
              promotions: [],
              cancellationPolicies: [
                "Puedes cancelar hasta el 03/10/2022 a las 3:59",
                "Puedes cancelar hasta el 06/10/2022 a las 3:59",
              ],
              taxes: [
                {
                  included: false,
                  total: 56,
                  currency: "MXN",
                },
              ],
              nights: 2,
              pricePerNight: 9605,
            },
          ],
          id: "DBL.GV-1-BOOKABLE-NOR-False-AT_WEB-AI-CG-MEXB2B",
          currency: "MXN",
          isRefundable: true,
          hasPromotions: false,
          hasOffers: false,
          hasCancellationPolicies: true,
          refundable: "Tarifa reembolsable",
          mealPlan: "All Inclusive",
          mealPlanCode: "AI",
          total: 19209,
          promotions: [],
          cancellationPolicies: [
            "Puedes cancelar hasta el 03/10/2022 a las 3:59",
            "Puedes cancelar hasta el 06/10/2022 a las 3:59",
          ],
          offers: [],
          totalDiscounted: 0,
          discountRate: 0,
          totalWithOffers: 19209,
          totalDifferenceOverLowPrice: 0,
          totalPerNight: 9605,
        },
        amenities: [
          "Mesa - Gamboa",
          "Negrón e Hijos",
          "Meza, Guardado and Mascareñas",
          "Herrera - Carrero",
        ],
      },
      {
        id: "4bf69d5f-ae8f-40b0-8893-f6143b7d78b1",
        externalId: "4bf69d5f-ae8f-40b0-8893-f6143b7d78b1",
        name: "Tulum & Coba Tradiciones Mayas Comida Bebidas Cenote",
        duration: "10 hrs",
        category: "Aventura",
        
           description: "Partial cancellation is not available for roundtrip transfers. Additional or oversized baggage is subject to space availability and may incur an extra surcharge, which is payable to your driver at the time of service. Photographs are meant to illustrate vehicle type only; your vehicle may differ from what is pictured. This is a shared shuttle and a maximum of 3 stops is guaranteed Infant car seats are the traveller’s responsibility; children must be seated in a size appropriate safety restraint, in accordance with local law.",
        address: "Cancun",
        photos: [
          {
            name: "Sotavento - 1",
            url: "https://www.senatorrivieracancunsparesort.com/wp-content/uploads/2019/11/tulum2.jpg",
          }
        ],
        geoPosition: {
          latitude: 21.144169,
          longitude: -86.790685,
        },
        bestRate: {
          grouped: [
            {
              id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-4B2B-RO",
              rateKey:
                "2022-11-17|2022-11-19|2|4bf69d5f-ae8f-40b0-8893-f6143b7d78b1|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|CGW-4B2B-RO|1956.72|MX|638005925048497827;C48053FD48AA3D1A91380AA00936123F",
              isRefundable: true,
              refundable: "Tarifa reembolsable",
              mealPlan: "Room Only",
              mealPlanCode: "RO",
              total: 2012.717495975,
              differenceOverLowPrice: 0,
              currency: "MXN",
              promotions: [],
              cancellationPolicies: [
                "Puedes cancelar hasta el 13/11/2022 a las 4:59",
                "Puedes cancelar hasta el 16/11/2022 a las 4:59",
              ],
              taxes: [
                {
                  included: false,
                  total: 56,
                  currency: "MXN",
                },
              ],
              nights: 2,
              pricePerNight: 1506,
            },
          ],
          id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-4B2B-RO",
          currency: "MXN",
          isRefundable: true,
          hasPromotions: false,
          hasOffers: false,
          hasCancellationPolicies: true,
          refundable: "Tarifa reembolsable",
          mealPlan: "Room Only",
          mealPlanCode: "RO",
          total: 2013,
          promotions: [],
          cancellationPolicies: [
            "Puedes cancelar hasta el 13/11/2022 a las 4:59",
            "Puedes cancelar hasta el 16/11/2022 a las 4:59",
          ],
          offers: [],
          totalDiscounted: 0,
          discountRate: 0,
          totalWithOffers: 2013,
          totalDifferenceOverLowPrice: 0,
          totalPerNight: 1206,
        },
        amenities: [
          "Patiño - Alba",
          "Valdés - Melgar",
          "Castañeda S.A.",
          "Pulido Hermanos",
        ],
      },
      {
        id: "22b19cd6-20fc-4b97-8708-a2d920a0c1d2",
        externalId: "22b19cd6-20fc-4b97-8708-a2d920a0c1d2",
        name: "Lo mejor de HolBox",
        duration: "10 hrs",
        category: "Aventura",
        
           description: "Partial cancellation is not available for roundtrip transfers. Additional or oversized baggage is subject to space availability and may incur an extra surcharge, which is payable to your driver at the time of service. Photographs are meant to illustrate vehicle type only; your vehicle may differ from what is pictured. This is a shared shuttle and a maximum of 3 stops is guaranteed Infant car seats are the traveller’s responsibility; children must be seated in a size appropriate safety restraint, in accordance with local law.",
        address: "Cancun",
        photos: [
          {
            name: "Imperial las Perlas - 1",
            url: "https://zoeviajando.com/wp-content/uploads/2020/06/holbox-mx3.jpg",
          }
        ],
        geoPosition: {
          latitude: 21.155781,
          longitude: -86.799629,
        },
        bestRate: {
          grouped: [
            {
              id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-MEXROB2B",
              rateKey:
                "2022-11-17|2022-11-19|2|22b19cd6-20fc-4b97-8708-a2d920a0c1d2|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|CGW-MEXROB2B|2325.69|MX|638005925048505433;2BCE7E668D2185798A028589B4B0C005",
              isRefundable: true,
              refundable: "Tarifa reembolsable",
              mealPlan: "Room Only",
              mealPlanCode: "RO",
              total: 2381.6867799,
              differenceOverLowPrice: 0,
              currency: "MXN",
              promotions: [],
              category: "Acuatico",
              cancellationPolicies: [
                "Puedes cancelar hasta el 08/11/2022 a las 4:59",
                "Puedes cancelar hasta el 15/11/2022 a las 4:59",
                "Puedes cancelar hasta el 11/11/2022 a las 4:59",
                "Puedes cancelar hasta el 18/11/2022 a las 4:59",
              ],
              taxes: [
                {
                  included: false,
                  total: 56,
                  currency: "MXN",
                },
              ],
              nights: 2,
              pricePerNight: 1191,
            },
          ],
          id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-MEXROB2B",
          currency: "MXN",
          isRefundable: true,
          hasPromotions: false,
          hasOffers: false,
          hasCancellationPolicies: true,
          refundable: "Tarifa reembolsable",
          mealPlan: "Room Only",
          mealPlanCode: "RO",
          total: 2382,
          promotions: [],
          cancellationPolicies: [
            "Puedes cancelar hasta el 08/11/2022 a las 4:59",
            "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            "Puedes cancelar hasta el 11/11/2022 a las 4:59",
            "Puedes cancelar hasta el 18/11/2022 a las 4:59",
          ],
          offers: [],
          totalDiscounted: 0,
          discountRate: 0,
          totalWithOffers: 2382,
          totalDifferenceOverLowPrice: 0,
          totalPerNight: 1191,
        },
  
      },
      {
        id: "45f4ca52-703a-42b9-af2c-e85d9cc96f3c",
        externalId: "45f4ca52-703a-42b9-af2c-e85d9cc96f3c",
        name: "Disney",
        category: "Aventura",
        duration: "7 hrs",
        address: "Orlando",
        photos: [
          {
            name: "Aquamarina Beach - 1",
            url: "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/90/media/disneyparks_v0100/1/media/mediaplayer/homepage/mickey-minnie-16x9.jpg",
          }
        ],
        geoPosition: {
          latitude: 21.146455,
          longitude: -86.790548,
        },
        bestRate: {
          grouped: [
            {
              id: "DBL.VM-BOOKABLE-NOR-True-AT_WEB-RO-CGW-MEXB2BRO",
              rateKey:
                "2022-11-17|2022-11-19|2|45f4ca52-703a-42b9-af2c-e85d9cc96f3c|HotelBeds|DBL.VM|BOOKABLE|NOR|RO|MXN|CGW-MEXB2BRO|3247.85|MX|638005925048508156;6F0B98C9C81BF067327C16EF914610EC",
              isRefundable: true,
              refundable: "Tarifa reembolsable",
              mealPlan: "Room Only",
              mealPlanCode: "RO",
              total: 3303.853404675,
              differenceOverLowPrice: 0,
              currency: "MXN",
              promotions: [],
              cancellationPolicies: [
                "Puedes cancelar hasta el 08/11/2022 a las 4:59",
                "Puedes cancelar hasta el 11/11/2022 a las 4:59",
              ],
              taxes: [
                {
                  included: false,
                  total: 56,
                  currency: "MXN",
                },
              ],
              offers: [
                {
                  name: "Exclusive discount",
                  total: 137.11904404,
                },
              ],
              nights: 2,
              pricePerNight: 1652,
            },
          ],
          id: "DBL.VM-BOOKABLE-NOR-True-AT_WEB-RO-CGW-MEXB2BRO",
          currency: "MXN",
          isRefundable: true,
          hasPromotions: false,
          hasOffers: true,
          hasCancellationPolicies: true,
          refundable: "Tarifa reembolsable",
          mealPlan: "Room Only",
          mealPlanCode: "RO",
          total: 3304,
          promotions: [],
          cancellationPolicies: [
            "Puedes cancelar hasta el 08/11/2022 a las 4:59",
            "Puedes cancelar hasta el 11/11/2022 a las 4:59",
          ],
          offers: [
            {
              name: "Exclusive discount",
              total: 137.11904404,
            },
          ],
          totalDiscounted: 137,
          discountRate: 4,
          totalWithOffers: 3441,
          totalDifferenceOverLowPrice: 0,
          totalPerNight: 1652,
        },
        amenities: [
          "Zambrano Hermanos",
          "Porras - Ponce",
          "Argüello - Henríquez",
          "Tejada, Sedillo and Alaníz",
        ],
      },    
    ],
    filters: [
      {
        code: "CATEGORY",
        title: "Categoria",
        items: ["Aventura", "Acuatico"],
      },
      {
        code: "DURATION",
        title: "Duration",
        items: ["10 hrs", "7 hrs", "2 hrs"],
      },
    
    ],
    numberHotels: 12,
  };
  
  export default listTourMock;
  
  