const formatAmount = (amount, currency, locale) => {
	const roundAmount = amount ? amount.toFixed() : 0;
	const options = { style: 'currency', currency: currency, currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0 };
	const numberFormat = new Intl.NumberFormat(locale, options);
	return `${numberFormat.format(roundAmount)} ${currency}`;
}

const formatAmountWithoutCoin = (amount, currency, locale) => {
	const options = { style: 'currency', currency: currency, currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0 };
	const numberFormat = new Intl.NumberFormat(locale, options);
	const parts = numberFormat.formatToParts(amount);
	const symbol = parts.find(p => p.type === 'currency').value;
	return numberFormat.format(amount).replace(symbol, '');
}

const rateUtils = {
	formatAmount,
	formatAmountWithoutCoin
}

export default rateUtils;