import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import storage from "../localstorage/storage";
import dateUtils from "../utils/dateUtils";

//TODO redirect from vouchers
const useRedirectHomeWithTimerAndDeteleLocalStorage = (cart, minute, locale) => {
    let navigate = useNavigate();

    const goBackPage = () => {
        //TODO any service back page to home
        const locationPage = {
          pathname: `/${locale}`,
        };
        window.scrollTo(0, 0);
        navigate(locationPage);
        window.removeEventListener('popstate', goBackPage);
    }

    useEffect(() => {
        if(cart !== null) {
            window.addEventListener('popstate', goBackPage);
            setTimeout(()=> {
                //TODO delete cart and any carts
                storage.deleteLocalStorage(cart.uuid);
                storage.removeObjectsWithExpiry();

                const locationPage = {
                  pathname: `/${locale}`,
                };
                window.scrollTo(0, 0);
                navigate(locationPage);
              }, dateUtils.minutesToSecondsDate(minute));
        }
    }, [cart])
}

export default useRedirectHomeWithTimerAndDeteleLocalStorage;