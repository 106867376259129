import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import GoogleMap from "google-map-react";
import rateUtils from "../../../utils/rateUtils";
import mapUtils from "../../../utils/mapUtils";
import CardContainerServiceMap from "../../cards/CardContainerServiceMap";
import { useDeviceSelectors } from "react-device-detect";
import "./components/style.scss";

const Map = (props) => {
	let { markersData } = props;
	const { apiKey, coordInitial, currency, serviceType, glusterParams, zoomDefault, zoomChangeChips, txt, styleContent, locale, fullscreenControl, zoomControl, streetViewControl, pointInterest, limit, radiusPoint } = props;
	const [view, setView] = useState(null)
	const [params, setParams] = useState({
		zoom: zoomDefault,
		center: coordInitial
	});
	const [apiMaps, setApiMaps] = useState({
		map: null,
		maps: null
	});
	const [loading, setLoading] = useState(true);
	const chipIndex = useRef(1);
	const [cardStyle, setCardStyle] = useState({});
	const [selectors] = useDeviceSelectors(window.navigator.userAgent);
	const { isMobile, isTablet } = selectors;

	markersData = markersData.filter(h => h.bestRate);
	useEffect(() => {
		const container = document.querySelector("#map-container");
		const bottom = ((container.offsetHeight / 2) * -1) + 20;
		setCardStyle({
			bottom: isMobile || isTablet ? bottom : ""
		});
	}, [isMobile, isTablet, window.innerWidth, window.innerHeight]);

	function mapOptionsCreator(map) {
		const options = {
			mapTypeControlOptions: {
				position: map.ControlPosition.RIGHT_TOP
			},
			zoomControlOptions: {
				position: map.ControlPosition.RIGHT_TOP,
			},
			streetViewControlOptions: {
				position: map.ControlPosition.RIGHT_TOP
			},
			fullscreenControl: fullscreenControl,
			zoomControl: zoomControl,
			streetViewControl: streetViewControl,
			clickableIcons: !isMobile && !isTablet,
		};
		return options;
	}
	const handleZoom = (zoom) => {
		setParams({ ...params, zoom })
	}

	const _onBoundsChange = (_center, zoom) => {
		handleZoom(zoom);
	}

	const onCloseCard = () => {
		setView(null);
	}

	const onClickFabs = (item) => {
		onCloseCard();
		let zoom = params.zoom + 3;
		setParams({
			...params,
			center: { lat: item.lat, lng: item.lng },
			zoom: zoom
		})
	}

	const onClickChip = (e, data) => {
		e.preventDefault();
		const { map, maps } = apiMaps;
		const nw = new maps.LatLng(data.geoPosition.latitude, data.geoPosition.longitude);
		setView(data.id);
		map.setCenter(nw);
		e.target.parentElement.style.zIndex = chipIndex.current;
		chipIndex.current++;
	}

	const renderGluster = () => {
		const glusters = mapUtils.getGluster(markersData, params.zoom, glusterParams.offset, glusterParams.radius);
		return glusters.map((gluster) => {
			const { lat, lng } = gluster;
			return (<div
				className="container-circles-fab-sun"
				key={gluster.clusterList}
				lat={lat}
				lng={lng}
				onClick={() => onClickFabs({ lat, lng })}>
				<div className="fab-sun">{gluster.size}</div>
			</div>)
		})
	}

	const renderCard = (data, index) => {
		return (<div className={`container-map-cards-bloque ${isMobile || isTablet ? 'isMobile' : ''}`} key={index} style={{ zIndex: chipIndex.current, ...cardStyle }}>
			<CardContainerServiceMap
				card={data}
				type={serviceType}
				currency={currency}
				txt={txt}
				onClickClose={() => onCloseCard()}
			/>
		</div>)
	}

	const renderChipAndGrid = () => {
		const newMarketData = [...markersData];
		const itemX = markersData.find(e => e.id === view);
		if (itemX !== undefined) {
			const itemNX = { ...itemX };
			itemNX.showCardinMap = true;
			newMarketData.push(itemNX);
		}
		return newMarketData.map((data, indexS) => {
			var chipText = data.price ? rateUtils.formatAmount(data.price, currency, locale) : data.name;
			if (isMobile || isTablet) {
				return data.showCardinMap ? renderCard(data, indexS) :
					<div className="container-map-prices" key={indexS} lat={data.geoPosition.latitude} lng={data.geoPosition.longitude}>
						<div className={view === data.id ? 'chip-arrow chip-arrow-selected' : 'chip-arrow'} onClick={(e) => onClickChip(e, data)}>
							{chipText}
						</div>
					</div>
			}
			return <div className="container-map-prices" key={indexS} lat={data.geoPosition.latitude} lng={data.geoPosition.longitude}>
				<div className={view === data.id ? 'chip-arrow chip-arrow-selected' : 'chip-arrow'} onClick={(e) => onClickChip(e, data)}>
					{chipText}
				</div>
				{data.showCardinMap && renderCard(data, indexS)}
			</div>
		})
	}

	const handleApiLoaded = (map, maps) => {
		setApiMaps({ map, maps });
		let service = null;
		let hasPlacesService = false;
		if (maps?.places?.PlacesService !== undefined) {
			hasPlacesService = true;
			service = new maps.places.PlacesService(map);
		}

		let _infoWindow = null;
		const types = pointInterest;

		function callback(results, status, type) {
			if (status === maps.places.PlacesServiceStatus.OK) {
				const data = limit ? results.slice(0, 1) : results;
				const bounds = new maps.LatLngBounds();
				const infoWindow = new maps.InfoWindow();
				data.forEach(place => {
					const location = {
						lat: place.geometry.location.lat(),
						lng: place.geometry.location.lng(),
						image: place.icon,
						title: place.name,
					};
					const item = new maps.LatLng(location.lat, location.lng);
					const marker = new maps.Marker({
						position: item,
						map: map,
						icon: {
							url: location.image,
							scaledSize: new maps.Size(20, 20),
						},
						title: location.title,
					});

					marker.addListener("click", () => {
						bounds.extend(marker.position);
						if (_infoWindow) _infoWindow.close();
						infoWindow.open(marker.getMap(), marker);
						infoWindow.setContent(marker.getTitle());
						_infoWindow = infoWindow;
						map.setCenter(marker.getPosition());
					});

					bounds.extend(item);
				});

				map.fitBounds(bounds);
				map.setCenter({
					lat: params.center.lat,
					lng: params.center.lng
				});
				map.setZoom(zoomDefault);
			}
		}

		types.forEach(function (type) {
			const request = {
				location: new maps.LatLng(params.center.lat, params.center.lng),
				radius: radiusPoint,
				type: type
			};
			hasPlacesService && service.nearbySearch(request, callback)
		});
	};

	function renderMap(){
		if(markersData.length === 0) return;
		if(params.zoom < zoomChangeChips) 
			return renderGluster();
		return renderChipAndGrid();
	}

	return (
		<div id="map-container" style={{ width: "100%", height: "100%", position: "relative", ...styleContent }}>
			<GoogleMap
				bootstrapURLKeys={{
					key: apiKey,
					libraries: ["places", "directions"],
					language: locale
				}}
				center={params.center}
				zoom={params.zoom}
				options={mapOptionsCreator}
				onBoundsChange={_onBoundsChange}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
				style={{ position: "relative", width: "100%", height: "100%" }}
				mapContainerClassName={"position-map"}
				onTilesLoaded={() => setLoading(false)}>
				{!loading && renderMap()}
			</GoogleMap>
		</div>
	);
}

Map.propTypes = {
	apiKey: PropTypes.string.isRequired,
	currency: PropTypes.string.isRequired,
	sx: PropTypes.object,
	coordInitial: PropTypes.object,
	serviceType: PropTypes.any,
	componentLink: PropTypes.any,
	markersData: PropTypes.array,
	glusterParams: PropTypes.object,
	zoomDefault: PropTypes.number,
	zoomChangeChips: PropTypes.number,
	fullscreenControl: PropTypes.bool,
	zoomControl: PropTypes.bool,
	streetViewControl: PropTypes.bool,
	onClickTools: PropTypes.func,
	hasFabTools: PropTypes.bool,
}

Map.defaultProps = {
	glusterParams: {
		offset: 268435456,
		radius: 85445659.4471
	},
	zoomDefault: 8,
	zoomChangeChips: 14,
	fullscreenControl: true,
	zoomControl: true,
	streetViewControl: true,
	hasFabTools: false
}

export default Map;