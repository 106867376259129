import commonUtils from "../utils/commonUtils";

const email = (value, paramsDescriptions) => {
    const param = {
        ok: true,
    }

    if(commonUtils.isEmpty(value))
    {
        param.ok = false;
        param.description = paramsDescriptions.empty;
    }
    else if(!commonUtils.isEmail(value))
    {
        param.ok = false;
        param.description = paramsDescriptions.text;
    }
    return param;
}

const isEmail = (value) => {
    const isOK = (commonUtils.isEmpty(value) || !commonUtils.isEmail(value))? false : true;

    return isOK;
}

const password = (value, paramsDescriptions) => {
    const param = {
        ok: true,
    }

    if(commonUtils.isEmpty(value))
    {
        param.ok = false;
        param.description = paramsDescriptions.empty;
    }

    return param;
}

const isPassword = (value) => {
    const isOK = commonUtils.isEmpty(value) ? false : true;

    return isOK;
}

const validateFormLogin = (user) => {
    let validate = true;

    if(!isEmail(user.email) || !isPassword(user.password))
        validate = false;

    return validate;
}

const formValidate = {
    email,
    password,
    validateFormLogin
}

export default formValidate;