import { AutoComplete as Auto } from "primereact/autocomplete";
import { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import iconUtils from "../../../../utils/iconUtils";
import "./components/autocompleteStyle.scss"
import iconContants from "../../../../constants/iconContants";
import serviceTypeConstant from "../../../../constants/serviceTypeConstant";

const Autocomplete = (props) => {
	const { value, icon, placeholder, disabled, inputId = "autocomplete", showClear = true } = props;
	const [auxValue, setAuxValue] = useState(value ?? '');
	const [suggestions, setSuggestions] = useState(null)
	const [displayClear, setDisplayClear] = useState('d-none')

	useEffect(() => {
		setAuxValue(value);
		setDisplayClear(value ? 'd-block' : 'd-none');
	}, [value]);

	const onChangeAutocomplete = (event) => {
		const { onChangeAutocomplete } = props;
		onChangeAutocomplete && onChangeAutocomplete(event.query,
			(response) => {
				setSuggestions(response.items);
			})
	};

	//TODO get item select to list
	const onChange = (e) => {
		const { onChangeSelect } = props;
		e.preventDefault();
		setAuxValue(e.value)
		onChangeSelect && onChangeSelect(e.value)
	}

	const handleEnter = (e) => {
		if (e.key === 'Enter')
			e.preventDefault();
	}

	const itemTemplate = (item) => {
		return (
			<div className="country-item d-flex">
				{item &&
					<div className="me-2">
						{item.type === serviceTypeConstant.DESTINATION && iconUtils.icon(iconContants.LOCATION)}
						{item.type === serviceTypeConstant.HOTEL && iconUtils.icon(iconContants.HOTEL)}
						{item.type === serviceTypeConstant.TOUR && iconUtils.icon(iconContants.ACTIVITY)}
					</div>
				}
				<div>{item.name}</div>
			</div>
		);
	}

	const clearValue = (e) => {
		setAuxValue('');
		setDisplayClear('d-none');
		onChangeAutocomplete(e);
		document.getElementById(inputId).focus();
	}

	return (
		<InputGroup className={`mzp-autocomplete ${icon && 'has-icon'}`}>
			{icon && (<InputGroup.Text>{iconUtils.icon(icon)}</InputGroup.Text>)}
			<Auto
				id={inputId}
				disabled={disabled}
				value={auxValue}
				itemTemplate={itemTemplate}
				suggestions={suggestions}
				completeMethod={onChangeAutocomplete}
				field="name"
				placeholder={placeholder}
				onChange={onChange}
				onKeyPress={handleEnter}
			/>
			{showClear && (<Button id='btn-clear' className={`btn-clear ${displayClear}`} variant="light" onClick={clearValue}>X</Button>)}
		</InputGroup>
	);
};

export default Autocomplete;
