import iconUtils from "@mzp/commons/dist/utils/iconUtils";
import iconContants from "@mzp/commons/dist/constants/iconContants";
import footerComponent from "./components/footerComponent";
import languageConstants from "../../../constants/languageConstants";
import pageLinksConstants from "../../../constants/pageLinksConstants";
import dateUtils from "../../../utils/dateUtils";
import headerComponent from "./components/headerComponent";
import images from "../../../theme/ImageSite";
import commonUtils from "../../../utils/commonUtils";
import meta from "../../../theme/metaSiteData";
import webSiteConstant from "../../../constants/webSitesiteRoutesConstant";
const metaData = commonUtils.filterMetaByRouteType(
  meta.metaData_es_mx,
  webSiteConstant.HOME
);

const imagenback = images?.imagenback;

const { startDate, endDate } = dateUtils.getRangeDays(15, 17);

let response = {
  routeType: webSiteConstant.HOME,
  siteLocale: languageConstants.MX,
  pathname: pageLinksConstants.HOME,
  hasHeaderLayout: false,
  hasFooterLayout: false,
  dinamicPage: true,
  keypage: "slug-page",
  itemsComponent: [
    {
      order:1,
      type: "header",
      showComponent: true,
      ...headerComponent,
    },
    {
      order:2,
      type: "search-booking",
      showComponent: true,
      searchBoxProps: {
        background: false,
        type: "Hotel",
        autocompleteProps: {
          active: true,
          label: "¿Dónde ir?",
          placeholder: "Buscar un lugar u hotel",
          single: true,
           icon: iconContants.LOCATION,
        },
        calendarProps: {
          active: true,
          label: "¿Cuándo?",
          icon: iconContants.BSFILLCALENDAR2CHECKFILL,
          placeholder: "-- --",
          rangeDate: true,
        },
        roomProps: {
          active: true,
          label: "Habitación",
          icon: iconContants.BSFILLPERSONFILL,
        },
        buttonProps: {
          text: iconUtils.icon(iconContants.SEARCH),
        },
      },
      dataButton: {
        text: "Buscar Hoteles en...",
        icon: iconUtils.icon(iconContants.SEARCH),
      },
      component: {
        title: "Tu siguiente viaje comienza aquí",
        image: {
          url: images.imagenback,
        },
      },
      minTextAPI: 3,
    },
    {order:3,
      type: "grid-gallery-section",
      showComponent: true,
      title: "Prepara tu próxima <span>aventura</span>",
      items: [
        {
          title: "Cancún",
          // text: "10+ Hotels",
          img: images.cancun, 
          url: `/hoteles?checkin=${startDate}&checkout=${endDate}&destination=Cancun&destinationkeyword=1536&paxes=2-0`,
          isBig: true,
         
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
        {
          title: "Punta Cana",
          // text: "10+ hoteles",
          img: images.PuntaCanaB,
          url: `/hoteles?checkin=${startDate}&checkout=${endDate}&destination=Punta+Cana&destinationkeyword=1708&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
        {
          title: "República Dominicana",
          //text: "10+ hoteles",
          img: images.DominicanB,
          isBig: true,
          url: `/hoteles?checkin=${startDate}&checkout=${endDate}&destination=República+Dominicana&destinationkeyword=1709&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
        {
          title: "Isla Mujeres",
          // text: "10+ hoteles",
          img: images.IslaMujeres,
          url: `/hoteles?checkin=${startDate}&checkout=${endDate}&destination=Isla+Mujeres&destinationkeyword=1546&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
        // ],
        // itemsB: [
        {
          title: "Riviera Maya",
          // text: "20+ Hotels",
          img:images.RivieraMayaB,
          url: `/hoteles?checkin=${startDate}&checkout=${endDate}&destination=Riviera+Maya&destinationkeyword=1561&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
        {
          
          title: "Jamaica",
          // text: "25+ Hotels",
          img: images.Jamaica,
          isBig: true,
          bottom: true,
          url: `/hoteles?checkin=${startDate}&checkout=${endDate}&destination=Jamaica&destinationkeyword=1711&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
        {
          title: "Los Cabos",
          // text: "25+ Hotels",
          img: images.LosCabos,
          url: `/hoteles?checkin=${startDate}&checkout=${endDate}&destination=Los+Cabos&destinationkeyword=1452&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
        {
          title: "Santa Lucía",
          // text: "25+ Hotels",
          img: images.SantaLuciaB,
          isBig: true,
          bottom: true,
          url: `/hoteles?checkin=${startDate}&checkout=${endDate}&destination=Santa+Lucía&destinationkeyword=1710&paxes=2-0`,
          dates: [{ start: "2023-05-15" }, { end: "2023-05-15" }],
        },
      ],
    },
    {
      order:4,
      type: "slide-top-things-todo",
      showComponent: true,
      typeCard: "hotel",
      title: "Hoteles <span> recomendados</span> para ti",
      items: [],
    },
    {
      order:5,
      type: "footer",
      showComponent: true,
      ...footerComponent,
    },
  ],
};

response = { ...response, ...metaData };

export default response;
