import { Skeleton } from "primereact/skeleton";
import "./components/style.scss";

const ViewPage = () => {
  return (
    <>
      <div className="w-full md:w-6 p-3 skeleton-header">
        <div className="nav-title nav-mobile">
          <Skeleton width="6rem" className="mb-2" />
          <Skeleton width="5rem" className="mb-2" />
          <Skeleton width="5rem" className="mb-2" />
        </div>
        <Skeleton height="2rem" width="13rem" className="mb-2 logo-skeleton" />
        <div className="nav-title">
          <Skeleton width="13rem" className="mb-2 nav-right"></Skeleton>
        </div>
      </div>

      <div className="skeleton-body">
        <Skeleton className="container-body-large"></Skeleton>
      </div>
      <div className="skeleton-body">
        <Skeleton className="container-body"></Skeleton>
      </div>
    </>
  );
};

const SkeletonPage = (props) => {
  return (
    <div className="container-loading">
      <div className="row-container">
        <div className="spinner-border loadings" role="status">
          <span className="visually-hidden"></span>
        </div>
      </div>
    </div>
  );
};

SkeletonPage.defaultProps = {
  text: false,
};
export default SkeletonPage;
