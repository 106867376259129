import React from "react";
import iconUtils from "../../../utils/iconUtils";
import constants from "../../../constants";
import { RiStarSFill } from 'react-icons/ri';


const StartGroup = (props) => {
	const { category, stars, } = props;
	const iconType = constants.iconType;
	let count = category;

	switch (stars) {
		case "One":
		case "OneAndAHalf":
		case "1":
			count = 1;
			break;
		case "Two":
		case "TwoAndAHalf":
		case "2":
			count = 2;
			break;
		case "Three":
		case "ThreeAndAHalf":
		case "3":
			count = 3;
			break;
		case "Four":
		case "FourAndAHalf":
		case "4":
			count = 4;
			break;
		case "Five":
		case "5":
			count = 5;
			break;
		default:
			count = stars;
			break;
	}

	let component = [];
	for (let i = 0; i < count; i++)
		component.push(<></>);
		
	return component.map((com, index) => {
		return (
			<div key={index} className="box-star">{com}
				<RiStarSFill />
			</div>
		);
	});
};

StartGroup.defaultProps = {
	iconLabel: "Estrellas"
}
export default StartGroup;
