import initialState from "./initialState";
import types from "../constants/reducers/flightConstants";
const flightReducer = (state = initialState.flight, action) => {
    switch(action.type) {
        case types.FLIGHT_FETCHING:
            return {...initialState.flight, ...action.flight}
        case types.FLIGHT:
            return action.flight;
        case types.FLIGHT_ERROR:
            return {...initialState.flight}
        default:
            return state;
    }
}

export default flightReducer;