import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useIntl } from "react-intl";

const CheckValidateRateSummary = (props) => {
    const type = "switch";
    const intl = useIntl();
    const [check, setCheck] = useState(true);

    const onChange = () => {
        const { onChange } = props;

        setCheck(!check);
        onChange && onChange(!check);
    }

    return (
        <Form>
            <div className="mb-3">
                <Form.Check
                    type={type}
                    id={`default-${type}-summary-rate`}
                    label={intl.formatMessage({ id: check ? "checkout.summary.labelCheckRate" : "checkout.summary.labelCheckRateFalse" })}
                    checked={check}
                    onChange={onChange}
                />
            </div>
        </Form>
    );
}

export default CheckValidateRateSummary;