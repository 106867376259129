// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "tourDetailPage.searchTitle" : "The best activities around the world.",
    "tourDetailPage.titleInfoGeneral" : "General information",
    "tourDetailPage.titleExperience" : "Your experience",
    "tourDetailPage.infoCancelation" : "Free Cancelation",
    "tourDetailPage.infoCovid" : "Precautions against Covid-19",
    "tourDetailPage.infoValidateDay" : "Validity 1 day",
    "tourDetailPage.infoLanguaje" : "Language",
    "tourVoucher.info" : "Information / Tour Itinerary",
    "tourVoucher.cancelation" : "Tour cancellation policies",    

}