import types from "../constants/reducers/currencyConstants";

const currency = (currency, dispatch, onSuccess, _onError) => {
    dispatch({type: types.SET_CURRENCY, currency});

    (onSuccess && typeof onSuccess === 'function') && onSuccess(currency);
}

const currencyAction = {
    currency
}

export default currencyAction;