import initialState from "./initialState";
import types from "../constants/reducers/hotelConstants";
const hotelReducer = (state = initialState.hotel, action) => {
    switch(action.type) {
        case types.HOTEL_FETCHING:
            return {...initialState.hotel, ...action.hotel}
        case types.HOTEL:
            return action.hotel;
        case types.HOTEL_ERROR:
            return action.hotel;
        default:
            return state;
    }
}

export default hotelReducer;