import iconContants from "../../../constants/iconContants";
import iconUtils from "../../../utils/iconUtils";
import CategoryTypeGroup from "../BoxIcon/CategoryTypeGroup";
import "./components/boxTitle.scss";

const BoxTitle = (props) => {
    const { item, sxBoxTitle, } = props;

    return (<div className={`box-title`} style={sxBoxTitle}>
        <div>
            <p className="title">{item.name}</p>
            <div className="box-title-content">
            <CategoryTypeGroup num={item?.categoryNumber ? item.categoryNumber: item.stars}  categoryCode={item.categoryCode} categoryString={item.categoryString} />              
            </div>
        </div>
        {(item.address) && (
            <>
                <hr className={'divider-horizontal'} />
                <div className="container-location">

                    <div className="container-item-location me-1">{iconUtils.icon(iconContants.LOCATION)}</div>
                    <div className="container-item-location">
                        <span className="tipography">
                            {item.address}{item.city && ", "+item.city}{item.country && ", "+item.country}
                        </span>
                    </div>
                </div>
            </>
        )}
    </div>
    );
}


export default BoxTitle;