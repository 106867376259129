import { Container, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useGlobalState } from "../../../store/StoreProvider";
import serviceVoucherAction from "../../../actions/serviceVoucherAction";
import storage from "../../../localstorage/storage";
import hotelsValidate from "../../../validate/hotelsValidate";
import checkoutUtils from "../../../utils/checkoutUtils";
import { checkoutStep } from "../../../constants/common";
import SkeletonVoucher from "../../../components/skeleton/SkeletonVoucher/SkeletonVoucher";
import useRedirectToHome from "../../../hooks/useRedirectHomeWithTimerAndDeteleLocalStorage";
import SimpleCardDescription from "./components/SimpleCardDescription";
import AmenitiesSeccion from "./components/AmenitiesSeccion";
import SpecialCommentsSection from "./components/SpecialCommentsSection";
import VoucherRoomsSection from "./components/VoucherRoomsSection";
import ServiceHotelSection from "../../../components/section/ServiceSection";
import serviceReponseToEcommersMapperUtils from "../../../utils/serviceReponseToEcommersMapperUtils";
import GoogleTagManagerUtils from "../../../utils/GoogleTagManagerUtils";
import VoucherHeader from "../components/VoucherHeader";
import CardPayment from "./components/CardPayment";
import voucher from "../components/data";
import STATUSCODE from "../../../constants/statusCode";
import BOOKSTATUS from "../../../constants/bookStatus";
import "./style.scss";

const VoucherPage = (props) => {
	const intl = useIntl();
	const MINUTES_REDIRECT_HOME = 15;
	const MINUTES_LOCAL_STORAGE = 10;
	const { locale, resourceSite } = useGlobalState();
	const {
		toggleListHotel: radios,
		searchHotelParams,
		siteRoutes: pathnames,
	} = resourceSite.options;

	const localizedUrlHome =  `${resourceSite.options.home.href}` ?? `/${locale}`;
	const UrlVoucherStatic = pathnames?.voucherStatic?.pathname ? `/${locale}${pathnames.voucherStatic.pathname}` : "";
	const paymentInfo = process.env.REACT_APP_VOUCHER_SHOW_PAYMENT_INFO === 'true';
	const { authSession } = useGlobalState();
	const [searchParams, setSearchParams] = useSearchParams();
	const query = { cart: searchParams.get("cart") };
	const title = intl.formatMessage({ id: "voucherPage.title" });
	const voucherStaticInformation = {SupplierNote: intl.formatMessage({ id: "voucherPage.SupplierNote" })}

	window.document.title = title;
	const [hotel, setHotel] = useState({ id: null });
	const [cart, setCart] = useState(null);
	const [params, setParams] = useState(hotelsValidate.paramsHotelFormat(query, searchHotelParams.paramsRoom.rules));
	const [expandedAmenities, setExpandedAmenities] = useState(true);
	const [expandedServices, setExpandedServices] = useState(true);
	const [itinerary, setItinerary] = useState({ isSucces: false });
	const [isErrorItineraty, setIsErrorItineraty] = useState(false);
	const handleExpandedAmenities = () => setExpandedAmenities((prevExpanded) => !prevExpanded);
	const handleExpandedServices = () => setExpandedServices((prevExpanded) => !prevExpanded);

	const getItinerary = (request, retryError) => {
		serviceVoucherAction.hotelItinerary(
			{ ...request, authSession },
			(response) => {
				setItinerary(response);
				pushGoogleTagManagerEvent(response);
			},
			(error) => {
				setIsErrorItineraty(true);
				if (retryError) {
					storage.setLocalStorage(
						`static-voucher-${query.cart}`,
						{
							statusCode: STATUSCODE.BOOKINGNOFOUND,
							clientReference: request.bookingId,
							bookStatus: BOOKSTATUS.CONFIRMED
						},
						MINUTES_LOCAL_STORAGE
					);

					const location = { pathname: UrlVoucherStatic };
					window.location.href = location.pathname
				}
				console.error(error);
			}
		);
	};

	const pushGoogleTagManagerEvent = (itinerary) => {
		try {
			const key = "pushGoogleTagManagerEvent" + itinerary.clientReference;
			if (!storage.getLocalStorage(key)) {
				const reservation = storage.getLocalStorage(query.cart);
				const ecommerce = serviceReponseToEcommersMapperUtils.purchaseEcommers(
					reservation.services,
					itinerary?.referenceBooking,
					itinerary?.selling,
					itinerary?.currency,
					reservation.booking
				);
				GoogleTagManagerUtils.purchaseEvent(ecommerce);
				storage.setLocalStorage(key, "purchaseEcommers", MINUTES_REDIRECT_HOME);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const onClickRedirect = () => {
		const location = {
			pathname: localizedUrlHome,
		};
		window.scrollTo(0, 0);
		window.location.href = location.pathname
	};

	useRedirectToHome(cart, MINUTES_REDIRECT_HOME, locale);


	// Api itinerary call
	useEffect(() => {
		const reservation = storage.getLocalStorage(query.cart);

		window.scrollTo(0, 0);
		if (reservation && reservation.type === checkoutStep.VOUCHER) {
			setCart(reservation);
			const nParams = { ...reservation.booking };
			setParams(nParams);
			const newHotel = checkoutUtils.getHotel(reservation.services);
			setHotel(newHotel);

			if (reservation.clientReference) {
				const request = {
					bookingId: reservation.clientReference.reference,
					email: reservation.clientReference.email,
					locale,
				};

				getItinerary(request, true);
				// retry itinery para envio voucher estatico
				if (isErrorItineraty) {
					getItinerary(request, false);
				}
			} else {
				const location = { pathname: localizedUrlHome };
				window.location.href = location.pathname
			}
		} else {
			const location = { pathname: localizedUrlHome };
			window.location.href = location.pathname
		}

	}, []);

	const simpleCard = voucherStaticInformation.SupplierNote.replace(
		"#SupplierName",
		itinerary?.providerData?.hotel?.supplier?.name
	).replace("#VatNumber", itinerary?.providerData?.hotel?.supplier?.vatNumber);

	return (
		<Container className="container-aux-voucher">
			{hotel && (
				<div className="voucher-container">
					<h1 className="title-service-general">{voucher.title}</h1>
					{itinerary.isSucces ? (
						<>
							<div className="voucher-card">
								<VoucherHeader hotel={hotel} itinerary={itinerary} />
								<div className="voucher-card-body">
									<VoucherRoomsSection
										itinerary={itinerary}
										hotel={hotel}
										locale={locale}
									/>
								</div>
							</div>
						
							<SpecialCommentsSection itinerary={itinerary} />
							{hotel.services && (
								<>
									<br />
									<div className="voucher-card-extra services">
										<ServiceHotelSection
											hotel={hotel}
											handleExpandedServices={handleExpandedServices}
											expandedServices={expandedServices}
										/>
									</div>
								</>
							)}
							
							<AmenitiesSeccion
								itinerary={itinerary}
								expandedAmenities={expandedAmenities}
								handleExpandedAmenities={handleExpandedAmenities}
							/>
							
							{itinerary.isSucces &&
								itinerary.providerData &&
								itinerary.providerData.hotel && (
									<SimpleCardDescription item={simpleCard} />
								)}
							
							{itinerary.isSucces && paymentInfo && (
								<CardPayment
									selling={itinerary.selling}
									currency={itinerary.currency}
									net={itinerary.net}
									payPublicRate={itinerary.payPublicRate}
								/>
							)}

							<br />
							<Button
								onClick={onClickRedirect}
								className="text-white d-block m-auto"
							>
								<span>{intl.formatMessage({ id: "button.backToList" })} </span>
							</Button>
						</>
					) : (
						<SkeletonVoucher />
					)}
				</div>
			)}
		</Container>
	);
};

export default VoucherPage;
