const LOCALE = {
    MUL: "mul",
    MX: "es-mx",
    EN: "en-us",
  }

  const Language = {
    MUL: "Mul",
    MX: "Spanish",
    EN: "English",
  }

  const currencyConst = {
    MUL: "Mul",
    MXN: "MXN",
    USD: "USD",
  }

  const localStorageConst = {
    CURRENCY : "_currency",
    LOCALE : "_locale",
    HOME: "_home",
    AUTH: "_auth",
    AUTHB2B: "_auth_bb",
    AUTHB2B2C: "_auth_bb_c",
    SITE:"_site",
    GTM:"_gtm",
    COMPONENTSOPTIONS:"_components_options"
  }

const checkoutStep = {
  CHECKOUT: "CHECKOUT",
  VOUCHER: "VOUCHER"
}

const viewGridListConst = {
  GRID: "block",
  ROW: "large",
  MAP: "map",
  FILTER: "filter",
}

const paymentType = {
  CREDITCARD: "credit_card",
  BANKTRANSFER: "bank_transfer"
}

export {
    //LOCALE,
    Language,
    currencyConst,
    localStorageConst,
    checkoutStep,
    viewGridListConst,
    paymentType
}