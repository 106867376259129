export default {
    "destino.cun": "Cancun" , 
    "destino.cun.des": "Considerado como la puerta de entrada al Mundo Maya.",  
    "destino.cun.num": "69 Tours y actividades",  
    "destino.cabos": "Los Cabos" ,
    "destino.cabos.des": "Descubre la hermosura del extremo sur de la península de Baja California." ,  
    "destino.aca": "Acapulco" ,    
    "destino.aca.des": "Reconocido mundialmente como sinónimo de relajación y diversión" ,  
    "destino.mz": "Mazatlán" ,  
    "destino.mz.des": '"La Perla del Pacífico", con inmejorables atardeceres y generosa gastronomía' ,  
    "destino.tr.cun": "Traslados en Cancun" ,
    "destino.tr.cabos": "Traslados en Los Cabos" ,
    "destino.tr.aca": "Traslados en Acapulco" ,  
    "destino.tr.mz": "Traslados en Mazatlán" ,  
    "banner.tr.title": "Promociones especiales en Chichen Itza",
    "banner.tr.sub1": "01-Dic-2022 - 08-En-2023",
    "banner.tr.sub2": "Descuentos de hasta el 50%",
    "banner.tr.sub3": "52% en Traslados",
    "banner.tr.sub4": "40% en Tours",
    "banner.tr.sub5": "Elección del viajeros en Tripadvisor",
    "banner.h.title": "Promociones especiales",
    "banner.h.sub1": "01-Dic-2022 - 08-En-2023",
    "banner.h.sub2": "Descuentos de hasta",
    "banner.h.sub3": "52% en Traslados",
    "banner.h.sub4": "40% en Tours",
    "banner.h.sub5": "Elección del viajeros en Tripadvisor",
    "banner.tour.title": "Promociones en Tours",
    "banner.tour.sub1": "Descuentos en Cancun",
    "banner.tour.sub2": "De enero a mayo",
    "banner.tour.sub3": "52% tour en Los cabos",
    "home.destination": "Las 10 mejores cosas que hacer en el Caribe",
    "home.similar": "Similar hotels",
}