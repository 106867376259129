import { Button, Alert, Spinner } from "react-bootstrap";

const AlertErrorWithButton = (props) => {
    const {hasButton, variant, buttonLabel} = props;

    const onClick = () => {
        const {onClickButton} = props;
        onClickButton && onClickButton();
    }

    return (
        <div className="alert-checkout-reservation-message">
        <Alert variant={variant} className="mt-3">
            <div className="alert-checkout-reservation-message-body">
             <span>{props.children}</span>
             {hasButton && <Button onClick={onClick}>{buttonLabel}</Button>}
            </div>
        </Alert>
        </div>
    );
}

export default AlertErrorWithButton;