import initialState from "./initialState";
import types from "../constants/reducers/paymentConstants";
const paymentReducer = (state = initialState.payment, action) => {
    switch(action.type) {
        case types.PAYMENT_FETCHING:
            return {...initialState.payment, ...action.payment}
        case types.PAYMENT:
            return action.payment;
        case types.PAYMENT_ERROR:
            return {...initialState.payment}
        default:
            return state;
    }
}

export default paymentReducer;