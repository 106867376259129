import serviceTypeConstant from "../../../../constants/serviceTypeConstant";
import { useGlobalState } from "../../../../store/StoreProvider";
import rateUtils from "../../../../utils/rateUtils";

const CardSummaryPrice = (props) => {
  const { txt, rate, service, type, payPublicRate } = props;
  const { locale, currency: currencyCode } = useGlobalState();
  const currency = currencyCode.code;
  const renderSubtotal = () => {
    let component = null;
    if (type === serviceTypeConstant.TRANSFER || (rate.totalTaxesIncluded > 0))
      component = (<div className='box-summary-div border-summary-item' style={{ borderBottom: "none" }}>
        {/* //TODO subTotal */}
        {type !== serviceTypeConstant.TRANSFER && (
          <>
            <div qui="p subtotal" className='box-summary-item'>
              {txt.subTotal}
            </div>
            {(rate.totalTaxesIncluded > 0) && (
              <div className='box-summary-item'>
                <p qui="p totalWithoutTaxes">{rateUtils.formatAmount(rate.totalWithoutTaxes, currency, locale)}</p>
              </div>
            )}
            {(!rate.taxes) && (
              <div className='box-summary-item'>
                <p qui="p total">{rateUtils.formatAmount(rate.total, currency, locale)}</p>
              </div>
            )}
          </>
        )}
        {type === serviceTypeConstant.TRANSFER && (
          <>
            <div className='box-summary-item'>
              <p>{`${service.shuttleType} - ${service.categoryType}`}</p>
            </div>
            <div className='box-summary-item'>
              <p>{rateUtils.formatAmount(service.rate.total - service?.rate?.taxes[0].total, currency, locale)}</p>
            </div>
          </>
        )}
      </div>);

    return component;
  }

  const renderTax = () => {
    let component = null;
    if (rate.totalTaxesIncluded && rate.totalTaxesIncluded > 0)
      component = (<>
        <div className='box-summary-item'>
          <p qui="p taxesText">{txt.taxesText}</p>
        </div>
        <div className='box-summary-item '>
          <p  qui="p totalTaxesIncluded">{rateUtils.formatAmount(rate.totalTaxesIncluded, currency, locale)}</p>
        </div>
      </>)

    return component;
  }
  return (<>

    <div className='box-summary-div border-summary-item' style={{ borderBottom: "none" }}>
      {type === serviceTypeConstant.PACKAGE && (
        <>
          <div className='box-summary-item'>
            <p>TUA</p>
          </div>
          <div className='box-summary-item '>
            <p qui="p tua">$1,200 MXN</p>
          </div>
        </>
      )}
    </div>
    {payPublicRate && renderSubtotal()}
    {type === serviceTypeConstant.TRANSFER && (
      <div className='box-summary-div border-summary-item'>
        <div className='box-summary-item'>
          <p>{txt.taxesText}</p>
        </div>
        <div className='box-summary-item '>
          <p>{rateUtils.formatAmount(service?.rate.taxes[0].total, currency, locale)}</p>
        </div>
      </div>
    )}
    {/* //TODO impuestos */}
    {type !== serviceTypeConstant.TRANSFER && (
      <div className='box-summary-div border-summary-item' >
        {payPublicRate && renderTax()}
      </div>
    )}
  </>
  )
}

export default CardSummaryPrice;
