import { useIntl } from "react-intl";
import { useEffect, useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import BoxDetailFlight from "../BoxDetailFlight/BoxDetailFlight";
import { useNavigate } from "react-router-dom";

import serviceType from "../../../constants/serviceTypeConstant";
import BoxIconText from "../BoxIconText/BoxIconText";
import iconUtils from "../../../utils/iconUtils";
import iconContants from "../../../constants/iconContants";
import rateUtils from "../../../utils/rateUtils";
import "./components/style.scss";

const BoxSelectedFlight = (props) => {
  const {
    scale,
    typePrice,
    typeCategory,
    title,
    showInfo,
    detail
    
  } = props;

  const intl = useIntl();

  const [tprice, setTprice] = useState(typePrice)
  const [tcat, setCat] = useState(typeCategory)

  const onClickChangeFlight = () => {
   
    const { OnClickRouteChange } = props;
    OnClickRouteChange && OnClickRouteChange();
  };

const [openModalDetail, setOpenModalDetail] = useState(false);
const [fullscreen, setFullscreen] = useState(true);

function handleOpenDetail(breakpoint) {
  setFullscreen(breakpoint);
  setOpenModalDetail(!openModalDetail);
}

const handleCloseModal = () => setOpenModalDetail(false);

useEffect(() => {
    setTprice(typePrice)
    setCat(typeCategory)
    
},[typePrice, typeCategory])


  const renderTooltip = (props) => (
    <Popover id="button-tooltip" {...props}>
        <span className="p-2 d-block toolTip-list">
            <div className="tooltip-text">
                <div className="icon-warning me-2">{iconUtils.icon(iconContants.BSEXCLAMATIONTRIANGLEFILL)}</div>
                <span>Las dimensiones permitidas son <strong>55 x 40 x 25 cm</strong>  y que su peso no exceda los <strong>10 kgs </strong> entre el objeto personal y el equipaje de mano light sin afectar la comodidad y seguridad de los pasajeros.</span>
            </div>         
        </span>
    </Popover>
);
  
  return (
  <div className="flight-selected">
    <div className="container-selected-flight">
        <div className="flight-info inner-container">
                <div className="circle-corner-top"></div>
                <div className={`farecolor ${tprice} ${tcat}`}></div>
                <div className="inner-content regular basic">
                    <h5><span>{iconUtils.icon(iconContants.AIFILLCHECKCIRCLE)}</span> {title}</h5>                                        
                    <Row>
                        <Col lg={12}><div className="flightroute"> {detail.arrival.name} a {detail.departure.name} </div></Col>
                        <Col lg={8}>
                            <div className="flighttime"> {detail.arrival.time} - {detail.departure.time} </div>
                            <div className="flightdate"> {detail.date} </div>
                        </Col>
                        <Col lg={4}>
                            <div className="flightlayover"> Sin escalas </div>
                            {showInfo && (<>
                                <div className="flightdetails"  onClick={() => handleOpenDetail('md-down')}>Detalles</div>
                                <BoxDetailFlight handleCloseModal={handleCloseModal} show={openModalDetail} fullscreen={fullscreen} scale={false}/>
                            </>                                
                            )}
                            
                        </Col>
                    </Row>
                   
                </div>
                <div className="circle-corner-bottom"></div>
        </div>
        <div className="flight-feature inner-container">
            <div className="circle-corner-top"></div>
                <div className={`inner-content ${tprice} ${tcat}`}>
                    <Row>
                        <Col md={7}>
                            {tprice == "regular" && (
                                <h5 className={`faretype ${tprice} ${tcat}`}>{tcat == 'basic' ? "Básica " : tcat == 'classic' ? "Clásica" : "Plus"}</h5>
                            )}
                            {tprice == "club" && (
                                <h5 className={`faretype ${tprice} ${tcat}`}>{tcat == 'basic' ? "Básica v.club" : tcat == 'classic' ? "Clásica v.club" : "Plus v.club"}</h5>
                            )}
                        </Col>
                        <Col md={5}>
                            {showInfo &&(
                                <div className="changeflight"  onClick={onClickChangeFlight}>Cambiar el vuelo</div>
                            )}
                        </Col>
                        <Col md={7} sm={9}>
                            <div className="list-amenities">
                                <ul className="amenities">
                                    <li className="personal-item">
                                        <div className="d-flex align-items-baseline">
                                            <span className="icon-item me-2">{iconUtils.icon(iconContants.BSFILLHANDBAGFILL)}</span>
                                            <span className="d-flex">
                                                <span className="textpar">
                                                    <span className="personalItemLabel"> 1 Objeto personal </span>
                                                    <span className="personalItemSubLabel">Debe caber debajo del asiento frente a ti  Por ejemplo: bolsa de mano, portafolio o porta laptop.</span>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-baseline">
                                            <span className="icon-item me-2">{iconUtils.icon(iconContants.BSFILLBAGFILL)}</span>
                                            <span className="d-flex">
                                                <span className="textpar">
                                                    <span className="personalItemLabel"> 
                                                        <span className=""> 1 equipaje de mano light</span>
                                                        <span className="newBaggage tooltip-bag">
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={renderTooltip}
                                                        >
                                                        <span className="tooltip-club"> {iconUtils.icon(iconContants.BSEXCLAMATIONCIRCLEFILL)}</span>
                                                        </OverlayTrigger> 
                                                        </span>
                                                    </span>
                                                    <span className="personalItemSubLabel">Dimensiones máximas 55 x 40 x 25 cm</span>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="personalItemSubLabel">
                                            El peso del artículo personal y equipaje de mano light no debe exceder  <strong>10 kg</strong> 
                                        </div>
                                    </li>
                                    <li className="d-flex justify-items-center no-baggage-item ">
                                        <div className="d-flex align-items-baseline disabled">
                                            <span className="icon-item me-2">{iconUtils.icon(iconContants.BSBAGDASFILL)}</span>
                                            <span className="d-flex">
                                                <span className="textpar">
                                                    <span className="personalItemSubLabel"> Equipaje documentado no incluido</span>
                                                </span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={5} sm={12}>
                            <div className="flightamenities">
                                <div className="priceflight">
                                    <span>precio por persona</span>
                                    <strong> $76 MXN</strong>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            <div className="circle-corner-bottom"></div>
        </div>
    </div>
  </div>
  );
};

BoxSelectedFlight.defaultProps = {
    title:"Salida",
    showInfo:true,
    detail:{       
            arrival: {
                time: "2:02 PM",
                codeIATA: "MEX",
                terminal: "T2",
                name:"Cd. México (AICM)"
            },
            departure : {
                time: "3:15 PM",
                codeIATA: "CUN",
                terminal:"",
                name:"Cancún"
            },
            date:"Martes, 27 Enero, 2023",
            flightDuration:"2h 53min",
            scale:true,
            flightSegment:1
          }    
}
export default BoxSelectedFlight;
