import commonUtils from "../../utils/commonUtils";
const name = (value, paramsDescriptions) => {
    const param = {
        ok: true,
    };

    if (commonUtils.isEmpty(value)) {
        param.ok = false;
        param.description = paramsDescriptions.empty;
    } else if (value.length < 2) {
        param.ok = false;
        param.description = paramsDescriptions.minText;
    } else if (!commonUtils.isText(value)) {
        param.ok = false;
        param.description = paramsDescriptions.text;
    }
    return param;
};
const lastName = (value, paramsDescriptions) => {
    const param = {
        ok: true,
    };

    if (commonUtils.isEmpty(value)) {
        param.ok = false;
        param.description = paramsDescriptions.empty;
    } else if (value.length < 2) {
        param.ok = false;
        param.description = paramsDescriptions.minText;
    } else if (!commonUtils.isText(value)) {
        param.ok = false;
        param.description = paramsDescriptions.text;
    }
    return param;
};
const email = (value, paramsDescriptions) => {
    const param = {
        ok: true,
    };

    if (commonUtils.isEmpty(value)) {
        param.ok = false;
        param.description = paramsDescriptions.empty;
    } else if (!commonUtils.isEmail(value)) {
        param.ok = false;
        param.description = paramsDescriptions.text;
    }
    return param;
};
const phone = (value, paramsDescriptions) => {
    const param = {
        ok: true,
    };

    if (commonUtils.isEmpty(value)) {
        param.ok = false;
        param.description = paramsDescriptions.empty;
    } else if (value.length > 15) {
        param.ok = false;
        param.description = paramsDescriptions.maxText;
    } else if (value.length < 7) {
        param.ok = false;
        param.description = paramsDescriptions.minText;
    } else if (!commonUtils.isNumeric(value)) {
        param.ok = false;
        param.description = paramsDescriptions.text;
    }

    return param;
};
const specialRequest = (value, paramsDescriptions) => {
    const param = {
        ok: true,
    };

    return param;
};

const secondName = (value, paramsDescriptions) => {
    const param = {
        ok: true,
    };

    if (!commonUtils.isEmpty(value) && !commonUtils.isText(value)) {
        param.ok = false;
        param.description = paramsDescriptions.empty;
    }

    return param;
};
const cashDiscount = (value, valueTypeDiscount, rate, paramsDescriptions) => {
    const param = {
        ok: true,
    };

    switch (valueTypeDiscount) {
        case "1":
            if (!commonUtils.isNumber(value)) {
                param.ok = false;
                param.description = paramsDescriptions.isAmount;
            } else if (value >= rate) {
                param.ok = false;
                param.description = paramsDescriptions.maxAmount;
            }
            break;
        case "2":
            if (!commonUtils.isNumber(value)) {
                param.ok = false;
                param.description = paramsDescriptions.isPercentage;
            } else if (value >= 100) {
                param.ok = false;
                param.description = paramsDescriptions.maxPercentage;
            }
            break;
        default:
    }

    return param;
};

const voucher = (value, paramsDescriptions) => {
    const param = {
        ok: true,
    };

    if (!commonUtils.isEmpty(value) && !commonUtils.isText(value)) {
        param.ok = false;
        param.description = paramsDescriptions.empty;
    }

    return param;
};

const validateForm = (formValidates, index) => {
    let validate = true;

    if (
        formValidates.name === undefined ||
        formValidates.name == null ||
        (formValidates.name && !formValidates.name.ok)
    )
        validate = false;

    if (formValidates.secondName && !formValidates.secondName.ok) validate = false;

    if (
        formValidates.lastName === undefined ||
        formValidates.lastName == null ||
        (formValidates.lastName && !formValidates.lastName.ok)
    )
        validate = false;

    if (formValidates.phone && !formValidates.phone.ok) validate = false;

    if (
        index === 0 &&
        (formValidates.email === undefined ||
            formValidates.email == null ||
            (formValidates.email && !formValidates.email.ok))
    )
        validate = false;

    return validate;
};

const formValidate = {
    name,
    secondName,
    lastName,
    email,
    phone,
    validateForm,
    cashDiscount,
    voucher,
    specialRequest,
};

export default formValidate;
