/* eslint-disable import/no-anonymous-default-export */
export default {
    "reservationList.table.title": "Búsqueda por...",
    "reservationList.table.name": "Nombre",
    "reservationList.table.reservationId": "Referencia reserva",
    "reservationList.table.nameService": "Nombre del hotel",
    "reservationList.table.nameSeller": "Nombre del vendedor",
    "reservationList.table.netRate": "Tarifa Neta agencia",
    "reservationList.table.publicRate": "Tarifa Publica",
    "reservationList.table.nameServiceRoom": "Nombre habitación",
    "reservationList.table.roomCount": "Habitaciones",
    "reservationList.table.adultsTotal": "Adultos",
    "reservationList.table.childrenTotal": "Niños",
    "reservationList.table.dateReservation": "Fecha reserva",
    "reservationList.table.dateLimitCancellation": "Límite cancelación",
    "reservationList.table.dateLimitPayment": "Límite Pago",
    "reservationList.table.statusReservation": "Estatus reserva",
    "reservationList.table.statusPayment": "Estatus pago",
    "reservationList.table.isRefundable": "Es Reembolsable",
    "reservationList.table.isPublicRate": "Tipo Tarifa",
    "reservationList.table.checkIn": "Check In",
    "reservationList.table.checkOut": "Check Out",
    "reservationList.table.td.isPublicRate": "Tarifa Publica",
    "reservationList.table.td.nonPublicRate": "Tarifa neta",
    "reservationList.table.placeholder.any": "Cualquiera",

    "reservationList.table.placeholderName": "Búsqueda por nombre",
    "reservationList.table.placeholder.reservationId": "Búsqueda por reserva",
    "reservationList.table.placeholder.nameService": "Búsqueda por hotel",
    "reservationList.table.placeholder.nameSeller": "Búsqueda por vendedor",
    "reservationList.table.placeholder.netRate": "Búsqueda por tarifa neta",
    "reservationList.table.placeholder.publicRate": "Búsqueda por tarifa publica",
    "reservationList.table.placeholder.nameServiceRoom": "Búsqueda por habitación",
    "reservationList.table.placeholder.roomCount": "Número de habitaciones",
    "reservationList.table.placeholder.adultsTotal": "Número de adultos",
    "reservationList.table.placeholder.childrenTotal": "Número de menores",
    "reservationList.table.placeholder.isRefundable": "Selecciona por",
    "reservationList.table.placeholder.isPublicRate": "Selecciona por",
    "reservationList.table.placeholder.select": "Select",

    "reservationList.table.emptyMessage": "No se encontraron clientes",
    "reservationList.table.currentPageReport": "Mostrando {first} a {last} de {totalRecords} entradas",
    "reservationList.table.filter.roomCount": "{count, plural, =0 {sin resultados} one {# Habitación} other {# Habitaciones}}",
    "reservationList.table.filter.adultCount": "{count, plural, =0 {sin resultados} one {# Adulto} other {# Adultos}}",
    "reservationList.table.filter.childrenCount": "{count, plural, =0 {sin resultados} one {# Menor} other {# Menores}}",
}