import types from "../constants/reducers/authSessionConstants";
import typesSite from "../constants/reducers/resourceSiteConstants";
import storage from "../localstorage/storage";
//TODO: get authSession
const get = (authSession) => {
  authSession.isFetching = false;
  authSession.isSuccess = true;
  authSession.isError = false;
  return { type: types.AUTH, authSession };
};
const fetching = () => {
  const authSession = {
    isFetching: true,
    isSuccess: false,
    isError: false,
  };
  return { type: types.AUTH_FETCHING, authSession };
};
const initial = () => {
  const authSession = {
    isFetching: false,
    isSuccess: false,
    isError: false,
  };
  return { type: types.AUTH_FETCHING, authSession };
};
const getError = () => {
  const authSession = {
    isFetching: false,
    isSuccess: false,
    isError: true,
  };
  return { type: types.AUTH_ERROR, authSession };
};

 

//TODO Get authSession with context and use Ditpatch
const auth = async (request, dispatch, onSuccess, onError) => {
  const responseStatus = [ 500, 502, 503, 504 ];
  dispatch(fetching());
  await fetch(`${process.env.REACT_APP_BFF_AUTH_API}api/Auth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  })
    .then((response) => {
    if (responseStatus.includes(response.status))   throw "500";

    if (!response.ok) throw Error(response.status);
      return response.json();
    })
    .then((data) => {
      dispatch(get(data));

      if (onSuccess && typeof onSuccess === "function") {
        onSuccess(data);
      }
    })
    .catch((ex) => {
      dispatch(getError());
      if (onError && typeof onError === "function") {
        onError(ex);
      }
    });
};
//TODO Get authSession with context and use Ditpatch
const authWithOutLoading = async (
  authConst,
  request,
  dispatch,
  onSuccess,
  onError
) => {
  await fetch(`${process.env.REACT_APP_BFF_AUTH_API}api/Auth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  })
    .then((response) => {
      if (!response.ok) throw Error(response.status);
      let result = response.json();
      storage.setLocalStorage(authConst, result, result.expires); //120min
      return result;
    })
    .then((data) => {
      dispatch(get(data));
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess(data);
      }
    })
    .catch((ex) => {
      dispatch(getError());
      if (onError && typeof onError === "function") {
        onError(ex);
      }
    });
};

const setAuth = async (request, dispatch) => {
  dispatch(get(request));
};
const cleanAuth = async (dispatch) => {
  dispatch(initial());
};

const AuthAction = {
  auth,
  setAuth,
  cleanAuth,
  authWithOutLoading,
};
export default AuthAction;
