import { useIntl } from "react-intl";
import rateUtils from "../../../../utils/rateUtils";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Overlay from "../../../Overlays/Overlay/Overlay";
import { useGlobalState } from "../../../../store/StoreProvider";

const CardRate = (props) => {
	const { service } = props;
	const { locale, currency } = useGlobalState();
	const intl = useIntl();
	const hasOffers = service.hasOffers && service.offerSale > 0 ? true : false;
	return (
		<>
			<small className="text-muted"><b>{intl.formatMessage({ id: "text.pernight" })}</b></small>
			{hasOffers && (
				<span className="old-fee text-color">
					{rateUtils.formatAmount(service.bestRate?.totalWithOffersPerNight, currency.code, locale)}
				</span>
			)}
			<div className="overlay-container">
				<Overlay
					idOverlay={`net-rate-${service.id}`}
					classNameOverlay={"tooltip-rates"}
					description={`${intl.formatMessage({
						id: "general.table.netRate",
					})}: ${rateUtils.formatAmount(
						service.bestRate?.totalNetAgencyPerNight,
						currency.code,
						locale
					)}`}>
					{rateUtils.formatAmount(
						service.bestRate?.totalPerNight,
						currency.code,
						locale
					)}
				</Overlay>
			</div>
			<div className="overlay-container">
				<Overlay
					idOverlay={`net-rate-${service.id}`}
					classNameOverlay={"tooltip-rates"}
					description={`${intl.formatMessage({
						id: "general.table.netRate",
					})}: ${rateUtils.formatAmount(
						service.bestRate?.totalNetAgency,
						currency.code,
						locale
					)}`}>
					<small id="info-total" className="card-sv-content-info-total">
						{intl.formatMessage({ id: "general.totalPrice" })}{" "}
						{rateUtils.formatAmount(service.bestRate?.total, currency.code, locale)}
					</small>
				</Overlay>
			</div>
			<small className="text-muted">{intl.formatMessage({ id: "text.tax" })}</small>
			<Link to={service.url} className="text-decoration-none">
				<Button variant="outline-primary" className="card-btn-reservation">
					{intl.formatMessage({ id: "button.viewDetails" })}
				</Button>
			</Link>
		</>
	);
};

export default CardRate;
