import GalleryGrid from "@mzp/commons/dist/dataDisplay/Gallery";
import { useIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import breakpointConstants from "../../../constants/breakpointConstants";
import "./components/style.scss";

const itemTemplate = (item) => {
	return <img key={item.name} loading="lazy" src={item.photoUris} alt={item?.alt ?? item.name} />;
};
const thumbnail = (item) => {
	return <img key={item.photoUris} loading="lazy" src={item.photoUris} alt={item?.alt ?? item.name} />;
};

const breakPointOptions = [
	{
		breakpoint: `${breakpointConstants.XL}px`,
		numVisible: 7,
		numScroll: 3,
	},
	{
		breakpoint: `${breakpointConstants.LG}px`,
		numVisible: 5,
		numScroll: 1,
	},
	{
		breakpoint: "850px",
		numVisible: 4,
		numScroll: 1,
	},
	{
		breakpoint: `${breakpointConstants.MD}px`,
		numVisible: 3,
		numScroll: 1,
	},
	{
		breakpoint: "320px",
		numVisible: 1,
		numScroll: 1,
	},
];

const WrapperCollage = (props) => {
	const { show, gallery, title } = props;
	const intl = useIntl();

	const text = {
		all: intl.formatMessage({ id: "general.all" }),
		grid: intl.formatMessage({ id: "general.grid" }),
		gallery: intl.formatMessage({ id: "general.gallery" }),
	};

	const handleClose = (status) => {
		const { handleCloseModal } = props;
		handleCloseModal && handleCloseModal(status);
	};
	return (
		<Modal
			className="wrapper-collage"
			show={show}
			fullscreen={true}
			onHide={handleClose}
			scrollable={false}
			contentClassName=""
		>
			<Modal.Header closeButton>
				<Modal.Title>{title && title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<GalleryGrid
					data={gallery}
					responsiveOptions={breakPointOptions}
					gridGap={0}
					gridColumns={3}
					numVisible={7}
					content={itemTemplate}
					thumbnailContent={thumbnail}
					groupBy={"type"}
					text={text}
				/>
			</Modal.Body>
		</Modal>
	)
}
export default WrapperCollage;