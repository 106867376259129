import reservationValidate from '../validate/reservationValidate';

const getReservations = async (request, onSuccess, onError) => {

  const { authSession, ...rest} = request; 
  await fetch(`${process.env.REACT_APP_BFF_API}${process.env.REACT_APP_BFF_BOOKINGS}`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authSession.token}`
      },
      body: JSON.stringify({...rest })
  })
  .then((response) => {
      
      if(response.ok){ 
        return response.json();
      }
  }) .then((data) => {
      if(onSuccess && typeof onSuccess === 'function'){
          onSuccess({
              data:  reservationValidate.formatReservation(data),
          });
      }
  })
  .catch(ex => {
      console.error(ex);
      if(onError && typeof onError === 'function'){
          onError(ex);
      }
  });
}


const reservationAction = {
    getReservations
}
export default reservationAction;
