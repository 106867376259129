import initialState from "./initialState";
import types from "../constants/reducers/siteInformationConstants";

const siteInformationReducer = (state = initialState.siteInformation, action) => {
    switch(action.type) {
        case types.SITE_FETCHING:
            return {...initialState.siteInformation, ...action.siteData}
        case types.SITE:
            return action.siteData;
        case types.SITE_ERROR:
            return action.siteData;
        default:
            return state;
    }
}

export default siteInformationReducer;