import { Button } from "react-bootstrap";
import Stack from 'react-bootstrap/Stack';
import selectPersonsValidate from "../../../validate/selectPersonsValidate";
import selectPersonsUtils from "../../../utils/selectPersonsUtils";
import objectUtils from "../../../utils/objectUtils";
import { useState } from "react";
import { useIntl } from "react-intl";
import BoxSelectPeopleSearch from "../BoxSelectPeopleSearch/BoxSelectPeopleSearch";
import iconUtils from "../../../utils/iconUtils";
import iconContants from "../../../constants/iconContants";

const GroupSelectPersons = (props) => {
    const { paramsRoom, options, rules} = props;
    const intl = useIntl();
    const {adultParams, childParams, infantParams} = paramsRoom;
    const [rooms, setRooms] = useState(objectUtils.isObjEmpty(options) ? [{
        adult: rules.adultDefaultRoom,
        child: rules.childMinforRoom,
        infant: rules.withoutInfant ? 0 : rules.infantMinforRoom,
        childYears: []
    }] : options);

    const messageValidate = (roomsObject) => {
        const {rulesDescriptions, onMessage} = props;
        const message = selectPersonsValidate.validate(roomsObject, rules, rulesDescriptions);

        if(onMessage)
            onMessage(message);

        return(message ?? null);
    }
    const addBoxRoom = () => {
        const nRoom = selectPersonsUtils.totalPeopleDefault(rooms, rules);
        return (rooms.length + 1) <= rules.roomsMax && nRoom.createRoom
    }
    const _onChangeRoomsLess = () => {
        const newRooms = (rooms.length - 1) >= 1 ? (rooms.filter((_, i) => i !== rooms.length - 1)) : rooms;
        messageValidate(newRooms);
        setRooms(newRooms);
    }

    const _onChangeRoomsMore = () => {
        const nRoom = selectPersonsUtils.totalPeopleDefault(rooms, rules);
        if((rooms.length + 1) <= rules.roomsMax && nRoom.createRoom){
            const newRooms = [...rooms, {
                adult: nRoom.adult,
                child: nRoom.child,
                infant: nRoom.infant,
                childYears: []
            }];
            messageValidate(newRooms);
            setRooms(newRooms)
        }
    }
    const _onChangeAdult = (result, index)=>{
        let items = [...rooms];
        const nItem = {...items[index], adult: result}
        items[index] = nItem;
        messageValidate(items);
        setRooms(items);
    }

    const _onChangeChildsAndYears = (response, index)=>{
        let items = [...rooms];
        const nItem = {...items[index], child: response.length, childYears: response}
        items[index] = nItem;
        messageValidate(items);
        setRooms(items);
    }

    const _onChangeInfant = (result, index)=>{
        let items = [...rooms];
        const nItem = {...items[index], infant: result}
        items[index] = nItem;
        messageValidate(items);
        setRooms(items);
    }

    const buttonSubmit = (_e) => {
        _e.preventDefault();
        const error = messageValidate(rooms);
        const {handleCloseModal} = props;  
        if(error == null){
            const {onSubmit} = props;
            if(onSubmit)
                onSubmit(rooms)
                handleCloseModal && handleCloseModal(false);
        }

        
        
    }

    return (<div className="box-search-select-options-rooms">
        <div className="pb-2 box-select-pax">
        {rooms && rooms.map((room, index) => {
            return <Stack gap={3} key={room + index}>
                <BoxSelectPeopleSearch id={`adult-${index}`} hasDroplist={false} value={room.adult} {...adultParams} onChange={(result) => {_onChangeAdult(result,index)}} minValue={rules.adultMinforRoom} maxValue={rules.adultMaxforRoom}
                 classs={"pb-2 pt-2"}
                 />
                <BoxSelectPeopleSearch id={`child-${index}`} value={room.child} {...childParams}
                    onChangeSelect={(response) => {_onChangeChildsAndYears(response,index)}}
                    minValue={rules.childMinforRoom} maxValue={rules.childMaxforRoom}
                    hasDroplist={true} itemsValue={room.childYears}
                    itemsSelect={rules.childYear}
                    classs={"pb-2"}
                />
               
                {rooms.length !== (index + 1) && <div className="border-bottom mb-3" />}
            </Stack>
        })}
        </div>
        {rooms.length > 1 && (
            <div className="text-sm-end pb-2 14 text-start">
                <Button size="md" variant="light" className="hover-text-bg btn-select-options-rooms-submit" onClick={_onChangeRoomsLess}>
                    {`${intl.formatMessage({ id: "website.searchBox.removeRoomHotel" })} `}
                </Button>
            </div>
        )}
       <div className={`d-block d--sm-flex ${addBoxRoom() ? "justify-content-between" : "justify-content-end" }`}>
        {addBoxRoom() && (
                <div className="text-start text-sm-end pb-2 14">
                    <Button size="md" variant="light" className="hover-text-bg btn-select-options-rooms-submit" onClick={_onChangeRoomsMore}>
                        {`${intl.formatMessage({ id: "website.searchBox.addRoomHotel" })} `}
                    </Button>
                </div>
            )}
             <div className="text-end mobile-button" onClick={buttonSubmit}>
                <Button size="md" variant="primary" className="text-white btn-select-options-rooms-submit">
                {intl.formatMessage({ id: "website.searchBox.submitHotel" })}
                </Button>
            </div>
       </div>
    </div> );
}
 
export default GroupSelectPersons;