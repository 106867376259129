import selectPersonsUtils from "../utils/selectPersonsUtils";

const validateChildren = (childYears, rulesDescriptions) => {
    let message = null;
    childYears.map(child => {
        if(child == null || child == undefined || child == '?')
            message = rulesDescriptions.yearChildren;
    })

    return message;
}

const validate = (roomsJson, rules, rulesDescriptions) => {
    if(selectPersonsUtils.totalPeople(roomsJson) > rules.personsTotal)
        return rulesDescriptions.personsTotal

    if(roomsJson && roomsJson.length > 0)
    {
        let errorText = null;
        roomsJson.map(room => {
            if(room.child > 0)
            {
                if((room.childYears == null || room.childYears == undefined) || room.child != room.childYears.length)
                    errorText = rulesDescriptions.emptyChildren;
                else {
                    const textE = validateChildren(room.childYears, rulesDescriptions)

                    errorText = textE ?? errorText;
                }
            }
        })

        if(errorText != null)
            return errorText;
    }

    return null;
}



const selectPersonsValidate = {
    validate,
}

export default selectPersonsValidate;