import BoxTitleCard from "../../../boxes/BoxTitleCard";
import iconContants from "../../../../constants/iconContants";
import iconUtils from "../../../../utils/iconUtils";
import serviceTypeConstant from "../../../../constants/serviceTypeConstant";
import Popover from "react-bootstrap/Popover";
import BoxIconText from "../../../../components/boxes/BoxIconText";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import commonUtils from '../../../../utils/commonUtils'

const CardContent = (props) => {
	const { item, type } = props;
	const exclusiveDeal = item.promotions && item.promotions.length > 0 ? item.promotions : [];

	const renderTooltip = (props) => (
		<Popover id="button-tooltip" {...props}>
			<span className="p-2 d-block toolTip-list">
				<BoxIconText
					items={exclusiveDeal.map((itm) => {
						return {
							icon: iconContants.FICHECK,
							classIcon: "color-primary",
							text: itm,
						};
					})}
				/>
			</span>
		</Popover>
	);

	return (
		<>
			<div id="card-content" className="card-content">
				<BoxTitleCard item={item} type={type} hasLink={item.bestRate} />
				<div className="container-flex">
					{item.mealPlan?.code && (<span>
						<i className="me-1">{iconUtils.icon(iconUtils.getIconMealPlanType(item.mealPlan?.code))}</i>
						<b>{`${type === serviceTypeConstant.HOTEL ? commonUtils.compoundNameUpperCase(item.mealPlan?.name) : item?.category}`}</b>
					</span>)}
					{item.bestRate?.isRefundable && item.bestRate?.hasCancellationPolicies && (
						<div className="hasCancellationPolicies">
							<small className="mb-2 text-muted">
								{item.bestRate.cancellationPolicies[0]}
							</small>
						</div>
					)}
					{item.hasPaymentLimit && (
						<div className="hasPaymentLimit">
							<small className="mb-2 text-muted">
								{item.paymentLimit}
							</small>
						</div>
					)}
					{!item.bestRate?.isRefundable && (
						<div className="isRefundable">
							<small className="mb-2 text-muted">
								{item.bestRate?.refundable}
							</small>
						</div>
					)}
					<div className="container-mealplan mt-1 mb-2">
						{exclusiveDeal.length > 0 && (
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={renderTooltip}>
								<span variant="success" className="icono icon-promotion">
									<span className="porcen">%</span>{" "}
									{iconUtils.icon(iconContants.FATAGS)}
								</span>
							</OverlayTrigger>
						)}
					</div>
				</div>
			</div>
			{item.shortDescription &&
				<div className="card-content-description text-color">
					{item.shortDescription}...
				</div>
			}
		</>
	);
};

export default CardContent;
