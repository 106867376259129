import languageConstants from "../../../constants/languageConstants";
import pageLinksConstants from "../../../constants/pageLinksConstants";
import footerComponent from "./components/footerComponent";
import headerComponent from "./components/headerComponent";
import commonUtils from "../../../utils/commonUtils";
import meta from "../../../theme/metaSiteData"
import webSiteConstant from "../../../constants/webSitesiteRoutesConstant"
const metaData = commonUtils.filterMetaByRouteType(meta.metaData_es_mx,webSiteConstant.RESERVATION);


let response = {
    routeType: webSiteConstant.RESERVATION,
    siteLocale: languageConstants.MX,
    pathname: pageLinksConstants.CHECKOUT,
    hasHeaderLayout: false,
    hasFooterLayout: false,
    hasDisabled: false,
    hideMenuMobile: true,
    hideMenu: true,
    itemsComponent: [
        {
            order:1,
            type: "header",
            showComponent:true,
            ...headerComponent
        },
        {
            order:2,
            type: "checkout-section",
            showComponent:true,
            components:{
                CheckBoxTerms:{
                    link:"He leído y acepto las condiciones de compra,<a target='_blank' href='https://www.nexustours.com/terms-and-conditions'> política de privacidad</a> y <a target='_blank' href='https://www.nexustours.com/terms-and-conditions'>política de cambios y cancelaciones.</a>"
                }
            }
        },
        {
            order:3,
            type: "footer",
            showComponent:true,
            ...footerComponent
        },
    ]
}

response = {...response,...metaData}

export default response;