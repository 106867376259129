import { useIntl } from "react-intl";
import { Container } from "react-bootstrap";
import BoxTitle from "../BoxTitle/BoxTitle";
import BoxServiceRate from "../BoxServiceRate";
import serviceType from "../../../constants/serviceTypeConstant";
import BoxIconText from "../BoxIconText/BoxIconText"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import iconUtils from '../../../utils/iconUtils'
import iconContants from "../../../constants/iconContants";

import rateUtils from "../../../utils/rateUtils";
import { useGlobalState } from "../../../store/StoreProvider";
import { useMediaQuery } from "react-responsive";
import breakpointConstants from "../../../constants/breakpointConstants";

const BoxServiceTitleRate = (props) => {
	const { 
		type, 
		sxServiceTitleRate,
		informationBoxServiceRate,
		informationBoxIconText,
		service,
	} = props;
	const intl = useIntl();
	const { locale } = useGlobalState();
	const isMobile = useMediaQuery({ maxWidth: breakpointConstants.SM });
	const onClick = () => {
		const { onClickButton } = props;
		onClickButton && onClickButton();
	}

	return (
		<>
			{type === serviceType.HOTEL && (
				<div className={`box-detail-service-title-rate ${sxServiceTitleRate ?? ''}`}>
					<>
						<div className="d-flex flex-column">
							<BoxTitle
								sxBoxTitle={{ justifyContent: "initial" }}
								item={service}
							/>
							{informationBoxIconText && informationBoxIconText.length > 0 && (
								<BoxIconText
									items={informationBoxIconText}
									alignItems={"left"}
								/>
							)}
						</div>
						<div className="d-flex flex-column">
							{informationBoxServiceRate && !isMobile && (
								<BoxServiceRate
									classs={informationBoxServiceRate.classs}
									textFrom={informationBoxServiceRate.textFrom}
									textButton={informationBoxServiceRate.textButton}
									rate={informationBoxServiceRate.rate}
									currency={informationBoxServiceRate.currency}
									informations={informationBoxServiceRate.informations}
									hasOffers={informationBoxServiceRate.hasOffers}
									rateDiscount={informationBoxServiceRate.rateDiscount}
									rateWithoutTax={informationBoxServiceRate.rateWithoutTax}
									total={informationBoxServiceRate.total}
									onClick={onClick}
								/>
							)}
						</div>
					</>
				</div>
			)}
			<div>
				{type === serviceType.TOUR && (
					<div className="service-detail-main-tour">
						<div className="d-flex flex-column w-100">
							<BoxTitle
								sxBoxTitle={{ justifyContent: "initial" }}
								item={service}
							/>
						</div>
						<Row className="pt-4">
							<Col className="" md={8}>
								<h5 className="mb-2 main-subtitle"> {intl.formatMessage({ id: "general.features" })}</h5>
								<Row id="service-detail-container" className="service-detail-container-text-icon-span">
									<Col md={6}>
										<div className="d-flex">
											<div className="container-icon me-2">{iconUtils.icon(iconContants.BIRESTAURANT)}</div>
											<span>{intl.formatMessage({ id: "tourProvisional.txt1" })}</span>
										</div>
										<div className="d-flex">
											<div className="container-icon me-2">{iconUtils.icon(iconContants.BSCLOCK)}</div>
											<span><b>{intl.formatMessage({ id: "general.duration" })}: </b>7 {intl.formatMessage({ id: "general.hours" })} </span>
										</div>
									</Col>
									<Col md={6}>
										<div className="d-flex">
											<div className="container-icon me-2">{iconUtils.icon(iconContants.GOARROWRIGHT)}</div>
											<span><b>{intl.formatMessage({ id: "general.startTime" })}</b> 10:00 a.m. </span>
										</div>
										<div className="d-flex">
											<div className="container-icon me-2">{iconUtils.icon(iconContants.GOARROWLEFT)}</div>
											<span><b>{intl.formatMessage({ id: "general.endTime" })}</b> 17:00 p.m. </span>
										</div>
									</Col>
								</Row>
							</Col>
							<Col className="text-start text-md-end " md={4}>
								<div className="box-rate-price">
									<div className="box-service-rate-tour bg-rate">
										<span className="mb-2 text-rate-tour">{intl.formatMessage({ id: "general.from" })}:</span>
										<div className="rate-tour"><span>{intl.formatMessage({ id: "general.for" })} 1 {intl.formatMessage({ id: "general.adult" })}</span>{rateUtils.formatAmount(1000, informationBoxServiceRate.currency, locale)}</div>
										<div className="rate-tour"><span>{intl.formatMessage({ id: "general.for" })} 1 {intl.formatMessage({ id: "general.child" })}</span>{rateUtils.formatAmount(600, informationBoxServiceRate.currency, locale)}</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				)}
			</div>
		</>

	);
}

export default BoxServiceTitleRate;