
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "website.logout": "Sign off",
    "website.header.lenguage": "Language",
    "website.header.currency": "Currency",
    "website.searchFilter.labelAutocomplete": "Search :",
    "website.searchBox.submitHotel": "Send",
    "website.searchBox.addRoomHotel": "Add room",
    "website.searchBox.removeRoomHotel": "Remove",
    "website.adultTotal": "{count, plural, one {# adult} other {# adults}}",
    "website.roomTotal": "{count, plural, one {# room} other {# rooms}}",
    "website.guestTotal": "{count, plural, one {# guest} other {# guests}}",
    "website.peopleTotal": "{count, plural, one {# person} other {# people}}",
    "website.passengerTotal": "{count, plural, one {# passenger} other {# passengers}}",
    "website.childrenTotal": "{count, plural, =0 {without children} one {# child} other {# children}}",
    "website.nightsTotal": "{count, plural, =0 {# nights} one {# night} other {# nights}}",
}