import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import iconContants from "../../../constants/iconContants";
import CarouselHolder from "../../carousel/CarouselHolder";
import Label from '../../label/Label/';
import "./cardContainerImage.scss";

const CardContainerImage = (props) => {
	const { name, showDiscount, discount, photos, position, className } = props;
	const intl = useIntl();

	return <div className={`card-container-image ${position} ${className}`}>
		{showDiscount && (
			<Label
				text={intl.formatMessage({ id: "general.discountText" }, { count: discount })}
				typeIcon={iconContants.CIDISCOUNT1}
			/>
		)}
		<CarouselHolder name={name} data={photos} className="card-content-img" />
	</div>
};

CardContainerImage.propTypes = {
	photos: PropTypes.array,
	showDiscount: PropTypes.bool,
	position: PropTypes.string,
};

CardContainerImage.defaultProps = {
	photos: [],
	showDiscount: true,
	discount: 0,
	position: 'left'
};

export default CardContainerImage;
