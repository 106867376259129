import SlidersComponent from "@mzp/commons/dist/layout/Sliders";
import CardOverlay from "@mzp/commons/dist/surfaces/CardOverlay";
import { useMediaQuery } from "react-responsive";
import FlipCardComponent from "@mzp/commons/dist/surfaces/FlipCard";
import breakpointConstants from "../../constants/breakpointConstants";

const SlideVacationLifeSection = (props) => {
  const { options } = props;
  const isMobile = useMediaQuery({ maxWidth: breakpointConstants.SM });
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    arrows: false,
    variableWidth: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          variableWidth: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          variableWidth: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <section className="vacation-life-section">
      <SlidersComponent settings={settings} {...options}>
        {isMobile ? <CardOverlay /> : <FlipCardComponent />}
      </SlidersComponent>
    </section>
  );
};

export default SlideVacationLifeSection;
