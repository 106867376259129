import { useState, useEffect } from "react";
import { useLocalStorage } from "./useLocalStorage";

export function UseFilterImages ({ data, take = 5 }) {
	const [imagesInvalid, setImagesInvalid] = useLocalStorage('imagesInvalid', []);
	const [loading, setLoading] = useState(true);
	const [images, setImages] = useState([]);
	useEffect(() => {
		if (!data || !data.length) return;
		const onImagesInvalid = (images) => {
			if(!images || !images.length) return;
			const invalids = images.filter(img => !imagesInvalid.includes(img.photoUris));
			if (invalids.length){
				const urlInvalids = invalids.map(img => img.photoUris);
				setImagesInvalid(image => [...image, ...urlInvalids]);
			}
		};

		const paginate = (array, page_size, page_number) => {
			return array.slice((page_number - 1) * page_size, page_number * page_size);
		}

		const loadImage = async (data) => {
			if (!data.photoUris.match(/https:/)) 
				data.photoUris = data.photoUris.replace('http:', 'https:');
			const img = new Image();
  		img.src = data.photoUris;
			return new Promise((resolve) => {
				img.onload = () => {
					data.success = true;
					resolve(data);
				};
				img.onerror = () => {
					data.success = false;
					resolve(data);
				};
			});
		}

		const init = () => {
			const imagesFiltered = data.filter(img => img.photoUris && !imagesInvalid.includes(img.photoUris));
			const pages = Math.abs(Math.ceil(imagesFiltered.length / take));
			const imagesValid = [];
			setLoading(true);
			(async function() {
				for (let i = 1; i <= pages && i <= 2; i++) {
					const imagesPage = paginate(imagesFiltered, take, i);
					const imagesPromises = imagesPage.map(v => loadImage(v));
					const imagesResponse = await Promise.all(imagesPromises);
					const images = imagesResponse.filter(img => img !== undefined && img !== null);
					const valids = images.filter(img => img.success);
					const invalids = images.filter(img => !img.success);
					onImagesInvalid(invalids);
					if (valids.length) valids.map(image => imagesValid.push(image));
					if (imagesValid.length >= take){
						setImages(imagesValid);
						setLoading(false);
						return;
					}
				}
				setImages(imagesValid);
				setLoading(false);
			})();
		}
		init();
	}, [data]);

	if(data && data.length <= take) return {images: data, loading:false};
	return {images, loading};
}
