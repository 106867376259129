const transferVoucherMock = {
  
    "alphanumericReference": "efda9444",
    "holderName": "Antonio",
    "holderLastName": "Gomez",
    "holderPhoneNumber": "9989379589",
    "holderEmail": "antonio.gomez@gmail.com",
    "shuttlelId": "b13cc0db",
    "shuttleName": "Traslado privado",
    "holderCountryOfResidence": "MEX",
    "arrivalDate": "2022-12-13T00:00:00",
    "arrivalHour":"20:00",
    "from":"Aeropuerto internacion de Cancun",
    "to":"Oasis Cancun",
    "airline":"Sunwing",
    "flightNumber":"586",
    "currency": "MXN",
    "image": {
                  
      "code": "https://mediaim.expedia.com/localexpert/780674/e88e74c6-5b15-4da1-b73f-08212da0ab7d.jpg?impolicy=resizecrop&rw=350&rh=197",          
      "title": "Traslado privado"
    },              
    "hasPromotions": false,
    "hasCancellationPolicies": false,
    "hasOffers": false,
    "total": 1000,
    "promotions": [],
    "cancellationPolicies": [],
    "taxes": [
        {
            "included": false,
            "total": 30.0022,
            "currency": "MXN"
        }
    ],
    "offers": [],
    "totalDiscounted": 0,
    "totalWithOutOffers": 0,
    "services":[
      "Al llegar al aeropuerto de Cancun uno de los representantes lo estará esperando a la salida de los pasajeros, portando un letrero con el logo y su nombre. Por favor presente este cupón de confirmación junto con una identificación oficial. En este momento Usted también recibirá la hora exacta en que será recogida a su salida, en caso de haber reservado traslado redondo.",
      "Por favor es muy importante que si viaja con bicicletas, tablas de surf o equipaje adicional nos lo haga saber al momento de hacer su reserva ya que aplican cargos adicionales."
  
    ],
    "dateCreatedUtc": "2022-12-02T04:50:39.9245941"
  
  }

export default transferVoucherMock;