import { useEffect, useState } from "react";
import businessModel from "../constants/businessModel";

const useRenderCheckRateBusinessSummary = () => {
    const [view, setView] = useState(false);
    useEffect(() => {
        try {
            setView(process.env.REACT_APP_BUSINESS === businessModel.B2C ? false : true);
        }
        catch (_) {
            setView(false);
        }
    }, [])
    return view;
}

export default useRenderCheckRateBusinessSummary;