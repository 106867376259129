import localeUtils from "../utils/localeUtils";
import autocompleteValidate from "../validate/autocompleteValidate";
import types from "../constants/reducers/searchHotelsConstants";
import storage from "../localstorage/storage";

const get = (searchHotels, fromCache = false) => {
	searchHotels.isFetching = false;
	searchHotels.fromCache = fromCache;
	return { type: types.SEARCH_HOTEL, searchHotels };
};

const fetching = () => {
	const searchHotels = {
		isFetching: true
	};
	return { type: types.SEARCH_HOTEL_FETCHING, searchHotels };
};

const error = () => {
	const searchHotels = {
		isFetching: false
	};
	return { type: types.SEARCH_HOTEL_ERROR, searchHotels };
};

let _cache = storage.getWithExpiry('autocomplete') ?? {};
const cache = (keyword) => {
	return keyword in _cache ? _cache[keyword] : null;
}

const setCache = (keyword, items) => {
	_cache[keyword] = items;
	storage.setLocalStorage('autocomplete', _cache, process.env.REACT_APP_SITE_AUTOCOMPLETE_CACHE_TIME);
}

// variables to cancel previous api call
let controller, signal;
//TODO: get hotels, destinations and airport
const search = (request, dispatch, onSuccess, onError) => {
	const { authSession, locale, keyword, useCache = true } = request;
	// cancel previous api call
	if (controller) controller.abort();
	if (useCache) {
		var items = cache(keyword);
		if (items && items.length) {
			const dataItems = get({ items }, true);
			onSuccess && typeof onSuccess === 'function' && onSuccess(dataItems.searchHotels);
			return;
		}
	};

	controller = new AbortController()
	signal = controller.signal;

	dispatch(fetching());
	const language = localeUtils.handleLanguage(locale);
	fetch(`${process.env.REACT_APP_BFF_API}${process.env.REACT_APP_BFF_AUTOCOMPLETE}`, {
		signal: signal,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${authSession.token}`
		},
		body: JSON.stringify({ keyword, language })
	})
		.then((response) => {
			if (response.ok) return response.json();
			throw new Error('Something went wrong.');
		})
		.then((data) => {
			const items = autocompleteValidate.formatList(data);
			const dataItems = get({ items });
			if (useCache) setCache(keyword, items);
			dispatch(dataItems);
			onSuccess && typeof onSuccess === 'function' && onSuccess(dataItems.searchHotels);
		})
		.catch(ex => {
			dispatch(error());
			onError && typeof onError === 'function' && onError(ex);
		});
}

export default {
	search,
};