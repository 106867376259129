import "./style.scss";

const InformationSection = (props) => {
  const { title, text, type, classs, btn } = props;


  const onClickGoToHome = () => {
    const { onClickGoToHome } = props;
    onClickGoToHome && onClickGoToHome();
  };

  return (
    <>
      <div qui="con notFound" className={`not-found-container-image ${classs}`}>
        <div className="not-found-container">
          <label className="not-found-404">{type}</label>
          <h2 qui="lbl notFound text" className="not-found-title">
            {title}
          </h2>
          <h3 className="not-found-text">{text}</h3>
          <button
            qui="btn notFound go home"
            onClick={onClickGoToHome}
            className="go-home-button"
          > 
           { btn }
          </button>
        </div>
      </div>
    </>
  );
};

export default InformationSection;
