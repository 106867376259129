import React from "react";
import { Form } from "react-bootstrap";

const InputLabel = (props) => {
    const { item, id, onChange, onBlur,qa, ...rest } = props;

    const handleChange = (e) => {
        onChange && onChange(e.target);
    };
    const handleBlur = (e) => {
        onBlur && onBlur(e.target);
    };

    return (
        <>
            <Form.Label htmlFor={id}>{item.label}</Form.Label>
            <Form.Control
                type={item.type}
                id={id}
                aria-describedby={`${id}-${item.aria}`.toLowerCase()}
                onChange={handleChange}
                onBlur={handleBlur}
                {...rest}
            />
            <Form.Text qui={qa} id={`${id}-${item.aria}`.toLowerCase()} muted>
                {item.validate}
            </Form.Text>
        </>
    );
};

export default InputLabel;
