import { useIntl } from "react-intl";

import iconUtils from "../../../../utils/iconUtils";
import iconContants from "../../../../constants/iconContants";
import ReadMore from "../../../../components/buttons/ReadMore/ReadMore";
import BoxIconText from "../../../../components/boxes/BoxIconText";

const AmenitiesSeccion = (props) => {

    const { itinerary, expandedAmenities, handleExpandedAmenities } = props;

    const intl = useIntl();

     let item = itinerary?.hotelBookings[0]?.rooms[0];
    return <>
      
        {item.amenities && item.amenities.length > 0 && (
        <>
        <br /> 
        <div className="voucher-card-extra services">
        <div className="rounded-5 py-4 bg-white container-incluid">
            <h2 className="mb-4 text-subtitle">
                {intl.formatMessage({
                    id: "voucherPage.titleAminities"
                })}
            </h2>
            <h5 className="mt-4">{item.amenities[0].name}</h5>
            <div
                className={`content-list-ameni ${item.amenities[0].amenities.length > 19 && expandedAmenities && "box-tittle-column-state"
                    }`}
            >
                <BoxIconText
                    items={item.amenities[0].amenities.map((item, i) => {
                        return {
                            icon: item.isIncluded ? iconContants.FICHECK : iconContants.AIOUTLINECLOSE,
                            classIcon: "color-primary",
                            text: item.name
                        };
                    })}
                />
            </div>
            {item.amenities[0].amenities.length > 19 && (
                <ReadMore
                    handleExpanded={handleExpandedAmenities}
                    text={
                        expandedAmenities
                            ? intl.formatMessage({
                                id: "general.seeMore"
                            })
                            : intl.formatMessage({
                                id: "general.seeLess"
                            })
                    }
                    icon={expandedAmenities ? iconUtils.icon(iconContants.IOIOSARROWUP) : iconUtils.icon(iconContants.IOIOSARROWDOWN)}
                />
            )}
        </div>
        </div>
        </>
        )}

    </>

}

export default AmenitiesSeccion;