import "./components/style.scss";
const LabelFloat = (props) => {
    const { text, icon , sx, top } = props;

    return (
    <div  className="label-overlay" 
    style={{ textAlign: top ? 'end' : 'inherit', position: top ? 'absolute': 'relative' }}>
        <label className="label-overlay-text" style={{ 
            borderBottomLeftRadius: 0,
            position: 'absolute',
            bottom: 0
            } }>
            { icon } {"  "} { text }
        </label>
    </div>
    )
}

LabelFloat.defaultProps = {
    top:false,
    sx: {},

}
export default LabelFloat;
