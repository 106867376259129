import "../components/style.scss";
import { useIntl } from "react-intl";

const TermsAndConditions = (props) => {
  const { options } = props;
  const intl = useIntl();
  return (
    <div id="body-render">
      <div className="page-general">
        <h2> {options?.title}</h2>
        <p>{options?.paragraph}</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
