import { useState } from "react";
import { useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import rateUtils from "../../../../utils/rateUtils";
import SkeletonTable from "../../../skeleton/SkeletonTable";
import hotelsValidate from "../../../../validate/hotelsValidate";
import serviceHotelAction from "../../../../actions/serviceHotelAction";
import Overlay from "../../../Overlays/Overlay/Overlay";
import iconUtils from "../../../../utils/iconUtils";
import iconContants from "../../../../constants/iconContants";
import storage from "../../../../localstorage/storage";
import checkoutUtils from "../../../../utils/checkoutUtils";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import commonUtils from "../../../../utils/commonUtils";
import serviceReponseToEcommersMapperUtils from "../../../../utils/serviceReponseToEcommersMapperUtils"
import GoogleTagManagerUtils from "../../../../utils/GoogleTagManagerUtils"

const CardTable = (props) => {
	const {
		currency,
		locale,
		sourceMarket,
		authSession,
		parameters,
		pathnames,
		refundable,
		hotelId,
	} = props;
	const intl = useIntl();
	const [show, setShow] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [rooms, setRooms] = useState([]);
	const [hotel, setHotel] = useState(null);
	const [scrollTable, setScrollTable] = useState(false);
	let [searchParams] = useSearchParams();
	let navigate = useNavigate();
	let yesterday = moment().add(-1, "days").format("DD/MM/YYYY");
	const localizedUrlBooking = `/${locale}${pathnames.reservation.pathname}`;
	const newParameters = {
		...parameters,
		inputAutoValueStart: {
			label: "--",
			keyword: hotelId,
			type: "Hotel",
		},
	};

	const getRooms = () => {
		const auxQuery = hotelsValidate.formatQueryDetailsFromParams({
			...newParameters,
			currency: currency,
		});
		const query = {
			...auxQuery,
			hotelId: hotelId,
		};

		setIsError(false);
		setIsLoading(true);
		//TODO get api without context
		serviceHotelAction.hotelSlow(
			{ ...query, locale, sourceMarket: sourceMarket, authSession },
			(response) => {
				setIsLoading(false);
				setHotel(response);
				setRooms(response.rooms);
			},
			(error) => {
				console.error(error);
				setIsLoading(false);
				setIsError(true);
			}
		);
	};

	const onClickHeader = () => {
		setShow(!show);
		if (!show) {
			getRooms();
		}
	};

	const onClickReserve = (room) => {
		const newHotel = { ...hotel };
		newHotel.rooms = [];
		newHotel.rooms.push(room);
		try {
			const ecommerce =
				serviceReponseToEcommersMapperUtils.hotelDetailRoomToEcommers(newHotel);
			GoogleTagManagerUtils.addToCartEvent(ecommerce);
		} catch (error) {
			console.log(error);
		}
		const auxParameters = { ...newParameters };
		auxParameters.inputAutoValueStart.label = hotel.name;

		const request = checkoutUtils.reservationHotel(
			newHotel,
			auxParameters,
			hotel?.gallery[0]
		);

		storage.setLocalStorage(request.uuid, request, process.env.REACT_APP_SHOPPING_SESSION_CART);
		const location = {
			pathname: localizedUrlBooking,
			search: `?${new URLSearchParams(
				{
					cart: request.uuid,
					sn: hotel.name,
					checkin: searchParams.get("checkin"),
					checkout: searchParams.get("checkout"),
					paxes: searchParams.get("paxes"),
					destination: searchParams.get("destination"),
					destinationkeyword: searchParams.get("destinationkeyword"),
					s: searchParams.get("s"),
				}
			).toString()}`,
		};

		window.location.href = location.pathname + location.search;
	};

	const handleScrollRoomsContainer = () => {
		setScrollTable(true);
	};
	const onCloseTooltipTable = () => {
		setScrollTable(false);
	};

	const renderTable = () => {
		let component = (
			<div
				className="card-large-table-container"
				id={"roomsTable" + hotelId}
				onScroll={handleScrollRoomsContainer}
			>
				<Table striped hover size="sm" responsive="lg">
					<thead id="table-columns-rates">
						<tr>
							<th>{intl.formatMessage({ id: "general.table.room" })}</th>
							<th>{intl.formatMessage({ id: "general.table.mealplan" })}</th>
							<th>{intl.formatMessage({ id: "general.table.pricePerNight" })}</th>
							<th>{intl.formatMessage({ id: "general.table.totalRate" })}</th>
							<th>{intl.formatMessage({ id: "general.table.paymentLimit" })}</th>
							<th>{intl.formatMessage({ id: "general.table.cancelationLimit" })}
							</th>
							<th></th>
						</tr>
					</thead>
					<tbody id="table-body-columns-rates">
						{rooms && rooms.map((room, i) => {
							return (
								<tr key={i}>
									<td>
										<div className="t-d-name">
											{`${room.name}`}
											{room.hasOffers && (
												<Overlay
													classNameOverlay={"tooltip-rates"}
													description={`${intl.formatMessage(
														{ id: "general.discountText" },
														{ count: room.offerSale }
													)}`}
													closeTooltip={scrollTable}
													onCloseTooltip={() => onCloseTooltipTable()}
												>
													<span>{iconUtils.icon(iconContants.DISCOUNT)}</span>
												</Overlay>
											)}
											{room.hasPromotions && (
												<Overlay
													key={`over-promotion${i}`}
													classNameOverlay={"tooltip-rates"}
													description={room.promotions.map((promo, ipro) => (
														<div key={ipro}>{promo}</div>
													))}
													closeTooltip={scrollTable}
													onCloseTooltip={() => onCloseTooltipTable()}
												>
													<span>{iconUtils.icon(iconContants.FATAGS)}</span>
												</Overlay>
											)}
										</div>
									</td>
									<td>
										<div className="container-mealplan">
											{`${commonUtils.compoundNameUpperCase(
												room.mealPlan?.name
											)}`}</div>
									</td>
									<td align="right">
										<Overlay
											classNameOverlay={"tooltip-rates"}
											description={
												<>
													<div>{`${intl.formatMessage({
														id: "general.table.netRate",
													})}: ${rateUtils.formatAmount(
														room?.totalNetAgencyPerNight,
														currency,
														locale
													)}`}</div>
													<div>{`${intl.formatMessage({
														id: "general.utility",
													})}: ${rateUtils.formatAmount(
														room?.utilityRatePerNight,
														currency,
														locale
													)}`}</div>
												</>
											}
											closeTooltip={scrollTable}
											onCloseTooltip={() => onCloseTooltipTable()}
										>
											{rateUtils.formatAmount(room?.rate, currency, locale)}
										</Overlay>
									</td>
									<td align="right">
										<Overlay
											classNameOverlay={"tooltip-rates"}
											description={
												<>
													<div>{`${intl.formatMessage({
														id: "general.table.netRate",
													})}: ${rateUtils.formatAmount(
														room?.totalNetAgency,
														currency,
														locale
													)}`}</div>
													<div>{`${intl.formatMessage({
														id: "general.utility",
													})}: ${rateUtils.formatAmount(
														room?.utilityRate,
														currency,
														locale
													)}`}</div>
												</>
											}
											closeTooltip={scrollTable}
											onCloseTooltip={() => onCloseTooltipTable()}
										>
											{rateUtils.formatAmount(room?.total, currency, locale)}
										</Overlay>
									</td>
									<td align="center">
										{room.paymentDeadlineFormat
											? moment.utc(room.paymentDeadline).format("DD/MM/YYYY")
											: yesterday}
									</td>
									<td align="center">
										{room.cancellationDeadlineFormat
											? moment
												.utc(room.cancellationDeadline)
												.format("DD/MM/YYYY")
											: yesterday}{" "}
									</td>
									<td align="center">
										<Button
											variant="outline-primary"
											className="card-btn-reservation"
											onClick={() => onClickReserve(room)}>
												{intl.formatMessage({ id: "general.reservation" })}
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>
		);

		return component;
	};

	const handleErrors = () => {
		var errorMessage = "";
		if (rooms.length <= 0) {
			errorMessage = intl.formatMessage({ id: "general.noAvailable" });
		} else if (isError) {
			errorMessage = intl.formatMessage({ id: "general.error" });
		}
		return errorMessage;
	};

	return (
		<Accordion>
			<Accordion.Item eventKey="0">
				<Accordion.Header onClick={onClickHeader}>
					{intl.formatMessage({
						id: !show
							? "general.moreAvailableRooms"
							: "general.lessAvailableRooms",
					})}
				</Accordion.Header>
				<Accordion.Body>
					{rooms && !isLoading && !isError && renderTable()}
					{isLoading && <SkeletonTable />}
					{!isLoading && handleErrors()}
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
};

CardTable.propTypes = {
	// isLoading: PropTypes.bool,
	// rooms: PropTypes.array,
};

CardTable.defaultProps = {};

export default CardTable;
