const imagesOLD = [
    {
      title: 'San Francisco – Oakland Bay Bridge, United States',
      img: "/static/imagenTest/photo-1537944434965-cf4679d1a598.webp",
    },
    {
      title: 'Bird',
      img: "/static/imagenTest/photo-1538032746644-0212e812a9e7.webp",
    },
    {
      title: 'Bali, Indonesia',
      img: "/static/imagenTest/photo-1537996194471-e657df975ab4.webp",
    },
    {
      title: 'Goč, Serbia',
      img: "/static/imagenTest/photo-1512341689857-198e7e2f3ca8.webp",
    },
  ];
const images = [
    {
      name: 'San Francisco – Oakland Bay Bridge, United States',
      photoUris: "/static/imagenTest/photo-1537944434965-cf4679d1a598.webp",
    },
    {
      name: 'Bird',
      photoUris: "/static/imagenTest/photo-1538032746644-0212e812a9e7.webp",
    },
    {
      name: 'Bali, Indonesia',
      photoUris: "/static/imagenTest/photo-1537996194471-e657df975ab4.webp",
    },
    {
      name: 'Goč, Serbia',
      photoUris: "/static/imagenTest/photo-1512341689857-198e7e2f3ca8.webp",
    },
    {
      name: 'Goč, Serbia',
      photoUris: "/static/imagenTest/photo-1512341689857-198e7e2f3ca8.webp",
    },
  ];

export {
    images,
    imagesOLD
};