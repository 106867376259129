import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import iconContants from "../../constants/iconContants";
import iconUtils from "../../utils/iconUtils";
import './components/style.scss';
const DropdownFilterOrden = (props) => {
	const { items, active, titleDefault, id, } = props;

	const onSelect = (e) => {
		const { onSelect } = props;
		onSelect && onSelect(e);
	}

	const Title = () => {
		return <>
			<i>
				{iconUtils.icon(iconContants.TBARROWDOWNUP)}
			</i>
			<span className="title"> {titleDefault}</span>
		</>
	}
	return (
		<>
			{items && (
				<DropdownButton
					id={id}
					title={<Title />}
					onSelect={onSelect}
					className='dropdown-list'>
					{items.map((item, i) => {
						return (
							<Dropdown.Item
								key={i}
								eventKey={item.value}
								active={item.value === active}
							>
								<span className={``}>{item.name}</span>
							</Dropdown.Item>
						);
					})}
				</DropdownButton>
			)}
		</>
	);
};
DropdownFilterOrden.defaultProps = {
	titleDefault: "Precio: Menor a mayor",
	items: []
};
export default DropdownFilterOrden;
