import { useState } from "react";
import { Button, Container, Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useMediaQuery } from 'react-responsive';
import iconUtils from "../../../utils/iconUtils";
import iconContants from "../../../constants/iconContants";
import serviceType from "../../../constants/serviceTypeConstant";
import bookingValidate from "../../../validate/bookingValidate";
import {Autocomplete, Calendar, SelectRooms} from "../../form/inputs";
import AutocompleteList from "../../form/inputs/AutocompleteList/AutocompleteList";
import { useGlobalState, useDispatch } from "../../../store/StoreProvider";
import searchHotelAction from "../../../actions/searchHotelAction";
import breakpointConstants from "../../../constants/breakpointConstants";
import Title from "../components/Title";
import Modals from '../../modals/Modals';
import SingleDay from "../../form/inputs/Calendar/SingleDay"
import "./components/style.scss";
import "./components/tour.scss";

const BookingEngineTour = (props) => {
    const { title, locale, params, paramsRooms, type,
            paramsAutocomplete, paramsCalendar, componentTitle, 
            isEditable, isDetailPage, isHome
          } = props;

    const intl = useIntl();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery( { maxWidth: breakpointConstants.SM })
    const isTablet = useMediaQuery( { minWidth: breakpointConstants.MD })
    const {searchHotels, authSession} = useGlobalState();
    const [paramsFront, setParams] = useState(type === serviceType.TRANSFER
        ? bookingValidate.paramsShutterFormat(params)
        : bookingValidate.paramsHotelFormat(params))
    const [ validate, setValidate ] = useState(false)
    
    const _onChangeSingleDate = (response ) =>  setParams(Object.assign({}, paramsFront, {date: response.date}))


    const _onClickRooms = () => {
      const {onClickRooms} = props;
      if(onClickRooms)
        onClickRooms();
    }

    const _onCloseRooms = () => {
      const {onCloseRooms} = props;
      if(onCloseRooms)
        onCloseRooms();
    }

    const _onSubmitRooms = (response) => {
      setParams(Object.assign({}, paramsFront, {rooms: response}))
    }

    const onChangeAutocomplete = (value, onSuccess) => {
      if (value.trim().length >= 3){
        searchHotelAction.search(
            { keyword: value, locale, authSession }, dispatch,
            (response) => {  onSuccess(response)  },
            (error) => { console.error(error);  }
        );
      }
    }

    const onChangeAutocompleteSelect = (response) => {
        const nAuto = typeof response === 'string' ?
        { label: response} :
        {
            label: response.name,
            keyword: response.code,
            type: response.type,
        };

        setParams(Object.assign({}, paramsFront, {inputAutoValueStart: nAuto}))
        if(validate){
            setValidate(false)
        }
    }
    const _onSubmit = () => {
        const {onSubmit} = props;
        
        if(isDetailPage)
          paramsFront.typeService = 'Detail'

        if(!paramsFront.inputAutoValueStart?.label){
            setValidate(true)
            return false;
        }
        /* validation by type of service */
        //TODO nessesary validate tours
        const dateValidate = type === serviceType.HOTEL
        ? bookingValidate.hotelValidate(paramsFront, paramsAutocomplete, paramsCalendar, paramsRooms) :
        {
            submit: true
        };

        if(!dateValidate.submit){
          alert(dateValidate.alert)
          return false;
        }

        if(onSubmit){
          onSubmit(paramsFront);
        }
    }

    const [fullscreen, setFullscreen] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [listSearch, setListSearch] = useState(null);

    const handleListSearch = (event) => {
      setListSearch(event);
    };

    function handleShowModal(breakpoint) {
        setFullscreen(breakpoint);
        setShowModal(isMobile);
        setParams(Object.assign({}, paramsFront, {search: listSearch}))
        
    }
    const handleCloseModal = () => setShowModal(false);


    return ( <Container>
      <div className ="booking-engine booking-engine-tour">
        { title && ( <Title title={title} componentTitle={componentTitle} /> ) }
        <Row className="booking-engine-row">
          <Col className={ isEditable ? "booking-engine-col" : "d-none"} >
              { isMobile ? ( <>
              <Button variant="Light" className="calendar-button-render-input w-100 btn btn-Light"
              onClick={() => handleShowModal(true)}> 
              {iconUtils.icon(iconContants.LOCATION)} 
              
              {paramsFront.inputAutoValueStart?.label ? "Parque Xcaret" :
              intl.formatMessage({ id: "general.destiny" })}
              </Button>               
              <Modals show={showModal} 
              fullscreen={fullscreen} 
              showClose={false} 
              handleCloseModal={handleCloseModal} > 
                  <AutocompleteList  
                  suggestions={searchHotels.isFetching ? [] : searchHotels.items}
                  onChangeAutocomplete={onChangeAutocomplete}
                  handleCloseModal={handleCloseModal}
                  onChangeSelect={onChangeAutocompleteSelect}
                  text={paramsFront.inputAutoValueStart?.label}
                  list={handleListSearch}
                  search={searchHotels.items}
                  placeholder={intl.formatMessage({ id: "general.placeholderAutocomplete" })}
                  noresultText={intl.formatMessage({ id: "general.noresultAutocomplete" })}/>
              </Modals >
              </>) 
              : (
              <Autocomplete
                  icon={iconContants.LOCATION}
                  placeholder={  intl.formatMessage({ id: "general.destiny"}) }
                  suggestions={searchHotels.isFetching ? [] : searchHotels.items}
                  onChangeAutocomplete={onChangeAutocomplete}
                  onChangeSelect={onChangeAutocompleteSelect}
                  value={paramsFront.inputAutoValueStart?.label}
              />)}
              
              {/* <div className="alert-text">
                { validate &&  intl.formatMessage({ id: "general.selectDestination" }) }
              </div>
      */}

          </Col>
          <Col className={ isHome ? "d-none" : "booking-engine-col"}>
          
          <SingleDay 
            date={paramsFront.date}
            onChage={_onChangeSingleDate}
            type={type}
            locale={locale}
            placeholder={"---"} 
            />
          </Col>
          <Col className={ isHome ? "d-none" : "booking-engine-col"}>
            <SelectRooms
            type={type}
            options={paramsFront.rooms}
            {...paramsRooms}
            onClick={_onClickRooms}
            onClose={_onCloseRooms}
            onSubmit={_onSubmitRooms}/>   
          </Col>
          
          <Col className="booking-engine-col btn-div" >
              <Button onClick={_onSubmit} className="text-white pb-4 btn-book-submit">
              { iconUtils.icon(iconContants.SEARCH) }
            <span> 
              { isDetailPage ? intl.formatMessage({ id: "button.availability" }) 
                              : intl.formatMessage({ id: "button.search" }) }
            </span>
              </Button>
          </Col>

        </Row>
      </div>
    </Container>
      );
}

BookingEngineTour.defaultProps = {
  isEditable: true,
  isHome: false,
  isDetailPage: false
}

export default BookingEngineTour;
