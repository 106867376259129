import { Col, Card } from "react-bootstrap";
import BoxTitleCard from "../../boxes/BoxTitleCard/BoxTitleCard";
import CardContent from "./components/CardContent";
import CardSummaryPrice from "./components/CardSummaryPrice";
import rateUtils from "../../../utils/rateUtils";
import iconContants from "../../../constants/iconContants";
import iconUtils from "../../../utils/iconUtils";
import serviceTypeConstant from "../../../constants/serviceTypeConstant";
import Badge from "react-bootstrap/Badge";
import "./components/style.scss";
import { useGlobalState } from "../../../store/StoreProvider";
import CheckValidateRateSummary from "./components/CheckValidateRateSummary";
import useRenderCheckRateBusinessSummary from "../../../hooks/useRenderCheckRateBusinessSummary";
import { useState } from "react";

//TODO card for ticket in checkout
function CardContainerSummary(props) {
	const { txt, service, searchBooking, checkRates, payPublicRate } = props;
	const { currency: currencySite, locale } = useGlobalState();
	const currency = currencySite.code;
	const [imgSuccess, setImgSuccess] = useState(true)
	const viewCheckRateBusiness = useRenderCheckRateBusinessSummary();
	const styleCap = {
		textTransform: "capitalize",
	};
	
	if(checkRates.items.length == null)
		return null;

	let checkRate = checkRates.items[0]
	let hotel = checkRate?.hotel?.details;
	let photo = null, itemBoxTitle = null, room = null, rate = null, cancellationPoliciesMessages = null;

	if(hotel){
		searchBooking.type = serviceTypeConstant.HOTEL;
		searchBooking.startDate = hotel.request.checkIn;
		searchBooking.endDate = hotel.request.checkOut;
		searchBooking.rooms = hotel.request.occupancies.map(pax => {
			return {
				adult: pax.adults,
				child: pax.children?? 0,
				childYear: pax.ages?? 0
			}
		});
		photo = hotel?.gallery[0]?.url;
		itemBoxTitle = {
			name: hotel.name,
			categoryNumber: hotel.categoryNumber,
			categoryCode: hotel.categoryCode,
			categoryString: hotel.category,
			destination: hotel.city
		};
		room = hotel.rooms[0];
		rate = room.ratesGrouped[0];
		cancellationPoliciesMessages = checkRate.cancellationPoliciesMessages[0];
	}

	const renderCancellationPolies = () => {
		if (cancellationPoliciesMessages) {
			return (
				<p qui="p room-cancellation-policies" className="container-flex item-row item-align-center">
					{cancellationPoliciesMessages}
				</p>);
		}
		return null;
	};

	const onChangePaymentService = (response) => {
		const { onChangePaymentService } = props;
		onChangePaymentService && onChangePaymentService(response);
	};

	return (
		<Col qui="container box-summary" className="card-containers">
			<Card className="box-summary">
				{photo && imgSuccess && (
					<img loading="lazy" qui="img img-sumary" className="img-sumary" src={photo} onError={({ currentTarget }) => {
						currentTarget.onerror = null;
						setImgSuccess(false)
					}} />
				)}
				{itemBoxTitle && <BoxTitleCard item={itemBoxTitle} type={searchBooking.type} hasLink={service.bestRate}/>}
				<CardContent
					item={service}
					searchBooking={searchBooking}
					txt={txt}
					locale={locale}
				/>
				{viewCheckRateBusiness && (
					<CheckValidateRateSummary onChange={onChangePaymentService} />
				)}

				{searchBooking.type === serviceTypeConstant.HOTEL && (
					<>
						<h5 qui="tit room-name-title">
							{searchBooking.rooms.length === 1 ? txt.roomTitle : txt.roomsTitle}
						</h5>
						{room && (<>
							<p qui="p room-name" style={styleCap}>{room.name}</p>
							<p qui="p room-mealplan" className="container-flex item-row item-align-center">
								{iconUtils.getIconMealPlan(rate.mealPlanCode)}
								<span className="ms-2">{rate.mealPlan}</span>
							</p>
						</>)}
						{renderCancellationPolies()}
					</>
				)}
				{searchBooking.type === serviceTypeConstant.TOUR && (
					<>
						<p
							style={styleCap}
						>{`${service.rooms[0]?.name}, descripción del tipo de Tour, comentarios en vivo en inglés Tour guiado de Tulum.`}</p>
						<Badge
							bg="secondary"
							className="mb-3"
							style={{ display: "inline-flex", alignItems: "centrer" }}
						>
							{iconUtils.icon(iconContants.BSCLOCK)}
							<span className="ms-2">12 h 30min</span>
						</Badge>
					</>
				)}

				{rate && <CardSummaryPrice
						txt={txt}
						type={searchBooking.type}
						rate={rate}
						payPublicRate={payPublicRate}
					/>
				}
				<div
					className="box-summary-div border-summary-item"
					style={{ borderBottom: "none" }}
				>
					<div className="box-summary-item">
						<p className="fw-bold mb-0">{txt.totalText}</p>
					</div>
					{searchBooking.type === serviceTypeConstant.TRANSFER && (
						<div className="box-summary-item">
							<p  qui="p total-service" className="fw-bold mb-0">
								{rateUtils.formatAmount(service.rate?.total, currency, locale)}
							</p>
						</div>
					)}
					{searchBooking.type !== serviceTypeConstant.TRANSFER && rate && (
						<div className="box-summary-item">
							<p qui="p total-service" className="fw-bold mb-0">
								{rateUtils.formatAmount(
									payPublicRate ? rate.total : rate.totalNetAgency, currency, locale
								)}
							</p>
						</div>
					)}
				</div>
			</Card>
		</Col>
	);
}
export default CardContainerSummary;
