import { useRef, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Popover, OverlayTrigger, Button} from 'react-bootstrap';
import iconContants from '../../../../constants/iconContants';
import iconUtils from '../../../../utils/iconUtils';
import selectPersonsUtils from '../../../../utils/selectPersonsUtils';
import GroupSelectPersons from '../../../ui/GroupSelectPersons/GroupSelectPersons';
import serviceType from '../../../../constants/serviceTypeConstant';
import Modals from '../../../modals/Modals';
import breakpointConstants from "../../../../constants/breakpointConstants";
import { useIntl } from "react-intl";

const SelectRooms = (props) => {
  const intl = useIntl();
  const {label, id, sx, hasBoxShadow, type, ...rest} = props;
  const target = useRef(null);
  const [defaultValue, setDefaultValue] = useState(props.options &&
        props.options.length > 0 ?
        selectPersonsUtils.formatTextSelectionRooms(props.options, props.rulesDescriptions,null, props.rules)
        : '');
  const [messages, setMessages] = useState(null);
  const [fullscreen, setFullscreen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const isMobile = useMediaQuery( { maxWidth: breakpointConstants.SM })




  const _onMessage = (response) => {
    const {onMessage} = props;
      setMessages(response);
    if(onMessage)
      onMessage(response);
  }
  
  const _submit = (response) => {
    const {onSubmit, rulesDescriptions} = props;
    setDefaultValue(selectPersonsUtils.formatTextSelectionRooms(response, rulesDescriptions, null, props.rules))
    setMessages(null);
    if(onSubmit)
      onSubmit(response)
  }

  const handleShowModal = (breakpoint) => {
    setFullscreen(breakpoint);
    setShowModal(isMobile); 
  }


  const handleCloseModal = () => {
    target.current.click();
    setShowModal(prev => !prev)
  }; 

  const closeBtn = () => {
    handleShowModal(true);
  }


  const popover = (
  <Popover id="popover-search-room" className='popover-search-room-button'>
    <Popover.Body>
      <GroupSelectPersons
      {...rest}
      onSubmit={_submit}
      onMessage={_onMessage}
      handleCloseModal={handleCloseModal}
      />
    </Popover.Body>
  </Popover>
  );


  return (<>
   
    <Modals 
    fullscreen={fullscreen} 
    show={ isMobile && showModal } 
    showClose={false} 
    handleCloseModal={handleCloseModal} >                    
    <div className='paxes-modal'>
      {popover}
    </div>
    </Modals>
    <OverlayTrigger 
    placement = "bottom" 
    overlay = { !isMobile ? popover : <></> } 
    target = {target.current} 
    trigger = "click" 
    rootClose>
      <Button id="input-paxs" 
      variant="Light" 
      className='search-room-button-render-input w-100' 
      onClick={closeBtn}
      ref={target}>
          {type === serviceType.HOTEL
          ? iconUtils.icon(iconContants.BED)
          : iconUtils.icon(iconContants.PERSON)}

          {(type !== serviceType.HOTEL &&
           type !== serviceType.TOUR &&
           type !== serviceType.TRANSFER) ?
           (<>
            <span className='pax-position-top'>{intl.formatMessage({ id: "general.persons" })}</span>
            <span className='mt-3'>{props.options &&
            props.options.length > 0 ?
            selectPersonsUtils.formatTextSelectionRooms(props.options, props.rulesDescriptions,null, props.rules)
            : ''}</span>
              </>) : (<span >{props.options &&
            props.options.length > 0 ?
            selectPersonsUtils.formatTextSelectionRooms(props.options, props.rulesDescriptions,null, props.rules)
            : ''}</span>)
          }
      </Button>
    </OverlayTrigger>
    </> 
  );
}

export default SelectRooms;
