import { isAfter, isBefore, isEqual } from "date-fns";
import dateUtils from "../utils/dateUtils";
import guestsUtils from "../utils/selectPersonsUtils";
import orderByFilter from "../mock/filters/orderByFilter";
import roomsDetailsMocks from "../mock/hotels/roomsDetailsMocks";
import imgDefault from "../assets/img/defaultImage.svg";
import htmlparse from 'html-react-parser'
import React from 'react';
import serviceCodeConstants from "../constants/serviceCodeConstants";
import moment from "moment/moment";

const convertStringToNumber = (value) => {
	let number = 0;
	try {
		number = parseInt(value);
	} catch (_ex) {
		number = 0;
	}
	return number;
};

const convertStringToNumberWithDefault = (value, _valueReturn) => {
	let number = _valueReturn;
	try {
		number = parseInt(value);
	} catch (_ex) {
		number = _valueReturn;
	}
	return number;
};

const convertStringToKeyname = (value) => {
	value = value.replace(/[^a-zA-Z0-9][\W]/gi, ' ');
	value = value.replace('  ', ' ').replace('   ', ' ');
	value = value.replace(/\s+/g, '-');
	value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	return value.replace('---','-').toLowerCase();
}

const formatList = (data, query) => {
	let paxes = query.occupancies.map((room) => {
		return `${room.adults}-${room.children}${room.infant > 0 ? `-${room.infant}` : ""
			}${room.children && room.ages ? `:${room.ages.join()}` : ""}`;
	}).join("!");

	const items = data.length ? data.map((d) => {
		let auxQuery =
		{
			checkin: query.checkIn,
			checkout: query.checkOut,
			paxes: paxes,
			sn: d.name, //services name
			destinationkeyword: d.zoneId ?? query.destinationkeyword,
			destination: d.zone ?? query.destination,
			s: serviceCodeConstants.HOTEL
		};

		let urlLink = `?${new URLSearchParams(auxQuery).toString()}`
		let keyName = `${convertStringToKeyname(d.name)}/`

		return {
			...d,
			id: d.id,
			name: d.name,
			keyword: d.id,
			categoryString: d.category,
			category: getCategoryStartGroup(d.stars),
			currency: d.bestRate?.currency,
			mealPlan: d.bestRate ? {
				name: d.bestRate.mealPlan,
				code: d.bestRate.mealPlanCode,
			} : null,
			zone: {
				name: d.zone,
				keyword: d.zoneId,
			},
			destination: {
				name: d.city ?? d.destination,
				keyword: "",
			},
			hasOffers: d.bestRate?.hasOffers ?? false,
			offerSale: d.bestRate?.hasOffers ? d.bestRate.totalDiscountRate : 0,
			promotions: d.bestRate?.promotions,
			hasPromotions: d.bestRate?.hasPromotions ?? false,
			pricewithoutOffer: d.bestRate?.hasOffers ? d.bestRate.totalWithOffersPerNight : null,
			price: d.bestRate?.totalPerNight,
			taxesIncluded: true,
			description: eliminarEtiquetasHtml(d.shortDescription),
			shortDescription:eliminarEtiquetasHtml(d.shortDescription),
			gallery: d.photos && d.photos.length ? d.photos.map((img) => {
				return {
					name: img.name,
					photoUris: img.url,
				};
			}) : [],
			url: `${query.pathNext}${keyName}${d.id}${urlLink}`,
		};
	}) : [];
	return items;
};

const getMockData = () => {
	var min = 0;
	var max = roomsDetailsMocks.newRooms.length - 1;
	var random = Math.floor(Math.random() * (max - min + 1) + min);
	var bed = roomsDetailsMocks.newRooms[random].amenityBasic.bed;
	return { bed };
};

const formatDetails = (d, query) => {
	let rooms = [];
	const totalPersons = totalOccupancies(query.occupancies);
	d.rooms.forEach((room) => {
		//TODO comentado llamado de mock para no traer informacion falsa
		var wifiItems =
			room.amenities && room.amenities.length
				? room.amenities.find((a) => a.name === "Internet" || a.name === "Wifi")
				: null;
		var hasWifi = (wifiItems && wifiItems.items && wifiItems.length > 0);

		var aaItems =
			room.amenities && room.amenities.length
				? room.amenities.find(
					(a) => a.name === "AA" || a.name === "AireAcondicionado"
				)
				: null;
		var hasAA =
			(aaItems && aaItems.items && aaItems.length > 0);

		var phoneItems =
			room.amenities && room.amenities.length
				? room.amenities.find(
					(a) => a.name === "Telefono" || a.name === "Phone"
				)
				: null;
		var hasPhone =
			(phoneItems && phoneItems.items && phoneItems.length > 0);

		rooms.amenities = room.amenities;
		room.ratesGrouped.forEach((rate) => {
			var _room = {
				id: rate.id,
				externalId: room.externalId,
				name: room.name,
				paymentDeadline: rate.paymentDeadline,
				paymentDeadlineFormat: rate.paymentDeadlineFormat,
				hasCancellationPolicies: rate.hasCancellationPolicies,
				cancellationDeadline: rate.cancellationDeadline,
				cancellationDeadlineFormat: rate.cancellationDeadlineFormat,
				cancellationPolicies: rate.cancellationPolicies && rate.cancellationPolicies.length > 0 ? rate.cancellationPolicies : [rate.refundable],
				isRefundable: rate.cancellationPolicies?.length > 0 ? false : true,
				refundable: rate.refundable,
				photos: room.photos ? room.photos.map((photo) => {
					return {
						name: photo.name,
						photoUris: photo.url,
					};
				}) : [
					{
						name: d.name,
						photoUris: imgDefault,
					},
				],
				amenityBasic: {
					//bed: bed,
					hasWifiFree: hasWifi,
					hasAA: hasAA,
					hasPhone: hasPhone,
				},
				amenities: room.amenities,
				mealPlan: {
					code: rate.mealPlanCode,
					name: rate.mealPlan,
				},
				//TODO Cambiar aqui
				upgradeRate: rate.totalDifferenceOverLowPrice > 0 ? rate.totalDifferenceOverLowPrice / totalPersons : 0,
				rate: rate.totalPerNight,
				total: rate.total,
				totalDifferenceOverLowPrice: rate.totalDifferenceOverLowPrice,
				totalDiscountPerNight: rate.totalDiscountPerNight,
				totalDiscountRate: rate.totalDiscountRate,
				totalDiscounted: rate.totalDiscounted,
				totalFeesIncluded: rate.totalFeesIncluded,
				totalFeesNotIncluded: rate.totalFeesNotIncluded,
				totalPerNight: rate.totalPerNight,
				totalPerPerson: rate.totalPerPerson,
				totalTaxesIncluded: rate.totalTaxesIncluded,
				totalTaxesNotIncluded: rate.totalTaxesNotIncluded,
				totalWithOffers: rate.totalWithOffers,
				totalWithOffersPerNight: rate.totalWithOffersPerNight,
				totalWithoutTaxes: rate.totalWithoutTaxes,
				totalPerNightWithoutTaxe: rate.totalPerNightWithoutTaxe,
				taxesOptions: rate.taxes,
				taxes: rate.grouped[0]?.taxes[0]?.total,
				rateKeyLead: rate.grouped[0].rateKey,
				currency: query.currency,
				view: "",
				utilityRatePerNight: rate.totalPerNight - rate.totalNetAgencyPerNight,
				utilityRatePerPerson: rate.totalPerPerson - rate.totalNetAgencyPerPerson,
				utilityRate: rate.total - rate.totalNetAgency,
				totalNetAgency: rate.totalNetAgency,
				totalNetAgencyPerNight: rate.totalNetAgencyPerNight,
				totalNetAgencyPerPerson: rate.totalNetAgencyPerPerson,
				promotions: rate.promotions,
				hasOffers: rate.hasOffers,
				hasPromotions: rate.hasPromotions,
				offerSale: rate.hasOffers ? rate.totalDiscountRate : 0,
				pricewithoutOffer: rate.hasOffers ? rate.totalWithOffersPerNight : null,
			};
			rooms.push(_room);
		});
	});
	// para el nodo aboutOfHotel se requiere hacer un parseo a html ya que los proveedores mandan html como cadena de texto
	// se requeire hacer un doble parceo si existen entidades/simbolos de html y no etiquetas html
	return d ? {
		...d,
		address: d.address,
		city: d.city,
		categoryString: d.category,
		country: d.country,
		aboutOfHotelHtml: contieneEntidadesHTML(d?.description),
		aboutOfHotel: d?.description,
		description: d?.description,
		id: d.externalId,
		destinationId: d.zoneId,
		gallery: d.gallery && d.gallery.length ? d.gallery.map((img) => {
			return {
				name: img.name,
				photoUris: img.url,
				type: img.type,
			};
		}) : [],
		geoPosition: {
			latitude: d?.geoPosition?.latitude,
			longitude: d?.geoPosition?.longitude,
		},
		name: d.name,
		notices: [],
		postalCode: d.postalCode,
		rooms: rooms,
		bestRate: {
			...d.bestRate,
			id: rooms[0].id,
			currency: d.bestRate.currency || query.currency,
			hasPromotions: d.bestRate.hasPromotions,
			rateWithoutPromotional: d.bestRate.totalWithOffersPerNight,
			rate: d.bestRate.totalPerNight,
			mealPlan: d.bestRate.mealPlan,
			hasCancellationPolicies: d.bestRate.cancellationPolicies?.length > 0 ? true : false,
			cancellationPolicies: d.bestRate.cancellationPolicies && d.bestRate.cancellationPolicies.length > 0 ? d.bestRate.cancellationPolicies : [d.bestRate.refundable],
			isRefundable: d.bestRate?.cancellationPolicies?.length <= 0 ? true : false,
			refundable: d.bestRate.refundable,
		},
		stars: d.stars,
		zone: d.zone,
		services: d.services,
		//datos para cargar en las politicas
		checkin: "",
		checkout: "",
		extendedCheckout: "",
		policies: {
			cancellationPolicies: "",
			minYears: 25,
			petfriendly: "",
			additional: [],
		},
	} : {};
};

const eliminarEtiquetasHtml = (texto) => {
    return texto.replace(/<[^>]*>/g, '');
}

const contieneEntidadesHTML = (cadena) => {

	let htmlEntity = {
		containHtmlCode: false,
		containHtmlTag: false
	};

	if (cadena) {
		// Lista de entidades HTML comunes
		const entidadesHTML = [
			'amp',
			'lt',
			'gt',
			'quot',
			'nbsp',
			'ndash',
			'mdash',
			'copy',
			'reg',
			'trade',
			'asymp',
			'ne',
			'pound',
			'euro',
			'deg',
			// Agrega más entidades HTML aquí según tus necesidadess
		];

		// Construye una expresión regular a partir de la lista
		const expresionRegular = new RegExp(`&(${entidadesHTML.join('|')});`, 'g');
		let htmldata = expresionRegular.test(cadena);


			let html = htmlparse(cadena);

			if (Array.isArray(html)) {

				htmlEntity = {
					containHtmlCode: false,
					containHtmlTag: contieneReactElement(html)
				};
 
				return htmlEntity;
			} else {

				if(htmldata){

					htmlEntity = {
						containHtmlCode: true,
						containHtmlTag: false
					};

				}else{
					htmlEntity = {
						containHtmlCode: false,
						containHtmlTag: false
					};

				}
				
				return htmlEntity;
			}
		
	}

	return htmlEntity;

}

  function contieneReactElement(array) {
	for (let i = 0; i < array.length; i++) {
	  if (React.isValidElement(array[i])) {
		return true; // Encontramos un elemento React.
	  }
	}
	return false; // No se encontraron elementos React.
  }

const formatQueryDestinationFromParams = (params) => {
	if (params === null || params.inputAutoValueStart === null) return null;
	const { type, keyword } = params.inputAutoValueStart;
	return {
		occupancies: params.rooms.map((room) => {
			return {
				adults: room.adult,
				children: room.child,
				infant: room.infant,
				ages: room.child > 0 ? room.childYears : null,
			};
		}),
		checkIn: params.startDate,
		checkOut: params.endDate,
		currency: params.currency,
		id: keyword,
		type: type.toLowerCase() === "hotel" ? "point" : "zone"
	};
};

const formatQueryDetailsFromParams = (params) => {
	return {
		occupancies: params.rooms.map((room) => {
			return {
				adults: room.adult,
				children: room.child,
				infant: room.infant,
				ages: room.child > 0 ? room.childYears : null,
			};
		}),
		checkIn: params.startDate,
		checkOut: params.endDate,
		currency: params.currency,
		hotelId: params.inputAutoValueStart.keyword,
	};
};

const paxesQuery = (rooms) => {
	let paxes = rooms.map((room) => {
		return `${room.adult}-${room.child}${room.infant > 0 ? `-${room.infant}` : ""
			}${room.child > 0 ? `:${room.childYears.join()}` : ""}`;
	});
	return paxes.join("!");
};

const totalOccupancies = (rooms) => {
	const totals = rooms.map((room) => {
		return (
			room.adults +
			(room.children ? room.children : 0) +
			(room.infant ? room.infant : 0)
		);
	});

	return totals.reduce((accumulator, curr) => accumulator + curr);
};

const paramsListforQuery = (params) => {
	const queries = {};
	if (params?.inputAutoValueStart?.type === "Airport") {
		queries.airport = params.inputAutoValueStart.label;
		queries.airportkeyword = params.inputAutoValueStart.keyword;
	}
	else if (params?.inputAutoValueStart?.type === "Destination") {
		queries.destination = params.inputAutoValueStart.label;
		queries.destinationkeyword = params.inputAutoValueStart.keyword;
	}
	else if (params?.inputAutoValueStart?.type === "Hotel" || params?.inputAutoValueStart?.type === "HotelDetails") {
		queries.hotel = params.inputAutoValueStart.label;
		queries.hotelkeyword = params.inputAutoValueStart.keyword;
		queries.sn = params.inputAutoValueStart.label;
	}
	queries.paxes = paxesQuery(params.rooms);
	queries.checkin = params.startDate;
	queries.checkout = params.endDate;
	return queries;
};

const paramsFormat = (query, rules) => {
	//TODO casado con listformat
	const params = {
		checkIn: "2022-08-10",
		checkOut: "2022-08-12",
		rooms: [
			{
				adults: rules.adultDefaultRoom,
				children: rules.childMinforRoom,
				infant: rules.infantMinforRoom,
				//ages: []
			},
		],
	};
	return params;
};

const getRoomChildrenYearsToQuery = (room, rules, _pax) => {
	if (room.child > 0) {
		if (_pax[1]) {
			const _years = _pax[1].split(",");
			let years = [];
			// eslint-disable-next-line eqeqeq
			if (_years.length == _pax[1])
				years = _years.map((year) => {
					// eslint-disable-next-line eqeqeq
					const nChild = rules.childYear.find((_child) => _child == year);
					return nChild !== undefined && year >= nChild
						? convertStringToNumber(year)
						: rules.childYear[0];
				});
			else {
				for (let i = 0; i < room.child; i++) {
					years[i] =
						_years[i] &&
							rules.childYear.find((_child) => _child == _years[i]) !== undefined
							? convertStringToNumber(_years[i])
							: rules.childYear[0];
				}
			}
			room.childYears = years;
		}
	}
	return room;
};

const paramsListFormat = (query, rules) => {
	const params = {
		inputAutoValueStart: query.inputAutoValueStart ?? null,
		rooms: [
			{
				adult: rules.adultDefaultRoom,
				child: rules.childMinforRoom,
				infant: rules.infantMinforRoom,
			},
		],
	};

	const start = 7;
	const end =  10;
	const checkinDate = new Date();
	const checkoutDate = new Date();
	checkinDate.setDate(checkinDate.getDate() + start);
	checkoutDate.setDate(checkoutDate.getDate() + end);
	const checkOutStatic = checkoutDate.toLocaleDateString("sv");
	const checkinStatic = checkinDate.toLocaleDateString("sv");
	params.startDate = checkinStatic;
	params.endDate = checkOutStatic;

	if (
		dateUtils.valiteDateFormat(query.checkin) &&
		dateUtils.valiteDateFormat(query.checkout)
	) {
		let checkin = dateUtils.getDateforFormat(query.checkin);
		let checkout = dateUtils.getDateforFormat(query.checkout);
		if (isBefore(checkin, checkout)) {
			if (
				(isEqual(checkin, new Date()) || isAfter(checkin, new Date())) &&
				isAfter(checkout, new Date())
			) {
				params.startDate = query.checkin;
				params.endDate = query.checkout;
			}
		}
	}

	if (query.paxes) {
		const rooms = [];
		const _rooms = query.paxes.split("!");
		if (_rooms.length > 0) {
			_rooms.forEach((_room) => {
				const room = {};
				const _pax = _room.split(":");
				const _guests = _pax[0].split("-");
				const adult = _guests[0] && convertStringToNumberWithDefault(_guests[0], rules.adultDefaultRoom);
				const child = _guests[1] && convertStringToNumberWithDefault(_guests[1], rules.childMinforRoom);
				const infant = _guests[2] && convertStringToNumberWithDefault(_guests[2], rules.infantMinforRoom);
				room.adult = adult >= rules.adultMinforRoom && adult <= rules.adultMaxforRoom ? adult : rules.adultDefaultRoom;
				room.child = child >= rules.childMinforRoom && child <= rules.childMaxforRoom ? child : rules.childMinforRoom;
				room.infant = infant >= rules.infantMinforRoom && infant <= rules.infantMaxforRoom ? infant : rules.childMinforRoom;
				rooms.push(getRoomChildrenYearsToQuery(room, rules, _pax));
			});
			if (
				guestsUtils.totalPeople(rooms) <= rules.personsTotal &&
				rooms.length <= rules.roomsMax
			)
				params.rooms = rooms;
		}
	}

	if (query?.s) params.s = query?.s;
	if (query.destination && query.destinationkeyword) {
		params.inputAutoValueStart = {
			label: query.destination,
			keyword: query.destinationkeyword,
			type: "Destination",
		};
	}
	else if (query.hotel && query.hotelkeyword) {
		params.inputAutoValueStart = {
			label: query.hotel,
			keyword: query.hotelkeyword,
			type: "Hotel",
		};
	}
	else if (query.airport && query.airportkeyword) {
		params.inputAutoValueStart = {
			label: query.airport,
			keyword: query.airportkeyword,
			type: "Airport",
		};
	}
	return params;
};

const paramsListFormatAvailability = (query) => {
	return {
		orderBy:
			query.sort &&
				orderByFilter.findIndex(
					(f) => f.value.toLocaleLowerCase() === query.sort.toLocaleLowerCase()
				)
				? query.sort.toLocaleLowerCase()
				: orderByFilter[0].value,
		mealPlans: null,
		categories: null,
		page: 1,
	};
};

const paramsHotelFormat = (query, rules) => {
	const params = {
		inputAutoValueStart: {
			label: query.sn,
			keyword: query.keyword,
			type: "HotelDetails",
		},
		rooms: [
			{
				adult: rules.adultDefaultRoom,
				child: rules.childMinforRoom,
				infant: rules.infantMinforRoom,
			},
		],
	};
	const start = 7;
	const end =  10;
	const checkinDate = new Date();
	const checkoutDate = new Date();
	checkinDate.setDate(checkinDate.getDate() + start);
	checkoutDate.setDate(checkoutDate.getDate() + end);
	const checkOutStatic = checkoutDate.toLocaleDateString("sv");
	const checkinStatic = checkinDate.toLocaleDateString("sv");
	params.startDate = checkinStatic;
	params.endDate = checkOutStatic;

	if (
		dateUtils.valiteDateFormat(query.checkin) &&
		dateUtils.valiteDateFormat(query.checkout)
	) {
		let checkin = dateUtils.getDateforFormat(query.checkin);
		let checkout = dateUtils.getDateforFormat(query.checkout);
		if (isBefore(checkin, checkout)) {
			if (
				(isEqual(checkin, new Date()) || isAfter(checkin, new Date())) &&
				isAfter(checkout, new Date())
			) {
				params.startDate = query.checkin;
				params.endDate = query.checkout;
			}
		}
	}

	if (query.paxes) {
		const rooms = [];
		const _rooms = query.paxes.split("!");
		if (_rooms.length > 0) {
			_rooms.forEach((_room) => {
				const room = {};
				const _pax = _room.split(":");
				const _guests = _pax[0].split("-");
				const adult = _guests[0] && convertStringToNumberWithDefault(_guests[0], rules.adultDefaultRoom);
				const child = _guests[1] && convertStringToNumberWithDefault(_guests[1], rules.childMinforRoom);
				const infant = _guests[2] && convertStringToNumberWithDefault(_guests[2], rules.infantMinforRoom);
				room.adult = adult >= rules.adultMinforRoom && adult <= rules.adultMaxforRoom ? adult : rules.adultDefaultRoom;
				room.child = child >= rules.childMinforRoom && child <= rules.childMaxforRoom ? child : rules.childMinforRoom;
				room.infant = infant >= rules.infantMinforRoom && infant <= rules.infantMaxforRoom ? infant : rules.childMinforRoom;
				rooms.push(getRoomChildrenYearsToQuery(room, rules, _pax));
			});
			if (
				guestsUtils.totalPeople(rooms) <= rules.personsTotal &&
				rooms.length <= rules.roomsMax
			)
				params.rooms = rooms;
		}
	}
	return params;
};

const getCategoryStartGroup = (stars) => {
	var count = 0;
	switch (stars) {
		case "Two":
		case "TwoAndAHalf":
			count = 2;
			break;
		case "Three":
		case "ThreeAndAHalf":
			count = 3;
			break;
		case "Four":
		case "FourAndAHalf":
			count = 4;
			break;
		case "Five":
			count = 5;
			break;
		default:
		case "One":
		case "OneAndAHalf":
			count = 1;
			break;
	}
	return count;
};

// params top destinations

const paramsTopHotels = (currency, hotelsIds, sourceMarket, culture, addStart, addEnd) => {
	const dates = dateUtils.getRangeDays(addStart, addEnd);
	return {
		hotelIds: hotelsIds,
		checkIn: dates.startDate,
		checkOut: dates.endDate,
		currency: currency,
		culture: culture,
		sourceMarket: sourceMarket,
		maxPhotos: 0,
		occupancies: [
			{
				adults: 2,
				children: 0,
				infant: 0,
				ages: null,
			},
		],
	};
};

const addRageDateFromURl = (query) => {

	if (
    query.checkin == null ||
    query.checkout == null ||
    query.checkin == "" ||
    query.checkout == ""
  ) {
    if (query?.addDays != null && query?.nights != null) {
      let checkoutDays = parseInt(query.addDays) + parseInt(query.nights);
      let checkintDays = parseInt(query.addDays);

      if (checkintDays < checkoutDays) {
        query.checkin = moment(new Date())
          .add(checkintDays, "days")
          .format("yyyy-MM-DD");
        query.checkout = moment(new Date())
          .add(checkoutDays, "days")
          .format("yyyy-MM-DD");
      }
    }
  }
  return query;
}

const hotelsValidate = {
	paramsFormat,
	paramsListFormat,
	paramsHotelFormat,
	paramsListforQuery,
	formatList,
	formatDetails,
	formatQueryDestinationFromParams,
	formatQueryDetailsFromParams,
	paramsListFormatAvailability,
	paxesQuery,
	paramsTopHotels,
	convertStringToKeyname,
	contieneEntidadesHTML,
	addRageDateFromURl
};

export default hotelsValidate;