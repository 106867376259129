import React, { useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./components/style.scss";

const Overlay = (props) => {
  const {
    placement,
    classNameOverlay,
    description,
    isHover,
    trigger,
    closeTooltip,
    onCloseTooltip,
  } = props;

  const renderTooltip = (props) => {
    const { className, ...rest } = props;
    return (
      <Tooltip className={`${classNameOverlay}`} {...rest}>
        {description}
      </Tooltip>
    );
  };

  useEffect(() => {
    if (closeTooltip) {
      onCloseTooltip && onCloseTooltip();
    }
  }, [closeTooltip, onCloseTooltip]);

  const onToggle = () => {
    onCloseTooltip && onCloseTooltip();
  };

  return (
    <>
      <OverlayTrigger
        onToggle={onToggle}
        overlay={renderTooltip}
        placement={placement}
        trigger={isHover ? trigger : "click"}
        rootClose
      >
        <div className="button-overlay">{props.children}</div>
      </OverlayTrigger>
    </>
  );
};
Overlay.defaultProps = {
  placement: "top",
  classNameOverlay: "",
  trigger: "hover",
  isHover: false,
  closeTooltip: false,
};
export default Overlay;
