import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import CardSection from "./stripe/CardSection";
import { Button } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import iconContants from '../../constants/iconContants'
 import iconUtils from '../../utils/iconUtils'
class CheckoutForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      paymentId: props.paymentId,
      disabled: true,
      error: null,
      processing: false,
      clientSecret: props.clientSecret,
      canBook: props.canBook,
    };
  }

  handleChange = (event) => {
    var statError = event?.error?.code ?? "";

    this.setState({
      ...this.state,
      disabled: event.empty,
      error: statError,
    });
  };

  handleSubmit = async (event) => {
    const {
      onSubmit,
      disableEditInformationButton,
      checkRatePrePayment,
      bodyReservation,
    } = this.props;
    event.preventDefault();

    this.setState({
      ...this.state,
      // error: this.state.error.message ?? '', //TODO check
      processing: true,
    });

    disableEditInformationButton(true);
    const checkRatesuccess = await checkRatePrePayment();

    if (checkRatesuccess) {
      const { clientSecret } = this.state;
      const { stripe, elements } = this.props;
      if (!stripe || !elements) {
        return;
      }

      const card = elements.getElement(CardElement);

      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card: card },
      });
      if (payload.error) {
        this.setState({
          ...this.state,
          disabled: false,
          processing: false,
          error: payload?.error?.code,
        });
        disableEditInformationButton(false);
        // Display error.message in your UI.
      } else {
        // The payment has succeeded
        // Display a success message
        onSubmit && onSubmit(payload);
      }
    }
  };

  //Function to translate error mesagges
  handleErrors = (code, intl) => {
    switch (code) {
      case "card_declined":
        return intl.formatMessage({ id: "stripe.card_declined" });
      case "expired_card":
        return intl.formatMessage({ id: "stripe.expired_card" });
      case "incorrect_cvc":
        return intl.formatMessage({ id: "stripe.invalid_cvc" });
      case "processing_error":
        return intl.formatMessage({ id: "stripe.card_declined" });
      case  "invalid_number":
        return intl.formatMessage({ id: "stripe.incorrect_number" });
      case "incorrect_number":
          return intl.formatMessage({ id: "stripe.incorrect_number" });
      case "invalid_expiry_year_past":
        return intl.formatMessage({ id: "stripe.invalid_expiry_year" });
      case "invalid_expiry_year":
          return intl.formatMessage({ id: "stripe.invalid_expiry_year" });
   case "invalid_expiry_month_past":
        return intl.formatMessage({ id: "stripe.invalid_expiry_month" });
      case "invalid_expiry_month" :
          return intl.formatMessage({ id: "stripe.invalid_expiry_month" });
      case "incomplete_zip" :
            return intl.formatMessage({ id: "stripe.incomplete_zip" });
      default:
        return intl.formatMessage({ id: "stripe.processing_error" });
    }
  };

  render() {
    const { intl } = this.props;
    const { divRef } = this.props;
    return (
      <div ref={divRef}>
        {this.state?.canBook ? (
          <form
            className="box-form mt-3"
            qui={"frm paymentFormStripe"}
            onSubmit={this.handleSubmit}
          >
            <div id="payment-section">
              <CardSection onChange={this.handleChange} />

              <Button
                type="submit"
                id="add_payment_info"
                qui={"btn pay"}
                disabled={
                  this.state.processing ||
                  this.state.disabled ||
                  this.statesucceeded
                }
                className="mt-3 checkout-spt-btn"
              >
                {this.state.processing && (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">
                      {intl.formatMessage({
                        id: "checkout.formStripe.loading",
                      })}
                    </span>
                  </Spinner>
                )}
                <span qui={"span stripeInfo"} className="me-1 ms-1">
                  {intl.formatMessage({ id: "checkout.formStripe.submit" })}
                </span>
              </Button>

              {this.state.error && (
                <div
                  className="card-error mt-2"
                  qui={"span stripeError"}
                  role="alert"
                >
                  {this.handleErrors(this.state.error, intl)}
                </div>
              )}
            </div>
          </form>
        ) : (
          <div>
            <div class="alert alert-warning mt-3" role="alert">
              <h4 class="alert-heading">
                {iconUtils.icon(iconContants.TBALERTCIRCLE)}{" "}
                <strong>
                  {" "}
                  {intl.formatMessage({
                    id: "checkout.formRules.important",
                  })}
                </strong>{" "}
              </h4>

              <p class="mb-0">
                {intl.formatMessage({
                  id: "checkout.formRules.paymentMethodDisabled",
                })}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} canBook={props.canBook} divRef={props.divRef} elements={elements} paymentId={props.paymentId} {...props} />
      )}
    </ElementsConsumer>
  );
}
