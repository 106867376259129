import initialState from "./initialState";
import types from "../constants/reducers/hotelConstants";
const tourReducer = (state = initialState.tour, action) => {
    switch(action.type) {
        case types.TOUR_FETCHING:
            return {...initialState.tour, ...action.tour}
        case types.TOUR:
            return action.tour;
        case types.TOUR_ERROR:
            return {...initialState.tour}
        default:
            return state;
    }
}

export default tourReducer;