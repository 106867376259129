import uuidUtils from "./uuidUtils";
import service from "../constants/serviceTypeConstant";
import { FormattedMessage } from "react-intl";
/* import selectPersonsUtils from "../utils/selectPersonsUtils"; */

const getHotel = (grouperServices) => {
  const _service = grouperServices.filter((ser) => ser.type === service.HOTEL);
  const auxService = _service[0];

  return auxService;
};

const getTour = (grouperServices) => {
  const _service = grouperServices.filter((ser) => ser.type === service.TOUR);
  const auxService = _service[0];

  return auxService;
};

const getShutter = (grouperServices) => {
  const _service = grouperServices.filter(
    (ser) => ser.type === service.TRANSFER
  );
  const auxService = _service[0];

  return auxService;
};

//TODO create cart the hotel
const reservationHotel = (hotel, params, galleryH,cartId= null) => {
  const uuid = cartId ?? uuidUtils.uuidv4();
  const request = {
    uuid,
    hotelPhoto: galleryH?.photoUris,
    hotel: {
      id: hotel.id,
      name: hotel.name,
      checkin: hotel.checkin,
      checkout: hotel.checkout,
      city: hotel.city,
      country: hotel.country,
      externalId: hotel.externalId,
      gallery: hotel.rooms[0].photos,
      rooms: hotel.rooms,
      stars: hotel.stars,
      services: hotel.services,
    },
    services: [
      {
        ...hotel,
        type: service.HOTEL,
        id: hotel.id,
        gallery: hotel.rooms[0].photos,
      },
    ],
    booking: {
      ...params,
      type: service.HOTEL,
    },
  };
  return request;
};

//TODO create cart the tour
const reservationTour = (hotel, params) => {
  const uuid = uuidUtils.uuidv4();
  const request = {
    uuid,
    services: [
      {
        type: service.TOUR,
        id: hotel.id,
        name: hotel.name,
        checkin: hotel.checkin,
        //checkout: hotel.checkout,
        city: hotel.city,
        country: hotel.country,
        externalId: hotel.externalId,
        gallery: hotel.rooms[0].photos,
        rooms: hotel.rooms,
        stars: hotel.stars,
      },
    ],
    booking: {
      ...params,
      type: service.TOUR,
    },
  };
  return request;
};

//TODO create cart the shutter
const reservationShutter = (hotel, params) => {
  const uuid = uuidUtils.uuidv4();
  const request = {
    uuid,
    services: [
      {
        ...hotel,
        type: service.TRANSFER,
      },
    ],
    booking: {
      ...params,
      type: service.TRANSFER,
    },
  };
  return request;
};

//TODO create cart the package
const reservationPackage = (hotel, params) => {
  const uuid = uuidUtils.uuidv4();
  const request = {
    uuid,
    hotel: {
      id: hotel.id,
      name: hotel.name,
      checkin: hotel.checkin,
      checkout: hotel.checkout,
      city: hotel.city,
      country: hotel.country,
      externalId: hotel.externalId,
      rooms: hotel.rooms,
      stars: hotel.stars,
    },
    services: [
      {
        type: service.HOTEL,
        id: hotel.id,
        name: hotel.name,
        checkin: hotel.checkin,
        checkout: hotel.checkout,
        city: hotel.city,
        country: hotel.country,
        gallery: hotel.rooms[0].photos,
        externalId: hotel.externalId,
        rooms: hotel.rooms,
        stars: hotel.stars,
      },
      {
        typeService: service.PACKAGE,
      },
    ],
    booking: {
      ...params,
      type: service.PACKAGE,
    },
  };
  return request;
};

const bodyReservationHotel = (
  params,
  clientInformation,
  checkRates,
  payPublicRate = true,
	payment,
	language
) => {
	const holder = clientInformation[0];
  const auxBody = {
    reservationHolder: {
      name: holder.name,
      lastName: holder.lastName,
      gender: "NotDefined",
      dateOfBirth: "1989-08-11",
      countryOfResidence: holder.countryOfResidence ?? "MEX", //TODO static parameter
      email: holder.email,
      phone: holder.phone,
    },
		language: language,
    specialRequests: holder.specialRequests,
    payPublicRate: payPublicRate,
		payment: {
			id: payment.id,
			provider: payment.provider ?? "stripe"
		}
  };
  auxBody.rooms = params.rooms.map((room, i) => {
    const adults = Array.from(Array(room.adult)).map((_, index) => {
      return {
        name: index === 0 ? clientInformation[i].name : `adult_name_${index}`,
				lastName: index === 0 ? clientInformation[i].lastName : `adult_lastName_${index}`,
        paxType: "Adult",
        gender: "NotDefined",
      };
    });
    const children = room.child <= 0 ? [] : Array.from(Array(room.child)).map((_, index) => {
      return {
        name: `child_name_${index}`,
        lastName: `child_lastName_${index}`,
        paxType: "Children",
        gender: "NotDefined",
        age:  room?.childYears ? room?.childYears[index]: room?.childYear[index],
      };
    });

    const auxPaxes = room.child > 0 ? adults.concat(children) : adults;
    return {
      paxes: auxPaxes,
      bookingKey: checkRates.items[0]?.bookingKey,
      payload: checkRates.items[0]?.payload,
      iv: checkRates.items[0]?.iv,
    };
  });

  return auxBody;
};

const ladaItems = [
  {
    code: "es-mx",
    label: "MX +52",
    value: "52",
    iso:"MX"
  },
  {
    code: "en-us",
    label: "US +1",
    value: "1",
    iso:"US"
  },
  {
    code: "en-us",
    label: "CA +1",
    value: "1",
    iso:"CA"
  },
  {
    code: "en-us",
    label: "ES +34",
    value: "34",
    iso:"ES"
  },
  {
    code: "en-us",
    label: "UK +44",
    value: "44",
    iso:"UK"
  },
];

const rulesDescription = {
  name: {
    empty: "el campo no puede estar vacio",
    minText: "agrega un nombre",
    text: "solo texto",
  },
  secondName: {
    empty: "el campo no puede estar vacio",
  },
  lastName: {
    empty: "el campo no puede estar vacio",
    minText: "agrega un nombre",
    text: "solo texto",
  },
  phone: {
    empty: "el campo no puede estar vacio",
    minText: "agrega un nombre",
    maxText: "agrega un nombre",
    text: "solo texto",
  },
  email: {
    empty: "el campo no puede estar vacio",
    text: "solo texto",
  },
  special: {
    text: "solo texto y numeros",
  },
};

const form = {
  name: {
    label: <FormattedMessage id="form.name" />,
    id: "form-name",
    aria: <FormattedMessage id="form.name" />,
  },
  lastName: {
    label: <FormattedMessage id="form.lastname" />,
    id: "form-last-name",
    aria: <FormattedMessage id="form.lastname" />,
  },
  phone: {
    label: <FormattedMessage id="form.phone" />,
    id: "phone",
    aria: <FormattedMessage id="form.phone" />,
    type: "tel",
  },
  email: {
    label: <FormattedMessage id="form.mail" />,
    id: "emails",
    aria: <FormattedMessage id="form.mail" />,
    type: "email",
  },
  airline: {
    label: <FormattedMessage id="form.airline" />,
    id: "airline",
    aria: <FormattedMessage id="form.airline" />,
  },
  numFlight: {
    label: <FormattedMessage id="form.numFlight" />,
    id: "numFlight",
    aria: <FormattedMessage id="form.numFlight" />,
  },
  terminal: {
    label: <FormattedMessage id="checkout.terminal" />,
    id: "terminal",
    aria: <FormattedMessage id="checkout.terminal" />,
  },
  pickUp: {
    label: <FormattedMessage id="checkout.pickUp" />,
    id: "pickUp",
    aria: <FormattedMessage id="checkout.pickUp" />,
    type: "time",
  },
  specialRequests: {
    label: <FormattedMessage id="checkout.form.special" />,
    subLabel: <FormattedMessage id="checkout.form.special.text" />,
    id: "specialR",
    aria: <FormattedMessage id="checkout.form.special" />,
  },
};

const optionsPaxes = {
  title: <FormattedMessage id="checkout.cardPaxes.title" />,
  description: <FormattedMessage id="checkout.cardPaxes.description" />,
  informationTitle: (
    <FormattedMessage id="checkout.cardPaxes.information.title" />
  ),
  informationDescription: (
    <FormattedMessage id="checkout.cardPaxes.information.description" />
  ),
};

const checkoutUtils = {
  getHotel,
  getTour,
  getShutter,
  reservationHotel,
  reservationTour,
  reservationShutter,
  reservationPackage,
  bodyReservationHotel,
  ladaItems,
  rulesDescription,
  form,
  optionsPaxes,
};

export default checkoutUtils;
