const voucherValidate = (data) => {
	const hotel = data.hotelBookings[0];
	var star = hotel?.category?.number ? hotel?.category?.number : hotel?.category?.name?.split(" ")[0];
	return {
		clientReference: data.id,
		referenceBooking: data.id,
		holderName: data.holder.name,
		hotelStars: star,
		holderLastName: data.holder.surname,
		holderPhoneNumber: data.holder.phone,
		holderEmail: data.holder.email,
		holderCountryOfResidence: data.holder.countryOfResidence,
		alphanumericReference: data.agencyReference,
		hotelId: hotel.hotelId,
		hotelName: hotel.name,
		rference: hotel.reference,
		hotelAddress: hotel.address.street,
		hotelDestination: hotel.address.city,
		hotelCategory: hotel.category.name,
		hotelCategoryCode: data,
		hotelCategoryType: hotel.category.iconType,
		hotelPhoto: hotel.image.uri,
		hotelPhoneNumber: hotel.phone,
		checkIn: hotel.checkIn,
		checkOut: hotel.checkOut,
		comments: hotel.customerComments,
		photos: [],
		additionalInformation: {
			supplierName: hotel.supplierName,
			supplierNumber: hotel.supplierNumber,
		},
		mailSent: true,
		paymentStatus: data.paymentStatus,
		status: data.bookingStatus,
		...data
	};
};

export default voucherValidate;
