export default {
    "tourProvisional.name": "Parque Xcaret: nocturno",
    "tourProvisional.txt1": "Alimentos y bebidas",
    "tourProvisional.txt2": "Sigue el aroma del copal hasta el Pueblo Prehispánico, donde las Danzas Prehispánicas celebran la llegada de la noche",
    "tourProvisional.experience.1": "Disfruta de más de 40 atracciones y actividades en el Parque Xcaret",
    "tourProvisional.experience.2": "Nada en las cuevas y piscinas naturales de un exuberante paisaje tropical",
    "tourProvisional.experience.3": "Explora el acuario de arrecifes de coral del Mar Caribe",
    "tourProvisional.experience.4": "Déjate deslumbrar por las mariposas del criadero del Pabellón de Mariposas",
    "tourProvisional.experience.5": "Mira espectáculos culturales mayas y mexicanos.",
    "tourProvisional.detail.1": "Disfruta de la entrada de día completo al Parque Xcaret, un parque temático paradisíaco en la Riviera Maya, donde podrás disfrutar de más de 40 actividades y atracciones.",
    "tourProvisional.detail.2": "Casillero y uso de equipo de snorkel (se requiere un depósito reembolsable de 20 USD)",
    "tourProvisional.detail.3": "Exposiciones y recorridos culturales",
    "tourProvisional.detail.4": "Espectáculo Xcaret México Espectacular (un viaje musical a través de la historia de México)",
    "tourProvisional.detail.5": "Chalecos salvavidas y cámaras de aire",
    "tourProvisional.detail.6": "Vestidores y duchas",
    "tourProvisional.detail.7": "Acceso a la playa, ensenada y piscinas naturales",
    "tourProvisional.detail.8": "Áreas de descanso, sillones y hamacas",
    "tourProvisional.detail.9": "Acuario de arrecife de coral",
    "tourProvisional.detail.10": "Casa de los Susurros",
    "tourProvisional.detail.11": "Cría de la fauna de la granja",
    "tourProvisional.detail.12": "Capilla de San Francisco de Asís",
    "tourProvisional.detail.13": "Estacionamiento",
    "tourProvisional.detail.14": "Marinas",
    "tourProvisional.detail.15": "Transporte",
    "tourProvisional.detail.16": "Nado con delfines",
    "tourProvisional.detail.17": "Aventura interactiva con tiburones",
    "tourProvisional.detail.18": "Fotos",
    "tourProvisional.notfor.1": "Personas con problemas de espalda",
    "tourProvisional.notfor.2": "Personas con problemas de movilidad",
    "tourProvisional.notfor.3": "Personas con problemas cardíacos",
    "tourProvisional.notfor.4": "Personas en silla de ruedas",
    "tourProvisional.important.1": "Pasaporte o Identificación Oficial",
    "tourProvisional.important.2": "Calzado cómodo",
    "tourProvisional.important.3": "Depósito",
    "tourProvisional.important.4": "Personas en silla de ruedas",
    "tourProvisional.general.1": "Cancela con hasta 24 horas de antelación y recibe un reembolso completo",
    "tourProvisional.general.2": "Se aplican medidas sanitarias y de seguridad especiales. Consulta todos los detalles en tu cupón de la actividad una vez que hayas realizado la reserva.",
    "tourProvisional.general.3": "Comprueba la disponibilidad para ver los horarios de inicio.",
    "tourProvisional.general.4": "Español, Inglés",
    "tourProvisional.title.1": "¿Qué harás?",
    "tourProvisional.title.2": "Detalle de Tour",
    "tourProvisional.title.3": "¿Qué incluye?",
    "tourProvisional.title.4": "No apto para",
    "tourProvisional.title.5": "Información importante",
}
