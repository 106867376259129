const resources = (locale, typePage) => {
    let data = null;
    try {
        data = require(`./${
            process.env.REACT_APP_BUSINESS_SITE_FILE
        }/api/response-${typePage}-${locale.toLowerCase()}.js`);
    }
    catch(_) {
        data = null;
    }

    return data ? data.default: null;
};

export default resources;