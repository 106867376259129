const listHotelsMock = {
  result: [
    {
      id: "5ea6223c-052c-4f0b-b6a0-32d8d4ae75ae",
      externalId: "5ea6223c-052c-4f0b-b6a0-32d8d4ae75ae",
      name: "Crown Paradise Club Cancun All Inclusive",
      shortDescription:
        "Esta propiedad es el enclave vacacional de playa perfecto, con el transporte público a sus puertas y a un breve trayecto en coche del centro de la ciudad. Las instalaciones del complejo incluyen 4 pis",
      stars: "Five",
      type: "Hotel",
      address: "Cancun",
      photos: [
        {
          name: "Crown Paradise Club Cancun All Inclusive - 1",
          url: "https://photos.hotelbeds.com/giata/00/005403/005403a_hb_p_023.JPG",
        },
        {
          name: "Crown Paradise Club Cancun All Inclusive - 2",
          url: "https://photos.hotelbeds.com/giata/00/005403/005403a_hb_ba_041.jpg",
        },
        {
          name: "Crown Paradise Club Cancun All Inclusive - 3",
          url: "https://photos.hotelbeds.com/giata/00/005403/005403a_hb_p_015.JPG",
        },
        {
          name: "Crown Paradise Club Cancun All Inclusive - 4",
          url: "https://photos.hotelbeds.com/giata/00/005403/005403a_hb_k_024.jpg",
        },
        {
          name: "Crown Paradise Club Cancun All Inclusive - 5",
          url: "https://photos.hotelbeds.com/giata/00/005403/005403a_hb_w_001.JPG",
        },
      ],
      geoPosition: {
        latitude: 21.056093,
        longitude: -86.781217,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-AI-SPA1",
            rateKey:
              "2022-11-17|2022-11-19|2|5ea6223c-052c-4f0b-b6a0-32d8d4ae75ae|HotelBeds|DBL.ST|BOOKABLE|NOR|AI|MXN|SPA1|9178.05|MX|638005925048444442;FC9FE985B2B1847B46F9214040EE0844",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 9234.046791375,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 12/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 4617,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-AI-SPA1",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 9234,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 12/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 9234,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 4617,
      },
      amenities: ["Vigil - Gaona", "Alarcón Hermanos", "Razo - Arce"],
    },
    {
      id: "23591bc9-4ce6-4196-8df0-65bb5fd97557",
      externalId: "23591bc9-4ce6-4196-8df0-65bb5fd97557",
      name: "Hotel Dos Playas Faranda Cancun",
      shortDescription:
        "Este magnífico hotel goza de una excelente ubicación en Cancún, en primera línea de playa, a sólo unos pasos de la famosa Avenida Kukulkán y a escasa distancia en coche del centro de la ciudad. Rodead",
      stars: "ThreeAndAHalf",
      type: "Resort",
      address: "Cancun",
      photos: [
        {
          name: "Hotel Dos Playas Faranda Cancun - 1",
          url: "https://photos.hotelbeds.com/giata/00/007896/007896a_hb_r_023.jpg",
        },
        {
          name: "Hotel Dos Playas Faranda Cancun - 2",
          url: "https://photos.hotelbeds.com/giata/00/007896/007896a_hb_s_009.jpg",
        },
        {
          name: "Hotel Dos Playas Faranda Cancun - 3",
          url: "https://photos.hotelbeds.com/giata/00/007896/007896a_hb_t_005.jpg",
        },
        {
          name: "Hotel Dos Playas Faranda Cancun - 4",
          url: "https://photos.hotelbeds.com/giata/00/007896/007896a_hb_a_015.jpg",
        },
        {
          name: "Hotel Dos Playas Faranda Cancun - 5",
          url: "https://photos.hotelbeds.com/giata/00/007896/007896a_hb_t_001.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.140095,
        longitude: -86.770597,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-GARRO",
            rateKey:
              "2022-11-17|2022-11-19|2|23591bc9-4ce6-4196-8df0-65bb5fd97557|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|GARRO|3953.46|MX|638005925048456335;E772417F13860F98096A19C4799F44AC",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 4009.4622578,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 08/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
              "Puedes cancelar hasta el 11/11/2022 a las 4:59",
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 2005,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-GARRO",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 4009,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 08/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
          "Puedes cancelar hasta el 11/11/2022 a las 4:59",
          "Puedes cancelar hasta el 18/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 4009,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 2005,
      },
      amenities: [
        "Zúñiga - Pedraza",
        "Montemayor, Caraballo and Ibarra",
        "Sanabria, Vanegas and Núñez",
        "Delgadillo - Arreola",
      ],
    },
    {
      id: "93f9750c-4df2-4bee-92f0-012abb7aaf35",
      externalId: "93f9750c-4df2-4bee-92f0-012abb7aaf35",
      name: "Hotel Maya Caribe Faranda Cancún",
      shortDescription:
        "Con una ubicación privilegiada en primera línea de la playa de Cancún, uno de los destinos turísticos más famosos del mundo, este encantador hotel acoge a todo tipo de huéspedes, desde visitantes part",
      stars: "Three",
      type: "Villa",
      address: "Cancun",
      photos: [
        {
          name: "Hotel Maya Caribe Faranda Cancún - 1",
          url: "https://photos.hotelbeds.com/giata/00/008410/008410a_hb_p_005.jpg",
        },
        {
          name: "Hotel Maya Caribe Faranda Cancún - 2",
          url: "https://photos.hotelbeds.com/giata/00/008410/008410a_hb_f_007.jpg",
        },
        {
          name: "Hotel Maya Caribe Faranda Cancún - 3",
          url: "https://photos.hotelbeds.com/giata/00/008410/008410a_hb_f_006.jpg",
        },
        {
          name: "Hotel Maya Caribe Faranda Cancún - 4",
          url: "https://photos.hotelbeds.com/giata/00/008410/008410a_hb_a_019.jpg",
        },
        {
          name: "Hotel Maya Caribe Faranda Cancún - 5",
          url: "https://photos.hotelbeds.com/giata/00/008410/008410a_hb_r_009.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.139753,
        longitude: -86.771329,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-GARRO",
            rateKey:
              "2022-11-17|2022-11-19|2|93f9750c-4df2-4bee-92f0-012abb7aaf35|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|GARRO|3953.46|MX|638005925048456966;9FD81F25F1FE04C6B44FF19EE6841BCF",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 4009.4622578,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 08/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
              "Puedes cancelar hasta el 11/11/2022 a las 4:59",
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 2005,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-GARRO",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 4009,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 08/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
          "Puedes cancelar hasta el 11/11/2022 a las 4:59",
          "Puedes cancelar hasta el 18/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 4009,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 2005,
      },
      amenities: ["Delapaz - Tirado", "Macías Hermanos"],
    },
    {
      id: "e22f5831-db93-4bcc-9928-9f812c940bf5",
      externalId: "e22f5831-db93-4bcc-9928-9f812c940bf5",
      name: "Golden Parnassus Resort- Adults Only All Inclusive",
      shortDescription:
        "Este complejo hotelero está situado en el famoso tramo turístico de Cancún, a sólo unos minutos del centro de la ciudad, donde los huéspedes podrán encontrar numerosos bares, restaurantes, tiendas y l",
      stars: "FourAndAHalf",
      type: "Resort",
      address: "Cancun",
      photos: [
        {
          name: "Golden Parnassus Resort- Adults Only All Inclusive - 1",
          url: "https://photos.hotelbeds.com/giata/00/007847/007847a_hb_ro_035.jpg",
        },
        {
          name: "Golden Parnassus Resort- Adults Only All Inclusive - 2",
          url: "https://photos.hotelbeds.com/giata/00/007847/007847a_hb_ro_025.jpg",
        },
        {
          name: "Golden Parnassus Resort- Adults Only All Inclusive - 3",
          url: "https://photos.hotelbeds.com/giata/00/007847/007847a_hb_r_004.jpg",
        },
        {
          name: "Golden Parnassus Resort- Adults Only All Inclusive - 4",
          url: "https://photos.hotelbeds.com/giata/00/007847/007847a_hb_p_003.JPG",
        },
        {
          name: "Golden Parnassus Resort- Adults Only All Inclusive - 5",
          url: "https://photos.hotelbeds.com/giata/00/007847/007847a_hb_p_001.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.091989,
        longitude: -86.768727,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.LX-OV-BOOKABLE-NOR-False-AT_WEB-AI-CGW-MEXB2B",
            rateKey:
              "2022-11-17|2022-11-19|2|e22f5831-db93-4bcc-9928-9f812c940bf5|HotelBeds|DBL.LX-OV|BOOKABLE|NOR|AI|MXN|CGW-MEXB2B|17272.28|MX|638005925048460609;8016419072D3D0D36A1366D1073A90B3",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 17328.27838435,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 13/11/2022 a las 4:59",
              "Puedes cancelar hasta el 16/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            offers: [
              {
                name: "Special discount",
                total: 727.46989832,
              },
            ],
            nights: 2,
            pricePerNight: 8664,
          },
        ],
        id: "DBL.LX-OV-BOOKABLE-NOR-False-AT_WEB-AI-CGW-MEXB2B",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: true,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 17328,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 13/11/2022 a las 4:59",
          "Puedes cancelar hasta el 16/11/2022 a las 4:59",
        ],
        offers: [
          {
            name: "Special discount",
            total: 727.46989832,
          },
        ],
        totalDiscounted: 727,
        discountRate: 4,
        totalWithOffers: 18055,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 8664,
      },
      amenities: [
        "Vigil - Carbajal",
        "Escobedo, Bueno and Reynoso",
        "Luevano S.A.",
        "Zayas S.L.",
      ],
    },
    {
      id: "910a04dc-38c2-4b96-aa36-d1c2ac95a1df",
      externalId: "910a04dc-38c2-4b96-aa36-d1c2ac95a1df",
      name: "Hotel Imperial Laguna Faranda cancun",
      shortDescription:
        "El cómodo y práctico Imperial Laguna Cancún está situado en una tranquila zona residencial del área hotelera de Cancún. En sus inmediaciones los huéspedes encontrarán la Plaza Caracol, un gran centro ",
      stars: "FourAndAHalf",
      type: "Posada",
      address: "Cancun",
      photos: [
        {
          name: "Hotel Imperial Laguna Faranda cancun - 1",
          url: "https://photos.hotelbeds.com/giata/00/005178/005178a_hb_a_008.jpg",
        },
        {
          name: "Hotel Imperial Laguna Faranda cancun - 2",
          url: "https://photos.hotelbeds.com/giata/00/005178/005178a_hb_a_010.jpg",
        },
        {
          name: "Hotel Imperial Laguna Faranda cancun - 3",
          url: "https://photos.hotelbeds.com/giata/00/005178/005178a_hb_t_006.jpg",
        },
        {
          name: "Hotel Imperial Laguna Faranda cancun - 4",
          url: "https://photos.hotelbeds.com/giata/00/005178/005178a_hb_p_010.jpg",
        },
        {
          name: "Hotel Imperial Laguna Faranda cancun - 5",
          url: "https://photos.hotelbeds.com/giata/00/005178/005178a_hb_r_006.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.133097,
        longitude: -86.757455,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-GARRO",
            rateKey:
              "2022-11-17|2022-11-19|2|910a04dc-38c2-4b96-aa36-d1c2ac95a1df|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|GARRO|2063.46|MX|638005925048466932;86508B6E075172A26F302AB20EB18432",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2119.456871575,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 08/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
              "Puedes cancelar hasta el 11/11/2022 a las 4:59",
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 1060,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-GARRO",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 2119,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 08/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
          "Puedes cancelar hasta el 11/11/2022 a las 4:59",
          "Puedes cancelar hasta el 18/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 2119,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1060,
      },
      amenities: [
        "Guardado - Ponce",
        "Rojas S.A.",
        "Aguayo, Valenzuela and Alcaraz",
        "Naranjo, Garrido and Tirado",
      ],
    },
    {
      id: "a56b6235-5963-4999-ac28-c63b0a0fd82b",
      externalId: "a56b6235-5963-4999-ac28-c63b0a0fd82b",
      name: "Beachscape Kin Ha Villas & Suites",
      shortDescription:
        "Situado justo en la playa y rodeado de un jardín tropical, este hotel está a solo unos minutos a pie del Centro de Convenciones. La playa de enfrente del hotel es famosa entre los que les gusta bucear",
      stars: "ThreeAndAHalf",
      type: "Posada",
      address: "Cancun",
      photos: [
        {
          name: "Beachscape Kin Ha Villas & Suites - 1",
          url: "https://photos.hotelbeds.com/giata/00/008540/008540a_hb_l_015.jpg",
        },
        {
          name: "Beachscape Kin Ha Villas & Suites - 2",
          url: "https://photos.hotelbeds.com/giata/00/008540/008540a_hb_s_037.jpg",
        },
        {
          name: "Beachscape Kin Ha Villas & Suites - 3",
          url: "https://photos.hotelbeds.com/giata/00/008540/008540a_hb_a_019.jpg",
        },
        {
          name: "Beachscape Kin Ha Villas & Suites - 4",
          url: "https://photos.hotelbeds.com/giata/00/008540/008540a_hb_ro_026.jpg",
        },
        {
          name: "Beachscape Kin Ha Villas & Suites - 5",
          url: "https://photos.hotelbeds.com/giata/00/008540/008540a_hb_p_013.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.135977,
        longitude: -86.753946,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-PPAMEX",
            rateKey:
              "2022-11-17|2022-11-19|2|a56b6235-5963-4999-ac28-c63b0a0fd82b|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|PPAMEX|5525.30|MX|638005925048467024;7FCA1E93529115B214F50FC0B4C49B46",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 5581.302197525,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 14/11/2022 a las 4:59",
              "Puedes cancelar hasta el 17/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 2791,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-PPAMEX",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 5581,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 14/11/2022 a las 4:59",
          "Puedes cancelar hasta el 17/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 5581,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 2791,
      },
      amenities: ["Acosta S.L.", "Miranda Hermanos"],
    },
    {
      id: "fa547dbd-a22d-425f-8447-1dc24553febd",
      externalId: "fa547dbd-a22d-425f-8447-1dc24553febd",
      name: "Selina  Cancun Laguna Zona Hotelera",
      shortDescription:
        "Este hotel se encuentra en un lugar céntrico y tranquilo, enfrente de la laguna Nichupte. Queda a tan solo 10 minutos a pie de la playa y mucho más cerca de la zona de bares, restaurantes y tiendas. A",
      stars: "TwoAndAHalf",
      type: "Resort",
      address: "Cancun",
      photos: [
        {
          name: "Selina  Cancun Laguna Zona Hotelera - 1",
          url: "https://photos.hotelbeds.com/giata/00/008362/008362a_hb_ro_028.jpg",
        },
        {
          name: "Selina  Cancun Laguna Zona Hotelera - 2",
          url: "https://photos.hotelbeds.com/giata/00/008362/008362a_hb_ro_041.jpg",
        },
        {
          name: "Selina  Cancun Laguna Zona Hotelera - 3",
          url: "https://photos.hotelbeds.com/giata/00/008362/008362a_hb_r_003.jpg",
        },
        {
          name: "Selina  Cancun Laguna Zona Hotelera - 4",
          url: "https://photos.hotelbeds.com/giata/00/008362/008362a_hb_ro_023.jpg",
        },
        {
          name: "Selina  Cancun Laguna Zona Hotelera - 5",
          url: "https://photos.hotelbeds.com/giata/00/008362/008362a_hb_ro_040.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.169379,
        longitude: -86.844882,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.FH-BOOKABLE-NOR-True-AT_WEB-RO-GARRONRF",
            rateKey:
              "2022-11-17|2022-11-19|2|fa547dbd-a22d-425f-8447-1dc24553febd|HotelBeds|DBL.FH|BOOKABLE|NOR|RO|MXN|GARRONRF|3213.21|MX|638005925048471393;B0803E66BA72ACEA4EEA813BC9DAD119",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 3269.2144246125,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 10/11/2022 a las 4:59",
              "Puedes cancelar hasta el 13/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            offers: [
              {
                name: "Exclusive discount",
                total: 285.73309776,
              },
            ],
            nights: 2,
            pricePerNight: 1635,
          },
        ],
        id: "DBL.FH-BOOKABLE-NOR-True-AT_WEB-RO-GARRONRF",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: true,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 3269,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 10/11/2022 a las 4:59",
          "Puedes cancelar hasta el 13/11/2022 a las 4:59",
        ],
        offers: [
          {
            name: "Exclusive discount",
            total: 285.73309776,
          },
        ],
        totalDiscounted: 286,
        discountRate: 8,
        totalWithOffers: 3555,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1635,
      },
      amenities: ["Velásquez e Hijos", "Alva, Palomino and Prado"],
    },
    {
      id: "a1c086b3-47b0-4f4f-a4cc-68fd2148c754",
      externalId: "a1c086b3-47b0-4f4f-a4cc-68fd2148c754",
      name: "Sandos Cancun All Inclusive",
      shortDescription:
        "Ubicado en una de las playas de arena blanca más hermosas del mundo, Sandos Cancun es una propiedad Cuatro Diamantes orientada a adultos con todo incluido que consta de 214 habitaciones y suites con v",
      stars: "Five",
      type: "Hostal",
      address: "Cancun",
      photos: [
        {
          name: "Sandos Cancun All Inclusive - 1",
          url: "https://photos.hotelbeds.com/giata/00/006880/006880a_hb_ro_094.jpg",
        },
        {
          name: "Sandos Cancun All Inclusive - 2",
          url: "https://photos.hotelbeds.com/giata/00/006880/006880a_hb_ba_009.jpg",
        },
        {
          name: "Sandos Cancun All Inclusive - 3",
          url: "https://photos.hotelbeds.com/giata/00/006880/006880a_hb_ro_167.jpg",
        },
        {
          name: "Sandos Cancun All Inclusive - 4",
          url: "https://photos.hotelbeds.com/giata/00/006880/006880a_hb_r_019.jpg",
        },
        {
          name: "Sandos Cancun All Inclusive - 5",
          url: "https://photos.hotelbeds.com/giata/00/006880/006880a_hb_ro_067.JPG",
        },
      ],
      geoPosition: {
        latitude: 21.091524820937178,
        longitude: -86.76937081385404,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.SU-2-BOOKABLE-NOR-False-AT_WEB-AI-CGW-SELECT",
            rateKey:
              "2022-11-17|2022-11-19|2|a1c086b3-47b0-4f4f-a4cc-68fd2148c754|HotelBeds|DBL.SU-2|BOOKABLE|NOR|AI|MXN|CGW-SELECT|10104.83|MX|638005925048486106;1033BEE681566EDE6A7E73A718EF66BD",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 10160.831946825,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 12/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 5080,
          },
        ],
        id: "DBL.SU-2-BOOKABLE-NOR-False-AT_WEB-AI-CGW-SELECT",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 10161,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 12/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 10161,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 5080,
      },
      amenities: ["Cárdenas e Hijos", "Aponte, Chapa and Arce"],
    },
    {
      id: "ba61127e-a448-485f-904a-4cad211f17d2",
      externalId: "ba61127e-a448-485f-904a-4cad211f17d2",
      name: "Grand Park Royal Cancun",
      shortDescription:
        "Este hotel todo incluido es el lugar ideal para disfrutar de unas vacaciones de ensueño en un entorno paradisíaco, con vistas a espectaculares playas de arena blanca y aguas turquesas y a laguna de Ni",
      stars: "TwoAndAHalf",
      type: "Hostal",
      address: "Cancun",
      photos: [
        {
          name: "Grand Park Royal Cancun - 1",
          url: "https://photos.hotelbeds.com/giata/00/006881/006881a_hb_f_016.jpg",
        },
        {
          name: "Grand Park Royal Cancun - 2",
          url: "https://photos.hotelbeds.com/giata/00/006881/006881a_hb_p_014.jpg",
        },
        {
          name: "Grand Park Royal Cancun - 3",
          url: "https://photos.hotelbeds.com/giata/00/006881/006881a_hb_p_026.jpg",
        },
        {
          name: "Grand Park Royal Cancun - 4",
          url: "https://photos.hotelbeds.com/giata/00/006881/006881a_hb_a_026.jpg",
        },
        {
          name: "Grand Park Royal Cancun - 5",
          url: "https://photos.hotelbeds.com/giata/00/006881/006881a_hb_r_026.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.123445,
        longitude: -86.754114,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.LX-BOOKABLE-NOR-False-AT_WEB-AI-SPA-0",
            rateKey:
              "2022-11-17|2022-11-19|2|ba61127e-a448-485f-904a-4cad211f17d2|HotelBeds|DBL.LX|BOOKABLE|NOR|AI|MXN|SPA-0|15377.65|MX|638005925048492341;26278D7B4C9621427444561BD45BB658",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 15433.65446745,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 12/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 7717,
          },
        ],
        id: "DBL.LX-BOOKABLE-NOR-False-AT_WEB-AI-SPA-0",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 15434,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 12/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 15434,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 7717,
      },
      amenities: [
        "Gallardo S.L.",
        "Orellana S.L.",
        "Báez, Ortiz and Montañez",
        "Tamez e Hijos",
      ],
    },
    {
      id: "5698e78b-3304-44fa-a573-44efc0565a6f",
      externalId: "5698e78b-3304-44fa-a573-44efc0565a6f",
      name: "Temptation Cancun Resort - Adults Only",
      shortDescription:
        "Con una excelente ubicación frente al mar, Temptation Cancun Resort es un hotel Todo Incluido Sólo para Adultos mayores de 21 años, con un ambiente exclusivo, desinhibido y sensual. Es una atractiva a",
      stars: "OneAndAHalf",
      type: "Posada",
      address: "Cancun",
      photos: [
        {
          name: "Temptation Cancun Resort - Adults Only - 1",
          url: "https://photos.hotelbeds.com/giata/00/001918/001918a_hb_ro_013.jpg",
        },
        {
          name: "Temptation Cancun Resort - Adults Only - 2",
          url: "https://photos.hotelbeds.com/giata/00/001918/001918a_hb_p_001.jpeg",
        },
        {
          name: "Temptation Cancun Resort - Adults Only - 3",
          url: "https://photos.hotelbeds.com/giata/00/001918/001918a_hb_ro_016.jpg",
        },
        {
          name: "Temptation Cancun Resort - Adults Only - 4",
          url: "https://photos.hotelbeds.com/giata/00/001918/001918a_hb_w_001.jpg",
        },
        {
          name: "Temptation Cancun Resort - Adults Only - 5",
          url: "https://photos.hotelbeds.com/giata/00/001918/001918a_hb_ro_019.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.148108,
        longitude: -86.792109,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.GV-1-BOOKABLE-NOR-False-AT_WEB-AI-CG-MEXB2B",
            rateKey:
              "2022-11-17|2022-11-19|2|5698e78b-3304-44fa-a573-44efc0565a6f|HotelBeds|DBL.GV-1|BOOKABLE|NOR|AI|MXN|CG-MEXB2B|19153.05|MX|638005925048494441;6DD72D94AA2D45F28F2FE442812CF0C2",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 19209.046709225,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 03/10/2022 a las 3:59",
              "Puedes cancelar hasta el 06/10/2022 a las 3:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 9605,
          },
        ],
        id: "DBL.GV-1-BOOKABLE-NOR-False-AT_WEB-AI-CG-MEXB2B",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 19209,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 03/10/2022 a las 3:59",
          "Puedes cancelar hasta el 06/10/2022 a las 3:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 19209,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 9605,
      },
      amenities: [
        "Mesa - Gamboa",
        "Negrón e Hijos",
        "Meza, Guardado and Mascareñas",
        "Herrera - Carrero",
      ],
    },
    {
      id: "4bf69d5f-ae8f-40b0-8893-f6143b7d78b1",
      externalId: "4bf69d5f-ae8f-40b0-8893-f6143b7d78b1",
      name: "Sotavento",
      shortDescription:
        "Situado en el famoso resorte turístico de Cancún, este fabuloso hotel es un sitio ideal para desconectar por completo del día a día. Los viajeros se encontrarán entre unas impresionantes playas de are",
      stars: "Five",
      type: "Hostal",
      address: "Cancun",
      photos: [
        {
          name: "Sotavento - 1",
          url: "https://photos.hotelbeds.com/giata/05/055373/055373a_hb_p_009.jpg",
        },
        {
          name: "Sotavento - 2",
          url: "https://photos.hotelbeds.com/giata/05/055373/055373a_hb_k_002.jpg",
        },
        {
          name: "Sotavento - 3",
          url: "https://photos.hotelbeds.com/giata/05/055373/055373a_hb_ro_001.jpg",
        },
        {
          name: "Sotavento - 4",
          url: "https://photos.hotelbeds.com/giata/05/055373/055373a_hb_ro_018.jpg",
        },
        {
          name: "Sotavento - 5",
          url: "https://photos.hotelbeds.com/giata/05/055373/055373a_hb_l_007.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.144169,
        longitude: -86.790685,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-4B2B-RO",
            rateKey:
              "2022-11-17|2022-11-19|2|4bf69d5f-ae8f-40b0-8893-f6143b7d78b1|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|CGW-4B2B-RO|1956.72|MX|638005925048497827;C48053FD48AA3D1A91380AA00936123F",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2012.717495975,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 13/11/2022 a las 4:59",
              "Puedes cancelar hasta el 16/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 1006,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-4B2B-RO",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 2013,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 13/11/2022 a las 4:59",
          "Puedes cancelar hasta el 16/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 2013,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1006,
      },
      amenities: [
        "Patiño - Alba",
        "Valdés - Melgar",
        "Castañeda S.A.",
        "Pulido Hermanos",
      ],
    },
    {
      id: "22b19cd6-20fc-4b97-8708-a2d920a0c1d2",
      externalId: "22b19cd6-20fc-4b97-8708-a2d920a0c1d2",
      name: "Imperial las Perlas",
      shortDescription:
        "Este hotel familiar goza de una acertada ubicación a tan sólo 25 minutos en coche del aeropuerto internacional de Cancún y a 10 minutos de la principal zona comercial y de ocio nocturno. Con unas magn",
      stars: "Four",
      type: "Hotel",
      address: "Cancun",
      photos: [
        {
          name: "Imperial las Perlas - 1",
          url: "https://photos.hotelbeds.com/giata/13/134311/134311a_hb_l_001.jpg",
        },
        {
          name: "Imperial las Perlas - 2",
          url: "https://photos.hotelbeds.com/giata/13/134311/134311a_hb_ba_001.jpg",
        },
        {
          name: "Imperial las Perlas - 3",
          url: "https://photos.hotelbeds.com/giata/13/134311/134311a_hb_r_003.jpg",
        },
        {
          name: "Imperial las Perlas - 4",
          url: "https://photos.hotelbeds.com/giata/13/134311/134311a_hb_a_008.jpg",
        },
        {
          name: "Imperial las Perlas - 5",
          url: "https://photos.hotelbeds.com/giata/13/134311/134311a_hb_p_006.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.155781,
        longitude: -86.799629,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-MEXROB2B",
            rateKey:
              "2022-11-17|2022-11-19|2|22b19cd6-20fc-4b97-8708-a2d920a0c1d2|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|CGW-MEXROB2B|2325.69|MX|638005925048505433;2BCE7E668D2185798A028589B4B0C005",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2381.6867799,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 08/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
              "Puedes cancelar hasta el 11/11/2022 a las 4:59",
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 1191,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-MEXROB2B",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 2382,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 08/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
          "Puedes cancelar hasta el 11/11/2022 a las 4:59",
          "Puedes cancelar hasta el 18/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 2382,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1191,
      },
      amenities: [
        "Fernández, Mercado and Armijo",
        "Sarabia, Gamboa and Guajardo",
        "Alfaro Hermanos",
      ],
    },
    {
      id: "45f4ca52-703a-42b9-af2c-e85d9cc96f3c",
      externalId: "45f4ca52-703a-42b9-af2c-e85d9cc96f3c",
      name: "Aquamarina Beach",
      shortDescription:
        "Este encantador establecimiento goza de una ubicación exclusiva en la principal zona turística de Cancún, desde donde los huéspedes dispondrán de fácil acceso a los puertos deportivos, centros de nego",
      stars: "Three",
      type: "Posada",
      address: "Benito Juarez",
      photos: [
        {
          name: "Aquamarina Beach - 1",
          url: "https://photos.hotelbeds.com/giata/00/004001/004001a_hb_l_009.jpg",
        },
        {
          name: "Aquamarina Beach - 2",
          url: "https://photos.hotelbeds.com/giata/00/004001/004001a_hb_a_012.jpeg",
        },
        {
          name: "Aquamarina Beach - 3",
          url: "https://photos.hotelbeds.com/giata/00/004001/004001a_hb_l_010.jpg",
        },
        {
          name: "Aquamarina Beach - 4",
          url: "https://photos.hotelbeds.com/giata/00/004001/004001a_hb_ro_009.jpg",
        },
        {
          name: "Aquamarina Beach - 5",
          url: "https://photos.hotelbeds.com/giata/00/004001/004001a_hb_a_008.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.146455,
        longitude: -86.790548,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.VM-BOOKABLE-NOR-True-AT_WEB-RO-CGW-MEXB2BRO",
            rateKey:
              "2022-11-17|2022-11-19|2|45f4ca52-703a-42b9-af2c-e85d9cc96f3c|HotelBeds|DBL.VM|BOOKABLE|NOR|RO|MXN|CGW-MEXB2BRO|3247.85|MX|638005925048508156;6F0B98C9C81BF067327C16EF914610EC",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 3303.853404675,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 08/11/2022 a las 4:59",
              "Puedes cancelar hasta el 11/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            offers: [
              {
                name: "Exclusive discount",
                total: 137.11904404,
              },
            ],
            nights: 2,
            pricePerNight: 1652,
          },
        ],
        id: "DBL.VM-BOOKABLE-NOR-True-AT_WEB-RO-CGW-MEXB2BRO",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: true,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 3304,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 08/11/2022 a las 4:59",
          "Puedes cancelar hasta el 11/11/2022 a las 4:59",
        ],
        offers: [
          {
            name: "Exclusive discount",
            total: 137.11904404,
          },
        ],
        totalDiscounted: 137,
        discountRate: 4,
        totalWithOffers: 3441,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1652,
      },
      amenities: [
        "Zambrano Hermanos",
        "Porras - Ponce",
        "Argüello - Henríquez",
        "Tejada, Sedillo and Alaníz",
      ],
    },
    {
      id: "7b25fceb-7125-4e0f-bde0-8ec8de5837a4",
      externalId: "7b25fceb-7125-4e0f-bde0-8ec8de5837a4",
      name: "Casa Maya Cancun",
      shortDescription:
        "Situado en la animada zona turística de Cancún y frente al turquesa del Caribe Mexicano, este impresionante recinto en la playa es el lugar ideal para unas vacaciones llenas de diversión. Los huéspede",
      stars: "TwoAndAHalf",
      type: "Posada",
      address: "Cancun",
      photos: [
        {
          name: "Casa Maya Cancun - 1",
          url: "https://photos.hotelbeds.com/giata/00/008545/008545a_hb_p_008.JPG",
        },
        {
          name: "Casa Maya Cancun - 2",
          url: "https://photos.hotelbeds.com/giata/00/008545/008545a_hb_p_010.JPG",
        },
        {
          name: "Casa Maya Cancun - 3",
          url: "https://photos.hotelbeds.com/giata/00/008545/008545a_hb_a_002.jpg",
        },
        {
          name: "Casa Maya Cancun - 4",
          url: "https://photos.hotelbeds.com/giata/00/008545/008545a_hb_s_005.JPG",
        },
        {
          name: "Casa Maya Cancun - 5",
          url: "https://photos.hotelbeds.com/giata/00/008545/008545a_hb_a_006.JPG",
        },
      ],
      geoPosition: {
        latitude: 21.144825,
        longitude: -86.779425,
      },
      bestRate: {
        grouped: [
          {
            id: "STU.ST-BOOKABLE-NOR-False-AT_WEB-RO-HB-SELECTRO",
            rateKey:
              "2022-11-17|2022-11-19|2|7b25fceb-7125-4e0f-bde0-8ec8de5837a4|HotelBeds|STU.ST|BOOKABLE|NOR|RO|MXN|HB-SELECTRO|3687.13|MX|638005925048524015;F7ED4AA8222A4A9026F707DA1D9C570A",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 3743.126988875,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 14/11/2022 a las 4:59",
              "Puedes cancelar hasta el 17/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 1872,
          },
        ],
        id: "STU.ST-BOOKABLE-NOR-False-AT_WEB-RO-HB-SELECTRO",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 3743,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 14/11/2022 a las 4:59",
          "Puedes cancelar hasta el 17/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 3743,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1872,
      },
      amenities: [
        "Romero - Aguirre",
        "Quintero - Ramos",
        "Ocampo, Alejandro and Parra",
        "Toro - Frías",
      ],
    },
    {
      id: "0d329833-9cce-4a8c-bc00-387514a4a580",
      externalId: "0d329833-9cce-4a8c-bc00-387514a4a580",
      name: "Royal Solaris Cancun Resort All Inclusive",
      shortDescription:
        "Disfruta de tus vacaciones TODO INCLUIDO\nRoyal Solaris Cancún es un mágnifico hotel Todo incluido familiar con 300 habitaciones, con vista a la laguna Nichupté o al Mar Caribe, el complejo que tiene u",
      stars: "ThreeAndAHalf",
      type: "Posada",
      address: "Cancun",
      photos: [
        {
          name: "Royal Solaris Cancun Resort All Inclusive - 1",
          url: "https://photos.hotelbeds.com/giata/00/004718/004718a_hb_ro_075.jpg",
        },
        {
          name: "Royal Solaris Cancun Resort All Inclusive - 2",
          url: "https://photos.hotelbeds.com/giata/00/004718/004718a_hb_p_010.jpg",
        },
        {
          name: "Royal Solaris Cancun Resort All Inclusive - 3",
          url: "https://photos.hotelbeds.com/giata/00/004718/004718a_hb_f_014.JPG",
        },
        {
          name: "Royal Solaris Cancun Resort All Inclusive - 4",
          url: "https://photos.hotelbeds.com/giata/00/004718/004718a_hb_ro_050.jpg",
        },
        {
          name: "Royal Solaris Cancun Resort All Inclusive - 5",
          url: "https://photos.hotelbeds.com/giata/00/004718/004718a_hb_f_015.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.049499,
        longitude: -86.782498,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.DX-VL-BOOKABLE-NOR-False-AT_WEB-AI-ESP-FLEX",
            rateKey:
              "2022-11-17|2022-11-19|2|0d329833-9cce-4a8c-bc00-387514a4a580|HotelBeds|DBL.DX-VL|BOOKABLE|NOR|AI|MXN|ESP-FLEX|8830.12|MX|638005925048534011;E55B6CCD893E359775AB3C61345993ED",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 8886.117480525,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 12/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 4443,
          },
        ],
        id: "DBL.DX-VL-BOOKABLE-NOR-False-AT_WEB-AI-ESP-FLEX",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 8886,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 12/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 8886,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 4443,
      },
      amenities: [
        "Arteaga Hermanos",
        "Camacho S.L.",
        "Alonso - Reyna",
        "Lara, Abreu and Vergara",
      ],
    },
    {
      id: "b855c892-8c6d-4315-8044-434b77157cde",
      externalId: "b855c892-8c6d-4315-8044-434b77157cde",
      name: "Sun Palace All Inclusive",
      shortDescription:
        "El recién renovado y lujoso hotel Sun Palace, sólo para parejas, dispone de una ubicación exclusiva frente al mar, a un corto trayecto de los lugares más turísticos de Cancún. Ofrece habitaciones con ",
      stars: "Four",
      type: "Villa",
      address: "Cancun",
      photos: [
        {
          name: "Sun Palace All Inclusive - 1",
          url: "https://photos.hotelbeds.com/giata/00/005185/005185a_hb_f_001.jpg",
        },
        {
          name: "Sun Palace All Inclusive - 2",
          url: "https://photos.hotelbeds.com/giata/00/005185/005185a_hb_l_001.jpg",
        },
        {
          name: "Sun Palace All Inclusive - 3",
          url: "https://photos.hotelbeds.com/giata/00/005185/005185a_hb_ro_021.jpg",
        },
        {
          name: "Sun Palace All Inclusive - 4",
          url: "https://photos.hotelbeds.com/giata/00/005185/005185a_hb_p_001.jpg",
        },
        {
          name: "Sun Palace All Inclusive - 5",
          url: "https://photos.hotelbeds.com/giata/00/005185/005185a_hb_r_004.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.044302451732797,
        longitude: -86.78209700683848,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.DX-OV-SU-BOOKABLE-NRF-False-AT_WEB-AI-CGW-LATAMNRF",
            rateKey:
              "2022-11-17|2022-11-19|2|b855c892-8c6d-4315-8044-434b77157cde|HotelBeds|DBL.DX-OV-SU|BOOKABLE|NRF|AI|MXN|CGW-LATAMNRF|23779.79|MX|638005925048539964;4749F2A6537D59AB651094CBCBEEA49C",
            isRefundable: false,
            refundable: "No puedes cancelar ni realizar cambios",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 23835.788105425,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: ["Non-refundable rate. No amendments permitted"],
            cancellationPolicies: [],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 11918,
          },
        ],
        id: "DBL.DX-OV-SU-BOOKABLE-NRF-False-AT_WEB-AI-CGW-LATAMNRF",
        currency: "MXN",
        isRefundable: false,
        hasPromotions: true,
        hasOffers: false,
        hasCancellationPolicies: false,
        refundable: "No puedes cancelar ni realizar cambios",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 23836,
        promotions: ["Non-refundable rate. No amendments permitted"],
        cancellationPolicies: [],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 23836,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 11918,
      },
      amenities: ["Arce - Guerrero", "Gálvez - Loya", "Feliciano S.L."],
    },
    {
      id: "100d337b-aa46-4a4a-af36-e20db2ac1f71",
      externalId: "100d337b-aa46-4a4a-af36-e20db2ac1f71",
      name: "InterContinental Presidente Cancun Resort",
      shortDescription:
        "El Presidente Intercontinental Cancún Resort se encuentra a pocos pasos de mar, en la mejor paya e Cancún de arenas blancas. Las recién renovadas habitaciones, ofrecen vistas al Mar Caribe o a La Lagu",
      stars: "FourAndAHalf",
      type: "Resort",
      address: "Cancun",
      photos: [
        {
          name: "InterContinental Presidente Cancun Resort - 1",
          url: "https://photos.hotelbeds.com/giata/00/008242/008242a_hb_w_049.jpg",
        },
        {
          name: "InterContinental Presidente Cancun Resort - 2",
          url: "https://photos.hotelbeds.com/giata/00/008242/008242a_hb_ro_121.jpg",
        },
        {
          name: "InterContinental Presidente Cancun Resort - 3",
          url: "https://photos.hotelbeds.com/giata/00/008242/008242a_hb_w_041.jpg",
        },
        {
          name: "InterContinental Presidente Cancun Resort - 4",
          url: "https://photos.hotelbeds.com/giata/00/008242/008242a_hb_ro_170.jpg",
        },
        {
          name: "InterContinental Presidente Cancun Resort - 5",
          url: "https://photos.hotelbeds.com/giata/00/008242/008242a_hb_ro_038.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.135415,
        longitude: -86.754288,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.LX-BOOKABLE-NOR-False-AT_WEB-RO-FIT-MEXICO-RO",
            rateKey:
              "2022-11-17|2022-11-19|2|100d337b-aa46-4a4a-af36-e20db2ac1f71|HotelBeds|DBL.LX|BOOKABLE|NOR|RO|MXN|FIT-MEXICO-RO|9517.25|MX|638005925048557052;C1601C7D2A8E8D53B8D145830381EEF5",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 9573.25221095,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 12/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 4787,
          },
        ],
        id: "DBL.LX-BOOKABLE-NOR-False-AT_WEB-RO-FIT-MEXICO-RO",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 9573,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 12/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 9573,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 4787,
      },
      amenities: [
        "Alonzo - Elizondo",
        "Valverde - Jiménez",
        "Pabón, Arenas and Aguayo",
      ],
    },
    {
      id: "b14ac3d6-99d4-45c4-be39-feb841f49f0f",
      externalId: "b14ac3d6-99d4-45c4-be39-feb841f49f0f",
      name: "Omni Cancun Hotel & Villas",
      shortDescription:
        "Rodeado de jardines tropicales y con vistas a las aguas de azul turquesa del Caribe y a la apartada laguna de Nichupté, este magnífico hotel goza de una ubicación privilegiada frente al mar en la isla",
      stars: "Four",
      type: "Resort",
      address: "Cancun",
      photos: [
        {
          name: "Omni Cancun Hotel & Villas - 1",
          url: "https://photos.hotelbeds.com/giata/00/003999/003999a_hb_l_003.jpg",
        },
        {
          name: "Omni Cancun Hotel & Villas - 2",
          url: "https://photos.hotelbeds.com/giata/00/003999/003999a_hb_r_024.jpg",
        },
        {
          name: "Omni Cancun Hotel & Villas - 3",
          url: "https://photos.hotelbeds.com/giata/00/003999/003999a_hb_r_047.jpg",
        },
        {
          name: "Omni Cancun Hotel & Villas - 4",
          url: "https://photos.hotelbeds.com/giata/00/003999/003999a_hb_p_003.jpg",
        },
        {
          name: "Omni Cancun Hotel & Villas - 5",
          url: "https://photos.hotelbeds.com/giata/00/003999/003999a_hb_ro_030.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.074812,
        longitude: -86.775141,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.VL-BOOKABLE-NRF-False-AT_WEB-AI-OPQ-HBGSELECT",
            rateKey:
              "2022-11-17|2022-11-19|2|b14ac3d6-99d4-45c4-be39-feb841f49f0f|HotelBeds|DBL.VL|BOOKABLE|NRF|AI|MXN|OPQ-HBGSELECT|10008.36|MX|638005925048564814;A0957A5846A41EC2245C29AB06D5FE45",
            isRefundable: false,
            refundable: "No puedes cancelar ni realizar cambios",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 10064.355972725,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [
              "Non-refundable rate. No amendments permitted",
              "April Special",
            ],
            cancellationPolicies: [],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 5032,
          },
        ],
        id: "DBL.VL-BOOKABLE-NRF-False-AT_WEB-AI-OPQ-HBGSELECT",
        currency: "MXN",
        isRefundable: false,
        hasPromotions: true,
        hasOffers: false,
        hasCancellationPolicies: false,
        refundable: "No puedes cancelar ni realizar cambios",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 10064,
        promotions: [
          "Non-refundable rate. No amendments permitted",
          "April Special",
        ],
        cancellationPolicies: [],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 10064,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 5032,
      },
      amenities: [
        "Grijalva Hermanos",
        "Bañuelos, Toro and Rosales",
        "Godínez, Lugo and Mercado",
        "Lovato, Gutiérrez and Paz",
      ],
    },
    {
      id: "970212e2-3a6d-4ed5-8e30-7d0f46084a4d",
      externalId: "970212e2-3a6d-4ed5-8e30-7d0f46084a4d",
      name: "Solymar Beach Resort",
      shortDescription:
        "Este estiloso hotel ofrece una excelente localización en Cancún, presumiendo de la proximidad a la playa, un servicio óptimo y personalizado con precios muy económicos, hacen que es un lugar perfecto ",
      stars: "TwoAndAHalf",
      type: "Villa",
      address: "Cancun",
      photos: [
        {
          name: "Solymar Beach Resort - 1",
          url: "https://photos.hotelbeds.com/giata/00/007893/007893a_hb_ba_008.JPG",
        },
        {
          name: "Solymar Beach Resort - 2",
          url: "https://photos.hotelbeds.com/giata/00/007893/007893a_hb_r_014.JPG",
        },
        {
          name: "Solymar Beach Resort - 3",
          url: "https://photos.hotelbeds.com/giata/00/007893/007893a_hb_ro_027.JPG",
        },
        {
          name: "Solymar Beach Resort - 4",
          url: "https://photos.hotelbeds.com/giata/00/007893/007893a_hb_r_011.jpg",
        },
        {
          name: "Solymar Beach Resort - 5",
          url: "https://photos.hotelbeds.com/giata/00/007893/007893a_hb_s_007.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.052613,
        longitude: -86.782215,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-EP-B2BMXN",
            rateKey:
              "2022-11-17|2022-11-19|2|970212e2-3a6d-4ed5-8e30-7d0f46084a4d|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|EP-B2BMXN|4604.16|MX|638005925048565930;AE45B7CB5251B2A1513EC7A07ED17B6F",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 4660.1619129,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 08/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
              "Puedes cancelar hasta el 11/11/2022 a las 4:59",
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 2330,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-EP-B2BMXN",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 4660,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 08/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
          "Puedes cancelar hasta el 11/11/2022 a las 4:59",
          "Puedes cancelar hasta el 18/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 4660,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 2330,
      },
      amenities: [
        "Delgadillo - Reséndez",
        "Grijalva, Enríquez and Mejía",
        "Orta S.A.",
        "Negrón - Razo",
      ],
    },
    {
      id: "52679990-689b-4250-8a46-57e0a687cc30",
      externalId: "52679990-689b-4250-8a46-57e0a687cc30",
      name: "Flamingo Cancun Resort",
      shortDescription:
        "Flamingo Cancún Resort está situado en el km 11.5 de la zona hotelera de Cancún, frente al azul turquesa del Caribe Mexicano, a 25 minutos del Aeropuerto Internacional de Cancún y a 13 km de la centra",
      stars: "Four",
      type: "Hotel",
      address: "Cancun",
      photos: [
        {
          name: "Flamingo Cancun Resort - 1",
          url: "https://photos.hotelbeds.com/giata/00/007849/007849a_hb_f_004.jpg",
        },
        {
          name: "Flamingo Cancun Resort - 2",
          url: "https://photos.hotelbeds.com/giata/00/007849/007849a_hb_k_001.jpg",
        },
        {
          name: "Flamingo Cancun Resort - 3",
          url: "https://photos.hotelbeds.com/giata/00/007849/007849a_hb_ro_044.JPEG",
        },
        {
          name: "Flamingo Cancun Resort - 4",
          url: "https://photos.hotelbeds.com/giata/00/007849/007849a_hb_f_001.jpg",
        },
        {
          name: "Flamingo Cancun Resort - 5",
          url: "https://photos.hotelbeds.com/giata/00/007849/007849a_hb_r_009.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.117865,
        longitude: -86.756795,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-True-AT_WEB-RO-CGW-MEXEPB2B",
            rateKey:
              "2022-11-17|2022-11-19|2|52679990-689b-4250-8a46-57e0a687cc30|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|CGW-MEXEPB2B|5572.00|MX|638005925048571379;BFC62555007E42F1F18220FC50ED15AA",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 5628.00067435,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 08/11/2022 a las 4:59",
              "Puedes cancelar hasta el 11/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 2814,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-True-AT_WEB-RO-CGW-MEXEPB2B",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 5628,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 08/11/2022 a las 4:59",
          "Puedes cancelar hasta el 11/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 5628,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 2814,
      },
      amenities: [
        "Durán Hermanos",
        "Gómez - Quezada",
        "Garrido - Soto",
        "Camarillo - Orozco",
      ],
    },
    {
      id: "d8b3e4f4-9e61-4c06-b3e1-e2f51e428022",
      externalId: "d8b3e4f4-9e61-4c06-b3e1-e2f51e428022",
      name: "Park Royal Beach Cancun",
      shortDescription:
        "Park Royal Beach Cancún cuenta con una ubicación privilegiada en pleno centro de la zona hotelera. Desde sus instalaciones tendrás acceso a la zona arqueológica de Yamil Lu'um, un punto de observación",
      stars: "OneAndAHalf",
      type: "Hostal",
      address: "Cancun",
      photos: [
        {
          name: "Park Royal Beach Cancun - 1",
          url: "https://photos.hotelbeds.com/giata/00/001921/001921a_hb_ro_131.jpg",
        },
        {
          name: "Park Royal Beach Cancun - 2",
          url: "https://photos.hotelbeds.com/giata/00/001921/001921a_hb_f_014.jpg",
        },
        {
          name: "Park Royal Beach Cancun - 3",
          url: "https://photos.hotelbeds.com/giata/00/001921/001921a_hb_a_010.jpg",
        },
        {
          name: "Park Royal Beach Cancun - 4",
          url: "https://photos.hotelbeds.com/giata/00/001921/001921a_hb_r_010.jpg",
        },
        {
          name: "Park Royal Beach Cancun - 5",
          url: "https://photos.hotelbeds.com/giata/00/001921/001921a_hb_t_004.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.111247,
        longitude: -86.760863,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-AI-SPA-0",
            rateKey:
              "2022-11-17|2022-11-19|2|d8b3e4f4-9e61-4c06-b3e1-e2f51e428022|HotelBeds|DBL.ST|BOOKABLE|NOR|AI|MXN|SPA-0|12925.21|MX|638005925048596340;7087A904A1016BA685313CEA95DCF612",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 12981.214679025,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 12/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 6491,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-AI-SPA-0",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 12981,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 12/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 12981,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 6491,
      },
      amenities: [
        "Raya Hermanos",
        "Valadez, Ceballos and Pelayo",
        "Espinal S.A.",
        "Santiago e Hijos",
      ],
    },
    {
      id: "6865d415-7ee6-44fb-b958-18f7cf403207",
      externalId: "6865d415-7ee6-44fb-b958-18f7cf403207",
      name: "Paradisus Cancún All Inclusive",
      shortDescription:
        "Perfectamente ubicado frente a las playas de suave arena blanca de Cancún, este exclusivo complejo de playa te ofrece el alojamiento perfecto para tus vacaciones. Paradisus Cancún te brinda la oportun",
      stars: "Four",
      type: "Posada",
      address: "Cancun",
      photos: [
        {
          name: "Paradisus Cancún All Inclusive - 1",
          url: "https://photos.hotelbeds.com/giata/00/008152/008152a_hb_w_016.jpg",
        },
        {
          name: "Paradisus Cancún All Inclusive - 2",
          url: "https://photos.hotelbeds.com/giata/00/008152/008152a_hb_k_051.jpg",
        },
        {
          name: "Paradisus Cancún All Inclusive - 3",
          url: "https://photos.hotelbeds.com/giata/00/008152/008152a_hb_ro_046.jpg",
        },
        {
          name: "Paradisus Cancún All Inclusive - 4",
          url: "https://photos.hotelbeds.com/giata/00/008152/008152a_hb_f_031.jpg",
        },
        {
          name: "Paradisus Cancún All Inclusive - 5",
          url: "https://photos.hotelbeds.com/giata/00/008152/008152a_hb_ro_1419.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.0836784,
        longitude: -86.77211449999999,
      },
      bestRate: {
        grouped: [
          {
            id: "JSU.VL-1-BOOKABLE-NOR-True-AT_WEB-AI-FR4-FLEX",
            rateKey:
              "2022-11-17|2022-11-19|2|6865d415-7ee6-44fb-b958-18f7cf403207|HotelBeds|JSU.VL-1|BOOKABLE|NOR|AI|MXN|FR4-FLEX|11301.03|MX|638005925048597448;3B6101DB3B709D1C910ECAD4850AC7D7",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 11357.03139165,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 13/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
              "Puedes cancelar hasta el 16/11/2022 a las 4:59",
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 5679,
          },
        ],
        id: "JSU.VL-1-BOOKABLE-NOR-True-AT_WEB-AI-FR4-FLEX",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 11357,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 13/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
          "Puedes cancelar hasta el 16/11/2022 a las 4:59",
          "Puedes cancelar hasta el 18/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 11357,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 5679,
      },
      amenities: [
        "Coronado - Regalado",
        "Monroy Hermanos",
        "Sanabria, Montero and Mendoza",
      ],
    },
    {
      id: "05722caa-eba0-47fb-89cc-9528c5065466",
      externalId: "05722caa-eba0-47fb-89cc-9528c5065466",
      name: "Le Blanc Spa Resort All inclusive - Adults Only",
      shortDescription:
        "El galardonado Blanc Spa Palace, sólo para adultos, está considerado por muchos uno de los mejores hoteles del mundo. El mayor lujo y la elegancia se reúnen en una ubicación increíble, con más de 20.0",
      stars: "Four",
      type: "Posada",
      address: "Cancun",
      photos: [
        {
          name: "Le Blanc Spa Resort All inclusive - Adults Only - 1",
          url: "https://photos.hotelbeds.com/giata/00/003900/003900a_hb_ba_013.jpg",
        },
        {
          name: "Le Blanc Spa Resort All inclusive - Adults Only - 2",
          url: "https://photos.hotelbeds.com/giata/00/003900/003900a_hb_w_020.jpg",
        },
        {
          name: "Le Blanc Spa Resort All inclusive - Adults Only - 3",
          url: "https://photos.hotelbeds.com/giata/00/003900/003900a_hb_a_017.jpg",
        },
        {
          name: "Le Blanc Spa Resort All inclusive - Adults Only - 4",
          url: "https://photos.hotelbeds.com/giata/00/003900/003900a_hb_w_024.jpg",
        },
        {
          name: "Le Blanc Spa Resort All inclusive - Adults Only - 5",
          url: "https://photos.hotelbeds.com/giata/00/003900/003900a_hb_w_018.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.126054,
        longitude: -86.752938,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.DX-VL-BOOKABLE-NRF-False-AT_WEB-AI-CGW-MEX-NRF",
            rateKey:
              "2022-11-17|2022-11-19|2|05722caa-eba0-47fb-89cc-9528c5065466|HotelBeds|DBL.DX-VL|BOOKABLE|NRF|AI|MXN|CGW-MEX-NRF|40868.35|MX|638005925048704751;6B437ABD02EADC419F78C5960B254E34",
            isRefundable: false,
            refundable: "No puedes cancelar ni realizar cambios",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 40924.351602925,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: ["Non-refundable rate. No amendments permitted"],
            cancellationPolicies: [],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 20462,
          },
        ],
        id: "DBL.DX-VL-BOOKABLE-NRF-False-AT_WEB-AI-CGW-MEX-NRF",
        currency: "MXN",
        isRefundable: false,
        hasPromotions: true,
        hasOffers: false,
        hasCancellationPolicies: false,
        refundable: "No puedes cancelar ni realizar cambios",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 40924,
        promotions: ["Non-refundable rate. No amendments permitted"],
        cancellationPolicies: [],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 40924,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 20462,
      },
      amenities: ["Cuellar - Abrego", "Patiño S.A.", "Campos - Laboy"],
    },
    {
      id: "62d5d621-954e-42f4-b9f7-44538f6fc601",
      externalId: "62d5d621-954e-42f4-b9f7-44538f6fc601",
      name: "Oleo Cancun Playa",
      shortDescription:
        "Enclavado entre el mar y la laguna, frente a una de las mejores playas de Cancún, se encuentra Óleo Cancún Playa un moderno boutique resort con un concepto minimalista y vanguardista que invitan a la ",
      stars: "Two",
      type: "Villa",
      address: "Cancun",
      photos: [
        {
          name: "Oleo Cancun Playa - 1",
          url: "https://photos.hotelbeds.com/giata/00/003677/003677a_hb_ro_041.jpg",
        },
        {
          name: "Oleo Cancun Playa - 2",
          url: "https://photos.hotelbeds.com/giata/00/003677/003677a_hb_ro_055.jpg",
        },
        {
          name: "Oleo Cancun Playa - 3",
          url: "https://photos.hotelbeds.com/giata/00/003677/003677a_hb_s_008.jpg",
        },
        {
          name: "Oleo Cancun Playa - 4",
          url: "https://photos.hotelbeds.com/giata/00/003677/003677a_hb_p_004.jpg",
        },
        {
          name: "Oleo Cancun Playa - 5",
          url: "https://photos.hotelbeds.com/giata/00/003677/003677a_hb_ro_080.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.045689,
        longitude: -86.782652,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.KG-BOOKABLE-NOR-True-AT_WEB-AI-FIT-PROMOMEX",
            rateKey:
              "2022-11-17|2022-11-19|2|62d5d621-954e-42f4-b9f7-44538f6fc601|HotelBeds|DBL.KG|BOOKABLE|NOR|AI|MXN|FIT-PROMOMEX|10650.08|MX|638005925048722286;830E397BFA64636A0067BE9EB85CC706",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 10706.0751515125,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 08/11/2022 a las 4:59",
              "Puedes cancelar hasta el 11/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 5353,
          },
        ],
        id: "DBL.KG-BOOKABLE-NOR-True-AT_WEB-AI-FIT-PROMOMEX",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 10706,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 08/11/2022 a las 4:59",
          "Puedes cancelar hasta el 11/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 10706,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 5353,
      },
      amenities: ["Almonte, Godínez and Vega", "Gil - Jasso", "Monroy S.A."],
    },
    {
      id: "57a82660-a4e1-471c-bc07-fee711e8f4b4",
      externalId: "57a82660-a4e1-471c-bc07-fee711e8f4b4",
      name: "Beach Palace All Inclusive",
      shortDescription:
        "Este hotel está situado en la turística ciudad de Cancún, a poca distancia a pie del centro de la ciudad, donde podrá encontrar numerosos restaurantes, tiendas y locales de ocio nocturno. Justo fuera ",
      stars: "Four",
      type: "Posada",
      address: "Cancun",
      photos: [
        {
          name: "Beach Palace All Inclusive - 1",
          url: "https://photos.hotelbeds.com/giata/00/005183/005183a_hb_f_009.jpg",
        },
        {
          name: "Beach Palace All Inclusive - 2",
          url: "https://photos.hotelbeds.com/giata/00/005183/005183a_hb_f_003.jpg",
        },
        {
          name: "Beach Palace All Inclusive - 3",
          url: "https://photos.hotelbeds.com/giata/00/005183/005183a_hb_a_001.jpg",
        },
        {
          name: "Beach Palace All Inclusive - 4",
          url: "https://photos.hotelbeds.com/giata/00/005183/005183a_hb_p_003.jpg",
        },
        {
          name: "Beach Palace All Inclusive - 5",
          url: "https://photos.hotelbeds.com/giata/00/005183/005183a_hb_a_008.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.113756,
        longitude: -86.759217,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.DX-VL-BOOKABLE-NRF-False-AT_WEB-AI-CGW-MEXNRF",
            rateKey:
              "2022-11-17|2022-11-19|2|57a82660-a4e1-471c-bc07-fee711e8f4b4|HotelBeds|DBL.DX-VL|BOOKABLE|NRF|AI|MXN|CGW-MEXNRF|15267.07|MX|638005925048724015;EEBF5B6D8D286F716669B7D7AB7ABA28",
            isRefundable: false,
            refundable: "No puedes cancelar ni realizar cambios",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 15323.0663162875,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: ["Non-refundable rate. No amendments permitted"],
            cancellationPolicies: [],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 7662,
          },
        ],
        id: "DBL.DX-VL-BOOKABLE-NRF-False-AT_WEB-AI-CGW-MEXNRF",
        currency: "MXN",
        isRefundable: false,
        hasPromotions: true,
        hasOffers: false,
        hasCancellationPolicies: false,
        refundable: "No puedes cancelar ni realizar cambios",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 15323,
        promotions: ["Non-refundable rate. No amendments permitted"],
        cancellationPolicies: [],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 15323,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 7662,
      },
      amenities: [
        "Rosado, Alcaraz and Anaya",
        "Jasso - Delacrúz",
        "Gámez - Cisneros",
        "Ibarra - Guevara",
      ],
    },
    {
      id: "4368a751-5dc3-4dd3-86ef-bdda658a4984",
      externalId: "4368a751-5dc3-4dd3-86ef-bdda658a4984",
      name: "Emporio Cancun",
      shortDescription:
        "El Hotel se localiza sobre una extensa playa en el corazón de la zona hotelera, a  15 min del Aeropuerto Internacional y cercano a las principales áreas comerciales, turísticas y de entretenimiento de",
      stars: "ThreeAndAHalf",
      type: "Hotel",
      address: "Cancun",
      photos: [
        {
          name: "Emporio Cancun - 1",
          url: "https://photos.hotelbeds.com/giata/07/073481/073481a_hb_r_004.jpg",
        },
        {
          name: "Emporio Cancun - 2",
          url: "https://photos.hotelbeds.com/giata/07/073481/073481a_hb_ba_002.jpg",
        },
        {
          name: "Emporio Cancun - 3",
          url: "https://photos.hotelbeds.com/giata/07/073481/073481a_hb_ro_090.jpg",
        },
        {
          name: "Emporio Cancun - 4",
          url: "https://photos.hotelbeds.com/giata/07/073481/073481a_hb_ro_080.jpg",
        },
        {
          name: "Emporio Cancun - 5",
          url: "https://photos.hotelbeds.com/giata/07/073481/073481a_hb_r_007.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.073419,
        longitude: -86.775762,
      },
      bestRate: {
        grouped: [
          {
            id: "SUI.DX-VM-BOOKABLE-NOR-True-AT_WEB-RO-PROMO-MXN",
            rateKey:
              "2022-11-17|2022-11-19|2|4368a751-5dc3-4dd3-86ef-bdda658a4984|HotelBeds|SUI.DX-VM|BOOKABLE|NOR|RO|MXN|PROMO-MXN|7625.71|MX|638005925048725495;91AD10F7731AEB13DC29E640078438A6",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 7681.7073145,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 13/11/2022 a las 4:59",
              "Puedes cancelar hasta el 16/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            offers: [
              {
                name: "Special discount",
                total: 6609.22002994,
              },
            ],
            nights: 2,
            pricePerNight: 3841,
          },
        ],
        id: "SUI.DX-VM-BOOKABLE-NOR-True-AT_WEB-RO-PROMO-MXN",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: true,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 7682,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 13/11/2022 a las 4:59",
          "Puedes cancelar hasta el 16/11/2022 a las 4:59",
        ],
        offers: [
          {
            name: "Special discount",
            total: 6609.22002994,
          },
        ],
        totalDiscounted: 6609,
        discountRate: 46,
        totalWithOffers: 14291,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 3841,
      },
      amenities: [
        "Arenas e Hijos",
        "Malave, Ocasio and Nájera",
        "Altamirano - Sierra",
        "Báez, Mercado and Duarte",
      ],
    },
    {
      id: "214f100c-3d85-4953-8f59-6319b2484368",
      externalId: "214f100c-3d85-4953-8f59-6319b2484368",
      name: "Krystal Cancun",
      shortDescription:
        "Este fantástico hotel, moderno y luminoso, y con unas vistas panorámicas del Mar Caribe, cuenta con una excelente situación directamente sobre la playa y su arena blanca. Es ideal tanto para viajeros ",
      stars: "OneAndAHalf",
      type: "Hostal",
      address: "Cancun",
      photos: [
        {
          name: "Krystal Cancun - 1",
          url: "https://photos.hotelbeds.com/giata/13/130214/130214a_hb_ro_007.jpg",
        },
        {
          name: "Krystal Cancun - 2",
          url: "https://photos.hotelbeds.com/giata/13/130214/130214a_hb_ro_014.jpg",
        },
        {
          name: "Krystal Cancun - 3",
          url: "https://photos.hotelbeds.com/giata/13/130214/130214a_hb_ro_033.jpg",
        },
        {
          name: "Krystal Cancun - 4",
          url: "https://photos.hotelbeds.com/giata/13/130214/130214a_hb_r_007.jpg",
        },
        {
          name: "Krystal Cancun - 5",
          url: "https://photos.hotelbeds.com/giata/13/130214/130214a_hb_r_009.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.134293,
        longitude: -86.744475,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.2B-1-BOOKABLE-NOR-False-AT_WEB-RO-SPACOMMNEP",
            rateKey:
              "2022-11-17|2022-11-19|2|214f100c-3d85-4953-8f59-6319b2484368|HotelBeds|DBL.2B-1|BOOKABLE|NOR|RO|MXN|SPACOMMNEP|4601.08|MX|638005925048740087;7605924E5E77404CC2FA13E85C0EE987",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 4657.08289245,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 12/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            offers: [
              {
                name: "Special discount",
                total: 409.30445182,
              },
            ],
            nights: 2,
            pricePerNight: 2329,
          },
        ],
        id: "DBL.2B-1-BOOKABLE-NOR-False-AT_WEB-RO-SPACOMMNEP",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: true,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 4657,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 12/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
        ],
        offers: [
          {
            name: "Special discount",
            total: 409.30445182,
          },
        ],
        totalDiscounted: 409,
        discountRate: 8,
        totalWithOffers: 5066,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 2329,
      },
      amenities: ["Muñiz S.A.", "Haro, Macías and Gaytán"],
    },
    {
      id: "e3bb9886-ea0e-4ad5-b890-843951867486",
      externalId: "e3bb9886-ea0e-4ad5-b890-843951867486",
      name: "Krystal Grand Cancun",
      shortDescription:
        "Situado en un espacio de arena blanca frente a la playa, con extraordinarias vistas del increíble azul turquesa del Caribe, Krystal Grand Cancún invita a amigos, familias y parejas a una vibrante esca",
      stars: "Five",
      type: "Posada",
      address: "Cancun",
      photos: [
        {
          name: "Krystal Grand Cancun - 1",
          url: "https://photos.hotelbeds.com/giata/00/008387/008387a_hb_r_004.jpg",
        },
        {
          name: "Krystal Grand Cancun - 2",
          url: "https://photos.hotelbeds.com/giata/00/008387/008387a_hb_ro_018.JPEG",
        },
        {
          name: "Krystal Grand Cancun - 3",
          url: "https://photos.hotelbeds.com/giata/00/008387/008387a_hb_s_013.jpg",
        },
        {
          name: "Krystal Grand Cancun - 4",
          url: "https://photos.hotelbeds.com/giata/00/008387/008387a_hb_ba_002.jpg",
        },
        {
          name: "Krystal Grand Cancun - 5",
          url: "https://photos.hotelbeds.com/giata/00/008387/008387a_hb_ro_017.JPEG",
        },
      ],
      geoPosition: {
        latitude: 21.134376,
        longitude: -86.743268,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.2D-DX-BOOKABLE-NOR-True-AT_WEB-RO-SPACOMMXNEP",
            rateKey:
              "2022-11-17|2022-11-19|2|e3bb9886-ea0e-4ad5-b890-843951867486|HotelBeds|DBL.2D-DX|BOOKABLE|NOR|RO|MXN|SPACOMMXNEP|7524.61|MX|638005925048752631;57CE6BE57E0AEC3922C399E2764AD27C",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 7580.612809725,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 13/11/2022 a las 4:59",
              "Puedes cancelar hasta el 16/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            offers: [
              {
                name: "Exclusive discount",
                total: 669.1737778,
              },
            ],
            nights: 2,
            pricePerNight: 3790,
          },
        ],
        id: "DBL.2D-DX-BOOKABLE-NOR-True-AT_WEB-RO-SPACOMMXNEP",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: true,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 7581,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 13/11/2022 a las 4:59",
          "Puedes cancelar hasta el 16/11/2022 a las 4:59",
        ],
        offers: [
          {
            name: "Exclusive discount",
            total: 669.1737778,
          },
        ],
        totalDiscounted: 669,
        discountRate: 8,
        totalWithOffers: 8250,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 3790,
      },
      amenities: ["Sarabia, Laboy and Zapata", "Vera - Arenas"],
    },
    {
      id: "b5290054-3450-4cd4-a2b8-6555dda7ff23",
      externalId: "b5290054-3450-4cd4-a2b8-6555dda7ff23",
      name: "GR Solaris Cancun All Inclusive",
      shortDescription:
        "Disfruta de tus vacaciones TODO INCLUIDO\nGR Solaris redefine el lujo con sus cómodas habitaciones, spa y cocina inspiradora. Como lo marca su concepto:\n\n—Es una Ventana al Mar Caribe—\n\n¿Esperaste much",
      stars: "Three",
      type: "Resort",
      address: "Cancun",
      photos: [
        {
          name: "GR Solaris Cancun All Inclusive - 1",
          url: "https://photos.hotelbeds.com/giata/01/017056/017056a_hb_a_053.jpg",
        },
        {
          name: "GR Solaris Cancun All Inclusive - 2",
          url: "https://photos.hotelbeds.com/giata/01/017056/017056a_hb_r_010.jpg",
        },
        {
          name: "GR Solaris Cancun All Inclusive - 3",
          url: "https://photos.hotelbeds.com/giata/01/017056/017056a_hb_a_057.jpg",
        },
        {
          name: "GR Solaris Cancun All Inclusive - 4",
          url: "https://photos.hotelbeds.com/giata/01/017056/017056a_hb_r_013.JPG",
        },
        {
          name: "GR Solaris Cancun All Inclusive - 5",
          url: "https://photos.hotelbeds.com/giata/01/017056/017056a_hb_k_002.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.054807,
        longitude: -86.781492,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.DX-BOOKABLE-NOR-False-AT_WEB-AI-ESP-FLEX",
            rateKey:
              "2022-11-17|2022-11-19|2|b5290054-3450-4cd4-a2b8-6555dda7ff23|HotelBeds|DBL.DX|BOOKABLE|NOR|AI|MXN|ESP-FLEX|7832.51|MX|638005925048774500;C00F8D594FDC4652A861B7F339C9C72D",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 7888.514854725,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 12/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 3944,
          },
        ],
        id: "DBL.DX-BOOKABLE-NOR-False-AT_WEB-AI-ESP-FLEX",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 7889,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 12/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 7889,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 3944,
      },
      amenities: ["Leiva, Sáenz and Muñoz", "Lozada S.L."],
    },
    {
      id: "25653081-9f33-4321-ad73-feeec42a700d",
      externalId: "25653081-9f33-4321-ad73-feeec42a700d",
      name: "Ocean Spa Hotel All Inclusive",
      shortDescription:
        "Este lujoso complejo goza de una ubicación privilegiada a solo 10 minutos del centro de Cancún, ofreciendo la opción perfecta para practicar deportes acuáticos debido a su situación privilegiada frent",
      stars: "FourAndAHalf",
      type: "Hostal",
      address: "Cancun",
      photos: [
        {
          name: "Ocean Spa Hotel All Inclusive - 1",
          url: "https://photos.hotelbeds.com/giata/01/018920/018920a_hb_t_002.jpg",
        },
        {
          name: "Ocean Spa Hotel All Inclusive - 2",
          url: "https://photos.hotelbeds.com/giata/01/018920/018920a_hb_l_001.jpg",
        },
        {
          name: "Ocean Spa Hotel All Inclusive - 3",
          url: "https://photos.hotelbeds.com/giata/01/018920/018920a_hb_r_010.jpg",
        },
        {
          name: "Ocean Spa Hotel All Inclusive - 4",
          url: "https://photos.hotelbeds.com/giata/01/018920/018920a_hb_l_004.jpg",
        },
        {
          name: "Ocean Spa Hotel All Inclusive - 5",
          url: "https://photos.hotelbeds.com/giata/01/018920/018920a_hb_r_007.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.146683,
        longitude: -86.791398,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-AI-CGW-MEXB2B",
            rateKey:
              "2022-11-17|2022-11-19|2|25653081-9f33-4321-ad73-feeec42a700d|HotelBeds|DBL.ST|BOOKABLE|NOR|AI|MXN|CGW-MEXB2B|8620.23|MX|638005925048777563;B3738BEC8F7BF6916FBF8369A051FBA5",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 8881.33185401,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 03/10/2022 a las 3:59",
              "Puedes cancelar hasta el 06/10/2022 a las 3:59",
            ],
            taxes: [
              {
                included: false,
                total: 261.10093416,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 4441,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-AI-CGW-MEXB2B",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 8881,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 03/10/2022 a las 3:59",
          "Puedes cancelar hasta el 06/10/2022 a las 3:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 8881,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 4441,
      },
      amenities: [
        "Montenegro, Carrasquillo and Vargas",
        "Pagan e Hijos",
        "Sisneros, Treviño and Terrazas",
        "Roybal - Coronado",
      ],
    },
    {
      id: "f80e0265-df10-4411-ab20-770d1e7e4c0b",
      externalId: "f80e0265-df10-4411-ab20-770d1e7e4c0b",
      name: "City Express Cancun",
      shortDescription:
        "Este hotel se encuentra en medio del esplendor tropical de Cancún. Está a solo 10 minutos en coche de la estación de autobuses, el centro de la ciudad y el Puerto de Cancún. Los huéspedes podrán disfr",
      stars: "Two",
      type: "Posada",
      address: "Cancun",
      photos: [
        {
          name: "City Express Cancun - 1",
          url: "https://photos.hotelbeds.com/giata/07/075080/075080a_hb_ro_001.jpg",
        },
        {
          name: "City Express Cancun - 2",
          url: "https://photos.hotelbeds.com/giata/07/075080/075080a_hb_ro_002.jpg",
        },
        {
          name: "City Express Cancun - 3",
          url: "https://photos.hotelbeds.com/giata/07/075080/075080a_hb_a_001.jpg",
        },
        {
          name: "City Express Cancun - 4",
          url: "https://photos.hotelbeds.com/giata/07/075080/075080a_hb_k_002.jpg",
        },
        {
          name: "City Express Cancun - 5",
          url: "https://photos.hotelbeds.com/giata/07/075080/075080a_hb_k_001.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.143553,
        longitude: -86.82261,
      },
      bestRate: {
        grouped: [
          {
            id: "SGL.ST-BOOKABLE-NOR-False-AT_WEB-AB-PQ-TODOS",
            rateKey:
              "2022-11-17|2022-11-19|2|f80e0265-df10-4411-ab20-770d1e7e4c0b|HotelBeds|SGL.ST|BOOKABLE|NOR|AB|MXN|PQ-TODOS|3724.84|MX|638005925048779531;E07246C6674873613997EC5D7F427499",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "American Breakfast",
            mealPlanCode: "AB",
            total: 3780.8449893875,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 13/11/2022 a las 4:59",
              "Puedes cancelar hasta el 16/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            offers: [
              {
                name: "Early Booking discount",
                total: 840.57258285,
              },
            ],
            nights: 2,
            pricePerNight: 1890,
          },
        ],
        id: "SGL.ST-BOOKABLE-NOR-False-AT_WEB-AB-PQ-TODOS",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: true,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "American Breakfast",
        mealPlanCode: "AB",
        total: 3781,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 13/11/2022 a las 4:59",
          "Puedes cancelar hasta el 16/11/2022 a las 4:59",
        ],
        offers: [
          {
            name: "Early Booking discount",
            total: 840.57258285,
          },
        ],
        totalDiscounted: 841,
        discountRate: 18,
        totalWithOffers: 4622,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1890,
      },
      amenities: [
        "Guajardo - Villaseñor",
        "Corrales - Balderas",
        "Rosales S.L.",
        "Urías, Galindo and Berríos",
      ],
    },
    {
      id: "e4ef668f-7b42-43a9-a97b-619b5f7e98d4",
      externalId: "e4ef668f-7b42-43a9-a97b-619b5f7e98d4",
      name: "NYX Cancun",
      shortDescription:
        "Descubra la magia de Cancún en el HOTEL NYX CANCUN. Vivirá en el corazón de la mejor zona de vacaciones en el Caribe con spa, restaurantes y una atención personalizada. ¡Sumérjase en la verdadera esen",
      stars: "FourAndAHalf",
      type: "Villa",
      address: "Cancun",
      photos: [
        {
          name: "NYX Cancun - 1",
          url: "https://photos.hotelbeds.com/giata/00/008384/008384a_hb_a_040.jpg",
        },
        {
          name: "NYX Cancun - 2",
          url: "https://photos.hotelbeds.com/giata/00/008384/008384a_hb_f_004.jpg",
        },
        {
          name: "NYX Cancun - 3",
          url: "https://photos.hotelbeds.com/giata/00/008384/008384a_hb_ro_105.jpg",
        },
        {
          name: "NYX Cancun - 4",
          url: "https://photos.hotelbeds.com/giata/00/008384/008384a_hb_k_001.jpg",
        },
        {
          name: "NYX Cancun - 5",
          url: "https://photos.hotelbeds.com/giata/00/008384/008384a_hb_r_012.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.114319,
        longitude: -86.758995,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-BB-FITMXDB1",
            rateKey:
              "2022-11-17|2022-11-19|2|e4ef668f-7b42-43a9-a97b-619b5f7e98d4|HotelBeds|DBL.ST|BOOKABLE|NOR|BB|MXN|FITMXDB1|5046.51|MX|638005925048784908;637035406D95A4BA9E5311D02D3E1E6D",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Bed And Breakfast",
            mealPlanCode: "BB",
            total: 5102.51451755,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 12/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 2551,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-BB-FITMXDB1",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Bed And Breakfast",
        mealPlanCode: "BB",
        total: 5103,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 12/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 5103,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 2551,
      },
      amenities: [
        "Rojas, Tejeda and Feliciano",
        "Fuentes, Laboy and Madrid",
        "Fajardo - Aguilar",
      ],
    },
    {
      id: "ae2dc778-06b1-4a3f-a9fd-34cb163368ff",
      externalId: "ae2dc778-06b1-4a3f-a9fd-34cb163368ff",
      name: "Cancun Bay Resort",
      shortDescription:
        "Es un hotel ubicado frente a la Bahía de Mujeres, sobre una hermosa playa bañada por el mar del Caribe Mexicano, por su excelente ubicación y a tan solo 3.5 km del centro de Cancún, es ideal para todo",
      stars: "Two",
      type: "Villa",
      address: "Cancun",
      photos: [
        {
          name: "Cancun Bay Resort - 1",
          url: "https://photos.hotelbeds.com/giata/00/003688/003688a_hb_l_014.JPG",
        },
        {
          name: "Cancun Bay Resort - 2",
          url: "https://photos.hotelbeds.com/giata/00/003688/003688a_hb_ba_001.JPG",
        },
        {
          name: "Cancun Bay Resort - 3",
          url: "https://photos.hotelbeds.com/giata/00/003688/003688a_hb_ro_038.jpg",
        },
        {
          name: "Cancun Bay Resort - 4",
          url: "https://photos.hotelbeds.com/giata/00/003688/003688a_hb_ro_034.jpg",
        },
        {
          name: "Cancun Bay Resort - 5",
          url: "https://photos.hotelbeds.com/giata/00/003688/003688a_hb_r_009.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.149608,
        longitude: -86.793232,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-AI-NRF-MEXB2BAI",
            rateKey:
              "2022-11-17|2022-11-19|2|ae2dc778-06b1-4a3f-a9fd-34cb163368ff|HotelBeds|DBL.ST|BOOKABLE|NRF|AI|MXN|NRF-MEXB2BAI|13797.60|MX|638005925048794577;CF5E632CE058F45BC758A0C944A7176F",
            isRefundable: false,
            refundable: "No puedes cancelar ni realizar cambios",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 13853.603806525,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: ["Non-refundable rate. No amendments permitted"],
            cancellationPolicies: [],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 6927,
          },
        ],
        id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-AI-NRF-MEXB2BAI",
        currency: "MXN",
        isRefundable: false,
        hasPromotions: true,
        hasOffers: false,
        hasCancellationPolicies: false,
        refundable: "No puedes cancelar ni realizar cambios",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 13854,
        promotions: ["Non-refundable rate. No amendments permitted"],
        cancellationPolicies: [],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 13854,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 6927,
      },
      amenities: ["Madera - Gastélum", "Trujillo - Ordóñez"],
    },
    {
      id: "d2f75e9b-af54-4abb-b8d6-afe0b71195a9",
      externalId: "d2f75e9b-af54-4abb-b8d6-afe0b71195a9",
      name: "Soberanis",
      shortDescription:
        "El hotel se encuentra en una zona céntrica de Cancún, a tan sólo 20 metros de la avenida Tulúm y a 1,5 kilómetros de una playa maravillosa. Es uno de los primeros hoteles que se construyeron en la ciu",
      stars: "TwoAndAHalf",
      type: "Resort",
      address: "Cancun",
      photos: [
        {
          name: "Soberanis - 1",
          url: "https://photos.hotelbeds.com/giata/03/034963/034963a_hb_a_001.jpg",
        },
        {
          name: "Soberanis - 2",
          url: "https://photos.hotelbeds.com/giata/03/034963/034963a_hb_w_008.jpg",
        },
        {
          name: "Soberanis - 3",
          url: "https://photos.hotelbeds.com/giata/03/034963/034963a_hb_ro_003.jpg",
        },
        {
          name: "Soberanis - 4",
          url: "https://photos.hotelbeds.com/giata/03/034963/034963a_hb_w_003.jpg",
        },
        {
          name: "Soberanis - 5",
          url: "https://photos.hotelbeds.com/giata/03/034963/034963a_hb_k_010.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.157753,
        longitude: -86.82645,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-MXNB2B",
            rateKey:
              "2022-11-17|2022-11-19|2|d2f75e9b-af54-4abb-b8d6-afe0b71195a9|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|CGW-MXNB2B|1694.49|MX|638005925048801357;702F3EF77F7C511878DAA45628C9B38F",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 1750.48758765,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 08/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
              "Puedes cancelar hasta el 11/11/2022 a las 4:59",
              "Puedes cancelar hasta el 18/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 875,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-MXNB2B",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 1750,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 08/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
          "Puedes cancelar hasta el 11/11/2022 a las 4:59",
          "Puedes cancelar hasta el 18/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 1750,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 875,
      },
      amenities: [
        "Barrios, Arredondo and Espinal",
        "Escalante e Hijos",
        "Solís - Cepeda",
      ],
    },
    {
      id: "3de4f910-0f48-475e-a495-73c339b2d93b",
      externalId: "3de4f910-0f48-475e-a495-73c339b2d93b",
      name: "All Ritmo Cancun Resort & Water Park",
      shortDescription:
        "Con una ubicación privilegiada en un bonito rincón caribeño, este encantador complejo es la elección perfecta para familias que viajan con niños. Sus huéspedes estarán alojados a sólo 22 kilómetros de",
      stars: "FourAndAHalf",
      type: "Posada",
      address: "Cancún",
      photos: [
        {
          name: "All Ritmo Cancun Resort & Water Park - 1",
          url: "https://photos.hotelbeds.com/giata/00/008011/008011a_hb_l_017.jpg",
        },
        {
          name: "All Ritmo Cancun Resort & Water Park - 2",
          url: "https://photos.hotelbeds.com/giata/00/008011/008011a_hb_r_009.jpg",
        },
        {
          name: "All Ritmo Cancun Resort & Water Park - 3",
          url: "https://photos.hotelbeds.com/giata/00/008011/008011a_hb_ro_123.JPG",
        },
        {
          name: "All Ritmo Cancun Resort & Water Park - 4",
          url: "https://photos.hotelbeds.com/giata/00/008011/008011a_hb_ro_034.jpg",
        },
        {
          name: "All Ritmo Cancun Resort & Water Park - 5",
          url: "https://photos.hotelbeds.com/giata/00/008011/008011a_hb_ro_044.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.201725,
        longitude: -86.805275,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-AI-CGW-MEX",
            rateKey:
              "2022-11-17|2022-11-19|2|3de4f910-0f48-475e-a495-73c339b2d93b|HotelBeds|DBL.ST|BOOKABLE|NOR|AI|MXN|CGW-MEX|10388.61|MX|638005925048801550;7C3A652D3A8B9481D16BE0D07D38FDDC",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 10444.6149983,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 08/11/2022 a las 4:59",
              "Puedes cancelar hasta el 11/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 5222,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-AI-CGW-MEX",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 10445,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 08/11/2022 a las 4:59",
          "Puedes cancelar hasta el 11/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 10445,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 5222,
      },
      amenities: [
        "Pedraza, Colunga and Villanueva",
        "Sarabia Hermanos",
        "Holguín, Montalvo and Córdova",
        "Piña - Delvalle",
      ],
    },
    {
      id: "40198cd1-fb21-439d-9f99-bcd6dd713a03",
      externalId: "40198cd1-fb21-439d-9f99-bcd6dd713a03",
      name: "Calypso Hotel Cancun",
      shortDescription:
        "Ubicado en el barrio de ocio de Cancún, entre la laguna Nichupte y el Caribe, este hotel es una gran opción para unas emocionantes vacaciones. Sus huéspedes estarán a solo 20 minutos a pie del centro ",
      stars: "Four",
      type: "Villa",
      address: "Cancun",
      photos: [
        {
          name: "Calypso Hotel Cancun - 1",
          url: "https://photos.hotelbeds.com/giata/00/006885/006885a_hb_a_004.jpg",
        },
        {
          name: "Calypso Hotel Cancun - 2",
          url: "https://photos.hotelbeds.com/giata/00/006885/006885a_hb_a_003.jpg",
        },
        {
          name: "Calypso Hotel Cancun - 3",
          url: "https://photos.hotelbeds.com/giata/00/006885/006885a_hb_ro_011.jpg",
        },
        {
          name: "Calypso Hotel Cancun - 4",
          url: "https://photos.hotelbeds.com/giata/00/006885/006885a_hb_a_006.jpg",
        },
        {
          name: "Calypso Hotel Cancun - 5",
          url: "https://photos.hotelbeds.com/giata/00/006885/006885a_hb_r_002.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.143892,
        longitude: -86.79007899999999,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-True-AT_WEB-RO-FITROTODOS",
            rateKey:
              "2022-11-17|2022-11-19|2|40198cd1-fb21-439d-9f99-bcd6dd713a03|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|FITROTODOS|2710.05|MX|638005925048808803;9638E71512DA2D52C145CC2F0961B680",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2766.051166075,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 13/11/2022 a las 4:59",
              "Puedes cancelar hasta el 16/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 1383,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-True-AT_WEB-RO-FITROTODOS",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 2766,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 13/11/2022 a las 4:59",
          "Puedes cancelar hasta el 16/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 2766,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1383,
      },
      amenities: ["Jurado, Medrano and Mata", "Lovato e Hijos"],
    },
    {
      id: "26c0ccaa-b363-42dc-87ce-e62b0d2b7387",
      externalId: "26c0ccaa-b363-42dc-87ce-e62b0d2b7387",
      name: "Adhara Hacienda Cancun",
      shortDescription:
        "Situado en el corazón de Cancún, este hotel de propiedad familiar está a 5 minutos a pie de la Avenida de Tulúm, famosa por sus mercados de artesanía, sus prestigiosas tiendas, restaurantes, bancos y ",
      stars: "Two",
      type: "Resort",
      address: "Cancun",
      photos: [
        {
          name: "Adhara Hacienda Cancun - 1",
          url: "https://photos.hotelbeds.com/giata/00/003898/003898a_hb_l_909.JPG",
        },
        {
          name: "Adhara Hacienda Cancun - 2",
          url: "https://photos.hotelbeds.com/giata/00/003898/003898a_hb_r_018.JPG",
        },
        {
          name: "Adhara Hacienda Cancun - 3",
          url: "https://photos.hotelbeds.com/giata/00/003898/003898a_hb_w_004.jpg",
        },
        {
          name: "Adhara Hacienda Cancun - 4",
          url: "https://photos.hotelbeds.com/giata/00/003898/003898a_hb_ro_015.jpg",
        },
        {
          name: "Adhara Hacienda Cancun - 5",
          url: "https://photos.hotelbeds.com/giata/00/003898/003898a_hb_f_906.JPG",
        },
      ],
      geoPosition: {
        latitude: 21.168371,
        longitude: -86.824028,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-True-AT_WEB-RO-SPA-COM-PQ",
            rateKey:
              "2022-11-17|2022-11-19|2|26c0ccaa-b363-42dc-87ce-e62b0d2b7387|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|SPA-COM-PQ|2744.95|MX|638005925048810823;A885FA47D8EBFDA7D79968BCC70614DA",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2800.946731175,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 10/11/2022 a las 4:59",
              "Puedes cancelar hasta el 13/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            offers: [
              {
                name: "Exclusive discount",
                total: 1192.6072543,
              },
            ],
            nights: 2,
            pricePerNight: 1400,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-True-AT_WEB-RO-SPA-COM-PQ",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: true,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 2801,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 10/11/2022 a las 4:59",
          "Puedes cancelar hasta el 13/11/2022 a las 4:59",
        ],
        offers: [
          {
            name: "Exclusive discount",
            total: 1192.6072543,
          },
        ],
        totalDiscounted: 1193,
        discountRate: 30,
        totalWithOffers: 3994,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1400,
      },
      amenities: ["Casarez e Hijos", "Razo, Barajas and Barrios"],
    },
    {
      id: "a70cbefc-3717-4da7-90e8-33d0e9b739f3",
      externalId: "a70cbefc-3717-4da7-90e8-33d0e9b739f3",
      name: "Casa Turquesa",
      shortDescription:
        "El hotel de playa está situado justo detrás del centro comercial de Kukulcan, en el distrito hotelero. El aeropuerto internacional de Cancún dista unos 20 km.\n\nEste sofisticado hotel tipo boutique da ",
      stars: "OneAndAHalf",
      type: "Hostal",
      address: "Cancun",
      photos: [
        {
          name: "Casa Turquesa - 1",
          url: "https://photos.hotelbeds.com/giata/01/015242/015242a_hb_ro_013.JPG",
        },
        {
          name: "Casa Turquesa - 2",
          url: "https://photos.hotelbeds.com/giata/01/015242/015242a_hb_r_001.jpg",
        },
        {
          name: "Casa Turquesa - 3",
          url: "https://photos.hotelbeds.com/giata/01/015242/015242a_hb_a_003.jpg",
        },
        {
          name: "Casa Turquesa - 4",
          url: "https://photos.hotelbeds.com/giata/01/015242/015242a_hb_l_008.JPG",
        },
        {
          name: "Casa Turquesa - 5",
          url: "https://photos.hotelbeds.com/giata/01/015242/015242a_hb_l_003.JPG",
        },
      ],
      geoPosition: {
        latitude: 21.100195,
        longitude: -86.765816,
      },
      bestRate: {
        grouped: [
          {
            id: "JSU.GV-1-BOOKABLE-NOR-True-AT_WEB-RO-GC-TODOSB2B",
            rateKey:
              "2022-11-17|2022-11-19|2|a70cbefc-3717-4da7-90e8-33d0e9b739f3|HotelBeds|JSU.GV-1|BOOKABLE|NOR|RO|MXN|GC-TODOSB2B|4890.51|MX|638005925048814440;4518E20DF0E85907409F4C5D4AD38763",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 4946.51081475,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 14/11/2022 a las 4:59",
              "Puedes cancelar hasta el 17/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            offers: [
              {
                name: "Exclusive discount",
                total: 434.75768754,
              },
            ],
            nights: 2,
            pricePerNight: 2473,
          },
        ],
        id: "JSU.GV-1-BOOKABLE-NOR-True-AT_WEB-RO-GC-TODOSB2B",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: true,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 4947,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 14/11/2022 a las 4:59",
          "Puedes cancelar hasta el 17/11/2022 a las 4:59",
        ],
        offers: [
          {
            name: "Exclusive discount",
            total: 434.75768754,
          },
        ],
        totalDiscounted: 435,
        discountRate: 8,
        totalWithOffers: 5382,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 2473,
      },
      amenities: [
        "Piña e Hijos",
        "Aparicio, Vergara and Tamayo",
        "Henríquez - Barraza",
        "Pedroza e Hijos",
      ],
    },
    {
      id: "31630a42-981d-45af-b0e9-79ac31720a89",
      externalId: "31630a42-981d-45af-b0e9-79ac31720a89",
      name: "Hacienda de Castilla",
      shortDescription:
        "Situado entre las avenidas López Portillo y Chichen Itza en un famoso complejo turístico y urbano de Cancún, este espléndido hotel es el lugar perfecto para unas vacaciones en familia, una escapada de",
      stars: "TwoAndAHalf",
      type: "Hostal",
      address: "Cancun",
      photos: [
        {
          name: "Hacienda de Castilla - 1",
          url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_w_016.JPG",
        },
        {
          name: "Hacienda de Castilla - 2",
          url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_p_013.jpg",
        },
        {
          name: "Hacienda de Castilla - 3",
          url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_a_017.JPG",
        },
        {
          name: "Hacienda de Castilla - 4",
          url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_ro_004.JPG",
        },
        {
          name: "Hacienda de Castilla - 5",
          url: "https://photos.hotelbeds.com/giata/13/135692/135692a_hb_a_014.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.174293,
        longitude: -86.822199,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-RO-NRF-TODOS",
            rateKey:
              "2022-11-17|2022-11-19|2|31630a42-981d-45af-b0e9-79ac31720a89|HotelBeds|DBL.ST|BOOKABLE|NRF|RO|MXN|NRF-TODOS|968.35|MX|638005925048817821;FAA831C219055039ADC736DAC4CFDB17",
            isRefundable: false,
            refundable: "No puedes cancelar ni realizar cambios",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 1024.351931525,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: ["Non-refundable rate. No amendments permitted"],
            cancellationPolicies: [],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 512,
          },
        ],
        id: "DBL.ST-BOOKABLE-NRF-False-AT_WEB-RO-NRF-TODOS",
        currency: "MXN",
        isRefundable: false,
        hasPromotions: true,
        hasOffers: false,
        hasCancellationPolicies: false,
        refundable: "No puedes cancelar ni realizar cambios",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 1024,
        promotions: ["Non-refundable rate. No amendments permitted"],
        cancellationPolicies: [],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 1024,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 512,
      },
      amenities: [
        "Atencio - Rivera",
        "Mena, Quintana and Griego",
        "Carbajal, Delafuente and Cuellar",
        "Rentería, León and Prado",
      ],
    },
    {
      id: "8e8ebb61-500b-4411-8bda-a14177fb6992",
      externalId: "8e8ebb61-500b-4411-8bda-a14177fb6992",
      name: "Plaza Caribe Cancún",
      shortDescription:
        "El hotel Plaza Caribe Cancún es un hotel de 3 estrellas ubicado en el centro de la ciudad de Cancún. Con la mejor ubicación en la zona somos la mejor opción para aquellos que vienen en plan de negocio",
      stars: "One",
      type: "Posada",
      address: "Cancún",
      photos: [
        {
          name: "Plaza Caribe Cancún - 1",
          url: "https://photos.hotelbeds.com/giata/00/008382/008382a_hb_ro_001.jpg",
        },
        {
          name: "Plaza Caribe Cancún - 2",
          url: "https://photos.hotelbeds.com/giata/00/008382/008382a_hb_ro_002.jpg",
        },
        {
          name: "Plaza Caribe Cancún - 3",
          url: "https://photos.hotelbeds.com/giata/00/008382/008382a_hb_r_004.JPG",
        },
        {
          name: "Plaza Caribe Cancún - 4",
          url: "https://photos.hotelbeds.com/giata/00/008382/008382a_hb_ro_006.jpg",
        },
        {
          name: "Plaza Caribe Cancún - 5",
          url: "https://photos.hotelbeds.com/giata/00/008382/008382a_hb_p_004.JPG",
        },
      ],
      geoPosition: {
        latitude: 21.164748,
        longitude: -86.826779,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.SU-BOOKABLE-NRF-False-AT_WEB-RO-CGW-MEX",
            rateKey:
              "2022-11-17|2022-11-19|2|8e8ebb61-500b-4411-8bda-a14177fb6992|HotelBeds|DBL.SU|BOOKABLE|NRF|RO|MXN|CGW-MEX|3192.94|MX|638005925048821822;7055FDFE0071F9BAFE153CAD0ECFCA12",
            isRefundable: false,
            refundable: "No puedes cancelar ni realizar cambios",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 3248.94420665,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: ["Non-refundable rate. No amendments permitted"],
            cancellationPolicies: [],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 1624,
          },
        ],
        id: "DBL.SU-BOOKABLE-NRF-False-AT_WEB-RO-CGW-MEX",
        currency: "MXN",
        isRefundable: false,
        hasPromotions: true,
        hasOffers: false,
        hasCancellationPolicies: false,
        refundable: "No puedes cancelar ni realizar cambios",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 3249,
        promotions: ["Non-refundable rate. No amendments permitted"],
        cancellationPolicies: [],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 3249,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1624,
      },
      amenities: [
        "Ybarra - Guajardo",
        "de Anda S.L.",
        "Trejo S.L.",
        "Nevárez, Fonseca and Sotelo",
      ],
    },
    {
      id: "d3f4ebb5-e619-40da-b0f1-9a8232db08b3",
      externalId: "d3f4ebb5-e619-40da-b0f1-9a8232db08b3",
      name: "Krystal Urban Cancún Centro",
      shortDescription:
        "Ofrecemos comodidad extraordinaria, también tenemos grandes espacios llenos de comodidades modernas, pendientes y excelentes servicios de negocios. La mejor opción para alojarse en la zona del centro ",
      stars: "One",
      type: "Hostal",
      address: "Cancun",
      photos: [
        {
          name: "Krystal Urban Cancún Centro - 1",
          url: "https://photos.hotelbeds.com/giata/12/125857/125857a_hb_a_002.jpg",
        },
        {
          name: "Krystal Urban Cancún Centro - 2",
          url: "https://photos.hotelbeds.com/giata/12/125857/125857a_hb_p_001.jpg",
        },
        {
          name: "Krystal Urban Cancún Centro - 3",
          url: "https://photos.hotelbeds.com/giata/12/125857/125857a_hb_r_004.jpg",
        },
        {
          name: "Krystal Urban Cancún Centro - 4",
          url: "https://photos.hotelbeds.com/giata/12/125857/125857a_hb_r_006.jpg",
        },
        {
          name: "Krystal Urban Cancún Centro - 5",
          url: "https://photos.hotelbeds.com/giata/12/125857/125857a_hb_r_001.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.150373,
        longitude: -86.820031,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.RO-BOOKABLE-NRF-True-AT_WEB-RO-NRFSPAMN",
            rateKey:
              "2022-11-17|2022-11-19|2|d3f4ebb5-e619-40da-b0f1-9a8232db08b3|HotelBeds|DBL.RO|BOOKABLE|NRF|RO|MXN|NRFSPAMN|2699.53|MX|638005925048822108;D04A0FCCF15ED310B7DBBCF6AFA2360D",
            isRefundable: false,
            refundable: "No puedes cancelar ni realizar cambios",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2755.5311795375,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            offers: [
              {
                name: "Exclusive discount",
                total: 239.75305904,
              },
            ],
            nights: 2,
            pricePerNight: 1378,
          },
        ],
        id: "DBL.RO-BOOKABLE-NRF-True-AT_WEB-RO-NRFSPAMN",
        currency: "MXN",
        isRefundable: false,
        hasPromotions: false,
        hasOffers: true,
        hasCancellationPolicies: false,
        refundable: "No puedes cancelar ni realizar cambios",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 2756,
        promotions: [],
        cancellationPolicies: [],
        offers: [
          {
            name: "Exclusive discount",
            total: 239.75305904,
          },
        ],
        totalDiscounted: 240,
        discountRate: 8,
        totalWithOffers: 2996,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1378,
      },
      amenities: ["Pedraza, Vázquez and Caballero", "Rosales Hermanos"],
    },
    {
      id: "37ff2c02-bb97-4686-8823-6a207013393b",
      externalId: "37ff2c02-bb97-4686-8823-6a207013393b",
      name: "Xbalamque & Spa",
      shortDescription:
        "Este maravilloso hotel goza de una situación privilegiada en el centro del popular de la ciudad de Cancún, lo que permite llegar sin problemas a muchos lugares de interés turístico de la zona y a la p",
      stars: "TwoAndAHalf",
      type: "Hostal",
      address: "Cancun",
      photos: [
        {
          name: "Xbalamque & Spa - 1",
          url: "https://photos.hotelbeds.com/giata/00/008503/008503a_hb_ro_004.jpg",
        },
        {
          name: "Xbalamque & Spa - 2",
          url: "https://photos.hotelbeds.com/giata/00/008503/008503a_hb_a_040.jpg",
        },
        {
          name: "Xbalamque & Spa - 3",
          url: "https://photos.hotelbeds.com/giata/00/008503/008503a_hb_t_001.jpg",
        },
        {
          name: "Xbalamque & Spa - 4",
          url: "https://photos.hotelbeds.com/giata/00/008503/008503a_hb_p_012.jpg",
        },
        {
          name: "Xbalamque & Spa - 5",
          url: "https://photos.hotelbeds.com/giata/00/008503/008503a_hb_p_014.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.161771,
        longitude: -86.829107,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-B2B-NET1RO",
            rateKey:
              "2022-11-17|2022-11-19|2|37ff2c02-bb97-4686-8823-6a207013393b|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|B2B-NET1RO|1656.00|MX|638005925048826176;9E3EB61C0A3A6143C3EDC33B6AE9D2EE",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 1711.999832025,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 14/11/2022 a las 4:59",
              "Puedes cancelar hasta el 17/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 856,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-B2B-NET1RO",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 1712,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 14/11/2022 a las 4:59",
          "Puedes cancelar hasta el 17/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 1712,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 856,
      },
      amenities: ["Angulo - Ledesma", "Tapia S.A.", "Palacios - Villarreal"],
    },
    {
      id: "0e04002a-76aa-4acb-986f-806f8a1dc644",
      externalId: "0e04002a-76aa-4acb-986f-806f8a1dc644",
      name: "Sunset Royal Beach Resort",
      shortDescription:
        "Este encantador hotel de playa goza de una ubicación estratégica en el corazón de la zona hotelera de Cancún. Está rodeado de las discotecas más famosas de la ciudad, así como de varios restaurantes y",
      stars: "Five",
      type: "Hostal",
      address: "Cancun",
      photos: [
        {
          name: "Sunset Royal Beach Resort - 1",
          url: "https://photos.hotelbeds.com/giata/14/146700/146700a_hb_l_001.jpg",
        },
        {
          name: "Sunset Royal Beach Resort - 2",
          url: "https://photos.hotelbeds.com/giata/14/146700/146700a_hb_l_002.JPG",
        },
        {
          name: "Sunset Royal Beach Resort - 3",
          url: "https://photos.hotelbeds.com/giata/14/146700/146700a_hb_a_007.JPG",
        },
        {
          name: "Sunset Royal Beach Resort - 4",
          url: "https://photos.hotelbeds.com/giata/14/146700/146700a_hb_p_001.jpg",
        },
        {
          name: "Sunset Royal Beach Resort - 5",
          url: "https://photos.hotelbeds.com/giata/14/146700/146700a_hb_f_002.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.126224,
        longitude: -86.752039,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-AI-GC-MEXB2B",
            rateKey:
              "2022-11-17|2022-11-19|2|0e04002a-76aa-4acb-986f-806f8a1dc644|HotelBeds|DBL.ST|BOOKABLE|NOR|AI|MXN|GC-MEXB2B|18497.73|MX|638005925048826598;EAA7DF80B89AF2551B51A1FE538F9AD7",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 18758.82945761,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 12/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 261.10093416,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 9379,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-AI-GC-MEXB2B",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 18759,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 12/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 18759,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 9379,
      },
      amenities: ["Quintero - Lebrón", "Balderas, Barela and Anguiano"],
    },
    {
      id: "92132d4a-c7b6-4bc2-8217-946b74884998",
      externalId: "92132d4a-c7b6-4bc2-8217-946b74884998",
      name: "Del Sol",
      shortDescription:
        "Este hotel está situado en Puerto Juárez, a solo 5 minutos de las zonas más interesantes de Cancún y de la playa del Niño. Se encuentra a 10 km de la plaza de las Américas y sus tiendas, y a 15 km del",
      stars: "FourAndAHalf",
      type: "Posada",
      address: "Cancun",
      photos: [
        {
          name: "Del Sol - 1",
          url: "https://photos.hotelbeds.com/giata/14/140885/140885a_hb_ro_003.jpg",
        },
        {
          name: "Del Sol - 2",
          url: "https://photos.hotelbeds.com/giata/14/140885/140885a_hb_a_006.jpg",
        },
        {
          name: "Del Sol - 3",
          url: "https://photos.hotelbeds.com/giata/14/140885/140885a_hb_l_023.jpg",
        },
        {
          name: "Del Sol - 4",
          url: "https://photos.hotelbeds.com/giata/14/140885/140885a_hb_ro_031.jpg",
        },
        {
          name: "Del Sol - 5",
          url: "https://photos.hotelbeds.com/giata/14/140885/140885a_hb_a_004.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.188556,
        longitude: -86.808037,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-NET-SB2BRO",
            rateKey:
              "2022-11-17|2022-11-19|2|92132d4a-c7b6-4bc2-8217-946b74884998|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|NET-SB2BRO|2541.22|MX|638005925048829950;B2AD7755CC848F32FA0C21324E6903FD",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2597.2182114,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 03/10/2022 a las 3:59",
              "Puedes cancelar hasta el 06/10/2022 a las 3:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 1299,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-NET-SB2BRO",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 2597,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 03/10/2022 a las 3:59",
          "Puedes cancelar hasta el 06/10/2022 a las 3:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 2597,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1299,
      },
      amenities: [
        "Espinal e Hijos",
        "Padrón - Posada",
        "Segura - Roque",
        "Adorno S.A.",
      ],
    },
    {
      id: "327a54a5-c756-4013-891a-058a3898157f",
      externalId: "327a54a5-c756-4013-891a-058a3898157f",
      name: "GR Caribe by Solaris Deluxe All Inclusive Resort",
      shortDescription:
        "Disfruta de tus vacaciones TODO INCLUIDO\nGR Caribe by Solaris, Hotel de lujo todo incluido, cuenta con una arquitectura mediterránea única y elegante, un diseño interior moderno en un ambiente casual ",
      stars: "ThreeAndAHalf",
      type: "Hotel",
      address: "Cancun",
      photos: [
        {
          name: "GR Caribe by Solaris Deluxe All Inclusive Resort - 1",
          url: "https://photos.hotelbeds.com/giata/14/143954/143954a_hb_t_001.jpg",
        },
        {
          name: "GR Caribe by Solaris Deluxe All Inclusive Resort - 2",
          url: "https://photos.hotelbeds.com/giata/14/143954/143954a_hb_a_006.jpg",
        },
        {
          name: "GR Caribe by Solaris Deluxe All Inclusive Resort - 3",
          url: "https://photos.hotelbeds.com/giata/14/143954/143954a_hb_a_005.jpg",
        },
        {
          name: "GR Caribe by Solaris Deluxe All Inclusive Resort - 4",
          url: "https://photos.hotelbeds.com/giata/14/143954/143954a_hb_l_002.jpg",
        },
        {
          name: "GR Caribe by Solaris Deluxe All Inclusive Resort - 5",
          url: "https://photos.hotelbeds.com/giata/14/143954/143954a_hb_l_001.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.0483100442471,
        longitude: -86.78295086330763,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.DX-BOOKABLE-NOR-False-AT_WEB-AI-ESP-FLEX",
            rateKey:
              "2022-11-17|2022-11-19|2|327a54a5-c756-4013-891a-058a3898157f|HotelBeds|DBL.DX|BOOKABLE|NOR|AI|MXN|ESP-FLEX|7931.56|MX|638005925048830801;9B9781156C008A2C4B62F8E131D2BF38",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 7987.5566792,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 12/11/2022 a las 4:59",
              "Puedes cancelar hasta el 15/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 3994,
          },
        ],
        id: "DBL.DX-BOOKABLE-NOR-False-AT_WEB-AI-ESP-FLEX",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 7988,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 12/11/2022 a las 4:59",
          "Puedes cancelar hasta el 15/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 7988,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 3994,
      },
      amenities: [
        "Osorio - Anguiano",
        "Sedillo Hermanos",
        "Ramírez, Ayala and Ornelas",
        "Negrete - Ríos",
      ],
    },
    {
      id: "f9b72702-2c5d-4f60-b413-9728fc38f497",
      externalId: "f9b72702-2c5d-4f60-b413-9728fc38f497",
      name: "Adhara Express",
      shortDescription:
        "Este atractivo hotel está situado en la avenida Yaxchillan, en el corazón de Cancún a solo unos pasos de la zona de tiendas, restaurantes y discotecas. La playa más cercana está a 10 minutos en coche ",
      stars: "OneAndAHalf",
      type: "Hostal",
      address: "Cancun",
      photos: [
        {
          name: "Adhara Express - 1",
          url: "https://photos.hotelbeds.com/giata/00/003969/003969a_hb_ro_015.jpg",
        },
        {
          name: "Adhara Express - 2",
          url: "https://photos.hotelbeds.com/giata/00/003969/003969a_hb_ro_009.jpg",
        },
        {
          name: "Adhara Express - 3",
          url: "https://photos.hotelbeds.com/giata/00/003969/003969a_hb_p_010.jpg",
        },
        {
          name: "Adhara Express - 4",
          url: "https://photos.hotelbeds.com/giata/00/003969/003969a_hb_r_014.jpg",
        },
        {
          name: "Adhara Express - 5",
          url: "https://photos.hotelbeds.com/giata/00/003969/003969a_hb_p_009.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.164733,
        longitude: -86.829136,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-True-AT_WEB-BB-SPA-COM-PQ",
            rateKey:
              "2022-11-17|2022-11-19|2|f9b72702-2c5d-4f60-b413-9728fc38f497|HotelBeds|DBL.ST|BOOKABLE|NOR|BB|MXN|SPA-COM-PQ|3018.98|MX|638005925048836273;F6523D345DEF853CAE39009764EAD8BD",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Bed And Breakfast",
            mealPlanCode: "BB",
            total: 3074.979551225,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 10/11/2022 a las 4:59",
              "Puedes cancelar hasta el 13/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            offers: [
              {
                name: "Exclusive discount",
                total: 1312.07324776,
              },
            ],
            nights: 2,
            pricePerNight: 1537,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-True-AT_WEB-BB-SPA-COM-PQ",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: true,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Bed And Breakfast",
        mealPlanCode: "BB",
        total: 3075,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 10/11/2022 a las 4:59",
          "Puedes cancelar hasta el 13/11/2022 a las 4:59",
        ],
        offers: [
          {
            name: "Exclusive discount",
            total: 1312.07324776,
          },
        ],
        totalDiscounted: 1312,
        discountRate: 30,
        totalWithOffers: 4387,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1537,
      },
      amenities: ["Godínez - Herrera", "Segura - Casillas"],
    },
    {
      id: "b7ff78fa-4bf1-4de4-b60e-5684cc952197",
      externalId: "b7ff78fa-4bf1-4de4-b60e-5684cc952197",
      name: "Terracaribe",
      shortDescription:
        "Este hotel está ubicado en el centro de Cancún, a solo 3 minutos del embarcadero a Isla Mujeres. De estilo moderno y minimalista, con espacios abiertos y piscina al aire libre, cuenta con un restauran",
      stars: "ThreeAndAHalf",
      type: "Posada",
      address: "Cancun",
      photos: [
        {
          name: "Terracaribe - 1",
          url: "https://photos.hotelbeds.com/giata/02/022462/022462a_hb_r_001.jpg",
        },
        {
          name: "Terracaribe - 2",
          url: "https://photos.hotelbeds.com/giata/02/022462/022462a_hb_a_014.jpg",
        },
        {
          name: "Terracaribe - 3",
          url: "https://photos.hotelbeds.com/giata/02/022462/022462a_hb_l_004.jpg",
        },
        {
          name: "Terracaribe - 4",
          url: "https://photos.hotelbeds.com/giata/02/022462/022462a_hb_a_020.jpg",
        },
        {
          name: "Terracaribe - 5",
          url: "https://photos.hotelbeds.com/giata/02/022462/022462a_hb_a_021.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.1771,
        longitude: -86.8201,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-B2B-NETRO",
            rateKey:
              "2022-11-17|2022-11-19|2|b7ff78fa-4bf1-4de4-b60e-5684cc952197|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|B2B-NETRO|1835.10|MX|638005925048836796;F9960BF98E823EAA7371A84FE86D3D30",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 1891.0961882,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 14/11/2022 a las 4:59",
              "Puedes cancelar hasta el 17/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 946,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-B2B-NETRO",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 1891,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 14/11/2022 a las 4:59",
          "Puedes cancelar hasta el 17/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 1891,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 946,
      },
      amenities: [
        "Hurtado e Hijos",
        "Tijerina, Linares and Arteaga",
        "Gastélum - Jaime",
        "Laboy, Vanegas and Huerta",
      ],
    },
    {
      id: "fd3a02fc-7f10-4ea5-a7fe-f14bea346bb5",
      externalId: "fd3a02fc-7f10-4ea5-a7fe-f14bea346bb5",
      name: "Suites Gaby",
      shortDescription:
        "Suites Gaby está ubicado en el centro de la ciudad de Cancún cerca de la zona comercial y a 10 minutos de la playa. Es un hotel de estilo moderno con cajas de seguridad digitales, ventanas anti ruido,",
      stars: "ThreeAndAHalf",
      type: "Villa",
      address: "Cancún",
      photos: [
        {
          name: "Suites Gaby - 1",
          url: "https://photos.hotelbeds.com/giata/12/128674/128674a_hb_w_002.jpg",
        },
        {
          name: "Suites Gaby - 2",
          url: "https://photos.hotelbeds.com/giata/12/128674/128674a_hb_w_005.jpg",
        },
        {
          name: "Suites Gaby - 3",
          url: "https://photos.hotelbeds.com/giata/12/128674/128674a_hb_ro_003.jpg",
        },
        {
          name: "Suites Gaby - 4",
          url: "https://photos.hotelbeds.com/giata/12/128674/128674a_hb_a_009.jpg",
        },
        {
          name: "Suites Gaby - 5",
          url: "https://photos.hotelbeds.com/giata/12/128674/128674a_hb_r_007.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.16185,
        longitude: -86.832473,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-B2B-NET",
            rateKey:
              "2022-11-17|2022-11-19|2|fd3a02fc-7f10-4ea5-a7fe-f14bea346bb5|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|B2B-NET|1854.08|MX|638005925048840341;ECD2D8A8A5A1C7D75C3A3432A9A16FDC",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 1910.083480975,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 14/11/2022 a las 4:59",
              "Puedes cancelar hasta el 17/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 955,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-B2B-NET",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 1910,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 14/11/2022 a las 4:59",
          "Puedes cancelar hasta el 17/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 1910,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 955,
      },
      amenities: [
        "Alcaraz, Ayala and Santana",
        "Viera, Ortiz and Mejía",
        "Vera - Arevalo",
        "Montemayor, Cabán and Casarez",
      ],
    },
    {
      id: "64068a13-9588-4bef-bb84-d3a38a771fa1",
      externalId: "64068a13-9588-4bef-bb84-d3a38a771fa1",
      name: "Caribe Internacional Cancun",
      shortDescription:
        "El hotel se encuentra a 20 kilómetros del aeropuerto internacional de Cancún, es decir, a unos 20 minutos en coche. Enfrente del hotel podrá encontrar servicio de transporte público. El mar y la preci",
      stars: "Two",
      type: "Hotel",
      address: "Cancun Centro",
      photos: [
        {
          name: "Caribe Internacional Cancun - 1",
          url: "https://photos.hotelbeds.com/giata/01/017294/017294a_hb_ro_001.jpg",
        },
        {
          name: "Caribe Internacional Cancun - 2",
          url: "https://photos.hotelbeds.com/giata/01/017294/017294a_hb_ro_007.jpg",
        },
        {
          name: "Caribe Internacional Cancun - 3",
          url: "https://photos.hotelbeds.com/giata/01/017294/017294a_hb_w_003.jpg",
        },
        {
          name: "Caribe Internacional Cancun - 4",
          url: "https://photos.hotelbeds.com/giata/01/017294/017294a_hb_a_015.jpg",
        },
        {
          name: "Caribe Internacional Cancun - 5",
          url: "https://photos.hotelbeds.com/giata/01/017294/017294a_hb_r_001.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.162615,
        longitude: -86.829701,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-B2B-NETRATE",
            rateKey:
              "2022-11-17|2022-11-19|2|64068a13-9588-4bef-bb84-d3a38a771fa1|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|B2B-NETRATE|1943.38|MX|638005925048840652;50E7E6FC0BBE3724C061AD77CBD41B5F",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 1999.375074025,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 14/11/2022 a las 4:59",
              "Puedes cancelar hasta el 17/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 1000,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-B2B-NETRATE",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 1999,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 14/11/2022 a las 4:59",
          "Puedes cancelar hasta el 17/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 1999,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1000,
      },
      amenities: ["Avilés S.L.", "León, Gamboa and Cisneros", "Moya Hermanos"],
    },
    {
      id: "1d18d490-1f53-4cf6-b8b8-72aaaa19e0a9",
      externalId: "1d18d490-1f53-4cf6-b8b8-72aaaa19e0a9",
      name: "Antillano",
      shortDescription:
        "Este hotel está situado en la calle principal de Cancún, la Avenida Tulum, a 10 minutos andando de maravillosos restaurantes, zonas comerciales, rastros, atracciones turísticas y una amplia gama de lo",
      stars: "TwoAndAHalf",
      type: "Hostal",
      address: "Cancun",
      photos: [
        {
          name: "Antillano - 1",
          url: "https://photos.hotelbeds.com/giata/05/059159/059159a_hb_ba_001.jpg",
        },
        {
          name: "Antillano - 2",
          url: "https://photos.hotelbeds.com/giata/05/059159/059159a_hb_w_001.jpg",
        },
        {
          name: "Antillano - 3",
          url: "https://photos.hotelbeds.com/giata/05/059159/059159a_hb_a_002.JPG",
        },
        {
          name: "Antillano - 4",
          url: "https://photos.hotelbeds.com/giata/05/059159/059159a_hb_ro_002.jpg",
        },
        {
          name: "Antillano - 5",
          url: "https://photos.hotelbeds.com/giata/05/059159/059159a_hb_ro_021.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.1603879,
        longitude: -86.82563640000001,
      },
      bestRate: {
        grouped: [
          {
            id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-TODOSEP",
            rateKey:
              "2022-11-17|2022-11-19|2|1d18d490-1f53-4cf6-b8b8-72aaaa19e0a9|HotelBeds|DBL.ST|BOOKABLE|NOR|RO|MXN|CGW-TODOSEP|2105.02|MX|638005925048840933;ED6AA648640F282822E46E9DF2036316",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "Room Only",
            mealPlanCode: "RO",
            total: 2161.02364765,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 13/11/2022 a las 4:59",
              "Puedes cancelar hasta el 16/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 1081,
          },
        ],
        id: "DBL.ST-BOOKABLE-NOR-False-AT_WEB-RO-CGW-TODOSEP",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "Room Only",
        mealPlanCode: "RO",
        total: 2161,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 13/11/2022 a las 4:59",
          "Puedes cancelar hasta el 16/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 2161,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 1081,
      },
      amenities: [
        "Farías, Villalobos and Haro",
        "Santana - de Anda",
        "Delagarza - Delarosa",
      ],
    },
    {
      id: "540a2fa5-1c01-482e-9120-8e98d242352e",
      externalId: "540a2fa5-1c01-482e-9120-8e98d242352e",
      name: "The Royal Cancun, All Suites Resort",
      shortDescription:
        "Este suntuoso hotel de playa está situado en la famosa localidad turística de Cancún, a solo 600 metros de la terminal de ferry y a 23 km del aeropuerto internacional de Cancún. El establecimiento se ",
      stars: "FourAndAHalf",
      type: "Villa",
      address: "Cancun",
      photos: [
        {
          name: "The Royal Cancun, All Suites Resort - 1",
          url: "https://photos.hotelbeds.com/giata/01/014447/014447a_hb_p_004.jpg",
        },
        {
          name: "The Royal Cancun, All Suites Resort - 2",
          url: "https://photos.hotelbeds.com/giata/01/014447/014447a_hb_a_006.jpg",
        },
        {
          name: "The Royal Cancun, All Suites Resort - 3",
          url: "https://photos.hotelbeds.com/giata/01/014447/014447a_hb_ro_027.jpg",
        },
        {
          name: "The Royal Cancun, All Suites Resort - 4",
          url: "https://photos.hotelbeds.com/giata/01/014447/014447a_hb_l_001.jpg",
        },
        {
          name: "The Royal Cancun, All Suites Resort - 5",
          url: "https://photos.hotelbeds.com/giata/01/014447/014447a_hb_ro_026.jpg",
        },
      ],
      geoPosition: {
        latitude: 21.143465,
        longitude: -86.783945,
      },
      bestRate: {
        grouped: [
          {
            id: "SUI.B2-3-BOOKABLE-NOR-True-AT_WEB-AI-B2BOPAQUEAIM",
            rateKey:
              "2022-11-17|2022-11-19|2|540a2fa5-1c01-482e-9120-8e98d242352e|HotelBeds|SUI.B2-3|BOOKABLE|NOR|AI|MXN|B2BOPAQUEAIM|12797.44|MX|638005925048841138;6CE0F413347AC0637C72311858E69C67",
            isRefundable: true,
            refundable: "Tarifa reembolsable",
            mealPlan: "All Inclusive",
            mealPlanCode: "AI",
            total: 12853.43533035,
            differenceOverLowPrice: 0,
            currency: "MXN",
            promotions: [],
            cancellationPolicies: [
              "Puedes cancelar hasta el 14/11/2022 a las 4:59",
              "Puedes cancelar hasta el 17/11/2022 a las 4:59",
            ],
            taxes: [
              {
                included: false,
                total: 56,
                currency: "MXN",
              },
            ],
            nights: 2,
            pricePerNight: 6427,
          },
        ],
        id: "SUI.B2-3-BOOKABLE-NOR-True-AT_WEB-AI-B2BOPAQUEAIM",
        currency: "MXN",
        isRefundable: true,
        hasPromotions: false,
        hasOffers: false,
        hasCancellationPolicies: true,
        refundable: "Tarifa reembolsable",
        mealPlan: "All Inclusive",
        mealPlanCode: "AI",
        total: 12853,
        promotions: [],
        cancellationPolicies: [
          "Puedes cancelar hasta el 14/11/2022 a las 4:59",
          "Puedes cancelar hasta el 17/11/2022 a las 4:59",
        ],
        offers: [],
        totalDiscounted: 0,
        discountRate: 0,
        totalWithOffers: 12853,
        totalDifferenceOverLowPrice: 0,
        totalPerNight: 6427,
      },
      amenities: [
        "Cuellar S.L.",
        "Carrillo - Serna",
        "Gaitán, Leal and Vergara",
        "Zapata, Navarro and Marín",
      ],
    },
  ],
  filters: [
    {
      code: "MOSTUSED",
      title: "Filtros más usados",
      items: ["All Inclusive", "Hotel"],
    },
    {
      code: "STARS",
      title: "Categoría del hotel",
      items: [
        "Five",
        "Four",
        "FourAndAHalf",
        "One",
        "OneAndAHalf",
        "Three",
        "ThreeAndAHalf",
        "Two",
        "TwoAndAHalf",
      ],
    },
    {
      code: "PROPERTY",
      title: "Tipo de propiedad",
      items: ["Hostal", "Hotel", "Posada", "Resort", "Villa"],
    },
    {
      code: "MEALPLANS",
      title: "Planes de alimentos",
      items: [
        "All Inclusive",
        "Room Only",
        "American Breakfast",
        "Bed And Breakfast",
      ],
    },
    {
      code: "SERVICES",
      title: "Servicios",
      items: [
        "Vigil - Gaona",
        "Alarcón Hermanos",
        "Razo - Arce",
        "Zúñiga - Pedraza",
        "Montemayor, Caraballo and Ibarra",
        "Sanabria, Vanegas and Núñez",
        "Delgadillo - Arreola",
        "Delapaz - Tirado",
        "Macías Hermanos",
        "Vigil - Carbajal",
        "Escobedo, Bueno and Reynoso",
        "Luevano S.A.",
        "Zayas S.L.",
        "Guardado - Ponce",
        "Rojas S.A.",
        "Aguayo, Valenzuela and Alcaraz",
        "Naranjo, Garrido and Tirado",
        "Acosta S.L.",
        "Miranda Hermanos",
        "Velásquez e Hijos",
        "Alva, Palomino and Prado",
        "Cárdenas e Hijos",
        "Aponte, Chapa and Arce",
        "Gallardo S.L.",
        "Orellana S.L.",
        "Báez, Ortiz and Montañez",
        "Tamez e Hijos",
        "Mesa - Gamboa",
        "Negrón e Hijos",
        "Meza, Guardado and Mascareñas",
        "Herrera - Carrero",
        "Patiño - Alba",
        "Valdés - Melgar",
        "Castañeda S.A.",
        "Pulido Hermanos",
        "Fernández, Mercado and Armijo",
        "Sarabia, Gamboa and Guajardo",
        "Alfaro Hermanos",
        "Zambrano Hermanos",
        "Porras - Ponce",
        "Argüello - Henríquez",
        "Tejada, Sedillo and Alaníz",
        "Romero - Aguirre",
        "Quintero - Ramos",
        "Ocampo, Alejandro and Parra",
        "Toro - Frías",
        "Arteaga Hermanos",
        "Camacho S.L.",
        "Alonso - Reyna",
        "Lara, Abreu and Vergara",
        "Arce - Guerrero",
        "Gálvez - Loya",
        "Feliciano S.L.",
        "Alonzo - Elizondo",
        "Valverde - Jiménez",
        "Pabón, Arenas and Aguayo",
        "Grijalva Hermanos",
        "Bañuelos, Toro and Rosales",
        "Godínez, Lugo and Mercado",
        "Lovato, Gutiérrez and Paz",
        "Delgadillo - Reséndez",
        "Grijalva, Enríquez and Mejía",
        "Orta S.A.",
        "Negrón - Razo",
        "Durán Hermanos",
        "Gómez - Quezada",
        "Garrido - Soto",
        "Camarillo - Orozco",
        "Raya Hermanos",
        "Valadez, Ceballos and Pelayo",
        "Espinal S.A.",
        "Santiago e Hijos",
        "Coronado - Regalado",
        "Monroy Hermanos",
        "Sanabria, Montero and Mendoza",
        "Cuellar - Abrego",
        "Patiño S.A.",
        "Campos - Laboy",
        "Almonte, Godínez and Vega",
        "Gil - Jasso",
        "Monroy S.A.",
        "Rosado, Alcaraz and Anaya",
        "Jasso - Delacrúz",
        "Gámez - Cisneros",
        "Ibarra - Guevara",
        "Arenas e Hijos",
        "Malave, Ocasio and Nájera",
        "Altamirano - Sierra",
        "Báez, Mercado and Duarte",
        "Muñiz S.A.",
        "Haro, Macías and Gaytán",
        "Sarabia, Laboy and Zapata",
        "Vera - Arenas",
        "Leiva, Sáenz and Muñoz",
        "Lozada S.L.",
        "Montenegro, Carrasquillo and Vargas",
        "Pagan e Hijos",
        "Sisneros, Treviño and Terrazas",
        "Roybal - Coronado",
        "Guajardo - Villaseñor",
        "Corrales - Balderas",
        "Rosales S.L.",
        "Urías, Galindo and Berríos",
        "Rojas, Tejeda and Feliciano",
        "Fuentes, Laboy and Madrid",
        "Fajardo - Aguilar",
        "Madera - Gastélum",
        "Trujillo - Ordóñez",
        "Barrios, Arredondo and Espinal",
        "Escalante e Hijos",
        "Solís - Cepeda",
        "Pedraza, Colunga and Villanueva",
        "Sarabia Hermanos",
        "Holguín, Montalvo and Córdova",
        "Piña - Delvalle",
        "Jurado, Medrano and Mata",
        "Lovato e Hijos",
        "Casarez e Hijos",
        "Razo, Barajas and Barrios",
        "Piña e Hijos",
        "Aparicio, Vergara and Tamayo",
        "Henríquez - Barraza",
        "Pedroza e Hijos",
        "Atencio - Rivera",
        "Mena, Quintana and Griego",
        "Carbajal, Delafuente and Cuellar",
        "Rentería, León and Prado",
        "Ybarra - Guajardo",
        "de Anda S.L.",
        "Trejo S.L.",
        "Nevárez, Fonseca and Sotelo",
        "Pedraza, Vázquez and Caballero",
        "Rosales Hermanos",
        "Angulo - Ledesma",
        "Tapia S.A.",
        "Palacios - Villarreal",
        "Quintero - Lebrón",
        "Balderas, Barela and Anguiano",
        "Espinal e Hijos",
        "Padrón - Posada",
        "Segura - Roque",
        "Adorno S.A.",
        "Osorio - Anguiano",
        "Sedillo Hermanos",
        "Ramírez, Ayala and Ornelas",
        "Negrete - Ríos",
        "Godínez - Herrera",
        "Segura - Casillas",
        "Hurtado e Hijos",
        "Tijerina, Linares and Arteaga",
        "Gastélum - Jaime",
        "Laboy, Vanegas and Huerta",
        "Alcaraz, Ayala and Santana",
        "Viera, Ortiz and Mejía",
        "Vera - Arevalo",
        "Montemayor, Cabán and Casarez",
        "Avilés S.L.",
        "León, Gamboa and Cisneros",
        "Moya Hermanos",
        "Farías, Villalobos and Haro",
        "Santana - de Anda",
        "Delagarza - Delarosa",
        "Cuellar S.L.",
        "Carrillo - Serna",
        "Gaitán, Leal and Vergara",
        "Zapata, Navarro and Marín",
      ],
    },
  ],
  numberHotels: 51,
};

export default listHotelsMock;
