import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import Modals from '../../modals/Modals';
import { TimelineFlight } from "../../feedback";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import serviceType from "../../../constants/serviceTypeConstant";
import BoxIconText from "../BoxIconText/BoxIconText";
import iconUtils from "../../../utils/iconUtils";
import iconContants from "../../../constants/iconContants";
import rateUtils from "../../../utils/rateUtils";
import "./components/style.scss";

const BoxDetailFlight = (props) => {
  const {
    fullscreen,
    show,
    handleCloseModal,
    scale
  } = props;
  const intl = useIntl();
  const onClick = () => {
    const { onClickButton } = props;
    onClickButton && onClickButton();
  };


  
  
  return (
    <Modals
    fullscreen={fullscreen}
    show={show}
    showClose={false}
    handleCloseModal={handleCloseModal}
    title="Detalles del vuelo"
    >
      <div className="box-detail-flight">
        <div className="desc-detail-flight">
            <h6>Cd. México (AICM) a Cancún</h6>
            <span>Duración total del vuelo: 1h 13m</span>
            <b>Sin escalas</b>
        </div>
        <div className="desc-timeline-flight">          
            <div className="info-tag-flight">
                <h5>Vuelo <strong>Y4 102</strong></h5>
                <div className="mb-3">
                    <span>Operado por Volaris México</span>
                    <span>Tipo de avión A320</span>
                </div>
            </div>
            <div className="info-timeline">
                <div className="flight">
                  <div>{iconUtils.icon(iconContants.RIFLIGHTOFF)}</div>
                  <div className="desti-clock">
                    <span><strong>04:58 AM</strong></span>
                    <small>Cd. México (AICM)</small>
                  </div>
                </div>
                <div className="timeline-vertical"></div>
                <div className="flight">
                  <div>{iconUtils.icon(iconContants.RIFLIGHTLAND)}</div>
                  <div className="desti-clock">
                    <span><strong>04:58 AM</strong></span>
                    <small>Guadalajara</small>
                  </div>
                </div>
            </div>
        </div>
        {scale &&(
          <>
          <div className="layover-details">
          <span>{iconUtils.icon(iconContants.CLOCK)} </span> 
          <span> Tiempo de espera 4h 44min conexión en Guadalajara</span>
        </div>

        <div className="desc-timeline-flight">
            <div className="info-tag-flight">
                <h5>Vuelo <strong>Y4 102</strong></h5>
                <div className="mb-3">
                    <span>Operado por Volaris México</span>
                    <span>Tipo de avión A320</span>
                </div>
            </div>
            <div className="info-timeline">
                <div className="flight">
                  <div>{iconUtils.icon(iconContants.RIFLIGHTOFF)}</div>
                  <div className="desti-clock">
                    <span><strong>04:58 AM</strong></span>
                    <small>Guadalajara</small>
                  </div>
                </div>
                <div className="timeline-vertical"></div>
                <div className="flight">
                  <div>{iconUtils.icon(iconContants.RIFLIGHTLAND)}</div>
                  <div className="desti-clock">
                    <span><strong>04:58 AM</strong></span>
                    <small>Cancún</small>
                  </div>
                </div>
            </div>
        </div>
          </>
        )}
        
        
      </div>
    </Modals>
  );
};

export default BoxDetailFlight;
