
import localeUtils from "../utils/localeUtils";
import hotelsValidate from "../validate/hotelsValidate";
import transfersValidate from "../validate/transfersValidate";
import toursValidate from "../validate/toursValidate";
import packagesValidate from "../validate/packagesValidate";
import listHotelsMock from "../mock/hotels/listHotelsMock";
import listTourMock from "../mock/tour/listTourMock"
import listTransfersMock from "../mock/transfers/listTransfersMock"

// variables to cancel previous api call
let controller, signal;

//TODO: get hotels
const hotels = async (request, onSuccess, onError, onAbort = null, onFinally = null) => {
	const { locale, pathNext, authSession, ...rest } = request;
	const language = localeUtils.handleLanguage(locale);
	// cancel previous api call
	if (controller) controller.abort();
	controller = new AbortController()
	signal = controller.signal;

	await fetch(`${process.env.REACT_APP_BFF_API}${process.env.REACT_APP_BFF_HOTEL_LIST}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${authSession.token}`
		},
		signal,
		body: JSON.stringify({ ...rest, culture: language })
	})
	.then((response) => {
		if (response.ok)
			return response.json();
		throw new Error('Something went wrong.');
	})
	.then((data) => {
		if (onSuccess && typeof onSuccess === 'function') {
			onSuccess({
				filters: data.filters,
				items: hotelsValidate.formatList(data.result, request),
				isFetching: true,
				isSuccess:true,
				geoPositionZone: data.geoPositionZone
			});
		}
	})
	.catch(ex => {
		if(ex.name === 'AbortError')
			onAbort && typeof onAbort === 'function' && onAbort(ex)
		else
			onError && typeof onError === 'function' && onError(ex);
	})
	.finally(() => {
		onFinally && typeof onFinally === 'function' && onFinally();
	});
}

const hotelsById = async (request, onSuccess, onError) => {
	const { locale, authSession, ...rest } = request;
	const language = localeUtils.handleLanguage(locale);
	// cancel previous api call
	if (controller) {
		controller.abort()
	}
	controller = new AbortController()
	signal = controller.signal;

	await fetch(`${process.env.REACT_APP_BFF_API}${process.env.REACT_APP_BFF_HOTELS_LIST_BYID}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${authSession.token}`
		},
		signal,
		body: JSON.stringify({ ...rest, culture: language })
	})
		.then((response) => {
			if (response.ok)
				return response.json();
			throw new Error('Something went wrong.');
		})
		.then((data) => {
			if (onSuccess && typeof onSuccess === 'function') {
				onSuccess({
					filters: data.filters,
					items: hotelsValidate.formatList(data.result, request),
					isFetching: true,
					geoPositionZone: data?.geoPositionZone
					// isFetching: false,
					// isSuccess: true,
					// isError :false
				});
			}
		})
		.catch(ex => {
			console.error(ex);
			if (onError && typeof onError === 'function') {
				onError(ex);
			}
		});
}


//TODO Get list fake package
const hotelsMock = async (request, onSuccess, _onError) => {
	const { authSession, ...rest } = request;
	await onSuccess({
		filters: listHotelsMock.filters,
		items: hotelsValidate.formatList(listHotelsMock.result, rest),
		isFetching: false,
		// isSuccess: true,
		// isError :false
	});
}

const tourMock = async (request, onSuccess, _onError, dictionary) => {
	const { authSession, ...rest } = request;
	await onSuccess({
		filters: listTourMock.filters,
		items: toursValidate.formatList(listTourMock.result, rest, dictionary),
		isFetching: true
	});
}

const ActivitysMock = async (request, onSuccess, _onError, dictionary) => {
	const { authSession, ...rest } = request;
	await onSuccess({
		filters: listTourMock.filters,
		items: toursValidate.formatList(listTourMock.result, rest, dictionary),
		isFetching: true
	});
}

const transfersMock = async (request, onSuccess, _onError, dictionary) => {
	const { authSession, ...rest } = request;
	await onSuccess({
		filters: listTransfersMock.filters,
		items: transfersValidate.formatList(listTransfersMock.result, rest, dictionary),
		// items: listTransfersMock.result,
		isFetching: true
	});
}

//TODO Get list fake package
const packages = async (request, onSuccess, onError, dictionary) => {
	const { authSession, ...rest } = request;
	await onSuccess({
		filters: listHotelsMock.filters,
		items: packagesValidate.formatList(listHotelsMock.result, rest, dictionary),
		isFetching: true
	});
}

const serviceListAction = {
	hotels,
	hotelsById,
	hotelsMock,
	packages,
	tourMock,
	transfersMock,
	ActivitysMock
}

export default serviceListAction;
