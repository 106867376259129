import { FormattedMessage } from "react-intl";
import dateUtils from "../utils/dateUtils";
import moment from "moment/moment";

const dates = dateUtils.getRangeDays();

const optionDates = [
  {
    id: 1,
    key: "bookingDate",
    label: <FormattedMessage id="checkout.reservation" />,
  },
  {
    id: 2,
    key: "cancellationDate",
    label: <FormattedMessage id="checkout.cancelationLimit" />,
  },
  {
    id: 3,
    key: "paymentLimitDate ",
    label: <FormattedMessage id="checkout.cancelationPayment" />,
  },
];

const params = () => {
  return {
    start: moment().subtract(5, "days").format("yyyy-MM-DD"),
    end: moment().format("yyyy-MM-DD"),
    dateType: "bookingDate",
  };
};

const reservationUtils = {
  optionDates,
  params,
};

export default reservationUtils;
