import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import imageDefault from '../../../assets/img/defaultImage-Slider.svg'
import { Card, Carousel } from "react-bootstrap";

const MAX_PHOTOS = 5;

const Collage = (props) => {
	const { gallery, locale, onClick, isMobile } = props;
	const [gallerySlice, setGallerySlice] = useState([])
	useEffect(() => {
		const cloneGallery = [...gallery];
		const auxGallery = cloneGallery && cloneGallery.length > 0 ? cloneGallery.splice(0, MAX_PHOTOS) : [];
		if (auxGallery.length <= MAX_PHOTOS) {
			const count = auxGallery.length;
			for (let i = count; i <= MAX_PHOTOS; i++) {
				const image = {
					name: "Default",
					photoUris: imageDefault
				}
				auxGallery.push(image)
			}
		}
		setGallerySlice(auxGallery)
	}, [])

	const renderImg = (galleryItem) => {
		return <Image
			alt={galleryItem?.name}
			src={galleryItem?.photoUris}
			className='w-100 h-100'
			onError={({ currentTarget }) => {
				currentTarget.onerror = null;
				currentTarget.src = imageDefault
			}}
		/>
	}
	return (
		<div className='container-xl' style={{ position: "relative" }}>
			<div className="collage">
				<div className='container-first'>
					{isMobile && gallerySlice?.length > 2 ? (<>
						<Carousel>
							{gallerySlice.filter(x => x.name != "Default").map((img, index) => {
								return (
									<Carousel.Item interval={100000} key={index}>
										<Card.Img
											className="card-img"
											src={img.photoUris}
											onError={({ currentTarget }) => {
												currentTarget.onerror = null;
												currentTarget.src = imageDefault;
											}}
										/>
									</Carousel.Item>
								);
							})}
						</Carousel>
					</>) : renderImg(gallerySlice[0])}
				</div>
				<div className="container-duo">
					<div className='container-top'>
						{renderImg(gallerySlice[1])}
					</div>
					<div className='container-botom'>
						{renderImg(gallerySlice[2])}
					</div>
				</div>
				<div className="container-duo">
					<div className='container-top'>
						{renderImg(gallerySlice[3])}
					</div>
					<div className='container-botom'>
						{renderImg(gallerySlice[4])}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Collage;
