
import types from "../constants/reducers/cmsComponentsConstants";
const get = (data) => {
    data.isFetching = false;
    data.isSuccess = true;
    data.isError = false;
    return { type: types.CMS_COMPONENT, data };
  };
  const fetching = () => {
    const data = {
      isFetching: true,
      isSuccess: false,
      isError: false,
    };
    return { type: types.CMS_COMPONENT_FETCHING, data };
  };
  const initial = () => {
    const data = {
      isFetching: false,
      isSuccess: false,
      isError: false,
    };
    return { type: types.CMS_COMPONENT_FETCHING, data };
  };
  const getError = () => {
    const data = {
      isFetching: false,
      isSuccess: false,
      isError: true,
    };
    return { type: types.CMS_COMPONENT_ERROR, data };
  };

  
const setData = async (request, dispatch) => {
    dispatch(get(request));
  };
  const cleanData = async (dispatch) => {
    dispatch(initial());
  };
  
  
const getResourceSiteComponents = async (request, onSuccess,dispatch, onError) => {
    dispatch(fetching());
  const { ...rest} = request; 
  await fetch(`${process.env.REACT_APP_BFF_AUTH_API}${process.env.REACT_APP_BFF_AUTH_SITE_COMPONENTS}`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)    
  })
  .then((response) => {
      
      if(response.ok){ 
        return response.json();
      }
      if(response.status ==404){ 
       throw 'Data not found'
    }
  }) .then((data) => {
      if(onSuccess && typeof onSuccess === 'function'){
          dispatch(get(data));
          onSuccess(data);
      }
  })
  .catch(ex => {
      dispatch(getError());
      
      if(onError && typeof onError === 'function'){
          onError(ex);
      }
  });
}


const cmsAction = {
    getResourceSiteComponents,
    setData,
    cleanData
}
export default cmsAction;
