
import BannerVideoComponent from "@mzp/commons/dist/layout/BannerVideo";

const RightVideoSection = (props) => {
    const { options } = props;

    return (<section className="banner-video-right-section">
        <BannerVideoComponent {...options} />
    </section>);
}

export default RightVideoSection;