import { useEffect, useState } from "react";
import Header from "@mzp/commons/dist/headers/Header";
import languageConstants from "../../constants/languageConstants";
import { useDispatch, useGlobalState } from "../../store/StoreProvider";
import useChangeCurrency from "../../hooks/useChangeCurrency";
import useChangeLenguage from "../../hooks/useChangeLenguage";
import storage from "../../localstorage/storage";
import { useLocation, useNavigate } from "react-router-dom";
import useGetNameAuthStorage from "../../hooks/useGetNameAuthStorage";
import AuthAction from "../../actions/authAction";
import breakpointConstants from "../../constants/breakpointConstants";
import { useMediaQuery } from "react-responsive";
import businessModel from "../../constants/businessModel";

//TODO parametros del header
/*
    positionPage: para cambiar por idioma, es el index que identifica a la pagina por idioma,
    deben tener el mismo index para machearlo, solo existe en las paginas que usan slugPage.
    routeType: identificar que pagina se encuentra y hacer el cambio de idioma,
    solo para las paginas estaticas
    positionMenu: remarcar del menu en que seccion se encuentra, sin uso por ahora.
*/
const HeaderLayoutCustom = (props) => {
  const {
    positionPage,
    positionMenu,
    cart: cartPageView,
    user: userPageView,
    routeType,
    ...rest
  } = props;
  const {
    locale,
    currency,
    resourceSite,
    authSession,
    pageOptions,
    siteInformation,
  } = useGlobalState();
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const authConst = useGetNameAuthStorage();
  const isMobile = useMediaQuery({ maxWidth: breakpointConstants.SM });
  const isB2C =
    process.env.REACT_APP_BUSINESS.toUpperCase() === businessModel.B2C;
  const isB2B =
    process.env.REACT_APP_BUSINESS.toUpperCase() === businessModel.B2B;
  //TODO retorna el numero por la posicion del idioma
  const getValueIndexLanguage = (locale) => {
    const enumLang = Object.values(languageConstants);
    const auxLocale = enumLang.findIndex(
      (a) => a.toLowerCase() === locale.toLowerCase()
    );
    return auxLocale;
  };

  const [interLocaleIndex, setInterLocaleIndex] = useState(
    getValueIndexLanguage(locale)
  );
  const [currencyCode, setCurrencyCode] = useState(currency.code);

  useChangeCurrency(currencyCode);
  useChangeLenguage(interLocaleIndex, routeType, positionPage);

  //TODO controlado el stado por el useChangeLenguage
  //TODO recupera el index del select lenguage
  const onClickLenguage = (value) => {
    setInterLocaleIndex(value);
  };

  //TODO controlado el stado por el useChangeCurrency
  const onClickCurrency = (value) => {
    setCurrencyCode(value);
  };

  const onClickLogout = () => {
    storage.cleanSession(authConst);
    storage.removeObjectsWithExpiry();
    AuthAction.cleanAuth(dispatch);
    //redirect
    const location = {
      pathname: `/${locale}${resourceSite.options.redirectPageDefault}`,
    };
    navigate(location);
  };

  //TODO falta func logout,
  useEffect(() => {
    setInterLocaleIndex(getValueIndexLanguage(locale));
  }, [locale]);

  const renderMenu = () => {
    let showMenu = true;
    if (isB2C || (!isB2C && authSession.isSuccess)) {
      if (
        location.pathname.toLocaleLowerCase().search("/reserva") >= 0 ||
        location.pathname.toLocaleLowerCase().search("/checkout") >= 0 ||
        location.pathname.toLocaleLowerCase().search("/voucher") >= 0
      ) {
        showMenu = false;
      }
    }

    return showMenu;
  };

  const renderLanguage = () => {
    let show = false;

    if (
      location.pathname.toLocaleLowerCase().search("/reserva") >= 0 ||
      location.pathname.toLocaleLowerCase().search("/checkout") >= 0 ||
      location.pathname.toLocaleLowerCase().search("/voucher") >= 0
    ) {
      show = false;
    } else {
      if (resourceSite.isSuccess) {
        if (resourceSite.options.headerInformation.showLanguage) show = true;
      }
    }

    return show;
  };

  const renderCurrency = () => {
    let show = false;

    if (
      location.pathname.toLocaleLowerCase().search("/reserva") >= 0 ||
      location.pathname.toLocaleLowerCase().search("/checkout") >= 0 ||
      location.pathname.toLocaleLowerCase().search("/voucher") >= 0 ||
      location.pathname.toLocaleLowerCase().search("/mis-reservaciones") >= 0 ||
      location.pathname.toLocaleLowerCase().search("/my-reservations") >= 0
    ) {
      show = false;
    } else {
      show = isB2C ? true : authSession.isSuccess ? true : false;
    }

    return show;
  };
  // validate path home default
  resourceSite.options.home.href = resourceSite.options.home.hrefWithOutSession;
  if ( isB2B &&
    authSession.isSuccess
  ) {
    resourceSite.options.home.href = resourceSite.options.home.hrefWithSession;
  }
  const AddClases = () => {
    var isHome = location.pathname.toLocaleLowerCase().length < 7;
    var isCheckOut =
      location.pathname.toLocaleLowerCase().search("/reserva") >= 0 ||
      location.pathname.toLocaleLowerCase().search("/checkout") >= 0;

    var isVoucher =
      location.pathname.toLocaleLowerCase().search("/voucher") >= 0;

    var nameClass = "";
    const customStyle = {
      home: "headerHome",
      headerCheckout: " headerCheckOut",
    };

    if (isHome) nameClass = nameClass + customStyle.home;
    if (isCheckOut || isVoucher)
      nameClass = nameClass + customStyle.headerCheckout;
    return nameClass;
  };

  return (
    <Header
      classNameHeader={AddClases()}
      languagesItems={resourceSite.options.languagesItems}
      currenciesItems={resourceSite.options.currenciesItems}
      locale={'/'+locale}
      home={resourceSite.options.home}
      onClickLenguage={onClickLenguage}
      onClickCurrency={onClickCurrency}
      onClickLogout={onClickLogout}
      valueLanguage={interLocaleIndex}
      valueCurrency={currency?.code}
      textCurrency={currency?.code}
      textLanguage={
        resourceSite.options.languagesItems[interLocaleIndex - 1]?.name ||
        "Lenguaje"
      }
      positionMenu={positionMenu}
      textUser={""} //TODO al tener session cambarlo por la variable
      cart={cartPageView} //TODO crear carrito func el carrito
      user={userPageView} //TODO crear session func, sesion de usuario
      {...rest}
      menuItems={
        process.env.REACT_APP_BUSINESS.toUpperCase() === businessModel.B2B
          ? resourceSite.options.menu.filter(
              (c) => c.authentication == authSession.isSuccess
            )
          : resourceSite.options.menu
      }
      showLanguage={renderLanguage()}
      showMenu={renderMenu()}
      showCurrency={renderCurrency()}
      authSession={
        process.env.REACT_APP_BUSINESS.toUpperCase() !== businessModel.B2C
          ? authSession.isSuccess
            ? {
                sessionItems: resourceSite.options.sessionItems,
                ...authSession,
              }
            : null
          : null
      }
    />
  );
};

HeaderLayoutCustom.defaultProps = {
  positionMenu: -1,
};

export default HeaderLayoutCustom;
