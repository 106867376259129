import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { Container, Breadcrumb } from "react-bootstrap";
import Gallery from "../../../components/galleries/Collage/Collage";
import { useGlobalState, useDispatch } from "../../../store/StoreProvider";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";
import toursValidate from "../../../validate/toursValidate";
import serviceTourAction from "../../../actions/serviceTourAction";
import BoxBooking from "../../../components/bookings/BoxBooking/BoxBooking";
import serviceType from "../../../constants/serviceTypeConstant";
import checkoutUtils from "../../../utils/checkoutUtils";
import storage from "../../../localstorage/storage";
import BoxDetailSeviceTitleRate from "../../../components/boxes/BoxDetailSeviceTitleRate";
import iconContants from "../../../constants/iconContants";
import BoxIconText from "../../../components/boxes/BoxIconText";
import SkeletonGallery from "../../../components/skeleton/SkeletonGallery/SkeletonGallery";
import SkeletonDetail from "../../../components/skeleton/SkeletonDetail/SkeletonDetail";
import CardTourActivity from "../../../components/cards/CardTourActivity";
import iconUtils from '../../../utils/iconUtils'
import ReadMore from '../../../components/buttons/ReadMore/ReadMore'
import "./style.scss";

const TourDetailPage = (props) => {
  const { locale, resourceSite } = props;
  const pathnames = resourceSite.siteRoutes;
  const searchTourParams = resourceSite.searchTourParams;
  const intl = useIntl();
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { hotel, currency, authSession } = useGlobalState();
  const localizedUrlBooking = `/${locale}${pathnames.reservation.pathname}`;
  let [searchParams, setSearchParams] = useSearchParams();

  const [showAboutHotel, setShowAboutHotel] = useState(false);
  const [bodyRenderScroll, setBodyRenderScroll] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [expandedAmenities, setExpandedAmenities] = useState(true);
  
  const GetIdService = () => {
    const { id } = useParams();
    return id;
  };

  const [query, setQuery] = useState({
    keyword: GetIdService(),
    checkin: searchParams.get("checkin"),
    checkout: searchParams.get("checkout"),
    paxes: searchParams.get("paxes"),
  });
  const [params, setParams] = useState(null);

  if (typeof window !== 'undefined') {
    window.onscroll = function () {
      var y = window.scrollY;
      const elem = document.querySelector("#body-render");
      const rect = (elem != null && elem !== undefined && elem && elem.offsetTop) ? elem.offsetTop : 0;

      setBodyRenderScroll(y > (rect + 10))
    };
  }

  const getTour = async (parameters) => {
    const query = toursValidate.formatQueryDetailsFromParams({
      ...parameters,
      currency: currency.code,
    });

    if (query != null) {
      await serviceTourAction.tourMock(
        {...query, locale, sourceMarket: "MX", authSession},
        dispatch,
        (response) => {
          let par = { ...parameters };
          par.inputAutoValueStart.label = response.name;
          setParams(par);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const getCardsDetail = async (parameters) => {
    const query = toursValidate.formatQueryDetailsFromParams({
      ...parameters,
      currency: currency.code,
    });

    if (query != null) {
      await serviceTourAction.ActivitiesMock(
        {...query, locale, sourceMarket: "MX", authSession},
        dispatch,
        (response) => {
          let par = { ...parameters };
          par.inputAutoValueStart.label = response.name;
          setParams(par);
        },
        (error) => {
          console.error(error);
        }
      );
    }
    hotel.rooms = [];

    onClickToScrollRooms("#service-detail-container")
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    const auxParams = toursValidate.paramsTourFormat(query, searchTourParams.paramsRoom.rules)
    setParams(auxParams);
    getTour(auxParams);
  }, [currency, location]);

  //TODO get select room to go to reservation
  const onClickReservation = (room) => {
    const newHotel = { ...hotel };
    newHotel.rooms = [];
    newHotel.rooms.push(room);
    const request = checkoutUtils.reservationTour(newHotel, params);
    storage.setLocalStorage(request.uuid, request, 15);
    window.location.href= `${localizedUrlBooking}?${new URLSearchParams({ cart: request.uuid }).toString()}`;
  };

  const getInfoDetailTour = () => {
    return {
      type: serviceType.TOUR,
      informationBoxTitle: {
        name: hotel.name,
        stars: hotel.stars,
        zone: {
          name: hotel.zone,
        },
        destination: {
          name: "",
        },
      },
      informationBoxServiceRate: {
        classs: "bg-rate",
        textFrom: intl.formatMessage({ id: "general.from" }),
        rate: hotel.bestRate?.totalPerNight,
        currency: currency.code,
        informations: [
          intl.formatMessage({ id: "general.bestPricePerRoom" }),
          intl.formatMessage({ id: "general.bestPricePerRoomTaxes" }),
        ],
        textButton: intl.formatMessage({ id: "general.moreRooms" })
      },
      informationBoxIconText: [{
        icon: iconContants.MARTINI,
        classIcon: "",
        text: hotel.bestRate?.mealPlan,
        classTex: "",
      }
      ]
    }
  }
  const onClickToScrollRooms = (id) => {
    document.querySelector(id).scrollIntoView({
      behavior: 'smooth'
    })
  }

 const resetInitialRender = (searchBooking) => {

  if (
    searchBooking.inputAutoValueStart?.type === "Tour" ||
    searchBooking.inputAutoValueStart?.type === "TourDetails"
  ) {
    const auxQuery = {
      keyword: searchBooking.inputAutoValueStart.keyword,
      checkin: searchBooking.startDate,
      paxes: toursValidate.paxesQuery(searchBooking.rooms)
    };

   setQuery(auxQuery);
   const auxParams = toursValidate.paramsHotelFormat(auxQuery, searchTourParams.paramsRoom.rules);
   setParams(auxParams);
   getTour(auxParams);
  }
}

const onChangeBooking  = async (searchBooking) => {
  resetInitialRender(searchBooking);
}

const handleExpanded = () => setExpanded((prevExpanded) => !prevExpanded)
const handleExpandedAmenities = () => setExpandedAmenities((prevExpanded) => !prevExpanded)



  return (
    <div id="body-render">
      <div id="booking-hotel" className={`search-box-service-hotel  ${bodyRenderScroll ? 'box-fixed' : ''}`}>
        <div className="pt-3">
          <Container fluid="xl">
              <Breadcrumb className="d-none d-sm-block">
                <Breadcrumb.Item href={`/${locale}`}>{intl.formatMessage({
                  id: "general.breadcrumdHome",
                })}</Breadcrumb.Item>
                <Breadcrumb.Item active={false}>
                  {intl.formatMessage({ id: "general.breadcrumdTours", })}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{hotel?.name}</Breadcrumb.Item>
              </Breadcrumb>
          </Container>
        </div>
        {params && (
            <Container fluid="xl">
              <BoxBooking
                params={params}
                locale={locale}
                siteRoutes={resourceSite.siteRoutes}
                componentTitle="h2"
                closeColorButton="white"
                type={serviceType.TOUR}
                searchHotelParams={searchTourParams}
                onChangeBooking={onChangeBooking}
                isEditable={false}
                viewBooking={true}
                isDetailPage={true}
                getCardsDetail={getCardsDetail}
              />
            </Container>
        )}
      </div>

      {hotel.isFetching && (
         <SkeletonGallery  />
      )}  

      {hotel.isSuccess && (
        <Gallery locale={locale} gallery={hotel.gallery} />
      )}

      <div>
        {hotel.isFetching && (
        <Container className="pt-4 pb-4">
          <SkeletonDetail  />
        </Container>          
        )} 
        <Container className="pt-4 pb-4">
          {hotel.isSuccess && (
            <BoxDetailSeviceTitleRate
              {...getInfoDetailTour()}
              service={hotel}
              onClickButton={() => onClickToScrollRooms("#title-hotel")}
            />
          )}
        </Container>
      </div>
      { hotel.isSuccess && (
        <div className="pt-5 pb-4 bg-light">
          <Container fluid="xl">
            <div className="container-information-service-bg p-4 bg-white">
              <h2 id="title-hotel" className="details-title-description">
                {intl.formatMessage({ id: "general.titleActivity" })}
              </h2>
              <div>
                { hotel.rooms.map((room, i) => {
                  return ( <CardTourActivity key={i}
                    classs={"mb-3"}
                    room={room}
                    rateDescriptions={[
                      intl.formatMessage({ id: "general.bestPricePerRoomTaxes" }),
                    ]}
                    textButton={intl.formatMessage({ id: "general.reservation" })}
                    onClickReservation={() => onClickReservation(room)}
                  />)
                })}
              </div>
            </div>
          </Container>
        </div>
      )}


      {hotel.isSuccess && (
        <div className="pb-4 bg-light">
          <Container fluid="xl">            
          <div className='container-information-service-bg p-4 bg-white mb-4' >
            <div className={ `${expanded && 'box-tittle-column-state'}`}>
            <h2 className="details-title-description">{intl.formatMessage({ id: "tourDetailPage.titleInfoGeneral" })}</h2>
              <div className="list-info">
                <h5><b>{intl.formatMessage({ id: "tourDetailPage.infoCancelation" })}</b></h5>
                <p><span className="container-icon me-2">{iconUtils.icon(iconContants.AIOUTLINECHECK)}</span> 
                
                { intl.formatMessage({ id: "tourProvisional.general.1" })}
                </p>
              </div>
              <div className="list-info">
                <h5><b>{intl.formatMessage({ id: "tourDetailPage.infoCovid" })}</b></h5>
                <p><span className="container-icon me-2">{iconUtils.icon(iconContants.AIOUTLINECHECK)}</span>
                { intl.formatMessage({ id: "tourProvisional.general.2" })}
                </p>
              </div>
              <div className="list-info">
                <h5><b>{intl.formatMessage({ id: "tourDetailPage.infoValidateDay" })}</b></h5>
                <p><span className="container-icon me-2">{iconUtils.icon(iconContants.AIOUTLINECHECK)}</span>
                { intl.formatMessage({ id: "tourProvisional.general.3" })}
                </p>
              </div>
              <div className="list-info">
                <h5><b>{intl.formatMessage({ id: "tourDetailPage.infoLanguaje" })}</b></h5>
                <p><span className="container-icon me-2">{iconUtils.icon(iconContants.AIOUTLINECHECK)}</span>             
                  { intl.formatMessage({ id: "tourProvisional.general.4" })}
                </p>
              </div>
            </div>
              <ReadMore 
              handleExpanded={handleExpanded}
              text={expanded ? intl.formatMessage({id: "general.seeMore",}) : intl.formatMessage({id: "general.seeLess",})}   
              icon={expanded ?  iconUtils.icon(iconContants.IOIOSARROWUP) : iconUtils.icon(iconContants.IOIOSARROWDOWN)  }
              />
            </div>

            {hotel.services && (
              <div className="container-information-service-bg p-4 bg-white mb-4">
                <h2 className="details-title-description">{intl.formatMessage({ id: "tourDetailPage.titleExperience" })}</h2>
                <div  className={ `box-hashotel-column ${expandedAmenities && 'box-tittle-column-state'}` } >
                  {hotel.services.map((item, i) => {
                    return (<div className="box-hashotel-item" key={i}>
                      <h5 className="py-3" ><b>{item.name}</b></h5>                     
                      <BoxIconText items={item.items.map((itm) => {
                        return {
                          icon: iconContants.FICHECK, 
                          classIcon: "color-primary",
                          text: itm,
                        }
                      })} />
                    </div>)
                  })}
                </div>
                <ReadMore 
                handleExpanded={handleExpandedAmenities}
                text={expandedAmenities ? intl.formatMessage({id: "general.seeMore",}) : intl.formatMessage({id: "general.seeLess",})}   
                icon={expandedAmenities ?  iconUtils.icon(iconContants.IOIOSARROWUP) : iconUtils.icon(iconContants.IOIOSARROWDOWN)  }
               />
              </div>
            )}
          </Container>
        </div>
      )}
    
    </div>
  );
};

export default TourDetailPage;
