import dateUtils from "../../../../utils/dateUtils";

const dateLimitCancellationBodyTemplate = (rowData, locale) => {
    return rowData.hasCancellationPolicies ? dateUtils.formatDateLocale(rowData.dateLimitCancellation, locale): "---";
}

const funcsTable = {
    dateLimitCancellationBodyTemplate,
}

export default funcsTable;