import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { Container, Row, Button, Col, Breadcrumb } from "react-bootstrap";
import { useGlobalState } from "../../../store/StoreProvider";
import { useSearchParams, useLocation } from "react-router-dom";
import hotelsValidate from "../../../validate/hotelsValidate";
import serviceListAction from "../../../actions/serviceListAction";
import serviceType from "../../../constants/serviceTypeConstant";
import BoxBooking from "../../../components/bookings/BoxBooking/BoxBooking";
import DrawerFilter from "../../../components/ui/DrawerFilter";
import CardContainerService from "../../../components/cards/CardContainerService";
import SkeletonCardList from "../../../components/skeleton/SkeletonCardList/SkeletonCardList";
import "../../hotel/HotelListPage/components/hotelListPage.scss"
import BoxWhitoutResults from "../../../components/boxes/BoxWhitoutResults/BoxWhitoutResults";

const PackageListPage = (props) => {
    const { locale, resourceSite } = props;
    const pathnames = resourceSite?.siteRoutes;
    const searchHotelParams = resourceSite?.searchHotelParams;
    const intl = useIntl();
    const location = useLocation();
    const { currency, authSession } = useGlobalState();
    const [searchParams] = useSearchParams();
    const [bodyRenderScroll, setBodyRenderScroll] = useState(false);

    //TODO render when change path url
    const query = {
        sort: searchParams.get("sort"),
        destination: searchParams.get("destination"),
        destinationkeyword: searchParams.get("destinationkeyword"),
        paxes: searchParams.get("paxes"),
        checkin: searchParams.get("checkin"),
        checkout: searchParams.get("checkout"),
    };

    const localizedUrlHotelDetails = `/${locale}${pathnames.package.pathname}`;
    const [params, setParams] = useState(null);
    const [show, setShow] = useState(false);
    const [filtersApply, setFiltersApply] = useState({});
    const [suggestionsDrawerFilter, setSuggestionsDrawerFilter] = useState([]);
    const [availability, setAvailability] = useState(null);
    const [hotelsFiltered, setHotelsFiltered] = useState([]);
    const [hotels, sethotels] = useState({
        items: [],
        isFetching: false,
        filters: [],
    });
    const [cardsView, setCardsView] = useState({
        total: 0,
        items: [],
        pageSize: 0,
        loading: true,
    });
	const [countHotels, setCountHotels] = useState("");

    const gridCards = (numberView, availability, newHotels, sort = true) => {
        const insideHotels = newHotels && newHotels.isFetching ? newHotels : hotels;
        let nCards = null;
        availability.orderBy = "category";

        if (availability.orderBy === "recommended") {
            nCards = !sort
                ? insideHotels.items
                : insideHotels.items.sort((a, b) => {
                      return a.price === b.price ? 0 : a.price > b.price ? 1 : -1;
                  });
            const listGeneral = nCards.filter(
                (meal) => meal.mealPlanCode !== "AI"
            );
            const listAllInclusive = nCards.filter(
                (meal) => meal.mealPlanCode === "AI"
            );
            const all = [...listAllInclusive, ...listGeneral];
            nCards = all;
        } else {
            nCards = !sort
                ? insideHotels.items
                : insideHotels.items.sort((a, b) => {
                      if (availability.orderBy === "desc")
                          return a.price === b.price ? 0 : a.price > b.price ? -1 : 1;
                      else if (availability.orderBy === "category")
                          return a.categoryStars > b.categoryStars ? -1 : 1;
                      else
                          return a.price === b.price ? 0 : a.price > b.price ? 1 : -1;
                  });
        }
		
		var filtered = nCards.length < hotels.items.length;
		var results = intl.formatMessage({
			id: filtered ? "general.resultsFilter" : (nCards.length === 1 ? "general.result" : "general.results")
		}, {
			count: nCards.length,
			total: hotels.items.length,
		});
		setCountHotels(results);

        return {
            total: insideHotels.items.length,
            items: nCards.length < numberView ? nCards : nCards.slice(0, numberView),
            pageSize: nCards.length < numberView ? nCards.length : numberView,
            loading: insideHotels.isFetching ? false : true,
        };
    };

    const getNumbreViewCards = (page) => {
        return 20 * page;
    };
    const onDrawerInputAutocompleteChange = (value, onSuccess) => {
        if (value != null && value !== "") {
            const hotelsFilters = value
                ? hotels.items
                      .filter(
                          (hotel) =>
                              hotel.name
                                  .toLowerCase()
                                  .indexOf(value.toLowerCase()) > -1
                      )
                      .map((hotel) => {
                          return {
                              id: hotel.id,
                              label: hotel.name,
                          };
                      })
                : [];
            setSuggestionsDrawerFilter(hotelsFilters);
            const newSuggetionsAutocomplete = {
                items: hotelsFilters.map((item) => {
                    return {
                        name: item.label,
                        code: item.id,
                    };
                }),
            };
            onSuccess(newSuggetionsAutocomplete);
        } else {
            applyFilters(null, "ID");
            applyFilters(null, "NAME");
        }
    };
    const onDrawerAutocompleteChange = (value) => {
        switch (typeof value) {
            case "object":
                applyFilters(
                    {
                        id: value.code,
                        name: value.name,
                    },
                    "ID"
                );
                break;
            case "string":
                applyFilters(value, "NAME");
                break;
            default:
                applyFilters(null, "ID");
                applyFilters(null, "NAME");
        }
    };
    const getHotels = (params, availability) => {
        const query = hotelsValidate.formatQueryDestinationFromParams(params);
        serviceListAction.hotels(
            {
                ...query,
                locale,
                sourceMarket: "MX",
                pathNext: localizedUrlHotelDetails,
                authSession
            },
            (response) => {
                sethotels(response);
                setCardsView(
                    gridCards(
                        getNumbreViewCards(availability.page),
                        availability,
                        response
                    )
                );
            },
            (error) => {
                console.error(error);
                sethotels({ items: [], filters: [], isFetching: true });
                setCardsView({
                    total: 0,
                    items: [],
                    pageSize: 0,
                    loading: false,
                });
            }
        );
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        resetInitialRender();
    }, [location, currency]);

    const onClickMoreServices = () => {
        const nAvailability = { ...availability, page: availability.page + 1 };
        setAvailability(nAvailability);
        let nCardsView = {
            ...cardsView,
            items: hotels.items,
            isFetching: true,
        };
        if (hotelsFiltered.length)
            nCardsView = {
                ...cardsView,
                items: hotelsFiltered,
                isFetching: true,
            };
        setCardsView(
            gridCards(
                getNumbreViewCards(nAvailability.page),
                nAvailability,
                nCardsView
            )
        );
    };

    if (typeof window !== "undefined") {
        window.onscroll = function () {
            var y = window.scrollY;
            const elem = document.querySelector("#body-render");
            const rect =
                elem != null && elem !== undefined && elem && elem.offsetTop
                    ? elem.offsetTop
                    : 0;

            setBodyRenderScroll(y > rect + 10);
        };
    }

    const onDrawerHandleToggle = (value) => applyFilters(value, value.code);
    const onClickOpenFilter = () => setShow((s) => !s);
    const getTagsFiltersApply = () => {
        var tags = [];
        Object.keys(filtersApply).forEach((key) => {
            var values = filtersApply[key];
            if (Array.isArray(values)) {
                values.forEach((v) => {
                    tags.push({
                        key: v.getId(key),
                        label: v.getValue(key),
                        type: key,
                    });
                });
            }
        });
        return tags;
    };

    const getSelectedsFiltersApply = () => {
        var tags = getTagsFiltersApply();
        return tags.map((tag) => tag.key);
    };

    const applyFilters = (value, type) => {
        const filters = { ...filtersApply };
        switch (type) {
            case "ID":
                filters[type] = value ? value.id : null;
                break;
            case "NAME":
                filters[type] = value ? value : null;
                break;
            default:
                if (!filters[type]) filters[type] = [];
                var _value = value.value.replace(type + "_", "");
                var currentIndex = filters[type].indexOf(_value);
                value.checked
                    ? filters[type].push(_value)
                    : filters[type].splice(currentIndex, 1);
                break;
        }

        setFiltersApply(filters);

        var sort = true;
        var items = hotels.items
            .filter(
                (h) =>
                    !filters.NAME ||
                    !filters.NAME ||
                    h.name.toLowerCase().includes(filters.NAME.toLowerCase())
            )
            .filter(
                (h) =>
                    !filters.MOSTUSED ||
                    !filters.MOSTUSED.length ||
                    filters.MOSTUSED.includes(h.type.clearValue()) ||
                    filters.MOSTUSED.includes(h.bestRate.mealPlan.clearValue())
            )
            .filter(
                (h) =>
                    !filters.STARS ||
                    !filters.STARS.length ||
                    filters.STARS.includes(h.category.toString())
            )
            .filter(
                (h) =>
                    !filters.MEALPLANS ||
                    !filters.MEALPLANS.length ||
                    filters.MEALPLANS.includes(h.bestRate.mealPlan.clearValue())
            )
            .filter(
                (h) =>
                    !filters.PROPERTY ||
                    !filters.PROPERTY.length ||
                    filters.PROPERTY.includes(h.type.clearValue())
            )
            .filter(
                (h) =>
                    !filters.SERVICES ||
                    !filters.SERVICES.length ||
                    filters.SERVICES.some((s) =>
                        h.amenities
                            .map((a) => a.clearValue())
                            .includes(s.clearValue())
                    )
            );

        if (filters.ID) {
            sort = false;
            items = items.filter((h) => h.id !== value.id);
            items.unshift(hotels.items.find((h) => h.id === value.id));
            filters.ID = null;
            setFiltersApply(filters);
        }

        setHotelsFiltered(items);

        const nAvailability = { ...availability, page: 1 };
        const nCardsView = { ...cardsView, items: items, isFetching: true };
        setAvailability(nAvailability);
        setCardsView(
            gridCards(
                getNumbreViewCards(nAvailability.page),
                nAvailability,
                nCardsView,
                sort
            )
        );
    };
    const resetInitialRender = () => {
        const auxParams = {
            ...hotelsValidate.paramsListFormat(
                query,
                searchHotelParams.paramsRoom.rules
            ),
            currency: currency?.code,
            locale,
        };
        setParams(auxParams);

        const auxAvailability = hotelsValidate.paramsListFormatAvailability(query);
        setAvailability(auxAvailability);

        sethotels({
            items: [],
            isFetching: false,
            filters: [],
        });
        setCardsView({
            total: 0,
            items: [],
            pageSize: 0,
            loading: true,
        });

        setFiltersApply({});
        setSuggestionsDrawerFilter([]);
        setHotelsFiltered([]);
        getHotels(auxParams, auxAvailability);
    };

    const onChangeBooking = () => {
        resetInitialRender();
    };

    return (
        <div id="body-render">
            <div
                id="booking-hotel"
                className={`search-box-service-hotel  ${
                    bodyRenderScroll ? "box-fixed" : ""
                }`} >
                <div className="pt-3">
                    <Container fluid="xl">
                        {!bodyRenderScroll && (
                            <Breadcrumb className="d-none d-sm-block">
                                <Breadcrumb.Item href={`/${locale}`}>
                                    {intl.formatMessage({
                                        id: "general.breadcrumdHome",
                                    })}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {intl.formatMessage({
                                        id: "general.breadcrumdPaquetes",
                                    })}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {params?.inputAutoValueStart?.label}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        )}
                    </Container>
                </div>
                {params && (
                    <div className="pb-2">
                        <Container fluid="xl">
                            <BoxBooking
                                params={params}
                                locale={locale}
                                siteRoutes={resourceSite?.siteRoutes}
                                closeColorButton="white"
                                type={serviceType.PACKAGE}
                                searchHotelParams={searchHotelParams}
                                onChangeBooking={onChangeBooking}
                                    
                            />
                        </Container>
                    </div>
                )}
            </div>
            <Container className=" mt-3 mb-2">
                <Row>
                    {cardsView.loading && <SkeletonCardList number={8} />}
                </Row>
                <Row>
                    <Col>
                        {!cardsView.loading && cardsView.pageSize > 0 && (
                            <h3 className="total-hotel">
                                {!cardsView.loading &&
                                    countHotels
								}
                            </h3>
                        )}
                        {!cardsView.loading && cardsView.pageSize === 0 && (
                            <>
                                <br />
                                <BoxWhitoutResults
                                    text={intl.formatMessage({
                                        id: "hotelListPage.hotelWhitoutResults",
                                    })}
                                />
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
            <Container className="mb-5">
				{!cardsView.loading && (
					<DrawerFilter
						filters={hotels.filters}
						selecteds={getSelectedsFiltersApply()}
						suggestions={suggestionsDrawerFilter}
						onInputAutocompleteChange={onDrawerInputAutocompleteChange}
						onAutocompleteChange={onDrawerAutocompleteChange}
						show={show}
						label={intl.formatMessage({ id: "filtro.nameHotel" })}
						onClickOpenFilter={onClickOpenFilter}
						handleToggle={onDrawerHandleToggle}
					/>
				)}

                <Row>
                    {cardsView &&
                        cardsView.items.map((card, index) => (
                            <CardContainerService
                                key={index}
                                item={card}
                                currency={currency?.code}
                                type={serviceType.HOTEL}
                                txt={intl.formatMessage({
                                    id: "text.pernight",
                                })}
                            />
                        ))}
                </Row>

                {cardsView.pageSize < cardsView.total && (
                    <Row>
                        <Col
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "10px",
                            }}>
                            <Button
                                variant="primary"
                                className="button-more-hotels justify-content-md-center"
                                onClick={onClickMoreServices}>
                                {intl.formatMessage({
                                    id: "hotelListPage.buttonTextMore",
                                })}
                            </Button>
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    );
};
// eslint-disable-next-line no-extend-native
String.prototype.getId = function (code) {
    return code + "_" + String(this).replace(/\s/g, "_").toUpperCase();
};
// eslint-disable-next-line no-extend-native
String.prototype.clearValue = function () {
    return String(this).replace(/\s/g, "_").toUpperCase();
};
// eslint-disable-next-line no-extend-native
String.prototype.getValue = function (type = null) {
    var _string = String(this);
    if (type && type === "STARS") {
        _string = _string + " " + (_string === "1" ? "Estrella" : "Estrellas");
    }
    return _string.replace(/_/g, " ").toPascalCase();
};
// eslint-disable-next-line no-extend-native
String.prototype.toPascalCase = function () {
    return String(this).replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
    });
};
export default PackageListPage;
