String.prototype.getId = function (code) {
	return code + "_" + String(this).replace(/\s/g, "_").toUpperCase();
};

String.prototype.clearValue = function () {
	return String(this).replace(/\s/g, "_").toUpperCase();
};

String.prototype.getValue = function (type = null) {
	var _string = String(this);
	if (type && type === "STARS") {
		_string = _string + " " + (_string === "1" ? "Estrella" : "Estrellas");
	}
	return _string.replace(/_/g, " ").capitalize(); 
};

String.prototype.toPascalCase = function () {
	return String(this).replace(/(\w)(\w*)/g, function (g0, g1, g2) {
		return g1.toUpperCase() + g2.toLowerCase();
	});
};

String.prototype.capitalize = function () {
	var str = String(this).toLowerCase();
	return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
};