import iconUtils from "../../../utils/iconUtils";
import "./components/label.scss"

const Label = (props) => {
	const { text, sxText, typeIcon, sxIcon, sxBox } = props;
	return (
		<label className="container-label" style={sxBox}>
			{typeIcon && (<span className="icon" style={sxIcon}> {iconUtils.icon(typeIcon)}</span>)}
			{text && (<span className="text" style={sxText}> {text}</span>)}
		</label>
	);
}

export default Label;
