import React from "react";
import { useIntl } from "react-intl";
import Carousel from 'react-bootstrap/Carousel';
import BoxIconText from "../../../components/boxes/BoxIconText";
import iconContants from "../../../constants/iconContants";
import { Container, Row, Button, Col } from "react-bootstrap";
import rateUtils from "../../../utils/rateUtils";
import "./components/style.scss";
import iconUtils from '../../../utils/iconUtils'
import { useGlobalState } from "../../../store/StoreProvider";

const BoxInfoRoom = (props) => {
	const { item, textButton, classTachado, textFrom, informations } = props;
	const { locale, currency } = useGlobalState();
	const intl = useIntl();
	const onClick = () => {
		const { onClick } = props;
		onClick && onClick();
	}

	return (
		<div className="container-info-room">
			<Container>
				<Row>
					<Col md={{ span: 6, order: 'last' }} className="mb-4">
						<Carousel>
							{item.photos && item.photos.length > 0 && (
								item.photos.map((img, index) => {
									return (
										<Carousel.Item interval={20000} key={index}>
											<img loading="lazy"
												className="d-block w-100 info-room-slide-img"
												src={img.photoUris}
												alt={item.name}
											/>
										</Carousel.Item>
									);
								})
							)
							}
						</Carousel>
						<div className="contant-btn">
							<div className="box-service-rate ">
								{textFrom && (<div className="rate-from">{textFrom}</div>)}
								{item.hasOffers && item.offerSale > 0 && (
									<div className={`rate-offe ${classTachado}`}>
										<span>{rateUtils.formatAmount(item.pricewithoutOffer, item.currency, locale)}</span>
									</div>
								)}
								<small className="text-muted">{intl.formatMessage({ id: "text.pernight" })}</small>
								<div className="rate">{rateUtils.formatAmount(item.rate, item.currency, locale)}</div>
								{intl.formatMessage({ id: "general.totalPrice" })}  {rateUtils.formatAmount(item?.total, currency.code, locale)}
								<small className="text-muted">{intl.formatMessage({ id: "text.tax" })}</small>
							</div>
							<Button className="btn-reservation" onClick={onClick}>{textButton}</Button>
						</div>
					</Col>
					<Col md={{ span: 6, order: 'first' }} >
						<h3 className="title">{item.name}</h3>
						{item && item.mealPlan && (
							<BoxIconText
								items={[{
									icon: iconUtils.getIconMealPlanType(item.mealPlan.code),
									text: item.mealPlan?.name
								}]}
							/>
						)}
						<br />
						<h4 className="subtitle">{intl.formatMessage({ id: "hotelDetailPage.titleCancelationPolicies" })}</h4>
						<p>{item.cancellationPolicies}</p>
						{item.amenities && (
							<>
								<h4 className="subtitle">{intl.formatMessage({ id: "hotelDetailPage.titleAminities" })}</h4>
								<h5 className="mt-4 subtitle">{item.amenities[0]?.name}</h5>

								<div className="content-list-ameni">
									<BoxIconText items={item.amenities[0].amenities.map((item, i) => {
										return {
											icon: item.included == true ? iconContants.FICHECK : iconContants.DOLLARSIGN,
											classIcon: "color-primary",
											text: item.name,
										}
									})} />
								</div>
							</>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	)
};

BoxInfoRoom.defaultProps = {
}
export default BoxInfoRoom;
