import Table from "react-bootstrap/Table";
import Placeholder from "react-bootstrap/Placeholder";

const SkeletonTable = () => {
  return (
    <Table bordered hover responsive="lg">
      <thead>
        <tr>
          {Array.from({ length: 3 }).map((_, index) => (
            <th key={index}>
              <Placeholder className="w-100" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 3 }).map((_, i) => (
          <tr key={i}>
            {Array.from({ length: 3 }).map((_, index) => (
            <td key={index}>
              <Placeholder className="w-100" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
            </td>
          ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SkeletonTable;
