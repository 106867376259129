import localeUtils from "../utils/localeUtils";

// variables to cancel previous api call
let controller, signal;

const affiliationForm = async (request, onSuccess, onError) => {
  const { authSession, culture, ...rest } = request;

  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  signal = controller.signal;

  await fetch(
    `${process.env.REACT_APP_BFF_API}contact/affiliation?culture=${culture}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      signal,
      body: JSON.stringify({ ...rest }),
    }
  )
    .then((response) => {
      if (response.ok) return response.json();
      throw new Error("Something went wrong.");
    })
    .then((data) => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess({
          sent: data.sent,
        });
      }
    })
    .catch((ex) => {
      console.error(ex);
      if (onError && typeof onError === "function") {
        onError(ex);
      }
    });
};

const homeServiceAction = {
  affiliationForm,
};

export default homeServiceAction;
