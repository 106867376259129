// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "general.bestPricePerRoom": "Precio por noche",
  "general.vuelohotel": "AVÍON+HOTEL",
  "general.bestPricePerRoomTaxes": "Impuestos incluidos",
  "general.breadcrumdHome": "Inicio",
  "general.breadcrumdHotels": "Hoteles",
  "general.breadcrumdPaquetes": "Paquetes",
  "general.breadcrumdTours": "Tours",
  "general.breadcrumdTransfer": "Transfer",
  "text.price": "Precio",
  "text.tax": "Impuestos incluídos",
  "general.taxes": "Impuestos",
  "text.filter": "FILTROS",
	"text.filterClear": "Borrar Filtros",
  "text.pernight": "por noche",
  "general.from": "desde",
  "general.reservation": "Reservar",
  "general.seeMore": "Ver más",
  "general.seeLess": "Ver menos",
  "general.moreRooms": "Ver habitaciones",
  "general.moreInformation": "Ver más detalles",
  "general.titleRooms": "Habitaciones",
  "general.refundable": "Tarifa reembolsable",
  "general.noRefundable": "Tarifa no reembolsable",
  "general.checkin": "Llegada",
  "general.checkout": "Salida",
  "general.departure": "Ida",
  "general.arrival": "Vuelta",
  "general.night": "noches",
  "general.adults": "adultos",
  "general.adult": "adulto",
  "general.for": "por",
  "general.allinclusive": "Todo Incluido",
  "general.room": "Habitacion",
  "general.rooms": "Habitaciones",
  "general.discount": "Descuento",
  "general.discountText": "{count, plural, =0 {Tarifa especial} other {#% Descuento}}",
  "general.titleActivity": "Selecciona tu actividad",
  "general.features": "Características",
  "general.duration": "Duración:",
  "general.startTime": "Hora de inicio:",
  "general.endTime": "Hora de término:",
  "general.child": "niño:",
  "general.language": "Idiomas",
  "general.seeMoreDetails": "Más detalle",
  "general.total": "Total",
  "general.reservationName": "Nombre de la reservación",
  "general.passanger": "Pasajeros",
  "general.date": "Fecha",
  "general.startDate": "Inicio de actividad",
  "general.endDate": "Fin de Actividad",
  "text.pertransfer": "por traslado",
  "text.perperson": "por persona",
  "filtro.nameTour": "Nombre del tour",
  "filtro.nameTransfer": "Nombre del traslado",
  "filtro.nameHotel": "Nombre del hotel",
  "general.done": "Listo",
  "general.utility": "Utilidad",
  "general.placeholderAutocomplete": "¿A dónde quieres ir?",
  "general.noresultAutocomplete": "Por favor buscar por destino, alojamiento o lugar de interés.",
  "general.selectDestination": "Por favor  selecciona un destino",
  "general.result": "{count} resultado",
  "general.results": "{count} resultados",
  "general.resultsTotal": "{count, plural, one {# resultado} other {# resultados}}",
  "general.resultsFilter": "{count} de {total} resultados",
  "general.origin": "Origen",
  "general.destiny": "Destino",
  "general.persons": "Personas",
  "general.orderBy": "Ordenar por",
  "general.details": "Detalles",
  "general.measures": "Medidas de limpieza",
  "general.accept": "Aceptar",
  "general.cotiza": "¿Quieres  continuar con tu cotización? ",
  "general.cotiza.2": "¡Sigues ahí !",
  "general.roundtrip": "Reserva ida y vuelta",
  "general.hours": "horas",
  "general.table.room": "Habitación",
  "general.table.mealplan": "Plan",
  "general.table.publicRate": "Tarifa publica",
  "general.table.netRate": "Tarifa neta",
  "general.table.paymentLimit": "Limite pago",
  "general.table.cancelationLimit": "Cancelación",
  "general.table.refundable": "Reembolsable",
  "general.table.nonRefundable": "No Reembolsable",
  "general.statusConfirmed": "Confirmado",
  "general.statusCancelled": "Cancelado",
  "general.statusHold": "Mantener",
  "general.moreAvailableRooms": "Ver más habitaciones disponibles",
  "general.lessAvailableRooms": "Ver menos habitaciones",
  "general.noAvailable": "Lo sentimos, no hay habitaciones disponibles",
  "general.error": "Lo sentimos, se ha producido un error ",
  "general.typeDate": "Tipo de fecha",
  "general.totalPrice": "Total",
  "general.table.pricePerNight": "Por noche",
  "general.table.totalRate": "Tarifa total",
  "general.updated": "Operación exitosa",
  "general.infoSaved": "Se ha realizado la acción",
  "general.changes": "Lo sentimos, no se ha podido realizar los cambios",
  "general.gallery": "Galeria",
  "general.grid": "Mosaico",
  "general.all": "TODOS",
  "general.notFoundText": "La página que estás buscando no existe",
  "general.notFoundTitle": "Página no encontrada",
  "general.goHome": "Ir a la Home",
  "general.autoI": "¿Hacia dónde vas?",
  "general.roomI": "¿Cuándo?",
  "general.paxI": "Habitación",
  "genera.autoP": "Buscar un lugar u hotel",
  "general.action": "Acción",
	"general.notAvailable": "No disponible"
};
