

import { useState } from 'react';
import { useIntl } from "react-intl";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import iconUtils from '../../../utils/iconUtils';
import BoxClubFlight from '../BoxClubFlight/BoxClubFlight';
import iconContants from '../../../constants/iconContants';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import BoxIconText from '../BoxIconText';
import Tooltip from 'react-bootstrap/Tooltip';
import rateUtils from "../../../utils/rateUtils";
import "./components/style.scss";
import { Row, Col } from 'react-bootstrap';
import BoxDetailBaggageFlight from '../BoxDetailBaggageFlight/BoxDetailBaggageFlight';


//TODO card for rooms in hotel detail
function BoxFamilyRateFlight(props) {
  const { typeFamily,  classs, family, typePrice, typeCategory, position } = props;
  const intl = useIntl();
  const renderTooltip = (props) => (
    <Popover id="button-tooltip" {...props}>
        <span className="p-2 d-block toolTip-list">
            <div className="tooltip-text">
                <div className="icon-warning me-2">{iconUtils.icon(iconContants.BSEXCLAMATIONTRIANGLEFILL)}</div>
                <span>Las dimensiones permitidas son <strong>55 x 40 x 25 cm</strong>  y que su peso no exceda los <strong>10 kgs </strong> entre el objeto personal y el equipaje de mano light sin afectar la comodidad y seguridad de los pasajeros.</span>
            </div>         
        </span>
    </Popover>
  );
  const [openModalBaggage, setOpenModalBaggage] = useState(false);  
  const [openModalClub, setOpenModalClub] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);

  function handleOpenClub(breakpoint) {
    setFullscreen(breakpoint);
    setOpenModalClub(!openModalClub);
  }
  function handleOpenModalBaggage(breakpoint){
    setFullscreen(breakpoint);
    setOpenModalBaggage(!openModalBaggage);
  }

  const handleCloseModal = () => setOpenModalClub(false);
  const handleCloseModalBaggage = () => setOpenModalBaggage(false)

  const [selectType, setSelectType] =useState('');
  const [selectTypeCategory, setSelectTypeCategory] =useState('');

  const onClickRate = (tPrice, tCategory) => {
    if(tPrice == "club" && position == "1"){
      setSelectType(tPrice)
      setSelectTypeCategory(tCategory)
      handleOpenClub('md-down')
    }else{
    /*   localStorage.setItem('tPrice', JSON.stringify(tPrice));
      localStorage.setItem('tCategory', JSON.stringify(tCategory)) */

      const {onClickRate} = props;
      onClickRate && onClickRate(tPrice, tCategory);
    }   
  }
  //select with modal club 
  const onClickRateClub = (value, newvalue) => {
      setOpenModalClub(false)
     /*  localStorage.setItem('tPrice', JSON.stringify(value));
      localStorage.setItem('tCategory', JSON.stringify(newvalue)) */
      const {onClickRate} = props;
      onClickRate && onClickRate(value, newvalue);
  }
  // change type club a regular 
  const changeType = (value) => {
    setOpenModalClub(false)
    const {changeType} = props;
    changeType && changeType(value);
 }

  return (
    <div className='flightItemDetails'>
       {typeFamily && typeFamily == "regular" && (
      <div className={`fareTypes fareTypeRegular ${classs ? classs : ''}`}>
        <Row>
            {family.rates.map((item, index) => {  
              return (<Col md={4} className="position-relative" key={index}>
                <div  disabled={typeCategory == item.tag && position == "2"  ? false : typeCategory == ""  && position == "1"  ? false : true} className={`mat-card ${item.tag == 'plus' ? 'plus'  : item.tag == 'classic' ? 'classic' : 'basic' }`} >
                <div className='overlayGo' onClick={() => onClickRate(item.type, item.tag)}></div>
                  {item.favorite && (
                    <div className="ribbon-container">
                      <span><small>Favorita</small></span>
                    </div>
                  )}
                  <div className='d-md-none'>
                    <div className='d-flex preferential-sm'>
                      <span>Tarifa preferencial</span>
                      <span>$220 <small>MXN</small></span>
                    </div>
                  </div>
                  <h3 className='mb-3 d-flex'>
                    <div className=''>
                      <span>{item.title}</span>
                      <span className='RegularSubtitle'>{item.textTravel}</span>
                    </div>
                    <div className='d-md-none'>
                        <span>$202 <small>MXN</small></span>
                    </div>                    
                  </h3>
                  <ul className='benefitsList'>
                    <li className="item-benefists">
                      <div className="d-flex align-items-baseline">
                          <span className="icon-item me-2">{iconUtils.icon(iconContants.BSFILLHANDBAGFILL)}</span>
                          <span className="d-flex">
                              <span className="textpar">
                                  <span className="personalItemLabel"> 1 Objeto personal </span>
                                  <span className="personalItemSubLabel">Debe caber debajo del asiento frente a ti  Por ejemplo: bolsa de mano, portafolio o porta laptop.</span>
                              </span>
                          </span>
                      </div>                 
                    </li>
                    <li className='item-benefists'>
                      <div className="d-flex align-items-baseline">
                          <span className="icon-item me-2">{iconUtils.icon(iconContants.BSFILLBAGFILL)}</span>
                          <span className="d-flex">
                              <span className="textpar">
                                  <span className="personalItemLabel"> 
                                      <span className=""> 1 equipaje de mano light</span>
                                      <span className="newBaggage tooltip-bag">
                                      <OverlayTrigger
                                          placement="bottom"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltip}
                                      >
                                      <span className="tooltipBag"> {iconUtils.icon(iconContants.BSEXCLAMATIONCIRCLEFILL)}</span>
                                      </OverlayTrigger> 
                                      </span>
                                  </span>
                                  <span className="personalItemSubLabel">Dimensiones máximas 55 x 40 x 25 cm</span>
                              </span>
                          </span>
                      </div>
                      <div className="personalItemSubLabel">
                          El peso del artículo personal y equipaje de mano light no debe exceder  <strong>10 kg</strong> 
                      </div>
                    </li>
                    <li className="item-benefists">
                      <div className="d-flex align-items-baseline disabled no-baggage-item">
                          <span className="icon-item me-2">{iconUtils.icon(iconContants.BSBAGDASFILL)}</span>
                          <span className="d-flex">
                              <span className="textpar">
                                  <span className="personalItemSubLabel"> Equipaje documentado no incluido</span>
                              </span>
                          </span>
                      </div>
                    </li>
                  </ul>
                  <div className='rate-box'>
                    <div className='d-none d-md-block preferential'>Tarifa preferencial</div>
                    <div className='d-none d-md-block priceDiscounted'> $220 MXN</div>
                    <div className='d-none d-md-block price'> $202 <small>MXN</small></div>
                  </div>
                  <button className='btn btn-small d-none d-md-block  mat-button-base mat-secondary'>
                    <span className='mat-button-wrapper'>Seleccionar</span>
                    <div className='mat-button-ripple mat-ripple'></div>
                    <div className='mat-button-focus-overlay'></div>
                  </button>
                  <div className='disabled-container'>
                    <div className='disabled-message'>
                      Elegiste {typeCategory == 'basic' ? "Básica" : typeCategory == 'classic' ? "Clásica" : "Plus"} para tu vuelo de salida, solo podrás seleccionar una tarifa del mismo tipo para tu vuelo de regreso. 
                    </div>
                  </div>
                </div>
              </Col>)
              })}
            </Row>
          </div>
          ) }
          {typeFamily && typeFamily == "club" && (
            <div className='fareTypes fareTypeVClub'>
                <Row>
                  {family.rates.map((item, index) => {  
                  return (<Col md={4} className="position-relative" key={index}>
                  <div   disabled={typeCategory == item.tag  && position == "2"  ? false :  typeCategory == ""  && position == "1"  ? false : true }  className={`mat-card ${item.tag == 'plus' ? 'plus'  : item.tag == 'classic' ? 'classic' : 'basic' }`}>
                    <div className='overlayGo' onClick={() => onClickRate(item.type, item.tag)}></div>
                    {item.favorite && (
                      <div className="ribbon-container">
                        <span><small>Favorita</small></span>
                      </div>
                    )}
                    <h3 className='mb-3'>
                      <span>{item.title}</span>
                      <span className='RegularSubtitle'>{item.textTravel}</span>
                    </h3>
                    <ul className='benefitsList'>
                      <li className="item-benefists">
                        <div className="d-flex align-items-baseline">
                            <span className="icon-item me-2">{iconUtils.icon(iconContants.BSFILLHANDBAGFILL)}</span>
                            <span className="d-flex">
                                <span className="textpar">
                                    <span className="personalItemLabel"> 1 Objeto personal </span>
                                    <span className="personalItemSubLabel">Debe caber debajo del asiento frente a ti  Por ejemplo: bolsa de mano, portafolio o porta laptop.</span>
                                </span>
                            </span>
                        </div>                 
                      </li>
                      <li className='item-benefists'>
                        <div className="d-flex align-items-baseline">
                            <span className="icon-item me-2">{iconUtils.icon(iconContants.BSFILLBAGFILL)}</span>
                            <span className="d-flex">
                                <span className="textpar">
                                    <span className="personalItemLabel"> 
                                        <span className=""> 1 equipaje de mano light</span>
                                        <span className="newBaggage tooltip-bag">
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip}
                                        >
                                        <span className="tooltipBag"> {iconUtils.icon(iconContants.BSEXCLAMATIONCIRCLEFILL)}</span>
                                        </OverlayTrigger> 
                                        </span>
                                    </span>
                                    <span className="personalItemSubLabel">Dimensiones máximas 55 x 40 x 25 cm</span>
                                </span>
                            </span>
                        </div>
                        <div className="personalItemSubLabel">
                            El peso del artículo personal y equipaje de mano light no debe exceder  <strong>10 kg</strong> 
                        </div>
                      </li>
                      <li className="item-benefists">
                        <div className="d-flex align-items-baseline disabled no-baggage-item">
                            <span className="icon-item me-2">{iconUtils.icon(iconContants.BSBAGDASFILL)}</span>
                            <span className="d-flex">
                                <span className="textpar">
                                    <span className="personalItemSubLabel"> Equipaje documentado no incluido</span>
                                </span>
                            </span>
                        </div>
                      </li>
                    </ul>
                    <div className='rate-box'>
                      <div className='d-none d-md-block preferential'>Tarifa preferencial</div>
                      <div className='d-none d-md-block priceDiscounted'> $220 MXN</div>
                      <div className='d-none d-md-block price'> $202 <small>MXN</small></div>
                    </div>
                    <button className='btn btn-small d-none d-md-block  mat-button-base mat-secondary'>
                      <span className='mat-button-wrapper'>Seleccionar</span>
                      <div className='mat-button-ripple mat-ripple'></div>
                      <div className='mat-button-focus-overlay'></div>
                    </button>
                    <div className='disabled-container'>
                      <div className='disabled-message'>
                        Elegiste {typeCategory == 'basic' ? "Básica" : typeCategory == 'classic' ? "Clásica" : "Plus"} para tu vuelo de salida, solo podrás seleccionar una tarifa del mismo tipo para tu vuelo de regreso. 
                      </div>
                    </div>
                  </div>
                </Col>)
                })}
            </Row>
            <BoxClubFlight 
                handleCloseModal={handleCloseModal} 
                show={openModalClub} 
                fullscreen={fullscreen} 
                scale={true} 
                selectType={selectType} 
                selectTypeCategory={selectTypeCategory} 
                onClickRateClub={onClickRateClub}
                changeType={changeType}
                />
        </div>
       ) }
       <div className='detail-baggage'>
         <div className='btn-bagagge' onClick={() => handleOpenModalBaggage('md-down')}>Ver detalle de equipaje</div>
         <div className='text-cabin'>En caso de no existir espacio suficiente en cabina, el mismo será documentado.</div>
       </div>
      <BoxDetailBaggageFlight show={openModalBaggage} fullscreen={fullscreen} handleCloseModalBaggage={handleCloseModalBaggage} />
    </div>
  );
}

export default BoxFamilyRateFlight;