import { useIntl } from "react-intl";
import BoxTitleCard from "../../../boxes/BoxTitleCard";
import rateUtils from "../../../../utils/rateUtils";
import iconContants from "../../../../constants/iconContants";
import iconUtils from "../../../../utils/iconUtils";
import serviceTypeConstant from "../../../../constants/serviceTypeConstant";
import { useGlobalState } from "../../../../store/StoreProvider";

const CardContent = (props) => {
	const { item, txt, type } = props;
	const intl = useIntl();
	const { locale, currency } = useGlobalState();
	const hasRate = item.bestRate ? true : false;
	return (
		<div className="card-content">
			<BoxTitleCard item={item} type={type} hasLink={hasRate}/>
			{hasRate && (<div className="container-flex">
				<div className="container-mealplan">
					<span className="mb-2">
						{(type === serviceTypeConstant.HOTEL || type === serviceTypeConstant.HOMEHOTEL) && (<>
							<i className="me-1">{iconUtils.icon(iconUtils.getIconMealPlanType(item.mealPlan?.code))}</i>
							{item.mealPlan?.name}
						</>)}
					</span>
					<div style={{ display: "grid" }}>
						<span
							className="container-duration"
							style={{
								display: "flex",
								verticalAlign: "middle",
							}}>
							{item?.maxNumberPassengers && (
								<>
									Max :
									<span className="icono">
										{" "}
										{iconUtils.icon(
											iconContants.BSFILLPERSONFILL
										)}{" "}
									</span>
									<span style={{ marginRight: "5px" }}>
										{item.maxNumberPassengers}{" "}
									</span>
								</>
							)}
							{item?.maxNumberBags && (
								<>
									<span className="icono">
										{" "}
										{iconUtils.icon(
											iconContants.BRIEFCASE
										)}{" "}
									</span>
									<span style={{ marginRight: "5px" }}>
										{item.maxNumberBags}{" "}
									</span>
								</>
							)}
							{item.duration && (
								<>
									{intl.formatMessage({
										id: "general.duration",
									})}
									{item.duration}
								</>
							)}
						</span>
						<p
							className="box-item-text bold"
							style={{ marginTop: "5px" }}>
							{txt && txt}
						</p>
					</div>
				</div>
				<div className="box-price">
					<div
						className="rate-without-discount"
						style={{
							textDecoration: "line-through",
							height: "20px",
						}}>
						{
							item.hasOffers && item.offerSale > 0 ? rateUtils.formatAmount(item.bestRate?.totalWithOffersPerNight, currency.code, locale)
								? rateUtils.formatAmount(item.bestRate?.totalWithOffersPerNight, currency.code, locale)
								: rateUtils.formatAmount(item.bestRate?.pricewithoutOffer, currency.code, locale) : ""
						}
					</div>
					<div className="box-item-price">
						{rateUtils.formatAmount(item.bestRate?.totalPerNight, currency.code, locale)}
					</div>
					<p className="box-item-text">
						{intl.formatMessage({ id: "general.totalPrice" })} {rateUtils.formatAmount(item.bestRate?.total, currency.code, locale)}
					</p>
					<p className="box-item-text">
						{intl.formatMessage({ id: "text.tax" })}
					</p>
				</div>
			</div>)}
			{!hasRate && (
				<div className="container-not-avalability">
					<b>{intl.formatMessage({id: "general.notAvailable" })}</b>
				</div>
			)}

		</div>
	);
};

export default CardContent;
