
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "website.logout": "Cerrar Sesión",
    "website.header.lenguage": "Lenguaje",
    "website.header.currency": "Moneda",
    "website.searchFilter.labelAutocomplete": "Buscar por:",
    "website.searchBox.submitHotel": "Listo",
    "website.searchBox.addRoomHotel": "Agregar habitación",
    "website.searchBox.removeRoomHotel": "Eliminar",
    "website.adultTotal": "{count, plural, one {# adulto} other {# adultos}}",
    "website.guestTotal": "{count, plural, one {# huésped} other {# huéspedes}}",
    "website.peopleTotal": "{count, plural, one {# persona} other {# personas}}",
    "website.passengerTotal": "{count, plural, one {# pasajero} other {# pasajeros}}",
    "website.roomTotal": "{count, plural, one {# habitación} other {# habitaciones}}",
    "website.childrenTotal": "{count, plural, =0 {sin menores} one {# menor} other {# menores}}",
    "website.nightsTotal": "{count, plural, =0 {# noches} one {# noche} other {# noches}}",
}