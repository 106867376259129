import iconUtils from "../../../../utils/iconUtils";
import iconContants from "../../../../constants/iconContants";
import languageConstants from "../../../../constants/languageConstants";
import moment from "moment/moment";
import images from "../../../../theme/ImageSite";

const startDate= moment(new Date()).add(7,'days').format("yyyy-MM-DD");
const endDate= moment(new Date()).add(9,'days').format("yyyy-MM-DD");

const footerComponent = {
    type: "footer",
    showSocial: true,
    showText: true,
    secondMenu: false,
    contact: false,
    footer: {
        alt: "Nexus Vacations",
        href: `/${languageConstants.EN}`,
        imgPath: images.footerLogo ?? '',
        textMini:"Get social with us",
        columnsLinks: [
            {
              textTitle: "About us:",
              links: [
                {
                  text: "Who we are",
                  url: `https://corporate.nexustours.com/corporate/who-we-are/`,
                  hasLink: true,
                  applyExternalLink: true,
                  target:"_blank"
                },
                {
                  text: "Privacy policy",
                  url: `https://www.nexustours.com/privacy-policy`,
                  hasLink: true,
                  applyExternalLink: true,
                  target:"_blank"
                },
                {
                  text: "Groups",
                  url: `https://www.nexustours.com/groups-and-weddings`,
                  hasLink: true,
                  applyExternalLink: true,
                  target:"_blank"
                },
                {
                  text: "Our Destinations",
                  url: `https://corporate.nexustours.com/groups/destinations/`,
                  hasLink: true,
                  applyExternalLink: true,
                  target:"_blank"
                },
                {
                  text: "Travel Blog",
                  url: `https://www.nexustours.com/travel-blog`,
                  hasLink: true,
                  applyExternalLink: true,
                  target:"_blank"
                },
                {
                  text: "Be Safe with Nexus",
                  url: `https://www.nexustours.com/besafe`,
                  hasLink: true,
                  applyExternalLink: true,
                  target:"_blank"
                },
                {
                  text: "Terms and Conditions",
                  url: `https://www.nexustours.com/terms-and-conditions`,
                  hasLink: true,
                  applyExternalLink: true,
                  target:"_blank"
                },
                {
                  text: "Contact",
                  url: `https://www.nexustours.com/contact-us`,
                  hasLink: true,
                  applyExternalLink: true,
                  target:"_blank"
                },
              ],
            },
            {
              textTitle: "Users",
              links: [
                {
                  text: "User Agreement",
                  url: `https://www.nexustours.com/user-agreement`,
                  hasLink: true,
                  applyExternalLink: true,
                  target:"_blank"
                },
                {
                  text: "FAQ",
                  url: `https://www.nexustours.com/faq`,
                  hasLink: true,
                  applyExternalLink: true,
                  target:"_blank"
                },
                {
                  text: "Agency Access",
                  url: `https://tpp.nexustours.com/login/`,
                  hasLink: true,
                  applyExternalLink: true,
                  target:"_blank"
                },
              ],
            },
          ],
        social: [
            {
                icon: iconUtils.icon(iconContants.FAFACEBOOKF),
                url: "https://www.facebook.com/nexus.tours.activities",
                active: true
            },
            {
                icon: iconUtils.icon(iconContants.FATWITTER),
                url: "https://twitter.com/i/flow/login?redirect_after_login=%2FNexusTours",
                active: true
            },
            {
                icon: iconUtils.icon(iconContants.FAINSTAGRAM),
                url: "https://www.instagram.com/nexustours/",
                active: true
            },
            {
                icon: iconUtils.icon(iconContants.FaLINKEDIN),
                url: "https://www.linkedin.com/company/nexustours/",
                active: true
            },
            {
                icon: iconUtils.icon(iconContants.FATRIPADVISOR),
                url: "https://www.nexustours.com/tripadvisor-reviews",
                active: true
            },
        ],
    }
}

export default footerComponent;