import Placeholder from "react-bootstrap/Placeholder";
import { Card } from "react-bootstrap";
import "../../cards/CardHotelLarge/components/style.scss";

const SkeletonCardLarge = () => {
  return (
    <Card className="card-sv-large-b2b">
      <div className="content-box">
        <div className="card-content-img">
          <div className="card-img placeholder-img"></div>
        </div>
        <div className="card-sv-content-info">
          <div className="card-sv-content-info-start">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={9} md={6} />
            </Placeholder>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={8} md={8} />
            </Placeholder>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={8} md={8} />
            </Placeholder>
          </div>
          <div className="card-sv-content-info-end">
            <div className="w-100 text-end">
              <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
              <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={9} md={6} />
              </Placeholder>
              <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={8} md={8} />
              </Placeholder>
            </div>
            <Placeholder.Button
              variant="primary"
              xs={6}
              md={10}
              className="py-4"
            />
          </div>
        </div>
      </div>
      <div></div>
    </Card>
  );
};

export default SkeletonCardLarge;
