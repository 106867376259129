import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import DataReservationTable from "../../components/DataTable/DataReservationTable";
import FiltersTable from "../../components/DataTable/FiltersTable/FiltersTable";
import { useGlobalState } from "../../store/StoreProvider";
import reservationAction from "../../actions/reservationAction";
import reservationUtils from "../../utils/reservationUtils";
import "./style.scss";
const MyReservations = () => {
  const { authSession, locale } = useGlobalState();
  const [itemsReservations, setItemsReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState(reservationUtils.params());

  const _onSubmit = (params) => {
    setParams(params);
    reservations(params);
  };

  const reservations = (params) => {
    setLoading(true);
    setItemsReservations([]);
    reservationAction.getReservations(
      { ...params, authSession },
      (response) => {
        setItemsReservations(response.data);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      }
    );
  };

  const _updateTable = () => {
    reservations(params);
  };

  useEffect(() => {
    reservations(params);
    return () => reservations({});
  }, []);

  return (
    <div className="py-5 bg-light my-reservations">
      <Container fluid className="mt-3 mb-2">
        <div className="container-information-service-bg p-4 bg-white mb-4">
          <FiltersTable
            onSubmit={_onSubmit}
            _params={params}
            loading={loading}
            locale={locale}
          />
        </div>
        <div className="container-information-service-bg p-4 bg-white mb-4">
          <DataReservationTable
            {...authSession}
            updateTable={_updateTable}
            dataTable={itemsReservations}
            loading={loading}
          />
        </div>
      </Container>
    </div>
  );
};

export default MyReservations;
