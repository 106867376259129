import { useMediaQuery } from "react-responsive";
import SkeletonCardListHome from "../SkeletonCardListHome/SkeletonCardListHome";
import breakpointConstants from "../../../constants/breakpointConstants";

export const SkeletonThingsToDoHome = () => {
  const isMobile = useMediaQuery({ maxWidth: breakpointConstants.SM });
  const isTablet = useMediaQuery({ maxWidth: breakpointConstants.MD });
  const isDesktop = useMediaQuery({ maxWidth: breakpointConstants.LG });

  const getNumberbyResolution = () => {
    if (isMobile) return 1;
    if (isTablet) return 2;
    if (isDesktop) return 3;
    return 4;
  };

  return <SkeletonCardListHome number={getNumberbyResolution()} />;
};
