import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Modals = (props) => {
	const { title, showClose, fullscreen, show, head, size, className } = props;
	const handleClose = (status) => {
		const { handleCloseModal } = props;
		handleCloseModal && handleCloseModal(status);
	};

	return (
		<div className="container-modal">
			<Modal className={className} show={show} size={size} fullscreen={fullscreen} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
				{head && (
					<Modal.Header closeButton>
						<Modal.Title>{title}</Modal.Title>
					</Modal.Header>
				)}
				<Modal.Body>{props.children}</Modal.Body>
				{showClose && (
					<Modal.Footer>
						<Button onClick={handleClose}>Close</Button>
					</Modal.Footer>
				)}
			</Modal>
		</div>
	);
};

Modals.defaultProps = {
	head: true,
	className: ""
}

export default Modals;
