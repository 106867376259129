// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "hotelDetailPage.searchTitle" : "Los mejores hoteles alrededor del mundo.",
    "hotelDetailPage.breadcrumdHome" : "Inicio",
    "hotelDetailPage.breadcrumdHotels" : "Hoteles",
    "hotelDetailPage.titleAboutHotel": "Acerca del hotel",
    "hotelDetailPage.titlePolicies": "Políticas",
    "hotelDetailPage.titleCancellationPolicies": "Políticas de cancelación",
    "hotelDetailPage.titlePetFrendly": "Mascotas",
    "hotelDetailPage.titleAmenities": "Niños y camas adicionales",
    "hotelDetailPage.titlehasHotel": "El alojamiento ofrece", 
    "hotelDetailPage.titleAminities":"Amenidades en la habitación",
    "hotelDetailPage.titleModalRoom":"Información de la habitación",
    "hotelDetailPage.titleCancelationPolicies":"Políticas de cancelación",
    "hotelDetailPage.hotelWhitoutResults": "Por el momento no es posible encontrar lo que buscabas, por favor modificar tu búsqueda  para seguir  planificando tu viaje.",
	"hotelDetailPage.currentRoom": "Tu habitación actual"
}
