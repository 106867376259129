import moment from "moment";

//TODO recupera la informacion en general de la caja de busqueda que es la que se procesa para crear los links o la misma casa a tus componentes
//TODO mejorar para recuperar desde la url
const getParametersService = (type) => {
    let parameters = {};
    switch (type) {
        case "Hotel":
            parameters = {
                startDate: moment(new Date()).add(7, 'days').format("yyyy-MM-DD"),
                endDate: moment(new Date()).add(9, 'days').format("yyyy-MM-DD"),
                inputAutoValueStart: null,            
                rooms: [
                    {
                        adult: 2,
                        child: 0,
                        infant: 0,
                    },
                ],
            }
            break;
        case "Package":
            parameters = {
                startDate: moment(new Date()).add(7, 'days').format("yyyy-MM-DD"),
                endDate: moment(new Date()).add(9, 'days').format("yyyy-MM-DD"),
                inputAutoValueStart: null,
                inputAutoValueEnd: null,
                rooms: [
                    {
                        adult: 2,
                        child: 0,
                        infant: 0,
                    },
                ],
            }
            break;
        case "Transfer":
            parameters = {
                startDate: moment(new Date()).add(7, 'days').format("yyyy-MM-DD"),
                endDate: moment(new Date()).add(9, 'days').format("yyyy-MM-DD"),
                inputAutoValueStart: null,
                inputAutoValueEnd: null,
                roundTrip: true,
                numberDaysRoundTrip:2,
                rooms: [
                    {
                        adult: 2,
                        child: 0,
                        infant: 0,
                    },
                ],
            }
            break;
        default:
    }

    return parameters;
}
const getParamsRoomsService = (type, resourceSite) => {
    let paramsRooms = {};
    //TODO parametros salen de ./data/{locale}/searchParams.js
    //TODO si se necesita modificar parametros, es desde el archivo searchParams por lenguaje
    const { searchHotelParams, searchPackageParams, searchShutterParams, searchTourParams } = resourceSite;
    switch (type) {
        case "Hotel":
            paramsRooms = {
                // rules: {
                //     roomsMax: 8,
                //     personsTotal: 14,
                //     adultMinforRoom: 1,
                //     adultDefaultRoom: 2,
                //     adultMaxforRoom: 14,
                //     childMinforRoom: 0,
                //     childMaxforRoom: 6,
                //     infantMinforRoom: 0,
                //     infantMaxforRoom: 6,
                //     childYear: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
                // },
                // paramsRoom: {
                //     adultParams: {
                //         ariaLabelLess: "Quitar Adultos",
                //         ariaLabelMore: "Agregar Adultos",
                //         label: "Adultos",
                //         //subLabel: "> 12 años",
                //     },
                //     childParams: {
                //         ariaLabelLess: "Quitar niños",
                //         ariaLabelMore: "Agregar niños",
                //         label: "Niños",
                //         subLabel: "2 - 17",
                //     },
                //     infantParams: {
                //         ariaLabelLess: "Quitar Infantes",
                //         ariaLabelMore: "Agregar Infantes",
                //         label: "Infantes",
                //         subLabel: "Menores de 2 años",
                //     },
                // },
                // rulesDescriptions: {
                //     personsTotal: "Sobre pasa el numero de personas a reservar",
                //     yearChildren: "Selecciona la edad de los menores",
                //     emptyPersons: "Selecciona las personas de tu viaje",
                //     emptyChildren: "Falta agregar la edad de los menores",
                //     adult: "adulto",
                //     adults: "adultos",
                //     child: "niño",
                //     children: "niños",
                //     infant: "infante",
                //     infants: "infantes",
                //     person: "persona",
                //     people: "personas",
                //     guest: "Huésped",
                //     guests: "Huéspedes",
                // },
                ...searchHotelParams.paramsRoom
            }
            break;
        case "Package":
            paramsRooms = {
                ...searchPackageParams.paramsRoom
            }
            break;
        case "Tour":
            paramsRooms = {
                ...searchTourParams.paramsRoom
            }
            break;
        case "Transfer":
            paramsRooms = {
                ...searchShutterParams.paramsRoom
            }
            break;
        default:

    }

    return paramsRooms;
}

const getParamsCalendarService = (type, resourceSite) => {
    let parameters = {};
    //TODO parametros salen de ./data/{locale}/searchParams.js
    //TODO si se necesita modificar parametros, es desde el archivo searchParams por lenguaje
    const { searchHotelParams, searchPackageParams, searchShutterParams, searchTourParams } = resourceSite;
    switch (type) {
        case "Hotel":
            parameters = {
                rulesDescriptions: {
                    empty: "Selecciona las fechas de tu viaje",
                },
                ...searchHotelParams.paramsCalendar,
            }
            break;
        case "Package":
            parameters = {
                ...searchPackageParams.paramsCalendar,
            }
            break;
        case "Tour":
            parameters = {
                ...searchTourParams.paramsCalendar,
            }
            break;
        case "Transfer":
            parameters = {
                ...searchShutterParams.paramsCalendar,
            }
            break;
        default:
    }
    return parameters;
}

const getParamsAutocompleteService = (type, resourceSite) => {
    let parameters = {};
    //TODO parametros salen de ./data/{locale}/searchParams.js
    //TODO si se necesita modificar parametros, es desde el archivo searchParams por lenguaje
    const { searchHotelParams, searchPackageParams, searchShutterParams, searchTourParams } = resourceSite;
    switch (type) {
        case "Hotel":
            parameters = {
                rulesDescriptions: {
                    empty: "Selecciona un hotel o destino de la caja de busqueda",
                    selectList: "Selecciona un hotel o destino del listado de búsqueda"
                },
                ...searchHotelParams.paramsAutocomplete,
            }
            break;
        case "Package":
            parameters = {
                ...searchPackageParams.paramsAutocomplete,
            }
            break;
        case "Tour":
            parameters = {
                ...searchTourParams.paramsAutocomplete,
            }
            break;
        case "Transfer":
            parameters = {
                ...searchShutterParams.paramsAutocomplete,
            }
            break;
        default:
    }
    return parameters;
}

const searchBookingUtils = {
    getParametersService,
    getParamsRoomsService,
    getParamsCalendarService,
    getParamsAutocompleteService
}

export default searchBookingUtils;