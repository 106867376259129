import { Card } from "react-bootstrap";
import { useGlobalState } from "../../../store/StoreProvider";
import rateUtils from "../../../utils/rateUtils";

const CardPaxes = (props) => {
  const { options, service } = props;
  const { currency, locale } = useGlobalState();
  return (
    <Card className="card-checkout-paxes">
      <Card.Body>
        <Card.Title>
          <h5 className="title">{options.title}</h5>
          {options.description && <p>{options.description}</p>}
        </Card.Title>
        <div className="card-text">
          <div className="content-pax">
            <div className="pax-info">
              <p className="mb-0 pb-0 title">
                {options.informationDescription}
              </p>
            </div>
            <div className="pax-rate">
              <p qui="p adicional-fees">
                {" "}
                {rateUtils.formatAmount(
                  service?.rooms[0]?.totalTaxesNotIncluded +
                    service?.rooms[0]?.totalFeesNotIncluded,
                  currency.code,
                  locale
                )}
              </p>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CardPaxes;
