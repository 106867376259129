import { FilterMatchMode, FilterOperator } from "primereact/api";

const statusRefundable = (intl) => {
    return [
        {
          label: intl.formatMessage({ id: "general.table.refundable" }),
          value: "refundable",
        },
        {
          label: intl.formatMessage({ id: "general.table.nonRefundable" }),
          value: "nonRefundable",
        },
      ]
}

const statusReservation = (intl) => {
    return [
        {
          label: intl.formatMessage({ id: "general.statusConfirmed" }),
          value: "CONFIRMED",
        },
        {
          label: intl.formatMessage({ id: "general.statusCancelled" }),
          value: "CANCELLED",
        },
        {
          label: intl.formatMessage({ id: "general.statusHold" }),
          value: "HOLD",
        },
      ]
}

const statusPublicRate = (intl) => {
    return [
    {
      label: intl.formatMessage({ id: "reservationList.table.td.isPublicRate" }),
      value: "publicRate",
    },
    {
      label: intl.formatMessage({ id: "reservationList.table.td.nonPublicRate" }),
      value: "nonPublicRate",
    },
  ];
}

const filterOptions = () => {

    return {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        reservationId: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        "customer.fullName": {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        netRate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        publicRate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        "seller.fullName": {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        "service.name": {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        "firstRoom.name": {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        roomsCount: { value: null, matchMode: FilterMatchMode.IN },
        adultsTotal: { value: null, matchMode: FilterMatchMode.IN },
        childrenTotal: { value: null, matchMode: FilterMatchMode.IN },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        dateReservation: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        dateLimitCancellation: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        dateLimitPayment: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        "service.checkIn": {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        "service.checkOut": {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        statusReservation: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        statusPayment: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        isRefundable: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        isPublicRate: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
    }
}

const tableNamesColums = (intl) => {
    return {
        id: {
          field: "reservationId",
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          header: intl.formatMessage({ id: "reservationList.table.reservationId" }),
          filterPlaceholder: intl.formatMessage({ id: "reservationList.table.placeholder.reservationId" })
        },
        name: {
          field: "customer.fullName",
          header: intl.formatMessage({ id: "reservationList.table.name" }),
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          filterPlaceholder: intl.formatMessage({ id: "reservationList.table.placeholderName" })
        },
        nameService: {
          field: "service.name",
          header: intl.formatMessage({ id: "reservationList.table.nameService" }),
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          filterPlaceholder: intl.formatMessage({ id: "reservationList.table.placeholder.nameService" })
        },
        nameServiceRoom: {
          field: "firstRoom.name",
          header: intl.formatMessage({ id: "reservationList.table.nameServiceRoom" }),
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          filterPlaceholder: intl.formatMessage({ id: "reservationList.table.placeholder.nameServiceRoom" })
        },
        roomCount: {
          field: "roomsCount",
          header: intl.formatMessage({ id: "reservationList.table.roomCount" }),
          filterPlaceholder: intl.formatMessage({ id: "reservationList.table.placeholder.roomCount" }),
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          showFilterMatchModes: false, //oculta matchs
        },
        adultsTotal: {
          field: "adultsTotal",
          header: intl.formatMessage({ id: "reservationList.table.adultsTotal" }),
          filterPlaceholder: intl.formatMessage({ id: "reservationList.table.placeholder.adultsTotal" }),
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          showFilterMatchModes: false,
        },
        childrenTotal: {
          field: "childrenTotal",
          header: intl.formatMessage({ id: "reservationList.table.childrenTotal" }),
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          filterPlaceholder: intl.formatMessage({ id: "reservationList.table.placeholder.childrenTotal" }),
          showFilterMatchModes: false,
        },
        nameSeller: {
          field: "seller.fullName",
          header: intl.formatMessage({ id: "reservationList.table.nameSeller" }),
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          filterPlaceholder: intl.formatMessage({ id: "reservationList.table.placeholder.nameSeller" })
        },
        netRate: {
          field: "net",
          // dataType: "numeric",
          header: intl.formatMessage({ id: "reservationList.table.netRate" }),
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          filterPlaceholder: intl.formatMessage({ id: "reservationList.table.placeholder.netRate" })
        },
        publicRate: {
          field: "selling",
          dataType: "numeric",
          header: intl.formatMessage({ id: "reservationList.table.publicRate" }),
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          // filterPlaceholder: intl.formatMessage({ id: "reservationList.table.publicRate" })
        },
        dateReservation: {
          field: "dateReservation",
          header: intl.formatMessage({ id: "reservationList.table.dateReservation" }),
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          filterField: "dateReservation",
          dataType: "date",
        },
        dateLimitCancellation: {
          field: "dateLimitCancellation",
          header: intl.formatMessage({ id: "reservationList.table.dateLimitCancellation" }),
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          filterField: "dateLimitCancellation",
          dataType: "date",
        },
        dateLimitPayment: {
          field: "dateLimitPayment",
          header: intl.formatMessage({ id: "reservationList.table.dateLimitPayment" }),
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          filterField: "dateLimitPayment",
          dataType: "date",
        },
        checkIn: {
          field: "service.checkIn",
          header: intl.formatMessage({ id: "reservationList.table.checkIn" }),
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          filterField: "service.checkIn",
          dataType: "date",
        },
        checkOut: {
          field: "service.checkOut",
          header: intl.formatMessage({ id: "reservationList.table.checkOut" }),
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          filterField: "service.checkOut",
          dataType: "date",
        },
        statusReservation: {
          field: "statusReservation",
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          header: intl.formatMessage({ id: "reservationList.table.statusReservation" }),
        },
        statusPayment: {
          field: "statusPayment",
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          header: intl.formatMessage({ id: "reservationList.table.statusPayment" }),
        },
        isRefundable: {
          field: "isRefundable",
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          header: intl.formatMessage({ id: "reservationList.table.isRefundable" }),
        },
        isPublicRate: {
          field: "isPublicRate",
          sortable: true, //ordenable
          filter: true, //aplicafiltro
          header: intl.formatMessage({ id: "reservationList.table.isPublicRate" }),
        },
        actions: {
          field: "actions",
          sortable: false, //ordenable
          filter: false, //aplicafiltro
          header: intl.formatMessage({ id: "general.action" }),
        },
      }
}
const optionsTable = {
    filterOptions,
    tableNamesColums,
    statusRefundable,
    statusPublicRate,
    statusReservation,
}

export default optionsTable;