export default {
    "homePage.title" : "The best hotels around the world.",
    "homePage.titleDestinations" : "Top Destinations",
    "homePage.attentionATitle" : "Personalized attention",
    "homePage.attentionADescription" : "Personal service before and during your trip.",
    "homePage.attentionBTitle" : "Products",
    "homePage.attentionBDescription" : "Choose from a wide range of options.",
    "homePage.attentionCTitle" : "Segurity",
    "homePage.attentionCDescription" : "Make your reservation with price and service guarantee.",
    "homePage.tours.title" : "Activities you will love",
    "homePage.tours.transfer" : "Transfers you will love",
    "homePage.packages.title" : "The best packages around the world.",
}