import initialState from "./initialState";
import types from "../constants/reducers/resourceSiteConstants";

const resourceSiteReducer = (state = initialState.resourceSite, action) => {
    switch(action.type) {
        case types.RESOURCE_SITE_FETCHING:
            return {...initialState.resourceSite, ...action.resourceSite}
        case types.RESOURCE_SITE:
            return action.resourceSite;
        case types.RESOURCE_SITE_ERROR:
            return action.resourceSite;
        default:
            return state;
    }
}

export default resourceSiteReducer;