import { Offcanvas, Tabs , Tab,  } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Button  } from "react-bootstrap";
import rateUtils from "../../../utils/rateUtils"
import "./components/style.scss";
import { useGlobalState } from "../../../store/StoreProvider";

const OffCanva = (props) => {
  const intl = useIntl();
  const { show , item, text, currency } = props;

  const {locale} = useGlobalState();

  const handdleDetails = (event) => {
    const { onClickOpenDetails } = props;
    onClickOpenDetails && onClickOpenDetails();
  }

  const handdleReservation = () => {
    const { gotoCheckOut} = props;
    gotoCheckOut && gotoCheckOut(item);
  }

    return (  
    <>
      <Button variant="text" 
      className='btn-filter'
      onClick={handdleDetails} >
        { text }
      </Button>
      <Offcanvas id="details-Tranfer"
      className="offcanva-pop"
      backdrop="false" show={show} onHide={handdleDetails}
      name="Disable backdrop" placement='end'
      scroll={false}
      >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title> {item.name} </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="Offcanva-body">
        <div>
          <Tabs  className="mb-4" defaultActiveKey="description" id="tabs-transfer" fill>
            <Tab eventKey="description" title= {intl.formatMessage({ id: "general.details" })} >
              <ul className="offcanva-description">
                { item.description && 
                  item.description.map((list, index) => (
                    <li key={index}> {list?.text} </li> ) )
                } 
              </ul>
            </Tab>
          
            <Tab eventKey="profile" title= {intl.formatMessage({ id: "general.measures" })} >
              { item.measures && 
                item.measures.map((measure, index) => ( <>
                <b className="mb-2"> { measure.title } </b> 
                <ul key={index}>
                  { measure.items && 
                    measure.items.map((text, i) => (
                      <li key={i}> {text?.text} </li> ) )
                  } 
                </ul>  </> ) ) 
              } 
            </Tab>
            <span>  Esta información la proporcionan nuestros proveedores. </span>
          </Tabs>
        </div>

        <div className="OffCanva-footer">
          <div className="box-reservation">
                { item.hasOffers && (  
                <div className="rate-without-discount">
                  { item.bestRate?.totalWithOffersPerNight
                  ? rateUtils.formatAmount(item.bestRate?.totalWithOffersPerNight, currency, locale)
                  : rateUtils.formatAmount(item.bestRate?.pricewithoutOffer, currency, locale) }
                </div> )}
            <div className="box-item-price">
                {rateUtils.formatAmount( item.bestRate?.totalPerNight, currency, locale)}
            </div>
            <p className="box-item-text">
               {item.categoryType } 
            </p>
          </div>
          <div className="box-reservation">
            <Button 
            className='btn-reserva'
            onClick={handdleReservation}>
              { intl.formatMessage({ id: "general.reservation"})}
            </Button>
        </div>
  
        </div>   
      </Offcanvas.Body>
      </Offcanvas>
    </>   
   )
}

export default OffCanva;
