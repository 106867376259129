import footer from "./footer";
import general from "./general";
import homePage from "./homePage";
import hotelDetailPage from "./hotelDetailPage";
import tourDetailPage from "./tourDetailPage";
import languages from "./languages";
import website from "./website";
import button from "./button";
import voucherPage from "./voucherPage";
import hotelListPage from "./hotelListPage";
import form from "./form";
import checkout from "./checkout";
import transferListPage from "./transferListPage";
import transferVoucherPage from "./trasferVoucherPage";
import homeData from "./homeData";
import packages from "./packages";
import loginPage from "./loginPage";
import tourDetailProvisional from "./tourDetailProvisional";
import reservationListPage from "./reservationListPage";
import stripeErrors from "./stripeErrors";
import paymentStatus from "./paymentStatus";
import bookStatus from "./bookStatus";

export default {
  "en-us": Object.assign(
    {},
    website,
    languages,
    footer,
    general,
    homePage,
    hotelDetailPage,
    button,
    voucherPage,
    hotelListPage,
    button,
    form,
    checkout,
    tourDetailPage,
    transferListPage,
    transferVoucherPage,
    homeData,
    packages,
    loginPage,
    tourDetailProvisional,
    reservationListPage,
    stripeErrors,
    paymentStatus,
		bookStatus
  ),
};
